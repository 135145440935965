/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React from 'react';
import 'rc-datetime-picker/dist/picker.min.css';
import LineChartDeprecated from './LineChartDeprecated';
// Temporal data removed, please ask in the channel if this feature needs to be back
// import arkData from '../../../utils/temporal-data/yakuza.json';
// import fearthenight1Data from '../../../utils/temporal-data/fearthenight1.json';
// import fearthenight2Data from '../../../utils/temporal-data/fearthenight2.json';
// import gotData from '../../../utils/temporal-data/got.json';
// import insurgencyData from '../../../utils/temporal-data/insurgency.json';
// import madmachinesData from '../../../utils/temporal-data/madmachines.json';
// import outlawsData from '../../../utils/temporal-data/outlaws.json';
// import pixarkData from '../../../utils/temporal-data/pixark.json';
// import splitgateData from '../../../utils/temporal-data/splitgate.json';
import './Graph.scss';

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  render() {
    const { id } = this.props.router.params;

    const data = [];
    let title = '';

    if (id === '1') {
      // data = arkData;
      title = 'ARK: Survival - 2019-06-17 00:00 to 2019-07-17 23:59';
    } else if (id === '2') {
      // data = fearthenight1Data;
      title = 'Fear the Night 1 - 2018-12-19 00:00 to 2018-12-23 23:59';
    } else if (id === '3') {
      // data = fearthenight2Data;
      title = 'Fear the Night 2 - 2019-03-02 00:00 to 2019-03-12 23:59';
    } else if (id === '4') {
      // data = gotData;
      title = 'GOT - 2019-04-09 00:00 to 2019-04-20 23:59';
    } else if (id === '5') {
      // data = insurgencyData;
      title = 'Insurgency - 2019-06-20 00:00 to 2019-06-24 23:59';
    } else if (id === '6') {
      // data = madmachinesData;
      title = 'Mad Machines - 2019-03-25 00:00 to 2019-07-31 23:59';
    } else if (id === '7') {
      // data = outlawsData;
      title = 'Outlaws - 2019-05-28 00:00 to 2019-07-31 23:59';
    } else if (id === '8') {
      // data = pixarkData;
      title = 'PixARK - 2019-05-31 00:00 to 2019-06-30 23:59';
    } else if (id === '9') {
      // data = splitgateData;
      title = 'Splitgate - 2019-05-18 00:00 to 2019-06-18 23:59';
    }

    return (
      <section className="Graph">
        <h3 className="Graph--title">{title}</h3>
        <LineChartDeprecated data={data} />
      </section>
    );
  }
}

Graph.propTypes = {

};

export default Graph;
