import React from 'react';
import './InsightsFilter.scss';
import SearchFilter from './filters/SearchFilter.js';
import RangeFilter from './filters/RangeFilter.js';
import classNames from 'classnames';

function InsightsFilter(props) {
  const {
    allowSearch,
    clearSelected,
    filterShown,
    filterType,
    icon,
    isFiltering,
    isLoading, 
    justify, 
    options,
    range,
    selected,
    setSelectedRange,
    title,
    toggleFilterShown,
    toggleSelectOption
  } = props;

  const classes = classNames({ filtering: !!isFiltering });

  return (
    <div className="InsightsFilter">
      {(filterType === 'search') ? (
        <>
          <div className="filterButton" onClick={toggleFilterShown}>
            <span className={classes}>
              <img alt="" src={icon} />
              {isFiltering && selected && selected.length ? `(${selected.length})` : ''} <span className="label">{title}</span>
            </span>
          </div>
          <SearchFilter
            allowSearch={allowSearch}
            clearSelected={clearSelected}
            display={filterShown}
            isLoading={isLoading}
            justify={justify}
            options={options}
            selected={selected}
            title={title}
            toggleSelectOption={option => toggleSelectOption(option)}
          />
        </>
      ) : (filterType === 'range') ? (
        <>
          <div className="filterButton" onClick={toggleFilterShown}>
            <span className={classes}>
              <img alt="" src={icon} />
              {isFiltering ? `${selected.min}-${selected.max}` : <span className="label">{title}</span>}
            </span>
          </div>
          <RangeFilter
            display={filterShown}
            justify={justify}
            range={range}
            selected={selected}
            setSelectedRange={rangeValue => setSelectedRange(rangeValue)}
            title={title}
          />
        </>
      ) : null}
    </div>
  );
};

export default InsightsFilter;
