import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import puzzle from '../assetsV2/puzzle-diagram.png';
import anime from 'animejs';


const GTheRightMix = (props) => {
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0, navToOffset = () => null, contactNavIndex = 0, isMobile = false,
  } = props;
  const ref = React.createRef();

  const onScreen = useOnScreen(ref, '100px', 0.5);
  const [debounce, setDebounce] = React.useState(true);

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.cascade-fade-g',
        opacity: [1, 0],
        translateY: 100,
        duration: 800,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(60, { direction: 'reverse' }),
      });
      setDebounce(false);
    }
  }, [onScreen, debounce]);

  return (
    < >
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0} className="slide-e-container" >
        <div className="brand-vert-stack cascade-fade-g">
          <div className="brand-title" style={{ marginBottom: isMobile ? '30px' : '' }}>Discover the Right Mix of Influencers
          </div>
          <div className="grey-brand-body-text">
            <div ref={ref} />
            We can help you find the right mix of macro and micro influencers to achieve your campaign’s goals. We effectively leverage tens of thousands of content creators for gamer endemic and non-endemic brands.
          </div>
          <div>
            <button onClick={() => navToOffset(contactNavIndex - 1.8)} className="brand-white-button"> Learn More </button>
          </div>
        </div>
        <div className="cascade-fade-g brand-image-container">
          <img src={puzzle} alt="puzzle piece" />
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0} className="moving-backg cascade-fade-g" />
    </>

  );
};


export default GTheRightMix;

