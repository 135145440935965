import React from 'react';
import classNames from 'classnames';
import './ChartStatBox.scss';
import { numberWithCommas } from '../../../../utils/functions';

class ChartStatBox extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  UNSAFE_componentWillMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
  }

  render() {
    const {
      data = [], size = 4, title = 'Title', minHeight = '100px', minWidth="100%",
    } = this.props;
    const maxValue = data && data.length >= 1 ? data[0].value : 0;
    const classes = classNames({
      ChartStatBox: true,
      full: size === 'full',
      half: size === 'half',
    });

    return (
      <div className={classes} style={{ minHeight, minWidth }}>
        <h2>{title}</h2>
        <div className="charts">
          <div className="chartTitles">
            { data.map(item => (
              <div className="chartTitle">
                { item.title }
                { item.img ? (<img alt="chartImg" src={item.img} />) : null }
              </div>))}
          </div>
          <div className="chartBars">
            { data.map((item, i) => (
              <span className="completionBar" key={i} >
                <span className="fill" style={{ width: `${(item.value / maxValue) * 100}%` }} />
                <span className="label">{item.value ? numberWithCommas(item.value) : '0'}</span>
              </span>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

ChartStatBox.propTypes = {
};

export default ChartStatBox;
