import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classNames from 'classnames';
import './Welcome.scss';

class ConnectAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      gamersActive: false,
      streamersActive: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        gamersActive: true,
      });
    }, 500);
    setTimeout(() => {
      this.setState({
        streamersActive: true,
      });
    }, 1600);
  }

  render() {
    const gamersClasses = classNames({
      'shapes-container': true,
      inactive: !this.state.gamersActive,
    });
    const streamersClasses = classNames({
      inactive: !this.state.streamersActive,
    });
    return (
      <section className="Welcome">
        <section className="ModalMaster">
          <section className={classNames('Modal Modal__welcome active')}>
            <Link
              to="/"
              href="/"
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <Link
                className="Modal__close-btn"
                to="/"
                href="/"
                role="button"
                aria-hidden
              />
              <div className="Welcome__header">
                <h1>Welcome</h1>
                <div className="Welcome__header__description">
                  Noiz is a community platform where streamers and gamers can explore all kinds of opportunities including:
                </div>
              </div>
              <div className="Welcome__body">
                <div className="Welcome__gamers">
                  <div className="Welcome__gamers__image">
                    <div className={`shapes-container shapes-container-gamer ${gamersClasses}`}>
                      <div className="shape shape1" />
                      <div className="shape shape2" />
                      <div className="shape shape3" />
                      <div className="shape shape4" />
                      <div className="shape shape5" />
                      <div className="shape shape6" />
                      <div className="shape shape7" />
                      <div className="shape shape8" />
                      <img src="//images/welcome/gamers-sizer.jpg" alt="Gamers Sizer" className="image-sizer image-sizer-gamers" />
                    </div>
                    <img src="//images/welcome/gamers-sizer.jpg" alt="Gamers Sizer" className="image-sizer image-sizer-gamers" />
                  </div>
                  <div className="Welcome__gamers__title">
                    Gamers
                  </div>
                  <div className="Welcome__gamers__row">
                    Alpha Testing + Feedback
                  </div>
                  <div className="Welcome__gamers__row">
                    Direct access to game dev teams
                  </div>
                  <div className="Welcome__gamers__row">
                    Raffles + Freebies
                  </div>
                  <div className="Welcome__gamers__row">
                    Competitions + Tournaments
                  </div>
                </div>
                <div className="Welcome__streamers">
                  <div className="Welcome__streamers__image">
                    <div className={`shapes-container shapes-container-streamer ${streamersClasses}`}>
                      <div className="shape shape1" />
                      <div className="shape shape2" />
                      <div className="shape shape3" />
                      <div className="shape shape4" />
                      <div className="shape shape5" />
                      <div className="shape shape6" />
                      <div className="shape shape7" />
                      <div className="shape shape8" />
                      <img src="//images/welcome/streamer-sizer.jpg" alt="Streamers Sizer" className="image-sizer image-sizer-gamers" />
                    </div>
                    <img src="//images/welcome/streamer-sizer.jpg" alt="Streamers Sizer" className="image-sizer image-sizer-gamers" />
                  </div>
                  <div className="Welcome__streamers__title">
                    Streamers
                  </div>
                  <div className="Welcome__streamers__row">
                    Paid opportunities
                  </div>
                  <div className="Welcome__streamers__row">
                    All opportunities opened to gamers
                  </div>
                  <div className="Welcome__streamers__row">
                    Exclusive Early Access
                  </div>
                  <div className="Welcome__streamers__row">
                    Other streaming partnerships
                  </div>
                </div>
              </div>
              <div className="Welcome__footer">
                <div className="Welcome__footer__button">
                  <Link to="/" href="/" >Continue</Link>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

ConnectAccounts.propTypes = {
};

export default ConnectAccounts;
