/* eslint-disable react/no-did-update-set-state */
import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { find } from 'lodash';
import classNames from 'classnames';
// import { Link } from 'react-router';
// import {Link} from 'react-router'
import './SearchQuests.scss';
import { isAuthenticated } from '../../utils/authChecker';
// import { simpleNotification } from '../../utils/notifications';
// import { isAuthenticated } from '../../utils/authChecker';

import IconParticles from '../icon-particles/IconParticles';

class SearchQuests extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      items: [],
      initialItems: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (isAuthenticated()) {
      const { token, user } = this.props.auth;
      const isBasicInfoCompleted = (user.firstName && user.lastName && user.country);
      if (!isBasicInfoCompleted) {
        this.props.router.replace('/complete-registration');
      } else {
        this.props.getGameList(token);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config.quests.isLoading && !this.props.config.quests.isLoading) {
      this.setState({
        initialItems: this.props.config.quests.data,
        items: this.props.config.quests.data,
      });
    }
  }

  onChangeSearch = (event) => {
    let updatedList = this.state.initialItems;
    updatedList = updatedList.filter(item => (
      item.type.toLowerCase().trim().search(event.target.value.toLowerCase().trim()) !== -1 ||
        item.title.toLowerCase().trim().search(event.target.value.toLowerCase().trim()) !== -1 ||
        item.description.toLowerCase().trim().search(event.target.value.toLowerCase().trim()) !== -1 ||
        item.game.name.toLowerCase().trim().search(event.target.value.toLowerCase().trim()) !== -1 ||
        item.game.description.toLowerCase().trim().search(event.target.value.toLowerCase().trim()) !== -1 ||
        item.game.developer.toLowerCase().trim().search(event.target.value.toLowerCase().trim()) !== -1
    ));
    this.setState({ items: updatedList });
  }

  getQuest = (questId) => {
    const { token } = this.props.auth;
    this.props.getQuest(questId, token);
  }

  generateGameQuestsHTML() {
    const quests = this.state.items;
    const { isLoading } = this.props.config.quests;
    const { data: myQuests } = this.props.user.myQuests;
    if (quests.length > 0 && !isLoading) {
      return quests.map((quest, i) => {
        const alredyIn = find(myQuests, questItem => questItem.id === quest.id);
        return (
          <section
            key={quest.id}
            className="QuestCard"
            onClick={this.getQuest.bind(this, quest.id)}
            aria-hidden
          >
            <div className="QuestCard__image">
              <figure style={{ backgroundImage: `url(${quest.cover})` }} alt="Game Cover" />
            </div>
            <section className="QuestCard__details">
              <div className="QuestCard__background-top" />
              <div className="QuestCard__background-bottom" />
              <section className="QuestCard__details-wrapper">
                <section className="QuestCard__details--row">
                  <i
                    className={classNames('QuestCard__details--icon', {
                      'noiz-video-camera': quest.type === 'paid bounty',
                      'noiz-game-controller': (quest.type === 'early access key' || quest.type === 'influencers early access'),
                      'noiz-thumbs-up': quest.type === 'alpha feedback',
                      'noiz-discount': quest.type === 'referral',
                      'fa fa-gear': quest.type === 'default',
                      'fa fa-clock-o': quest.type === 'timed',
                      'fa fa-external-link': quest.type === 'link out',
                    })}
                  />
                  <section className="QuestCard__details--info">
                    <section className="primary-info">
                      <div className="QuestCard__details--name">
                        { (quest.type === 'default') &&
                          <React.Fragment>General</React.Fragment>}
                        { (quest.type === 'link out') &&
                          <React.Fragment>External</React.Fragment>}
                        {(quest.type !== 'link out') && (quest.type !== 'default') &&
                          <React.Fragment>{quest.type}</React.Fragment>}
                      </div>
                    </section>
                    <section className="sub-info">
                      <div className="QuestCard__details--game-name">
                        {quest.game.name}
                      </div>
                      <div className="QuestCard__details--date-interval">
                        {quest.dateInterval || 'No Date'}
                      </div>
                    </section>
                  </section>
                </section>
                <section className="QuestCard__details--row">
                  { (!alredyIn) && (
                    <button className="SeeQuest">See Quest</button>
                  )}

                  { (alredyIn) && (
                    <button className="SeeQuest">Joined</button>
                  )}
                </section>
                <section className="QuestCard__details--row">
                  <div className="QuestCard__details--open-slots">
                    {quest.slots || 0} open slots
                  </div>
                </section>
              </section>
            </section>
            <div className="QuestCard__corner__1" />
            <div className="QuestCard__corner__2" />
            <div className="QuestCard__corner__3" />
            <div className="QuestCard__corner__4" />
          </section>
        );
      });
    }
    // If no active games show this message
    return (<div className="NoQuests">No Quests at this moment.</div>);
  }

  render() {
    return (
      <section className="SearchQuests">
        <section className="SearchQuests__container">
          <div className="SearchQuests__content__extra" />
          <div className="SearchQuests__content">
            <IconParticles />
            <div className="SearchQuests__header">
              Search, join quests and earn money!
            </div>
            <div className="SearchQuests__fields">
              <div className="input-container active first-input-container">
                <div className="input-wrapper input-wrapper_search">
                  <i className="noiz-search" />
                  <input
                    autoComplete="off"
                    id="search"
                    type="text"
                    placeholder="Search for games"
                    onChange={this.onChangeSearch.bind(this)}
                    className={classNames('input_search', {})}
                  />
                </div>
              </div>

              { /* SHOULD BE REMOVED WHEN FILTERS ARE IMPLEMENTED */}
              <div style={{ flex: '8' }} />
              { /* SHOULD BE REMOVED WHEN FILTERS ARE IMPLEMENTED */}

              { /* <div className="input-container">
                <label>
                  Genre
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all genres</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div>
              <div className="input-container">
                <label>
                  Features
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all features</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div>
              <div className="input-container">
                <label>
                  Release
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all release dates</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div>
              <div className="input-container">
                <label>
                  Company
                </label>
                <div className="input-wrapper">
                  <select
                    className={classNames({})}
                  >
                    <option className="first-value" value="">Show all companies</option>
                  </select>
                  <i className="fa fa-caret-down" />
                </div>
              </div> */}
            </div>
            <div className="SearchQuests__quests">
              { this.generateGameQuestsHTML() }
            </div>
            <div className="SearchQuests__more">
              <button>Load more quests</button>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

SearchQuests.propTypes = {

};

export default SearchQuests;
