/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import ReactDOM from 'react-dom';
import 'rxjs';
import { Provider } from 'react-redux';
import {
  applyRouterMiddleware,
  IndexRoute,
  Router,
  Route,
} from 'react-router';
import useScroll from 'react-router-scroll/lib/useScroll';

import store, { history } from './store';
import { requireAuth, onlyNotAuthenticated } from './utils/authChecker';

import App from './containers/App';
import AboutUs from './components/about-us/AboutUs';
import BrandService from './components/brand-service/BrandService';
import Careers from './components/careers/Careers';
import CareersPost from './components/careers/CareersPost';
import Onboard from './components/onboard/Onboard';

// start Careers
import BizDevDirector from './components/careers/BizDevDirector';
import CampaignManager from './components/careers/CampaignManager';
import UXIndi from './components/careers/UXIndi';
import BrandSalesManager from './components/careers/BrandSalesManager';



// added April 2, 2021
import CommunityManager from './components/careers/CommunityManager';

// End Careers

import Contact from './components/contact/Contact';
import SignIn from './components/login/sign-in/SignIn';
import SignUp from './components/login/sign-up/SignUp';
import CompleteRegistration from './components/login/complete-registration/CompleteRegistration';
import Recover from './components/login/recover/Recover';
import ConnectAccounts from './components/connect-accounts/ConnectAccounts';
import Logout from './components/logout/Logout';
import LogOutBooth from './components/logout/LogOutBooth';
import FAQ from './components/faq/Faq';

import NotFound from './components/not-found/NotFound';
import AccessDenied from './components/access-denied/AccessDenied';

import Game from './components/game/Game';
// GameFeatured


import 'normalize.css';
import './reset.scss';
import './index.scss';

import Welcome from './components/welcome/Welcome';
import WhatsNoiz from './components/whats-noiz/WhatsNoiz';
import MyAccountHome from './components/my-account/home/MyAccountHome';

import Profile from './components/profile/Profile';
import MyQuestsPage from './components/my-quests-page/MyQuestsPage';
import QuestDetailPage from './components/dashboard/quest-detail/QuestDetailPage';

import CommandCenter from './components/admin/command-center/CommandCenter';
import GameKeys from './components/admin/command-center/game-keys/GameKeys';
import TrackedLinks from './components/admin/command-center/tracked-links/TrackedLinks';

import WhiteList from './components/admin/command-center/white-list/WhiteList';
import Quest from './components/quest/Quest';
import QuestIndex from './components/quests/QuestIndex';
import Quests from './components/admin/command-center/quests/Quests';
import QuestEdit from './components/admin/command-center/quest-edit/QuestEdit';
import QuestActivity from './components/admin/command-center/quest-activity/QuestActivity';
import QuestSales from './components/admin/command-center/quest-sales/QuestSales';
import QuestTimed from './components/admin/command-center/quest-timed-activity/QuestTimedActivity';
import QuestPreapproval from './components/admin/command-center/quest-preapproval/QuestPreapproval';
import QuestAffiliateActivity from './components/admin/command-center/quest-affiliate-activity/QuestAffiliateActivity';
import QuestTieredActivity from './components/admin/command-center/quest-tiered/QuestTiered';
import QuestTieredMultipleDaysActivity from './components/admin/command-center/quest-tiered-multiple-days/QuestTieredMultipleDays';

import Campaigns from './components/admin/command-center/campaigns/Campaigns';
import CampaignEdit from './components/admin/command-center/campaign-edit/CampaignEdit';

// import AdminUserList from "./components/admin/user/list/UserList";
import AdminUserContainer from './components/admin/user/AdminUserContainer';
import AdminUserCreate from './components/admin/user/create/UserCreate';
import AdminUserBrandCreate from './components/admin/user/create/UserBrandCreate';
import AdminUserEdit from './components/admin/user/edit/UserEdit';

import AdminGameList from './components/admin/game/list/GameList';
import AdminGameCreate from './components/admin/game/create/GameCreate';
import AdminGameEdit from './components/admin/game/edit/GameEdit';
import AdminGameFeatured from './components/admin/game/featured/GameFeatured';

import AdminCustomGraph from './components/admin/custom/Graph';

import AdminPaymentList from './components/admin/payment/list/PaymentList';

import Portal from './components/brand/portal/Portal';
import PortalSignForm from './components/brand/portal/PortalSignForm';

import StorefrontGame from './components/storefront/storefront-game/StorefrontGame';
import StorefrontSuccess from './components/storefront/storefront-game/Success';
import StorefrontCheckout from './components/storefront/storefront-game/Checkout';

import Store from './components/store/Store';

import SearchGames from './components/search-games/SearchGames';
import SearchQuests from './components/search-quests/SearchQuests';

import ContactLight from './components/brand/contact/ContactLight';
import Contacts from './components/admin/contacts/Contacts';

import DataForm from './components/data-form/DataForm';

import Insights from './components/admin/insights/Insights';

import LandingQuest from './components/landing-quest/LandingQuest';
import OffplatformQuest from './components/offplatform-quest/OffplatformQuest';

import DnlReferralSalesTermsConditions from './components/static-pages/DnlReferralSalesTermsConditions';
import DnlKeyQuestTermsConditions from './components/static-pages/DnlKeyQuestTermsConditions';
import DnlReferralSalesAgreement from './components/static-pages/DnlReferralSalesAgreement';

import AdminDashboard from './components/my-account/admin-dashboard/AdminDashboard';
import AdminImportUsers from './components/my-account/admin-import-users/AdminImportUsers';
import PrivacyPolicy from './components/static-pages/PrivacyPolicy';
import TermsOfUse from './components/static-pages/TermsOfUse';
import TermsOfService from './components/static-pages/TermsOfService';
import QuestParticipationAgreement from './components/static-pages/QuestParticipationAgreement';

// import DinamicComponents from './components/DinamicComponent';

import MaintenanceMode from './components/maintenance-mode/MaintenanceMode';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Arbitration from './components/admin/command-center/arbitration/Arbitration';

import Home from './components/home/Home';

const routeList = { // should be converted to env var or db config number
  '/quests': 0.4,
  '/admin/command-center/': 0.1,
  '/': 0.2,
};

// Sentry.io Config
const { NODE_ENV } = process.env;
const isAliveEnv = NODE_ENV === 'production' || NODE_ENV === 'staging';
if (isAliveEnv) {
  Sentry.init({
    dsn: 'https://35f5ae7971a942408360de3487b7056f@o140475.ingest.sentry.io/702046',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampler: (samplingContext) => {
      const { NODE_ENV, SENTRY_DEFAULT_RATE } = process.env;
      if (NODE_ENV !== 'production') return 0;
      const defaultPassthrough = SENTRY_DEFAULT_RATE ? parseFloat(SENTRY_DEFAULT_RATE) : 0;
      const { pathname } = samplingContext.location;
      const presetPassthrough = routeList[pathname] ? routeList[pathname] : 0;
      console.log(`pathname: ${presetPassthrough}`);
      return presetPassthrough || defaultPassthrough;
    },
  });
}

const scrollMidleware = useScroll((prevRouterProps, { location }) => prevRouterProps && location.pathname !== prevRouterProps.location.pathname);

history.listen((location) => {
  if (window.ga) {
    window.ga('send', 'pageview', location.pathname);
  }
});

const subdomain = window.location.host.split(':')[0].split('.').slice(0, -2).join('.');

const router = (
  <Provider store={store}>
    <Router history={history} render={applyRouterMiddleware(scrollMidleware)}>
      <Route path="/" component={App}>
        <IndexRoute component={BrandService} />
        {/* No subdomain and www subdomain routes here! */}
        {/* { (subdomain === '' || subdomain === 'www' || subdomain === 'staging' || subdomain === 'new' || subdomain === '10.32' || subdomain === 'staging-v2' || subdomain === 'prod') && */}
        <React.Fragment>
          <Route path="/store" component={Store} />
          <Route path="/portal/sign-in" component={(props) => <PortalSignForm {...props} signInPage />} onEnter={onlyNotAuthenticated()} />
          <Route path="/portal/sign-up" component={PortalSignForm} onEnter={onlyNotAuthenticated()} />
          <Route path="/portal" component={Portal} onEnter={requireAuth(['brand', 'admin', 'demo', 'helper'])} />

          <Route path="/sign-up" component={SignUp} onEnter={onlyNotAuthenticated()} />
          <Route path="/sign-in" component={SignIn} onEnter={onlyNotAuthenticated()} />
          <Route path="/recover" component={Recover} onEnter={onlyNotAuthenticated()} />
          <Route path="/logout" component={Logout} />
          <Route path="/logout-booth" component={LogOutBooth} />
          <Route
            path="/connect-accounts(/:platform)"
            component={ConnectAccounts}
            onEnter={requireAuth(['user', 'admin', 'helper'])}
          />
          <Route
            path="/complete-registration"
            component={CompleteRegistration}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
          />
          <Route path="/welcome" component={Welcome} onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])} />
          <Route path="/access-denied" component={AccessDenied} />
          <Route path="/buy/:gameSlug/:questSlug/:username" component={StorefrontGame}>
            <Route path="/buy/:gameSlug/:questSlug/:username/success" component={StorefrontSuccess} />
            <Route path="/buy/:gameSlug/:questSlug/:username/checkout" component={StorefrontCheckout} />
          </Route>
          <Route path="/buy/:gameSlug" component={StorefrontGame}>
            <Route path="/buy/:gameSlug/success" component={StorefrontSuccess} />
            <Route path="/buy/:gameSlug/checkout" component={StorefrontCheckout} />
          </Route>
          <Route path="/quests/:questId" component={Quest} />
          <Route path="/quests/:questSlug/:userId" component={OffplatformQuest} />
          <Route path="/quest/:questSlug" component={LandingQuest} />
          {/* <Route path="/login" component={TwitchLogin} onEnter={onlyNotAuthenticated()} /> */}
          <Route
            component={QuestIndex}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
            path="/quests"
          />
          <Route
            path="/game/:gameId"
            component={Game}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
          />
          <Route
            path="/search/games"
            component={SearchGames}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
          />
          <Route
            path="/search/quests"
            component={SearchQuests}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
          />
          {/* <Route path="/game/:gameId/get-key" component={GameGetKey} />
          <Route path="/game/:gameId/join-bounty" component={GameJoinBounty} />
          <Route path="/profile" component={Profile} onEnter={requireAuth(['user', 'admin'])} /> */}
          <Route path="/my-account" component={Profile} onEnter={requireAuth(['brand', 'user', 'admin', 'helper', 'demo'])}>
            <IndexRoute component={MyAccountHome} />
          </Route>
          <Route
            path="/my-quests"
            component={MyQuestsPage}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
          />
          <Route
            path="/my-quests/:questId"
            component={QuestDetailPage}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
          />
          <Route
            path="/my-quests/preview/:questId"
            component={QuestDetailPage}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          {/* Admin Sections */}
          <Route
            path="/admin/import/users"
            component={AdminImportUsers}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route
            path="/admin/report/:gameId"
            component={AdminDashboard}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route
            path="/admin/custom/graph/:id"
            component={AdminCustomGraph}
            onEnter={requireAuth(['admin', 'helper'])}
          />


          <Route path="/admin/command-center" component={CommandCenter} onEnter={requireAuth(['admin', 'helper'])}>
            <Route path="/admin/command-center/:gameId">
              <Route path="/admin/command-center/:gameId/keys" component={GameKeys} />
              <Route path="/admin/command-center/:gameId/links" component={TrackedLinks} />
              <Route path="/admin/command-center/:gameId/white-list" component={WhiteList} />
              <Route path="/admin/command-center/:gameId/quests" component={Quests} />
              <Route path="/admin/command-center/:gameId/quests/create" component={QuestEdit} />
              <Route path="/admin/command-center/:gameId/quests/:questId/edit" component={QuestEdit} />
              <Route path="/admin/command-center/:gameId/quests/:questId/activity" component={QuestActivity} />
              <Route path="/admin/command-center/:gameId/quests/:questId/sales" component={QuestSales} />
              <Route path="/admin/command-center/:gameId/quests/:questId/timed" component={QuestTimed} />
              <Route path="/admin/command-center/:gameId/quests/:questId/preapproval" component={QuestPreapproval} />
              <Route
                path="/admin/command-center/:gameId/quests/:questId/affiliate"
                component={QuestAffiliateActivity}
              />
              <Route
                path="/admin/command-center/:gameId/quests/:questId/:questType"
                component={QuestTieredActivity}
              />
              <Route
                path="/admin/command-center/:gameId/quests/:questId/tiered-multiple-days"
                component={QuestTieredMultipleDaysActivity}
              />
              <Route path="/admin/command-center/:gameId/campaigns" component={Campaigns} />
              <Route path="/admin/command-center/:gameId/campaigns/create" component={CampaignEdit} />
              <Route path="/admin/command-center/:gameId/campaigns/:campaignId/edit" component={CampaignEdit} />
            </Route>
          </Route>
          <Route path="/admin/users" component={AdminUserContainer} onEnter={requireAuth(['admin', 'helper'])} />
          <Route
            path="/admin/users/create"
            component={AdminUserCreate}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route
            path="/admin/users/create-brand"
            component={AdminUserBrandCreate}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route
            path="/admin/users/edit/:userId"
            component={AdminUserEdit}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route path="/admin/games" component={AdminGameList} onEnter={requireAuth(['admin', 'helper'])} />
          {/* <Route path="/admin/games/create" component={AdminGameEdit} onEnter={requireAuth(['admin'])} /> */}
          <Route
            path="/admin/games/create"
            component={AdminGameCreate}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route
            path="/admin/games/edit/:gameId"
            component={AdminGameEdit}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route
            path="/admin/games/featured"
            component={AdminGameFeatured}
            onEnter={requireAuth(['admin', 'helper'])}
          />
          <Route path="/admin/insights" component={Insights} onEnter={requireAuth(['admin'])} />
          <Route path="/admin/arbitration" component={Arbitration} onEnter={requireAuth(['admin'])} />
          <Route path="/admin/contacts" component={Contacts} onEnter={requireAuth(['admin'])} />
          <Route path="/admin/payments" component={AdminPaymentList} onEnter={requireAuth(['admin', 'helper'])} />
          {/*
          <Route path="/admin/config" component={AdminConfigList} onEnter={requireAuth(['admin'])} />
          <Route path="/admin/config/create" component={AdminConfigCreate} onEnter={requireAuth(['admin'])} />
          <Route path="/admin/config/edit/:configId" component={AdminConfigEdit} onEnter={requireAuth(['admin'])} />
          */}
          <Route path="/dnl-referral-sales-terms-conditions" component={DnlReferralSalesTermsConditions} />
          <Route path="/dnl-key-quest-terms-conditions" component={DnlKeyQuestTermsConditions} />
          <Route path="/dnl-referral-sales-agreement" component={DnlReferralSalesAgreement} />
          {/* Plain Info Pages */}
          <Route path="/faqs" component={FAQ} />
          <Route
            path="/whats-noiz"
            component={WhatsNoiz}
            onEnter={requireAuth(['user', 'admin', 'helper', 'demo'])}
          />
          {/* <Route path="/brand-services" component={BrandService} /> */}
          <Route path="/stream-with-us" component={Home} />

          <Route path="/careers" component={Careers} />
          <Route path="/careers/business-development-representative" component={CareersPost} />
          <Route path="/careers/biz-dev-director" component={BizDevDirector} />
          <Route path="/careers/community-manager" component={CommunityManager} />
          <Route path="/careers/campaign-manager" component={CampaignManager} />
          <Route path="/careers/ux-ev" component={UXIndi} />
          <Route path="/careers/brand-sales-manager" component={BrandSalesManager} />

          <Route path="/onboard" component={Onboard} />

          <Route path="/contact" component={Contact} />
          <Route path="/about-us" component={AboutUs} />){/* Trade Show Page */}

        </React.Fragment>
        {/* Brands subdomain routes here! */}
        {(subdomain === 'brands' || subdomain === 'staging.brands') && (
          <React.Fragment>
            <Route path="/contact" component={ContactLight} />
          </React.Fragment>
        )}
        {/* Fractured Lands subdomain routes here! */}
        {(subdomain === 'fractured-lands-partners' || subdomain === 'staging.fractured-lands-partners') && (
          <React.Fragment>
            <Route path="/signup" component={DataForm} />
          </React.Fragment>
        )}
        {/* Shared Subdomain Routes */}
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/terms-of-service" component={TermsOfService} />
        <Route path="/quest-participation-agreement" component={QuestParticipationAgreement} />
        <Route path=":slug*" component={NotFound} />
      </Route>
    </Router>
  </Provider>
);

async function getMaintenanceMode() {
  await fetch('/api/v1/maintenance-mode/', {
    method: 'GET',
    // mode: 'same-origin',
    // cache: 'default',
    // credentials: 'same-origin',
    // headers: {},
    // redirect: 'follow',
    // referrerPolicy: 'no-referrer-when-downgrade',
  })
    .then(res => res.json())
    .then((res) => {
      const value = res[0] && res[0].value;
      if (value) ReactDOM.render(<MaintenanceMode />, document.getElementById('root'));
      else ReactDOM.render(router, document.getElementById('root'));
    })
    .catch((err) => {
      console.error(err);
      // ReactDOM.render(<MaintenanceMode />, document.getElementById('root'));
      ReactDOM.render(router, document.getElementById('root'));
    });
}

getMaintenanceMode();
