import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserDetailModule from './UserDetailModule.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import { getCompleteUsers } from '../queries.js';
import './MostWatchedModule.scss';

function MostWatchedModule({ handleUserCompletion, userData }) {
  const hasUserData = userData && userData.length > 0;
  const [ selectedUserIndex, setSelectedUserIndex ] = useState(hasUserData ? 0 : false);
  const { token } = useSelector(state => state.auth);
  const selectedUser = hasUserData ? userData[selectedUserIndex] : {};

  // Fetch extra user information
  useEffect(() => {
    if (selectedUser && !selectedUser.email) {
      // TODO: create userLoading indicator & set here?
      getCompleteUsers({
        token,
        userIds: [selectedUser.id]
      }, completedUsers => {
        handleUserCompletion(completedUsers);
      });
    }
  }, [selectedUser.email, selectedUser.id, token]);

  return (
    <div className="MostWatchedModule">
      <h3>
        Most Watched Channels
      </h3>
      <div className="MostWatchedList">
        <Table>
          <TableBody>
            {userData && userData.map((user, index) => {
              const classes = classNames({
                UserCell: true,
                even: !!(index % 2),
                odd: !(index % 2),
              });

              return (
                <TableRow
                  key={user.id}
                  onClick={() => setSelectedUserIndex(index)}>
                  <TableCell className={classes}>
                    <span className={(selectedUser && selectedUser.id === user.id) ? 'selected' : ''}>
                      {index + 1}. {user.name}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <UserDetailModule
        selectedUser={selectedUser}
        token={token}
        userData={userData}
      />
    </div>
  )
}

export default MostWatchedModule;
