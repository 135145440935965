import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';

/* eslint-disable import/prefer-default-export */

export const getAllGuestQuests = action$ =>
  action$.ofType('GET_GUEST_QUESTS')
    .mergeMap(action =>
      api.getGuestQuests()
        .map(response => ({ type: 'GET_OPTIMIZED_ALL_QUESTS_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_OPTIMIZED_ALL_QUESTS_ABORTED'))
        .defaultIfEmpty({ type: 'GET_OPTIMIZED_ALL_QUESTS_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_OPTIMIZED_ALL_QUESTS_ERROR', error }))
        .startWith({ type: 'GET_OPTIMIZED_ALL_QUESTS_PENDING', payload: action.payload }));

export const getOptimizedAllQuests = action$ =>
  action$.ofType('GET_OPTIMIZED_ALL_QUESTS')
    .mergeMap(action =>
      api.getOptimizedQuests(action.payload.filterType, action.payload.page, action.payload.token)
        .map(response => ({ type: 'GET_OPTIMIZED_ALL_QUESTS_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_OPTIMIZED_ALL_QUESTS_ABORTED'))
        .defaultIfEmpty({ type: 'GET_OPTIMIZED_ALL_QUESTS_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_OPTIMIZED_ALL_QUESTS_ERROR', error }))
        .startWith({ type: 'GET_OPTIMIZED_ALL_QUESTS_PENDING', payload: action.payload }));

export const getOptimizedEndedQuests = action$ =>
  action$.ofType('GET_OPTIMIZED_ENDED_QUESTS')
    .mergeMap(action =>
      api.getOptimizedQuests(action.payload.filterType, action.payload.page, action.payload.token)
        .map(response => ({ type: 'GET_OPTIMIZED_ENDED_QUESTS_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_OPTIMIZED_ENDED_QUESTS_ABORTED'))
        .defaultIfEmpty({ type: 'GET_OPTIMIZED_ENDED_QUESTS_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_OPTIMIZED_ENDED_QUESTS_ERROR', error }))
        .startWith({ type: 'GET_OPTIMIZED_ENDED_QUESTS_PENDING', payload: action.payload }));
