import React from 'react';
import { Link } from 'react-router';

import './BasicMenu.scss';

function BasicMenu({ authenticated, visible }) {
  if (!visible) return null;

  return (
    <nav className="basic-menu">
      <ul>
        <li className="menu-item">
          <Link
            activeClassName="active"
            onlyActiveOnIndex
            to="/"
          >
            Home
          </Link>
        </li>
        <li className="menu-item">
          <Link
            activeClassName="active"
            to="/stream-with-us"
          >
            Stream With Us
          </Link>
        </li>
        <li className="menu-item">
          <Link
            activeClassName="active"
            to="/about-us"
          >
            About Us
          </Link>
        </li>
        <li className="menu-item">
          <Link
            activeClassName="active"
            to="/careers"
          >
            Careers
          </Link>
        </li>
        {/* <li className='menu-item' /> */}
        <li className="brands-btn">
          <Link
            activeClassName="active"
            to="/onboard"
          >
            Brands
          </Link>
        </li>
        <li className="creators-btn">
          <Link
            activeClassName="active"
            to="/sign-up"
          >
            {authenticated ? 'Quests' : 'Creators'}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default BasicMenu;
