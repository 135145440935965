/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import AdminTabs from '../../util/tabs/AdminTabs';
import AdminGameFeaturedForm from '../../../forms/AdminGameFeaturedForm';
// import { simpleNotification } from '../../../../utils/notifications';
import './GameFeatured.scss';

class GameFeatured extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
    this.gameLoaded = false;
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    // const { gameId } = this.props.router.params;
    this.props.getAGameList(token);
    this.props.getInitialInfo(token);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config.featuredGames.isLoading === true && nextProps.config.featuredGames.isLoading === false) {
      if (!nextProps.config.featuredGames.errors.hasError) {
        const formatedData = nextProps.config.featuredGames.data.map(item => ({
          game: item.name,
          id: item.id,
        }));
        this.props.assignGameFeaturedInfo({ featured: formatedData });
      }
    }
  }

  onSubmit = (data) => {
    const { token } = this.props.auth;
    this.props.updateAFeatured(data, token);
    this.props.router.replace('/admin/games');
  }

  render() {
    return (
      <section className="AGameFeatured">
        <section className="AGameFeatured__wrapper">
          <AdminTabs {...this.props} />
          <section className="AGameFeatured__content">
            <h2 className="AGameFeatured__title">FEATURED GAMES</h2>
            <div className="AGameFeatured__actions">
              <Link
                to="/admin/games"
                activeClassName="active"
                href="/admin/games"
              >
                <button>Back</button>
              </Link>
            </div>
            <AdminGameFeaturedForm
              onSubmit={this.onSubmit.bind(this)}
              adminGameFeaturedForm={this.props.form.adminGameFeaturedForm}
              games={this.props.admin.games.data}
            />
          </section>
        </section>
      </section>
    );
  }
}

GameFeatured.propTypes = {

};

export default GameFeatured;
