import React from "react";
import { Link } from "react-router";
import "./MobileMenu.scss";

function MobileMenu({
  authenticated,
  closeMenu,
  fadingOut,
  isBrands,
  isBrandUser,
  isAdmin,
  isClientPortal,
  visible,
}) {
  if (!visible) return null;
  const Item = ({ linkTo, onlyActiveOnIndex, text }) => (
    <li>
      <Link
        activeClassName='active'
        onClick={closeMenu}
        onlyActiveOnIndex={onlyActiveOnIndex}
        to={linkTo}>
        {text}
      </Link>
    </li>
  );

  const AboutUs = <Item linkTo='/about-us' text='About Us' />;
  // const BrandServices = <Item linkTo='/' text='Brand Services' />;
  const Brands = <Item linkTo='/onboard' text='Brands' />;
  // const Contact = <Item linkTo='/#contact-us' text='Contact' />;
  const Creators = <Item linkTo='/sign-up' text='Creators' />;
  const Dashboard = <Item linkTo='/portal?component=default&tab=home' text='Dashboard' />;
  const FAQ = <Item linkTo='/faqs' text='FAQ' />;
  const Home = <Item linkTo='/' onlyActiveOnIndex text='Home' />;
  // const Login = <Item linkTo='/sign-in' text='Login' />;
  const Logout = <Item linkTo='/logout' text='Logout' />;
  const MyAccount = <Item linkTo='/my-account' text='My Account' />;
  const MyQuests = <Item linkTo='portal/?component=default&tab=quests' text='My Sponsorships' />;
  const Onboard = <Item linkTo='/onboard' text='Company' />;
  const Quests = <Item linkTo='/quests' text='Noiz Quests' />;
  // const Register = <Item linkTo='/sign-up' text='Register' />;
  const StreamWithUs = <Item linkTo='/stream-with-us' text='Stream With Us' />;

  const withFadingOut = className => `${className} ${fadingOut ? "fading-out" : ""}`;
  const mainClasses = withFadingOut("main");
  const isBrandOrAdmin = isBrands || isAdmin;
  const NoAuth = (
    <>
      <ul className={mainClasses}>
        {!isClientPortal && Home}
        {!isClientPortal && !isBrands && (
          <>
            {StreamWithUs}
            {AboutUs}
            {Onboard}
          </>
        )}
      </ul>
      <ul className={withFadingOut("bottom")}>
        {!isClientPortal && !isBrands && (
          <>
            {/* {Login}
            {Register} */}
            {Brands}
            {Creators}
          </>
        )}
      </ul>
    </>
  );

  const Auth = (
    <>
      <ul className={mainClasses}>
        {isBrandOrAdmin && Dashboard}
        {isBrandOrAdmin && MyQuests}
        {!isBrandUser && Quests}
        {Brands}
      </ul>
      <ul className={withFadingOut("bottom auth")}>
        {!isBrandUser && MyAccount}
        {!isBrandUser && FAQ}
        {Logout}
      </ul>
    </>
  );

  return (
    <nav className={withFadingOut("mobile-menu")}>
      {authenticated ? Auth : NoAuth}
    </nav>
  );
}

export default MobileMenu;
