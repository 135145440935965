import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import ReviewItems from './ReviewItems';

import './ReviewApplicantsDeliverables.scss';

const ReviewApplicantsDeliverables = (props) => {
  const [reviewPage, setReviewPage] = useState('Applicants');
  const [applicants, setApplicants] = useState([]);
  // const [deliverables, setDeliverables] = useState([]);

  useEffect(() => {
    setApplicants(props.applicants);
    // setDeliverables(props.deliverables);
  }, []);

  /* useEffect(() => {
    setApplicants(props.applicants);
    setDeliverables(props.deliverables);
  }, [props.applicants, props.deliverables]); */
  useEffect(() => {
    setApplicants(props.applicants);
    // setDeliverables(props.deliverables);
  }, [props.applicants]);

  const removeItem = (type) => {
    if (type.toLowerCase() === 'applicants') {
      return ({ questId, userId }) => setApplicants(prevState => [...prevState.filter(item => item.questId !== questId || (item.questId === questId && item.userId !== userId))]);
    }

    /* return ({ requirementId, userQuestId }) => setDeliverables(prevState => [
      ...prevState.filter(item => item.requirementId !== requirementId || (item.requirementId === requirementId && item.userQuestId !== userQuestId))]); */
  };

  return (
    <div className="ReviewApplicantsDeliverables">
      <div className="ReviewApplicantsDeliverables_Header">
        <div className="review">
          Review
        </div>
        <div
          className={classnames(
            "ReviewApplicantsDeliverables_Header_Tab",
            reviewPage === 'Applicants' ? 'ActiveTab' : 'InactiveTab'
          )}
          onClick={() => setReviewPage('Applicants')}>
          Applicants
        </div>
        {/* <div
          className={classnames(
            "ReviewApplicantsDeliverables_Header_Tab",
            reviewPage === 'Deliverables' ? 'ActiveTab' : 'InactiveTab'
          )}
          onClick={() => setReviewPage('Deliverables')}>
          Deliverables
        </div> */}
      </div>
      {reviewPage === 'Applicants' && (
        <ReviewItems
          type="Applicants"
          items={applicants}
          removeItem={removeItem}
        />
      )}
      {/* {reviewPage === 'Deliverables' && (
        <ReviewItems
          type="Deliverables"
          items={deliverables}
          removeItem={removeItem}
        />
      )} */}
    </div>
  )
};

export default ReviewApplicantsDeliverables;
