import React from 'react';

import locationIcon from '../../global/images/icons/location-icon.svg';

const Countries = (props) => {
  const {
    countries = ['United States'],
  } = props;

  return (
    <div className="eligibilityItem">
      <img src={locationIcon} alt="feedback" />
      <span className="label">
        {countries.slice(0, -1).join(', ')}{countries.length > 2 && ','}{countries.length > 1 && ' or '}{countries.slice(-1)}
      </span>
    </div>
  );
};

export default Countries;
