/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { isEmpty, find } from 'lodash';
import { CSVLink } from 'react-csv';
import { simpleNotification } from '../../../../utils/notifications';
import './TrackedLinks.scss';
import { campaignLinksReport } from '../../../../utils/downloadReports';
import { CampaignSelect, LinkTabs, SelectAllCheckBox, blueSwitch } from './LinkViews';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BlueSwitch, MasterLink, RedSwitch, RedButton, MSendIcon, MDeleteIcon, DownloadIcon } from './link-styles';
import LinkTable from './LinkTable';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  waitlistHeaders,
  usersHeaders,
  usersMap,
  waitlistMap,
  availableHeaders,
  availableMap,
  distributedHeaders,
  distributedMap,
} from './tableHeaders';
import Tooltip from '@material-ui/core/Tooltip';
import { isValidAddress } from '../../../../utils/functions';


class TrackedLinks extends React.Component {
  // switch for states between master link or upload
  // campaign selector drop down
  //
  constructor(props) {
    super(props);
    this.props = props;
    this.dropzoneRef = null;
    this.state = {
      dropzoneActive: false,
      file: null,
      customLinks: false,
      tab: 0,
      selectedCampaign: null,
      masterLink: null,
      rebrandedLinks: false,
      trackedLinksEnabled: false,
      distributeLinksCheck: false,
      deleteLinksCheck: false,
      linksToDelete: [],
      selectAllLinks: null,
      tabList: [
        { name: 'Available', data: 'available' },
        { name: 'Distributed', data: 'distributed' },
        { name: 'Users In Campaign', data: 'users' },
        { name: 'Waitlist', data: 'waitlist' },
      ],
    };
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    this.props.getCCCampaignListByGame(gameId, token);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { token } = this.props.auth;
    const { selectedCampaign: sc } = this.state;
    const id = sc && sc.id ? sc.id : '';
    if (
      this.props.commandCenter.linkAssignment.isLoading &&
    !nextProps.commandCenter.linkAssignment.isLoading
    ) {
      if (nextProps.commandCenter.linkAssignment.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Error Assigning Links',
          message: JSON.stringify(nextProps.commandCenter.linkAssignment.errors),
        });
      } else {
        simpleNotification({
          level: 'success',
          title: 'Success',
          message: 'Links Distributed',
        });
        this.props.getLinksDashboard(id, token);
        this.setState({ distributeLinksCheck: !this.state.distributeLinksCheck });
      }
    }

    // Handle key upload success/error
    if (this.props.commandCenter.uploadLinks.isLoading && !nextProps.commandCenter.uploadLinks.isLoading) {
      if (!nextProps.commandCenter.uploadLinks.errors.hasError) {
        simpleNotification({
          level: 'success',
          title: 'Success',
          message: 'The links have been successfully uploaded to the Campaign.',
        });
      } else {
        simpleNotification({
          level: 'error',
          title: 'error',
          message: 'The links did not upload successfully.',
        });
      }
      this.props.getLinksDashboard(id, token);
    }
    if (this.props.commandCenter.campaign.isUpdating && !nextProps.commandCenter.campaign.isLoading) {
      if (!nextProps.commandCenter.campaign.errors.hasError) {
        simpleNotification({ level: 'success', title: 'Success', message: 'Campaign updated successfully' });
        const { gameId } = this.props.router.params;
        this.props.getCCCampaignListByGame(gameId, token);
      } else {
        simpleNotification({ level: 'error', title: 'error', message: 'Something went wrong updating the campaign' });
      }
    }
    if (this.props.commandCenter.deletedLinks.isLoading && !nextProps.commandCenter.campaign.isLoading) {
      if (!nextProps.commandCenter.campaign.errors.hasError) {
        simpleNotification({ level: 'success', title: 'Success', message: 'Links Deleted Successfully' });
      } else {
        simpleNotification({ level: 'error', title: 'Failure', message: 'Links Failed To Delete' });
      }
      this.props.getLinksDashboard(id, token);
      this.setState({ linksToDelete: [], deleteLinksCheck: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const oldCampaignId = prevState.selectedCampaign ? prevState.selectedCampaign.id : false;
    const campaignId = this.state.selectedCampaign ? this.state.selectedCampaign.id : false;


    if (this.state.selectAllLinks) {
      this.setState({ selectAllLinks: null });
    }

    if (campaignId && oldCampaignId !== campaignId) {
      const newState = this.props.campaignList.data.find(item => item.id === campaignId);
      const customLinks = !!newState.customLinks;
      const trackedLinksEnabled = !!newState.trackedLinksEnabled;
      const rebrandedLinks = !!newState.rebrandedLinks;
      const { masterLink } = newState;
      this.setState({
        customLinks, trackedLinksEnabled, rebrandedLinks, masterLink,
      });
    }

    if (!oldCampaignId && campaignId) {
      const newState = this.props.campaignList.data.find(item => item.id === campaignId);
      const customLinks = !!newState.customLinks;
      const trackedLinksEnabled = !!newState.trackedLinksEnabled;
      const rebrandedLinks = !!newState.rebrandedLinks;
      const { masterLink } = newState;

      this.setState({
        customLinks, trackedLinksEnabled, rebrandedLinks, masterLink,
      });
    }
  }

  // static getDerivedStateFromProps(props, state) {


  // }


  onDragEnter = () => {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave = () => {
    this.setState({ dropzoneActive: false });
  }

  onDrop = (files) => {
    // Step 1. Files were selected or droped
    this.setState({ dropzoneActive: false, file: files[0] });
  }

  setTab = (n) => {
    this.setState({ tab: n, linksToDelete: [] });
  }

 deleteLinks = () => {
   const { linksToDelete, tab: index, tabList } = this.state;
   const tab = tabList[index].data;
   let assignedToDelete = [];
   let unassignedToDelete = [];
   if (tab === 'available') {
     unassignedToDelete = [...linksToDelete];
   } else if (tab === 'distributed') {
     assignedToDelete = [...linksToDelete];
   }
   const { token } = this.props.auth;
   this.props.deleteLinks(assignedToDelete, unassignedToDelete, token);
   this.setState({ linksToDelete: [] });
 }
 selectAllCustom = (yesNo) => {
   const { data: linksDashboard } = this.props.linksDashboard;
   const { tab: index, tabList } = this.state;
   const tab = tabList[index].data;
   const correctLinks = tab === 'available' ? 'unassigned' : tab === 'distributed' ? 'distributed' : null;
   if (correctLinks) {
     const all = linksDashboard[correctLinks];
     if (!yesNo) {
       this.setState({ selectAllLinks: { selected: false }, linksToDelete: [] });
     } else if (yesNo) {
       this.setState({ selectAllLinks: { selected: true }, linksToDelete: all });
     }
   }
 };

 addRemoveDeleteCustom = (item, addRem) => {
   const { linksToDelete } = this.state;
   if (addRem) {
     this.setState({ linksToDelete: [...this.state.linksToDelete, item] });
   } else if (!addRem) {
     const index = linksToDelete.indexOf(item);
     linksToDelete.splice(index, 1);
     this.setState({ linksToDelete: [...linksToDelete] });
   }
 };

 saveCampaign() {
   const { token } = this.props.auth;
   const {
     selectedCampaign: sc, masterLink, customLinks, trackedLinksEnabled, rebrandedLinks,
   } = this.state;
   const id = sc && sc.id ? sc.id : '';
   const masterCheck = masterLink && isValidAddress(masterLink);
   if (!customLinks && !masterCheck) {
     simpleNotification({ level: 'error', title: 'Error: Masterlink', message: 'Add or Enter Valid MasterLink' });
   } else {
     const update = {
       ...sc,
       customLinks,
       trackedLinksEnabled,
       rebrandedLinks,
     };
     delete update.masterLink;
     if (masterLink) {
       update.masterLink = masterLink.replace(/\s/g, '');
     }
     delete update.brandUsers;
     delete update.quests;
     this.props.updateCCCampaign(id, update, token);
   }
 }

 uploadLinks = () => {
   const { token } = this.props.auth;
   const formData = new FormData();
   formData.append('file', this.state.file);
   this.props.uploadLinks(formData, this.state.selectedCampaign.id, token);
 }

 updateMasterLink = (e) => {
   this.setState({ masterLink: e.target.value });
 }

 selectCampaign = (e) => {
   const { token } = this.props.auth;

   const id = e && e.id ? e.id : '';
   this.props.getLinksDashboard(id, token);
   this.setState({ selectedCampaign: e });
 }

 enableSave = () => {
   const {
     selectedCampaign: sc, masterLink, customLinks, trackedLinksEnabled, rebrandedLinks,
   } = this.state;
   const id = sc && sc.id ? sc.id : '';

   if (id) {
     const mChange = masterLink !== sc.masterLink;
     const cChange = customLinks !== sc.customLinks;
     const eChange = trackedLinksEnabled !== sc.trackedLinksEnabled;
     const rChange = rebrandedLinks !== sc.rebrandedLinks;
     if (mChange || cChange || eChange || rChange) {
       return false;
     }
     return true;
   }
   return true;
 }

 async distributeLinks() {
   const { token } = this.props.auth;
   const { selectedCampaign: sc } = this.state;
   const id = sc && sc.id ? sc.id : '';
   const customLinks = sc && sc.customLinks ? sc.customLinks : null;
   const trackedLinksEnabled = sc && sc.trackedLinksEnabled ? sc.trackedLinksEnabled : null;
   const masterLink = sc && sc.masterLink ? sc.masterLink : null;
   if (trackedLinksEnabled && customLinks) {
     await this.props.assignCustomLinks(id, token);
   } else if (trackedLinksEnabled && !customLinks && !masterLink) {
     simpleNotification({ level: 'error', title: 'No Link', message: 'Please add a master link' });
   } else if (trackedLinksEnabled && !customLinks && masterLink) {
     if (isValidAddress(masterLink)) {
       await this.props.assignMasterLinks(id, token);
     } else {
       simpleNotification({ level: 'error', title: 'Invalid Address', message: 'Please enter a valid url' });
     }
   }
 }

 calcLength = () => {
   const { data: linksDashboard } = this.props.linksDashboard;
   const availLength = linksDashboard && linksDashboard.unassigned ? linksDashboard.unassigned.length : 0;
   const assignedLength = linksDashboard && linksDashboard.distributed ? linksDashboard.distributed.length : 0;
   return availLength + assignedLength;
 }

 render() {
   const deletedLinksLoading = this.props.commandCenter.deletedLinks.isLoading;
   const distLinksLoading = this.props.commandCenter.linkAssignment.isLoading;
   const { isLoading } = this.props.commandCenter.linksDashboard;
   const weAreLoading = deletedLinksLoading || distLinksLoading || isLoading;
   const { gameId } = this.props.router.params;
   const { data: games } = this.props.commandCenter.games;
   const currentGame = find(games, game => game.id === gameId);
   const {
     dropzoneActive, tabList, rebrandedLinks, trackedLinksEnabled,
   } = this.state;
   const { data: linksDashboard } = this.props.linksDashboard;
   const { dataCSV, headersCSV } = campaignLinksReport(linksDashboard && linksDashboard.allCustom ? linksDashboard.allCustom : [], linksDashboard.distributed ? linksDashboard.distributed : [], this.state.tab);
   const { customLinks, selectedCampaign } = this.state;
   const peopleNeedLinks =
   linksDashboard && linksDashboard.waitlist ? linksDashboard.waitlist.length > 0 : false;
   const dbMasterLink = selectedCampaign && selectedCampaign.masterLink ? selectedCampaign.masterLink : null;
   const enableSave = this.enableSave();
   const campaignList = this.props.campaignList.isLoading ? [] : this.props.campaignList.data;
   const linkTypeToggle = blueSwitch(
     this.state.customLinks,
     () => this.setState({ customLinks: !this.state.customLinks }),
     'Custom',
   );
   const trackedLinksToggle = blueSwitch(
     this.state.trackedLinksEnabled,
     () => this.setState({ trackedLinksEnabled: !this.state.trackedLinksEnabled }),
     'Links',
   );
   const rebrandedLinksToggle = blueSwitch(
     this.state.rebrandedLinks,
     () => this.setState({ rebrandedLinks: !this.state.rebrandedLinks }),
     'Rebrand',
   );
   const showControls = selectedCampaign && trackedLinksEnabled;
   return (
     <section className="GameKeys">
       {/* {JSON.stringify(this.state.linksToDelete)} */}
       <Grid
         container
         direction="row"
         justify="flex-start"
         alignItems="center"
         wrap="wrap"
         spacing={3}
         style={{ marginBottom: '10px' }}
       >
         <Grid style={{ margin: '10px 0px 5px 10px' }}>
           <CampaignSelect
             campaigns={campaignList}
             selected={this.state.selectedCampaign}
             selectCampaign={this.selectCampaign.bind(this)}
           />
         </Grid>

         {selectedCampaign && (
         <Grid style={{ paddingLeft: '10px' }}>

           <Button
             variant="contained"
             color="primary"
             disabled={enableSave}
             onClick={this.saveCampaign.bind(this)}
           >
             Save
           </Button>
         </Grid>
     )}
       </Grid>
       {selectedCampaign && (
       <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2} style={{ margin: '10px 0px' }}>

         <FormControlLabel
           control={trackedLinksToggle}
           label="Links Enabled"
           style={{ color: 'white' }}
         />

         <FormControlLabel
           disabled={!trackedLinksEnabled}
           control={linkTypeToggle}
           label={customLinks ? 'Custom Link Distribution Enabled' : 'Master Link Distribution Enabled'}
           style={{ color: 'white' }}
         />
         <FormControlLabel
           disabled={!trackedLinksEnabled}
           control={rebrandedLinksToggle}
           label={
        rebrandedLinks ? 'Links Rebranded Via Rebrandly' : 'Links Distributed Without Masking Or Tracking'
       }
           style={{ color: 'white' }}
         />
       </Grid>
    )}

       {showControls && (
       <section>
         <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
           <Grid
             container
             direction="column"
             justify="flex-start"
             alignItems="flex-start"
             style={{
         border: '1px solid white',
         padding: '8px',
         width: '250px',
         borderRadius: '8px',
        }}
           >
             <Grid style={{ padding: '10px', fontSize: '1.2em' }}>Master Distribution Link</Grid>
             <MasterLink update={this.updateMasterLink} existingLink={dbMasterLink} />
           </Grid>
         </Grid>
         <Grid>
           <section className="GameKeys__uploader">
             <h4 className="GameKeys__uploader--title" style={{ marginTop: '15px' }}>
               Link Uploader
             </h4>
             <a className="GameKeys__uploader--sample-file" target="_blank" href="/csv/LINKS_EXAMPLE.csv">
               Download a sample template.
             </a>
             <div>
               <Dropzone
                 ref={(node) => {
           this.dropzoneRef = node;
          }}
                 disableClick={this.state.disableClick}
                 className="Dropzone"
                 onDrop={this.onDrop.bind(this)}
                 onDragEnter={this.onDragEnter.bind(this)}
                 onDragLeave={this.onDragLeave.bind(this)}
                 disablePreview={false}
                 accept=".csv"
                 multiple={false}
                 name="file"
                 inputProps={{ id: 'file' }}
               >
                 {dropzoneActive && <div className="Dropzone__DropLayer">Drop your CSV here...</div>}
                 <div className="fake-input">{this.state.file ? this.state.file.name : ''}</div>
                 <button
                   className="FilePickButton"
                   type="button"
                   onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.dropzoneRef.open();
           }}
                 >
                   BROWSE
                 </button>
               </Dropzone>
             </div>
             <p className="GameKeys__uploader--help">File must be a .CSV (i.e. Links.CSV)</p>
             {this.state.file ? (
               <button className="GameKeys__uploader--btn CTAButton__purple" onClick={this.uploadLinks.bind(this)}>
                 Upload File
               </button>
        ) : (
          <button className="GameKeys__uploader--btn CTAButton__purple disabled">Upload File</button>
        )}
           </section>
         </Grid>

         {isLoading ? (
           <section className="ToggleTable__table-wrapper">
             <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
           </section>
      ) : (
        <section className="GameKeys__crud">
          <div className="GameKeys__crud-title-bar">
            <h2 className="GameKeys__crud-title">
              TOTAL LINKS IN CAMPAIGN: <span> {this.calcLength()}</span>
            </h2>
            <CSVLink
              data={dataCSV}
              headers={headersCSV}
              style={{ marginLeft: 'auto' }}
              filename={
           !isEmpty(currentGame) ? `${currentGame.name.split(' ').join('')}-Links.csv` : 'Links.csv'
          }
            >
              <Tooltip title="Download Link CSV" >
                <Button style={{ padding: '0px 0px 3px 0px' }} color="primary"><DownloadIcon /></Button>
              </Tooltip>
            </CSVLink>
            <SelectAllCheckBox callBack={this.selectAllCustom} tab={this.state.tab} />

          </div>

          <LinkTabs
            tabList={tabList}
            setTab={this.setTab}
            tableData={{
          users: linksDashboard.users ? linksDashboard.users : [],
          waitlist: linksDashboard.waitlist ? linksDashboard.waitlist : [],
          available: linksDashboard.unassigned ? linksDashboard.unassigned : [],
          distributed: linksDashboard.distributed ? linksDashboard.distributed : [],
         }}
            tableHeaders={tabList}
            headerTemplates={{
          users: usersHeaders,
          waitlist: waitlistHeaders,
          available: availableHeaders,
          distributed: distributedHeaders,
         }}
            rowTemplates={{
          users: usersMap,
          waitlist: waitlistMap,
          available: availableMap,
          distributed: distributedMap,
         }}
            Table={LinkTable}
            passUp={this.addRemoveDeleteCustom}
            selectAll={this.state.selectAllLinks}
          />
        </section>
      )}
         { weAreLoading ? (<CircularProgress style={{ margin: '30px' }} />) : (<section>
           <Grid
             container
             direction="row"
             justify="space-between"
             alignItems="flex-start"
             style={{ marginTop: '20px', paddingLeft: '20px' }}
             spacing={3}
           >
             <Grid>
               <span style={{ color: !peopleNeedLinks ? 'grey' : 'white' }}>Confirm Distribute</span>
               <BlueSwitch
                 disabled={!peopleNeedLinks || !enableSave}
                 checked={this.state.distributeLinksCheck}
                 onChange={() =>
            this.setState({
             distributeLinksCheck: !this.state.distributeLinksCheck,
            })
           }
                 name="Enable Link Distribution"
                 inputProps={{ 'aria-label': 'primary checkbox' }}
               />
               <Tooltip title="Rebrandly Rate Limit 10/sec - big branded distributions take a long time" >
                 <Button
                   variant="contained"
                   color="primary"
                   disabled={!this.state.distributeLinksCheck}
                   onClick={this.distributeLinks.bind(this)}
                   startIcon={<MSendIcon />}
                 >
                   Distribute Links
                 </Button>
               </Tooltip>
             </Grid>
             <Grid>
               <span style={{ color: !this.state.linksToDelete.length ? 'grey' : 'white' }}>Confirm Delete</span>
               <RedSwitch
                 disabled={!this.state.linksToDelete.length}
                 checked={this.state.deleteLinksCheck}
                 onChange={() =>
            this.setState({
             deleteLinksCheck: !this.state.deleteLinksCheck,
            })
           }
                 name="Enable Link Deletion"
                 inputProps={{ 'aria-label': 'primary checkbox' }}
               />
               <Tooltip title="Rebrandly Rate Limit 10/sec - big branded deletions take a long time" >
                 <RedButton
                   variant="contained"
                   disabled={!this.state.deleteLinksCheck}
                   onClick={this.deleteLinks}
                   startIcon={<MDeleteIcon />}
                 >
                   Delete Links
                 </RedButton>
               </Tooltip>
             </Grid>
           </Grid>
                                                                               </section>) }
       </section>
    )}
     </section>
   );
 }
}
const mapStateToProps = state => ({
  campaignList: state.commandCenter.campaigns,
  linksDashboard: state.commandCenter.linksDashboard,
  deletedLinks: state.commandCenter.deletedLinks,
});
export default connect(
    mapStateToProps,
    null,
    null,
    {
      forwardRef: true
    })(TrackedLinks);
