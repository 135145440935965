import us from '../components/images/countryEmojis/us.png';
import gb from '../components/images/countryEmojis/gb.png';
import ca from '../components/images/countryEmojis/ca.png';
import fr from '../components/images/countryEmojis/fr.png';
import de from '../components/images/countryEmojis/de.png';
import br from '../components/images/countryEmojis/br.png';
import au from '../components/images/countryEmojis/au.png';
import es from '../components/images/countryEmojis/es.png';
import mx from '../components/images/countryEmojis/mx.png';
import ar from '../components/images/countryEmojis/ar.png';
import ph from '../components/images/countryEmojis/ph.png';
import nl from '../components/images/countryEmojis/nl.png';
import be from '../components/images/countryEmojis/be.png';
import no from '../components/images/countryEmojis/no.png';
import ru from '../components/images/countryEmojis/ru.png';
import pt from '../components/images/countryEmojis/pt.png';
import cl from '../components/images/countryEmojis/cl.png';
import pe from '../components/images/countryEmojis/pe.png';
import dk from '../components/images/countryEmojis/dk.png';
import nz from '../components/images/countryEmojis/nz.png';

import { getCode } from './countriesEmojis.js';

export const getEmojiImage = (country) => {
  const code = getCode(country);

  switch (code) {
    case 'US':
      return us;
    case 'GB':
      return gb;
    case 'CA':
      return ca;
    case 'FR':
      return fr;
    case 'DE':
      return de;
    case 'BR':
      return br;
    case 'AU':
      return au;
    case 'ES':
      return es;
    case 'MX':
      return mx;
    case 'AR':
      return ar;
    case 'PH':
      return ph;
    case 'NL':
      return nl;
    case 'BE':
      return be;
    case 'NO':
      return no;
    case 'RU':
      return ru;
    case 'PT':
      return pt;
    case 'CL':
      return cl;
    case 'PE':
      return pe;
    case 'DK':
      return dk;
    case 'NZ':
      return nz;
    default:
      return false;
  }
};
