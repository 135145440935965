import React from 'react';
import './BrandSafetyModal.scss';
import './ReputationSystemModal.scss';
import classNames from 'classnames';
import {
  without,
} from 'lodash';
import closeButton from '../../../../components/images/close-button.svg';

class BrandSafetyModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { content } = this.props;
    this.state = {
      comments: content && content.comments ? content.comments : '',
      selected: content && content.selected ? content.selected : [],
    };
  }

  handleSelect(value) {
    const { selected } = this.state;
    if (selected.indexOf(value) === -1) {
      this.setState({
        selected: [
          ...selected,
          value,
        ],
      });
    } else {
      this.setState({
        selected: [
          ...without(selected, value),
        ],
      });
    }
  }

  onChange(event) {
    this.handleSelect(event.target.value);
  }

  handleCommentsChange = (event) => {
    this.setState({
      comments: event.target.value,
    });
  }

  validate = () => {
    const { comments, selected } = this.state;
    this.props.onSubmit(comments, selected);
  }

  render() {
    const { isEditing, username } = this.props;
    const { comments, selected } = this.state;
    const options = [
      { label: 'controversy', value: 'controversy' },
      { label: 'fraud', value: 'fraud' },
      { label: 'negativity', value: 'negativity' },
      { label: 'substances', value: 'substances' },
      { label: 'criminality', value: 'criminality' },
      { label: 'language', value: 'language' },
      { label: 'sexuality', value: 'sexuality' },
      { label: 'violence', value: 'violence' },
    ];

    return (
      <React.Fragment>
        <div className="NoizModalOverlay" />
        <div className="ReputationModal">
          { isEditing ? (<img alt="close-button" className="close" src={closeButton} onClick={this.props.onClose.bind(this)} />) : null }
          <h2> Were there any brand safety violations by <span className="username">{username}</span>? </h2>

          <span className="label">Standards</span>
          <div className="optionsListBrand">
            { options.map(opt => (
              <span onClick={this.handleSelect.bind(this, opt.value)} className={classNames('option', { selected: selected.indexOf(opt.value) > -1 })}>
                <input checked={selected.indexOf(opt.value) > -1} onChange={this.onChange.bind(this)} value={opt.value} type="checkbox" /> { opt.label }
              </span>
              )) }
          </div>

          <span className="label">Comments + Timecode</span>
          <textarea name="comments" id="comments" value={comments} onChange={this.handleCommentsChange} />
          <span className="note">*Note: influencer will not see this rating/comment</span>

          <div className="buttons brand-safety">
            <button className="CTAButton__white" onClick={this.props.onBack.bind(this)}> Back </button>
            <button className="CTAButton__purple" onClick={this.validate.bind(this)}> Submit </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

BrandSafetyModal.propTypes = {
};

export default BrandSafetyModal;
