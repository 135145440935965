import React from 'react';
// import PropTypes from 'prop-types';
// import moment from 'moment';
import './MyAccountHome.scss';
// import { simpleNotification } from '../../../utils/notifications';


class MyAccountHome extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {

    };
  }

  // componentDidMount() {
  //
  // }
  //
  // componentWillReceiveProps(nextProps) {
  //
  // }

  render() {
    // const { user } = this.props.auth;
    return (
      <section className="MyAccountHome">
        <section className="MyAccountHome__main content-block two-column-container">
          <section className="MyAccountHome__main__profile column">
            <div className="profile-image" />
            <div className="profile-name">
              <h1>heyjaq</h1>
              <label>twitch.tv/heyjaq</label>
            </div>
          </section>
          <section className="MyAccountHome__main__account column">
            <h2>ACCOUNT<br />BALANCE</h2>
            <h3>$30.00</h3>
          </section>
        </section>

        <section className="MyAccountHome__bank two-column-container">
          <section className="back-information column">
            <h2 className="content-block-header">PAYMENT INFORMATION</h2>
            <section className="content-block" />
          </section>

          <section className="account-information column">
            <h2 className="content-block-header">ACCOUNT INFORMATION</h2>
            <section className="content-block">
              <div className="account-information__wrapper">
                <label>Name: Jackson Smith</label>
                <label>DOB: XX/XX/XXXX</label>
                <label>Country: USA</label>
              </div>
            </section>
          </section>

        </section>

        <section className="MyAccountHome__payment">
          <h2 className="content-block-header">PAYMENT SUMMARY</h2>
          <section className="content-block">
            <div className="tabs-container" />
            <section className="payment__table flex-table">
              <div className="row">
                <div className="table-cell headline">Game</div>
                <div className="table-cell headline">Avg. Viewers during Bounty</div>
                <div className="table-cell headline">Earned</div>
              </div>

              <div className="row">
                <div className="table-cell">Outlaws of the old West</div>
                <div className="table-cell">30</div>
                <div className="table-cell"><span className="green-plus">+</span>$30.00</div>
              </div>

              <div className="row">
                <div className="table-cell">PixARK</div>
                <div className="table-cell">No Data Available</div>
                <div className="table-cell"><span className="green-plus">+</span>$0.00</div>
              </div>

            </section>
          </section>
        </section>
      </section>
    );
  }
}

MyAccountHome.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  //   location: PropTypes.shape({
  //     pathname: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // auth: PropTypes.shape({
  //   isLoading: PropTypes.bool.isRequired,
  //   isUpdating: PropTypes.func.isRequired,
  //   token: PropTypes.string.isRequired,
  //   user: PropTypes.shape({
  //
  //   }).isRequired,
  //   errors: PropTypes.shape({
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // user: PropTypes.shape({
  //   currentAgreement: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  //   currentScheduledBroadcast: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // assignEditUserInfo: PropTypes.func.isRequired,
  // updateUserInfo: PropTypes.func.isRequired,
};

export default MyAccountHome;
