import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

// import PopOver from './PopOver';
import StatusButton from './StatusButton';

import { localizeIncorrectDate } from '../../utils/dateTime';
import { getPaymentAmount, rounding, textTruncate } from '../../utils/functions';

import './QuestSelector.scss';

const format = 'MMM. DD, YYYY';
const dateRange = (start, end) => `${localizeIncorrectDate(start).format(format)} – ${localizeIncorrectDate(end).format(format)}`;
const toMenuItem = ({
  endDateTime, id, platforms, startDateTime,
}) => (
  <MenuItem
    key={uuidv4()}
    name={startDateTime}
    value={id.toString()}
  >
    {`${dateRange(startDateTime, endDateTime)} (${(!!platforms.length && platforms[0].baseService) || 'All'})`}
  </MenuItem>
);

const QuestSelector = (props) => {
  const {
    router,
    location,
    currentQuest = {},
    handleApply,
    navStickyBottomPos = false,
    questDates,
    status,
    sticky,
    title,
    user,
    userQuest = {},
    errors: { message = '' },
    userQuestIsLoading = false,
  } = props;

  const {
    id: questId = '',
    memberTiers = [],
    platforms = [],
    slug = '',
    viewerTiers = [],
  } = currentQuest;

  const getSlug = (id) => {
    const q = questDates.find(qu => qu.id === id);
    return q.slug ? q.slug : slug;
  };

  const [dates, setDates] = useState('');
  const [questPlatform, setQuestPlatform] = useState('');

  useEffect(() => {
    setDates(questId.toString());
    setQuestPlatform(platforms);
  }, [currentQuest]);

  const handleDateEvent = (e) => {
    const id = e.target.value;
    if (id !== questId.toString()) {
      const newSlug = getSlug(id);
      window.location.assign(`/quests/${newSlug}`);
    }
  };

  const classes = classNames({
    QuestSelector: true,
    status: !!status,
    sticky,
    stop: !!navStickyBottomPos,
  });

  const questDateOptions = questDates.map(toMenuItem);
  const ineligibleStatuses = ['accv-min', 'no-twitch'];
  const { paymentAmount = 0, goal = '', goalEnabled = false } = getPaymentAmount(memberTiers || [], user, viewerTiers || [], userQuest || {});
  const eligible = status ? !ineligibleStatuses.includes(status) : paymentAmount !== -2;
  const freeKey = paymentAmount === -1;
  const points = paymentAmount === -2;
  let paymentText = `$${rounding(paymentAmount)}`;
  let disclaimer = 'This is an estimate and may be adjusted prior to acceptance.';

  if (paymentAmount === Number.MIN_SAFE_INTEGER || !eligible) {
    paymentText = '--';
    disclaimer = null;
  } else if (freeKey) {
    paymentText = 'Free Key';
    disclaimer = null;
  } else if (points) {
    paymentText = 'Points';
  } else if (paymentAmount > 0) {
    disclaimer = null;
  }

  const PaymentRender = () => {
    if (!!disclaimer) {
      return (
        <Tooltip placement="top" title={disclaimer}>
          <span className={`payment-amount disclaimer ${freeKey || points ? 'free-key' : ''}`}>
            {paymentText}&nbsp;<i className="fa fa-exclamation-circle" />
          </span >
        </Tooltip>
      )
    }

    return (
      <span className={`payment-amount ${freeKey || points ? 'free-key' : ''}`}>
        {paymentText}
      </span>
    )
  };

  return (
    <React.Fragment>
      <div className={classes} style={{ top: navStickyBottomPos || false }}>
        <Tooltip title={title} placement="top">
          <div className="title-quest-text">
            {textTruncate(title, 33, '...')}
          </div>
        </Tooltip>
        <div className="paymentBar">
          <PaymentRender />
        </div>
        {goalEnabled && goal && <div className="quest-goal-message">{goal}</div>}

        <div className="selections">
          <div className="questDates">
            <h3>Quest Dates</h3>
            <Select
              name=""
              // id="quest-dates"
              className="input-select-corrections"
              value={dates}
              onChange={handleDateEvent}
              MenuProps={{
                disableScrollLock: true,
              }}
              placeholder="Quest Dates"
            // IconComponent={() => <i className="fa fa-chevron-down" />}
            >
              {questDateOptions}
            </Select>
          </div>
          {dates &&
            <div className="gamingPlatform">
              <h3>Platform or Service</h3>
              <h2>{(!!questPlatform.length && questPlatform[0].platform) || 'All'}</h2>
            </div>
          }
        </div>
        {!!message && <div className="quest-rejection-message">{message}</div>}
        <div className="buttonsBarQS">
          <StatusButton
            quest={currentQuest}
            user={user}
            userQuest={userQuest}
            onApply={handleApply}
            userQuestIsLoading={userQuestIsLoading}
            router={router}
            location={location}
          />
          {/* <PopOver /> */}
        </div>
        <a
          className="help-button"
          href="https://noiz.freshdesk.com/support/home"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="fa fa-question-circle" />Need help?
        </a>
      </div>
    </React.Fragment>
  );
};

export default QuestSelector;
