import itpedia from './itpedia.mp4';
import kratzy76 from './kratzy76au.mp4';
import loserfruit from './loserfruit.mp4';
import mrMEOLA from './MrMEOLA.mp4';
import porta7511 from './porta7511.mp4';
import raeDancing from './rae-dancing.mp4';
import theRabble from './therabblewrangler.mp4';
import topictech from './topictech.mp4';

import itpedia_png from './itpedia-thumbnail@2x.jpg';
import kratzy76_png from './kratzy76au-thumbnail@2x.jpg';
import loserfruit_png from './loserfruit-thumbnail@2x.jpg';
import mrMEOLA_png from './MrMEOLA-thumbnail@2x.jpg';
import porta7511_png from './porta7511-thumbnail@2x.jpg';
import raeDancing_png from './rae-dancing-thumbnail@2x.jpg';
import theRabble_png from './therabblewrangler-thumbnail@2x.jpg';
import topictech_png from './topictech-thumbnail@2x.jpg';

const isMobile = window.innerWidth < 700;

export const pictureWall = [raeDancing_png, itpedia_png, kratzy76_png, loserfruit_png, porta7511_png, topictech_png, theRabble_png, mrMEOLA_png];

export const mobileVideoWall = [raeDancing, itpedia, kratzy76, loserfruit, topictech, theRabble];

export const videoWall = isMobile ? mobileVideoWall : [raeDancing, itpedia, kratzy76, loserfruit, porta7511, topictech, mrMEOLA, theRabble];
