import React from 'react';
import './UserDetailModule.scss';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import XboxIcon from '../icons/icon-xbox.svg';
import MobileIcon from '../icons/icon-android.svg';
import WindowsIcon from '../icons/icon-windows.svg';
import NintendoIcon from '../icons/icon-nintendo.svg';
import PlaystationIcon from '../icons/icon-playstation.svg';
import TwitchIcon from '../icons/icon-twitch.svg';
import { numberWithCommas } from '../../../../utils/functions';
import { getCompleteUsers } from '../queries.js';

const OptionSwitch = withStyles(theme => ({
  root: {
    width: 72,
    height: 36,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: '#3e424d',
    '&$checked': {
      transform: 'translateX(26px)',
      color: '#2c3239',
      '& + $track': {
        border: 'none',
        opacity: 1,
        backgroundImage: 'linear-gradient(to right, #49e1ca 0%, #3a74e0 100%)',
      },
    },
  },
  thumb: {
    width: 30,
    height: 30,
    boxShadow: 'none',
  },
  track: {
    border: '2px solid #2c3239',
    borderRadius: 36,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,0)',
  },
  checked: {},
}))(Switch);

class UserDetailModule extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      csvData: null,
      downloadModal: false,
      downloadToggles: [
        { id: 'name', title: 'Noiz Username', include: true },
        { id: 'firstName', title: 'First Name', include: true },
        { id: 'lastName', title: 'Last Name', include: true },
        { id: 'streamingPlatform', title: 'Streaming Platform', include: true },
        { id: 'gamingPlatform', title: 'Gaming Platform', include: true },
        { id: 'gender', title: 'Gender', include: true },
        { id: 'region', title: 'Country', include: true },
        { id: 'questHistory', title: 'Quest History', include: true },
        { id: 'email', title: 'Account Email', include: true },
        { id: 'accv', title: 'ACCV', include: true },
        { id: 'twitter', title: 'Twitter', include: true },
        { id: 'language', title: 'Language', include: true },
        { id: 'timezone', title: 'Time Zone', include: true },
        { id: 'paymentEmail', title: 'Payment Email', include: true },
      ],
    };
  }

  // Toggle download switch
  toggleDownloadOption(optionId) {
    const { downloadToggles } = this.state;
    const index = downloadToggles.findIndex(option => option.id === optionId);
    const toggle = downloadToggles[index];
    toggle.include = !toggle.include;
    const newToggles = downloadToggles;
    downloadToggles[index] = toggle;
    this.setState({ downloadToggles: newToggles });
  }

  getCSVHeaders() {
    const { downloadToggles } = this.state;
    const includeToggles = downloadToggles.filter(option => option.include);
    const headers = [];
    for (let i = 0; i < includeToggles.length; i++) {
      headers.push({ label: includeToggles[i].title, key: includeToggles[i].id });
    }

    return headers;
  }

  getCSVData = async () => {
    const { downloadToggles } = this.state;
    const { token, userData } = this.props;
    const data = [];
    const includeToggles = [];

    // use getCompleteUsers to fetch complete user info
    // but only if not already completed
    const userIds = userData.filter(user => !user.email).map(user => user.id);
    // TODO: show some kind of loading indicator here ???
    const completedUsers = await getCompleteUsers({ token, userIds });
    // merge completedUsers back into userData
    // TODO: maybe push back up into userData (phase 2); this logic is duplicated in Insights.js!
    const finalUserData = userData.map(user => {
      const completedUser = completedUsers.find(u => user.id === u.id);
      if (completedUser) {
        return {
          ...user,
          ...completedUser,
        }
      } else {
        return user;
      }
    });
    
    // Map toggles down
    for (let i = 0; i < downloadToggles.length; i++) {
      if (downloadToggles[i].include) {
        includeToggles[downloadToggles[i].id] = true;
      }
    }

    for (let i = 0; i < finalUserData.length; i++) {
      const tempObj = {};
      const user = finalUserData[i];
      const questsCompleted = user.questsCompleted || '0';
      const questsAttempted = user.questsAttempted || '0';

      if (includeToggles.name) tempObj.name = user.name;
      if (includeToggles.accv) tempObj.accv = user.accv;
      if (includeToggles.email) tempObj.email = user.email;
      if (includeToggles.firstName) tempObj.firstName = user.firstName;
      if (includeToggles.lastName) tempObj.lastName = user.lastName;
      if (includeToggles.gender) tempObj.gender = user.gender;
      if (includeToggles.region) tempObj.region = user.region;
      if (includeToggles.twitter) tempObj.twitter = user.twitter;
      if (includeToggles.language) tempObj.language = user.language;
      if (includeToggles.timezone) tempObj.timezone = user.timezone;
      if (includeToggles.paymentEmail) tempObj.paymentEmail = user.paymentEmail;
      if (includeToggles.questHistory) tempObj.questHistory = `${questsCompleted}/${questsAttempted}`;

      if (includeToggles.streamingPlatform) {
        const platforms = [];
        if (user.twitchId !== null) platforms.push('Twitch');
        tempObj.streamingPlatform = platforms.join(', ');
      }

      if (includeToggles.gamingPlatform) {
        const platforms = [];

        if (user.streamingConsoles) {
          if (user.streamingConsoles.pc) platforms.push('PC');
          if (user.streamingConsoles.xbox) platforms.push('Xbox');
          if (user.streamingConsoles.playstation) platforms.push('PlayStation');
          if (user.streamingConsoles.nintendo) platforms.push('Nintendo');
          if (user.streamingConsoles.mobile) platforms.push('Mobile');
          tempObj.gamingPlatform = platforms.join(', ');
        }
      }

      data.push(tempObj);
    }

    return data;
  }

  renderDownloadModal = (numUsersString) => {
    const { csvData, downloadToggles } = this.state;
    const headers = this.getCSVHeaders();

    return (
      <>
        <div
          className="ModalOverlay"
          onClick={() => this.setState({ downloadModal: false })}
        />
        <div className="ModalWrapper">
          <div className="Modal">
            <span
              className="close"
              onClick={() => this.setState({ downloadModal: false })}>
              <i className="fa fa-close" />
            </span>
            <h2>Export</h2>
            <div className="columnContainer">
              <div className="column">
                {downloadToggles.slice(0, downloadToggles.length / 2).map(option => (
                  <span style={{ margin: '8px 0' }}>
                    <OptionSwitch
                      className="optionSwitch"
                      checked={option.include}
                      onClick={this.toggleDownloadOption.bind(this, option.id)}
                    /> 
                    {option.title}
                  </span>
                ))}
              </div>
              <div className="column">
                {downloadToggles.slice(downloadToggles.length / 2).map(option => (
                  <span style={{ margin: '8px 0' }}>
                    <OptionSwitch
                      className="optionSwitch"
                      checked={option.include}
                      onClick={this.toggleDownloadOption.bind(this, option.id)}
                    /> 
                    {option.title}
                  </span>
                ))}
              </div>
            </div>
            <span className="apply">
              <CSVLink
                style={{ textDecoration: 'none' }}
                data={csvData}
                headers={headers}
                filename="Users-AllResults.csv">
                Export {numUsersString} Influencers
              </CSVLink>
            </span>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { selectedUser, userData } = this.props;
    const { downloadModal } = this.state;
    const numUsersString = userData && userData.length ? numberWithCommas(userData.length) : '0';

    if (selectedUser) {
      const questsCompleted = selectedUser.questsCompleted ? selectedUser.questsCompleted : 0;
      const questsAttempted = selectedUser.questsAttempted ? selectedUser.questsAttempted : 0;
      const completionPercentage = questsAttempted ? `${Math.round((questsCompleted / questsAttempted) * 100)}%` : '0%';
      const streamingConsoles = selectedUser.streamingConsoles ? selectedUser.streamingConsoles : {};

      return (
        <div className="UserDetailModule">
          <h2>{selectedUser.name}</h2>
          <div className="detailsColumn">
            ACCV
            <h2>{selectedUser.accv ? numberWithCommas(Math.round(selectedUser.accv)) : '0'}</h2>
          </div>
          <div className="detailsColumn">
            Stream Time
            <h2>
              {selectedUser.timePlayingTotal ? numberWithCommas((selectedUser.timePlayingTotal / 60).toFixed(2)) : '0'} <span>hrs</span>
            </h2>
          </div>
          <div className="icons">
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div>
                {selectedUser.twitchId && <img alt="twitch" src={TwitchIcon} />}
              </div>
              <div>
                {streamingConsoles.pc && <img alt="pc" src={WindowsIcon} />}
                {streamingConsoles.xbox && <img alt="xbox" src={XboxIcon} />}
                {streamingConsoles.playstation && <img alt="playstation" src={PlaystationIcon} />}
                {streamingConsoles.nintendo && <img alt="nintendo" src={NintendoIcon} />}
                {streamingConsoles.mobile && <img alt="mobile" src={MobileIcon} />}
              </div>
            </div>
          </div>
          <span className="completionBar">
            <span className="fill" style={{ width: completionPercentage }} />
          </span>
          <h3>{questsCompleted}/{questsAttempted} Completed ({completionPercentage})</h3>
          <button
            className="apply"
            onClick={async () => {
              if (!downloadModal) {
                const csvData = await this.getCSVData();
                this.setState({
                  csvData,
                  downloadModal: true
                });
              } else {
                this.setState({
                  downloadModal: false
                })
              }
            }}>
            Export {numUsersString} Influencers
          </button>
          {downloadModal && this.renderDownloadModal(numUsersString)}
        </div>
      );
    }

    return (
      <div className="UserDetailModule">
        No user selected
      </div>
    );
  }
}

export default UserDetailModule;
