import React from 'react';
import './ReloadButton.scss';

function ReloadButton({ onClick }) {
  return (
    <button
      className="reload-button"
      onClick={onClick}
      title="Reload initial user & game data"
      type="button">
      <svg
        fill="none"
        viewBox="0 0 16 16" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5h5V0" />
        <path d="M15 8a6.957 6.957 0 01-7 7 6.957 6.957 0 01-7-7 6.957 6.957 0 017-7 6.87 6.87 0 016.3 4" />
      </svg>
      Reload
    </button>
  )
};

export default ReloadButton;