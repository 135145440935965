import React, { useState } from "react";
import classnames from "classnames";

import {
  getDateFormattedString,
  getTwitchAverageViewers,
  getUserAvatar,
  rounding,
} from "../../../../../utils/functions";
import { getRandomAvatar } from "../../../../../global/images/icons/Avatar";
import { SetViewContext } from "../../PortalContext";

import CustomModal from "../../CustomInputs/CustomModal";
import FundQuestApplicant from "./FundQuestApplicant";
import Icon from "../../../../../global/images/icons/Icon";
import Tooltip from "@material-ui/core/Tooltip";

import { checkAuthType } from "../../../../../utils/authChecker";

import "./ViewAll.scss";

export const ApplicantCard = props => {
  const {
    display,
    userQuest: {
      id: userQuestId,
      user,
      user: {
        displayName,
        // id: userId,
        userGame30,
        username,
      },
      createdAt,
    },
    postBrandPreapproval,
    token,
    type,
    userQuest = {},
  } = props;

  const [fundQuestModalOpen, setFundQuestModalOpen] = useState(false);
  const [showFundQuestModal, setShowFundQuestModal] = useState(true);

  
  const handlePostApproval = (typePassed, userQuest) => {
      if (typePassed === 'approved' && showFundQuestModal) {
        setFundQuestModalOpen(true);
      } else if (typePassed === 'declined' && type === 'approved') {
        setFundQuestModalOpen(true)
      } else if (typePassed === 'declined' && type === 'pending') {
        postBrandPreapproval([userQuest.id], type, token);
      }
   };

  const ccv = getTwitchAverageViewers(user);
  const dateString = getDateFormattedString(createdAt);
  const avatarUrl = getUserAvatar(user);
  const gameString = userGame30?.[0]?.gameString;
  const typeSwitch = type === 'approved' ? 'declined' : 'approved';



  const authType = checkAuthType();
  console.log(authType)
  

  return (
    <div className={classnames("ApplicantCard", display)}>
      {fundQuestModalOpen && (
        <CustomModal
          open={fundQuestModalOpen}
          onClose={() => {
            setFundQuestModalOpen(false);
            if (!showFundQuestModal) {
              setShowFundQuestModal(true);
            }
          }}
          content={
            <FundQuestApplicant
              checkout={() => postBrandPreapproval([userQuest.id], typeSwitch, token)}
              rejection={type === "approved"}
              handleFundQuestModalChange={() => {
                const newValue = !showFundQuestModal;
                setShowFundQuestModal(newValue);
                localStorage.setItem(`showFundQuestModal-${userQuest.quest}`, newValue);
              }}
              showFundQuestModal={showFundQuestModal}
              uq={userQuest}
            />
          }
        />
      )}
      <div className={classnames("ApplicantCard__Left", display)}>
        {display === "grid" && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className='ApplicantCard__Icon'
                onClick={() =>
                  setView({
                    ...view,
                    component: "StreamerProfile",
                    currentUser: username,
                  })
                }>
                <Icon name='user' />
              </div>
            )}
          </SetViewContext.Consumer>
        )}
        <img
          className={classnames("ApplicantCard__Img", display)}
          src={avatarUrl}
          alt={displayName}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = getRandomAvatar();
          }}
        />
        <div className={classnames("ApplicantCard__Info", display)}>
          <div className='ApplicantCard__Info__Name'>{displayName}</div>
          <div className='ApplicantCard__Info__CCV'>{rounding(ccv, 1)} CCV</div>
          <div className='ApplicantCard__Info__Known'>
            <div className='ApplicantCard__Info__Known__Text'>Known For:&nbsp;</div>
            <div className='ApplicantCard__Info__Known__Game'>{gameString}</div>
          </div>
          <div className='ApplicantCard__Info__Date'>{dateString}</div>
        </div>
      </div>
      

      <div className={classnames("ApplicantCard__Buttons", display)}>
      {display === "list" && type === 'approved' && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <Tooltip title='View Deliverables' placement='top'>
                <div
                  className='ApplicantCard__Buttons__Deliverable'
                  onClick={() =>
                    setView({
                      ...view,
                      component: "ReviewDeliverable",
                      currentUserQuest: userQuestId,
                    })
                  }>
                  <i className='fa fa-list'></i>
                </div>
              </Tooltip>
            )}
          </SetViewContext.Consumer>
        )}
        {display === "list" && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <Tooltip title='View Profile' placement='top'>
                <div
                  className='ApplicantCard__Buttons__View'
                  onClick={() =>
                    setView({
                      ...view,
                      component: "StreamerProfile",
                      currentUser: username,
                    })
                  }>
                  <i className='fa fa-user'></i>
                </div>
              </Tooltip>
            )}
          </SetViewContext.Consumer>
        )}
        {((type === 'pending' || type === 'rejected') && authType === 'admin') && (
          <div
            className={`ApplicantCard__Buttons__Button ApproveButton ${display}`}
            onClick={() => handlePostApproval('approved', userQuest)}>
            {display === "list" && <Icon name='check' />}
            {display === "grid" && <>Approve</>}
          </div>
        )}
        {((type === 'pending' || type === 'approved') && authType === 'admin') && (
          <div
            className={`ApplicantCard__Buttons__Button RejectButton ${display}`}
            onClick={() => handlePostApproval('declined', userQuest)}>
            {display === "list" && <Icon name='times' />}
            {display === "grid" && <>Reject</>}
          </div>
        )}
      </div>
    </div>
    
  );
};

export const NoApplicantsCard = () => <div className='NoApplicantsCard'>No Applicants Found</div>;
