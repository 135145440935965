import React from 'react';
import Icon from '../../../../global/images/icons/Icon';
import './Categories.scss';

const defaultOptions = [
  {
    name: 'Action',
    url: 'Action',
  },
  {
    name: 'Adventure',
    url: 'Adventure',
  },
  {
    name: 'Open World',
    url: 'Open World',
  },
  {
    name: 'Shooter',
    url: 'Shooter',
  },
  {
    name: 'Survival',
    url: 'Survival',
  },
];

const Categories = (props) => {
  const {
    handleFormChange,
    header = 'CATEGORIES',
    options = defaultOptions,
    value = [],
  } = props;

  const handleCategories = (option, add = true) => {
    const newValue = [...value];
    if (add) {
      newValue.push({ name: option, url: option });
    } else {
      newValue.splice(newValue.findIndex(o => {
        return o.url === option;
      }), 1);
    }
    handleFormChange('categories', newValue);
  };

  const SelectedCategories = () => (
    <div className="Categories_Selected">
      {value.map(option => (
        <div className="Categories_Selected_Item" key={option.url} onClick={() => handleCategories(option.url, false)}>
          {option.name}
          <Icon name="xmark" color="#16181c" />
        </div>
      ))}
    </div>
  );

  const defaultOption = <option key="default" value="" disabled hidden />;
  const remainingCategoryOptions = options.filter(option => !value.find(valueOption => valueOption.url === option.url));

  const CategoryOptions = () => (
    <select className="Categories_Options" onChange={e => handleCategories(e.target.value)}>
      {[defaultOption, ...remainingCategoryOptions].map(option => (
        <option key={option.url} value={option.url}>{option.name}</option>
      ))}
    </select>
  );

  return (
    <div className="Categories">
      <div className="Categories_Header">{header}</div>
      {value?.length > 0 && (<SelectedCategories />)}
      {remainingCategoryOptions?.length > 0 && <CategoryOptions />}
    </div>
  );
};

export default Categories;
