import React from "react";
// import material ui tooltip
import { Tooltip } from "@material-ui/core";
import { isMvp } from "./renderingHelpers";

export const ComingSoon = ({ children, placement ='left', styl={} }) => {
  if (!isMvp) return <>{children}</>;
  return (
    <Tooltip title='Coming Soon!' placement={placement}>
      <span style={{ opacity: 0.3, ...styl}}>
        <span style={{ pointerEvents: "none"}}>{children}</span>
      </span>
    </Tooltip>
  );
};

export const CustomTooltipTextBlur = ({ children, placement = 'left', styl = {}, text = 'Coming Soon!' }) => (
  <Tooltip title={text} placement={placement}>
    <span style={{ opacity: 0.3, ...styl }}>
      <span style={{ pointerEvents: "none" }}>{children}</span>
    </span>
  </Tooltip>
);

export const MakeInvisible = ({ children }) => {
  if (!isMvp) return children;
  return <></>;
};
