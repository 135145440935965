/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';

class AdminUserBrandForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (info) => {
    this.props.onSubmit(info);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="form-container admin-user-create" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="text"
          name="firstName"
          id="firstName"
          placeholder="First Name"
          customLabel={(<label>First Name</label>)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last Name"
          customLabel={(<label>Last Name</label>)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="company"
          id="company"
          placeholder="Company"
          customLabel={(<label>Company</label>)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="email"
          id="email"
          placeholder="Email"
          customLabel={(<label>Email</label>)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="username"
          id="username"
          placeholder="Username"
          customLabel={((<label>Username</label>))}
          component={CustomInput}
        />
        <div className="form__footer">
          <button className="AUserForm__save--btn">Save</button>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.firstName || values.firstName.trim() === '') {
    errors.firstName = 'This field is required';
  }
  if (!values.lastName || values.lastName.trim() === '') {
    errors.lastName = 'This field is required';
  }
  if (!values.username || values.username.trim() === '') {
    errors.username = 'This field is required';
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  }
  if (!values.company || values.company.trim() === '') {
    errors.company = 'This field is required';
  }
  return errors;
};


AdminUserBrandForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'adminUserBrandForm',
  validate,
})(AdminUserBrandForm);
