const rejectArr = 'arr must be an array.';
const rejectSize = 'size must be a positive integer.';

function chunk(arr, size) {
  if (!Array.isArray(arr)) throw new Error(rejectArr);
  if (!Number.isInteger(size)) throw new Error(rejectSize);
  if (size <= 0) throw new Error(rejectSize);
  const chunks = [];

  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }

  return chunks;
};

export {
  chunk,
  rejectArr,
  rejectSize,
};