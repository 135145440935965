import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Main from '../components/main/Main';
import * as Sentry from '@sentry/react';
import * as actions from '../actions';

const mapStateToProps = state => ({
  home: state.home,
  auth: state.auth,
  notifications: state.notifications,
  form: state.form,
  user: state.user,
  admin: state.admin,
  commandCenter: state.commandCenter,
  config: state.config,
  brandUser: state.brandUser,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

const App = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true,
  },
)(Main);

export default Sentry.withProfiler(App);
