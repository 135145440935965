import { combineEpics } from 'redux-observable';

import {
  getInitialInfo,
  getGameList,
  getGame,
  requestGameKey,
  joinToBounty,
  leaveBounty,
  getUserPayments,
  getUserTodayActivity,
  getUserReferralsActivity,
  getUserTimedActivity,
  submitLink,
  submitAffiliateData,
  getMyQuestsPageData,
  getUserTickets,
  requestUpdateAvatarUrl,
  getBroadcast,
  submitRequirementData,
  checkRequirementData,
} from './userEpic';

import {
  loginTwitch,
  loginWithTwitterOAuthCode,
  loginYoutube,
  updateUserInfo,
  signIn,
  signUp,
  recover,
  recoverValidate,
  recoverChangePassword,
  disconnectTwitch,
  disconnectYoutube,
  updateProfileInfo,
  validateUserHandshake,
} from './authEpic';

import {
  uploadCSV,
  getGameLiveStreams,
  getGameBountiesData,
  getAUserList,
  getAUserListCsv,
  createAUser,
  createAUserBrand,
  getAUser,
  updateAUser,
  getAConfigList,
  createAConfig,
  getAConfig,
  updateAConfig,
  getAGameList,
  getAdminQuestListByGame,
  getAGame,
  createAGame,
  updateAGame,
  createAPlatform,
  getAllPlatforms,
  updateAFeatured,
  requestUploadImageUrl,
  getATrackedGames,
  getACampaignList,
  getACampaign,
  getACampaignQuestsReport,
  getAPaymentList,
  getAPaymentListCsv,
  getPaypalPayments,
  getAQuestList,
  // getInsightsData,
  // getInsightsOptions,
  createOffPlatformUser
} from './adminEpic';

import {
  getCCGameList,
  uploadKeys,
  getGameKeys,
  deleteGameKeys,
  getBountyPredictions,
  getWhiteList,
  toggleUserInWhiteList,
  createUserAndWhiteListIt,
  findUsers,
  getGameActivity,
  distributeGameKeys,
  assignNewKeyPlatforms,
  getCCQuestList,
  createCCQuest,
  getCCQuest,
  copyCCQuest,
  updateCCQuest,
  getCCQuestActivity,
  getCCQuestSales,
  getCCQuestTimed,
  getCCQuestGeneral,
  getCCQuestGeneralCsv,
  getCCQuestAffiliate,
  postCCPreapproval,
  postCCRequirement,
  postCCRequirementMultiple,
  postCCAffiliateResolution,
  getCCQuestTiered,
  getCCQuestTieredMultipleDays,
  requestUploadCCImageUrl,
  getCCCampaignListByGame,
  getCCCampaign,
  createCCCampaign,
  updateCCCampaign,
  updateCCGamesPlatform,
  updateCCQuestsPlatform,
  addCCUserQuest,
  removeCCUserQuest,
  moveCCUserQuest,
  getCCActiveQuestsByGame,
  getLinksDashboard,
  uploadLinks,
  assignCustomLinks,
  assignMasterLinks,
  deleteLinks,
  postReputationRating,
} from './commandCenterEpic';

import {
  getQuest,
  joinQuest,
  joinQuestOffplatform,
  leaveQuest,
  requestGameKeyByQuest,
  getSFGame,
  getSFGameBySlug,
  postGamePayment,
  getQuestByIdentifier,
  getQuestBySlugGuest,
  requestReferralCodeByQuest,
  verifyYoutubeChannelStreamStatus,
  getStoreGames,
} from './configEpic';

import {
  getOptimizedAllQuests,
  getAllGuestQuests,
  getOptimizedEndedQuests,
} from './homeEpic';

import {
  getBUCampaignList,
  getBUCampaign,
  getBrandGame,
  getBrandQuest,
  getBrandStreamer,
  getBrandUserQuest,
  brandUserUploadImageUrl,
  postBrandGame,
  postBrandPreapproval,
  postBrandQuest,
  postBrandRequirement,
  putBrandGame,
  putBrandQuest,
} from './brandUserEpic';

const rootEpic = combineEpics(
  loginTwitch,
  loginWithTwitterOAuthCode,
  loginYoutube,
  disconnectTwitch,
  disconnectYoutube,
  updateUserInfo,
  uploadCSV,

  // <--------------- START NOIZ V2 EPICS --------------->
  getInitialInfo,
  getGameList,
  getGame,
  requestGameKey,
  joinToBounty,
  leaveBounty,
  requestGameKeyByQuest,
  getGameLiveStreams,
  getGameBountiesData,
  getCCGameList,
  uploadKeys,
  getGameKeys,
  deleteGameKeys,
  getBountyPredictions,
  getWhiteList,
  toggleUserInWhiteList,
  createUserAndWhiteListIt,
  findUsers,
  getGameActivity,
  distributeGameKeys,
  assignNewKeyPlatforms,
  // <--------------- END NOIZ V2 EPICS --------------->

  // <--------------- START NOIZ V3 EPICS --------------->
  signIn,
  signUp,
  recover,
  recoverValidate,
  recoverChangePassword,
  getCCQuestList,
  getCCQuest,
  copyCCQuest,
  createCCQuest,
  updateCCQuest,
  getAUserList,
  getAUserListCsv,
  createAUser,
  createAUserBrand,
  getAUser,
  updateAUser,
  getAPaymentList,
  getAPaymentListCsv,
  getPaypalPayments,
  getAQuestList,
  getAConfigList,
  createAConfig,
  getAConfig,
  updateAConfig,
  getQuest,
  joinQuest,
  joinQuestOffplatform,
  leaveQuest,
  submitLink,
  submitAffiliateData,
  submitRequirementData,
  checkRequirementData,
  requestUpdateAvatarUrl,
  getSFGame,
  getSFGameBySlug,
  postGamePayment,
  getAdminQuestListByGame,
  getUserPayments,
  updateProfileInfo,
  validateUserHandshake,
  getCCCampaignListByGame,
  getCCCampaign,
  createCCCampaign,
  updateCCCampaign,
  updateCCGamesPlatform,
  updateCCQuestsPlatform,

  getUserTodayActivity,
  getUserReferralsActivity,
  getUserTimedActivity,
  getMyQuestsPageData,
  getUserTickets,
  getCCQuestActivity,
  getCCQuestSales,
  getCCQuestTimed,
  getCCQuestGeneral,
  getCCQuestGeneralCsv,
  getCCQuestAffiliate,
  postCCPreapproval,
  postCCRequirement,
  postCCRequirementMultiple,
  postCCAffiliateResolution,
  getCCQuestTiered,
  getCCQuestTieredMultipleDays,
  getQuestByIdentifier,
  getQuestBySlugGuest,
  requestReferralCodeByQuest,
  getAGameList,
  getAGame,
  createAGame,
  updateAGame,
  createAPlatform,
  getAllPlatforms,
  updateAFeatured,
  verifyYoutubeChannelStreamStatus,
  requestUploadImageUrl,
  // <--------------- END NOIZ V3 EPICS --------------->

  // <--------------- START NOIZ V4 EPICS --------------->
  getOptimizedAllQuests,
  getAllGuestQuests,
  getOptimizedEndedQuests,
  requestUploadCCImageUrl,
  getATrackedGames,
  getBUCampaignList,
  getBUCampaign,
  getACampaignList,
  getACampaign,
  getACampaignQuestsReport,
  getBroadcast,
  getStoreGames,
  addCCUserQuest,
  removeCCUserQuest,
  moveCCUserQuest,
  getCCActiveQuestsByGame,
  getLinksDashboard,
  uploadLinks,
  assignCustomLinks,
  assignMasterLinks,
  deleteLinks,
  postReputationRating,
  createOffPlatformUser,
  getBrandGame,
  getBrandQuest,
  getBrandStreamer,
  getBrandUserQuest,
  brandUserUploadImageUrl,
  postBrandGame,
  postBrandPreapproval,
  postBrandQuest,
  postBrandRequirement,
  putBrandGame,
  putBrandQuest,
  // <--------------- END NOIZ V4 EPICS --------------->
);

export default rootEpic;
