import React from 'react';
import classnames from 'classnames';

import './CustomInputs.scss';

const CustomDropdown = (props) => {
  const {
    header,
    name,
    value,
    onChange,
    error,
    options = [],
    enableDefaultOption = true,
  } = props;

  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  const defaultOption = (
    <option value="" key="" disabled defaultValue hidden>Select {name}</option>
  );

  const optionDisplay = options.map(({ optionName, optionValue }, index) => (
    <option key={`${optionValue}-${index}`} value={optionValue}>
      {optionName}
    </option>
  ));

  const optionContent = enableDefaultOption ? [defaultOption, ...optionDisplay] : optionDisplay;

  return (
    <div className="CustomInput">
      <div className="CustomInput__Header">{header}</div>
      <select
        className={classnames("CustomInput__Dropdown InputClass", error ? 'Error' : '')}
        // defaultValue={value}
        name={name}
        onChange={handleChange}
        value={value || ''}
      >
        {optionContent}
      </select>
    </div>
  );
};

export default CustomDropdown;
