/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import AdminTabs from '../../util/tabs/AdminTabs';
import AdminGameForm from '../../../forms/AdminGameForm';
import { simpleNotification } from '../../../../utils/notifications';
import './GameCreate.scss';

class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {

    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    this.props.getAllPlatforms(token);
  }

  componentDidMount() {
    this.props.assignGameDefaultInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.admin.game.isLoading === true) {
      simpleNotification({
        level: 'success',
        title: 'Game Created.',
        message: 'The game has been created successfully.',
      });
      this.props.router.push('/admin/games');
    }
  }

  onSubmit = (data) => {
    const { token } = this.props.auth;
    this.props.createAGame(data, token);
  }

  render() {
    const {
      admin: {
        game,
        platforms: { data: platforms = [] },
        preSignedUrl,
      },
      auth,
      form: { adminGameForm },
      createAPlatform,
      requestUploadImageUrl,
      swapGameGallery,
      updateAdminGameForm,
    } = this.props;

    return (
      <section className="AGameCreate">
        <section className="AGameCreate__wrapper">
          <AdminTabs {...this.props} />
          <section className="AGameCreate__content">
            <h2 className="AGameCreate__title">CREATE GAME</h2>
            <div className="AGameCreate__actions">
              <Link
                to="/admin/games"
                activeClassName="active"
                href="/admin/games"
              >
                <button>Back</button>
              </Link>
            </div>
            <AdminGameForm
              adminGameForm={adminGameForm}
              auth={auth}
              game={game}
              platforms={platforms}
              createAPlatform={createAPlatform}
              onSubmit={this.onSubmit.bind(this)}
              preSignedUrl={preSignedUrl}
              requestUploadImageUrl={requestUploadImageUrl}
              swapGameGallery={swapGameGallery}
              updateAdminGameForm={updateAdminGameForm}
            />
          </section>
        </section>
      </section>
    );
  }
}

UserCreate.propTypes = {

};

export default UserCreate;
