import React, { useState, useEffect } from "react";
import "./TableBuilder.scss";
// should add header rows
// should add category top rows
// should build based on passed in length
// should filter
// should have row limiter
// should scroll vertically and horizontally
// should have one row per streamer but split cells where necessary.
// user/pic, deliverables, schedule, vods, vod views, hours watched, peakccv, ccv, hours streamed, chat messages, tweet, engagements impressions:

import { tableSchema } from "./TableHelpers";
import NoizLogo from "../../../../logo/noiz-logo.png";
import { debounce } from "../../../../../utils/functions";

const Cell = ({ children, content, cssClass = "TableBuilder_Cell_Base", styles = {} }) => {
  return (
    <td className={cssClass} styles={{ ...styles }}>
      {content ? content : children}
    </td>
  );
};

const Row = ({ children, cssClass = "TableBuilder_Row_Base", styles = {} }) => {
  return (
    <tr className={cssClass} style={{ ...styles }}>
      {children}
    </tr>
  );
};

const vodTweetCell = (items, isTweet = false) => {
  return (
    <Cell
      children={
        <div className='TableBuilder_TableDivs'>
          {items.map((item, i) => {
            // return divs with links to vods and an icon
            const override =
              item === "none" ? (
                <div key={i}>none</div>
              ) : (
                <a href={item} key={i} target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-external-link'></i>
                </a>
              );
            return isTweet ? (
              override
            ) : (
              <a target='_blank' rel='noopener noreferrer' href={item} key={i}>
                {`VOD${i + 1}`}
              </a>
            );
          })}
        </div>
      }
    />
  );
};

const scheduleCell = items => {
  return (
    <Cell
     className="Schedule_Cell"
      children={
        <div className='TableBuilder_TableDivs Schedule_Cell'>
          {items.length ? (
            items.map((item, i) => {
              // return divs with links to vods and an icon
              return <div className="Schedule_Cell" key={i}>{item}</div>;
            })
          ) : (
            <div>No Schedule Given</div>
          )}
        </div>
      }
    />
  );
};

const profileCell = cell => {
  return (
    <>
      <Cell
        cssClass='TableBuilder_Cell_Profile'
        children={
          <div className='Tablebuilder_TableDivs'>
            <div style={{ marginBottom: "20px" }}>
              <img
                className='TableBuilder_Row_ProfileImage'
                src={cell[0]}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = NoizLogo;
                }}
                alt='User Profile'
              />
            </div>
            <div>{cell[1]}</div>
          </div>
        }
      />
    </>
  );
};

const MakeCategoryRows = ({ schema }) => {
  return (
    <Row
      cssClass='TableBuilder_Row_Category'
      children={schema.map((c, i) => {
        if (i === 0) return <th key={i} colSpan={3}></th>;
        if (i === 3)
          return (
            <th colSpan={7} key={i} className='TableBuilder_Row_Twitch'>
              TWITCH
            </th>
          );
        if (i === 10)
          return (
            <th colSpan={3} key={i} className='TableBuilder_Row_Twitter'>
              TWITTER
            </th>
          );
        return <React.Fragment key={i}/>
      })}
    />
  );
};

const makeRows = data => {
  return data.map((cell, i) => {
    // if it's the first row, create the profile image and username
    if (i === 0) return profileCell(cell);
    if (i === 2) return scheduleCell(cell);
    if (i === 3) return vodTweetCell(cell);
    if (i === 10) return vodTweetCell(cell, true);
    return (
      <Cell
        children={
          <div className='TableBuilder_TableDivs'>
            {cell.map((c, i) => (
              <div key={i}>{c}</div>
            ))}
          </div>
        }
      />
    );
  });
};

const makeTotalsRows = data => {
  return (
    <tr className='TableBuilder_TotalsRow'>
      {data.map((cell, i) => {
        // if it's the first row, create the profile image and username
        if (i === 0) return <td>TOTALS</td>;
        if (i === 10) return <td></td>;
        return (
          <td>
            {cell.map((c) => (
              <>{c}</>
            ))}
          </td>
        );
      })}
    </tr>
  );
};

const allTheFilters = (data, search, rows) => {
  if (!search && !rows) {
    return data;
  } else if (!search && rows) {
    return rows !== -1 ? data.slice(0, rows) : data;
  }
  const filtered = data.filter(row => {
    return row.some(cell => {
      return String(cell).toLowerCase().includes(search.toLowerCase());
    });
  });
  if (rows === -1) return filtered;
  return filtered.slice(0, rows);
};

const returnMaxIfMultiple = data => {
  if (!isNaN(data)) return data;
  if (data.length > 1) return Math.max(...data);
  else return data;
};

const sortByColumn = (data, column, direction) => {
  if (!column || !direction) return data;
  if (direction === "asc") {
    return data.sort((a, b) => {
      return returnMaxIfMultiple(a[column]) - returnMaxIfMultiple(b[column]);
    });
  } else {
    return data.sort((a, b) => {
      return returnMaxIfMultiple(b[column]) - returnMaxIfMultiple(a[column]);
    });
  }
};

const TableBuilder = ({ tableData, totals = [] }) => {
  const [rowNumbers, setRowNumbers] = useState(-1);
  const [searchInput, setSearchInput] = useState("");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState([4, "desc"]);
  const debouncedSearch = debounce(setSearch, 500);
  useEffect(() => {
    debouncedSearch(searchInput);
  }, [searchInput]);

  const manageSortSet = column => {
    setSort([column, sort[1] === "asc" ? "desc" : "asc"]);
  };

  const MakeHeaders = ({
    schema,
    callback = () => console.log("assign a callback to makeHeaders"),
    sortedByIndex = -1,
    sortedByDirection = "desc",
  }) => {
    return Object.keys(schema).map((key, i) => {
      return (
        <th style={{ cursor: "pointer" }} key={key} onClick={() => callback(i)}>
          <span>{schema[key]}</span>
          <span style={{ marginLeft: "3px", fontSize: "15px", color: "#a142ff" }}>
            {sortedByIndex === i && sortedByDirection === "asc" && <i className='fa fa-angle-up' />}
            {sortedByIndex === i && sortedByDirection === "desc" && (
              <i className='fa fa-angle-down' />
            )}{" "}
          </span>
        </th>
      );
    });
  };

  const filterBar = () => {
    const rowOptions = [10, 20, 30, 40, 50, 100, -1];
    return (
      <div className='TableBuilder_FilterBar'>
        <div>Show Rows</div>

        <div className='TableBuilder_FilterBar_Filter'>
          <select value={rowNumbers} onChange={e => setRowNumbers(e.target.value)}>
            {rowOptions.map((option, i) => {
              return <option key={i} value={option}>{option !== -1 ? option : "All"}</option>;
            })}
          </select>
        </div>
        <div className='TableBuilder_FilterBar_Search'>
          <input
            type='text'
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            placeholder='Search'
          />
        </div>
      </div>
    );
  };

  return (
    <div className='TableBuilder__MaxHeight'>
      {filterBar()}
      <table className='TableBuilder' cellPadding={0} cellSpacing={0}>
        <thead>
          <MakeCategoryRows schema={Object.keys(tableSchema)} />
        </thead>
        <thead>
          <Row
            cssClass='TableBuilder_Row_Header'
            children={
              <MakeHeaders
                schema={tableSchema}
                callback={manageSortSet}
                sortedByIndex={sort[0]}
                sortedByDirection={sort[1]}
              />
            }
          />
        </thead>
        <tbody>
          {sortByColumn(allTheFilters(tableData, search, rowNumbers), sort[0], sort[1]).map((row, i) => (
            <Row key={i} children={makeRows(row)} />
          ))}
          {makeTotalsRows(totals)}
        </tbody>
      </table>
    </div>
  );
};

export default TableBuilder;
