import React from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import ContactForm from './ContactForm';
// Little helpers ...

import './BrandServiceSlides.scss';

const ContactUs = (props) => {
  const { pOffset, layoutFactor = 1, offsetAdjust = 0 } = props;

  return (
    <>
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0} className="contact-us-container" id="contact-us-container" >
        <ContactForm />
        <div className="grey-brand-body-text brand-vert-stack cascade-fade-l fade-in-prep">
          <div className="brand-title">Schedule Your Next
            Campaign Today
          </div>
          <div>
            Do you have a game or product you want to promote? Awesome, we can help! To start, fill out this form and we’ll be in touch soon.
          </div>
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0.8} className="brand-angle-svg" />
    </>
  );
};

export default ContactUs;
