import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import './CustomInputs.scss';

const monthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const CustomDate = (props) => {
  const {
    header,
    name,
    value,
    onChange,
    error,
  } = props;

  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setMonth(date.getUTCMonth() + 1);
      setDay(date.getUTCDate());
      setYear(date.getUTCFullYear());
    }
  }, []);

  const handleChange = (e, target) => {
    let newDate = '';
    const fullCheck = month && day && year;

    if (fullCheck) {
      newDate = `${year}-${month}-${day}`;
    }

    if (target === 'month') {
      if(fullCheck) {
        newDate = `${year}-${e.target.value}-${day}`;
      }
      setMonth(e.target.value);
    } else if (target === 'day') {
      if(fullCheck) {
        newDate = `${year}-${month}-${e.target.value}`;
      }
      setDay(e.target.value);
    } else if (target === 'year') {
      if(fullCheck) {
      }
      newDate = `${e.target.value}-${month}-${day}`;
      setYear(e.target.value);
    }

    if (newDate) {
      onChange(name, new Date(newDate));
    }
  };

  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(
      <option key={`month-${i}`} value={i}>{monthNames[i]}</option>
    );
  }

  const dayOptions = [];
  for (let i = 1; i <= 31; i++) {
    dayOptions.push(
      <option key={`day-${i}`} value={i}>{i}</option>
    );
  }

  const today = new Date();
  const yearOptions = [];
  for (let i = year || 2010; i <= today.getUTCFullYear() + 5; i++) {
    yearOptions.push(
      <option key={`year-${i}`} value={i}>{i}</option>
    );
  }


  const defaultOption = (name) => (
    <option value="" key={`${name}-default`} disabled defaultValue hidden>{name}</option>
  );

  return (
    <div className="CustomInput">
      <div className="CustomInput__Header">{header}</div>
      <div className="CustomInput__Date">
        <select
          className={classnames("CustomInput__Dropdown InputClass Month", error ? 'Error' : '')}
          name='month'
          value={month || ''}
          onChange={e => handleChange(e, 'month')}
        >
          {[defaultOption('Month'), ...monthOptions]}
        </select>
        <select
          className={classnames("CustomInput__Dropdown InputClass Day", error ? 'Error' : '')}
          name='day'
          value={day || ''}
          onChange={e => handleChange(e, 'day')}
        >
          {[defaultOption('Day'), ...dayOptions]}
        </select>
        <select
          className={classnames("CustomInput__Dropdown InputClass Year", error ? 'Error' : '')}
          name='year'
          value={year || ''}
          onChange={e => handleChange(e, 'year')}
        >
          {[defaultOption('Year'), ...yearOptions]}
        </select>
      </div>  
    </div>
  );
};

export default CustomDate;
