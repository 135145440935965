import React from 'react';

import PortraitCards from '../PortraitCards/PortraitCards';

const Quests = ({ quests, productImg }) => {
  const preparedQuests = quests.map(({ id, title, questCover, startDateTime, endDateTime }) => ({
    id,
    img: questCover || productImg,
    name: title,
    startDateTime,
    endDateTime,
  }));

  const editFn = (id, newItem = false) => ({ ...(newItem ? {} : { currentQuest: id }), component: 'QuestEdit' });
  const detailFn = (id) => ({ currentQuest: id, component: 'QuestDetail' });

  return (
    <PortraitCards
      items={preparedQuests}
      showViewAll={false}
      type='quests'
      proper='Quest'
      editFn={editFn}
      detailFn={detailFn}
    />
  );
};

export default Quests;
