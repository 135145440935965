import moment from "moment-timezone";

const today = moment().tz('America/Los_Angeles');
const hoursOffset = -today.utcOffset() / 60;
const localizeMe = (d, s)=> {
  const momentString = s ? 'ddd MMM D, YYYY - hh:mm A z' : 'ddd MMM D, YYYY - hh A z'
  return moment(d).tz('America/Los_Angeles').add(hoursOffset, 'hours').format(momentString);
}

const partitionData = (timeSeries, singleUser) => {
  const minOrHour = singleUser ? 'minute' : 'hour';
  const space2Large = singleUser ? 20 : 1;
  const arrSet = [];
  let currArr = [];
  for (let i = 0; i < timeSeries.length; i++) {
    const item = timeSeries[i];
    if (i === 0) {
      currArr.push(item);
      continue;
    }
    const prev = timeSeries[i - 1];
    const spaceToLarge = moment(item.x).diff(moment(prev.x), minOrHour);
    if (spaceToLarge > space2Large) {
      arrSet.push(currArr);
      currArr = [];
    }
    currArr.push(item);
    if (i === timeSeries.length - 1) arrSet.push(currArr);
  }
  return arrSet.map(item=> item.map(it=> ({x: localizeMe(it.x, singleUser), y: it.y})));
};

const streamAggregator = (session, singleUser) => {
  const tPlayingInc = singleUser ? 5 : 60;
  const streamStats = session.reduce((agg, item, i) => {
  const currCCV = item.y;
  const currTimestamp = item.x;

  if (i===0) {
      agg.streamStart = currTimestamp;
      agg.min = currCCV;
  }
  if (i === session.length - 1) agg.streamEnd = currTimestamp;
  if (agg.max < currCCV) agg.max = currCCV;
  if (agg.min > currCCV) agg.min = currCCV;
  agg.ccvSum += currCCV;
  agg.timePlaying += tPlayingInc;
  return agg;
  }, {
    accv: 0,
    max: 0,
    timePlaying: 0,
    viewerMinutesWatched: 0,
    streamStart: null,
    streamEnd: null,
    ccvSum: 0,
    min: null
  });
  streamStats.accv = streamStats.ccvSum / session.length;
  streamStats.viewerMinutesWatched = streamStats.timePlaying * streamStats.accv;
  return streamStats;

};

const makeHeaders = (schema) => {
    const theSwitch = {
        accv: 'ACCV',
        max: 'Max Viewers',
        timePlaying: 'Time Playing',
        viewerMinutesWatched: 'Viewer Minutes Watched',
        streamStart: 'Stream Start',
        streamEnd: 'Stream End',
        ccvSum: 'Sum of CCV',
        min: 'Minimum CCV'
    }
    return Object.entries(theSwitch).map(item=> ({label: item[1], key: item[0]}))
}

export default function prepCSV(timeSeries, singleUser) {
  const partitionedRawData = partitionData(timeSeries, singleUser);
  const streamStatistics = partitionedRawData.map((item) =>
    streamAggregator(item, singleUser)
  );
  return {data: streamStatistics, headers: makeHeaders()}
}
