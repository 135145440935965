import React from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import './BrandServiceSlides.scss';
import FloatBy from './animations/FloatBy';
import { videoWall, mobileVideoWall } from '../assetsV2/videoWallIndex';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import anime from 'animejs';
import throttle from '../throttle';

const AFullService = (props) => {
  const {
    pOffset, isMobile, layoutFactor = 1, offsetAdjust = 0,
  } = props;

  const getLeftStart = (index, length) => {
    const top = index < (length / 2);
    const left = top ? (20 * index * 2) : (20 * (index - 4.5) * 2);
    return left;
  };
  const ref = React.createRef();

  let isIn = true;

  const toggleMenu = (scrollObj) => {
    const scrollPos = Math.abs(scrollObj.translate);
    if (scrollPos >= 50 && isIn) {
      anime({
        targets: '.Main__Header__wrapper',
        opacity: 0,
        duration: 100,
        easing: 'linear',
      });
      const fixedContactDealy = document.querySelector('.fixed-contact-dealy');
      if (fixedContactDealy) {
        fixedContactDealy.style.display = 'flex';
      }
      anime({
        targets: '.fixed-contact-dealy',
        opacity: 1,
        duration: 100,
        easing: 'linear',
      });
      isIn = false;
    }

    if (scrollPos <= 49 && !isIn) {
      anime({
        targets: '.Main__Header__wrapper',
        opacity: 1,
        duration: 80,
        easing: 'linear',
      });
      anime({
        targets: '.fixed-contact-dealy',
        opacity: 0,
        duration: 80,
        easing: 'linear',
      });
      document.querySelector('.fixed-contact-dealy').style.display = 'none';
      isIn = true;
    }
  };

  const toggleMenuThrottle = throttle(toggleMenu, 100);

  const topOrBottom = (index, length) => {
    const increment = (100 / 5);
    const top = increment;
    const bottom = increment * 3.5;
    const even = index % 2 === 0;
    const deskOffset = even ? 0 : 3;
    const mobileOffset = even ? 0 : 2;
    const offset = isMobile ? mobileOffset : deskOffset;
    const topOrBottomCall = index < (length / 2);
    const final = topOrBottomCall ? top + offset : bottom + offset;
    return final;
  };

  const wallLength = isMobile ? mobileVideoWall.length : videoWall.length;
  const theVideoMap = videoWall;

  return (
    <>
      <ParallaxLayer
        className="brand-service-slide-a"
        factor={layoutFactor}
        forceviewheight
        offset={pOffset + offsetAdjust}
        speed={0}
      >
        {theVideoMap.map((vid, i) => (<FloatBy isMobile={isMobile} key={i} wallLength={wallLength} zLevel={i} video={vid} cssClass={`vid-mover-brand-${i}`} startLeft={getLeftStart(i, wallLength)} startTop={topOrBottom(i, wallLength)} cssStyle={{}} />
        ))}
      </ParallaxLayer>
      <ParallaxLayer
        className="brand-service-slide-a-text"
        forceviewheight
        offset={pOffset + offsetAdjust}
        onFrame={toggleMenuThrottle}
        space={2}
        speed={0.5}
      >
        <div style={{ maxWidth: '80%', paddingTop: '7.5%' }} >Noiz is a <span style={{ borderBottom: '2px solid #a142ff', color: '#a142ff' }}>full service</span> marketing platform that connects brands with influencers in gaming and entertainment</div>
        <div ref={ref} />
      </ParallaxLayer>
      <ParallaxLayer
        className="scroll-for-more-brand"
        fontSize="large"
        forceviewheight
        offset={pOffset + offsetAdjust}
        speed={0.8}
      >
        <div className="scroll-notice-flex">
          <div>SCROLL FOR MORE</div> <div><KeyboardArrowDownIcon /> </div>
        </div>
      </ParallaxLayer>
    </>
  );
};

export default AFullService;
