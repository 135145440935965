/* eslint-disable */
import React from 'react';

class TermsOfService extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  UNSAFE_componentWillMount(){
    this.props.router.push(`/terms-of-use`);
  }

  render() {
    return (
        <section />
    );
  }
}

  export default TermsOfService;
