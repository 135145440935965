/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
// import PropTypes from 'prop-types';
import './CareersPost.scss';
// import IconParticles from '../icon-particles/IconParticles';

const quickStyle = { fontWeight: 800, fontStyle: 'italic' };

class UXIndi extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">
              <section className="PostingItem">
                <a className="PostingItem__apply-btn" href="mailto:angie@noiz.gg">
                  Apply for this job <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">Senior UX Designer - EV Client</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <div>
                <p dir="ltr">Noiz is a creative agency and full-service marketing platform that connects brands with influencers in gaming and entertainment. We are looking for a Senior UX Designer with proven automotive experience to join our growing design department. Our client is a yet-to-be-unveiled, highly innovative EV startup that offers an amazing opportunity to own the design and user experience from the ground floor. Our ideal candidate is an analytical and creative designer who is able to grasp user needs and solve problems while building a beautiful and engaging brand experience for the end-user. As a User Experience Lead, you'll head the planning of multiple project initiatives and contribute to the vision of our growing team.</p>
                <p dir="ltr"><strong>Job Responsibilities:</strong></p>
                <ul>
                  <li dir="ltr">
                    <p dir="ltr">Work with cross-functional team partners and users to understand requirements.</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Create research artifacts including personas, journey maps, user flows, Information Architecture (IA), wireframes, sitemaps, and clickable prototypes</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Design digital experiences that simplify complexity and are intuitive</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Explain your thought process and present design concepts clearly</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Refine your designs from concept to high fidelity prototypes</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Mentor and inspire junior members of the design team</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Conduct user testing/research</p>
                  </li>
                </ul>
                <p dir="ltr"><strong>Qualifications:&nbsp;</strong></p>
                <ul>
                  <li dir="ltr">
                    <p dir="ltr">8+ years of professional experience working on digital products&nbsp;</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Experience leading small-to-medium-sized teams</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Proven ability in designing automotive websites</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Motivated self-starter who will work closely with cross-functional partners in product, engineering, and research</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Ability to thoughtfully communicate your designs, receive feedback gracefully, and contribute to the work of others</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Ample technical knowledge to translate your designs for engineering</p>
                  </li>
                  <li dir="ltr">
                    <p dir="ltr">Comfortable working with Figma, Sketch, Animation tools, Creative Suite, etc.&nbsp;</p>
                  </li>
                </ul>
                <p dir="ltr"><strong>To</strong> <strong>Apply</strong>&nbsp;</p>
                <p dir="ltr">Candidates must apply with a link to an online portfolio that shows examples of design projects. Applicants without an online portfolio or web-specific examples will not be considered.</p>
                <p dir="ltr"><strong>Employment Type</strong></p>
                <p dir="ltr">Full-time</p>
                <p dir="ltr" style={quickStyle}>Noiz is committed to creating an inclusive employee experience for all. Regardless of race, gender, religion, sexual orientation, age, disability, we firmly believe that our work is at its best when everyone feels free to be themselves.</p>
                <p dir="ltr" style={quickStyle}>Noiz offers competitive salaries &amp; benefits including (but not limited to) medical, dental, and vision health insurance.</p>
              </div>

              <p className="CareersPostDetails__content--cta">
                Email your resume to <a href="mailto:angie@noiz.gg">angie@noiz.gg</a>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

// CareersPostFullStackEngineer.propTypes = {
//
// };

export default UXIndi;
