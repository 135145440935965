import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import anime from 'animejs';
import bestInClassIndex from '../assetsV2/iconography/managedCampaignIndex';
// import { fadeInTop, fadeInLeft, rotateIn } from './animations/allTransitions';
// const bestInClassIndex = [1, 2, 3, 4, 5, 6];


const MiniCircle = props => (<svg width="16" height="16" style={{ position: 'absolute', overflow: 'visible', ...props.passStyle }} xmlns="http://www.w3.org/2000/svg">
  <circle
    className="wee-little-circle fade-up-prep"
    r="8"
    stroke="#83858c"
    strokeWidth="2"
    fill="#18191d"
  />
</svg>);

const JManagedCampaign = (props) => {
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0, isDesktop = false,
  } = props;
  const ref = React.createRef();
  const onScreen = useOnScreen(ref);
  const [debounce, setDebounce] = React.useState(true);
  const lineLength = isDesktop ? 575 : 787;

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.isolated-j-fade',
        easing: 'easeOutQuad',
        delay: (e, i) => i * 100,
        opacity: [0, 1],
        duration: 1000,
      });
      anime({
        targets: '.wee-little-circle',
        easing: 'easeOutQuad',
        delay: (e, i) => i * 400,
        opacity: 1,
        duration: 200,
        r: [0, 8],
      });
      anime({
        targets: '.big-line-down',
        easing: 'easeOutQuad',
        opacity: 1,
        duration: 3000,
        y2: [0, lineLength],
      });
      setDebounce(false);
    }
  }, [onScreen, debounce, lineLength]);

  const spaceIntervalCirc = isDesktop ? 115 : 155;
  const spaceIntervalText = isDesktop ? 115 : 155;
  const circleOffset = isDesktop ? 250 : 297.5;
  const textBoxOffset = isDesktop ? 183 : 230;


  return (
    < >
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0} className="slide-j-container hide-brand-scroll" >

        <div className="brand-j-title">End-to-End Managed Campaign Process


        </div>
        <div className="brand-j-fixed-stack">

          <svg className="big-line-down" xmlns="http://www.w3.org/2000/svg" width="1%" height={lineLength}viewBox={`0 0 3 ${lineLength}`}>

            <line className="big-line-down fade-up-prep" x1="2" y1="0" x2="2" y2={lineLength} stroke="#83858c" strokeWidth="2" />
          </svg>
          {bestInClassIndex.map((item, i) => {
                     const left = isDesktop ? 49.93 : 5;
           return (<MiniCircle key={i} passStyle={{ left: `${left}%`, top: `${(spaceIntervalCirc * i) + circleOffset}px`, zIndex: 15 }} />);
            })}

          {bestInClassIndex.map((item, i) => {
            const isEven = i % 2 === 0;
            const left = isEven && isDesktop ? 5 : isDesktop ? 55 : 10;
            const pingPong = isEven && isDesktop ? '' : 'quick-reverse';
            const justify = isEven && isDesktop ? 'justify-start' : 'justify-end';
            const arrowThing = isEven && isDesktop ? 'triangle-pokey-right' : 'triangle-pokey-left';
            return (<div key={i} className={`brand-text-stack isolated-j-fade fade-up-prep ${arrowThing}`} style={{ left: `${left}%`, top: `${(spaceIntervalText * i) + textBoxOffset}px` }}>
              <div className={`row-flex ${pingPong}`}>
                <div className={`image-round ${justify}`}>
                  <img src={item.icon} alt={item.title} />
                </div>
                <div className="text-flex">
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="body">
                    {item.text}
                  </div>
                </div>
              </div>
            </div>);
  })}
        </div>
        <div ref={ref} />
      </ParallaxLayer>


    </>

  );
};


export default JManagedCampaign;

