/* eslint-disable import/prefer-default-export */

export const getOptimizedAllQuests = (filterType, page, token) => ({
  type: 'GET_OPTIMIZED_ALL_QUESTS',
  payload: {
    filterType,
    page,
    token,
  },
});

export const getAllGuestQuests = () => ({
  type: 'GET_GUEST_QUESTS',
  payload: {},
});

export const getOptimizedEndedQuests = (filterType, page, token) => ({
  type: 'GET_OPTIMIZED_ENDED_QUESTS',
  payload: {
    filterType,
    page,
    token,
  },
});
