import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';

/* eslint-disable import/prefer-default-export */

/* export const getConfigList = action$ =>
  action$.ofType('GET_CONFIG_LIST')
    .mergeMap(action =>
      api.getConfigList(action.payload.token)
        .map(response => ({ type: 'GET_CONFIG_LIST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_CONFIG_LIST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_CONFIG_LIST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_CONFIG_LIST_ERROR', error }))
        .startWith({ type: 'GET_CONFIG_LIST_PENDING', payload: action.payload })); */

export const getQuest = action$ =>
  action$.ofType('GET_QUEST')
    .mergeMap(action =>
      api.getQuest(action.payload.questId, action.payload.openModal, action.payload.token)
        .map(response => ({ type: 'GET_QUEST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_QUEST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_QUEST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_QUEST_ERROR', error }))
        .startWith({ type: 'GET_QUEST_PENDING', payload: action.payload }));

export const joinQuest = action$ =>
  action$.ofType('JOIN_QUEST')
    .mergeMap(action =>
      api.joinQuest(action.payload.questId, action.payload.data, action.payload.token)
        .map(response => ({ type: 'JOIN_QUEST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('JOIN_QUEST_ABORTED'))
        .defaultIfEmpty({ type: 'JOIN_QUEST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'JOIN_QUEST_ERROR', error }))
        .startWith({ type: 'JOIN_QUEST_PENDING', payload: action.payload }));

export const joinQuestOffplatform = action$ =>
  action$.ofType('JOIN_QUEST_OFFPLATFORM')
    .mergeMap(action =>
      api.joinQuestOffplatform(action.payload.questId, action.payload.userId)
        .map(response => ({ type: 'JOIN_QUEST_OFFPLATFORM_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('JOIN_QUEST_OFFPLATFORM_ABORTED'))
        .defaultIfEmpty({ type: 'JOIN_QUEST_OFFPLATFORM_CANCELED' })
        .catch(error => getErrorResponse({ type: 'JOIN_QUEST_OFFPLATFORM_ERROR', error }))
        .startWith({ type: 'JOIN_QUEST_OFFPLATFORM_PENDING', payload: action.payload }));

export const leaveQuest = action$ =>
  action$.ofType('LEAVE_QUEST')
    .mergeMap(action =>
      api.leaveQuest(action.payload.questId, action.payload.token, action.payload.reason)
        .map(response => ({ type: 'LEAVE_QUEST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('LEAVE_QUEST_ABORTED'))
        .defaultIfEmpty({ type: 'LEAVE_QUEST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'LEAVE_QUEST_ERROR', error }))
        .startWith({ type: 'LEAVE_QUEST_PENDING', payload: action.payload }));

export const requestGameKeyByQuest = action$ =>
  action$.ofType('REQUEST_GAME_KEY_BY_QUEST')
    .mergeMap(action =>
      api.requestGameKeyByQuest(action.payload.questId, action.payload.token)
        .map(response => ({ type: 'REQUEST_GAME_KEY_BY_QUEST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('REQUEST_GAME_KEY_BY_QUEST_ABORTED'))
        .defaultIfEmpty({ type: 'REQUEST_GAME_KEY_BY_QUEST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'REQUEST_GAME_KEY_BY_QUEST_ERROR', error }))
        .startWith({ type: 'REQUEST_GAME_KEY_BY_QUEST_PENDING', payload: action.payload }));

export const getSFGame = action$ =>
  action$.ofType('GET_SF_GAME')
    .mergeMap(action =>
      api.getSFGame(action.payload.gameId)
        .map(response => ({ type: 'GET_SF_GAME_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_SF_GAME_ABORTED'))
        .defaultIfEmpty({ type: 'GET_SF_GAME_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_SF_GAME_ERROR', error }))
        .startWith({ type: 'GET_SF_GAME_PENDING', payload: action.payload }));

export const getSFGameBySlug = action$ =>
  action$.ofType('GET_SF_GAME_BY_SLUG')
    .mergeMap(action =>
      api.getSFGameBySlug(action.payload.slug)
        .map(response => ({ type: 'GET_SF_GAME_BY_SLUG_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_SF_GAME_BY_SLUG_ABORTED'))
        .defaultIfEmpty({ type: 'GET_SF_GAME_BY_SLUG_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_SF_GAME_BY_SLUG_ERROR', error }))
        .startWith({ type: 'GET_SF_GAME_BY_SLUG_PENDING', payload: action.payload }));

export const postGamePayment = action$ =>
  action$.ofType('POST_GAME_PAYMENT')
    .mergeMap(action =>
      api.postGamePayment(action.payload.description, action.payload.source, action.payload.currency, action.payload.amount)
        .map(response => ({ type: 'POST_GAME_PAYMENT_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('POST_GAME_PAYMENT_ABORTED'))
        .defaultIfEmpty({ type: 'POST_GAME_PAYMENT_CANCELED' })
        .catch(error => getErrorResponse({ type: 'POST_GAME_PAYMENT_ERROR', error }))
        .startWith({ type: 'POST_GAME_PAYMENT_PENDING', payload: action.payload }));

export const getQuestByIdentifier = action$ =>
  action$.ofType('GET_QUEST_BY_IDENTIFIER')
    .mergeMap(action =>
      api.getQuestByIdentifier(action.payload.identifier, action.payload.token, action.payload.type)
        .map(response => ({ type: 'GET_QUEST_BY_IDENTIFIER_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_QUEST_BY_IDENTIFIER_ABORTED'))
        .defaultIfEmpty({ type: 'GET_QUEST_BY_IDENTIFIER_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_QUEST_BY_IDENTIFIER_ERROR', error }))
        .startWith({ type: 'GET_QUEST_BY_IDENTIFIER_PENDING', payload: action.payload }));

export const getQuestBySlugGuest = action$ =>
  action$.ofType('GET_QUEST_BY_SLUG_GUEST')
    .mergeMap(action =>
      api.getQuestBySlugGuest(action.payload.slug, action.payload.userId)
        .map(response => ({ type: 'GET_QUEST_BY_SLUG_GUEST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_QUEST_BY_SLUG_GUEST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_QUEST_BY_SLUG_GUEST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_QUEST_BY_SLUG_GUEST_ERROR', error }))
        .startWith({ type: 'GET_QUEST_BY_SLUG_GUEST_PENDING', payload: action.payload }));

export const requestReferralCodeByQuest = action$ =>
  action$.ofType('REQUEST_REFERRAL_CODE_BY_QUEST')
    .mergeMap(action =>
      api.requestReferralCodeByQuest(action.payload.questId, action.payload.token)
        .map(response => ({ type: 'REQUEST_REFERRAL_CODE_BY_QUEST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('REQUEST_REFERRAL_CODE_BY_QUEST_ABORTED'))
        .defaultIfEmpty({ type: 'REQUEST_REFERRAL_CODE_BY_QUEST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'REQUEST_REFERRAL_CODE_BY_QUEST_ERROR', error }))
        .startWith({ type: 'REQUEST_REFERRAL_CODE_BY_QUEST_PENDING', payload: action.payload }));

export const verifyYoutubeChannelStreamStatus = action$ =>
  action$.ofType('GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS')
    .mergeMap(action =>
      api.verifyYoutubeChannelStreamStatus(action.payload.questId, action.payload.token)
        .map(response => ({ type: 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_ABORTED'))
        .defaultIfEmpty({ type: 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_ERROR', error }))
        .startWith({ type: 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_PENDING', payload: action.payload }));

export const getStoreGames = action$ =>
  action$.ofType('GET_STORE_GAMES')
    .mergeMap(action =>
      api.getStoreGames(action.payload.token)
        .map(response => ({ type: 'GET_STORE_GAMES_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_STORE_GAMES_ABORTED'))
        .defaultIfEmpty({ type: 'GET_STORE_GAMES_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_STORE_GAMES_ERROR', error }))
        .startWith({ type: 'GET_STORE_GAMES_PENDING', payload: action.payload }));
