/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class CustomSelectBasic extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      input,
      inputDisplay,
      placeholder,
      meta: {
        touched, error,
      },
      ...custom
    } = this.props;
    const hasError = touched && error !== undefined;
    const renderSelectOptions = option => (<option key={option} value={option}>{option}</option>);

    let options = [];
    if (custom.time === 'days') {
      options = custom.options;
    }
    if (custom.time === 'months') {
      options = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    }
    if (custom.time === 'years') {
      for (let i = 1905; i <= 2018; i += 1) {
        options.push(`${i}`);
      }
      // options = ['2000', '2001'];
    }
    return (
      <div className="input-container" style={{ flexBasis: custom.flexBasis }}>
        <div className="input-wrapper" style={{ margin: '0' }}>
          <select
            {...input}
            id={custom.id}
            name={custom.name}
            onChange={(value) => { input.onChange(value); }}
            className={classNames({
                touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value,
            })}
          >
            {
              (custom.time === 'years') &&
              <option value="year">Year</option>
            }
            {
              (custom.time === 'months') &&
              <option value="month">Month</option>
            }
            {
              (custom.time === 'days') &&
              <option value="day">Day</option>
            }
            { options.map(renderSelectOptions) }
          </select>
        </div>
      </div>
    );
  }
}

CustomSelectBasic.propTypes = {
  /* input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired, */
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomSelectBasic.defaultProps = {
  placeholder: '',
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

export default CustomSelectBasic;
