import React from 'react';
import classnames from 'classnames';

import './CustomInputs.scss';

const CustomCheckbox = props => {
  const {
    header = '',
    name,
    value,
    onChange,
    error,
  } = props;

  const handleChange = e => {
    onChange(name, e.target.value);
  };

  return (
    <div className="CustomInput">
      <div className={classnames("CustomInput__Radio", error && "Error")}>
        <input
          checked={value}
          className="Radio"
          name={name}
          onChange={handleChange}
          type="checkbox"
        />
        {!!header && <div className="CustomInput__Radio_Label">{header}</div>}
      </div>
    </div>
  );
};

export default CustomCheckbox;
