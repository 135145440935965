import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as notifications } from 'react-notification-system-redux';

import home from './home';
import auth from './auth';
import form from './form';
import user from './user';
import admin from './admin';
import commandCenter from './commandCenter';
import config from './config';
import brandUser from './brandUser';


const rootReducer = combineReducers({
  home,
  auth,
  routing,
  notifications,
  form,
  user,
  admin,
  commandCenter,
  config,
  brandUser,
});

export default rootReducer;
