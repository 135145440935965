/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import AdminTabs from '../../util/tabs/AdminTabs';
import AdminUserBrandForm from '../../../forms/AdminUserBrandForm';
import { simpleNotification } from '../../../../utils/notifications';
import './UserBrandCreate.scss';

class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {

    };
  }

  UNSAFE_componentWillMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.admin.user.isLoading === true && nextProps.admin.user.isLoading === false) {
      if (nextProps.admin.user.errors.hasError) {
        simpleNotification({
          level: 'warning',
          title: 'User not created.',
          message: nextProps.admin.user.errors.message,
        });
      } else {
        simpleNotification({
          level: 'success',
          title: 'User Created.',
          message: 'The user has been created successfully.',
        });
        this.props.router.replace('/admin/users');
      }
    }
  }

  onSubmit = (data) => {
    const { token } = this.props.auth;
    this.props.createAUserBrand(data, token);
  }

  render() {
    return (
      <section className="AUserCreateBrand">
        <section className="AUserCreateBrand__wrapper">
          <AdminTabs {...this.props} />
          <section className="AUserCreateBrand__content">
            <h2 className="AUserCreateBrand__title">CREATE USER</h2>
            <div className="AUserCreateBrand__actions">
              <Link
                to="/admin/users"
                activeClassName="active"
                href="/admin/users"
              >
                <button>Back</button>
              </Link>
            </div>
            <AdminUserBrandForm
              onSubmit={this.onSubmit.bind(this)}
              adminUserForm={this.props.form.adminUserForm}
            />
          </section>
        </section>
      </section>
    );
  }
}

UserCreate.propTypes = {

};

export default UserCreate;
