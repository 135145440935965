const valueMetricMap = {
  totalChatters: "TOTAL CHATTERS",
  totalMessages: "TOTAL MESSAGES",
  avgMessagesPerMinute: "AVERAGE MESSAGES PER MINUTE",
  totalFollowers: "TOTAL FOLLOWERS",
  totalReplies: "TOTAL REPLIES",
  totalRetweetsAndQuotes: "TOTAL RETWEETS",
  totalLikes: "TOTAL LIKES",
  topEmotes: "TOP EMOTES",
  topWords: "TOP WORDS",
  topKeywords: "TOP KEYWORDS",
};
const mapToComponent = dic => Object.entries(dic).map(([key, value]) => makeReportMetric(value, key));
const makeReportMetric = (value, metric) => {
  return {
    value,
    metric: valueMetricMap[metric],
  };
};
export const getChatTopLineCampaign = (chatData = {}) => {
  const { totalChatters, totalMessages, avgMessagesPerMinute } = chatData;
  return mapToComponent({  avgMessagesPerMinute, totalChatters, totalMessages });
};

export const getChatDetail = (chatData = {}) => {
  const { topEmotes = [], topWords = [], topKeywords = [] } = chatData;
  const toReturn = [topEmotes, topWords, topKeywords].filter(list => list.length);
  return [toReturn, ["TOP EMOTES", "TOP WORDS", "TOP KEYWORDS"]];
};

export const getChatGraphData = (chatData = {}) => {
  const { hourlyData = [] } = chatData;
  return hourlyData;
};

export const getTwitterToplineCampaign = (tweetData = {}) => {
  const { totalFollowers, totalReplies, totalRetweetsAndQuotes, totalLikes } = tweetData;
  return mapToComponent({ totalFollowers, totalReplies, totalRetweetsAndQuotes, totalLikes });
};

export const getTopTweets = (tweetData = {}) => {
  const { topTweets = [] } = tweetData;
  return topTweets;
};
