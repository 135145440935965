import React from 'react';
import classNames from 'classnames';
import { getDateString, numberWithCommas } from '../../../utils/functions';
import './CampaignSelector.scss';
import { Link } from 'react-router';

import chart from '../assets/chart.svg';

class CampaignSelector extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      campaignMenuOpen: false,
    };
  }

  goToPortalDetails = (campaignId) => {
    this.props.router.push(`/portal/${campaignId}`);
  }

  render() {
    const { campaignMenuOpen } = this.state;
    const { campaigns } = this.props;
    const { isLoading: isLoadingCampaigns } = campaigns;
    const { campaignId } = this.props.router.params;

    let campaign = false;
    let campaignsWithoutCurrent = [];
    if (campaigns.data) {
      campaign = campaigns.data.find(item => item.campaign === campaignId);
      campaignsWithoutCurrent = campaigns.data.filter(item => item.campaign !== campaignId);
    }
    if (!isLoadingCampaigns && campaign) {
      const {
        name, startDateTime, endDateTime, campaignDashboardSettings,
      } = campaign;
      const budget = campaignDashboardSettings ? campaignDashboardSettings.dashboardBudget : 0;

      return (
        <React.Fragment>
          { campaignMenuOpen ? (
            <div className="closeCampaignSelectorOverlay" onClick={() => this.setState({ campaignMenuOpen: false })} />
        ) : null }

          <div className={classNames('CampaignSelector', { active: campaignMenuOpen })} onClick={() => { this.setState({ campaignMenuOpen: !campaignMenuOpen }); }}>
            <img alt="chart" src={chart} />

            {/* Active Campaign */}
            { (!isLoadingCampaigns) ? (
              <div className="selection">
                <h3>{name}</h3>
                <div style={{ display: 'flex', flexDireciton: 'row' }}>
                  <span className="dateBudget">{getDateString(startDateTime, endDateTime)}</span>
                  { budget ? (
                    <span className="dateBudget border">{`Budget: $${numberWithCommas(budget)}`}</span>
                  ) : null }
                </div>
              </div>
          ) : null }
            <i className={classNames('fa', { 'fa-caret-up': campaignMenuOpen, 'fa-caret-down': !campaignMenuOpen })} />

            {/* Campaign Menu */}
            { campaignMenuOpen ? (
              <div className="campaignMenu">
                { campaignsWithoutCurrent.map((option) => {
            const { campaignDashboardSettings } = option;
            const budget = campaignDashboardSettings ? campaignDashboardSettings.dashboardBudget : 0;

              return (
                <div className="campaign">
                  <Link to={`/portal/${option.campaign}`} activeClassName="active" href={`/portal/${option.id}`}>
                    <img alt="chart" src={chart} style={{ margin: '0 12px 0 0' }} />
                    <h3> { option.name } </h3>
                    <div style={{ display: 'flex', flexDireciton: 'row' }}>
                      <span className="dateBudget">{getDateString(option.startDateTime, option.endDateTime)}</span>
                      { budget ? (
                        <span className="dateBudget border">{`Budget: $${numberWithCommas(budget)}`}</span>
                      ) : null }
                    </div>
                  </Link>
                </div>
              );
            })}
              </div>
          ) : null }
          </div>
        </React.Fragment>
      );
    }
    return (<div />);
  }
}

CampaignSelector.propTypes = {
};

export default CampaignSelector;
