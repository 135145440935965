import _ from 'lodash';
import store from '../store';


export const requireAuth = (roles = []) => (nextState, replace) => {
  const { auth } = store.getState();
  // auth.user.roles = ['user', 'admin']; //It should come from serverside or token and will be validated serverside too.

  // console.log(auth);
  if (roles.length === 0) return;

  if (auth.isAuthenticated) {
    if (_.isString(auth.user.roles) || _.isString(auth.user.role)) {
      if (roles.indexOf(auth.user.roles) !== -1 || roles.indexOf(auth.user.role) !== -1) { return; }
    }
  }

  if (auth.isAuthenticated) {
    if (_.isArray(auth.user.roles)) {
      if (_.intersection(roles, auth.user.roles)) { return; }
    }
  }
  /* eslint-disable consistent-return */
  return replace({
    pathname: '/',
    state: { nextPathname: nextState.location.pathname },
  });
};

/* eslint-disable consistent-return */
export const onlyNotAuthenticated = () => (nextState, replace) => {
  const { auth } = store.getState();
  // auth.user.roles = ['user', 'admin']; //It should come from serverside or token and will be validated serverside too.
  if (auth.isAuthenticated) {
    console.log('auth', auth, 'why is this not hitting?');
    return replace({
      pathname: auth?.user?.role === 'brand' ? '/portal' : '/quests',
      state: { nextPathname: nextState.location.pathname },
    });
  }
};


export const inRoles = (roles = []) => {
  const { auth } = store.getState();
  // auth.user.roles = ['user', 'admin']; //It should come from serverside or token and will be validated serverside too.
  if (roles.length === 0) return false;

  if (auth.isAuthenticated) {
    if (_.isString(auth.user.roles) || _.isString(auth.user.role)) {
      if (roles.indexOf(auth.user.roles) !== -1 || roles.indexOf(auth.user.role) !== -1) { return true; }
    }
  }

  if (auth.isAuthenticated) {
    if (_.isArray(auth.user.roles)) {
      if (_.intersection(roles, auth.user.roles)) { return true; }
    }
  }

  return false;
};

export const isAuthenticated = () => {
  const { auth } = store.getState();
  return auth.isAuthenticated;
};

export const isAuthenticatedId = () => {
  const { auth } = store.getState();
  return auth.isAuthenticated ? auth.user.id : false;
};


export const checkAuthType = () => {
  const { auth } = store.getState();
  if (auth.isAuthenticated) {
    console.log('auth', auth, 'why is this not hitting?');
    return auth.user.role;
  }
  console.log('auth', auth, 'bbbbbbbbbbbbbbbbbbbbbbb');
  return auth.user.role;
};