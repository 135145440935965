/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router';

import IconParticles from '../icon-particles/IconParticles';

import './Careers.scss';

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
  }


  render() {
    return (
      <section className="Careers">
        <section className="Careers__container">
          <section className="Hero">
            {/* <section className="Hero__particles" /> */}
            <IconParticles />
            <section className="Hero__content">
              <h2 className="Hero__title">Careers</h2>
            </section>
          </section>

          <section className="MainCareers">
            <section className="MainCareers__content">
              <section className="CareersListing">
                <section className="PostingGroup">
                  <section className="PostingCategory">
                    <h3 className="PostingCategory__title">OPEN POSITIONS</h3>
                    <p style={{ display: 'none' }} className="PostingCategory__label">ADVERTISING</p>
                    <span className="PostingCategory__spacer" />
                  </section>
                  <section className="PostingItem">
                    <Link className="PostingItem__wrapper" to="/careers/brand-sales-manager" href="/careers/brand-sales-manager">
                      <Link className="PostingItem__apply-btn" to="/careers/brand-sales-manager" href="/careers/brand-sales-manager">Apply</Link>
                      <h4 className="PostingItem__title">Brand & Sales Strategist (Manager)</h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link className="PostingItem__wrapper" to="/careers/ux-ev" href="/careers/ux-ev">
                      <Link className="PostingItem__apply-btn" to="/careers/ux-ev" href="/careers/ux-ev">Apply</Link>
                      <h4 className="PostingItem__title">Senior UX Designer - EV Client</h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link className="PostingItem__wrapper" to="/careers/biz-dev-director" href="/careers/biz-dev-director">
                      <Link className="PostingItem__apply-btn" to="/careers/biz-dev-director" href="/careers/biz-dev-director">Apply</Link>
                      <h4 className="PostingItem__title">Sales/Business Development Director</h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link className="PostingItem__wrapper" to="/careers/campaign-manager" href="/careers/campaign-manager">
                      <Link className="PostingItem__apply-btn" to="/careers/campaign-manager" href="/careers/campaign-manager">Apply</Link>
                      <h4 className="PostingItem__title">Campaign Manager</h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                      </section>
                    </Link>
                  </section>

                  <section className="PostingItem">
                    <Link className="PostingItem__wrapper" to="/careers/community-manager" href="/careers/community-manager">
                      <Link className="PostingItem__apply-btn" to="/careers/community-manager" href="/careers/community-manager">Apply</Link>
                      <h4 className="PostingItem__title">Community Manager</h4>
                      <section className="PostingItem__categories">
                        <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                      </section>

                    </Link>
                  </section>

                </section>
              </section>
            </section>
          </section>
        </section>
      </section>);
  }
}

// Careers.propTypes = {
//
// };

export default Careers;
