import { Observable } from 'rxjs/Observable';
import { simpleNotification } from './notifications';

const alertTypes = {
  E_USER_NO_LONGER_QUALIFIES: 'One or more users no longer qualifies for this quest, please freeze their average or make a member tier',
  E_EMAIL_NOT_SENT_SUCCESS: 'One or more user was accepted, but the email had trouble sending.  Please refresh and check in with user',
};

/* eslint-disable import/prefer-default-export */
export const getErrorResponse = response => {
  
  const apiCode = response && response.error && response.error.response ? response.error.response.code : '';
  const message = alertTypes[apiCode];
  if (message) {
    simpleNotification({
      level: 'error',
      title: 'error',
      message,
    });
  }

  if (response.error.xhr.response && response.error.xhr.response.code && response.error.xhr.response.code === 'E_FORCED_LOGOUT') {
    window.location.replace(`${window.location.origin}/logout`);
  }

  if (response.error.xhr.response
    && response.error.xhr.response.code
    && response.error.xhr.response.message
    && response.error.xhr.response.code === 'E_UNAUTHORIZED'
    && response.error.xhr.response.message === 'jwt expired') {
    window.location.replace(`${window.location.origin}/logout`);
  }

  const initialPayload = response.initialPayload ? response.initialPayload : {};
  if (response.error.xhr.response) {
    return Observable.of({
      type: response.type,
      payload: {
        initialPayload,
        error: true,
        message: response.error.xhr.response.message,
        code: response.error.xhr.response.code,
        errors: response.error.xhr.response.errors,
      },
    });
  }

  return Observable.of({
    type: response.type,
    payload: {
      initialPayload,
      error: true,
      message: 'No server response. (Generic)',
      code: 'GENERIC_ERROR_CODE',
      errors: {},
    },
  });
};
