import React from 'react';
import { useSpring, animated } from 'react-spring';

const CountUp = (props) => {
  const {
    countNum = 100, staticText = '', cssClass = '', cssStyle = {}, textPlacement = 'left', addPlus = true,
  } = props;

  const [toCount] = useSpring(() => ({ to: { val: countNum }, from: { val: 0 }, config: { duration: 1200 } }));
  const beforeText = textPlacement === 'left' ? `${staticText} ` : null;
  const afterText = textPlacement === 'right' ? ` ${staticText}` : null;
  const c = cssClass || '';
  const s = cssStyle || {};
  return (
    <div className={c} style={s}>
      {beforeText}<animated.span >{toCount.val.interpolate(val => Math.floor(val).toLocaleString())}</animated.span>{addPlus && '+'}{afterText}
    </div>

  );
};


export default CountUp;
