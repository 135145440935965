import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(...registerables, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, zoomPlugin);
import "chartjs-adapter-moment";
import "./StackedChart.scss";

// defaults.global.elements.point.pointStyle = 'rect';


const options = [
  { title: "All", value: 0 },
  { title: "CCV", value: 1 },
  { title: "Chat Messages", value: 2 },
];

const DropDownDataSelect = ({setValue = ()=>null}) => {
   
  return (
    <div className="StackedChart_FilterBar">
      <select onChange={(e)=> setValue(e.target.value)}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
};

const filterData = (data, filterN) => {
  if (!filterN) return data;
  if (filterN == 1) return [data[0]];
  if (filterN == 2) return [data[1]];
  return data;
}

class StackedChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {toFilter: 0};
    this.chartRef = React.createRef(null);
  }

  render() {
    const { data = [[], []], labelY, barThickness = 30 } = this.props;
    const chartData = {
      datasets: [],
    };

    const filteredData = filterData(data, this.state.toFilter);

    console.log(filteredData)
    for (let i = 0; i < filteredData.length; i += 1) {
      chartData.datasets.push({
        id: filteredData[i].label,
        label: filteredData[i].label,
        backgroundColor: filteredData[i].backgroundColor,
        // tension: 0,
        // fill: false,
        // categoryPercentage: 1, // notice here
        // barPercentage: 1, // notice here
        data: filteredData[i].data,
      });
    }

    return (
      <section className='Chart'>
        <div className="StackedChart_DataManipulation">
        <div>
        <button className='StackedChart_ResetButton' onClick={() => this.chartRef.current.resetZoom()}>
          Reset
        </button>
        </div>
        <DropDownDataSelect setValue={(v)=> this.setState({toFilter: v})} />
        </div>
        <Bar
          type='stacked'
          ref={this.chartRef}
          className="StackedChart_Chart"
          data={chartData}
          options={{
            // onHover: function (e) {
            //   console.log(e,'e')
            // },
            categoryPercentage: 1, // notice here
            barPercentage: 1, // notice here
            scaleLineColor: "rgba(0,0,0,0)",
            mantainAspectRatio: false,
            backgroundColor: "rgba(100,0,0,1)",
            layout: {
              padding: {
                top: 20,
                bottom: 20,
              },
            },
            tooltips: {
              intersect: false,
              callbacks: {},
            },
            legend: {
              display: false,
              position: "bottom",
              cursor: "crosshair",
              fontColor: "white",
            },
     
            scales: {
            
              y: {
                scaleLabel: {
                  display: true,
                  labelString: labelY,
                  fontColor: "white",
                  maxTicksLimit: 10,
                  padding: 0,
                },
                stacked: true,
                ticks: {
                  fontColor: "white",
                  maxTicksLimit: 5,
                  padding: 30,
                },
                gridLines: {
                  drawTicks: false,
                  display: false,
                  drawBorder: false,
                },
              },
              x: {
                type: "time",
                distribution: "linear",
                time: {
                  unit: "day",
                },
                stacked: true,
                barThickness: barThickness,
                // offset: true,
                gridLines: {
                  drawTicks: true,
                  display: true,
                  drawBorder: false,
                },
                ticks: {
                  padding: 30,
                  autoSkip: true,
                  maxTicksLimit: 8,
                  fontColor: "white",
                  beginAtZero: true,
                },
                pointLabels: {
                  fontColor: "white",
                },
              },
            },
            plugins: {
              
              zoom: {
                limits: {
                  x: { minRange: 10 },
                  y: { minRange: 10 },
                },
                pan: {
                  enable: true,
                  // pan options and/or events
                },
                limits: {
                  // axis limits
                },
                zoom: {
                  drag: {
                    enabled: true,
                  },
                  mode: "x",
                },
              },
            },
          }}
        />
      </section>
    );
  }
}

export default StackedChart;
