import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { fade, slide } from '../../utils/transitionIndex';
import './OpenBar.scss';

function OpenBar(props) {
  const { title, description } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div className={`OpenBar ${open ? 'open' : ''}`}>
      <TransitionGroup>
        <div className="previewBar">
          <h2>{title}</h2>
          <CSSTransition {...fade(200, 200)}>
            <i
              className={`fa fa-chevron-${open ? 'up' : 'down enlarge-me'}`}
              onClick={() => setOpen(!open)}
            />
          </CSSTransition>
        </div>
        {open && (
          <CSSTransition {...slide(200, 200)}>
            <div className="faqDesc">
              {ReactHtmlParser(description)}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>{' '}
    </div>
  );
}

export default OpenBar;
