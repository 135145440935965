import React from 'react';

import './LiveTag.scss';

const LiveTag = () => (
  <div className="LiveTag">
    <div className='LiveTag__Box1' />
    <div className='LiveTag__Tag'>
      Live
    </div>
    <div className='LiveTag__Box2' />
  </div>
);

export default LiveTag;
