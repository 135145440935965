import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router';
import './MyQuests.scss';
import { dayjs, localizeIncorrectDate } from '../../utils/dateTime';
import {
  getNumApprovedRequirements,
  getNumInReviewRequirements,
  getQuestRequirements,
  getStreamTimeCompleted,
} from '../../utils/functions';

function MyQuests({
  active, myQuests, myUserQuests, timedActivity, toggle,
}) {
  const questsNeedingAttention = [];
  const questsUnderReview = [];
  const questsCompleted = [];

  if (!myQuests.isLoading && !myUserQuests.isLoading && !timedActivity.isLoading) {
    for (let i = 0; i < myQuests.data.length; i += 1) {
      const quest = { ...myQuests.data[i] };
      const userQuest = myUserQuests.data.find(uq => uq.quest === quest.id);
      if (!quest.paymentMade && !(userQuest && ['pending', 'declined', 'leftQuest'].includes(userQuest.status))) {
        const userQuestTimedActivity = timedActivity.data.find(ta => (ta.quest ? ta.quest._id === quest.id : false));

        // Figure out how many submission requirements they completed
        let requirements = getQuestRequirements(quest, true).length;
        let completedRequirements = getNumApprovedRequirements(quest, userQuest);
        const inReviewRequirements = getNumInReviewRequirements(quest, userQuest);
        const submissionsNeeded = requirements - completedRequirements - inReviewRequirements; // Do this before mutating the variables below
        const timePlaying = userQuestTimedActivity ? userQuestTimedActivity.timePlaying : 0;
        const streamTimeRemaining = quest.requiredStreamTimeMinutes ? quest.requiredStreamTimeMinutes - timePlaying : 0;
        const streamTimeCompleted = getStreamTimeCompleted(quest, timePlaying);

        // Adjust for completed time requirement
        if (quest.requiredStreamTimeMinutes) {
          requirements += 1;
          if (streamTimeCompleted) {
            completedRequirements += 1;
          }
        }

        const inactive = dayjs() > localizeIncorrectDate(quest.endDateTime).add(7, 'day');
        const completed = requirements === completedRequirements;
        if (!(inactive && !completed)) {
          if (completed) questsCompleted.push(quest);
          if ((requirements === (completedRequirements + inReviewRequirements)) && (inReviewRequirements > 0)) questsUnderReview.push(quest);
          if (requirements > (completedRequirements + inReviewRequirements)) {
            questsNeedingAttention.push({
              ...quest,
              streamTimeRemaining,
              submissionsNeeded,
            });
          }
        }
      }
    }
  }

  const questsTotal = questsNeedingAttention.length + questsUnderReview.length + questsCompleted.length;

  return (
    <section className="MyQuests">
      <section className="ModalMaster">
        <section className={classNames('Modal', { active })}>
          <div className="Modal__layer" role="button" aria-hidden onClick={toggle} />
          <section className="Modal__wrapper">
            <div className="Modal_title">My Quests</div>
            <div className="Modal_tabs">
              <div className="Modal_tabs_wrapper">
                <ul>
                  <li className="active" aria-hidden>
                    Questbook
                  </li>
                </ul>
              </div>
            </div>
            <div className="Modal_body">
              <div className="QuestBook active">
                {myQuests.isLoading && <div className="Loading">Loading</div>}
                {(questsTotal === 0 && !myQuests.isLoading) && (
                  <div className="NoGames sidebar">
                    {'You haven\'t joined any quests yet. '}<Link to="/quests" href="Link/quests">Join some now!</Link>
                  </div>
                )}
                {!myQuests.isLoading && (
                  <>
                    <QuestsByStatus
                      quests={questsNeedingAttention}
                      status="Needs Attention"
                    />
                    <Divider show={(questsNeedingAttention.length > 0) && (questsUnderReview.length > 0)} />
                    <QuestsByStatus
                      quests={questsUnderReview}
                      status="Under Review"
                    />
                    <Divider show={(questsNeedingAttention.length > 0 || questsUnderReview.length > 0) && (questsCompleted.length > 0)} />
                    <QuestsByStatus
                      quests={questsCompleted}
                      status="Complete"
                    />
                  </>
                )}
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
}

export default MyQuests;

const dividerStyle = {
  border: 'solid 1px #313337',
  height: '1px',
  margin: '0 3px 12px 20px',
  width: '390px',
};

function Divider({ show }) {
  if (!show) return null;
  return <div style={dividerStyle} />;
}

function QuestsByStatus({ quests, status }) {
  if (quests.length === 0) return null;
  return (
    <section className="QuestByStatus">
      <h4 className="QuestByStatus__title">{status}</h4>
      {quests.map(quest => (
        <QuestItem
          key={quest.id}
          quest={quest}
          status={status}
        />
      ))}
    </section>
  );
}

function QuestItem({ quest, status }) {
  const {
    endDateTime, expectedPayoutDate, game, id, startDateTime, streamTimeRemaining, submissionsNeeded, title,
  } = quest;
  const dateInterval
    = (startDateTime && endDateTime)
      ? `${localizeIncorrectDate(startDateTime).format('MM/DD')} - ${localizeIncorrectDate(endDateTime).format('MM/DD')}`
      : 'No date';

  return (
    <Link key={id} href={`/my-quests/${id}`}>
      <button className="QuestByStatus__item" key={id}>
        <figure
          className="QuestByStatus__item--cover"
          style={{ backgroundImage: `url(${game.cover})` }}
          alt="Game Logo"
        />
        <div className="QuestByStatus__item--details">
          <div className="QuestByStatus__item--details-title">
            {title.length > 60 ? `${title.substring(0, 60)}...` : title}
          </div>
          <div className="QuestByStatus__item--details-date">
            {dateInterval}
          </div>
          {(status === 'Needs Attention') && (
            <>
              {(submissionsNeeded > 0) && (
                <div className="QuestByStatus__item--details-requirements failure">
                  {`${submissionsNeeded} submissions needed`}
                </div>
              )}
              {(streamTimeRemaining > 0) && (
                <div className="QuestByStatus__item--details-requirements failure">
                  {`Stream for ${streamTimeRemaining} minutes`}
                </div>
              )}
            </>
          )}
          {(status === 'Under Review') && (
            <div className="QuestByStatus__item--details-requirements underReview">
              Under review
            </div>
          )}
          {(status === 'Complete' && expectedPayoutDate) && (
            <div className="QuestByStatus__item--details-requirements success">
              {`Payment arrives by ${localizeIncorrectDate(expectedPayoutDate).format('MM/DD')}`}
            </div>
          )}
        </div>
      </button>
    </Link>
  );
}
