/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import tzlist from 'timezonelist-js';

// import moment from 'moment';
import CustomSelectBasic from '../../utils/redux-forms-custom-fields/CustomSelectBasic';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
// import CustomDatePicker from '../../utils/redux-forms-custom-fields/CustomDatePicker';
import CustomDropDown from '../../utils/redux-forms-custom-fields/CustomDropDown';
// import CustomDropDownTimezone from '../../utils/redux-forms-custom-fields/CustomDropDownTimezone';
import CustomDropDownArray from '../../utils/redux-forms-custom-fields/CustomDropDownArray';
import CustomDropDownGeneric from '../../utils/redux-forms-custom-fields/CustomDropDownGeneric';
import { validateTwitterHandle, throttle } from '../../utils/functions';

class CompleteRegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.throttledOnSubmit = throttle(this.props.onSubmit, 3000);
    this.state = {
      streamingConsoles: {
        pc: false,
        xbox: false,
        playstation: false,
        nintendo: false,
        mobile: false,
      },
    };
  }

  formatMonth = (arg) => {
    switch (arg) {
      case 'January':
        return '01';
      case 'February':
        return '02';
      case 'March':
        return '03';
      case 'April':
        return '04';
      case 'May':
        return '05';
      case 'June':
        return '06';
      case 'July':
        return '07';
      case 'August':
        return '08';
      case 'September':
        return '09';
      case 'October':
        return '10';
      case 'November':
        return '11';
      case 'December':
        return '12';
      default:
        return '';
    }
  }

  onChangeStreamingConsoles(checkbox) {
    const newStreamingConsoles = this.state.streamingConsoles;
    newStreamingConsoles[checkbox.target.id] = !this.state.streamingConsoles[checkbox.target.id];
    this.setState({
      streamingConsoles: newStreamingConsoles,
    });
  }


  submit = (userFormInfo) => {
    const userInfo = userFormInfo;
    userInfo.streamingConsoles = this.state.streamingConsoles;
    userInfo.birthDate = `${userFormInfo.year}-${this.formatMonth(userFormInfo.month)}-${userFormInfo.day}`;
    this.throttledOnSubmit(userInfo);
  }

  render() {
    const { handleSubmit, daysValues } = this.props;
    const tzOptions = tzlist.map(tz => ({
      label: tz.text,
      value: tz.text,
    }));

    const streamingConsoleOptions = [
      { label: 'PC', value: 'pc' },
      { label: 'Xbox', value: 'xbox' },
      { label: 'PlayStation', value: 'playstation' },
      { label: 'Nintendo', value: 'nintendo' },
      { label: 'Mobile', value: 'mobile' },
    ];

    return (
      <form className="form-container completeregistration signup" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="text"
          name="firstName"
          id="firstName"
          placeholder="First Name"
          customLabel2={(<label data-label="First Name" data-label-small="Your First Name here" />)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last Name"
          customLabel2={(<label data-label="Last Name" data-label-small="Your Last Name here" />)}
          component={CustomInput}
        />
        <div className="input-container">
          <label>
            Your birth day here
          </label>
          <div className="input-wrapper">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Field
                type="text"
                name="month"
                id="month"
                flexBasis="39%"
                time="months"
                component={CustomSelectBasic}
              />
              <Field
                type="text"
                name="day"
                id="day"
                flexBasis="19%"
                time="days"
                component={CustomSelectBasic}
                options={daysValues}
              />
              <Field
                type="text"
                name="year"
                id="year"
                flexBasis="39%"
                time="years"
                component={CustomSelectBasic}
              />
            </div>
          </div>
        </div>
        <Field
          type="text"
          name="country"
          id="country"
          customLabel2={(<label data-label="Country" data-label-small="Your country here" />)}
          component={CustomDropDown}
        />
        {/*
        <Field
          type="text"
          name="timezone"
          id="timezone"
          customLabel="What time zone will you be streaming in?"
          initialValue={this.props.userTimezone}
          component={CustomDropDownTimezone}
        />
        */}
        <Field
          name="gender"
          id="gender"
          placeholder="Select Gender"
          customLabel={((<label>Your gender here</label>))}
          component={CustomDropDownGeneric}
          options={['Male', 'Female', 'Other']}
        />
        <Field
          name="timezone"
          id="timezone"
          placeholder="Select Time Zone"
          customLabel={((<label>Stream Time Zone</label>))}
          component={CustomDropDownArray}
          options={tzOptions}
        />
        <Field
          placeholder="@twittername"
          type="text"
          name="twitter"
          id="twitter"

          customLabel={((<label>Twitter</label>))}
          component={CustomInput}
        />
        <Field
          name="discord"
          id="discord"
          placeholder="Discord User"
          type="text"
          customLabel={((<label>Discord</label>))}
          component={CustomInput}
        />

        <label className="consoles"> Streaming consoles </label>
        {
          streamingConsoleOptions.map(platform => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <input
                name={`${platform.value}`}
                id={`${platform.value}`}
                customLabel={`${platform.label}`}
                type="checkbox"
                style={{ width: '30px' }}
                onChange={this.onChangeStreamingConsoles.bind(this)}
                defaultChecked={this.state.streamingConsoles[`${platform.value}`]}
              />
              <span>{platform.label}</span>
            </div>
            ))
        }

        <div className="form__footer grid-x">
          <div className="form__footer__left small-5">
            {this.props.auth.isLoading && (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />)}
            {!this.props.auth.isLoading && (
            <button className="cta-button">
              Continue
            </button>)}
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  // const isValidDate = moment(values.birthDate, dateStoreFormat, true).isValid();
  const errors = {};
  if (!values.firstName || values.firstName.trim() === '') {
    errors.firstName = 'This field is required';
  }
  if (!values.lastName || values.lastName.trim() === '') {
    errors.lastName = 'This field is required';
  }
  if (!values.gender || values.gender.trim() === '') {
    errors.gender = 'This field is required';
  }
  if (!values.timezone || values.timezone.trim() === '') {
    errors.timezone = 'This field is required';
  }
  if (!values.day || values.day.trim() === '' || values.day === 'day') {
    errors.day = 'Required';
  }
  if (!values.month || values.month.trim() === '' || values.month.toLowerCase() === 'month') {
    errors.month = 'Required';
  }
  if (!values.year || values.year.trim() === '' || values.year === 'year') {
    errors.year = 'Required';
  }
  if (values.twitter && !validateTwitterHandle(values.twitter)) {
    errors.twitter = 'Incorrect format. Missing "@"';
  }
  /*
  if (!values.birthDate) {
    errors.birthDate = 'This field is required';
  } else if (!isValidDate) {
    errors.birthDate = `This date is not valid try with ${dateStoreFormat}`;
  }
  */
  if (!values.birthDate || values.birthDate.trim() === '') {
    errors.birthDate = 'This field is required';
  }
  if (!values.country) {
    errors.country = 'This field is required';
  }
  /*
  if (!values.timezone) {
    errors.timezone = 'This field is required';
  }
  */


  return errors;
};


CompleteRegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'completeRegistrationForm',
  validate,
})(CompleteRegistrationForm);
