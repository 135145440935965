import React from 'react'
import PixelToad from '../../../images/client-portal/8-bit-toad-bw@3x.png';
import './NothingHere.scss'

const NothingHere = ({text = "Nothing Here (yet...)"}) => (
  <div className="NothingHere mt-10">
    <div className="nothing-here-image">
      <img src={PixelToad} alt="Nothing Here" />
    </div>
    <div>
    {text}
    </div>
  </div>
);


export default NothingHere