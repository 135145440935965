import jwtDecode from 'jwt-decode';
import { isString } from 'lodash';
// import userUpdateCheck from '../utils/userUpdateCheck';

const auth = (state = {}, action) => {
  switch (action.type) {
    case 'SIGN_IN_PENDING':
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'SIGN_IN_ERROR':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };

    case 'SIGN_IN_ABORTED':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'SIGN_IN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: {
          connectedAccounts: {
            twitch: {
              id: null,
              username: null,
            },
            youtube: {
              id: null,
              username: null,
            },
            huya: {
              id: null,
              username: null,
            },
            twitter: {
              id: null,
              username: null,
            },
          },
          ...action.payload.user,
        },
        isAuthenticated: true,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'SIGN_UP_PENDING':
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'SIGN_UP_ERROR':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };

    case 'SIGN_UP_ABORTED':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'SIGN_UP_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: {
          connectedAccounts: {
            twitch: {
              id: null,
              username: null,
            },
            youtube: {
              id: null,
              username: null,
            },
            huya: {
              id: null,
              username: null,
            },
            twitter: {
              id: null,
              username: null,
            },
          },
          ...action.payload.user,
        },
        isAuthenticated: true,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_PENDING':
      return {
        ...state,
        isLoading: true,
        recoverSuccess: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_ERROR':
      return {
        ...state,
        isLoading: false,
        recoverSuccess: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };

    case 'RECOVER_ABORTED':
      return {
        ...state,
        isLoading: false,
        recoverSuccess: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_SUCCESS':
      return {
        ...state,
        isLoading: false,
        recoverSuccess: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_VALIDATE_PENDING':
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_VALIDATE_ERROR':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };

    case 'RECOVER_VALIDATE_ABORTED':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_VALIDATE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_CHANGE_PASSWORD_PENDING':
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_CHANGE_PASSWORD_ERROR':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };

    case 'RECOVER_CHANGE_PASSWORD_ABORTED':
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };

    case 'RECOVER_CHANGE_PASSWORD_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: {
          connectedAccounts: {
            twitch: {
              id: null,
              username: null,
            },
            youtube: {
              id: null,
              username: null,
            },
            huya: {
              id: null,
              username: null,
            },
          },
          ...action.payload.user,
        },
        isAuthenticated: true,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'LOGIN_TWITCH_OAUTH_CODE_PENDING':
      return {
        ...state,
        twitch: {
          ...state.twitch,
          isLoading: true,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_TWITCH_OAUTH_CODE_ERROR':
      return {
        ...state,
        twitch: {
          ...state.twitch,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'LOGIN_TWITCH_OAUTH_CODE_ABORTED':
      return {
        ...state,
        twitch: {
          ...state.twitch,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_TWITCH_OAUTH_CODE_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
        twitch: {
          ...state.twitch,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_TWITTER_OAUTH_CODE_PENDING':
      return {
        ...state,
        twitter: {
          ...state.twitter,
          isLoading: true,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_TWITTER_OAUTH_CODE_ERROR':
      return {
        ...state,
        twitter: {
          ...state.twitter,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'LOGIN_TWITTER_OAUTH_CODE_ABORTED':
      return {
        ...state,
        twitter: {
          ...state.twitter,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_TWITTER_OAUTH_CODE_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
        twitter: {
          ...state.twitter,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_YOUTUBE_OAUTH_CODE_PENDING':
      return {
        ...state,
        youtube: {
          ...state.youtube,
          isLoading: true,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_YOUTUBE_OAUTH_CODE_ERROR':
      return {
        ...state,
        youtube: {
          ...state.youtube,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'LOGIN_YOUTUBE_OAUTH_CODE_ABORTED':
      return {
        ...state,
        youtube: {
          ...state.youtube,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGIN_YOUTUBE_OAUTH_CODE_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
        youtube: {
          ...state.youtube,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'YOUTUBE_DISCONNECTION_PENDING':
      return {
        ...state,
        youtube: {
          ...state.youtube,
          isLoading: true,
          isDisconnecting: true,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'YOUTUBE_DISCONNECTION_ERROR':
      return {
        ...state,
        youtube: {
          ...state.youtube,
          isLoading: false,
          isDisconnecting: false,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'YOUTUBE_DISCONNECTION_ABORTED':
      return {
        ...state,
        youtube: {
          ...state.youtube,
          isLoading: false,
          isDisconnecting: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'YOUTUBE_DISCONNECTION_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
        youtube: {
          ...state.youtube,
          isLoading: false,
          isDisconnecting: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_USER_INFO_PENDING':
      return {
        ...state,
        isLoading: true,
        isUpdating: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'UPDATE_USER_INFO_ERROR':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'UPDATE_USER_INFO_ABORTED':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'UPDATE_USER_INFO_SUCCESS':
      localStorage.setItem('token', action.payload.data.token);
      return {
        ...state,
        token: action.payload.data.token,
        user: {
          connectedAccounts: {
            twitch: {
              id: null,
              username: null,
            },
            youtube: {
              id: null,
              username: null,
            },
            huya: {
              id: null,
              username: null,
            },
          },
          ...action.payload.data.user,
        },
        // isAuthenticated: true,
        shouldRedirect: false,
        isUpdating: false,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'GET_USER_STATISTICS_PENDING':
      return {
        ...state,
        twitch: {
          ...state.twitch,
          isLoading: true,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_USER_STATISTICS_ERROR':
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_USER_STATISTICS_ABORTED':
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_USER_STATISTICS_SUCCESS':
      return {
        ...state,
        statistics: {
          ...state.statistics,
          data: action.payload.data,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('firstLogin');
      return {
        ...state,
        user: {},
        token: '',
        isAuthenticated: false,
      };
    case 'GLOBAL_INIT': {
      const token = localStorage.getItem('token');
      const firstLogin = localStorage.getItem('firstLogin');

      const decoded = isString(token) ? jwtDecode(token) : null;
      if (decoded) {
        const currentDate = new Date();
        const tokenExpDate = new Date(0);
        tokenExpDate.setUTCSeconds(decoded.exp);
        if (tokenExpDate > currentDate) {
          return {
            ...state,
            user: decoded.user,
            token,
            isAuthenticated: true,
            rehydrated: true,
            firstLogin,
          };
        }
        // Token Expired
        localStorage.removeItem('token');
        localStorage.removeItem('firstLogin');
        return { ...state, rehydrated: true };
      }
      return { ...state, rehydrated: true };
    }
    case 'DISCONNECTING_TWITCH_SUCCESS': {
      if (action.payload.success) {
        localStorage.setItem('token', action.payload.token);
        return {
          ...state,
          token: action.payload.token,
          user: {
            ...action.payload.user,
            connectedAccounts: {
              ...action.payload.user.connectedAccounts,
              twitch: {
                username: null,
                id: null,
              },
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'UPDATE_PROFILE_INFO_PENDING':
      return {
        ...state,
        isLoading: true,
        isUpdating: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'UPDATE_PROFILE_INFO_ERROR':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'UPDATE_PROFILE_INFO_ABORTED':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'UPDATE_PROFILE_INFO_SUCCESS':
      localStorage.setItem('token', action.payload.data.token);
      return {
        ...state,
        token: action.payload.data.token,
        user: {
          connectedAccounts: {
            twitch: {
              id: null,
              username: null,
            },
            youtube: {
              id: null,
              username: null,
            },
            huya: {
              id: null,
              username: null,
            },
          },
          ...action.payload.data.user,
        },
        // isAuthenticated: true,
        shouldRedirect: false,
        isUpdating: false,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'VALIDATE_USER_HANDSHAKE_PENDING':
      return {
        ...state,
        isLoading: true,
        isUpdating: true,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'VALIDATE_USER_HANDSHAKE_ERROR':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'VALIDATE_USER_HANDSHAKE_ABORTED':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    case 'VALIDATE_USER_HANDSHAKE_SUCCESS':
      if (action.payload.data.match) {
        return {
          ...state,
          shouldRedirect: false,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.errors,
            hasError: false,
            code: '',
            message: '',
          },
        };
      }

      localStorage.setItem('token', action.payload.data.token);
      return {
        ...state,
        token: action.payload.data.token,
        user: action.payload.data.user,
        shouldRedirect: false,
        isUpdating: false,
        isLoading: false,
        errors: {
          ...state.errors,
          hasError: false,
          code: '',
          message: '',
        },
      };
    default:
      return state;
  }
};

export default auth;
