import React from 'react';

import Categories from '../../CustomInputs/Categories';
import CustomTextField from '../../CustomInputs/CustomTextField';
import SocialNetworks from '../../CustomInputs/SocialNetworks';

const Social = ({ form, handleFormChange, errors }) => (
  <>
    <CustomTextField
      header="WEBSITE URL"
      name="gamePageURL"
      value={form.gamePageURL}
      onChange={handleFormChange}
      error={errors.includes('gamePageURL')}
    />
    <CustomTextField
      header="HEADLINE"
      name="headline"
      value={form.headline}
      onChange={handleFormChange}
      error={errors.includes('headline')}
    />
    <CustomTextField
      header="HEADLINE VIDEO"
      name="headlineVideo"
      value={form.headlineVideo}
      onChange={handleFormChange}
      error={errors.includes('headlineVideo')}
    />
    <Categories
      value={form.categories}
      handleFormChange={handleFormChange}
    />
    <SocialNetworks
      value={form.socialNetworks}
      form={form}
      handleFormChange={handleFormChange}
      enableNameInCards={false}
    />
  </>
);

export default Social;
