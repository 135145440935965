import stunlock from '../assetsV2/brands/stunlock.png';
import snail from '../assetsV2/brands/snail-games.png';
import linceworks from '../assetsV2/brands/linceworks.png';
import bose from '../assetsV2/brands/bose.png';
import sega from '../assetsV2/brands/sega.png';
import nexon from '../assetsV2/brands/nexon.png';
import stridepr from '../assetsV2/brands/stridepr.png';
import donkey from '../assetsV2/brands/donkey-crew.png';
import squareEnix from '../assetsV2/brands/square-enix.png';
import redbullrecords from '../assetsV2/brands/redbullrecords.png';
import xseed from '../assetsV2/brands/xseed.png';
import wildCard from '../assetsV2/brands/wildcard.png';
import pqube from '../assetsV2/brands/pqube.png';
import facebook from '../assetsV2/brands/facebook.png';
import unknowng from '../assetsV2/brands/unknowng.png';
import thatgamecompany from '../assetsV2/brands/thatgamecompany.png';
import softGiving from '../assetsV2/brands/softgiving.png';

const brands = [
  stunlock,
  snail,
  linceworks,
  bose,
  sega,
  nexon,
  stridepr,
  donkey,
  squareEnix,
  redbullrecords,
  xseed,
  wildCard,
  pqube,
  facebook,
  unknowng,
  thatgamecompany,
  softGiving,
];

export default brands;
