import React from 'react';

import Icon from '../../../../global/images/icons/Icon';

import './LargeToggle.scss';

const LargeToggle = (props) => {
  const {
    header = '',
    leftColor = '#393939',
    leftIcon = null,
    rightColor = '#a142ff',
    rightIcon = null,
    name = '',
    onChange,
    value = false,
  } = props;

  const handleChange = () => {
    onChange(name, !value);
  };

  return (
    <div className="LargeToggle">
      {!!header && <div className="LargeToggle_Header">{header}</div>}
      <div
        className="LargeToggle_Toggle"
        onClick={handleChange}
        style={{
          borderColor: value ? rightColor : leftColor,
        }}
      >
        <div className='LargeToggle_Toggle_Ball' style={{
          backgroundColor: value ? rightColor : leftColor,
          transform: `translate(${value ? '16px' : '-16px'}, -50%)`,
        }} />
        {leftIcon && (
          <div className='LargeToggle_Toggle_Left'>
            <Icon name={leftIcon} color={rightColor} />
          </div>
        )}
        {rightIcon && (
          <div className='LargeToggle_Toggle_Right'>
            <Icon name={rightIcon} color={leftColor} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LargeToggle;
