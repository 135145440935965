import React, { useState } from 'react';
import classnames from 'classnames';

import Icon from '../../../../global/images/icons/Icon';
import Loading from '../../../../global/components/loading/Loading';

import './GlossyDropdown.scss';

const GlossyDropdown = (props) => {
  const {
    options = [],
    dropdownText = 'Select an option',
    loading = false,
    onSubmit,
    submitText = 'Submit',
  } = props;

  const [selectedOption, setSelectedOption] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const Option = ({ option }) => (
    <div className={`GlossyDropdown__Option ${option?.disabled ? 'Disabled' : ''}`} onClick={() => {
      if (!option?.disabled) setSelectedOption(option);
    }}>
      <div className='GlossyDropdown__Option__Left'>
        {option?.img && <img className='GlossyDropdown__Option__Img' src={option.img} alt={option.label} />}
        <div className='GlossyDropdown__Option__Label'>{option.label}</div>
      </div>
      <div className={classnames('GlossyDropdown__Option__Button', selectedOption.value === option.value && 'Selected')} />
    </div>
  );

  return (
    <div className="GlossyDropdown">
      <div className={classnames('GlossyDropdown__Title', isOpen && 'isOpen')} onClick={() => setIsOpen(!isOpen)}>
        {dropdownText}
        <Icon name={isOpen ? 'caretup' : 'caretdown'} />
      </div>
      <div className={classnames('GlossyDropdown__Options', isOpen && 'isOpen')}>
        {options.map(option => <Option option={option} key={option.value} />)}
        <div className='GlossyDropdown__Option GlossyDropdown__Submit'>
          <div
            className='GlossyDropdown__Submit__Button'
            disabled={!selectedOption.value}
            onClick={() => onSubmit(selectedOption.value)}
          >
            {!loading && submitText}
            {loading && <Loading />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlossyDropdown;
