import React from 'react';
import { Link } from 'react-router';
import { htmlToPlainText } from '../../utils/functions';
import './GameCard.scss';

const maxChar = 72;
const truncate = str => `${str.slice(0, maxChar) + (str.length > maxChar ? '...' : '')}`;

function GameCard({
  data: {
    cover, developer, developerLogo, id, name, headline = '',
  },
}) {
  const headlineText = htmlToPlainText(headline);
  const truncated = headlineText.length > maxChar;

  return (
    <div className="game-card">
      <div className="image-container">
        <div
          className="image"
          style={{ backgroundImage: `url(${cover})` }}
        >
          <div className="image-overlay" />
        </div>
      </div>
      <div className="info">
        <div className="dev">
          <img
            alt="logo"
            className="logo"
            src={developerLogo}
          />
          <div className="names">
            <span className="dev-name">
              {developer}
            </span>
            <span className="game-name">
              {name}
            </span>
          </div>
        </div>
        <div
          className="game-description"
          data-tip={truncated ? headlineText : undefined}
        >
          {truncate(headline)}
        </div>
        <Link
          className="view-quests-button"
          to={`/game/${id}`}
        >
          <span className="quest-button-text">
            View More
          </span>
        </Link>
      </div>
    </div>
  );
}

export default GameCard;
