import React from 'react';
// import PropTypes from 'prop-types';
import $ from 'jquery';
import qs from 'qs';
import { Link } from 'react-router';
import classNames from 'classnames';
import { simpleNotification } from '../../../utils/notifications';
import ContactLightForm from '../../forms/ContactLightForm';
import './ContactLight.scss';

class ContactLight extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingErrors: false,
      errors: {
        hasError: false,
        code: '',
        message: '',
        all: {},
      },
      activeModal: false,
    };
  }

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.isLoading === true && nextProps.auth.isLoading === false) {
      // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      }
    }
  }

  onSubmit = (allFields) => {
    this.submitGForm(allFields);
  }

  submitGForm = (allFields) => {
    const baseURL = 'https://docs.google.com/forms/d/e/1FAIpQLSfImSeGuX-GyABX6L0pWny9FuuzB8xK3FSuQaKac8r-djjTzA/formResponse?';
    const baseGFormFields = {
      submit: 'Submit',
      emailAddress: allFields.email ? allFields.email : '',
      'entry.351391559': allFields.contactName ? allFields.contactName : '',
      'entry.1587419735': allFields.website ? allFields.website : '',
      'entry.591337854': allFields.companyName ? allFields.companyName : '',
      'entry.617606079': allFields.comments ? allFields.comments : '',
    };

    const parameters = qs.stringify(baseGFormFields);

    const completeUrl = `${baseURL}${parameters}`;

    $.ajax({
      type: 'GET',
      url: completeUrl,
      contentType: 'application/x-www-form-urlencoded',
      success: () => {
        this.setState({
          activeModal: true,
        });
        simpleNotification({
          level: 'success',
          title: 'Thank you!',
          message: 'Thanks for reaching out! We\'ll be in touch shortly.',
        });
      },
      error: () => {
        this.setState({
          activeModal: true,
        });
        simpleNotification({
          level: 'success',
          title: 'Thank you!',
          message: 'Thanks for reaching out! We\'ll be in touch shortly.',
        });
      },
    });
  }

  dismissErrors = () => {
    this.setState({
      showingErrors: false,
      errors: {
        hasError: false,
        code: '',
        message: '',
        all: {},
      },
    });
  }

  render() {
    const { showingErrors, errors, activeModal } = this.state;
    return (
      <section className="ContactLight">
        <section className="ContactLight__wrapper">
          <section className="ContactLight__content">
            <div className="ContactLight__content__back" />
            <div className="ContactLight__content__color" />
            <div className="ContactLight__content__square1" />
            <div className="ContactLight__content__square2" />
            <div className="ContactLight__content__rectangle" />
            <div className="ContactLight__left">
              <div className="ContactLight__left__content">
                <div className="ContactLight__title">
                  Contact
                  <br />
                  our team
                </div>
                <div className="ContactLight__left__space" />
                <div className="ContactLight__left__rectangle" />
                <div className="blurb">
                  Our team is happy to answer your sales questions. Fill out the form and we'll be in touch as soon as possbile.
                </div>
              </div>
            </div>
            <div className="ContactLight__right">
              <div className="ContactLight__right__content">
                <section className={classNames('errors-container', { active: showingErrors })}>
                  <button className="error-dismiss" type="button" onClick={this.dismissErrors.bind(this)} />
                  <div className="error-message">
                    { errors.message }
                  </div>
                </section>
                <ContactLightForm onSubmit={this.onSubmit.bind(this)} />
              </div>
            </div>
          </section>
        </section>
        <section className="ContactLightSuccess">
          <section className="ModalMaster">
            <section className={classNames('Modal Modal__store__game__success', { active: activeModal })}>
              <Link to="/" href="/" className="Modal__layer" role="button" aria-hidden />
              <section className="Modal__wrapper">
                <Link
                  className="Modal__close-btn"
                  to="/"
                  href="/"
                  role="button"
                  aria-hidden
                />
                <section className="Modal__success__content">
                  <div className="Modal__success__image" />
                  <div className="Modal__success__title">
                    Thank you for contacting Noiz!
                  </div>
                  <div className="Modal__success__description">
                    Our account managers will be in
                    <br />
                    touch shortly!
                  </div>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

ContactLight.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
};

export default ContactLight;
