import React, { useEffect, useState } from 'react';
import AdminTabs from '../util/tabs/AdminTabs';
import InsightsFilterBar from './InsightsFilterBar.js';
import TimeframeModule from './sidebar/TimeframeModule.js';
import InsightsGraph from './graph/InsightsGraph.js';
import RegionModule from './modules/RegionModule';
import MostWatchedModule from './sidebar/MostWatchedModule.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReloadButton from './ReloadButton';
import RunButton from './RunButton';
import { filterUsers, get30DayDateRange } from './helpers/index.js';
import { getInitialData, getInsights } from './queries';
import './Insights.scss';

const message = "Gathering thousands of records. This might take a bit..."

function Insights(props) {
  const [dateRange, setDateRange] = useState(get30DayDateRange());
  const [filtered, setFiltered] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(false);
  const [initialData, setInitialData] = useState({
    allUsers: [],
    games: [],
    isLoading: true,
    maxACCV: 0,
    maxQuests: 0,
  });

  const [insightsData, setInsightsData] = useState({
    graphData: [],
    isLoading: false,
    regionData: [],
    totalReach: 0,
    userData: [],
  });

  const [selected, setSelected] = useState([]);
  const { allUsers, games, isLoading: isLoadingInitialData, maxACCV, maxQuests } = initialData;
  const { graphData, isLoading: isLoadingInsightsData, regionData, totalReach, userData } = insightsData;
  const { auth: { token } } = props;

  const handleInitialData = isReload => {
    getInitialData({ isReload, token }, data => {
      setInitialData({ ...data, isLoading: false });
    });
  };

  useEffect(() => {
    handleInitialData(false);
  }, [])

  // Handle user filtering after filters have been updated
  useEffect(() => {
    if (filtered) {
      setFilteredUsers(filterUsers(allUsers, selected));
    }
  }, [filtered, selected]);

  const updateFilters = selected => {
    setSelected({ ...selected });
    setFiltered(true);
  }

  const applyDates = dateRange => {
    setDateRange([...dateRange]);
    updateFilters(selected);
  }

  const handleInsightData = () => {
    const usersToSend = (filteredUsers.length === 0 && !filtered) ? allUsers : filteredUsers;
    setInsightsData({ ...insightsData, isLoading: true });
    getInsights({
      allUsers: usersToSend.length === allUsers.length,
      dateRange,
      filtered,
      selected,
      token,
      users: usersToSend,
    }, data => setInsightsData({
      ...data,
      isLoading: false,
    }));
  }

  const handleUserCompletion = completedUsers => {
    setInsightsData({
      ...insightsData,
      userData: userData.map(user => {
        const completedUser = completedUsers.find(u => user.id === u.id);
        if (completedUser) {
          return {
            ...user,
            ...completedUser,
          }
        } else {
          return user;
        }
      })
    });
  }

  return (
    <div className="insights-container">
      <AdminTabs {...props} />
      <h1>INSIGHTS</h1>
      {isLoadingInitialData ? <Loading /> : (
        <>
          <InsightsFilterBar
            allUsers={allUsers}
            games={games}
            isLoadingInitialData={isLoadingInitialData}
            maxACCV={maxACCV}
            maxQuests={maxQuests}
            updateFilters={updateFilters}
          />
          {isLoadingInsightsData ? <Loading message={message} /> : (
            <div className="body">
              <div className="main">
                <InsightsGraph
                  dateRange={dateRange}
                  graphData={graphData}
                  singleUser={filteredUsers.length === 1}
                  token={token}
                  users={insightsData.userData}
                />
                <RegionModule regionData={regionData} />
              </div>
              <div className="sidebar">
                <div className="btn-container">
                  <RunButton onClick={handleInsightData} />
                  <ReloadButton onClick={() => handleInitialData(true)} />
                </div>
                <TimeframeModule
                  applyDates={applyDates}
                  dates={dateRange}
                  totalReach={totalReach}
                  userData={userData}
                />
                <MostWatchedModule
                  handleUserCompletion={handleUserCompletion}
                  userData={userData && userData.sort((a, b) => b.accv - a.accv).slice(0, 100)}
                // (filteredUsers.length === 0 && !noUsersMatchFilters) ? allUsers : filteredUsers
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
};

const Loading = ({ message }) => (
  <div className="progressWrapper">
    <CircularProgress
      style={{
        color: '#49e1ca',
        height: '80px',
        width: '80px',
      }}
      thickness={2}
    />
    {message && (
      <div style={{
        margin: '20px',
        fontWeight: '500',
        color: 'white',
        transform: 'translateX(-45%)'
      }}>
        {message}
      </div>
    )}
  </div>
);

export default Insights;
