import React from 'react';
// import { Link } from 'react-router';

import IconParticles from '../icon-particles/IconParticles';
import ContactForm from '../brand-service/slides/ContactForm';

import './Onboard.scss';

const Onboard = () => {
  return (
    <section className="Onboard">
      <section className="Onboard__container">

        <section className="Hero">
          <IconParticles />
          <section className="Hero__content">
            <h2 className="Hero__title">Onboard</h2>
          </section>
        </section>

        <section className="MainOnboard">
          <ContactForm />
        </section>
      </section>
    </section>);
};

export default Onboard;
