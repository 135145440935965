import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  Paper,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Switch,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import ActiveQuestsTable from './ActiveQuestsTable';
import {
  // confirmationBox,
  simpleNotification,
} from '../../../../utils/notifications';
import './EditUserQuest.scss';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(255,255,255,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      outline: '1px solid slategrey',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
  },
  tabs: {
    minWidth: 850,
  },
  paper: {
    backgroundColor: '#202227',
    // boxShadow: theme.shadows[5],
    padding: '60px 40px',
    color: 'white',
    overflow: 'auto',
    height: '90vh',
    outline: 'none',
  },
  paper2: {
    padding: '15px 10px',
    marginTop: '25px',
  },
  topMast: {
    // backgroundColor: '#313337',
    padding: '20px 40px',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: '40px 40px',
  },
  textField: {
    width: 200,
  },
  errorButton: {},
  confirmButton: {
    backgroundColor: 'green',
  },
  buttonGrid: {
    width: '100%',
    marginTop: '30px',
  },
  inputBox: {
    // minHeight: '100px',
    // height: '200px',

    lineHeight: 2,
  },
  warning: {
    color: 'red',
    marginTop: '20px',
    // width: '20%',
  },
  h1: {
    fontWeight: 600,
    fontSize: '25px',
  },
  memberEdit: {
    marginLeft: '15px',
  },
  noBack: {
    backgroundColor: 'transparent',
  },
  confirm: {
    marginTop: '25px',
  },
}));

export default function EditUserQuest(props) {
  const {
    currentUser,
    status,
  } = props;
  const { questId } = props.router.params;
  const { token } = props.auth;
  const classes = useStyles();
  const { data: activeQuests } = props.commandCenter.activeQuests;
  const activeQuestsPopulated = (activeQuests && activeQuests.length > 0);
  const [open, setOpen] = React.useState(false);
  const [moveQuestId, setMoveQuestId] = React.useState('');
  const [allowed, setAllowed] = React.useState('');
  const [removeQuestId, setRemoveQuestId] = React.useState('');
  const [moveQuestTitle, setMoveQuestTitle] = React.useState('');
  const [allowRemove, setAllowRemove] = React.useState(false);
  const [allowMove, setAllowMove] = React.useState(false);

  const handleChangeCheckboxRemove = (removeUserIdArg, removeQuestIdArg) => {
    let allowed = false;
    if (removeQuestId === removeQuestIdArg) {
      setRemoveQuestId('');
    } else {
      setRemoveQuestId(removeQuestIdArg);
      allowed = true;
    }

    setMoveQuestId('');
    setMoveQuestTitle('');
    setAllowed(allowed);
  };

  const handleChangeCheckboxMove = (moveUserIdArg, moveQuestIdArg, moveQuestTitleArg) => {
    let allowed = false;
    if (moveQuestId === moveQuestIdArg) {
      setMoveQuestId('');
      setMoveQuestTitle('');
    } else {
      setMoveQuestId(moveQuestIdArg);
      setMoveQuestTitle(moveQuestTitleArg);
      allowed = true;
    }

    setRemoveQuestId('');
    setAllowed(allowed);
  };

  const [tab, setTab] = React.useState(0);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
    setMoveQuestId('');
    setAllowed('');
    setRemoveQuestId('');
    setMoveQuestTitle('');
    setAllowRemove(false);
    setAllowMove(false);
  };

  const handleClose = () => {
    setOpen(false);
    setMoveQuestId('');
    setAllowed('');
    setRemoveQuestId('');
    setMoveQuestTitle('');
    setAllowRemove(false);
    setAllowMove(false);
  };

  const handleTabChange = (e, val) => {
    setTab(val);
  };

  const handleTabChangeIndex = (index) => {
    setTab(index);
  };

  const handleRemove = () => {
    if (!allowed) {
      simpleNotification({
        level: 'warning',
        title: 'Warning!',
        message: 'Not allowed to remove this quest',
      });
    } else {
      props.removeCCUserQuest(currentUser.id, removeQuestId, token);
    }
  };

  const handleMove = () => {
    if (!allowed) {
      simpleNotification({
        level: 'warning',
        title: 'Warning!',
        message: 'Not allowed to move to this quest',
      });
    } else {
      props.moveCCUserQuest(currentUser.id, questId, moveQuestId, status, token);
    }
  };

  /* const onSubmit = async () => {
  }; */

  const enableAccept = (allowMove && moveQuestId !== '') || (allowRemove && removeQuestId !== '');

  return (
    <div >
      <Tooltip title="Move User" placement="top">
        <button className="small-btn" onClick={handleOpen}><i className="fa fa-arrow-right no-padding" /></button>
      </Tooltip>
      {/* <IconButton size="small" className={classes.noBack} color="primary" onClick={handleOpen} aria-label="Edit Member Tier">
        <AttachMoneyIcon size="small" className={classes.noBack} />
      </IconButton> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <AppBar position="static" color="default">
              <h2> {currentUser.username} </h2>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="tabs"
                className={classes.tabs}
              >
                <Tab label="Current Game" />
                <Tab label="Other Game" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tab}
              onChangeIndex={handleTabChangeIndex}
            >
              <TabPanel value={tab} index={0} dir={theme.direction}>
                {
                  (activeQuestsPopulated) &&
                  <ActiveQuestsTable
                    {...props}
                    tab={0}
                    currentUser={currentUser}
                    allowMove={allowMove}
                    allowRemove={allowRemove}
                    status={status}
                    handleChangeCheckboxRemove={handleChangeCheckboxRemove}
                    handleChangeCheckboxMove={handleChangeCheckboxMove}
                    moveCheckbox={moveQuestId}
                    removeCheckbox={removeQuestId}
                  />
                }
                {
                  (!activeQuestsPopulated) &&
                  <React.Fragment>There are no quests for this game</React.Fragment>
                }
              </TabPanel>
              <TabPanel value={tab} index={1} dir={theme.direction}>
                {
                  (activeQuestsPopulated) &&
                  <ActiveQuestsTable
                    {...props}
                    tab={1}
                    currentUser={currentUser}
                    allowMove={allowMove}
                    allowRemove={allowRemove}
                    status={status}
                    handleChangeCheckboxRemove={handleChangeCheckboxRemove}
                    handleChangeCheckboxMove={handleChangeCheckboxMove}
                    moveCheckbox={moveQuestId}
                    removeCheckbox={removeQuestId}
                  />
                }
                {
                  (!activeQuestsPopulated) &&
                  <React.Fragment>There are no quests for this game</React.Fragment>
                }
              </TabPanel>
            </SwipeableViews>
            <Grid
              container
              className={classes.buttonGrid}
              xs={12}
              direction="row"
              width="100%"
              justify="space-between"
              alignItems="center"
            >
              <Grid>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (allowMove) {
                      handleMove();
                    } else if (allowRemove) {
                      handleRemove();
                    }
                  }}
                  disabled={!enableAccept}
                >
                  Accept
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={handleClose}
                  className={classes.errorButton}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.confirm}>
              <Switch
                checked={allowRemove}
                onChange={() => {
                  if (!allowRemove) {
                    setAllowMove(false);
                  }
                  // setRemoveQuestId('');
                  // setMoveQuestId('');
                  setAllowRemove(!allowRemove);
                }}
              />  I am sure I want to remove this user from this quest.
            </Grid>
            <Grid className={classes.confirm}>
              <Switch
                checked={allowMove}
                onChange={() => {
                  if (!allowMove) {
                    setAllowRemove(false);
                  }
                  // setRemoveQuestId('');
                  // setMoveQuestId('');
                  setAllowMove(!allowMove);
                }}
              />  I am sure I want to move this user to the following quest: {moveQuestTitle}.
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
