import React from 'react';

import AddGameModal from './AddGameModal';
import SelectedGames from './SelectedGames';

import './RequiredGames.scss';

const RequiredGames = (props) => {
  const { onChange, values = [], token } = props;

  const handleRemoveSelectedGame = game => onChange(values.filter(g => g !== game));
  const handleAddSelectedGame = (game) => {
    values.push(game);
    onChange(values.sort());
  };

  return (
    <div className="RequiredGames">
      <label className="RequiredGames--label">Required Previously Played Games:</label>
      {/* {selectedGamesRender} */}
      {values && values.length > 0 && <SelectedGames games={values} onClick={handleRemoveSelectedGame} />}
      <AddGameModal
        handleAddSelectedGame={handleAddSelectedGame}
        token={token}
        values={values}
      />
    </div>
  );
};

export default RequiredGames;
