/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import CustomInputCheckbox from '../../utils/redux-forms-custom-fields/CustomInputCheckbox';
import CustomDropDown from '../../utils/redux-forms-custom-fields/CustomDropDown';
import CustomDropDownAccount from '../../utils/redux-forms-custom-fields/CustomDropDownAccount';

import dots from '../../utils/dots.svg';


class SignupFracturedLands extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit(allFields) {
    // Passing this params a level up on submit parent function.
    this.props.onSubmit(allFields);
  }

  render() {
    const { handleSubmit, isLoading, isUpdating } = this.props;

    return (
      <form
        className="form-container"
        autoComplete="off"
        onSubmit={handleSubmit(this.submit.bind(this))}
      >
        <div className="grid-x">
          <div className="large-6 cell">
            <Field
              type="text"
              name="firstname"
              id="fname"
              customLabel={(<div>First Name</div>)}
              component={CustomInput}
            />
          </div>

          <div className="large-6 cell">
            <Field
              type="text"
              name="lastname"
              id="lname"
              value="Last Name"
              customLabel={(<div>Last Name</div>)}
              component={CustomInput}
            />
          </div>
        </div>

        <div className="grid-x">
          <div className="large-8 cell">
            <Field
              type="text"
              name="email"
              id="email"
              customLabel={(<div>Email</div>)}
              component={CustomInput}
            />

          </div>

          <div className="large-4 cell">
            <Field
              type="text"
              name="country"
              id="country"
              customLabel={(<div>Country</div>)}
              component={CustomDropDown}
            />

          </div>
          <div className="large-12 cell">
            <div className="email-message">
              For Twitch users, the email provided must be the same as your Twitch account.
              <br />
              For YouTube users, your email must be displayed on the about us section.
            </div>
          </div>
        </div>

        <div className="grid-x">
          <div className="large-12 cell error-accounts pr-15">
            <div className={this.props.hasErrorAccount ? 'hidden error-message2' : ''}>
              {this.props.hasErrorAccount}
            </div>
          </div>
        </div>
        <div className="grid-x">
          <div className="large-12 cell">
            <Field
              type="text"
              name="twitch"
              id="twitch-id"
              onChange={this.onChangeInputEA}
              customLabel={(<div>Twitch Username</div>)}
              component={CustomInput}
            />
          </div>

          <div className="large-12 cell">
            <Field
              type="text"
              name="youtube"
              id="youtube-id"
              onChange={this.onChangeInputEA}
              customLabel={(<div>YouTube Channel Name</div>)}
              component={CustomInput}
            />
          </div>
        </div>

        <div className="grid-x">
          <div className="large-12 cell">
            <Field
              type="text"
              name="twitter"
              id="twitter-id"
              customLabel={(<div>Twitter Handle</div>)}
              component={CustomInput}
            />
          </div>

          <div className="large-12 cell">
            <Field
              name="primary_account"
              id="primary-account-id"
              customLabel={((<div>Which account is your primary account?</div>))}
              component={CustomDropDownAccount}
              options={['Twitch', 'Youtube']}
              className="form-control"
            />
          </div>

          <div className="large-12 cell">
            <Field
              type="text"
              name="discord"
              id="discord-id"
              customLabel={(<div>Discord Account Handle</div>)}
              component={CustomInput}
            />
          </div>

          <div className="large-12 cell">
            <Field
              type="text"
              name="upload"
              id="upload-id"
              customLabel={(<div>Content upload URL</div>)}
              component={CustomInput}
            />
          </div>

          <div className="large-12 cell">
            <Field
              type="textarea"
              name="comment"
              id="comment"
              placeholder="Provide your beta access code here if you played during beta"
              component={CustomInput}
              customLabel={(<div>Comments</div>)}
            />
          </div>
        </div>


        <div className="large-12 cell">
          <div className="mailto-comments">
            Do you have an alternate proposal to becoming a partner? Email <a href="mailto:partnersupport@noiz.gg">partnersupport@noiz.gg</a>
          </div>
        </div>

        <br />
        <br />

        <div className="large-12 cell">
          <div className="terms-privacy">
            <Field
              type="checkbox"
              name="agree"
              id="agree"
              component={CustomInputCheckbox}
              customLabel={(
                <span style={{ margin: '0 0 0 5px' }}>
                  I acknowledge and agree that I have read, consent and agree to the <a rel="noopener noreferrer" target="_blank" href="https://noiz.gg/terms-of-use">Terms of Use</a> and <a rel="noopener noreferrer" target="_blank" href="https://noiz.gg/privacy-policy">Privacy Policy</a> and I am of legal age.
                </span>
              )}
            />
          </div>
        </div>

        {(isLoading || isUpdating) ?
          <button type="submit" value="SUBMIT" disabled className="btn btn-submit site-button">
            <img src={dots} alt="Loading" />
          </button> :
          <input type="submit" value="SUBMIT" className="btn btn-submit site-button" /> }
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.firstname || values.firstname.trim() === '') {
    errors.firstname = 'First Name is required.';
  }
  if (!values.lastname || values.lastname.trim() === '') {
    errors.lastname = 'Last Name is required.';
  }

  if (!values.email || values.email.trim() === '') {
    errors.email = 'Email is required.';
  }

  if (!values.primary_account || values.primary_account.trim() === '') {
    errors.primary_account = 'Primary account is required.';
  } else if (values.primary_account === 'Twitch') {
    if (!values.twitch || values.twitch.trim() === '') {
      errors.twitch = 'Twitch Account is required.';
    }
  } else if (values.primary_account === 'Youtube') {
    if (!values.youtube || values.youtube.trim() === '') {
      errors.youtube = 'Youtube Account is required.';
    }
  }

  let empty1 = false;
  let empty2 = false;

  if (!values.twitch || values.twitch.trim() === '') {
    empty1 = true;
  }

  if (!values.youtube || values.youtube.trim() === '') {
    empty2 = true;
  }

  if (empty1 && empty2) {
    errors.accounts = 'Please fill out one of the following: Twitch username or Youtube Channel Name';
  }

  if (!values.agree) {
    errors.agree = 'Agree our terms of use';
  }

  if (!values.country) {
    errors.country = 'Country is required';
  }

  return errors;
};


SignupFracturedLands.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'signupFracturedLands',
  validate,
})(SignupFracturedLands);
