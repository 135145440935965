/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import classNames from 'classnames';
import { findIndex, isBoolean } from 'lodash';
import Dropzone from 'react-dropzone';
import {
  Button,
  TextField,
} from '@material-ui/core';

import CustomDateTimePicker from '../../utils/redux-forms-custom-fields/CustomDateTimePicker';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import CustomInputCheckbox from '../../utils/redux-forms-custom-fields/CustomInputCheckbox';
import CustomInputYesNoRadio from '../../utils/redux-forms-custom-fields/CustomInputYesNoRadio';
import CustomDropDownGeneric from '../../utils/redux-forms-custom-fields/CustomDropDownGeneric';
import RichText from '../../utils/RichText';

import ArrayDropDown from '../../utils/redux-forms-custom-fields/ArrayDropDown';
import { arrayDropDownColors } from '../../utils/constants';
import { simpleNotification } from '../../utils/notifications';
import { getBrandUsersList } from '../../utils/apiv2';

import Platform from '../platform/Platform';

import './skin.min.css';

const dateStoreFormat = 'YYYY-MM-DD';
const dateDisplayFormat = 'MM-DD-YYYY';

const dateStoreFormat2 = 'YYYY-MM-DD HH:mm';
const dateDisplayFormat2 = 'MM-DD-YYYY HH:mm';

class AdminGameForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.rte_description = '';
    this.rte_saleDescription = '';
    this.rte_storeDescription = '';
    this.rte_soldOutMessage = '';
    this.rte_actionMessage = '';
    this.rte_api_key = 'wulnzhippckjtehbc6kaq1irabfkdvhgkgjezk8i24a9c653';
    this.rte_default_config = {
      skin: 'dark',
      menubar: false,
      plugins: 'link, lists, advlist',
      toolbar1: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat',
      content_style: '#tinymce{background-color:#282830 !important;color:#fff !important;}',
    };
    this.state = {
      infoTab: 'general',
      activeModal: 0,
      brandUsers: [],
      categories: [
        {
          name: 'Action',
          selected: false,
        },
        {
          name: 'Adventure',
          selected: false,
        },
        {
          name: 'Shooter',
          selected: false,
        },
        {
          name: 'Survival',
          selected: false,
        },
        {
          name: 'Open World',
          selected: false,
        },
      ],
      gallery: [],
      image: '',
      file: '',
      videoUrl: '',
      platformModal: false,
    };
    this.galleryId = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.preSignedUrl.isLoading === true && nextProps.preSignedUrl.isLoading === false) {
      const { data: signedUrl } = nextProps.preSignedUrl;
      this.uploadToS3(this.state.file, signedUrl.preSignedUrl);
    }
    if (this.props.game.isCreating === true && nextProps.game.isCreating === false) {
      if (nextProps.game.errors.hasError) {
        if (nextProps.game.errors.code === 'E_GAME_SLUG_DUPLICATED') {
          this.setState({ infoTab: 'sales' });
          // this.scrollToSlug();
        }
      }
    }
    if (this.props.game.isUpdating === true && nextProps.game.isUpdating === false) {
      if (nextProps.game.errors.hasError) {
        if (nextProps.game.errors.code === 'E_GAME_SLUG_DUPLICATED') {
          this.setState({ infoTab: 'sales' });
          // this.scrollToSlug();
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adminGameForm && prevProps.adminGameForm.values === undefined && this.props.adminGameForm.values) {
      const { categories, gallery } = this.props.adminGameForm.values;
      // Setting up cateogries
      for (let i = 0; i < this.state.categories.length; i += 1) {
        if (findIndex(categories, { name: this.state.categories[i].name }) >= 0) {
          const tempCategories = [...this.state.categories];
          tempCategories[i].selected = true;
          this.setState({
            categories: [...tempCategories],
          });
        }
      }
      // Setting up gallery
      for (let i = 0; i < gallery.length; i += 1) {
        this.galleryId += 1;
        this.state.gallery.push({
          id: this.galleryId,
        });
      }
    }
  }

  componentDidMount() {
    getBrandUsersList().then((response) => {
      this.setState({
        brandUsers: response?.data?.data || [],
      });
    });
  }

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const file = files && files.length ? files[0] : e[0];

    this.setState({ dropzoneActive: false });
    const { image } = this.state;
    /* if (image === 'cover' && file.size > 700000) {
      if (file.size > 700000) {
        simpleNotification({
          level: 'warning',
          title: 'Warning',
          message: 'Cover image has a limit of 700kb.',
        });
      }
    } else if ((image === 'developer-logo' || image === 'logo') && file.size > 400000) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Logo image has a limit of 400kb.',
      });
    } else if ((image === 'gallery-image') && file.size > 500000) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Gallery image has a limit of 500kb.',
      });
    } */
    this.setState({
      file,
    });
    const format = file.name.split('.')[1];
    const slug = this.getSlug();
    const { token } = this.props.auth;
    if (image === 'cover' ||
      image === 'coverPortrait' ||
      image === 'logo' ||
      image === 'developer-logo' ||
      image === 'gallery-image')
    {
      this.props.requestUploadImageUrl(slug, format, token);
    }

    this.setState({
      activeModal: 2,
    });
    // this.uploadToS3(file);
  }

  handleVideoUrl = (event) => {
    this.setState({
      videoUrl: event.target.value,
    });
  }

  getSlug = () => {
    const { adminGameForm } = this.props;
    const { name } = adminGameForm.values;
    if (!name) {
      return '';
    }
    let slug = name.replace(/[^0-9a-z ]/gi, '');
    slug = slug.split('  ').join(' ');
    slug = slug.split(' ').join('-');
    slug = slug.toLowerCase();
    return slug;
  }

  changeCategory = (index) => {
    const tempCategories = [...this.state.categories];
    tempCategories[index].selected = !this.state.categories[index].selected;
    this.setState({
      categories: [...tempCategories],
    });
  }

  uploadToS3 = (file, preSignedUrl) => {
    const { image } = this.state;
    if ((image === 'cover' || image === 'coverPortrait') && file.size > 700000) {
      if (file.size > 700000) {
        simpleNotification({
          level: 'warning',
          title: 'Warning',
          message: 'Cover image has a limit of 700kb.',
        });
        this.setState({
          activeModal: 1,
        });
        return;
      }
    } else if ((image === 'developer-logo' || image === 'logo') && file.size > 400000) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Logo image has a limit of 400kb.',
      });
      this.setState({
        activeModal: 1,
      });
      return;
    } else if ((image === 'gallery-image') && file.size > 500000) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Gallery image has a limit of 500kb.',
      });
      this.setState({
        activeModal: 1,
      });
      return;
    }
    this.setState({
      activeModal: 2,
    });
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', preSignedUrl);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.addEventListener('load', this.handleLoad);
    xhr.send(file);
  }

  handleLoad = (event) => {
    const { data: signedUrl } = this.props.preSignedUrl;
    const { image, videoUrl } = this.state;

    if (image !== 'video' && event.target.status !== 200) {
      this.setState({
        activeModal: 0,
      });
      simpleNotification({
        level: 'error',
        title: 'Error',
        message: 'Error uploading the image, try again please.',
      });
      return;
    } else if (image === 'video' && !videoUrl) {
      this.setState({
        activeModal: 0,
      });
      simpleNotification({
        level: 'error',
        title: 'Error',
        message: 'Error uploading the YouTube URL; please check the ID and try again.',
      });
      return;
    }

    if (image === 'video' && videoUrl) {
      simpleNotification({
        level: 'success',
        title: 'Success',
        message: 'The YouTube URL was uploaded succesfully!',
      });
    } else {
      simpleNotification({
        level: 'success',
        title: 'Success',
        message: 'The image was uploaded succesfully!',
      });
    }

    if (image === 'cover') {
      this.props.updateAdminGameForm({ cover: signedUrl.url });
    } else if (image === 'coverPortrait') {
      this.props.updateAdminGameForm({ coverPortrait: signedUrl.url });
    } else if (image === 'logo') {
      this.props.updateAdminGameForm({ logo: signedUrl.url });
    } else if (image === 'developer-logo') {
      this.props.updateAdminGameForm({ developerLogo: signedUrl.url });
    } else if (image === 'gallery-image') {
      const { adminGameForm } = this.props;
      const { gallery } = adminGameForm.values;
      gallery.push({
        type: 'image',
        url: signedUrl.url,
        thumb: signedUrl.url,
      });
      this.props.updateAdminGameForm({ gallery });
    } else if (image === 'video') {
      const { adminGameForm } = this.props;
      const { gallery } = adminGameForm.values;
      gallery.push({
        type: 'video',
        url: `https://www.youtube.com/embed/${videoUrl}?autoplay=1`,
        thumb: `https://img.youtube.com/vi/${videoUrl}/hqdefault.jpg`,
      });
      this.props.updateAdminGameForm({ gallery });
    }

    this.setState({
      activeModal: 0,
      videoUrl: '',
    });
  }

  generateCategoryInputs() {
    return this.state.categories.map((category, index) => (
      <span className={classNames('', { checked: category.selected })} aria-hidden onClick={this.changeCategory.bind(this, index)}>
        { (category.selected) ? (<i className="fa fa-check" aria-hidden />) : (<i className="fa fa-times" aria-hidden />) }
        &nbsp;
        { category.name }
      </span>
    ));
  }

  generateGalleryInputs() {
    const { gallery } = this.state;
    return gallery.map((gal, index) => (
      <div className="gallery__item">
        <div
          onClick={
            () => {
              this.state.gallery.splice(index, 1);
              this.setState({});
            }
          }
          className="Modal__close-btn"
          aria-hidden
        />
        <Field
          type="text"
          name={`galleryType${gal.id}`}
          id={`galleryType${gal.id}`}
          placeholder="Type"
          customLabel={(<label>Type</label>)}
          component={CustomInput}
        />
        <Field
          type="text"
          name={`galleryUrl${gal.id}`}
          id={`galleryUrl${gal.id}`}
          placeholder="URL"
          customLabel={(<label>URL</label>)}
          component={CustomInput}
        />
        <Field
          type="text"
          name={`galleryThumb${gal.id}`}
          id={`galleryThumb${gal.id}`}
          placeholder="Thumb"
          customLabel={(<label>Thumb</label>)}
          component={CustomInput}
        />
      </div>
    ));
  }

  addToGallery() {
    this.galleryId += 1;
    this.setState(prevState => ({
      gallery: [...prevState.gallery, { id: this.galleryId }],
    }));
  }

  submit = info => {
    const { adminGameForm = {} } = this.props;
    const adminGameEditInfo = {
      name: info.name,
      slug: info.slug || '',
      nameInTwitch: info.nameInTwitch,
      nameInYoutube: info.nameInYoutube,
      price: info.price ? parseFloat(info.price) : 0,
      salePrice: info.salePrice ? parseFloat(info.salePrice) : 0,
      saleEndDate: info.saleEndDate,
      status: info.status,
      sponsorType: info.sponsorType,
      developer: info.developer,
      publisher: info.publisher || '',
      releaseDate: info.releaseDate,
      isPrivate: info.isPrivate,
      isOnSale: info.isOnSale,
      displayOnNoizStore: info.displayOnNoizStore,
      storeDescription: info.storeDescription,
      gallery: info.gallery,
      cover: info.cover || '',
      coverPortrait: info.coverPortrait || '',
      logo: info.logo || '',
      developerLogo: info.developerLogo || '',
      video: info.video || '',
      actionButtonText: info.actionButtonText || '',
      actionButtonLink: info.actionButtonLink || '',
      soldOutMessage: adminGameForm?.values?.soldOutMessage || '',
      description: adminGameForm?.values?.description || '',
      actionMessage: adminGameForm?.values?.actionMessage || '',
      saleDescription: adminGameForm?.values?.saleDescription || '',
      headlineVideo: info.headlineVideo || '',
      headline: info.headline || '',
      gamePageURL: info.gamePageURL || '',
      platforms: info.platforms || [],
      owner: info?.owner || null,
    };

    // adminGameEditInfo.description = adminGameForm?.values?.description || '';
    // adminGameEditInfo.saleDescription = adminGameForm?.values?.saleDescription || '';
    // adminGameEditInfo.storeDescription = adminGameForm?.values?.storeDescription || '';
    // adminGameEditInfo.soldOutMessage = adminGameForm?.values?.soldOutMessage || '';
    // adminGameEditInfo.actionMessage = adminGameForm?.values?.actionMessage || '';

    const socialNetworks = [];

    if (info.twitter && info.twitter.trim() !== '') {
      socialNetworks.push({
        name: 'twitter',
        url: info.twitter,
      });
    }
    if (info.facebook && info.facebook.trim() !== '') {
      socialNetworks.push({
        name: 'facebook',
        url: info.facebook,
      });
    }
    if (info.instagram && info.instagram.trim() !== '') {
      socialNetworks.push({
        name: 'instagram',
        url: info.instagram,
      });
    }
    if (info.twitch && info.twitch.trim() !== '') {
      socialNetworks.push({
        name: 'twitch',
        url: info.twitch,
      });
    }
    if (info.youtube && info.youtube.trim() !== '') {
      socialNetworks.push({
        name: 'youtube',
        url: info.youtube,
      });
    }

    adminGameEditInfo.socialNetworks = socialNetworks;

    const categories = [];
    for (let i = 0; i < this.state.categories.length; i += 1) {
      if (this.state.categories[i].selected) {
        categories.push({
          name: this.state.categories[i].name,
          url: this.state.categories[i].name,
        });
      }
    }

    adminGameEditInfo.categories = categories;

    if (!adminGameEditInfo.description || adminGameEditInfo.description.trim() === '') {
      return;
    }

    Object.keys(adminGameEditInfo).forEach((key) => {
      const value = adminGameEditInfo[key];
      if (typeof value === 'string') {
        adminGameEditInfo[key] = value.trim();
      }
    });

    if (adminGameEditInfo.platforms && adminGameEditInfo.platforms.length > 0) {
      adminGameEditInfo.platforms = adminGameEditInfo.platforms.map(p => p.value);
    }

    if (adminGameEditInfo.owner) {
      adminGameEditInfo.owner = adminGameEditInfo.owner.value;
    }

    this.props.onSubmit(adminGameEditInfo);
  }

  changeStep = (value) => {
    this.setState({
      activeModal: value,
    });
  }

  orderGallery = (index, direction) => {
    const swapIndex = (direction === 'up') ? index - 1 : index + 1;
    const { adminGameForm } = this.props;
    const arr = adminGameForm.values.gallery;
    [arr[index], arr[swapIndex]] = [arr[swapIndex], arr[index]];
    this.props.swapGameGallery(arr);
  }

  uploadCover = () => {
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Fill name field before upload an image.',
      });
      return;
    }
    this.setState({
      activeModal: 1,
      image: 'cover',
    });
  }

  uploadCoverPortrait = () => {
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Fill name field before upload an image.',
      });
      return;
    }
    this.setState({
      activeModal: 1,
      image: 'coverPortrait',
    });
  }


  uploadLogo = () => {
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Fill name field before upload an image.',
      });
      return;
    }
    // const { token } = this.props.auth;
    // this.props.requestUploadImageUrl(slug, 'logo', token);
    this.setState({
      activeModal: 1,
      image: 'logo',
    });
  }

  uploadDeveloperLogo = () => {
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Fill name field before upload an image.',
      });
      return;
    }
    // const { token } = this.props.auth;
    // this.props.requestUploadImageUrl(slug, 'developer-logo', token);
    this.setState({
      activeModal: 1,
      image: 'developer-logo',
    });
  }

  uploadGalleryImage = () => {
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Fill name field before upload an image.',
      });
      return;
    }
    // const { token } = this.props.auth;
    // this.props.requestUploadImageUrl(slug, 'gallery-image', token);
    this.setState({
      activeModal: 1,
      image: 'gallery-image',
    });
  }

  uploadGalleryVideo = () => {
    const slug = this.getSlug();
    if (!slug) {
      simpleNotification({
        level: 'warning',
        title: 'Warning',
        message: 'Fill name field before entering a video.',
      });
      return;
    }

    this.setState({
      activeModal: 4,
      image: 'video',
    });
  }

  handleEditorChange = (content, section) => {
    switch (section) {
      case 'description': this.props.updateAdminGameForm({ description: content });
        break;
      case 'saleDescription': this.props.updateAdminGameForm({ saleDescription: content });
        break;
      case 'soldOutMessage': this.props.updateAdminGameForm({ soldOutMessage: content });
        break;
      case 'actionMessage': this.props.updateAdminGameForm({ actionMessage: content });
        break;
      default:
        break;
    }
  }

  renderGallery = ({ fields, meta: { touched, error }, uploadGalleryImage }) => (
    <React.Fragment>
      <div
        className="gallery__add"
        style={{ display: (fields.length > 0) ? 'inline-block' : 'none' }}
        onClick={uploadGalleryImage}
        aria-hidden
      >
        <i className="fa fa-upload" aria-hidden />&nbsp;Upload Image
      </div>
      <div
        className="gallery__add"
        style={{ display: (fields.length > 0) ? 'inline-block' : 'none' }}
        onClick={this.uploadGalleryVideo}
        aria-hidden
      >
        <i className="fa fa-upload" aria-hidden />&nbsp;Upload YouTube Link
      </div>
      {
        fields.map((member, index) => (
          <div className="gallery__item">
            <div
              onClick={() => fields.remove(index)}
              className="Modal__close-btn"
              aria-hidden
            />
            <div
              className="GameForm__gallery--up"
              onClick={this.orderGallery.bind(this, index, 'up')}
              style={{ display: (index !== 0) ? 'block' : 'none' }}
              aria-hidden
            >
              <i className="fa fa-arrow-up" aria-hidden />
            </div>
            <div
              className="GameForm__gallery--down"
              onClick={this.orderGallery.bind(this, index, 'down')}
              style={{ display: (index !== fields.length - 1) ? 'block' : 'none' }}
              aria-hidden
            >
              <i className="fa fa-arrow-down" aria-hidden />
            </div>
            <Field
              name={`${member}.type`}
              placeholder="Select Type"
              customLabel={((<label>Type</label>))}
              component={CustomDropDownGeneric}
              options={['image', 'video']}
            />
            <Field
              name={`${member}.url`}
              type="text"
              component={CustomInput}
              customLabel={(<label>URL</label>)}
              placeholder="URL"
            />
            <Field
              name={`${member}.thumb`}
              type="text"
              component={CustomInput}
              customLabel={(<label>Thumb</label>)}
              placeholder="Thumb URL"
            />
          </div>
        ))
      }
      <div
        className="gallery__add"
        onClick={uploadGalleryImage}
        aria-hidden
      >
        <i className="fa fa-upload" aria-hidden />&nbsp;Upload Image
      </div>
      <div
        className="gallery__add"
        onClick={this.uploadGalleryVideo}
        aria-hidden
      >
        <i className="fa fa-upload" aria-hidden />&nbsp;Upload YouTube Link
      </div>
    </React.Fragment>
  );

  brandUserOptions = brandUsers => {
    const defaultChoice = { value: null, label: <span>None</span> };

    return [defaultChoice, ...brandUsers.map(({ id, displayName }) => ({ value: id, label: <span>{displayName}</span> }))];
  }

  platformOptions = () => {
    const { platforms } = this.props;

    const grouped = platforms.reduce((acc, platform) => {
      if (!acc[platform.baseService]) {
        acc[platform.baseService] = [];
      }
      acc[platform.baseService].push(platform);
      return acc;
    }, {});

    return Object.keys(grouped).map(group => ({
      label: group,
      options: grouped[group].map(({ id, platform }) => ({
        value: id,
        label: <span style={{ zIndex: '1000' }}>{platform}</span>,
      })),
    }));
  }

  togglePlatformModal = () => {
    this.setState({ platformModal: !this.state.platformModal });
  }

  render() {
    const {
      adminGameForm = {},
      auth: { token },
      createAPlatform,
      handleSubmit,
    } = this.props;
    const {
      activeModal,
      brandUsers,
      image,
      infoTab,
      platformModal,
      videoUrl,
    } = this.state;

    let imageText = '';
    let lessThan = 0;
    let recommendedDimensions = '';

    if (image === 'cover') {
      imageText = 'cover';
      lessThan = 700;
      recommendedDimensions = '1700px x 956px (16:9)';
    } else if (image === 'logo') {
      imageText = 'logo';
      lessThan = 400;
      recommendedDimensions = '150px x 150px';
    } else if (image === 'developer-logo') {
      imageText = 'developer logo';
      lessThan = 400;
      recommendedDimensions = '150px x 150px';
    } else if (image === 'gallery-image') {
      imageText = 'gallery image';
      lessThan = 500;
      recommendedDimensions = '1400px x 788px (16:9)';
    }

    return (
      <React.Fragment>
        {platformModal && <Platform modal={platformModal} toggleModal={this.togglePlatformModal} createAPlatform={createAPlatform} token={token} />}
        <div className="Global__tabs">
          <div
            className={classNames('Global__tab', { active: infoTab === 'general' })}
            onClick={() => this.setState({ infoTab: 'general' })}
            aria-hidden
          >
            General
          </div>
          <div
            className={classNames('Global__tab', { active: infoTab === 'assets' })}
            onClick={() => this.setState({ infoTab: 'assets' })}
            aria-hidden
          >
            Assets
          </div>
          <div
            className={classNames('Global__tab', { active: infoTab === 'sales' })}
            onClick={() => this.setState({ infoTab: 'sales' })}
            aria-hidden
          >
            Sales
          </div>
          <div
            className={classNames('Global__tab', { active: infoTab === 'store' })}
            onClick={() => this.setState({ infoTab: 'store' })}
            aria-hidden
          >
            Store
          </div>
          <div
            className={classNames('Global__tab', { active: infoTab === 'gallery' })}
            onClick={() => this.setState({ infoTab: 'gallery' })}
            aria-hidden
          >
            Gallery
          </div>
          <div
            className={classNames('Global__tab', { active: infoTab === 'categories' })}
            onClick={() => this.setState({ infoTab: 'categories' })}
            aria-hidden
          >
            Categories
          </div>
          <div
            className={classNames('Global__tab', { active: infoTab === 'social' })}
            onClick={() => this.setState({ infoTab: 'social' })}
            aria-hidden
          >
            Social
          </div>
          <div
            className={classNames('Global__tab', { active: infoTab === 'brand' })}
            onClick={() => this.setState({ infoTab: 'brand' })}
            aria-hidden
          >
            Brand
          </div>
        </div>
        <form className="form-container admin-game-form" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <div className="info-tab-wrap" style={(infoTab === 'general') ? { display: 'block' } : { display: 'none' }}>
            <Field
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              customLabel={(<label>Name</label>)}
              component={CustomInput}
            />
            <div className="input-container main_desc">
              <label>Description</label>
              <div className="input-wrapper">
                <RichText
                  initialValue={adminGameForm?.values?.description || ''}
                  callback={content => this.handleEditorChange(content, 'description')}
                />
                {
                  (
                    (adminGameForm && adminGameForm.syncErrors && adminGameForm.syncErrors.description && adminGameForm.submitFailed)
                    ||
                    (adminGameForm && adminGameForm.submitSucceeded && (!adminGameForm.description || adminGameForm.description.trim() === ''))
                  ) &&
                  <div className="error-message">This field is required</div>
                }
              </div>
            </div>
            <br />
            <Field
              type="text"
              name="nameInTwitch"
              id="nameInTwitch"
              placeholder="Name In Twitch"
              customLabel={(<label>Name In Twitch</label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="nameInYoutube"
              id="nameInYoutube"
              placeholder="Name In Youtube"
              customLabel={(<label>Name In Youtube: <br /><span style={{ fontSize: '12px', color: '#CCC' }}>This field must exactly match what shows in YouTube, including any case or punctuation. If it does not match, it will not track.</span></label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="sponsorType"
              id="sponsorType"
              placeholder="Sponsor Type"
              customLabel={(<label>Sponsor Type: <br /><span style={{ fontSize: '12px', color: '#CCC' }}>This affects the language of the automated emails participants will be sent.</span></label>)}
              component={CustomDropDownGeneric}
              options={['game', 'non-game']}
            />
            <Field
              name="status"
              id="status"
              placeholder="Select Status"
              customLabel={((<label>Status</label>))}
              component={CustomDropDownGeneric}
              options={['active', 'archived', 'disabled']}
            />
            <Field
              type="text"
              name="developer"
              id="developer"
              placeholder="Developer"
              customLabel={(<label>Developer</label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="publisher"
              id="publisher"
              placeholder="Publisher"
              customLabel={(<label>Publisher</label>)}
              component={CustomInput}
            />
            <Field
              name="platforms"
              id="platforms"
              isMulti
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              customLabel={<label>Platform</label>}
              optionsToRender={this.platformOptions()}
            />
            <Field
              type="text"
              name="releaseDate"
              id="releaseDate"
              placeholder="Release Date"
              dateStoreFormat={dateStoreFormat}
              dateDisplayFormat={dateDisplayFormat}
              enableClearDate
              customLabel={(<label>Release Date</label>)}
              component={CustomDateTimePicker}
            />
            <Field
              name="isPrivate"
              id="isPrivate"
              customLabel="Is Private"
              component={CustomInputYesNoRadio}
            />
            <Field
              name="headline"
              id="headline"
              customLabel="Headline"
              component={CustomInput}
              type="text"
            />
            <Field
              name="headlineVideo"
              id="headlineVideo"
              customLabel="Headline Video"
              component={CustomInput}
              type="text"
            />
            <Field
              name="gamePageURL"
              id="gamePageURL"
              customLabel="Game Page URL"
              component={CustomInput}
              type="text"
            />
          </div>
          <div className="info-tab-wrap" style={(infoTab === 'assets') ? { display: 'block' } : { display: 'none' }}>
            <Field
              type="text"
              name="cover"
              id="cover"
              placeholder="Cover Image URL"
              customLabel={(
                <label>
                  Cover&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadCover.bind(this)}
                    aria-hidden
                  >
                    (Upload Cover)
                  </div>
                </label>)}
              component={CustomInput}
            />

            <Field
              type="text"
              name="coverPortrait"
              id="coverPortrait"
              placeholder="Cover Image - Portrait URL"
              customLabel={(
                <label>
                  Cover - Portrait&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadCoverPortrait.bind(this)}
                    aria-hidden
                  >
                    (Upload Cover - Portrait)
                  </div>
                </label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="logo"
              id="logo"
              placeholder="Logo Image URL"
              customLabel={(
                <label>
                  Logo&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadLogo.bind(this)}
                    aria-hidden
                  >
                    (Upload Logo)
                  </div>
                </label>
              )}
              component={CustomInput}
            />
            <Field
              type="text"
              name="developerLogo"
              id="developerLogo"
              placeholder="Developer Logo Image URL"
              customLabel={(
                <label>
                  Developer Logo&nbsp;&nbsp;
                  <div
                    className="GameEdit__upload"
                    onClick={this.uploadDeveloperLogo.bind(this)}
                    aria-hidden
                  >
                    (Upload Developer Logo)
                  </div>
                </label>
              )}
              component={CustomInput}
            />

          </div>
          <div className="info-tab-wrap" style={(infoTab === 'sales') ? { display: 'block' } : { display: 'none' }}>
            <Field
              type="checkbox"
              name="isOnSale"
              id="isOnSale"
              placeholder="Is On Sale"
              customLabel="Is On Sale"
              component={CustomInputYesNoRadio}
            />
            <Field
              type="text"
              name="slug"
              id="slug"
              placeholder="Slug"
              customLabel={(<label>Slug</label>)}
              component={CustomInput}
            />
            {
              (adminGameForm && adminGameForm.values)
              &&
              <label className="Label__URL">
                URL: {window.location.origin}/buy/{adminGameForm.values.slug}
              </label>
            }
            <Field
              type="text"
              name="price"
              id="price"
              placeholder="0"
              customLabel="Retail Price"
              component={CustomInput}
            />
            <Field
              type="text"
              name="salePrice"
              id="salePrice"
              placeholder="0"
              customLabel="Sale Price"
              component={CustomInput}
            />
            <Field
              type="text"
              name="saleEndDate"
              id="saleEndDate"
              placeholder="saleEndDate"
              dateStoreFormat={dateStoreFormat2}
              dateDisplayFormat={dateDisplayFormat2}
              customLabel="Sale End Date"
              component={CustomDateTimePicker}
            />
            <div className="input-container main_desc">
              <label>Sale Description</label>
              <RichText
                initialValue={adminGameForm?.values?.saleDescription || ''}
                callback={content => this.handleEditorChange(content, 'saleDescription')}
              />

            </div>
            <br />
            <div className="input-container main_desc">
              <label>Sold Out Message</label>
              <RichText
                initialValue={adminGameForm?.values?.soldOutMessage || ''}
                callback={content => this.handleEditorChange(content, 'soldOutMessage')}
              />
            </div>
            <br />
            <div className="input-container main_desc">
              <label>Action Message</label>
              <RichText
                initialValue={adminGameForm?.values?.actionMessage || ''}
                callback={content => this.handleEditorChange(content, 'actionMessage')}
              />
            </div>
            <br />
            <Field
              type="text"
              name="actionButtonText"
              id="actionButtonText"
              placeholder="Action Button Text"
              customLabel={(<label>Action Button Text</label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="actionButtonLink"
              id="actionButtonLink"
              placeholder="Action Button Link"
              customLabel={(<label>Action Button Link</label>)}
              component={CustomInput}
            />
          </div>
          <div className="info-tab-wrap" style={(infoTab === 'store') ? { display: 'block' } : { display: 'none' }}>
            <Field
              type="checkbox"
              name="displayOnNoizStore"
              id="displayOnNoizStore"
              customLabel="Display on Noiz Store"
              component={CustomInputCheckbox}
            />
            <Field
              type="textarea"
              name="storeDescription"
              id="storeDescription"
              rows="5"
              placeholder="Store Description"
              customLabel="Store Description"
              component={CustomInput}
            />
          </div>
          <div className="info-tab-wrap" style={(infoTab === 'gallery') ? { display: 'block' } : { display: 'none' }}>
            <FieldArray
              name="gallery"
              component={this.renderGallery}
              uploadGalleryImage={this.uploadGalleryImage.bind(this)}
            />
          </div>
          <div className="info-tab-wrap" style={(infoTab === 'categories') ? { display: 'block' } : { display: 'none' }}>
            <div className="category__field">{ this.generateCategoryInputs() }</div>
          </div>
          <div className="info-tab-wrap" style={(infoTab === 'social') ? { display: 'block' } : { display: 'none' }}>
            <Field
              type="text"
              name="twitter"
              id="twitter"
              placeholder="Twitter URL"
              customLabel={(<label>Twitter</label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="facebook"
              id="facebook"
              placeholder="Facebook URL"
              customLabel={(<label>Facebook</label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="instagram"
              id="instagram"
              placeholder="Instagram URL"
              customLabel={(<label>Instagram</label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="twitch"
              id="twitch"
              placeholder="Twitch URL"
              customLabel={(<label>Twitch</label>)}
              component={CustomInput}
            />
            <Field
              type="text"
              name="youtube"
              id="youtube"
              placeholder="Youtube URL"
              customLabel={(<label>Youtube</label>)}
              component={CustomInput}
            />
          </div>
          <div className="info-tab-wrap" style={(infoTab === 'brand') ? { display: 'block' } : { display: 'none' }}>
            <Field
              name="owner"
              id="owner"
              component={ArrayDropDown}
              styles={arrayDropDownColors}
              customLabel={<label>Brand Owner</label>}
              optionsToRender={this.brandUserOptions(brandUsers)}
            />
          </div>
          <div className="form__footer">
            <button className="AGameForm__save--btn">Save</button>
          </div>
        </form>
        <section className="ModalMaster">
          <section className={classNames('Modal Modal__upload Modal__upload__one', { active: activeModal === 1 })}>
            <div onClick={this.changeStep.bind(this, 0)} className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              {
                (this.props.preSignedUrl && this.props.preSignedUrl.isLoading)
                &&
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              }
              {
                (!this.props.preSignedUrl || !this.props.preSignedUrl.isLoading)
                &&
                <React.Fragment>
                  <div className={classNames('Modal__upload__border', { active: this.state.dropzoneActive })} />
                  <div
                    className="Modal__close-btn"
                    style={{ display: (this.state.dropzoneActive) ? 'none' : 'block' }}
                    onClick={this.changeStep.bind(this, 0)}
                    role="button"
                    aria-hidden
                  />
                  <Dropzone
                    ref={(node) => { this.dropzoneRef = node; }}
                    disableClick={this.state.disableClick}
                    className="Modal__upload__content"
                    onDrop={(e, event) => this.onDrop(event, e)}
                    onDragEnter={this.onDragEnter.bind(this)}
                    onDragLeave={this.onDragLeave.bind(this)}
                    disablePreview={false}
                    multiple={false}
                    accept={['.jpg', '.png']}
                    inputProps={{ id: 'file' }}
                  >
                    <div className="Modal__upload__image" />
                    <div className="Modal__upload__title">
                      Drag and drop the {imageText} here
                    </div>
                    <div
                      className="Modal__upload__or"
                    >
                      Or
                    </div>
                    <div
                      className="Modal__upload__action"
                    >
                      <input type="file" name="file" id="file" className="inputFile" onChange={this.handleSelectedFile} />
                      <button className="cta__button">Choose a file</button>
                    </div>
                    <div
                      className="Modal__upload__or"
                    >
                      Mandatory Size: Less than {lessThan}kb
                      {
                        (recommendedDimensions) &&
                        <React.Fragment><br />Recommended Dimensions: {recommendedDimensions}</React.Fragment>
                      }
                    </div>
                  </Dropzone>
                </React.Fragment>
              }
            </section>
          </section>
          <section className={classNames('Modal Modal__upload Modal__upload__two', { active: activeModal === 2 })}>
            <div onClick={this.changeStep.bind(this, 0)} className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              <div className="Modal__upload__border" />
              <div onClick={this.changeStep.bind(this, 0)} className="Modal__close-btn" role="button" aria-hidden />
              <section className="Modal__upload__content">
                <div className="Modal__upload__image" />
                <div className="Modal__upload__title">
                  Uploading the image
                </div>
                <div className="Modal__upload__loading">
                  <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                </div>
              </section>
            </section>
          </section>
          <section className={classNames('Modal Modal__upload Modal__upload__three', { active: activeModal === 3 })}>
            <div onClick={this.changeStep.bind(this, 0)} className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              <div onClick={this.changeStep.bind(this, 0)} className="Modal__close-btn" role="button" aria-hidden />
              <section className="Modal__upload__content">
                <section className="Modal__upload__avatar">
                  <div className="Modal__upload__avatar--container" aria-hidden />
                </section>
                <div className="Modal__upload__title2">
                  Upload completed
                </div>
                <div onClick={this.changeStep.bind(this, 0)} className="Modal__upload__button" aria-hidden>
                  Change Image
                </div>
              </section>
            </section>
          </section>
          <section className={classNames('Modal Modal__upload Modal__upload__four', { active: activeModal === 4 })}>
            <div onClick={this.changeStep.bind(this, 0)} className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              <div onClick={this.changeStep.bind(this, 0)} className="Modal__close-btn" role="button" aria-hidden />
              <section className="Modal__upload__content">
                <div className="Modal__upload__title">
                  YouTube ID
                </div>
                <br />
                Only the ID portion of the YouTube video is required.
                <br />
                <br />
                <TextField
                  type="text"
                  name="videoUrl"
                  id="videoUrl"
                  placeholder="dQw4w9WgXcQ"
                  customLabel={(<label>YouTube ID</label>)}
                  value={videoUrl}
                  onChange={this.handleVideoUrl}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleLoad}
                >
                  Submit
                </Button>
              </section>
            </section>
          </section>
        </section>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.trim() === '') {
    errors.name = 'This field is required';
  }
  if (!values.description || values.description.trim() === '') {
    errors.description = 'This field is required';
  }
  if (!isBoolean(values.isPrivate)) {
    errors.isPrivate = 'This field is required';
  }
  if (!isBoolean(values.isOnSale)) {
    errors.isOnSale = 'This field is required';
  }
  if (values.displayOnNoizStore && !isBoolean(values.displayOnNoizStore)) {
    errors.displayOnNoizStore = 'This field is required';
  }
  if (values.displayOnNoizStore && (!values.storeDescription || values.storeDescription.trim() === '')) {
    errors.storeDescription = 'This field is required';
  }
  if (values.isOnSale && (!values.slug || values.slug.trim() === '')) {
    errors.slug = 'This field is required';
  }
  if (!values.nameInTwitch || values.nameInTwitch.trim() === '') {
    errors.nameInTwitch = 'This field is required';
  }
  if (!values.nameInYoutube || values.nameInYoutube.trim() === '') {
    errors.nameInYoutube = 'This field is required';
  }
  if (values.isOnSale && (!values.price || typeof values.price === 'undefined' || values.price === '')) {
    errors.price = 'This field is required';
  }
  if (!values.status || values.status.trim() === '') {
    errors.status = 'This field is required';
  }
  if (!values.developer || values.developer.trim() === '') {
    errors.developer = 'This field is required';
  }
  /*
  if (!values.releaseDate) {
    errors.releaseDate = 'This field is required';
  }
  */
  if (!values.releaseDate || values.releaseDate === '') {
    values.releaseDate = '-';
  }
  if (values.isOnSale && !values.price) {
    errors.price = 'This field is required';
  }
  if (values.price && !/^\d+(.\d{1,2})?$/i.test(values.price)) {
    errors.price = 'Incorrect format';
  } else if (values.price === '') {
    values.price = '0';
  }
  if (values.platforms && values.platforms.length <= 0) {
    errors.platforms = 'This field is required';
  }

  return errors;
};


AdminGameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'adminGameForm',
  validate,
  destroyOnUnmount: false,
})(AdminGameForm);
