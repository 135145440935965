/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import { without } from 'lodash';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';
// import moment from 'moment';
// import { simpleNotification } from '../../../../utils/notifications';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import snowFlake from '../../../../images/icons/frozen.png';

import './QuestPreapproval.scss';
import languages from '../../../../utils/languages';
import EditUserQuest from '../../util/edit-user-quest/EditUserQuest';
import EditTier from '../edit-tier/EditTier';
import { simpleNotification } from '../../../../utils/notifications';

// Custom Modal
import ApprovalModal from './ApprovalModal';

// Utils
import {
  calculatePaymentAmount,
  capitalizeFirstLetter,
  getTwitchAverageViewers,
  keyDistributionStepExists,
  questPreapproval,
  throttle,
  viewerTiersWithSpacesLeft,
} from '../../../../utils/functions';
import { forceSullyGnomeUpdate } from '../../../../utils/apiv2';
import creep from '../../../../images/icons/creep.png';

const MAX_PAGE_SIZE = 9999;

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

class QuestPreapproval extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.throtPostPreapproval = throttle(this.props.postCCPreapproval, 2000);
    this.state = {
      numPage: 1,
      showingElements: [],
      tab: 'pending',
      showTwitch: false,
      memberTiers: [],
      viewerTiers: [],
      quest: { viewerTiers: [] },
      orderBy: false,
      order: 'desc',
      searchValueCurrent: '',
      searchValue: '',
      downloadModal: false,
      updateModal: false,
      selectedUsers: [],
      pageSize: 10,
    };
  }

  UNSAFE_componentWillMount() {
    this.getUserQuests();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.commandCenter.questGeneral.isLoading === true && nextProps.commandCenter.questGeneral.isLoading === false) {
      const { data: quest } = nextProps.commandCenter.quest;
      this.setState({
        memberTiers: quest.memberTiers,
        viewerTiers: quest.viewerTiers,
        quest,
      });

      if (quest.twitchAccountRequired === true) {
        this.setState({
          showTwitch: true,
        });
      }

      // One or more users failed to update
      if (nextProps.commandCenter.questGeneral.batchErrorMessage) {
        simpleNotification({
          level: 'error',
          title: 'Error',
          message: nextProps.commandCenter.questGeneral.batchErrorMessage,
        });
      } else if (!nextProps.commandCenter.questGeneral.errors.hasError && nextProps.commandCenter.questGeneral.successCount) {
        simpleNotification({
          level: 'success',
          title: 'Success!',
          message: 'User(s) successully updated.',
        });
      }
    }

    // General error
    if (this.props.commandCenter.activeUserQuests.isLoading === true && nextProps.commandCenter.activeUserQuests.isLoading === false) {
      if (nextProps.commandCenter.activeUserQuests.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Error!',
          message: nextProps.commandCenter.activeUserQuests.errors.message,
        });
      }
    }

    // User sucessfully moved/removed from quest
    if (this.props.commandCenter.activeUserQuests.isLoadingSpecial === true && nextProps.commandCenter.activeUserQuests.isLoadingSpecial === false) {
      if (!nextProps.commandCenter.activeUserQuests.errors.hasError) {
        simpleNotification({
          level: 'success',
          title: 'Success!',
          message: nextProps.commandCenter.activeUserQuests.errors.message,
        });

        this.getUserQuests();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { loadUsers } = this.props.commandCenter.questGeneral;
    const { loadUsers: prevLoadUsers } = prevProps.commandCenter.questGeneral;

    if ((loadUsers !== prevLoadUsers) && loadUsers) {
      this.getUserQuests();
    }
  }

  getCurrentFiltering = () => {
    let sorting = '';
    if (this.state.order && this.state.orderBy) {
      sorting = this.state.orderBy + capitalizeFirstLetter(this.state.order);
    }

    const filtering = {
      search: this.state.searchValue,
      sorting,
    };

    return JSON.stringify(filtering);
  }

  getUserQuests = () => {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestGeneral(this.state.numPage, this.state.pageSize, this.state.tab, questId, this.getCurrentFiltering(), 'preapproval', token);
    }
  }


  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [
          ...showingElements,
          elementId,
        ],
      });
    } else {
      this.setState({
        showingElements: [
          ...without(showingElements, elementId),
        ],
      });
    }
  }

  reset = () => {
    this.setState({
      numPage: 1,
      selectedUsers: [],
      searchValue: '',
      updateModal: false,
    });
  }

  changeTab = (param) => {
    this.clearSearch(false);
    this.setState({
      tab: param,
      numPage: 1,
      selectedUsers: [],
      orderBy: false,
      order: 'desc',
      searchValue: '',
      searchValueCurrent: '',
    }, this.getUserQuests);
  }

  approve = (userIds) => {
    const { questId } = this.props.router.params;
    const { token } = this.props.auth;
    const { tab, numPage, pageSize } = this.state;

    this.throtPostPreapproval(questId, JSON.stringify(userIds), 'approved', tab, numPage, pageSize, token);
    this.reset();
  }

  decline = (userIds) => {
    const { questId } = this.props.router.params;
    const { token } = this.props.auth;
    const { tab, numPage, pageSize } = this.state;

    this.throtPostPreapproval(questId, JSON.stringify(userIds), 'declined', tab, numPage, pageSize, token);
    this.reset();
  }

  pending = (userIds) => {
    const { questId } = this.props.router.params;
    const { token } = this.props.auth;
    const { tab, numPage, pageSize } = this.state;

    this.throtPostPreapproval(questId, JSON.stringify(userIds), 'pending', tab, numPage, pageSize, token);
    this.reset();
  }

  forceUpdate = (userId) => {
    forceSullyGnomeUpdate(userId);
    this.reset();
  }

  toggleSelectUser = (userId) => {
    const { selectedUsers } = this.state;

    if (selectedUsers.indexOf(userId) === -1) {
      this.setState({
        selectedUsers: [
          ...selectedUsers,
          userId,
        ],
      });
    } else {
      this.setState({
        selectedUsers: [
          ...without(selectedUsers, userId),
        ],
      });
    }
  }

  prepQuestsForCSV = (item) => {
    const last3Quests = item.last3Quests ? item.last3Quests : [];

    const questCondense = last3Quests.reduce((acc, q, i) => {
      const {
        timePlaying, requiredStreamTimeMinutes, title, AVGViewers, userQuestStatus,
      } = q;

      const passFail = userQuestStatus && userQuestStatus.result ? 'PASSED' : 'FAILED';

      acc += `${title}, `;
      acc += `${passFail}, `;
      acc += `Time Played: ${Math.round(timePlaying)}, `;
      acc += `Time Required: ${Math.round(requiredStreamTimeMinutes)}, `;
      acc += `AVG Viewers: ${Math.round(AVGViewers)}, `;

      return acc;
    }, '');

    return JSON.stringify(questCondense);
  }

  generateDetailRowHTML = (value1, value2) => (
    <TableRow>
      <TableCell colSpan="1" align="left" />
      <TableCell colSpan="4" align="left" style={{ flexBasis: '20%' }}>{value1}</TableCell>
      <TableCell colSpan="5" align="left" style={{ flexBasis: '80%' }}>{value2}</TableCell>
    </TableRow>
  )

  generateDetailsRowsHTML = (item) => {
    const discordHtml = this.generateDetailRowHTML('Discord', item.discord ? item.discord : <i>unset</i>);
    const twitterHtml = this.generateDetailRowHTML('Twitter', item.twitter ? item.twitter : <i>unset</i>);
    const userCountryHTML = this.generateDetailRowHTML('User Defined Country', item.country ? item.country : <i>unset</i>);
    let userInfoHeader = <React.Fragment />;
    let last3QuestsHTML = <React.Fragment />;
    let userInformationHTML = <React.Fragment />;

    const typeContentHeader = (
      <TableRow style={{ background: '#2b2e38' }}>
        <TableCell colSpan="1" align="left" />
        <TableCell colSpan="4" align="left" style={{ color: '#9b9b9b' }}><b>Type</b></TableCell>
        <TableCell colSpan="5" align="left" style={{ color: '#9b9b9b' }}><b>Content</b></TableCell>
      </TableRow>
    );

    if (item.userInformation) {
      userInfoHeader = (
        <TableRow style={{ background: '#2b2e38' }}>
          <TableCell colSpan="1" align="left" />
          <TableCell colSpan="4" align="left" style={{ color: '#9b9b9b' }}><b>User Info Title</b></TableCell>
          <TableCell colSpan="5" align="left" style={{ color: '#9b9b9b' }}><b>Response</b></TableCell>
        </TableRow>
      );
      userInformationHTML = item.userInformation.map(info => (
        <TableRow>
          <TableCell colSpan="1" align="left" />
          <TableCell colSpan="4" align="left">{info.title}</TableCell>
          <TableCell colSpan="5" align="left">{info.information ? info.information : <i>n/a</i>}</TableCell>
        </TableRow>
      ));
    }

    if (item.last3Quests && item.last3Quests.length > 0) {
      const tempLast3QuestsHeader = (
        <TableRow style={{ background: '#2b2e38' }}>
          <TableCell colSpan="1" align="left" />
          <TableCell colSpan="4" align="left" style={{ color: '#9b9b9b' }}><b>Game/Quest</b></TableCell>
          <TableCell colSpan="2" align="left" style={{ color: '#9b9b9b' }}><b>Status</b></TableCell>
          <TableCell colSpan="2" align="left" style={{ color: '#9b9b9b' }}><b>Time Playing/Time Required</b></TableCell>
          <TableCell colSpan="1" align="left" style={{ color: '#9b9b9b' }}>
            <b>Average Viewers</b>&nbsp;<Tooltip placement="top" title="ACCV in Quest / ACCV at Joined Date"><i className="fa fa-info-circle" /></Tooltip>
          </TableCell>
        </TableRow>
      );
      const tempLast3QuestsHTML = item.last3Quests.map((lq) => {
        const pass = lq.userQuestStatus && lq.userQuestStatus.result;
        const questUrl = 'tiered';
        const roundedAverageViewersInQuest = lq.AVGViewers ? Math.round(lq.AVGViewers) : Math.round(lq.averageViewers);
        const roundedAverageViewersAtJoin = Math.round(lq.averageViewersAtJoin);

        return (
          <TableRow>
            <TableCell colSpan="1" align="left" />
            <TableCell colSpan="4" align="left" data-tip={`${lq.title}`}>
              {lq.title}
              {lq.gameName}
              &nbsp;
              <Link
                to={`/admin/command-center/${lq.game}/quests/${lq.quest}/${questUrl}`}
                href={`/admin/command-center/${lq.game}/quests/${lq.quest}/${questUrl}`}
                target="_blank"
              >
                <i className="fa fa-line-chart" />
              </Link>
            </TableCell>
            <TableCell colSpan="2" align="left" className={classNames('table-cell', { passedQuest: pass, failedQuest: !pass })}>{(pass) ? 'PASSED' : 'FAILED'}</TableCell>
            <TableCell colSpan="2" align="left">{Math.round(lq.timePlaying)}/{Math.round(lq.requiredStreamTimeMinutes)}</TableCell>
            <TableCell colSpan="2" align="left">{roundedAverageViewersInQuest}/{roundedAverageViewersAtJoin} ({Math.round((roundedAverageViewersInQuest / (roundedAverageViewersAtJoin === 0 ? 1 : roundedAverageViewersAtJoin)) * 100)}%)</TableCell>
          </TableRow>
        );
      });
      last3QuestsHTML = <React.Fragment>{tempLast3QuestsHeader}{tempLast3QuestsHTML}</React.Fragment>;
    } else {
      last3QuestsHTML = <TableCell colSpan="10" align="center" className="warning">This user has not participated in any quests in the last 30 days.</TableCell>;
    }

    let mostPlayed5GamesHTML = <React.Fragment />;

    if (item.mostPlayed5Games && item.mostPlayed5Games.length > 0) {
      const tempMostPlayed5GamesHeader = (
        <TableRow style={{ background: '#2b2e38' }}>
          <TableCell colSpan="1" align="left" />
          <TableCell colSpan="4" align="left" style={{ color: '#9b9b9b' }}><b>Game (Last 30 days activity)</b></TableCell>
          <TableCell colSpan="2" align="left" style={{ color: '#9b9b9b' }}><b>Time Playing</b></TableCell>
          <TableCell colSpan="2" align="left" style={{ color: '#9b9b9b' }}><b>Average Viewers</b></TableCell>
          <TableCell colSpan="2" align="left" style={{ color: '#9b9b9b' }}><b>Peak Viewers</b></TableCell>
        </TableRow>
      );
      const tempMostPlayed5GamesHTML = item.mostPlayed5Games.map(mpg => (
        <TableRow>
          <TableCell colSpan="1" align="left" />
          <TableCell colSpan="4" align="left">{mpg.gameString}</TableCell>
          <TableCell colSpan="2" align="left">{Math.round(mpg.timePlaying)}</TableCell>
          <TableCell colSpan="2" align="left">{Math.round(mpg.averageViewers)}</TableCell>
          <TableCell colSpan="2" align="left">{Math.round(mpg.peakViewers)}</TableCell>
        </TableRow>
      ));
      mostPlayed5GamesHTML = <React.Fragment>{tempMostPlayed5GamesHeader}{tempMostPlayed5GamesHTML}</React.Fragment>;
    } else {
      mostPlayed5GamesHTML = <TableCell colSpan="10" align="center" className="warning">This user has not streamed anything in the last 30 days.</TableCell>;
    }

    const detailsRowsHTML = (
      <React.Fragment>
        {userInfoHeader}
        {userInformationHTML}
        {typeContentHeader}
        {discordHtml}
        {twitterHtml}
        {userCountryHTML}
        {last3QuestsHTML}
        {mostPlayed5GamesHTML}
      </React.Fragment>
    );
    return (detailsRowsHTML);
  }

  formatExtraInfo = (item) => {
    // type title information
    const discord = item.discord ? item.discord : 'unset';
    const twitter = item.twitter ? item.twitter : 'unset';
    const language = item.language ? languages(item.language) : 'unset';
    const last3Quests = item.last3Quests ? item.last3Quests : [];

    const questMap = last3Quests.map((q) => {
      const {
        timePlaying, requiredStreamTimeMinutes, title, AVGViewers, userQuestStatus,
      } = q;

      const passFail = userQuestStatus && userQuestStatus.result ? 'PASSED' : 'FAILED';
      return {
        prevQuest: true,
        type: 'text',
        information: [passFail, `Time Playing: ${Math.round(timePlaying)}`, `Time Required: ${Math.round(requiredStreamTimeMinutes)}`, `AVG Viewers: ${Math.round(AVGViewers)}`],
        title: `Quest: ${title}`,
      };
    });

    return [
      {
        type: 'text', title: 'Discord', information: discord, copy: true,
      },
      {
        type: 'text', title: 'Twitter', information: twitter, link: `http://twitter.com/${twitter}`,
      },
      { type: 'text', title: 'Language', information: language },
      ...questMap,
      ...item.userInformation,
    ];
  }

  toggleTwitch = () => {
    this.setState({
      showTwitch: !this.state.showTwitch,
    });
  }

  toggleSearchModal = () => {
    this.setState({
      searchModal: !this.state.searchModal,
    });
  }

  handleSearchValueChange = (event) => {
    this.setState({
      searchValueCurrent: event.target.value,
    });
  }

  clearSearch = (applySearch) => {
    this.setState({
      searchValue: '',
      searchValueCurrent: '',
      searchModal: false,
    }, () => {
      if (applySearch) {
        this.applySearch();
      }
    });
  }

  applySearch = () => {
    this.setState({
      searchValue: this.state.searchValueCurrent,
      numPage: 1,
    }, () => {
      this.getUserQuests();
      this.toggleSearchModal();
    });
  }

  openDownloadModal = () => {
    const { token, user } = this.props.auth;
    const { questId } = this.props.router.params;
    const isAdmin = user.role === 'admin';
    const isHelper = user.role === 'helper';

    if (isAdmin || isHelper) {
      const filtering = {
        search: '',
        sorting: '',
      };

      this.props.getCCQuestGeneralCsv(0, MAX_PAGE_SIZE, 'all', questId, JSON.stringify(filtering), 'preapproval', token);
      this.setState({
        downloadModal: true,
      });
    }
  }

  closeDownloadModal = () => {
    this.setState({
      downloadModal: false,
    });
  }

  openUpdateModal = () => {
    this.setState({
      updateModal: true,
    });
  }

  closeUpdateModal = () => {
    this.setState({
      updateModal: false,
    });
  }

  getPagesInTab = () => {
    const { pagination } = this.props.commandCenter.quest;
    const { tab, pageSize } = this.state;

    if (!pagination || !pagination.userCounts) {
      return 0;
    }

    let usersInTab = 0;
    switch (tab) {
      case 'pending':
        usersInTab = pagination.userCounts.appliedCount ? pagination.userCounts.appliedCount : 0;
        break;
      case 'approved':
        usersInTab = pagination.userCounts.approvedCount ? pagination.userCounts.approvedCount : 0;
        break;
      case 'declined':
        usersInTab = pagination.userCounts.declinedCount ? pagination.userCounts.declinedCount : 0;
        break;
      case 'leftQuest':
        usersInTab = pagination.userCounts.leftQuestCount ? pagination.userCounts.leftQuestCount : 0;
        break;
      default:
        usersInTab = pagination.userCounts.totalCount ? pagination.userCounts.totalCount : 0;
        break;
    }

    let numPages = 1;
    if (pageSize < MAX_PAGE_SIZE) {
      numPages = usersInTab ? Math.ceil(usersInTab / pageSize) : 1;
    }

    return numPages;
  }

  selectAllUsers = () => {
    const { data: questGeneral } = this.props.commandCenter.questGeneral;
    const { selectedUsers } = this.state;

    // Select all users on curent page not already selected
    const userIds = questGeneral.map(user => user.id);
    selectedUsers.forEach((user) => {
      if (!userIds.includes(user)) {
        userIds.push(user);
      }
    });

    this.setState({
      selectedUsers: userIds,
    });
  }

  renderFirstRow = (downloadButton, pagination) => {
    const {
      showTwitch, searchValue, selectedUsers, numPage,
    } = this.state;
    const numPages = this.getPagesInTab();

    return (
      <div className="row first-row">
        <button
          className={classNames('CCQuestPreapproval__subtab', { active: showTwitch })}
          onClick={this.toggleTwitch.bind(this)}
        >
          Twitch
        </button>
        <div className="pagination">
          <div className="right">
            <span className="search">
              {(searchValue) && `Searching for: ${searchValue}`}
            </span>
            <span>
              {(downloadButton === true) &&
                <button
                  className={classNames('')}
                  onClick={this.openDownloadModal.bind(this)}
                >
                  <i className="fa fa-download" />
                </button>
              }
              <button onClick={this.toggleSearchModal.bind(this)}>
                Search
              </button>
              {(selectedUsers.length) ? (
                <React.Fragment>
                  <button onClick={() => { this.setState({ selectedUsers: [] }); }}>
                    Clear Selected
                  </button>
                  <button onClick={this.openUpdateModal.bind(this)}>
                    Update Selected ({selectedUsers.length})
                  </button>
                </React.Fragment>
              ) : null}
              <button onClick={this.selectAllUsers.bind(this)}>
                Select All
              </button>
              {(pagination && numPages > 1) &&
                <React.Fragment>
                  <button onClick={this.paginationLeft.bind(this)}>
                    <i className="fa fa-arrow-left" />
                  </button>
                  Page {numPage} / {numPages}
                  <button onClick={this.paginationRight.bind(this)}>
                    <i className="fa fa-arrow-right" />
                  </button>
                </React.Fragment>
              }
            </span>
          </div>
        </div>
      </div>
    );
  }

  paginationRight = () => {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    const numPages = this.getPagesInTab();

    if (this.state.numPage >= numPages) {
      return;
    }

    if (!this.props.admin.users.isLoading) {
      this.props.getCCQuestGeneral(this.state.numPage + 1, this.state.pageSize, this.state.tab, questId, this.getCurrentFiltering(), 'preapproval', token);
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  }

  paginationLeft = () => {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;

    if (this.state.numPage === 1) {
      return;
    }

    if (!this.props.admin.users.isLoading) {
      this.props.getCCQuestGeneral(this.state.numPage - 1, this.state.pageSize, this.state.tab, questId, this.getCurrentFiltering(), 'preapproval', token);
    }

    this.setState({
      numPage: this.state.numPage - 1,
    });
  }

  // Define table headers based on tab; return array of headers
  getTableHeaders = (partOfCampaign = false) => {
    const { showTwitch, tab } = this.state;
    const tableHeaders = [
      {
        id: 'index', numeric: false, disablePadding: true, label: '#',
      },
      {
        id: 'username', numeric: false, disablePadding: true, label: 'Noiz',
      },
      {
        id: 'email', numeric: false, disablePadding: true, label: 'Email',
      }];

    if (showTwitch) {
      tableHeaders.push({
        id: 'twitchUsername', numeric: false, disablePadding: true, label: 'Twitch',
      });
      tableHeaders.push({
        id: 'twitchAvg30', numeric: true, disablePadding: true, label: 'Twitch ACCV',
      });
    }

    tableHeaders.push({
      id: 'paymentAmount', numeric: false, disablePadding: true, label: 'Payment Amt',
    });

    tableHeaders.push({
      id: 'country', numeric: false, disablePadding: true, label: 'Country',
    });

    tableHeaders.push({
      id: 'language', numeric: false, disablePadding: true, label: 'Language',
    });

    if (tab === 'leftQuest') {
      tableHeaders.push({
        id: 'questLeftDate', numeric: false, disablePadding: true, label: 'Left Date',
      });
    } else {
      tableHeaders.push({
        id: 'dateJoined', numeric: false, disablePadding: true, label: 'Joined Date',
      });
    }

    tableHeaders.push({
      id: 'questStatistics', numeric: false, disablePadding: true, label: 'Quest Stats',
    });

    if (tab === 'pending' && partOfCampaign) {
      tableHeaders.push({
        id: 'campaignFlag', numeric: false, disablePadding: true, label: 'In Campaign',
      });
    }

    if (tab !== 'leftQuest') {
      tableHeaders.push({
        id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
      });
    }

    tableHeaders.push({
      id: '', numeric: false, disablePadding: true, label: '',
    });

    return tableHeaders;
  }

  getTableRows = (userQuests, partOfCampaign = false) => {
    const { keysAvailables } = this.props.commandCenter.questGeneral;
    const { pagination } = this.props.commandCenter.quest;
    const {
      memberTiers,
      selectedUsers,
      showingElements,
      showTwitch,
      tab,
      quest: {
        type: questType,
      },
      viewerTiers,
    } = this.state;

    const keyDistribute = this.props.commandCenter.quest.data.keyRequired && keyDistributionStepExists(this.props.commandCenter.quest.data);

    return userQuests.map((item, index) => {
      const {
        averageViewersAtJoin,
        blacklist,
        twitchAVGOverride: override,
        status,
      } = item;
      const isFrozen = item.freezeTwitchViewers && item.freezeTwitchViewers.isFrozen;
      const whoFroze = item.freezeTwitchViewers && item.freezeTwitchViewers.whoFroze;
      const dateFrozen = item.freezeTwitchViewers && item.freezeTwitchViewers.dateFrozen;

      // If user frozen & not manually updated to tier, and this is a twitch quest, account for override when calculating payment
      const averageViewers = ['approved', 'normal'].includes(status) ? averageViewersAtJoin : getTwitchAverageViewers({
        connectedAccounts: { twitch: { avg30: item.twitchAvg30 } },
        freezeTwitchViewers: { isFrozen },
        statistics: { AVG30: item.avg30 },
        twitchAVGOverride: override,
      });

      const toPay = calculatePaymentAmount(item, averageViewers, viewerTiers, memberTiers);
      const whichTier = toPay.isMemberTier ? ' -M' : '';

      const avg30 = (item.avg30) ? Math.round(item.avg30) : 0;
      const avgViewers = item.avgViewers ? Math.round(item.avgViewers) : 0;
      const twitchAvg30 = (item.twitchAvg30) ? Math.round(item.twitchAvg30) : 0;
      // const normalized30 = item.statistics && item.statistics.normalizedAVG30 ? item.statistics.normalizedAVG30 : 0;
      const displayAvg30 = (avg30 > 0) ? avg30 : twitchAvg30 || avgViewers;
      const showEditTier = questType === 'tiered' || questType === 'tiered-one-time' || questType === 'tiered-multi-days';

      const frozenText = `Frozen by <strong>${whoFroze} </strong> on <strong>${moment(dateFrozen).format('MMMM DD, YYYY - hh:mm a')} </strong> </br> </br> <strong> NOTES: </strong> ${item.notes ? item.notes : ''}`;

      return (
        <React.Fragment>
          <TableRow className={item.status} hover tabIndex={-1} key={item.id}>

            {/* Index */}
            <TableCell align="center">{index + 1 + pagination.skips}</TableCell>

            {/* Noiz Username */}
            <TableCell align="center">
              {blacklist ?
                (
                  <span data-tip={` <strong>NOTES: </strong> ${item.notes ? item.notes : ''} (blacklisted)`} >
                    {item.username}&nbsp;&nbsp;
                    <img src={creep} className="deadhead" alt="blacklisted head" />
                  </span>
                ) : (
                  <span data-tip={` <strong>NOTES: </strong> ${item.notes ? item.notes : ''}`}>
                    {item.username}
                  </span>)}
            </TableCell>

            {/* Email */}
            <TableCell align="center">{item.email}</TableCell>

            {/* Twitch Username & Average */}
            {showTwitch &&
              <React.Fragment>
                <TableCell align="center">
                  {item.twitchUsername ?
                    <React.Fragment>
                      <Link
                        to={`https://twitch.tv/${item.twitchUsername}`}
                        href={`https://twitch.tv/${item.twitchUsername}`}
                        target="_blank"
                      >
                        <Tooltip title="Twitch" placement="top">
                          <span>{item.twitchUsername}</span>
                        </Tooltip>
                      </Link>
                      <Link
                        to={`https://sullygnome.com/channel/${item.twitchUsername}/30`}
                        href={`https://sullygnome.com/channel/${item.twitchUsername}/30`}
                        target="_blank"
                      >
                        <Tooltip title="SullyGnome 30" placement="top">
                          <i style={{ margin: '3px 0px 0px 5px' }} className="fa fa-external-link" />
                        </Tooltip>
                      </Link>
                    </React.Fragment>
                    :
                    '-'
                  }
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Arithmetic Average (straight average)" placement="top" >
                    <span>{isFrozen ? override : displayAvg30}</span>
                  </Tooltip>
                  {/* <span style={{ margin: '0px 3px' }}>|</span>
                  <Tooltip title="Normalized Average (only numbers within two standard deviations of mean)"placement="top">
                    <span style={{ color: '#77dd77', cursor: 'arrow' }}>
                      {Math.round(normalized30)}
                    </span>
                  </Tooltip>&nbsp; */}
                  &nbsp;
                  <Tooltip title="Force SullyGnome Refresh" placement="top">
                    <button
                      className="small-btn"
                      onClick={this.forceUpdate.bind(this, [item.id])}
                      style={{ color: 'green' }}
                    >
                      <i className='fa fa-refresh' />
                    </button>
                  </Tooltip>
                  {isFrozen ? (<img src={snowFlake} data-tip={frozenText} className="snowflake" alt="This one frozen, yo" />) : null}
                </TableCell>
              </React.Fragment>
            }

            {/* Paymount Amount */}
            <TableCell align="center">
              {toPay.value === -1 ? 'Free Key'
                : toPay.value === -2 ? 'Points'
                  : `$${toPay.value}${whichTier}`
              }
            </TableCell>

            { /* IP Country */}
            <TableCell align="center">{item.ipCountry ? item.ipCountry : (
              <Tooltip title="No IP Country Found; Using User Defined Country" placement="top">
                <b>{item.country}</b>
              </Tooltip>
            )}
            </TableCell>

            { /* Language */}
            <TableCell align="center">{item.language ? languages(item.language) : '-'}</TableCell>

            { /* Date Joined/Left */}
            <TableCell align="center">{moment(item.dateJoined).format('MM/DD/YY - hh:mm a')}</TableCell>

            { /* Quest Statistics */}
            <TableCell align="center">
              {item.userQuestStatistics ?
                <span data-tip="Quests Ended: Completed / Participated">
                  {item.userQuestStatistics.questEndedCompleted} / {item.userQuestStatistics.questEndedParticipationAward}
                </span>
                : null
              }
            </TableCell>

            {/* Show In Campaign Flag */}
            {tab === 'pending' && partOfCampaign && (
              <TableCell align="center">
                {item.campaignFlag ?
                  <Tooltip title={item.campaignFlag} placement="top">
                    <i className="fa fa-flag" />
                  </Tooltip>
                  :
                  null
                }
              </TableCell>
            )}

            { /* Actions */}
            <TableCell align="center">
              <div
                style={{
                  display: 'flex',
                  width: '150px',
                  padding: '0px 10px',
                  margin: 'auto',
                }}
              >
                {(item.status !== 'approved') && (
                  (!keyDistribute || keysAvailables > 0) ?
                    <Tooltip title="Approve" placement="top">
                      <button
                        className="small-btn"
                        onClick={this.approve.bind(this, [item.id])}
                        style={{ color: '#49e1ca' }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </Tooltip>
                    :
                    <ApprovalModal
                      checkmark
                      approve={this.approve.bind(this)}
                      styling={{ color: '#49e1ca' }}
                      selected={[item.id]}
                    />
                )}
                {(item.status !== 'declined') && (
                  <Tooltip title="Decline" placement="top">
                    <button
                      className="small-btn"
                      onClick={this.decline.bind(this, [item.id])}
                      style={{ color: 'red' }}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </Tooltip>
                )}
                {
                  (item.status === 'declined' || item.status === 'approved')
                  &&
                  <Tooltip title="Move to Pending" placement="top">
                    <button className="small-btn" onClick={this.pending.bind(this, [item.id])} style={{ color: '#ffd300' }}><i className="fa fa-clock-o" /></button>
                  </Tooltip>
                }
                {(showingElements.indexOf(item.username) === -1 && item.userInformation) && (
                  <Tooltip title="Recent Activity" placement="top">
                    <button className="small-btn" onClick={this.toggleElementInfo.bind(this, item.username)}>+</button>
                  </Tooltip>
                )}
                {(showingElements.indexOf(item.username) !== -1 && item.userInformation) && (
                  <Tooltip title="Collapse Recent Activity" placement="top">
                    <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, item.username)}>-</button>
                  </Tooltip>
                )}
                {showEditTier && (
                  <EditTier
                    {...this.props}
                    memberTiers={this.state.memberTiers}
                    viewerTiers={viewerTiersWithSpacesLeft(this.state.quest, userQuests)}
                    averageViewersAtJoin={averageViewers}
                    user={item}
                    rehydrateCallback={this.props.getCCQuestGeneral}
                    pagination={[this.state.numPage, this.state.pageSize, this.state.tab]}
                    currentFiltering={this.getCurrentFiltering()}
                    locMod="preapproval"
                  />
                )}
                <EditUserQuest
                  {...this.props}
                  currentUser={item}
                  status="pending"
                />
              </div>
            </TableCell>

            {/* Select */}
            <TableCell align="center">
              <input
                style={{ margin: '0px' }}
                type="checkbox"
                onClick={() => { this.toggleSelectUser(item.id); }}
                checked={(selectedUsers.indexOf(item.id) !== -1)}
              />
            </TableCell>
          </TableRow>
          {this.state.showingElements.indexOf(item.username) !== -1 &&
            <React.Fragment>
              {this.generateDetailsRowsHTML(item)}
            </React.Fragment>
          }
          <ReactTooltip className="tip" effect="solid" place="top" multiline html />
        </React.Fragment>
      );
    });
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
    }, this.getUserQuests);
  }

  renderSearchModal() {
    return (
      <section className="ModalPreapproval active">
        <section className="Modal active">
          <section className="Modal__wrapper">
            <h2>Type the username or email you'd like to search for</h2>
            <input
              type="text"
              value={this.state.searchValueCurrent}
              onChange={this.handleSearchValueChange.bind(this)}
              style={{ display: 'flex' }}
            />
            <div className="Filters__close">
              <button onClick={this.toggleSearchModal.bind(this)}>
                <i className="fa fa-times" /> Cancel
              </button>
              <button onClick={this.clearSearch.bind(this, true)}>
                <i className="fa fa-eraser" /> Clear
              </button>
              <button onClick={this.applySearch.bind(this)}>
                <i className="fa fa-check" /> Apply
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  renderDownloadModal() {
    const { data: questGeneralCsv, isLoading } = this.props.commandCenter.questGeneralCsv;
    const { data: quest } = this.props.commandCenter.quest;
    const {
      headersCSV, dataCSV, pendingCSV, approvedCSV, declinedCSV, leftQuestCSV,
    } = questPreapproval(questGeneralCsv, quest);

    return (
      <section className="ModalPreapproval active">
        <section className="Modal active">
          <button onClick={this.closeDownloadModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Modal__download">
            <div className="Modal__download--content">
              <div className="Modal__download--header">
                <h2 style={{ fontSize: '21px' }}> Select what info you would like to download </h2>
              </div>
              {
                (isLoading) &&
                <div className="Modal__download--spinner" style={{ padding: '0px' }}>
                  <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                </div>
              }
              {
                (!isLoading) &&
                <div className="Modal__download--body">
                  <div className="Modal__download--row">
                    <CSVLink data={dataCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}-all.csv` : 'report.csv'}>
                      All Users
                    </CSVLink>
                  </div>
                  <div className="Modal__download--row">
                    <CSVLink data={pendingCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}-applied.csv` : 'report.csv'}>
                      Applied
                    </CSVLink>
                    <CSVLink data={approvedCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}-approved.csv` : 'report.csv'}>
                      Approved
                    </CSVLink>
                    <CSVLink data={declinedCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}-declined.csv` : 'report.csv'}>
                      Declined
                    </CSVLink>
                    <CSVLink data={leftQuestCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}-leftQuest.csv` : 'report.csv'}>
                      Left Quest
                    </CSVLink>
                  </div>
                  <div className="Modal__download--footer">
                    <button
                      onClick={this.closeDownloadModal.bind(this)}
                      className="Modal__download--cta"
                    >
                      <i className="fa fa-times" />&nbsp;Close
                    </button>
                  </div>
                </div>
              }
            </div>
          </section>
        </section>
      </section>
    );
  }

  handleChange(e) {
    const pageSize = e.target.value;
    const numPage = 1;

    this.setState({
      pageSize,
      numPage,
    }, this.getUserQuests);
  }

  renderUpdateModal() {
    const { keysAvailables } = this.props.commandCenter.questGeneral;
    const { selectedUsers } = this.state;

    const keyDistribute = this.props.commandCenter.quest.data.keyRequired && keyDistributionStepExists(this.props.commandCenter.quest.data);

    const approvalBtn = (!keyDistribute || (keysAvailables > 0 && keysAvailables >= selectedUsers.length)) ? (
      <button onClick={this.approve.bind(this, selectedUsers)} className="Modal__download--cta" style={{ margin: '0px 5px' }}>
        Approve {selectedUsers.length} Users
      </button>
    ) : (
      <ApprovalModal
        approve={this.approve.bind(this)}
        styling={{ color: '#49e1ca' }}
        selected={selectedUsers}
        className="Modal__download--cta"
      />
    );

    return (
      <section className="ModalPreapproval active">
        <section className="Modal active">
          <button onClick={this.closeDownloadModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Modal__download">
            <div className="Modal__download--content">
              <div className="Modal__download--header">
                Are you sure you want to update {selectedUsers.length} user(s)?
              </div>
              <div className="Modal__download--buttons">
                <button onClick={this.pending.bind(this, selectedUsers)} className="Modal__download--cta" style={{ margin: '0px 5px' }}> Set {selectedUsers.length} Users to Applied </button>
                {approvalBtn}
                <button onClick={this.decline.bind(this, selectedUsers)} className="Modal__download--cta" style={{ margin: '0px 5px' }}> Decline {selectedUsers.length} Users </button>
              </div>
              <div className="Modal__download--footer">
                <button
                  onClick={this.closeUpdateModal.bind(this)}
                  className="Modal__download--cta"
                >
                  <i className="fa fa-times" />&nbsp;Close
                </button>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }

  render() {
    const { gameId } = this.props.router.params;
    const { data: questGeneral, isLoading: isQuestGeneralLoading } = this.props.commandCenter.questGeneral;
    const { data: quest, pagination } = this.props.commandCenter.quest;
    const { pageSize } = this.state;

    const partOfCampaign = quest.campaigns && quest.campaigns.length > 0;

    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
      this.setState({
        numPage: 1,
      });
    };

    return (
      <section className="CCQuestPreapproval">
        <Link
          to={`/admin/command-center/${gameId}/quests`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests`}
        >
          <button className="CCQuests__back--btn">Back</button>
        </Link>
        <h2>Title: {quest.title}</h2>
        <div className="Global__tabs">
          <div
            className={classNames('Global__tab', { active: this.state.tab === 'pending' })}
            onClick={this.changeTab.bind(this, 'pending')}
            aria-hidden
          >
            Applied ({pagination ? pagination.userCounts.appliedCount : 0})
          </div>
          <div
            className={classNames('Global__tab', { active: this.state.tab === 'approved' })}
            onClick={this.changeTab.bind(this, 'approved')}
            aria-hidden
          >
            Approved ({pagination ? pagination.userCounts.approvedCount : 0})
          </div>
          <div
            className={classNames('Global__tab', { active: this.state.tab === 'declined' })}
            onClick={this.changeTab.bind(this, 'declined')}
            aria-hidden
          >
            Declined ({pagination ? pagination.userCounts.declinedCount : 0})
          </div>
          <div
            className={classNames('Global__tab', { active: this.state.tab === 'leftQuest' })}
            onClick={this.changeTab.bind(this, 'leftQuest')}
            aria-hidden
          >
            Left Quest ({pagination ? pagination.userCounts.leftQuestCount : 0})
          </div>
          <div
            className={classNames('Global__tab', { active: this.state.tab === 'all' })}
            onClick={this.changeTab.bind(this, 'all')}
            aria-hidden
          >
            Total ({pagination ? pagination.userCounts.totalCount : 0})
          </div>
        </div>
        <section className="ToggleTable" style={{ width: 'auto' }}>
          {this.renderFirstRow(true, pagination)}
          <Table>
            <TableHead>
              <TableRow>
                {this.getTableHeaders(partOfCampaign).map(headCell => (
                  <TableHeaderCell
                    key={headCell.id}
                    align="center"
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection="asc"
                    style={{ padding: '0px' }}
                  >
                    <TableSortLabel
                      active={this.state.orderBy === headCell.id}
                      direction={this.state.order}
                      onClick={createSortHandler(headCell.id)}
                      IconComponent={ArrowDropDownIcon}
                    >
                      {headCell.label}
                      {this.state.orderBy === headCell.id ? (
                        <span style={{ display: 'none' }}>
                          {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableHeaderCell>))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (questGeneral && questGeneral.length > 0 && !isQuestGeneralLoading)
                &&
                (this.getTableRows(questGeneral, partOfCampaign))
              }
            </TableBody>
          </Table>
          {
            (questGeneral.length <= 0 && !isQuestGeneralLoading)
            &&
            <section className="ToggleTable__table flex-table" style={{ textAlign: 'center', padding: '50px' }}>
              No users available
            </section>
          }
          {
            (questGeneral.length > 0 && !isQuestGeneralLoading)
            &&
            <div className="numUsersSection">
              <span>Users per page:</span>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pageSize}
                onChange={this.handleChange.bind(this)}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={MAX_PAGE_SIZE}>All</MenuItem>
              </Select>
            </div>
          }
          {
            (isQuestGeneralLoading)
            &&
            <section className="CCQuestPreapproval__loading">
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            </section>
          }
        </section>
        {(this.state.searchModal) && this.renderSearchModal()}
        {(this.state.downloadModal) && this.renderDownloadModal()}
        {(this.state.updateModal) && this.renderUpdateModal()}
      </section>
    );
  }
}

QuestPreapproval.propTypes = {

};

export default QuestPreapproval;
