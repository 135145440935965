import React from 'react';
import './PortalNav.scss';
import CampaignSelector from '../main/brand/CampaignSelector';

function PortalNav({
  campaign, campaigns, router, visible,
}) {
  if (!visible) return null;

  return (
    <>
      {campaign ? (
        <CampaignSelector
          campaign={campaign}
          campaigns={campaigns}
          router={router}
        />
      ) : null}
    </>
  );
}

export default PortalNav;
