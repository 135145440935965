/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import { validateTwitterHandle } from '../../utils/functions';
import '../login/login.scss';
// import '../login/sign-in/SignIn.scss';

class LoginUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      streamingConsoles: props.auth.user.streamingConsoles ? props.auth.user.streamingConsoles : {
        pc: false,
        xbox: false,
        playstation: false,
        nintendo: false,
        mobile: false,
      },
    };
  }

  submit = (userFormInfo) => {
    userFormInfo.streamingConsoles = this.state.streamingConsoles;
    this.props.onSubmit(userFormInfo);
  }

  onChange(checkbox) {
    // Toggle state
    const newStreamingConsoles = this.state.streamingConsoles;
    newStreamingConsoles[checkbox.target.id] = !this.state.streamingConsoles[checkbox.target.id];
    this.setState({
      streamingConsoles: newStreamingConsoles,
    });
  }

  render() {
    const { handleSubmit } = this.props;

    const streamingPlatformOptions = [
      { label: 'PC', value: 'pc' },
      { label: 'Xbox', value: 'xbox' },
      { label: 'PlayStation', value: 'playstation' },
      { label: 'Nintendo', value: 'nintendo' },
      { label: 'Mobile', value: 'mobile' },
    ];

    return (
      <form className="form-container-quick contact-light-quick" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          placeholder="@twittername"
          type="text"
          name="twitter"
          id="twitter"
          customLabel={((<label>Twitter</label>))}
          component={CustomInput}
        />
        <Field
          name="discord"
          id="discord"
          placeholder="Discord User"
          type="text"
          customLabel={((<label>Discord</label>))}
          component={CustomInput}
        />
        <p className="subHeader" style={{ margin: '50px 0px 20px 0px' }}>
          Tell us what you stream on, so we can bring you more opportunities in the future!
        </p>

        <div style={{ marginBottom: '30px' }}>
          {
          streamingPlatformOptions.map(platform => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <input
                name={`${platform.value}`}
                id={`${platform.value}`}
                customLabel={`${platform.label}`}
                type="checkbox"
                style={{ width: '30px' }}
                onChange={this.onChange.bind(this)}
                defaultChecked={this.state.streamingConsoles[`${platform.value}`]}
              />
              <span>{platform.label}</span>
            </div>
            ))
        }
        </div>

        <div className="form__footer-quick grid-x" style={{ margin: '20px 0px' }}>
          <div className="form__footer__left-quick large-5">
            <button className="cta-button-quick">
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.twitter && !validateTwitterHandle(values.twitter.trim())) {
    errors.comments = 'Invalid format.  Add "@"';
  }
  return errors;
};


LoginUpdateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'loginUpdateForm',
  validate,
})(LoginUpdateForm);
