import React from 'react';

export const toolStyle = { backgroundColor: "rgba(255, 255, 255, 0.5)", padding: "4px 10px", borderRadius: "4px", fontWeight: "bold" };

export const xAxisFormatter = value => {
  // make a date from the value and reformat it to YY-MM-DD
  const date = new Date(value);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  return `${month}-${day} (${hour}:00)`;
};

export const customDateAndHour = (value, simple = false) => {
  // make a date from the value and reformat it to YY-MM-DD
  // make an object with the keys as months and the values as the month in english
  const months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  if (!value) return "";
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthWritten = months[date.getMonth() + 1];
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const minutesDisplay = minutes < 10 ? `0${minutes}` : minutes;
  const hourDisplay = hour < 10 ? `0${hour}` : hour;
  
  return simple ? `${monthWritten} ${day}, ${year}` : `${month}-${day}-${year}-${hourDisplay}:${minutesDisplay}`;
};

export const trimData = (data, start = 0, end = data.length || 0) => {
  let trimIndexStart = 0;
  let trimIndexEnd = data.length - 1;
if (start && end) {
  if (start > end) {
    return data.slice(end,start);
  }
  return data.slice(start, end);
}

  for (let i = 0; i < data.length; i++) {
    if (data[i].value > 0) {
      trimIndexStart = i;
      break;
    }
  }
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i].value > 0) {
      trimIndexEnd = i;
      break;
    }
  }
  return data.slice(trimIndexStart, trimIndexEnd);
 };

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip' style={toolStyle}>
        <div className='label'>Time</div>
        <div className='label'>{`${customDateAndHour(label)}`}</div>
        <div className='label'>Aggregate CCV</div>
        <div className='intro'>{`${payload[0].value}`}</div>
      </div>
    );
  }

  return null;
};
