/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import {
  without,
  filter,
  find,
} from 'lodash';
import classNames from 'classnames';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
// import { simpleNotification } from '../../../../utils/notifications';
import './QuestAffiliateActivity.scss';

class QuestAffiliateActivity extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingElements: [],
      tab: 6,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestAffiliate(questId, token);
    }
  }

  changeTab = (param) => {
    this.setState({
      tab: param,
    });
  }

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [
          ...showingElements,
          elementId,
        ],
      });
    } else {
      this.setState({
        showingElements: [
          ...without(showingElements, elementId),
        ],
      });
    }
  }

  postAffiliateResolution = (userId, stepTitle, stepType, status, resolution) => {
    const { questId } = this.props.router.params;
    const { token } = this.props.auth;
    const data = JSON.stringify({
      userId,
      questId,
      stepTitle,
      stepType,
      status,
      resolution,
    });
    this.props.postCCAffiliateResolution(data, token);
  }

  generateLinksRowsHTML = (items, steps, status, userId) => {
    if (steps.length > 0) {
      return steps.map((step) => {
        const item = find(items, { title: step.title, type: step.type });
        if (!item && (status === 'content-toReview' || status === 'content-approved' || status === 'link-toReview' || status === 'completed' || status === 'toReview' || status === 'rejected')) {
          return <React.Fragment />;
        } else if (item && item.status !== 'content-toReview' && status === 'content-toReview') {
          return <React.Fragment />;
        } else if (item && (item.status !== 'content-approved' && item.status !== 'link-rejected') && status === 'content-approved') {
          return <React.Fragment />;
        } else if (item && item.status !== 'link-toReview' && status === 'link-toReview') {
          return <React.Fragment />;
        } else if (item && item.status !== 'completed' && status === 'completed') {
          return <React.Fragment />;
        } else if (item && (item.status !== 'link-toReview' && item.status !== 'content-toReview') && status === 'toReview') {
          return <React.Fragment />;
        } else if (item && (item.status !== 'link-rejected' && item.status !== 'content-rejected') && status === 'rejected') {
          return <React.Fragment />;
        }
        const itemStatus = (item) ? item.status : '';
        const itemContent = (item && item.content) ? item.content : '- - -';
        let pre = '';
        if (item && item.link && !item.link.toLowerCase().startsWith('http')) {
          pre = 'http://';
        }
        const itemLink =
          (item && item.link) ?
            <Link href={`${pre}${item.link.toLowerCase()}`} target="_blank">{`${pre}${item.link.toLowerCase()}`}</Link> :
            '- - -';
        let actions = <React.Fragment />;
        if (status === 'content-toReview' || (itemStatus === 'content-toReview' && status === 'toReview')) {
          actions =
            (
              <React.Fragment>
                <button
                  onClick={this.postAffiliateResolution.bind(this, userId, step.title, step.type, 'content-toReview', true)}
                  style={{ color: '#49e1ca' }}
                >
                  <i className="fa fa-check" />
                </button>
                <button
                  onClick={this.postAffiliateResolution.bind(this, userId, step.title, step.type, 'content-toReview', false)}
                  style={{ color: 'red' }}
                >
                  <i className="fa fa-times" />
                </button>
              </React.Fragment>
            );
        } else if (status === 'link-toReview' || (itemStatus === 'link-toReview' && status === 'toReview')) {
          actions =
            (
              <React.Fragment>
                <button
                  onClick={this.postAffiliateResolution.bind(this, userId, step.title, step.type, 'link-toReview', true)}
                  style={{ color: '#49e1ca' }}
                >
                  <i className="fa fa-check" />
                </button>
                <button
                  onClick={this.postAffiliateResolution.bind(this, userId, step.title, step.type, 'link-toReview', false)}
                  style={{ color: 'red' }}
                >
                  <i className="fa fa-times" />
                </button>
              </React.Fragment>
            );
        }
        return (
          <div
            key={`${step.type}-${step.title}`}
            className="row"
          >
            <div
              className={classNames(
                'table-cell',
                {
                  green: (itemStatus === 'completed' || itemStatus === 'link-toReview' || itemStatus === 'content-approved' || itemStatus === 'link-rejected'),
                  error: (itemStatus === 'content-rejected'),
                },
              )}
            >
              {itemContent}
            </div>
            {
              (status === 'any' || status === 'completed' || status === 'link-toReview' || status === 'toReview' || status === 'rejected')
              &&
              <div
                className={classNames(
                  'table-cell',
                  {
                    green: (itemStatus === 'completed'),
                    error: (itemStatus === 'link-rejected'),
                  },
                )}
              >
                {itemLink || '- - -'}
              </div>
            }
            <div className="table-cell capitalize">{step.type}</div>
            {
              (status === 'content-toReview' || (itemStatus === 'content-toReview' && status === 'toReview'))
              &&
              <div className="table-cell">{moment(item.contentSubmissionDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
            }
            {
              (status === 'link-toReview' || (itemStatus === 'link-toReview' && status === 'toReview'))
              &&
              <div className="table-cell">{moment(item.linkSubmissionDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
            }
            {
              (status === 'link-rejected' || (itemStatus === 'link-rejected' && status === 'rejected'))
              &&
              <div className="table-cell">{moment(item.linkRejectedDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
            }
            {
              (status === 'content-rejected' || (itemStatus === 'content-rejected' && status === 'rejected'))
              &&
              <div className="table-cell">{moment(item.contentRejectedDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
            }
            {
              (status === 'completed')
              &&
              <div className="table-cell">{moment(item.completedDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
            }
            {
              (status === 'content-approved' || status === 'link-rejected')
              &&
              <div className="table-cell">{moment(item.contentApprovedDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
            }
            {
              (status === 'content-toReview' || status === 'link-toReview' || status === 'toReview')
              &&
              <div className="table-cell">
                { actions }
              </div>
            }
          </div>
        );
      });
    }
    return (<div className="NoItem">No Sale.</div>);
  }

  generateSummaryRowsHTML = (items, steps, ended) => {
    if (items && items.length > 0) {
      return items.map((item, index) => {
        /* STRAT - Checking status */
        let allCompleted = true;
        let underReview = false;
        let onlyLinkToReviewAndCompleted = true;
        steps.forEach((step) => {
          const item2 = find(item.affiliateLinks, { title: step.title, type: step.type });
          const item2Status = (item2) ? item2.status : '';
          if (item2Status !== 'completed') {
            allCompleted = false;
          }
          if (
            item2Status === 'completed' ||
            item2Status === 'link-toReview' ||
            item2Status === 'content-toReview' ||
            item2Status === 'content-approved' ||
            item2Status === 'link-rejected'
          ) {
            underReview = true;
          }
          if (
            item2Status !== 'completed' &&
            item2Status !== 'link-toReview'
          ) {
            onlyLinkToReviewAndCompleted = false;
          }
        });
        let statusHTML = <React.Fragment />;
        if (allCompleted) {
          statusHTML =
          (
            <div className="table-cell success">
              Completed
            </div>
          );
        } else if (ended && onlyLinkToReviewAndCompleted) {
          statusHTML =
          (
            <div className="table-cell warning">
              Under Review
            </div>
          );
        } else if (ended) {
          statusHTML =
          (
            <div className="table-cell error">
              Failed
            </div>
          );
        } else if (underReview) {
          statusHTML =
          (
            <div className="table-cell warning">
              Under Review
            </div>
          );
        } else {
          statusHTML =
          (
            <div className="table-cell">
              Not Started
            </div>
          );
        }
        /* END - Checking status */
        return (
          <div
            key={item.id}
            className="row toggable"
          >
            <div className="row-basic">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">{item.user.username}</div>
              <div className="table-cell">{moment(item.createdAt).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
              { steps.map((step) => {
                const item2 = find(item.affiliateLinks, { title: step.title, type: step.type });
                let pre = '';
                if (item2 && item2.link && !item2.link.toLowerCase().startsWith('http')) {
                  pre = 'http://';
                }
                if (item2 && item2.link && item2.status === 'completed') {
                  return (
                    <div className="table-cell">
                      <Link
                        href={`${pre}${item2.link}`}
                        target="_blank"
                      >
                        <i className="fa fa-check green" style={{ color: '#49e1ca' }} />
                      </Link>
                    </div>
                  );
                }
                return <div className="table-cell" />;
              }) }
              { statusHTML }
            </div>
          </div>
        );
      });
    }
  }

  generateRowsHTML = (items, status, steps) => {
    const { showingElements } = this.state;
    if (items && items.length > 0) {
      return items.map((item, index) => {
        let itemsContentToReview = [];
        let itemsContentApproved = [];
        let itemsLinkToReview = [];
        let itemsCompleted = [];
        let itemsToReview = [];
        let itemsRejected = [];
        if (item.affiliateLinks) {
          itemsContentToReview = filter(item.affiliateLinks, { status: 'content-toReview' });
          itemsContentApproved = filter(item.affiliateLinks, o => o.status === 'content-approved' || o.status === 'link-rejected');
          itemsLinkToReview = filter(item.affiliateLinks, { status: 'link-toReview' });
          itemsCompleted = filter(item.affiliateLinks, { status: 'completed' });
          itemsToReview = filter(item.affiliateLinks, o => o.status === 'content-toReview' || o.status === 'link-toReview');
          itemsRejected = filter(item.affiliateLinks, o => o.status === 'content-rejected' || o.status === 'link-rejected');
        }
        if (
          (status === 'content-toReview' && itemsContentToReview.length === 0) ||
          (status === 'content-approved' && itemsContentApproved.length === 0) ||
          (status === 'link-toReview' && itemsLinkToReview.length === 0) ||
          (status === 'completed' && itemsCompleted.length === 0) ||
          (status === 'toReview' && itemsToReview.length === 0) ||
          (status === 'rejected' && itemsRejected.length === 0)
        ) {
          return <React.Fragment />;
        }
        let itemsToPass = [];
        if (status === 'content-toReview') {
          itemsToPass = itemsContentToReview;
        } else if (status === 'content-approved') {
          itemsToPass = itemsContentApproved;
        } else if (status === 'link-toReview') {
          itemsToPass = itemsLinkToReview;
        } else if (status === 'completed') {
          itemsToPass = itemsCompleted;
        } else if (status === 'any') {
          itemsToPass = item.affiliateLinks;
        } else if (status === 'toReview') {
          itemsToPass = itemsToReview;
        } else if (status === 'rejected') {
          itemsToPass = itemsRejected;
        }
        return (
          <div
            key={item.id}
            className={classNames('row toggable', { open: (showingElements.indexOf(item.user.username) !== -1) })}
          >
            <div className="row-basic">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">{item.user.username}</div>
              <div className="table-cell">{itemsToPass.length}</div>
              <div className="table-cell">{moment(item.createdAt).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a')}</div>
              <div className="table-cell">
                { (showingElements.indexOf(item.user.username) === -1) && (
                  <button className="small-btn" onClick={this.toggleElementInfo.bind(this, item.user.username)}>Show Details</button>
                )}
                { (showingElements.indexOf(item.user.username) !== -1) && (
                  <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, item.user.username)}>Hide Details</button>
                ) }
              </div>
            </div>
            <div className="row-details">
              <section className="StreamActivityTable flex-table">
                <div className="row">
                  <div className="table-cell headline">Content</div>
                  {
                    (status === 'any' || status === 'completed' || status === 'link-toReview' || status === 'toReview' || status === 'rejected')
                    &&
                    <div className="table-cell headline">Link</div>
                  }
                  <div className="table-cell headline">Platform</div>
                  {
                    (status === 'content-toReview' || status === 'link-toReview' || status === 'toReview')
                    &&
                    <div className="table-cell headline">Submission Date</div>
                  }
                  {
                    (status === 'rejected')
                    &&
                    <div className="table-cell headline">Rejected Date</div>
                  }
                  {
                    (status === 'completed')
                    &&
                    <div className="table-cell headline">Completed Date</div>
                  }
                  {
                    (status === 'content-approved' || status === 'link-rejected')
                    &&
                    <div className="table-cell headline">Approved Date</div>
                  }
                  {
                    (status === 'content-toReview' || status === 'link-toReview' || status === 'toReview')
                    &&
                    <div className="table-cell headline">Yes/No</div>
                  }
                </div>
                { this.generateLinksRowsHTML(itemsToPass, steps, status, item.user.id) }
              </section>
            </div>
          </div>
        );
      });
    }
    return (<div className="NoQuestGeneral">No one has joined yet.</div>);
  }

  render() {
    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;

    const { gameId } = this.props.router.params;
    const { data: questAffiliate, isLoading: questAffiliateIsLoading } = this.props.commandCenter.questAffiliate;
    const { data: quest } = this.props.commandCenter.quest;

    let affiliateSteps = [];
    let tempSteps = [];
    let tempStepsPage2 = [];
    let tempStepsPage3 = [];

    if (quest && quest.steps) {
      tempSteps = filter(quest.steps, o => (o.type === 'instagram' || o.type === 'facebook' || o.type === 'youtube' || o.type === 'twitter'));
    }

    if (quest && quest.stepsPage2) {
      tempStepsPage2 = filter(quest.stepsPage2, o => (o.type === 'instagram' || o.type === 'facebook' || o.type === 'youtube' || o.type === 'twitter'));
    }

    if (quest && quest.stepsPage3) {
      tempStepsPage3 = filter(quest.stepsPage3, o => (o.type === 'instagram' || o.type === 'facebook' || o.type === 'youtube' || o.type === 'twitter'));
    }

    affiliateSteps = [
      ...tempSteps,
      ...tempStepsPage2,
      ...tempStepsPage3,
    ];

    let itemsContentToReviewCount = 0;
    let itemsContentApprovedCount = 0;
    let itemsLinkToReviewCount = 0;
    let itemsCompletedCount = 0;
    let itemsContentRejectedCount = 0;
    let itemsLinkRejectedCount = 0;
    let itemsToReviewCount = 0;
    let itemsRejectedCount = 0;

    questAffiliate.forEach((item) => {
      let itemsContentToReview = [];
      let itemsContentApproved = [];
      let itemsLinkToReview = [];
      let itemsCompleted = [];
      let itemsContentRejected = [];
      let itemsLinkRejected = [];
      let itemsToReview = [];
      let itemsRejected = [];
      if (item.affiliateLinks) {
        itemsContentRejected = filter(item.affiliateLinks, { status: 'content-rejected' });
        itemsLinkRejected = filter(item.affiliateLinks, { status: 'link-rejected' });
        itemsContentToReview = filter(item.affiliateLinks, { status: 'content-toReview' });
        itemsContentApproved = filter(item.affiliateLinks, o => o.status === 'content-approved' || o.status === 'link-rejected');
        itemsLinkToReview = filter(item.affiliateLinks, { status: 'link-toReview' });
        itemsCompleted = filter(item.affiliateLinks, { status: 'completed' });
        itemsToReview = filter(item.affiliateLinks, o => (o.status === 'content-toReview' || o.status === 'link-toReview'));
        itemsRejected = filter(item.affiliateLinks, o => (o.status === 'content-rejected' || o.status === 'link-rejected'));
      }
      itemsContentToReviewCount += itemsContentToReview.length;
      itemsContentApprovedCount += itemsContentApproved.length;
      itemsLinkToReviewCount += itemsLinkToReview.length;
      itemsCompletedCount += itemsCompleted.length;
      itemsContentRejectedCount += itemsContentRejected.length;
      itemsLinkRejectedCount += itemsLinkRejected.length;
      itemsToReviewCount += itemsToReview.length;
      itemsRejectedCount += itemsRejected.length;
    });

    const totalItems = itemsContentToReviewCount + itemsContentApprovedCount + itemsLinkToReviewCount + itemsCompletedCount + itemsContentRejectedCount + itemsLinkRejectedCount;

    const ended = (moment(quest.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') < today);

    /* START - Summary Data */
    const headersCSV = [
      { label: '#', key: 'number' },
      { label: 'Username', key: 'username' },
      { label: 'Date Joined', key: 'dateJoined' },
      { label: 'Status', key: 'status' },
      { label: 'Links', key: 'links' },
    ];

    const dataCSV = [];
    if (questAffiliate && questAffiliate.length > 0) {
      questAffiliate.forEach((item, index) => {
        /* STRAT - Checking status */
        let allCompleted = true;
        let underReview = false;
        let onlyLinkToReviewAndCompleted = true;
        const socialData = [];
        quest.steps.forEach((step) => {
          const item2 = find(item.affiliateLinks, { title: step.title, type: step.type });
          let pre = '';
          if (item2 && item2.link && !item2.link.toLowerCase().startsWith('http')) {
            pre = 'http://';
          }
          const item2Status = (item2) ? item2.status : '';
          if (item2Status !== 'completed') {
            allCompleted = false;
          }
          if (
            item2Status === 'completed' ||
            item2Status === 'link-toReview' ||
            item2Status === 'content-toReview' ||
            item2Status === 'content-approved' ||
            item2Status === 'link-rejected'
          ) {
            underReview = true;
          }
          if (
            item2Status !== 'completed' &&
            item2Status !== 'link-toReview'
          ) {
            onlyLinkToReviewAndCompleted = false;
          }
          if (item2) {
            socialData.push(`${pre}${item2.link}`);
          }
        });
        let summaryStatus = '';
        if (allCompleted) {
          summaryStatus = 'Completed';
        } else if (ended && onlyLinkToReviewAndCompleted) {
          summaryStatus = 'Under Review';
        } else if (ended) {
          summaryStatus = 'Failed';
        } else if (underReview) {
          summaryStatus = 'Under Review';
        } else {
          summaryStatus = 'Not Started';
        }
        const jsonString = JSON.stringify(socialData);
        const replaced = jsonString.replace(/"/g, '\'');
        dataCSV.push({
          number: index + 1,
          username: item.user.username,
          dateJoined: moment(item.createdAt).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a'),
          status: summaryStatus,
          links: replaced,
        });
        /* END - Checking status */
      });
    }
    /* END - Summary Data */
    return (
      <section className="CCQuestAffiliateActivity">
        <Link
          to={`/admin/command-center/${gameId}/quests`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests`}
        >
          <button className="CCQuests__back--btn">Back</button>
        </Link>
        <h2>Title: { (quest) ? quest.title : '' }</h2>
        <div className="CCQuestAffiliateActivity__tabs">
          <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 6 })}
            onClick={this.changeTab.bind(this, 6)}
            aria-hidden
          >
            To Review ({itemsToReviewCount})
          </div>
          <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 7 })}
            onClick={this.changeTab.bind(this, 7)}
            aria-hidden
          >
            Rejected ({itemsRejectedCount})
          </div>
          {/* <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 0 })}
            onClick={this.changeTab.bind(this, 0)}
            aria-hidden
          >
            Content to Review ({itemsContentToReviewCount})
          </div> */}
          <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 1 })}
            onClick={this.changeTab.bind(this, 1)}
            aria-hidden
          >
            Content Approved ({itemsContentApprovedCount})
          </div>
          {/* <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 2 })}
            onClick={this.changeTab.bind(this, 2)}
            aria-hidden
          >
            Link to Review ({itemsLinkToReviewCount})
          </div> */}
          <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 3 })}
            onClick={this.changeTab.bind(this, 3)}
            aria-hidden
          >
            Completed ({itemsCompletedCount})
          </div>
          <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 4 })}
            onClick={this.changeTab.bind(this, 4)}
            aria-hidden
          >
            All ({totalItems})
          </div>
          <div
            className={classNames('CCQuestAffiliateActivity__tab', { active: this.state.tab === 5 })}
            onClick={this.changeTab.bind(this, 5)}
            aria-hidden
          >
            Summary
          </div>
        </div>
        {
          (questAffiliateIsLoading)
          &&
          <section className="CCQuestAffiliateActivity__loading">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </section>
        }
        {
          (!questAffiliateIsLoading && this.state.tab === 6)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Count</div>
                  <div className="table-cell headline">Date Joined</div>
                  <div className="table-cell headline" />
                </div>
                { this.generateRowsHTML(questAffiliate, 'toReview', affiliateSteps) }
              </section>
            </section>
          </section>
        }
        {
          (!questAffiliateIsLoading && this.state.tab === 7)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Count</div>
                  <div className="table-cell headline">Date Joined</div>
                  <div className="table-cell headline" />
                </div>
                { this.generateRowsHTML(questAffiliate, 'rejected', affiliateSteps) }
              </section>
            </section>
          </section>
        }
        {/*
          (!questAffiliateIsLoading && this.state.tab === 0)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Count</div>
                  <div className="table-cell headline">Date Joined</div>
                  <div className="table-cell headline" />
                </div>
                { this.generateRowsHTML(questAffiliate, 'content-toReview', affiliateSteps) }
              </section>
            </section>
          </section>
        */}
        {
          (!questAffiliateIsLoading && this.state.tab === 1)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Count</div>
                  <div className="table-cell headline">Date Joined</div>
                  <div className="table-cell headline" />
                </div>
                { this.generateRowsHTML(questAffiliate, 'content-approved', affiliateSteps) }
              </section>
            </section>
          </section>
        }
        {/*
          (!questAffiliateIsLoading && this.state.tab === 2)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Count</div>
                  <div className="table-cell headline">Date Joined</div>
                  <div className="table-cell headline" />
                </div>
                { this.generateRowsHTML(questAffiliate, 'link-toReview', affiliateSteps) }
              </section>
            </section>
          </section>
        */}
        {
          (!questAffiliateIsLoading && this.state.tab === 3)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Count</div>
                  <div className="table-cell headline">Date Joined</div>
                  <div className="table-cell headline" />
                </div>
                { this.generateRowsHTML(questAffiliate, 'completed', affiliateSteps) }
              </section>
            </section>
          </section>
        }
        {
          (!questAffiliateIsLoading && this.state.tab === 4)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Count</div>
                  <div className="table-cell headline">Date Joined</div>
                  <div className="table-cell headline" />
                </div>
                { this.generateRowsHTML(questAffiliate, 'any', affiliateSteps) }
              </section>
            </section>
          </section>
        }
        {
          (!questAffiliateIsLoading && this.state.tab === 5)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row first-row">
                  <CSVLink data={dataCSV} headers={headersCSV} filename={(quest) ? `${quest.title}.csv` : 'report.csv'}>
                    <i className="fa fa-download" />
                  </CSVLink>
                </div>
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Date Joined</div>
                  { affiliateSteps.map(step => <div className="table-cell headline capitalize">{ step.type }</div>) }
                  <div className="table-cell headline">Status</div>
                </div>
                { this.generateSummaryRowsHTML(questAffiliate, affiliateSteps, ended) }
              </section>
            </section>
          </section>
        }
      </section>
    );
  }
}

QuestAffiliateActivity.propTypes = {

};

export default QuestAffiliateActivity;
