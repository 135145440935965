import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router';

import notFound404 from '../images/404-bg.png';

import './NotFound.scss';

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { home, buttonText, text } = this.props;

    const homeLink = home || '/';
    const homeText = buttonText || 'Home';
    const bodyText = text || (<><h1>Oops. <br /> Are you lost?</h1>
        <p>
          There's nothing to see here, let's take you back.
        </p></>);

  return(
      <section className = "NotFound" >
      <div className="NotFound__wrapper">
        <div className="NotFound__content">
          <figure>
            <img src={notFound404} alt="404 BG" />
          </figure>
          <div className="info-wrap">
            <div className="inner">
              {bodyText}
              <Link className="CTAButton__blue back-home" to={homeLink} href={homeLink} >{homeText}</Link>
            </div>
          </div>
        </div>
      </div>
      </section>
    );
  }
}

NotFound.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  //   push: PropTypes.func.isRequired,
  //   location: PropTypes.shape({
  //     pathname: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
};

export default NotFound;
