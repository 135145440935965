import moment from "moment-timezone";

const makeHeaders = () => {
  const theSwitch = {
    x: "Time",
    y: "ACCV",
  };
  return Object.entries(theSwitch).map((item) => ({
    label: item[1],
    key: item[0],
  }));
};

export default function prepRawData(timeSeries) {
  const today = moment().tz("America/Los_Angeles");
  const hoursOffset = -today.utcOffset() / 60;
  const toPST = timeSeries.map((item) => ({
    y: item.y,
    x: moment(item.x)
      .tz("America/Los_Angeles")
      .add(hoursOffset, "hours")
      .format("ddd MMM D, YYYY - hh:mm A z"),
  }));
  return { data: toPST, headers: makeHeaders() };
}
