import React from 'react';
import classnames from 'classnames';
import Icon from '../../../../../global/images/icons/Icon';
import { getDefaultIcon } from '../../../../../utils/functions';

const StreamerDeliverablesCard = props => {
  const { content, id, quest, setModal, setModalContent, status, subtype, timeRequired, title, type } = props;

  const clickable = content && !!!timeRequired;

  const sdCardStatus = switchStatus(status, timeRequired);

  return (
    <div
      className={classnames('StreamerDeliverablesCard', clickable && "Clickable")}
      key={id}
      onClick={() => {
        if (clickable) {
          setModal(true);
          setModalContent(props);
        }
      }}>
      {timeRequired ? (
        <div className="StreamerDeliverablesCard__Icon">
          <Icon
            height='22'
            name='clock'
            width='22'
          />
        </div>
      ) : (
        <img
          className='StreamerDeliverablesCard__Icon'
          src={getDefaultIcon(type, subtype, quest)}
          alt={title}
        />
      )}
      <div className='StreamerDeliverablesCard__Title'>{title}</div>
      <div className={classnames("StreamerDeliverablesCard__Completion", sdCardStatus)}>
        {sdCardStatus === "Completed" && <Icon name='check' />}
        {sdCardStatus === "Rejected" && <Icon name='xmark' />}
        {sdCardStatus === "Pending" && <Icon name='questionmark' color='#b7bbfd' />}
      </div>
    </div>
  );
};

export default StreamerDeliverablesCard;

const switchStatus = (status, timeRequired) => {
  switch (status) {
    case 'completed': return 'Completed';
    case 'rejected': return 'Rejected';
    case 'to-review':
      if (!timeRequired) {
        return 'Pending';
      }
      break;
    default: return 'Incomplete';
  }
};