import React from 'react';
import PropTypes from 'prop-types';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.props.logout();
    this.props.router.replace('/');
  }

  render() {
    return (
      <h1>Logged Out.</h1>
    );
  }
}

Logout.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

export default Logout;
