/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */

import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import 'react-id-swiper/src/styles/css/swiper.css';
import { simpleNotification } from '../../../utils/notifications';
import './Checkout.scss';

const CURRENCY = 'USD';

const fromDollarToCent = amount => Math.round(parseFloat(parseFloat(amount) * 100));
const roundingToString = number => parseFloat(number).toFixed(2);

class ConnectCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  successPayment = (data) => {
    const { gameSlug, questSlug, username } = this.props.params;
    this.props.router.push(`/buy/${gameSlug}/${questSlug}/${username}/success`);
  };

  errorPayment = (data) => {
    simpleNotification({
      level: 'error',
      title: 'Warning.',
      message: 'Payment data wasn\'t correct, please try again.',
    });
    const { gameSlug, questSlug, username } = this.props.params;
    this.props.router.push(`/buy/${gameSlug}/${questSlug}/${username}`);
  };

  postMethod = (amount, description, slug) => token =>
    axios.post(
      '/api/v1/stripe/payment/post',
      {
        description,
        source: token.id,
        currency: CURRENCY,
        amount: fromDollarToCent(amount),
        referrals: localStorage.getItem('referrals'),
        slug,
        tokenEmail: token.owner ? token.owner.email : token.email,
      },
    ).then(this.successPayment).catch(this.errorPayment);

  render() {
    const {
      name, description, amount, publicKey, slug,
    } = this.props;
    return (
      <StripeCheckout
        name={name}
        description={description}
        label={`Buy Now for $${roundingToString(amount)}`}
        image="/apple-touch-icon.png"
        panelLabel="Pay"
        amount={fromDollarToCent(amount)}
        token={this.postMethod(amount, description, slug)}
        currency={CURRENCY}
        stripeKey={publicKey}
      />
    );
  }
}


export default ConnectCheckout;
