function filterUsers(allUsers, filters) {
  const {
    consoles,
    genders,
    languages,
    quests,
    regions,
    streamingPlatforms,
    users,
  } = filters;

  let filteredUsers = [];

  // Iterate over all users
  for (let i = 0; i < allUsers.length; i++) {
    const user = allUsers[i];
    // Users (exclusive)
    if (users && users.length && !users.includes(user.name)) continue;

    // Streaming platforms (inclusive)
    if (streamingPlatforms && streamingPlatforms.length) {
      if (!(streamingPlatforms.includes('twitch') && user.connectedAccounts.twitch.id !== null)) {
        continue;
      }
    }

    // Consoles (inclusive)
    if (consoles && consoles.length) {
      let consoleFound = false;

      for (let i = 0; i < consoles.length; i++) {
        if (user.streamingConsoles) {
          if (user.streamingConsoles[consoles[i]] === true) {
            consoleFound = true;
          }
        }
      }

      if (!consoleFound) continue;
    }

    // Gender (inclusive)
    if (genders && genders.length) {
      if (!genders.includes(user.gender)) {
        continue;
      }
    }

    // Regions (inclusive)
    if (regions && regions.length) {
      if (!regions.includes(user.region)) {
        continue;
      }
    }

    // Language (inclusive)
    if (languages && languages.length) {
      if (!languages.includes(user.language)) {
        continue;
      }
    }

    // Quests (range)
    if (quests) {
      const questsCompleted = user.questsCompleted !== undefined ? user.questsCompleted : 0;
      if (questsCompleted > quests.max || questsCompleted < quests.min) {
        continue;
      }
    }

    // If user made it through all filters, add to list
    filteredUsers.push(user);
  }

  return filteredUsers;
}

export default filterUsers;