const commandCenter = (state = {}, action) => {
  switch (action.type) {
    case 'GET_BU_CAMPAIGN_LIST_PENDING':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: true,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BU_CAMPAIGN_LIST_ERROR':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_BU_CAMPAIGN_LIST_ABORTED':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BU_CAMPAIGN_LIST_SUCCESS':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BU_CAMPAIGN_PENDING':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: true,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BU_CAMPAIGN_ERROR':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_BU_CAMPAIGN_ABORTED':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BU_CAMPAIGN_SUCCESS':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: action.payload.response.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_A_CAMPAIGN_LIST_PENDING':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: true,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_A_CAMPAIGN_LIST_ERROR':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_A_CAMPAIGN_LIST_ABORTED':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_A_CAMPAIGN_LIST_SUCCESS':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_A_CAMPAIGN_PENDING':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: true,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_A_CAMPAIGN_ERROR':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_A_CAMPAIGN_ABORTED':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_A_CAMPAIGN_SUCCESS':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: action.payload.response.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_GAME_PENDING':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_GAME_ERROR':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_BRAND_GAME_ABORTED':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_GAME_SUCCESS':
      return {
        ...state,
        games: {
          ...state.games,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_BRAND_QUEST_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_QUEST_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_BRAND_QUEST_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_QUEST_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_BRAND_STREAMER_PENDING':
      return {
        ...state,
        streamers: {
          ...state.streamers,
          isLoading: true,
          errors: {
            ...state.streamers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_STREAMER_ERROR':
      return {
        ...state,
        streamers: {
          ...state.streamers,
          isLoading: false,
          errors: {
            ...state.streamers.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_BRAND_STREAMER_ABORTED':
      return {
        ...state,
        streamers: {
          ...state.streamers,
          isLoading: false,
          errors: {
            ...state.streamers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_STREAMER_SUCCESS': {
      const uniqueStreamers = [];

      [...action.payload.response.data, ...state.streamers.data].forEach(
        (streamer) => {
          if (!uniqueStreamers.find((s) => s.id === streamer.id)) {
            uniqueStreamers.push(streamer);
          }
        }
      );

      return {
        ...state,
        streamers: {
          ...state.streamers,
          data: uniqueStreamers,
          isLoading: false,
          errors: {
            ...state.streamers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    }

    case 'GET_BRAND_USER_QUEST_PENDING':
      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          isLoading: true,
          errors: {
            ...state.deliverable.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_USER_QUEST_ERROR':
      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          isLoading: false,
          errors: {
            ...state.deliverable.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_BRAND_USER_QUEST_ABORTED':
      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          isLoading: false,
          errors: {
            ...state.deliverable.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BRAND_USER_QUEST_SUCCESS': {
      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.deliverable.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    }

    case 'BRAND_REQUEST_UPLOAD_IMAGE_URL_PENDING':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: true,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'BRAND_REQUEST_UPLOAD_IMAGE_URL_ERROR':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'BRAND_REQUEST_UPLOAD_IMAGE_URL_ABORTED':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'BRAND_REQUEST_UPLOAD_IMAGE_URL_SUCCESS':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'POST_BRAND_GAME_PENDING':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_BRAND_GAME_ERROR':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'POST_BRAND_GAME_ABORTED':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_BRAND_GAME_SUCCESS': {
      const oldGames = state.games.data.games;

      return {
        ...state,
        games: {
          ...state.games,
          data: {
            ...state.games.data,
            games: [...oldGames, action.payload.response.data],
          },
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    }

    case 'POST_BRAND_QUEST_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_BRAND_QUEST_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'POST_BRAND_QUEST_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_BRAND_QUEST_SUCCESS': {
      const oldQuests = state.quests.data.quests;

      return {
        ...state,
        quests: {
          ...state.quests,
          data: {
            ...state.quests.data,
            quests: [...oldQuests, action.payload.response.data],
          },
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    }

    case 'POST_BRAND_REQUIREMENT_PENDING':
    case 'POST_BRAND_PREAPPROVAL_PENDING':
      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          isLoading: true,
          errors: {
            ...state.deliverable.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'POST_BRAND_REQUIREMENT_ERROR':
    case 'POST_BRAND_PREAPPROVAL_ERROR':
      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          isLoading: true,
          errors: {
            ...state.deliverable.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'POST_BRAND_REQUIREMENT_ABORTED':
    case 'POST_BRAND_PREAPPROVAL_ABORTED':
      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          isLoading: true,
          errors: {
            ...state.deliverable.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'POST_BRAND_REQUIREMENT_SUCCESS':
    case 'POST_BRAND_PREAPPROVAL_SUCCESS': {
      const newQuests = action.payload.response.data.quests;
      const updateOldQuests = state.quests.data.quests.map((quest) => {
        const newQuest = newQuests.find((q) => q.id === quest.id);

        if (newQuest) {
          return newQuest;
        }

        return quest;
      });

      return {
        ...state,
        deliverable: {
          ...state.deliverable,
          data: {
            ...action.payload.response.data?.userQuest || {},
          },
          isLoading: false,
          errors: {
            ...state.deliverable.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          data: {
            ...state.quests.data,
            quests: updateOldQuests,
          },
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    };

    case 'PUT_BRAND_GAME_PENDING':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: true,
          isUpdating: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'PUT_BRAND_GAME_ERROR':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          isUpdating: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'PUT_BRAND_GAME_ABORTED':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          isUpdating: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'PUT_BRAND_GAME_SUCCESS': {
      const oldGames = state.games.data.games;
      const newGame = action.payload.response.data;

      oldGames[oldGames.findIndex(q => q.id === newGame.id)] = newGame;

      return {
        ...state,
        games: {
          ...state.games,
          data: {
            ...state.games.data,
            games: oldGames,
          },
          isLoading: false,
          isUpdating: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    }

    case 'PUT_BRAND_QUEST_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: true,
          isUpdating: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'PUT_BRAND_QUEST_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          isUpdating: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'PUT_BRAND_QUEST_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          isUpdating: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'PUT_BRAND_QUEST_SUCCESS': {
      const oldQuests = state.quests.data.quests;
      const newQuest = action.payload.response.data;

      oldQuests[oldQuests.findIndex(q => q.id === newQuest.id)] = newQuest;

      return {
        ...state,
        quests: {
          ...state.quests,
          data: {
            ...state.quests.data,
            quests: oldQuests,
          },
          isLoading: false,
          isUpdating: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    }

    default:
      return state;
  }
};

export default commandCenter;