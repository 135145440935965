import React, { useEffect, useState } from "react";
import axios from "axios";
import { formatTiersForTable, finalsRow } from "./TableHelpers";
import TableBuilder from "./TableBuilder";
import './ReportTable.scss';

const ReportTable = ({ campaign = "", quests = [], token = "" }) => {
  const preppedQuests = quests.length
    ? quests.reduce((acc, quest, i) => {
        if (i === quests.length) {
          acc += `${quest}`;
        } else {
          acc += `${quest}, `;
        }
        return acc;
      })
    : "";

  const [data, setData] = useState([]);
  const [questReference, setQuestReference] = useState([]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    const options = { headers: { Authorization: `JWT ${token}` }, cancelToken: ourRequest.token };
    axios
      .get(`/api/v1/user-brand/reports/table?campaign=${campaign}&quest=${preppedQuests}`, options)
      .then(res => {
        setData(res.data.data.tieredData);
        setQuestReference(res.data.data.questsReference)
      })
      .catch(err => {
        console.log(err, "err");
      });
      
      return () => {
        ourRequest.cancel();
      }
  }, [campaign]);

 const tableData = formatTiersForTable(data, questReference)
 const totals = finalsRow(tableData);

  return (
    <div className="ReportTable_Container">
      <TableBuilder tableData={tableData} totals={totals} />

    </div>
  );
};

export default ReportTable;
