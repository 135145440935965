import React from 'react';
import './QuickUpdate.scss';
import Axios from 'axios';

class QuickUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingErrors: false,
      errors: {

      },
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    const { twitter, discord, streamingConsoles } = user;

    this.props.assignLoginUpdateForm({ twitter, discord, streamingConsoles });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.isLoading === true && nextProps.auth.isLoading === false) {
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      }
    }
  }

  onSubmit = async (arg) => {
    const { token, user } = this.props.auth;
    const {
      firstName, lastName, birthDate, timezone,
    } = user;
    const preppedArg = {
      ...arg, firstName, lastName, birthDate, timezone,
    };

    await this.postToBroadcast(this.props.broadcast); // order here is important.
    await this.props.submitCallback(preppedArg, token);
    this.props.togglePopup();
  }

  postToBroadcast = async (broadcastKey) => {
    const { token } = this.props.auth;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`,
    };

    await Axios.post('/api/v1/user/broadcasts', { broadcast: broadcastKey }, { headers });
  }

  dismissErrors = () => {
    this.setState({
      showingErrors: false,
      errors: {
        hasError: false,
        code: '',
        message: '',
        all: {},
      },
    });
  }

  quitBuggingMe = async () => {
    // Posts to DON'T SHOW AGAIN on close or on blur
    await this.postToBroadcast(this.props.broadcast);
    this.props.togglePopup();
  }

  render() {
    const { hidden, Form } = this.props;
    if (hidden) return null;

    return (
      <div className="flex-parent-popup">
        <div onClick={this.props.togglePopup} className="popup" />
        <div className="popup_inner">
          <span className="closeSignup" onClick={this.quitBuggingMe}>
            &times;
          </span>

          <div className="signupBody">
            <p className="popupHeader">{this.props.header || 'Select a header, friendo!'}</p>
            <p className="subHeader">
              {this.props.subHeader || 'Select a subheader, friendo!'}
            </p>
            {Form ? <Form auth={this.props.auth} onSubmit={this.onSubmit} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default QuickUpdate;
