const makeHeaders = () => {
  const theSwitch = {
    accv: "Total Period ACCV",
    email: "Email",
    firstName: "First Name",
    gender: "Gender",
    hourlyAvgViewersTotal:"Sum of Average Viewers",
    id: "Noiz ID",
    language: "Language",
    lastName: "Last Name",
    name: "First Name",
    paymentEmail: "Payment Email",
    questsAttempted: "Quests Accepted",
    questsCompleted: "Quests Completed",
    region: "Region",
    timePlayingTotal: "Time Playing Total",
    timezone: "Timezone",
    twitchId: "Twitch ID",
    twitchUsername: "Twitch Username",
  };
  
  return Object.entries(theSwitch).map((item) => ({
    label: item[1],
    key: item[0],
  }));
};

export default function prepUserData(timeSeries) {
  return { data: timeSeries, headers: makeHeaders() }
}
