export const defaultProductButtons = [
  {
    tip: 'Edit',
    img: 'edit',
  },
  {
    tip: 'Add New Quest',
    img: 'plus',
  },
]

export const defaultProductMvpButtons = [
  {
    tip: 'Edit',
    img: 'edit',
  },
  {
    tip: 'View Quests',
    img: 'House',
  },
]

export const defaultMvpQuestButtons = [
  {
    tip: 'Edit',
    img: 'edit',
  },
  {
    tip: 'Details',
    img: 'graph',
  },
]

export const defaultQuestButtons = [
  {
    tip: 'Edit',
    img: 'edit',
  },
  {
    tip: 'Details',
    img: 'graph',
  },
]


export const defaultCampaignButtons = [
  {
    tip: 'Edit',
    img: 'edit',
  },
  {
    tip: 'Details',
    img: 'graph',
  },
]

export const defaultMvpCampaignButtons = [
  {
    tip: 'Run Report',
    img: 'graph',
  },
]