import React, {useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip as TT, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

import { getCompleteUsers } from '../queries';
import { numberWithCommas } from '../../../../utils/functions';
import { prepSummaryCSV, prepUserCSV, prepRawCSV } from '../helpers/index';
import { CSVLink } from 'react-csv';
import Tooltip from '@material-ui/core/Tooltip';
import './InsightsGraph.scss';
ChartJS.register(...registerables, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, TT, zoomPlugin);


const today = moment().tz('America/Los_Angeles');
const hoursOffset = -today.utcOffset() / 60;
const localizeMe = (d, s) => {
  const momentString = s ? 'ddd MMM D, YYYY - hh:mm A z' : 'ddd MMM D, YYYY - hh A z'
  return moment(d).tz('America/Los_Angeles').add(hoursOffset, 'hours').format(momentString);
};

function InsightsGraph({ dateRange, graphData, singleUser, token, users }) {
  const [rawCSVTitle, setRawCSVTitle] = useState('');
  const [rawData, setRawData] = useState({ data: [], headers: [] });
  const [summaryCSVTitle, setSummaryCSVTitle] = useState('');
  const [summaryData, setSummaryData] = useState({ data: [], headers: [] });
  const [userData, setUserData] = useState({ data: [], headers: [] });
  const [usersCSVTitle, setUsersCSVTitle] = useState('');


  useEffect(() => {
    const stringDate = dateRange && dateRange.length && `${localizeMe(dateRange[0], singleUser)}-${localizeMe(dateRange[1], singleUser)}`
    setSummaryCSVTitle( `${singleUser ? users[0]?.name: 'Users'}-stream-summary-${stringDate}`);
    setRawCSVTitle(`${singleUser ? users[0]?.name: 'Users'}-raw-data-${stringDate}`);
    setUsersCSVTitle(`${singleUser ? users[0]?.name: 'Users'}-users-summary-${stringDate}`);
  }, [dateRange]);

  useEffect(() => {
    const timeAdjustedData = prepRawCSV(graphData);
    setRawData(timeAdjustedData);
    setSummaryData(prepSummaryCSV(graphData, singleUser));
  }, [graphData]);

  useEffect(() => {
    const userIds = users.filter(user => !user.email).map(user => user.id);
    getCompleteUsers({ token, userIds }, completedUsers => {
      const finalUserData = users.map(user => {
        const completedUser = completedUsers.find(u => user.id === u.id);
        if (completedUser) {
          return {
            ...user,
            ...completedUser,
          }
        } else {
          return user;
        }
      });

      // TODO: maybe push back up into userData (phase 2); this logic is duplicated elsewhere
      setUserData(prepUserCSV(finalUserData));
    });
  }, [token, users]);

  const chartData = () => {
    // const ctx = canvas.getContext('2d');
    // const graphGradient = ctx.createLinearGradient(0, 0, 0, 500);
    // graphGradient.addColorStop(0, 'rgba(58, 116, 224, 0.7)');
    // graphGradient.addColorStop(0.8, 'rgba(58, 116, 224, 0.07)');
    const datasets = [];
    datasets.push({
      borderColor: '#3a74e0',
      pointBorderColor: 'rgba(255, 255, 255, 1)',
      pointBackgroundColor: 'rgba(0, 0, 0, 1)',
      pointRadius: 0,
      data: graphData,
      lineTension: 0,
      // backgroundColor: graphGradient,
      borderWidth: 5,
    });

    console.log(datasets)

    return { datasets };
  };

  const sameDay = dateRange.length && new Date(dateRange[0]).getDay() === new Date(dateRange[1]).getDay();

  // X Axes differs between historical and average


  const options = {
    type: 'stacked',
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      intersect: false,
      displayColors: false,
      callbacks: {
        label(tooltipItem, data) {
          let xLabel = '';
          if (tooltipItem) {
            xLabel = tooltipItem.xLabel;
          }

          const timestamp = localizeMe(xLabel, singleUser);
          return timestamp;
        },
        title(tooltipItem, data) {
          let yLabel = 0;
          if (tooltipItem[0]) {
            yLabel = tooltipItem[0].yLabel;
          }

          return `${numberWithCommas(yLabel)} ACCV`;
        },
      },
    },
    scales: {
      y: {
        position: 'right',
        ticks: {
          fontColor: 'rgba(255, 255, 255, 0.4)',
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 30,
        },
        gridLines: {
          drawTicks: true,
          display: true,
          drawBorder: false,
          color: 'rgba(255, 255, 255, 0.1)',
          zeroLineColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
      x:  {
        type: 'time',
        distribution: 'linear',
        time: {
          unit: sameDay && singleUser ? 'hour': 'day',
          unitStepSize: 1,
          displayFormats: {
            day: 'MMM DD',
          },
        },
        gridLines: {
          drawTicks: false,
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 30,
          fontColor: 'white',
        },
        pointLabels: {
          fontColor: 'white',
        },
      },
    },
  };

  const finalData = chartData();

  return (
    <div className="insights-graph">
      <section className="chart">
        <Bar
          data={finalData}
          options={options}
        />
      </section>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin:'10px 0px'
        }}>
        <CSVLink
          data={summaryData.data}
          filename={summaryCSVTitle}
          headers={summaryData.headers}>
          <Tooltip
            placement="top"
            title="Stream and User Statistics">
            <div>Stream Summary <i className="fa fa-download" /></div>
          </Tooltip>
        </CSVLink>
        <CSVLink
          data={userData.data}
          filename={usersCSVTitle}
          headers={userData.headers}>
          <Tooltip
            placement="top"
            title="User Data">
            <div>User Data <i className="fa fa-download" /></div>
          </Tooltip>
        </CSVLink>
        <CSVLink
          data={rawData.data}
          filename={rawCSVTitle}
          headers={rawData.headers}>
          <Tooltip
            placement="top"
            title="Raw Data">
            <div>Raw Data <i className="fa fa-download" /></div>
          </Tooltip>
        </CSVLink>
      </div>
    </div>
  )
}

export default InsightsGraph;
