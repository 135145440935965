/* eslint-disable import/prefer-default-export */

// export const getLastAgreement = token => ({
//   type: 'GET_LAST_AGREEMENT',
//   payload: {
//     token,
//   },
// });
// export const deleteScheduledBroadcast = (scheduledBroadcastId, token) => ({
//   type: 'DELETE_SCHEDULED_BROADCAST',
//   payload: {
//     scheduledBroadcastId,
//     token,
//   },
// });
//
// export const scheduleABroadcast = (scheduledBroadcastInfo, agreementId, token) => ({
//   type: 'CREATE_SCHEDULED_BROADCAST',
//   payload: {
//     scheduledBroadcastInfo,
//     agreementId,
//     token,
//   },
// });

//  <----------- START NOIZ V2 ACTION FUNCTIONS ---------->


export const getInitialInfo = token => ({
  type: 'GET_INITIAL_INFO',
  payload: {
    token,
  },
});

export const getGameList = token => ({
  type: 'GET_GAME_LIST',
  payload: {
    token,
  },
});

export const getGame = (gameId, token) => ({
  type: 'GET_GAME',
  payload: {
    gameId,
    token,
  },
});

export const requestGameKey = (gameId, token) => ({
  type: 'REQUEST_GAME_KEY',
  payload: {
    gameId,
    token,
  },
});

export const joinToBounty = (bountyId, paymentEmail, token) => ({
  type: 'JOIN_TO_BOUNTY',
  payload: {
    bountyId,
    paymentEmail,
    token,
  },
});

export const leaveBounty = (bountyId, token) => ({
  type: 'LEAVE_BOUNTY',
  payload: {
    bountyId,
    token,
  },
});

//  <----------- END NOIZ V2 ACTION FUNCTIONS ---------->

//  <----------- START NOIZ V3 ACTION FUNCTIONS ---------->

export const getUserPayments = token => ({
  type: 'GET_USER_PAYMENTS',
  payload: {
    token,
  },
});

export const updatePaymentEmail = (paymentEmail, token) => ({
  type: 'UPDATE_PAYMENT_EMAIL',
  payload: {
    paymentEmail,
    token,
  },
});

export const getUserTodayActivity = token => ({
  type: 'GET_USER_TODAY_ACTIVITY',
  payload: {
    token,
  },
});

export const getUserReferralsActivity = token => ({
  type: 'GET_USER_REFERRALS_ACTIVITY',
  payload: {
    token,
  },
});

export const getUserTimedActivity = token => ({
  type: 'GET_USER_TIMED_ACTIVITY',
  payload: {
    token,
  },
});

export const submitLink = (link, questId, token) => ({
  type: 'SUBMIT_LINK',
  payload: {
    link,
    questId,
    token,
  },
});

export const submitAffiliateData = (data, stepType, stepTitle, questId, kind, token) => ({
  type: 'SUBMIT_AFFILIATE_DATA',
  payload: {
    data,
    stepType,
    stepTitle,
    questId,
    kind,
    token,
  },
});

export const submitRequirementData = (data, token) => ({
  type: 'SUBMIT_REQUIREMENT_DATA',
  payload: {
    data,
    token,
  },
});

export const checkRequirement = (reqId, checked, type, title, questId, approvalRequired, token) => ({
  type: 'CHECK_REQUIREMENT_DATA',
  payload: {
    reqId,
    checked,
    type,
    title,
    questId,
    approvalRequired,
    token,
  },
});

export const getMyQuestsPageData = token => ({
  type: 'GET_MY_QUESTS_PAGE_DATA',
  payload: {
    token,
  },
});


export const getUserTickets = token => ({
  type: 'GET_USER_TICKETS',
  payload: {
    token,
  },
});

export const verifyYoutubeChannelStreamStatus = (questId, token) => ({
  type: 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS',
  payload: {
    questId,
    token,
  },
});

export const requestUpdateAvatarUrl = token => ({
  type: 'REQUEST_UPDATE_AVATAR_URL',
  payload: {
    token,
  },
});

export const getBroadcast = (broadcast, token) => ({
  type: 'GET_BROADCAST',
  payload: {
    token,
    broadcast,
  },
});


//  <----------- END NOIZ V2 ACTION FUNCTIONS ---------->
