import React from 'react';
import { omit } from 'lodash';
import Notifications from 'react-notification-system-redux';
import store from '../store';
import './notifications.scss';


export const confirmationBox = (options, callback) => {
  // Remove all notifications
  store.dispatch(Notifications.removeAll());
  const notificationOpts = {
    // uid: 'once-please', // you can specify your own uid if required
    title: 'Registrate para guardar',
    message: 'Es necesario que tengas una cuenta en subelotu.com para agregarlo a favoritos.',
    position: 'br',
    autoDismiss: 0,
    children: (
      <div className="Notification__actions">
        <button
          style={{ background: options.cancelButtonBg || 'inherit' }}
          className="Notification__actions--cancel"
          onClick={() => { callback(false); }}
        >
          {options.cancelButtonText || 'cancelar'}
        </button>
        <button
          style={{ background: options.confirmButtonBg || 'inherit' }}
          className="Notification__actions--confirm"
          onClick={() => { callback(true); }}
        >
          {options.confirmButtonText || 'confirmar'}
        </button>
      </div>
    ),
    ...omit(options, ['cancelButtonText', 'confirmButtonText', 'level']),
  };
  store.dispatch(Notifications.show(notificationOpts, options.level));
};

export const simpleNotification = (options) => {
  // Remove all notifications
  store.dispatch(Notifications.removeAll());
  const notificationOpts = {
    // uid: 'once-please', // you can specify your own uid if required
    title: 'No title',
    message: 'No Message',
    position: 'br',
    autoDismiss: 5,
    ...omit(options, ['level']),
  };
  store.dispatch(Notifications.show(notificationOpts, options.level));
};
