import React from 'react';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(...registerables, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, zoomPlugin);

// defaults.global.elements.point.pointStyle = 'rect';

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // UNSAFE_componentWillMount() {
  //   Chart.pluginService.register({
  //     beforeDraw: (chartInstance) => {
  //       const { ctx } = chartInstance.chart;
  //       ctx.fillStyle = 'rgba(0,0,0,0)';
  //       ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);

  //       const { chartArea } = chartInstance;

  //       const width = (chartArea.right - chartArea.left) + 45;
  //       const height = (chartArea.bottom - chartArea.top) + 20;
  //       const startPoint = chartArea.left - 20;

  //       ctx.save();
  //       ctx.fillStyle = 'rgba(255, 255, 255, 0.05)';
  //       ctx.fillRect(startPoint, chartArea.top - 5, width, height);
  //       ctx.restore();
  //     },
  //   });
  // }

  render() {
    const { data, labelY } = this.props;
    console.log('up in this');
    const chartData = () => {
      // console.log(canvas, 'caanvas')
      // const ctx = canvas.getContext('2d');

      // const gradientFill = ctx.createLinearGradient(900, 0, 100, 0);
      // gradientFill.addColorStop(0, 'rgba(128, 182, 244, 1)');
      // gradientFill.addColorStop(1, 'rgba(244, 144, 128, 1)');

      return {
        datasets: [{
          label: labelY,
          backgroundColor:  'rgba(128, 182, 244, 1)',
          borderWidth: 1,
          tension: 0,
          data,
        }],
      };
    };

    const finalData = chartData();

    return (
      <section className="Chart">
        <Bar
          data={finalData}
          type="stacked"
          options={{
            scaleLineColor: 'rgba(0,0,0,0)',
            mantainAspectRatio: false,
            layout: {
              padding: {
                top: 20,
                bottom: 20,
              },
            },
            tooltips: {
              intersect: false,
              callbacks: {
              },
            },
            legend: {
                display: false,
                position: 'bottom',
                fontColor: 'white',
            },
            scales: {
                y: {
                    scaleLabel: {
                      display: true,
                      labelString: labelY,
                      fontColor: 'white',
                      maxTicksLimit: 5,
                      padding: 0,
                    },
                    ticks: {
                        fontColor: 'white',
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        padding: 30,
                    },
                    gridLines: {
                        drawTicks: false,
                        display: false,
                        drawBorder: false,
                    },
                },
                x: {
                    type: 'time',
                    distribution: 'linear',
                    time: {
                      unit: 'day',
                    },
                    barThickness: 30,
                    offset: true,
                    gridLines: {
                        drawTicks: false,
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        padding: 30,
                        fontColor: 'white',
                    },
                    pointLabels: {
                      fontColor: 'white',
                    },
                },
            },
          }}
        />
      </section>
    );
  }
}

export default BarChart;
