const home = (state = {}, action) => {
  switch (action.type) {
    case 'GET_OPTIMIZED_ALL_QUESTS_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          all: {
            ...state.quests.all,
            isLoading: true,
            errors: {
              ...state.quests.all.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_OPTIMIZED_ALL_QUESTS_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          all: {
            ...state.quests.all,
            isLoading: false,
            errors: {
              ...state.quests.all.errors,
              hasError: action.payload.error,
              code: action.payload.code,
              message: action.payload.message,
              all: action.payload.errors,
            },
          },
        },
      };

    case 'GET_OPTIMIZED_ALL_QUESTS_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          all: {
            ...state.quests.all,
            isLoading: false,
            errors: {
              ...state.quests.all.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_OPTIMIZED_ALL_QUESTS_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          all: {
            ...state.quests.all,
            isLoading: false,
            data: action.payload.response.data,
            total: action.payload.response.total,
            page: action.payload.response.page,
            errors: {
              ...state.quests.all.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_OPTIMIZED_ENDED_QUESTS_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          ended: {
            ...state.quests.ended,
            isLoading: true,
            errors: {
              ...state.quests.ended.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_OPTIMIZED_ENDED_QUESTS_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          ended: {
            ...state.quests.ended,
            isLoading: false,
            errors: {
              ...state.quests.ended.errors,
              hasError: action.payload.error,
              code: action.payload.code,
              message: action.payload.message,
              all: action.payload.errors,
            },
          },
        },
      };

    case 'GET_OPTIMIZED_ENDED_QUESTS_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          ended: {
            ...state.quests.ended,
            isLoading: false,
            errors: {
              ...state.quests.ended.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_OPTIMIZED_ENDED_QUESTS_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          ended: {
            ...state.quests.ended,
            isLoading: false,
            data: action.payload.response.data,
            total: action.payload.response.total,
            page: action.payload.response.page,
            errors: {
              ...state.quests.ended.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    default:
      return state;
  }
};

export default home;
