
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */

import React from 'react';
import { Link } from 'react-router';
import { isEmpty, find, map } from 'lodash';
import classNames from 'classnames';
import Swiper from 'react-id-swiper';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment-timezone';
import 'react-id-swiper/src/styles/css/swiper.css';
import { rounding } from '../../../utils/functions';
import { simpleNotification } from '../../../utils/notifications';
import './StorefrontGame.scss';

class StorefrontGame extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.thumbSwiper = null;
    this.masterSwiper = null;
    this.agreeBounty = null;
    this.agreeKey = null;
    this.state = {
      activeGalleryIndex: 0,
    };
  }

  UNSAFE_componentWillMount() {
    const { gameSlug } = this.props.params;
    this.props.getSFGameBySlug(gameSlug);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config.sfgame.isLoading === true && nextProps.config.sfgame.isLoading === false) {
      if (nextProps.config.sfgame.errors.hasError) {
        this.props.router.replace('/not-found');
      }
      const { questSlug, username } = this.props.params;
      if (!questSlug || !username) {
        return;
      }
      const referrals = localStorage.getItem('referrals');
      let referralValues = '';
      if (!referrals) {
        referralValues =
          JSON.stringify([
            {
              game: nextProps.config.sfgame.data.id,
              quest: questSlug,
              user: username,
            },
          ]);
      } else {
        let referralsObj = JSON.parse(referrals);
        const obj = find(referralsObj, { game: nextProps.config.sfgame.data.id });
        if (!obj) {
          // If doesn't exist (referrals not empty)
          referralsObj.push({
            game: nextProps.config.sfgame.data.id,
            quest: questSlug,
            user: username,
          });
        } else {
          // If already exists (referrals not empty)
          referralsObj = map(referralsObj, a => ((a.game === nextProps.config.sfgame.data.id && questSlug && username) ? {
            game: nextProps.config.sfgame.data.id,
            quest: questSlug,
            user: username,
          } : a));
        }
        referralValues = JSON.stringify(referralsObj);
      }
      if (referralValues !== '') {
        localStorage.setItem('referrals', referralValues);
      }
    }
  }

  componentWillUnmount() {}

  goToSlide = (index) => {
    this.setState({
      activeGalleryIndex: index,
    });
  }

  generateThumbnails() {
    return this.props.config.sfgame.data.gallery.map((item, i) => {
      switch (item.type) {
        case 'video':
          return (
            <button
              key={`thumb-slide-${i}`}
              onKeyPress={this.goToSlide.bind(this, i)}
              onClick={this.goToSlide.bind(this, i)}
              className={classNames('thumb-slide', { active: (this.state.activeGalleryIndex === i) })}
            >
              <div className="active-indicator" />
              <div className="thumb-overlay swiper-lazy" style={{ backgroundImage: `url('${item.thumb}')` }} >
                <div className="play-icon">
                  <i className="play-icon fa fa-play" />
                </div>
              </div>
            </button>);
        case 'image':
          return (
            <button
              key={`thumb-slide-${i}`}
              onKeyPress={this.goToSlide.bind(this, i)}
              onClick={this.goToSlide.bind(this, i)}
              className={classNames('thumb-slide', { active: (this.state.activeGalleryIndex === i) })}
            >
              <div className="thumb-overlay swiper-lazy" style={{ backgroundImage: `url('${item.thumb}')` }} />
            </button>);
        default:
          return (
            <button
              key={`thumb-slide-${i}`}
              onKeyPress={this.goToSlide.bind(this, i)}
              onClick={this.goToSlide.bind(this, i)}
              className={classNames('thumb-slide', { active: (this.state.activeGalleryIndex === i) })}
            >
              <div className="active-indicator" />
              <div className="thumb-overlay swiper-lazy" style={{ backgroundImage: `url('${item.thumb}')` }} />
            </button>);
      }
    });
  }

  soldOutMessage = () => {
    simpleNotification({
      level: 'warning',
      title: 'Sold Out.',
      message: 'Sorry, but this item is currently sold out. Please check back later.',
    });
  }

  roundingToString = number => parseFloat(number).toFixed(2);

  render() {
    const gameGalleryParams = {
      direction: 'horizontal',
      spaceBetween: 2,
      slidesPerView: 'auto',
      centeredSlides: false,
      speed: 500,
      loop: false,
      scrollBar: false,
      containerClass: 'SFGameGalleryThumb',
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
      },
      freeMode: true,
      freeModeMomentum: true,
      mousewheel: true,
    };

    const galleryParams = {
      spaceBetween: 27,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      speed: 500,
      loop: false,
      direction: 'horizontal',
      scrollBar: false,
      containerClass: 'SFGameGalleryT',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    };

    const { data: game, isLoading: isLoadingGame } = this.props.config.sfgame;
    const { questSlug, username } = this.props.params;

    let checkoutUrl;
    if (questSlug && username) {
      checkoutUrl = `/buy/${game.slug}/${questSlug}/${username}/checkout`;
    } else {
      checkoutUrl = `/buy/${game.slug}/checkout`;
    }

    let buttonPrice = `Buy now for $${rounding(game.price)}`;

    if (game.salePrice && game.saleEndDate) {
      const today = moment().tz('America/Los_Angeles');
      const hoursOffset = today.utcOffset() / 60;
      const saleDate = new Date(game.saleEndDate);
      const now = moment(new Date()).add(hoursOffset, 'hours').toDate();
      if (saleDate > now) {
        buttonPrice = `Buy now for $${rounding(game.salePrice)}`;
      }
    }

    return (
      <section className="SFGame">
        {(game && !isLoadingGame) && (
          <section className="SFGame__background">
            <figure style={{ backgroundImage: `url(${game.cover})` }} />
          </section>
        )}
        <section className="SFGame__container">
          <section className="SFGame__primary">
            <div className="SFGame__primary--wrapper">
              <div className="PrimaryGameMedia">
                <section className="SFGameGallery2">
                  { (!isEmpty(game) && !isLoadingGame && game.gallery.length > 0) && (
                    <Swiper {...galleryParams} >
                      {this.generateThumbnails()}
                    </Swiper>
                    )}
                </section>
                <section className="SFGameGallery">
                  <section className="SFGameGallery__wrapper">
                    {(!isEmpty(game) && !isLoadingGame && game.gallery.length > 0) && (
                      <section
                        className="SFGameGallery__current-image"
                        style={{ backgroundImage: `url('${game.gallery[this.state.activeGalleryIndex].thumb}')` }}
                      />
                    )}
                    { (!isEmpty(game) && !isLoadingGame && game.gallery.length > 0) && (
                      <Swiper {...gameGalleryParams} >
                        {this.generateThumbnails()}
                      </Swiper>
                    )}
                  </section>
                </section>
                <section className="SFGameInfo">
                  <section className="SFGameInfo__details">
                    <div className="SFGame__right__header">
                      <div className="SFGame__right__developerlogo">
                        <figure style={{ backgroundImage: `url(${game.developerLogo})` }} alt="Developer Logo" />
                      </div>
                      <div className="SFGame__right__row__center">
                        <div className="SFGame__right__developer">
                          {game.developer}
                        </div>
                        <div className="SFGame__right__name">
                          {game.name}
                        </div>
                      </div>
                      {/* <section className="SFGame__right__row__right">
                        {
                          (game.socialNetworks && game.socialNetworks.length > 0) && game.socialNetworks.map((socialNetwork, i) => {
                            let socialIcon = socialNetwork.name;
                            if (socialIcon === 'youtube') {
                              socialIcon = 'youtube-play';
                            }
                            return (
                              <a href={`${socialNetwork.url}`} target="_blank" rel="noopener noreferrer">
                                <i className={`fa fa-${socialIcon}`} />
                              </a>
                            );
                          })
                        }
                      </section> */}
                    </div>
                    <section className="SFGame__socialNetwork">
                      {
                        (game.socialNetworks && game.socialNetworks.length > 0) && game.socialNetworks.map((socialNetwork, i) => {
                          let socialIcon = socialNetwork.name;
                          if (socialIcon === 'youtube') {
                            socialIcon = 'youtube-play';
                          }
                          return (
                            <a href={`${socialNetwork.url}`} target="_blank" rel="noopener noreferrer">
                              <i className={`fa fa-${socialIcon}`} />
                            </a>
                          );
                        })
                      }
                    </section>
                    <section className="SFGameInfo__details--bottom">
                      <p className="SFGameInfo__details--description">
                        { (game.saleDescription) && <React.Fragment>{ReactHtmlParser(game.saleDescription)}</React.Fragment> }
                        { (!game.saleDescription) && <React.Fragment>{ReactHtmlParser(game.description)}</React.Fragment> }
                      </p>
                      <section className="SFGameInfo__tags">
                        <div>Developer: <span className="tag">{ game.developer }</span></div>
                        { (game.publisher) &&
                            (<div>Publisher: <span className="tag">{ game.publisher }</span></div>)
                        }
                        <div>Release Date: <span className="tag">{ moment(game.releaseDate, 'YYYY-MM-DD').format('MMMM DD, YYYY') }</span></div>
                      </section>
                      <section className="SFGameInfo__ctas">
                        { (game.keysAvailables <= 0) &&
                          (
                          <button onClick={this.soldOutMessage.bind(this)} className="SFGameInfo__ctas--pre-order disabled">
                            Sold Out
                          </button>
                          )
                        }
                        { (game.keysAvailables > 0) &&
                          <Link
                            className="SFGameInfo__ctas--pre-order"
                            to={checkoutUrl}
                            href={checkoutUrl}
                          >
                            {buttonPrice}
                          </Link>
                        }
                        { (game.keysAvailables <= 0) &&
                        <div className="SFGameInfo__ctas--message">
                          { (game.soldOutMessage) && <React.Fragment>{ReactHtmlParser(game.soldOutMessage)}</React.Fragment> }
                          { (!game.soldOutMessage) && <React.Fragment>Sorry, this game has sold out. Please check back soon.</React.Fragment> }
                        </div>
                        }
                        { (game.keysAvailables > 0) &&
                          <div className="SFGameInfo__ctas--message">
                            You will receive game key after purchase
                          </div>
                        }
                      </section>
                    </section>
                  </section>
                </section>
              </div>
            </div>
          </section>
          <section className="SFGame__secondary">
            <div className="SFGame__secondary--wrapper">
              <React.Fragment>{ReactHtmlParser(game.actionMessage)}</React.Fragment>
              { (game.actionButtonLink && game.actionButtonText) &&
                <div>
                  <Link
                    className="SFGameInfo__ctas--pre-order SFGameInfo__ctas--pre-order2"
                    to={game.actionButtonLink}
                    href={game.actionButtonLink}
                  >
                    {game.actionButtonText}
                  </Link>
                </div>
              }
            </div>
          </section>
        </section>
        {(this.props.children && this.props.children.props.children) ?
          React.cloneElement(this.props.children.props.children, this.props)
          : '' }
      </section>);
  }
}

StorefrontGame.propTypes = {
};

export default StorefrontGame;
