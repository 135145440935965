import React from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

import Icon from '../../../../global/images/icons/Icon';

import './CustomInputs.scss';

const CustomModal = (props) => {
  const {
    open,
    clickOutsideToClose = false,
    content = null,
    onClose,
  } = props;

  return (
    <Modal
      open={open}
      {...clickOutsideToClose && { onClose: onClose }}
    >
      <Box className='CustomModal'>
        <div className='CustomModal__Content'>
          <div className='CustomModal__Close' onClick={onClose}>
            <Icon name='xmark' color='black' />
          </div>
          {content}
        </div>
      </Box>
    </Modal>
  );
};

export default CustomModal;
