import React from "react";

const styles = {
  background: 'grey',
  color: 'white',
  padding: '10px 25px',
  margin: '20px 0px',
  borderRadius: '10px'
}

const Instructions = () => (
  <section style={styles}>
    <p>
      Add a user to the Noiz streamer database by putting the relevant
      information in below.
    </p>
    <p>
      Once added, the user will be editable in the <strong>User List </strong>{" "}
      tab of the <strong>Users section </strong>of the{" "}
      <strong> Command Center. </strong>
    </p>
    <p>
      You can add an off platform user to a quest like you would any other user
      from the <strong> User List. </strong>
    </p>
    <p>
      Once you do they will receive an email at the main email you provided to
      "sign" the Quest Acceptance Agreement.
    </p>
    <p>
      This will always be {" "}
      <strong>https://noiz.gg/quests/(QUEST_SLUG)/(USER_ID)</strong>.
    </p>
    <p>
      {" "}
      Once this has been executed, they will be automatically added to the
      quest. If you like, you can also push their application through from the
      Preapproval Menu.
    </p>
    <p>
    <strong>  Note: A user's userId can be found via the User's List</strong>.  You may need to reconstruct a URL manually
    </p>
  </section>
);

export default Instructions;
