import React from 'react';
import classnames from 'classnames';

import './CustomInputs.scss';

const CustomTextArea = (props) => {
  const {
    header,
    name,
    value,
    onChange,
    error,
  } = props;

  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  return (
    <div className="CustomInput">
      <div className="CustomInput__Header">{header}</div>
      <textarea
        className={classnames("CustomInput__TextArea InputClass", error ? 'Error' : '')}
        name={name}
        value={value}
        onChange={handleChange}
        type="text"
      />
    </div>
  );
}

export default CustomTextArea;
