export const assignEditUserInfo = fields => ({
  type: 'ASSIGN_EDIT_USER_INFO',
  payload: {
    fields,
  },
});

export const assignLoginUpdateForm = fields =>
  // console.log('ding', fields);
  ({
    type: 'ASSIGN_LOGIN_UPDATE_FORM',
    payload: {
      fields,
    },
  });
export const resetEditUserInfo = () => ({
  type: 'RESET_EDIT_USER_INFO',
});

export const assignQuestInfo = fields => ({
  type: 'ASSIGN_QUEST_INFO',
  payload: {
    fields,
  },
});

export const swapQuestStep = (kind, arr) => ({
  type: 'SWAP_QUEST_STEP',
  payload: {
    kind,
    arr,
  },
});

export const swapRequirements = arr => ({
  type: 'SWAP_REQUIREMENTS',
  payload: {
    arr,
  },
});

export const swapGameGallery = arr => ({
  type: 'SWAP_GAME_GALLERY',
  payload: {
    arr,
  },
});

export const titleSlugApply = str => ({
  type: 'TITLE_SLUG_APPLY',
  payload: {
    str,
  },
});

export const updateCCQuestForm = fields => ({
  type: 'UPDATE_CC_QUEST_FORM',
  payload: {
    fields,
  },
});

export const removeIcon = fields => ({
  type: 'REMOVE_ICON',
  payload: {
    fields,
  },
});

export const assignQuestSlugError = errorMessage => ({
  type: 'ASSIGN_QUEST_SLUG_ERROR',
  payload: {
    errorMessage,
  },
});

export const assignGameSlugError = errorMessage => ({
  type: 'ASSIGN_GAME_SLUG_ERROR',
  payload: {
    errorMessage,
  },
});

export const assignProfileInfoForm = fields => ({
  type: 'ASSIGN_PROFILE_INFO_FORM',
  payload: {
    fields,
  },
});

export const assignCampaignInfoForm = fields => ({
  type: 'ASSIGN_CAMPAIGN_INFO_FORM',
  payload: {
    fields,
  },
});

export const updateAdminGameForm = fields => ({
  type: 'UPDATED_ADMIN_GAME_FORM',
  payload: {
    fields,
  },
});

export const resetQuestInfo = () => ({
  type: 'RESET_QUEST_INFO',
});

export const assignQuestDefaultInfo = () => ({
  type: 'ASSIGN_QUEST_DEFAULT_INFO',
});

export const assignGameDefaultInfo = () => ({
  type: 'ASSIGN_GAME_DEFAULT_INFO',
});

export const assignCampaignDefaultInfo = () => ({
  type: 'ASSIGN_CAMPAIGN_DEFAULT_INFO',
});

export const assignGameInfo = fields => ({
  type: 'ASSIGN_GAME_INFO',
  payload: {
    fields,
  },
});

export const assignGameFeaturedInfo = fields => ({
  type: 'ASSIGN_GAME_FEATURED_INFO',
  payload: {
    fields,
  },
});
