import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './VideoWidget.scss';
import playButton from '../../global/images/icons/play_button.svg';
import { returnYoutubeId } from '../../utils/functions';
import { fade } from '../../utils/transitionIndex';
import YouTube from 'react-youtube';

const VideoWidget = (props) => {
  const {
    src = '',
    img = '',
  } = props;


  const videoId = returnYoutubeId(src);

  const [isHovered, setIsHovered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {

  }, []);

  const opts = {
    // height: "540px",
    // width: "100%",
    // height: "100%",
    // padding: "0",
    // margin: "0",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const imageAndNoVideo = img && !src;
  const elementTransition = fade(1000, 1000, false, true, false);

  const toShow = imageAndNoVideo ? false : isPlaying || isHovered;
  return (
    <>
      <TransitionGroup
        className="video-widget-container"
        onMouseEnter={() => setIsHovered(true)}
        onFocus={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onBlur={() => setIsHovered(false)}
      >
        {toShow && (
          <CSSTransition {...elementTransition} className="video-widget-player">
            <YouTube
              className="video-widget-iframe"
              opts={opts || {}}
              videoId={`${videoId || ''}`}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onEnd={() => setIsPlaying(false)}
            />
          </CSSTransition>
        )}
        {!toShow && (
          <CSSTransition {...elementTransition} className="video-widget-image" >
            <div className="video-widget-image" >
              <div className="preview-image">
                <img className="img-man" src={img} alt="preview video" />
              </div>
              {!imageAndNoVideo && <div className="after-video-image">
                <img src={playButton} alt="play button" />
              </div>}
            </div>

          </CSSTransition>
        )}

      </TransitionGroup>
    </>
  );
};

export default VideoWidget;

// <iframe
// id="questPageIframe"
// height={540}
// width="100%"
// autoplay="off"
// src={src}
// type="video/mp4"
// title="Video Preview Iframe"
// onstatechange={(arg)=>console.log(arg, 'statechange')}
// />
