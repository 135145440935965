/* eslint-disable */
import React from 'react';
// import PropTypes from 'prop-types'
import './static.css';

class DnlKeyQuestTermsConditions extends React.Component {
  render() {
    return (
      <section className="PrivacyPolicy static">
        <h1>Dark and Light “Free Key Quest” - Terms and Conditions
        </h1>
        <h2>
NOIZ “The Company” deliverables: 
</h2>
<ul>
<li>NOIZ will provide a Steam game key via Noiz.gg platform after login and agreement to Terms of Use.
</li><li>At the end of agreement, we will request feedback and evaluate for ongoing interest.
</li>
</ul>
<h2>
STREAMER “Noiz User” deliverables:
</h2>
<ul>
<li>The streamer logged into “Noiz.gg” and collected the Game Key must stream for 1+ Hour within 3 days of receiving Game Key from Noiz.gg.
</li></ul>
<h2>
IMPORTANT NOTICES: 
</h2>

<strong>Failure to Deliver:</strong> If the STREAMER does not complete the required quest deliverable: “Stream ‘Dark and Light’ for 1 hour on Twitch.tv within 3 days of receiving game key”, Noiz.gg has the right to revoke the game key.
<br />
<br />
<strong>Cancellation policy:</strong> Noiz.gg and our partnering clients reserve the right to cancel any quest or reward given if the User does not act within “Terms of Use” and/or attempts to sell the Game Key to an unintended user.

<p>Last Updated: 10/15/2018</p>
      </section>
    );
  }
}

export default DnlKeyQuestTermsConditions;
