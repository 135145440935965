import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, List, ListItem, ListItemText, Modal } from '@material-ui/core';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './GamesPlayedModal.scss';
import { fade } from '../../utils/transitionIndex';

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#18191d',
    // border: '2px solid #000',
    borderRadius: '5px',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
  },
}));

const GamesPlayedModal = (props) => {
  const { games } = props;

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [currentGame, setCurrentGame] = useState('');

  useEffect(() => {
    let i = 0;

    const intervalId = setInterval(() => {
      i += 1;
      if (i >= games.length) i = 0;
      setCurrentGame('');

      setCurrentGame(games[i]);
    }, 2500);

    return () => clearInterval(intervalId);
  }, []);

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const gamesHtml = games.map(g => (
    <ListItem key={g} id={g}>
      <ListItemText primary={g} />
    </ListItem>
  ));

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <List style={{ maxHeight: 200, overflow: 'auto' }}>{gamesHtml}</List>
      <Button
        className="GamesPlayedModal_close-button"
        color="primary"
        onClick={handleClose}
      >
        Close
      </Button>
    </div>
  );

  return (
    <>
      <div className="play-games-flex">Play Any of the Following Games</div>
      <TransitionGroup>
        {currentGame && (
          <CSSTransition className="fading-game-label"{...fade(300, 300)}>
            <div >{currentGame}</div>
          </CSSTransition>
        )}

      </TransitionGroup>
      <button
        className="GamesPlayedModal_button make-visible"
        type="button"
        onClick={handleOpen}
      >
        Click here for the full list of games.
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
};

export default GamesPlayedModal;
