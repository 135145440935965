import React from 'react';
import { Link } from 'react-router';
import './Back.scss';

const Back = () => (
  <Link
    className="back"
    to="/quests"
  >
    ← Back to browse
  </Link>
);

export default Back;
