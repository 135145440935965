import React from 'react';
import {
  Paper,
  Modal,
  Backdrop,
  Fade,
  Button,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { simpleNotification } from '../../../../utils/notifications';
import './QuestPreapproval.scss';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  paper: {
    backgroundColor: '#212227',
    boxShadow: theme.shadows[5],
    color: 'white',
    width: '480px',
    maxWidth: '90%',
  },
  topMast: {
    backgroundColor: '#313337',
    padding: '20px 40px',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: '40px 40px',
  },
  textField: {
    boxShadow: 'none !important',
    '-webkit-appearance': 'none',
  },
  errorButton: {},
  confirmButton: {
    backgroundColor: 'green',
  },
  buttonGrid: {
    width: '100%',
    marginTop: '30px',
  },
  inputBox: {
    lineHeight: 2,
  },
  warning: {
    color: 'red',
    marginTop: '20px',
  },
  openButton: {
    color: 'white',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    padding: '5px 10px',
  },
}));

export default function ApprovalModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const {
    checkmark,
    approve,
    styling,
    selected,
  } = props;

  const handleOpen = () => {
    if (selected && selected.length <= 0) {
      simpleNotification({
        level: 'warning',
        title: '',
        message: 'Please select at least one item.',
      });
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const btn = checkmark ? (
    <Tooltip title="Approve" placement="top">
      <button type="button" className="small-btn" onClick={handleOpen}>
        <i style={styling} className="fa fa-check" />
      </button>
    </Tooltip>
  ) : (
    <button onClick={handleOpen} className="Modal__download--cta" style={{ margin: '0px 5px' }}>
      Approve {selected ? selected.length : 0} Users
    </button>
  );

  return (
    <div className="ApprovalModal">
      { btn }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid
              xs={12}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid container xs={12} justify="space-around" align="flex-start" direction="column" className={classes.mainContainer}>
                <Grid className="CustomLink--input">
                  There are not enough unassigned keys left for the {selected && selected.length > 1 ? selected.length : 1} user{selected && selected.length > 1 ? 's' : ''} you are approving.
                  Are you sure you want to approve {selected && selected.length > 1 ? 'these users' : 'this user'}?
                </Grid>
                <Grid
                  container
                  className={classes.buttonGrid}
                  xs={12}
                  direction="row"
                  width="100%"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        approve(selected);
                        handleClose();
                      }}
                    >
                      Approve
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      onClick={handleClose}
                      className={classes.errorButton}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
