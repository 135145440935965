import React from 'react';

// import Icon from '../../global/images/icons/Icon';
import QuestItem from './QuestItem';

import './GameQuestList.scss';

function GameQuestList({ data, user, userQuests }) {
  const {
    cover = '',
    isBrand = false,
    logo = '',
    // owner = {},
    name = '',
    quests = [],
  } = data;

  return (
    <div className={`GQL__Wrapper`}>
    {/* <div className={`GQL__Wrapper ${isBrand ? 'BrandGame' : 'NoizGame'}`}> */}
      <div className='game-quest-list'>
        <div className="hero">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${cover})`,
            }}
          />
          <div className="bg gradient" />
          {/* <div className='ManagedBy'>
            <Icon name='handshield' color='black' />
            <div className='ManagedBy__Label'>Managed By</div>
            <div className={`ManagedBy__Logo__BG ${isBrand ? 'Brand' : 'Noiz'}`}>
              {isBrand ? (
                <img className='ManagedBy__Logo__IMG IMG' src={owner?.avatar} alt={owner?.displayName || owner?.username} />
              ) : (
                <>
                  <img className='ManagedBy__Logo__IMG NOIZ' src={'https://cdn.zeplin.io/61b39e894d20733a8bc58e68/assets/25ACB0C8-D38D-4BF8-A0CC-CCAD10DE44AF.png'} alt='Noiz' />
                  <Icon name='noizlogo' />
                </>
              )
              }
            </div>
          </div> */}
          <div className="icon-container">
            <div
              className="icon"
              style={{
                backgroundImage: `url(${logo})`,
              }}
            />
          </div>
          <span className="title">
            {name.toUpperCase()}
          </span>
        </div>
        <div className="quest-list">
          {quests.map(quest => (
            <QuestItem
              key={quest.id}
              isBrand={isBrand}
              quest={quest}
              user={user}
              userQuest={userQuests.find(uq => uq.quest === quest.id) || {}}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GameQuestList;
