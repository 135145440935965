/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
// import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';

import AdminTabs from '../../util/tabs/AdminTabs';

import './GameList.scss';

class GameList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // numPage: 1,
      // filter: 0,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    if (!this.props.admin.games.isLoading) {
      this.props.getAGameList(token);
    }
  }

  // paginationRight = () => {
  //   const { token } = this.props.auth;
  //   if (!this.props.admin.games.isLoading) {
  //     this.props.getAGameList(token);
  //   }
  //   this.setState({
  //     numPage: this.state.numPage + 1,
  //   });
  // }

  // paginationLeft = () => {
  //   const { token } = this.props.auth;
  //   if (this.state.numPage === 1) {
  //     return;
  //   }
  //   if (!this.props.admin.games.isLoading) {
  //     this.props.getAGameList(token);
  //   }
  //   this.setState({
  //     numPage: this.state.numPage - 1,
  //   });
  // }

  generateRowsHTML = (games = []) => {
    if (games.length > 0) {
      return games.map(game => (
        <div className="row" key={games.id}>
          <div className="table-cell">
            {game.owner && (
              <>
                <Tooltip title='Brand Game' placement='top'>
                  <i className='fa fa-black-tie' />
                </Tooltip>
                &nbsp;
              </>
            )}
            {game.name}
          </div>
          <div className="table-cell">{game.status}</div>
          <div className="table-cell">
            <Link
              to={`/admin/games/edit/${game.id}`}
              activeClassName="active"
              href={`/admin/games/edit/${game.id}`}
            >
              <button className="remove-key">EDIT</button>
            </Link>
          </div>
        </div>
      ));
    }
    return (<div className="NoGames">No Games Found</div>);
  }

  render() {
    const { data: games, pagination = {} } = this.props.admin.games;
    // const leftDisabled = pagination.pageNumber === '1';
    // const rightDisabled = pagination.pageNumber >= pagination.totalPages;

    // const disabledStyling = {
    //   cursor: 'not-allowed',
    //   opacity: '0.2',
    // };

    return (
      <section className="AGameList">
        <section className="AGameList__wrapper">
          <AdminTabs {...this.props} />
          <h2 className="AGameList__title">GAMES</h2>
          <div className="AGameList__actions">
            <Link
              to="/admin/games/create"
              activeClassName="active"
              href="/admin/game/create"
            >
              <button>Create Game</button>
            </Link>
            <Link
              to="/admin/games/featured"
              activeClassName="active"
              href="/admin/game/featured"
            >
              <button>Featured</button>
            </Link>
          </div>
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <div className="ToggleTable__header__left">
                GAMES: {pagination.totalGames}
                {/* <div>
                  <React.Fragment>
                    {pagination.totalGames} (Page {pagination.pageNumber} of {pagination.totalPages})
                  </React.Fragment>
                </div> */}
              </div>
              {/* <div className="ToggleTable__header__right">
                <button
                  disabled={leftDisabled}
                  onClick={this.paginationLeft.bind(this)}
                  style={leftDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-left" />
                </button>
                <button
                  disabled={rightDisabled}
                  onClick={this.paginationRight.bind(this)}
                  style={rightDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-right" />
                </button>
              </div> */}
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">Name</div>
                  <div className="table-cell headline">Status</div>
                  <div className="table-cell headline">Actions</div>
                </div>
                {this.generateRowsHTML(games)}
              </section>
            </section>
            {/* <section className="ToggleTable__footer">
              <section className="ToggleTable__footer__left">
                <button
                  disabled={leftDisabled}
                  onClick={this.paginationLeft.bind(this)}
                  style={leftDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-left" />
                </button>
              </section>
              <section className="ToggleTable__footer__right">
                <button
                  disabled={rightDisabled}
                  onClick={this.paginationRight.bind(this)}
                  style={rightDisabled ? disabledStyling : {}}
                >
                  <i className="fa fa-caret-right" />
                </button>
              </section>
            </section> */}
          </section>
        </section>
      </section>
    );
  }
}

GameList.propTypes = {

};

export default GameList;
