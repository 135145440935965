import React, { useEffect, useState } from 'react';
import defaultVideo from '../../assetsV2/loserfruit.mp4';


import anime from 'animejs';


const FloatBy = (props) => {
  const {
    isMobile = false, video = defaultVideo, cssClass = 'default-float-by', cssStyle = {}, startTop = '0%', zLevel = 0,
    wallLength = 0,
  } = props;

  const baseTime = isMobile ? 35000 : 30000;
  // const [triggerLoop, setTriggerLoop] = useState(false);

  const [width, setWidth] = useState(0);

  const [debounce, setDebounce] = useState(false);
  const thisElement = document.querySelectorAll(`.${cssClass}`);
  const endDistanceLeft = isMobile ? '-45%' : '-35%';
  const even = zLevel % 2 === 0;
  const bigGuy = even ? '30%' : '25%';
  const littleGuy = even ? '45%' : '43%';
  const maxWidthBig = even ? '300px' : '200px';
  const maxWidthSmall = even ? '200px' : '100px';
  const vidWidth = isMobile ? littleGuy : bigGuy;
  const maxWidth = isMobile ? maxWidthSmall : maxWidthBig;
  const halfLength = wallLength / 2;

  let a1;
  let a2;


  useEffect(() => {
  // refs are messy for what I want, using straight dom manipulation;

    if (thisElement.length && !width) {
      const el = thisElement[0];

      const newWidth = el.getBoundingClientRect().width;
      setWidth(newWidth);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      a2 = anime({
        targets: `.${cssClass}`,
        opacity: [0, 1],
        duration: 8000,
        // autoplay: false
      });
      // const speed = 115 / baseTime;
      // const firstPassSameSpeed = Math.floor(speed * baseTime * startLeft);


      // anime({
      //   targets: `.${cssClass}`,
      //   left: [`${startLeft}%`, endDistanceLeft],
      //   easing: 'linear',
      //   duration: firstPassSameSpeed,
      //   // duration: 1000,
      //   update(anim) {
      //     if (anim.progress === 100) {
      //       setTriggerLoop(true);
      //     }
      //   },
      // });
    }
    const sizeStaggerMobile = even ? 30000 : 35000;
    const staggerTime = isMobile ? sizeStaggerMobile : 20000;
    const offsetTime = isMobile ? 20000 : 10000;

    const staggerDelay = zLevel >= halfLength ? ((zLevel - halfLength) * staggerTime) : (zLevel * staggerTime) + offsetTime;

    if (!debounce && thisElement.length) {
    // begin loop sequence
      thisElement[0].style.left = '100%';
      a1 = anime({
        targets: `.${cssClass}`,
        left: endDistanceLeft,
        easing: 'linear',
        loop: false,
        duration: baseTime,
        delay: staggerDelay,
        autoplay: false,

        update(anim) {
          if (anim.progress === 100) {
            thisElement[0].style.left = '100%';
            anime({
              targets: `.${cssClass}`,
              left: endDistanceLeft,
              easing: 'linear',
              loop: true,
              duration: baseTime,
              delay: isMobile ? 2500 : 0,
              update(anim) {
                if (anim.progress === 100) {
                  thisElement[0].style.left = '100%';
                }
              },

            });
          }
        },

      });

      a1.seek(a1.duration * 0.6);

      a1.play();
      a2.play();

      // anim.seek(50);
      // anim.seek(baseTime * 0.5);
      setDebounce(true);
    }
  });

  return (

    <video
      muted
      autoPlay="autoplay"
      loop
      playsInline
      plays-inline="true"
      preload="auto"
      type="video/mp4"
      width={vidWidth}
      style={{
 ...cssStyle, position: 'absolute', left: '0%', top: `${startTop}%`, opacity: 0, maxWidth, zIndex: zLevel,
             }}
      className={cssClass}
    >
      <source
        src={video}
        type="video/mp4"
        playsInline
        plays-inline="true"
        muted
        preload="auto"
        autoPlay="autoplay"
      />
      Your browser does not support the video tag.
    </video>


  );
};


export default FloatBy;
