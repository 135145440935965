export const test1 = 1;

export const test2 = 2;

export const arrayDropDownColors = {
  menu: () => ({
    backgroundColor: '#212227',
    // border: '3px solid #83858c',
    boxShadow: '7px 5px 5px #111117',
    borderRadius: '15px',
    marginTop: '3px',
  }),
  control: styles => (
    {
      ...styles,
      backgroundColor: '#202227',
      border: 'none',
      borderBottom: '2px solid #83858C',
      borderRadius: 'none',
      margin: '0 auto',
      color: 'white',
    }
  ),
  option: styles => (
    {
      ...styles,
      backgroundColor: '#202227',
      cursor: 'pointer',
    }
  ),
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#202227',
  }),
  singleValue: styles => ({
    ...styles,
    color: 'white',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: '#ececec',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: '#ececec',
    ':hover': {
      backgroundColor: '#202227',
      color: 'white',
      cursor: 'pointer',
    },
  }),
};
