const eula = `Disputes Between Sponsors and Creators

On the Site, users can take part in certain activities related to sponsors' engagement of influencers, streamers, and other content creators to create content (each, a "Quest"). Users may be the hiring party or the party performing services in each Quest. Notwithstanding the terms of the Section below entitled Limitation of Liability and any dispute resolution provisions in that Section, you agree that any Site disputes which are between (i) Quest sponsors ("Sponsors"), (ii) any of the influencers, streamers, content creators, or other parties who take part in the Quest ("Creators"), or any combination of the foregoing, must be brought before our internal dispute resolution process (the "Dispute Process").

That process consists of the following steps:

	•	When a Creator participating in a Quest submits all deliverables, and those deliverables are acknowledged by the Site's systems, a 30-day timer will start.
	•	If no dispute is raised within that 30-day period, we will automatically release payment to the Creator, or the Sponsor can release the payment early, if satisfied.
	•	If there is a dispute regarding the Quest, either the Sponsor or the Creator can request arbitration within the 30-day period. If arbitration is selected, a NOIZ staff member or other party designated by NOIZ to arbitrate disputes (in either case, the "Arbitrator") will review the facts presented by each side and make a final and binding decision, in their sole discretion, based on the information they deem relevant and appropriate under the circumstances.
	•	Either party to the dispute may appeal the decision within 30 days of notification of the Arbitrator's decision, only by providing new evidence and information not initially provided to the Arbitrator during the initial dispute resolution process.
	•	By agreeing to these Terms and participating in a Quest, each user agrees that they will be bound by the Arbitrator's decision in any Quest-related dispute, and that there is no appeals process or other remedy other than that outlined above in this Section.
`;

export default eula;
