import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';

import Icon from '../../../../../global/images/icons/Icon';
import Loading from '../../../../../global/components/loading/Loading';

import './DeleteModal.scss';

const DeleteModal = ({ deleteFn, loading, open, secondaryStatement, setOpen }) => {
  const handleClose = () => setOpen(false);

  const [checkmark, setCheckmark] = useState(false);
  const [secondaryStatementChecked, setSecondaryStatementChecked] = useState(false);

  return (
    <Modal
      open={open}
    // onClose={handleClose}
    >
      <Box className='DeleteModal__Box'>
        <div className='DeleteModal'>
          <div className='DeleteModal__Close' onClick={handleClose}>
            <Icon name='xmark' />
          </div>
          <div className='DeleteModal__Title'>Are you sure you want to delete this product?</div>
          <div className='DeleteModal__Content'>
            <p>
              By agreeing to delete this product, you will lose all data associated with it.
              {secondaryStatement && (
                <>
                  This product is currently running a quest with active streamers. <b style={{ color: '#e03959' }}><u>It will end immediately and you will forfeit your prepaid funding fee.</u></b>
                  <Checkbox checked={secondaryStatementChecked} onChange={() => setSecondaryStatementChecked(!secondaryStatementChecked)} />&nbsp; &nbsp;I understand I will forfeit my prepaid funding fee.
                </>
              )}
              <br /><br />
              This action <b style={{ color: '#e03959' }}><u>cannot</u></b> be undone.
            </p>
          </div>
          <div>
            <Checkbox checked={checkmark} onChange={() => setCheckmark(!checkmark)} />&nbsp;&nbsp;I understand the consequences of deleting this product.
          </div>
          {loading && (<div className='DeleteModal__Button Active' disabled>
            <Loading />
          </div>)}
          {!loading && (
            <div
              className={`DeleteModal__Button ${(checkmark && (
                secondaryStatementChecked || !secondaryStatement
              )) && 'Active'}`}
              disabled={!checkmark}
              onClick={(e) => {
                if (checkmark) deleteFn(e);
              }}
            >
              DELETE
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteModal;
