import React, { useEffect, useState } from "react";
import "./Payment.scss";
import { AddPayButton, PaymentOptionSaved } from "./AddPaymentOption";
import {
  getUserBrandPaymentMethods,
  deleteUserBrandPaymentMethod,
  getStreamerBankAccounts,
  deleteStreamerStripeAccount,
  getStripeCountries,
} from "../../../utils/apiv2";
import Loading from "../loading/Loading";

const Payment = ({ streamer = false }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const getPaymentMethods = async () => {
    if (streamer) {
      const {
        data: { data: accountInfo },
      } = (await getStreamerBankAccounts()) || { data: [] };
      const {
        data: { data: countries },
      } = (await getStripeCountries() ||  { data: [] });
      setCountries(countries);
      if (accountInfo) setPaymentMethods(accountInfo);
    } else {
      const {
        data: { data: paymentInfo },
      } = (await getUserBrandPaymentMethods()) || { data: [] };
      if (paymentInfo) setPaymentMethods(paymentInfo);
    }
  };

  // write a drop down input component to pick a country

  useEffect(() => {
    // getPaymentMethods();
  }, []);

  const deleteAndFetchPaymentMethods = async paymentId => {
    setIsLoading(true);
    try {
      if (streamer) {
        await deleteStreamerStripeAccount();
      } else {
        await deleteUserBrandPaymentMethod(paymentId);
      }
      await getPaymentMethods();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const title = !streamer ? "PAYMENT METHOD" : "CONNECTED BANK ACCOUNTS";
  console.log(paymentMethods, "paymentMethods");
  console.log('streamer', streamer)
  return (
    <div className='BrandPayment'>
      <div className='PaymentTitle'>{title}</div>
      {paymentMethods.map(paymentMethod => {
        const {
          brand,
          exp_month,
          exp_year,
          last4,
          fingerprint,
          id: paymentId,
          default_currency,
          payouts_enabled,
          details_submitted,
          country,
        } = paymentMethod;
        return (
          <PaymentOptionSaved
            brand={brand}
            exp_month={exp_month}
            exp_year={exp_year}
            last4={last4}
            fingerprint={fingerprint}
            key={paymentId}
            paymentId={paymentId}
            callback={deleteAndFetchPaymentMethods}
            streamer={streamer}
            default_currency={default_currency}
            payouts_enabled={payouts_enabled}
            details_submitted={details_submitted}
            country={country}
          />
        );
      })}
      {isLoading && <Loading />}

      {!isLoading && (
        <AddPayButton countries={countries} streamer={streamer} disabled={paymentMethods.length} />
      )}
      {/* <AddPaymentOptionForm /> */}
    </div>
  );
};

export default Payment;
