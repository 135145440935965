import React from 'react';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import Icon from '../../images/icons/Icon';
import { socialNetworks } from '../../../utils/util';
import { validateUrl } from '../../../utils/functions';

import './SocialIcons.scss';

const defaultHandleOpen = (url) => {
  if (url && validateUrl(url)) {
    window.open(url, '_blank');
  }
};

const SocialIcons = (props) => {
  const { network, tooltip, url, handleUrl = null, color = 'white', customStyling = {} } = props;

  const networkLower = network?.toLowerCase();

  if (!socialNetworks.includes(networkLower)) { return null; }

  return (
    <div className='SocialIcons'>
      <Tooltip title={tooltip} placement='top'>
        <div
          className={classnames("SocialIcons_Icon", url ? `${networkLower} URL` : 'Grayscale')}
          onClick={() => (handleUrl ? handleUrl(url) : defaultHandleOpen(url))}
          style={customStyling}
        >
          <Icon name={networkLower} color={color} />
        </div>
      </Tooltip>
    </div>
  );
};

export default SocialIcons;
