import React, { useState, useEffect } from "react";
import AdminTabs from "../../util/tabs/AdminTabs";
import { DisputeDropdown } from "./DisputeItem";
import "./Arbitration.scss";
import {
  SearchBar,
  ActiveFilters,
} from "../../../../global/components/filters/SearchBarAndFilters";
import { adminGetArbitration } from "../../../../utils/apiv2";


const Arbitration = props => {
  const [arbitrationSearch, setArbitrationSearch] = useState("");
  const [arbitrationFilter, setArbitrationFilter] = useState({});
  const [arbitrationFilterModal, setArbitrationFilterModal] = useState(false);
  const [disputeList, setDisputeList] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const res = await adminGetArbitration();
      setDisputeList(res?.data?.data || []);
      console.log(res);
    };
   fetchData();
  }, [arbitrationSearch, arbitrationFilter]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await adminGetArbitration();
      setDisputeList(res?.data?.data || []);
      console.log(res);
    };
    fetchData();
  }, []);

  const deliverableFilters = {
    completed: false,
    sorting: null,
  };


  const arbitrationSorts = [
    "pending",
    "brand",
    "streamer",
    "other"
  ];

  console.log(disputeList)

  return (
    <div className='Arbitration_MasterContainer'>
      <AdminTabs {...props} />
      <div className='Arbitration_WorkingContainer'>
        <section className='Arbitration_Search'>
          <SearchBar
            search={arbitrationSearch}
            setSearch={setArbitrationSearch}
            filterModal={arbitrationFilterModal}
            setFilterModal={setArbitrationFilterModal}
            filter={arbitrationFilter}
            setFilter={setArbitrationFilter}
            possibleSorts={arbitrationSorts}
          />
          <h1>Disputes</h1>

          <ActiveFilters
            filter={arbitrationFilter}
            setFilter={setArbitrationFilter}
            defaultFilters={deliverableFilters}
          />
        </section>
        <section className='Arbitration_MainList'>
          {disputeList &&
            disputeList.map((dispute, index) => {
              return (
                <DisputeDropdown
                  key={index}
                  product={dispute?.game}
                  quest={dispute?.quest}
                  disputes={dispute?.disputes}
                
                />
              );
            })}
        </section>
      </div>
    </div>
  );
};

export default Arbitration;
