import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";

import defaultAvatar from "../../../../../images/avatars/defaultSpaceman.png";
import CustomTextArea from "../../CustomInputs/CustomTextArea";
import CustomTextField from "../../CustomInputs/CustomTextField";
import Loading from "../../../../../global/components/loading/Loading";
// import StripeAdd from "../../payments/StripePay";

import { simpleNotification } from "../../../../../utils/notifications";
import { uploadToS3 } from "../../../../../utils/util";

import '../../../../profile/Profile.scss';
import "./General.scss";

const ProfilePic = props => {
  const {
    avatar = "",
    brandUserUploadImageUrl,
    handleFormChange,
    preSignedUrl: psu,
    token,
  } = props;

  const {
    data: {
      preSignedUrl,
      url: signedUrl,
    },
    isLoading,
  } = psu;

  const [pendingFile, setPendingFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (pendingFile && preSignedUrl) {
      uploadToS3(pendingFile, preSignedUrl, handleLoad);
    }
  }, [preSignedUrl]);

  const handleLoad = e => {
    setUploading(false);
    setPendingFile(null);

    if (e.target.status !== 200) {
      simpleNotification({
        level: "error",
        title: "Error",
        message: "Error uploading the image, try again please."
      });
    } else {
      handleFormChange("avatar", signedUrl);
    }
  };

  const handleFileUpload = (files, e) => {
    setUploading(true);
    try {
      const file = files?.length ? files[0] : e[0];
      setPendingFile(file);
      const fileNameSplit = file.name.split('.');
      const format = fileNameSplit[fileNameSplit.length - 1];

      brandUserUploadImageUrl(format, token);
    } catch (e) {
      setUploading(false);
      simpleNotification({
        level: 'error',
        title: 'Error Reading File',
        message: 'Error reading file. Please try again.',
        autoDismiss: 7,
      });
    }
  };

  const loading = isLoading || uploading;

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Dropzone
          className='ProfilePic__Dropzone'
          onDrop={(e, event) => handleFileUpload(e, event)}
          disablePreview={false}
          multiple={false}
          accept={['.jpg', '.png']}
          inputProps={{ id: 'file' }}
        >
          <div className='Profile_Pic_Container'>
            <div className='Profile_Pic'>
              <div className='TheImage'>
                <img src={avatar || defaultAvatar} alt="avatar" />
              </div>
              <div className='Profile_Pic_Text'>Change / Add Profile Photo</div>
            </div>
          </div>
        </Dropzone>
      )}
    </>
  )
};

// const ConnectAccount = ({ isLoading }) => (
//   <>
//     {!isLoading ? (
//       <div className='Add_Payment_Button'>
//         <div>
//           <i className='fa fa-credit-card' />
//         </div>
//         <div className='Add_Payment_Button_Text'>Connect Your Account For Payments</div>
//       </div>
//     ) : (
//       <div className='Add_Payment_Button'>
//         <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
//       </div>
//     )}
//   </>
// );

// const PaymentMethod = ({ isLoading }) => (
//   <>
//     {!isLoading ? (
//       <div className='Add_Payment_Button mt-5'>
//         <div>
//           <i className='fa fa-credit-card' />
//         </div>
//         <div className='Add_Payment_Button_Text'>Fund Your Quest</div>
//       </div>
//     ) : (
//       <div className='Add_Payment_Button'>
//         <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
//       </div>
//     )}
//   </>
// );

const General = (props) => {
  const {
    // auth,
    brandUserUploadImageUrl,
    errors,
    form,
    handleFormChange,
    preSignedUrl,
    token,
    // updateProfileInfo,
  } = props;

  return (
    <div className='General_Profile'>
      <ProfilePic
        avatar={form.avatar}
        brandUserUploadImageUrl={brandUserUploadImageUrl}
        handleFormChange={handleFormChange}
        preSignedUrl={preSignedUrl}
        token={token}
      />

      {/* <StripeAdd token={auth.token} children={<ConnectAccount />} />
      <StripeAdd token={auth.token}  payment={true} children={<PaymentMethod />} /> */}

      <CustomTextField
        header='USERNAME'
        name='username'
        value={form.username}
        onChange={handleFormChange}
        error={errors.includes("username")}
      />
      <CustomTextArea
        header='BIO'
        name='bio'
        value={form.bio}
        onChange={handleFormChange}
        error={errors.includes("bio")}
      />
      <CustomTextField
        header='COUNTRY'
        name='country'
        value={form.country}
        onChange={handleFormChange}
        error={errors.includes("country")}
      />
      <CustomTextField
        header='ORGANIZATION'
        name='organization'
        value={form.organization}
        onChange={handleFormChange}
        error={errors.includes("organization")}
      />
    </div>
  );
};

export default General;
