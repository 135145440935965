import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { SetViewContext } from '../../PortalContext';
import Icon from '../../../../../global/images/icons/Icon';
import { textTruncate } from '../../../../../utils/functions';

import './QuestsDisplay.scss';
import './CampaignDisplay.scss';

export const ActionButton = ({ tooltip, icon, args = {} }) => (
  <SetViewContext.Consumer>
    {({ setView, view }) => (
      <Tooltip placement="top" title={tooltip}>
        <div className="QuestDisplay__Item_Actions_Action" onClick={() => setView({ ...view, ...args })}>
          <Icon color='white' name={icon} />
        </div>
      </Tooltip>
    )}
  </SetViewContext.Consumer>
);

export const GameButton = ({ game: { id, name = '', cover }, args={} }) => (
  <SetViewContext.Consumer>
  {({ setView, view }) => (
  <Tooltip placement="top" title={`Edit ${name}`}>
    <div className="QuestDisplay__Item_Actions_Game" onClick={() => setView({ ...view, ...args })}>
      <img src={cover} alt={name} />
      <div className="QuestDisplay__Item_Actions_Game_Title">{textTruncate(name, 20)}</div>
      <Icon color='#16181c' name='edit' />
    </div>
  </Tooltip>
      )}
  </SetViewContext.Consumer>
);

export const CampaignGameButton = ({ game: { id, name = '', cover } }) => (
  <SetViewContext.Consumer>
    {({ setView, view }) => (
      <Tooltip placement="top" title={`View ${name}`}>
        <div className="CampaignDisplay__Item_Actions_Game" onClick={() => setView({
          ...view,
          component: 'ProductDetail',
          currentProduct: id,
        })}>
          <div className="CampaignDisplay__Item_Actions_Game_Title">View Product</div>
          <Icon color='#16181c' name='scroll' />
        </div>
      </Tooltip>
    )}
  </SetViewContext.Consumer>
);

export const CampaignActionButton = ({ tooltip, icon, args = {} }) => (
  <SetViewContext.Consumer>
    {({ setView, view }) => (
      <Tooltip placement="top" title={tooltip}>
        <div className="CampaignDisplay__Item_Actions_Action" onClick={() => setView({ ...view, ...args })}>
          <div className="CampaignDisplay__Item_Actions_Game_Title">View Reports</div>
          <Icon color='white' name={icon} />
        </div>
      </Tooltip>
    )}
  </SetViewContext.Consumer>
);
