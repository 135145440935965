export const getBUCampaign = (campaignId, startDate, endDate, token) => ({
  type: 'GET_BU_CAMPAIGN',
  payload: {
    campaignId,
    startDate,
    endDate,
    token,
  },
});

export const getBUCampaignList = token => ({
  type: 'GET_BU_CAMPAIGN_LIST',
  payload: {
    token,
  },
});


export const getBrandGame = (query,token) => ({
  type: 'GET_BRAND_GAME',
  payload: {
    query,
    token,
  },
});

export const getBrandQuest = (query, token) => ({
  type: 'GET_BRAND_QUEST',
  payload: {
    query,
    token,
  },
});

export const getBrandStreamer = (username, token) => ({
  type: 'GET_BRAND_STREAMER',
  payload: {
    username,
    token,
  },
});

export const getBrandUserQuest = (userQuestId, token) => ({
  type: 'GET_BRAND_USER_QUEST',
  payload: {
    userQuestId,
    token,
  },
});

export const brandUserUploadImageUrl = (slug, format, token) => ({
  type: 'BRAND_REQUEST_UPLOAD_IMAGE_URL',
  payload: {
    slug,
    format,
    token,
  },
});

export const postBrandPreapproval = (userQuestIds, status, token) => ({
  type: 'POST_BRAND_PREAPPROVAL',
  payload: {
    userQuestIds,
    status,
    token,
  },
});

export const postBrandGame = (game, token) => ({
  type: 'POST_BRAND_GAME',
  payload: {
    game,
    token,
  },
});

export const postBrandQuest = (quest, token) => ({
  type: 'POST_BRAND_QUEST',
  payload: {
    quest,
    token,
  },
});

export const postBrandRequirement = (requirement, token) => ({
  type: 'POST_BRAND_REQUIREMENT',
  payload: {
    requirement,
    token,
  },
});

export const putBrandGame = (game, token) => ({
  type: 'PUT_BRAND_GAME',
  payload: {
    game,
    token,
  },
});

export const putBrandQuest = (quest, token) => ({
  type: 'PUT_BRAND_QUEST',
  payload: {
    quest,
    token,
  },
});
