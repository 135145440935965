import React from 'react';
import classnames from 'classnames';

import './CustomInputs.scss';

const CustomRadioSelection = (props) => {
  const {
    header = '',
    name,
    value,
    onChange,
    error,
    options = [],
  } = props;

  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  const optionDisplay = options.map(({ optionName, optionValue }) => (
    <div className={classnames("CustomInput__Radio", error && "Error")} key={optionName}>
      <input
        checked={value === optionValue}
        className="Radio"
        name={name}
        value={optionValue}
        onChange={handleChange}
        type="radio"
      />
      <div className="CustomInput__Radio_Label">{optionName}</div>
    </div>
  ));

  return (
    <div className="CustomInput">
      {!!header && <div className="CustomInput__Header">{header}</div>}
      {optionDisplay}
    </div>
  );
};

export default CustomRadioSelection;
