import React, { useEffect } from 'react';
import anime from 'animejs';

const PulseCircle = (props) => {
  const {
    startLeft = 20, startTop = 20, zLevel = 0,
  } = props;


  useEffect(() => {
    anime({
      targets: `.pulse-${zLevel}`,
      easing: 'easeOutQuad',
      duration: 2500,
      loop: true,
      delay: 50,
      opacity: [1, 0],
      r: ['8px', '22px'],
    });

    anime({
      targets: `.thin-${zLevel}`,
      easing: 'easeOutQuad',
      duration: 2500,
      loop: true,
      opacity: [1, 0],
      r: ['8px', '30px'],
    });

    anime({
      targets: `.pulse-circle-${zLevel}`,
      easing: 'linear',
      duration: 1500,
      opacity: [0, 1],

    });
  }, []);


  return (
    <svg
      className={`pulse-circle-${zLevel}`}
      style={{
 overflow: 'visible', position: 'absolute', left: startLeft, top: startTop, opacity: 0,
}}
    >
      <circle
        style={{
 overflow: 'visible', position: 'absolute', left: startLeft, top: startTop,
}}
        fill="#ED6E46"
        className={`solid-${zLevel}`}
        r="10px"
      />

      <circle
        style={{
 overflow: 'visible', position: 'absolute', left: startLeft, top: startTop,
}}
        stroke="#ED6E46"
        fill="none"
        className={`thin-${zLevel}`}
        r="10px"
      />

      <circle
        style={{
 overflow: 'visible', position: 'absolute', left: startLeft, top: startTop,
}}
        fill="#ED6E46"
        strokeWidth="5"
        className={`pulse-${zLevel}`}
        r="10px"
      />
    </svg>
  );
};


export default PulseCircle;
