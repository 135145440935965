import React from 'react';
import { htmlToPlainText } from '../../utils/functions';
import './Quest.scss';
import ReactHtmlParser from 'react-html-parser';


const GameSection = (props) => {
  const {
    gameDescription = '',
    developer,
    // gamePageURL,
    genres,
    id,
    logo,
    publisher,
    questDescription = '',
    tagline,
    gameTitle = '',
    sponsorType,
    tabWidth = 1000,
  } = props;

  const changeText = window.innerWidth <= tabWidth;

  const isAGame = sponsorType === 'game';

  const mainDesc = questDescription ? ReactHtmlParser(questDescription) : htmlToPlainText(gameDescription).trim();

  return (
    <>
      <div className="GameSection">
        <div className="gameImage">
          <img src={logo} alt="client logo" />
          <button className="CTAButton__lightGrey button-hover-glow" onClick={() => window.open(`/game/${id}`, '_blank')}>
            Learn More
          </button>
        </div>
        <div className="gameDesc">
          <h1>{tagline || gameTitle}</h1>
          <span className="subtitle-text make-visible" style={{ fontSize: `${changeText ? 'calc(6px + 1vw)' : '13px'}` }}>{mainDesc}</span>
        </div>
      </div>
      <div className="GameDescription">
        {!isAGame && (
        <div className="gameItem">
          <span className="subtitle">Sponsor</span>
          <span className="subtitle-text make-visible">{gameTitle}</span>
        </div>
        )}

        {isAGame && developer && (
          <div className="gameItem">
            <span className="subtitle">Game Developer</span>
            <span className="subtitle-text make-visible">{developer}</span>
          </div>
        )}
        {isAGame && publisher && (
          <div className="gameItem">
            <span className="subtitle">Game Publisher</span>
            <span className="subtitle-text make-visible">{publisher}</span>
          </div>
        )}
        {isAGame && genres.length > 0 && (
          <div className="gameItem">
            <span className="subtitle">Game Genre</span>
            <span className="subtitle-text make-visible">{genres.map(g => g.name).join(', ')}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default GameSection;
