import { queryString } from "../../../utils/functions";


export const isPortalWithNoParams = () => {
  const { search } = window.location;
  return !search;
};

export const pushComponentState = (s, router, pathname) => {
  router.push(`${pathname}${queryString(s)}`);
  window.scrollTo(0, 0);
};
