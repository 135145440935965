import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { basicModal } from '../../../../utils/material_ui/Styles';

const Assignment = (props) => {
  const classes = basicModal();
  const {
    getList,
    handleSubmit,
    isLoading,
    list,
    platformsSelected,
    selection,
    token,
  } = props;

  const [checked, setChecked] = useState([]);

  useEffect(() => {
    getList(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const checkListItems = list.map((item) => {
    const labelId = `checkbox-list-label-${item.id}`;

    return (
      <ListItem key={item.id} dense button onClick={handleToggle(item.id)}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.indexOf(item.id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={selection === 'game' ? item.name : item.title} />
      </ListItem>
    );
  });

  return (
    <div>
      <div style={{ marginBottom: 10 }}>Add <b>{platformsSelected.length === 1 ? platformsSelected[0].platform : (`${platformsSelected.length} platforms`)}</b> to <b>{checked.length} {selection}{checked.length !== 1 ? 's' : ''}</b>:</div>
      {isLoading && <i className="fa fa-spinner fa-pulse" />}
      {!isLoading && list.length > 0 &&
        <List className={classes.root}>
          {checkListItems}
        </List>
      }
      {!isLoading && list.length <= 0 &&
        <div className={classes.root}>
          <p>No applicable {selection}s found.</p>
        </div>
      }
      <Button
        variant="contained"
        onClick={() => handleSubmit(checked)}
        disabled={checked.length <= 0}
        style={{ marginTop: 15 }}
      >
        Update
      </Button>
    </div>
  );
};

export default Assignment;
