import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import classnames from 'classnames';

import Icon from '../../images/icons/Icon';

import './SearchBarAndFilters.scss';

const fallbackFilters = {
  'Active Quests': false,
  'Recently Added': false,
  sorting: null,
};

const defaultSorts = [
  'Budget Ascending',
  'Budget Descending',
  'Date Created Ascending',
  'Date Created Descending',
  'Quest Title Ascending',
  'Quest Title Descending',
];

const FilterModalContentItem = props => {
  const { checked, filter, toggleFilter } = props;

  return (
    <div className="Search_Filters_Content_Item" onClick={toggleFilter}>
      <Checkbox className="Search_Filters_Content_Item_Checkbox" checked={checked} />
      <div className="Search_Filters_Content_Item_Title">{filter}</div>
    </div>
  )
};

const FilterModalContent = ({ filter = {}, setFilter, possibleSorts }) => {
  const handleSorting = event => {
    if (filter.sorting !== event.target.value) {
      setFilter(prevState => ({ ...prevState, sorting: event.target.value }))
    } else {
      setFilter(prevState => ({ ...prevState, sorting: null }))
    }
  };

  return (
    <div className="Search_Filters_Content">
      {/* <div className="Search_Filters_Content_Title">Filters</div>  */}
      {Object.keys(filter).filter(key => key !== 'sorting').map(key => (
        <FilterModalContentItem
          key={key}
          checked={filter[key]}
          filter={key}
          toggleFilter={() =>
            setFilter(prevState => ({ ...prevState, [key]: !filter[key] }))
          }
        />
      ))}
      <div className="Search_Filters_Content_Title">Filter By</div>
      <Select
        value={filter.sorting}
        onChange={handleSorting}
        autoWidth
        displayEmpty
      >
        <MenuItem key='menu-item-none' value={null}>None</MenuItem>
        {possibleSorts.map(sort => (
          <MenuItem key={sort?.toString()} value={sort}>{sort}</MenuItem>
        ))}
      </Select>
    </div>
  )
};

export const SearchBarOnly = ({ disabled, search, setSearch, searchButtonFn }) => (
  <div className="Search">
    <div className="Search_Top">
      <div className={`Search_Input ${disabled && 'Disabled'}`}>
        <Icon color='#bfbfbf' name='search' />
        <input
          type="text"
          disabled={disabled}
          id="search-bar-input"
          label="SearchBarInput"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && searchButtonFn()}
        />
      </div>
      {!disabled && <div className="Search_Filters_Button" onClick={searchButtonFn}>
        <Icon color='black' name='play' />
      </div>}
      {disabled && (
        <div className="Search_Filters_Button" onClick={() => setSearch('')}>
          <Icon color='black' name='xmark' />
        </div>
      )}
    </div>
  </div>
);

export const SearchBar = ({ filter, setFilter, filterModal, setFilterModal, search, setSearch, possibleSorts = defaultSorts, customComponent = null }) => (
  <div className="Search">
    <div className="Search_Top">
      <div className="Search_Input">
        <Icon color='#bfbfbf' name='search' />
        <input
          type="text"
          id="search-bar-input"
          label="SearchBarInput"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="Search_Filters_Button" onClick={() => setFilterModal(!filterModal)}>
        <Icon color='black' name='filter' />
      </div>
    </div>
    <div className={classnames('Search_Filters', filterModal ? 'Filters_Open' : null)}>
      <FilterModalContent
        filter={filter}
        setFilter={setFilter}
        possibleSorts={possibleSorts}
      />
    </div>
    {customComponent}
  </div>
);

export const ActiveFilters = ({ filter, setFilter, defaultFilters = fallbackFilters }) => {
  const activeFilters = Object.keys(filter).filter(key => filter[key] && key !== 'sorting');

  const handleFilter = (key, sorting) => {
    if (sorting) {
      setFilter(prevState => ({ ...prevState, sorting: null }))
    } else {
      setFilter(prevState => ({ ...prevState, [key]: false }));
    }
  };

  const clearFilters = () => setFilter(defaultFilters);

  const ClearFiltersButton = () => (
    <div className="Filters_Clear" onClick={clearFilters}>
      Clear Filters
    </div>
  );

  const FilterButton = ({ filter, sorting }) => (
    <div className="Filters_Button" onClick={() => handleFilter(filter, sorting)}>
      <div className="Filters_Button_Close">x</div>
      {filter}
    </div>
  );

  const FiltersRow = ({ filters }) => (
    <div className="Filters_Buttons">
      {filters.map(af => <FilterButton key={af?.toString()} filter={af} />)}
      {filter.sorting && <FilterButton filter={filter.sorting} sorting />}
    </div>
  );

  if (activeFilters.length === 0 && !filter.sorting) { return null; }

  return (
    <div className="Filters">
      <FiltersRow filters={activeFilters} />
      <ClearFiltersButton />
    </div>
  );
};
