/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router';
import './AccessDenied.scss';
// import PropTypes from 'prop-types';

class AccessDenied extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  render() {
    return (
      <section className="AccessDenied">
        <div className="AccessDenied__wrapper">
          <div className="AccessDenied__content">
            <h1>Uh oh!</h1>
            <p>
              Looks like your account doesn't meet the criteria! At the moment,
              Noiz is only open to active streamers who are either an Affiliate on Twitch or reach an average viewer threshold.
            </p>
            <p className="sub">
              If you think we've made a mistake, please contact <a href="mailto:support@noiz.gg">support@noiz.gg</a>
            </p>
            <Link className="site-gradient-btn" to="/" href="/" >BACK</Link>
          </div>
          <div className="noiz-icon treasure" />
        </div>
      </section>
    );
  }
}

export default AccessDenied;
