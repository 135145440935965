import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import trophy from '../assetsV2/trophy.svg';
import anime from 'animejs';
// Little helpers ...

const FMicroInfluencer = (props) => {
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0, navToOffset = () => null, contactNavIndex = 0,
  } = props;
  const ref = React.createRef();

  const onScreen = useOnScreen(ref, '100px', 0.5);
  const [debounce, setDebounce] = React.useState(true);

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.cascade-fade-f',
        opacity: [1, 0],
        translateY: 100,
        duration: 800,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(60, { direction: 'reverse' }),
      });
      setDebounce(false);
    }
  }, [onScreen, debounce]);

  return (

    < >
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0} className="slide-f-container" >
        <div className="brand-vert-stack-center cascade-fade-f" >
          <div ref={ref} />
          <div>
            <img className="brand-micro-image" src={trophy} alt="micro" />
          </div>
          <div className="brand-services-trophy-text">
            Micro-influencers have 7x more engagement than macro influencers &#xb9;
          </div>
          <a href="https://influencermarketinghub.com/influencer-marketing-benchmark-report-2020/" target="_blank" rel="nofollow noopener noreferrer" className="brand-source brand-source-text">   source &#xb9;</a>
        </div>
        <div className="grey-brand-body-text brand-vert-stack cascade-fade-f">
          <div className="brand-title">Micro Influencers</div>
          <div>
            Micro-influencers produce authentic content that audiences love. This leads to successful engagement, yields positive campaign results and maximizes ROI.
          </div>
          <div>
            <button onClick={() => navToOffset(contactNavIndex - 1.8)} className="brand-white-button"> Learn More </button>


          </div>
        </div>
      </ParallaxLayer>


    </>

  );
};


export default FMicroInfluencer;

