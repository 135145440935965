import React from 'react';

const fontFamily = 'Lato, sans-serif';
const fontSize = '75';
const fontWeight = '900';

const StreamerNames = ({ text }) => {
  return (
    <svg height="235" viewBox="0 0 400 235" width="100%">
      <text
        fill="none"
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        opacity="0.08"
        stroke="white"
        textAnchor="middle"
        x="-50"
        y="65">
        {` ${text}`.repeat(11)}
      </text>
      <text
        fill="none"
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        opacity="0.06"
        stroke="white"
        textAnchor="middle"
        x="-120"
        y="140">
        {` ${text}`.repeat(11)}
      </text>
      <text
        fill="none"
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        opacity="0.04"
        stroke="white"
        textAnchor="middle"
        x="-50"
        y="215">
        {` ${text}`.repeat(11)}
      </text>
    </svg>
  );
};

export default StreamerNames;