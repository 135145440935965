const { countryCodeMap } = require('./countries.js');

const parsePercent = val => (val * 100).toFixed(2) + '%';

/*
  insightsData = {
    "A": 2.857142857,
    "G": "Minecraft",
    "M": 35,
    "T": "thedemonuk",
    "H": "2021-11-02T20:00:00.000Z"
  }

  users = {
    "id": "5ab67e98af9eba1658aaa76d",
    "name": "jadeplaysgames",
    "questsCompleted": 0,
    "region": "United Kingdom",
    "twitchId": 55334145,
    "twitchUsername": "jadeplaysgames"
  }

  TODO: this whole function is a mess, but the mess is all in one place, clean it up
  TODO: post-filter on games played all-time
  TODO: post-filter on games played within time period
  TODO: Post-query filtering needs to be abstracted to it's own function
    so that post-filters can be applied & undone without requerying snowflake
    maybe multiple fn's so each can be applied/undone independently
*/

function buildDashboard(_users, insights, accv) {

  let users = [ ..._users ];
  const singleUser = users.length === 1;
  const divisor = singleUser ? 5 : 60; // Single user queries Snowflake in 5 min increments instead of hour increments
  const dashboard = insights.reduce((model, _insight) => {
    const twitchUsername = _insight.T;
    const insight = { ..._insight };
    delete insight.T;
    let user = model.userData[twitchUsername];
    if (!user) { // This should only happen once
      // Associate user data w/ insights
      const userIndex = users.findIndex(user => twitchUsername === user.twitchUsername);
      const userData = { ...users[userIndex] };
      model.userData[twitchUsername] = {
        hourlyAvgViewersTotal: insight.A,
        timePlayingTotal: insight.M, 
        insights: [insight],
        ...userData,
      };

      users.splice(userIndex, 1); // remove user for the sake of efficiency
    } else {
      // Update aggregate stats
      user.hourlyAvgViewersTotal += insight.A;
      user.timePlayingTotal += insight.M;
      user.insights.push(insight);
    }

    return model;
  }, { userData: {} });

  dashboard.totalReach = 0;
  const hourlyData = {};
  const regionData = {};
  const userKeys = Object.keys(dashboard.userData);
  userKeys.forEach(userKey => {
    const user = dashboard.userData[userKey];

    // Calculate accv (over date range) for each user
    user.accv = user.hourlyAvgViewersTotal / (user.timePlayingTotal / divisor);

    // Post-filter on ACCV
    if (user?.accv <= accv?.min || user?.accv >= accv?.max) {
      user.exclude = true;
      return;
    }

    // ALL FILTERING SHOULD BE FINISHED BY THIS POINT

    // Add users accv to total reach
    const userAccv = user.accv;
    dashboard.totalReach += userAccv;

    // Add user's data to hourly data
    user.insights.forEach(insight => {
      if (!hourlyData[insight.H]) {
        hourlyData[insight.H] = insight.A;
      } else {
        hourlyData[insight.H] += insight.A;
      }
    });

    // Add user's data to region data
    const userRegion = user.region;
    const region = regionData[userRegion];
    if (!region) { // This should only happen once
      regionData[userRegion] = {
        country: userRegion,
        id: countryCodeMap[userRegion],
        userAccvTotal: userAccv,
        userCount: 1 
      };
    } else {
      region.userAccvTotal += userAccv;
      region.userCount += 1 
    }
  });

  dashboard.graphData = Object.keys(hourlyData).map(key => ({
    x: new Date(key),
    y: hourlyData[key]
  })).sort((a, b) => a.x - b.x);

  dashboard.regionData = Object.keys(regionData).map(regionKey => {
    const region = regionData[regionKey];
    return {
      ...region,
      accvPercent: parsePercent(region.userAccvTotal / dashboard.totalReach),
      usersPercent: parsePercent(region.userCount / userKeys.length),
    }
  }).sort((a, b) => b.userAccvTotal - a.userAccvTotal);

  // Restructure userData into array & remove those excluded by filters
  dashboard.userData = Object.keys(dashboard.userData).map(twitchUsername => {
    return dashboard.userData[twitchUsername];
  }).filter(user => !user.exclude);

  return dashboard;
}

export default buildDashboard;