import React from 'react';
import {
  Backdrop,
  Button,
  Fade,
  Modal,
} from '@material-ui/core';

import { basicModal } from '../../../../utils/material_ui/Styles';
import MassPlatform from './MassPlatform';

const DevMenu = (props) => {
  const { setMenuOption } = props;

  return (
    <div style={{
 display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
}}
    >
      <Button variant="contained" onClick={() => setMenuOption(1)}>Mass Platform Assignment</Button>
    </div>
  );
};

export default function DevConsole(props) {
  const { modal, toggleModal } = props;
  const classes = basicModal();

  const [menuOption, setMenuOption] = React.useState(0);


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        onClose={() => toggleModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            {menuOption === 0 && <DevMenu setMenuOption={setMenuOption} />}
            {menuOption === 1 && <MassPlatform {...props} setMenuOption={setMenuOption} />}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
