import React from 'react';

import CustomDate from '../../CustomInputs/CustomDate';
// import CustomDropdown from '../../CustomInputs/CustomDropdown';
// import CustomGroupSelection from '../../CustomInputs/CustomGroupSelection';
import CustomRadioSelection from '../../CustomInputs/CustomRadioSelection';
import CustomTextArea from '../../CustomInputs/CustomTextArea';
import CustomTextField from '../../CustomInputs/CustomTextField';
import LargeToggle from '../../CustomInputs/LargeToggle';
// import RichText from '../../../../../utils/RichText';

const General = ({ form, handleFormChange, errors, platformOptions }) => (
  <>
    <LargeToggle
      header='PRODUCT STATUS'
      leftColor='#393939'
      // leftIcon='sun'
      rightColor='#4be0ca'
      // rightIcon='moon'
      name='status'
      onChange={handleFormChange}
      value={form.status}
    />
    <CustomTextField
      header="PRODUCT NAME"
      name="name"
      value={form.name}
      onChange={handleFormChange}
      error={errors.includes('name')}
    />
    <CustomRadioSelection
      header="SPONSORSHIP TYPE"
      name="sponsorType"
      value={form.sponsorType}
      onChange={handleFormChange}
      error={errors.includes('sponsorType')}
      options={[
        { optionName: 'Video Game', optionValue: 'game' },
        { optionName: 'Product/event/other', optionValue: 'non-game' },
      ]}
    />
    {form.sponsorType === 'game' && (
      <CustomTextField
        header='NAME IN TWITCH'
        name='nameInTwitch'
        value={form.nameInTwitch}
        onChange={handleFormChange}
        error={errors.includes('nameInTwitch')}
      />
    )}
    <CustomTextArea
      header="PRODUCT DESCRIPTION"
      name="description"
      value={form.description}
      onChange={handleFormChange}
      error={errors.includes('description')}
    />
    {/* <h3>DESCRIPTION</h3>
    <RichText 
      initialValue={form.description} 
      callback={content=> handleFormChange('description', content)} 
    /> */}
    <CustomTextField
      header="CREATOR / DEVELOPER"
      name="developer"
      value={form.developer}
      onChange={handleFormChange}
      error={errors.includes('developer')}
    />
    <CustomTextField
      header="PUBLISHER"
      name="publisher"
      value={form.publisher}
      onChange={handleFormChange}
      error={errors.includes('publisher')}
    />
    {/* <CustomDropdown
      header="STATUS"
      name="status"
      value={form.status}
      onChange={handleFormChange}
      error={errors.includes('status')}
      options={[
        { optionName: 'Active', optionValue: 'active' },
        { optionName: 'Archived', optionValue: 'archived' },
        { optionName: 'Disabled', optionValue: 'disabled' },
      ]}
    /> */}
    <CustomDate
      header="RELEASE DATE"
      name="releaseDate"
      value={form.releaseDate}
      onChange={handleFormChange}
      error={errors.includes('releaseDate')}
    />
    {/* {form.sponsorType === 'game' && ( <CustomGroupSelection
      header="PLATFORMS"
      name="platforms"
      value={form.platforms}
      onChange={handleFormChange}
      error={errors.includes('platforms')}
      options={platformOptions}
    />)} */}
    {/* <CustomRadioSelection
      header="IS PRIVATE"
      name="isPrivate"
      value={form.isPrivate?.toString() || false}
      onChange={handleFormChange}
      error={errors.includes('isPrivate')}
      options={[
        { optionName: 'Yes', optionValue: 'true' },
        { optionName: 'No', optionValue: 'false' },
      ]}
    /> */}
  </>
);

export default General;
