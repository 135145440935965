import React, { useState } from "react";
import { CountryRegionData } from "react-country-region-selector";
import CustomDropdown from '../../../components/brand/portal/CustomInputs/CustomDropdown';

import {
  connectUserBrandPaymentOption,
  connectStreamerBankAccount,
  loginToStripe,
} from "../../../utils/apiv2";

import ConfirmModal from "../../../global/components/confirm-modal/ConfirmModal";
import Loading from "../loading/Loading";

import "./AddPaymentOption.scss";

const CountrySelector = ({ country, setCountry, countries = [] }) => {
  // const CRegionsWithNull = [['Select A Banking Region', '' , ''], ...CountryRegionData]
  const CRegionsWithNull = [
    ["Select a country"],
    ...CountryRegionData.filter(c => countries.includes(c[1])),
  ];

  return (
    <div className='mb-10'>
      <CustomDropdown
        header="Select a Banking Region"
        name="country"
        onChange={(_, value) => {
          setCountry(value);
        }}
        options={CRegionsWithNull.map(([name, code]) => ({
          optionName: name,
          optionValue: code,
        }))}
        value={country}
      />
      {/* <select
        value={country}
        onChange={e => {
          setCountry(e.target.value);
        }}>
        {CRegionsWithNull.map(([name, code]) =>
          <option key={code} value={code}>{name}</option>
        )}
      </select> */}
    </div>
  );
};

export const AddPayButton = ({ disabled = true, streamer = false, countries = [] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState("");
  const callPaymentAPI = async () => {
    setIsLoading(true);
    try {
      if (streamer) {
        await connectStreamerBankAccount(country);
      } else {
        await connectUserBrandPaymentOption();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const title = !streamer ? "Add Payment Method" : "Add Bank Account";
  const isStreamerAndNoCountry = streamer && !country;
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {streamer && !disabled && (
        <CountrySelector countries={countries} country={country} setCountry={setCountry} />
      )}
      <div
        disabled
        className={`AddPayButton ${disabled || isStreamerAndNoCountry ? "disabled" : ""}`}>
        <div
          disabled={disabled || isStreamerAndNoCountry}
          className='add-payment-option__button'
          onClick={() => (disabled || isStreamerAndNoCountry ? null : callPaymentAPI())}>
          <i className='fa fa-credit-card' />
          <span className='PaymentText'>{title}</span>
        </div>
      </div>
    </>
  );
};

export const PaymentOptionSaved = ({
  type = "",
  last4 = "",
  exp_month = "",
  exp_year = "",
  brand = "",
  paymentId = "",
  callback = a => console.log(a),
  streamer = false,
  default_currency = "",
  payouts_enabled = false,
  details_submitted = false,
  country = "",
}) => {
  const theType =
    type === "card" ? "fa-credit-card" : type === "bank" ? "fa-building-columns" : "fa-credit-card";
  const expires = `${exp_month}/${exp_year}`;
  const [modal, setOpenModal] = useState(false);
  const brandNullState =
    !streamer && type === "" && last4 === "" && exp_month === "" && exp_year === "" && brand === "";
  if (brandNullState) return null;

  const BankAccountLinked = () => {
    const goodToGo = payouts_enabled && details_submitted;
    return (
      <div className='StripeCluster'>

        <div className={`StripeCluster__Status ${goodToGo ? "go" : "stop"}`}>
          <span className={`${goodToGo ? "go" : "stop"}`}>
            {goodToGo ? "ACCOUNT READY" : "NEEDS INFORMATION"}{" "}
          </span>
          <button className='stripe_button' onClick={() => loginToStripe()}>
            Log In To <strong>Stripe </strong>
          </button>
        </div>
        <div className='StripeInfo'>
          <div className="ItemMarker">
            <div>Country:</div>
            <div>{country}</div>
          </div>
          <div className="ItemMarker">
            <div>Default Currency:</div>
            <div>{default_currency.toLocaleUpperCase()} </div>
          </div>
          <div className="ItemMarker">
            <div>Payouts Enabled:</div>
            <div className={`${goodToGo ? "go" : "stop"}`}>{`${payouts_enabled}`.toLocaleUpperCase()} </div>
          </div>
          <div className="ItemMarker">
            <div>Details Submitted:</div>
            <div  className={`${goodToGo ? "go" : "stop"}`}>{`${details_submitted}`.toLocaleUpperCase()} </div>
          </div>
        </div>
        <div className='TrashThis' onClick={a => setOpenModal(!modal)}>
          <i className={`fa fa-trash`} />
        </div>
      </div>
    );
  };

  const PaymentOptionLinked = () => {
    return (
      <>
        <div className={`PaymentCluster ${!streamer ? "IsBrand" : null}`}>
          <div className='IconType'>
            <div>
              <i className={`fa ${theType}`} />
            </div>
          </div>
          <div>
            <div>{brand}</div>
            <div className='PaymentNumber'>**** **** **** {last4}</div>
            {expires && <div className='PaymentExpires'>exp: {expires}</div>}
          </div>
        </div>
        <div className='TrashThis' onClick={a => setOpenModal(!modal)}>
          <i className={`fa fa-trash`} />
        </div>
      </>
    );
  };

  const brandConfirmText = {
    title: "Delete Payment Option",
    content: `Are you sure you want to remove the card ending in ${last4}?`,
    confirmText: "Yes",
  };

  const streamerConfirmText = {
    title: "Delete Connected Stripe Account",
    content: `Are you sure you liked to delete this account and all saved payment information?`,
    confirmText: "Yes",
  };

  const modalText = streamer ? streamerConfirmText : brandConfirmText;

  return (
    <div className='SavedPaymentOption'>
      {streamer ? <BankAccountLinked /> : <PaymentOptionLinked />}
      <ConfirmModal
        open={modal}
        setOpen={setOpenModal}
        callback={() => callback(paymentId)}
        {...modalText}
      />
    </div>
  );
};
