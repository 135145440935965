import React, { useEffect, useState } from 'react';
import classnames from 'classnames';


import Icon from '../../../global/images/icons/Icon';
import { throttle, validateSignForm } from '../../../utils/functions';
// import { simpleNotification } from '../../../utils/notifications';

import './PortalSignForm.scss';

const warningMessage = "Password must have at least one upper case letter, one lower case letter, and at least one special character, e.g., !@#$%^&* ()’;";

const PortalSignForm = (props) => {
  const {
    signIn: signInAction,
    signUp: signUpAction,
    router,
    signInPage,
    auth: {
      errors: {
        hasError,
        message,
      },
      isAuthenticated,
      isLoading,
      isUpdating,
      recoverSuccess,
    },
    recover: recoverFn,
  } = props;

  const [signIn, setSignIn] = useState(true);
  const [recover, setRecover] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setSignIn(!!signInPage);
  }, []);

  useEffect(() => {
   console.log(hasError, message, 'error and message')
  }, [hasError, message])

  // This section of code in theory shouldn't need to exist but index.js isn't handling the redirect properly
  useEffect(() => {
    if (isAuthenticated) {
      router.push('/portal/');
    }
  }, [isAuthenticated]);
  // End section

  const handleSubmit = (e) => {
    e.preventDefault();

    const throttledFn = throttle(signIn ? signInAction : signUpAction, 1000);
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    const validated = validateSignForm(formProps, signIn);

    if (validated.length === 0) {
      console.log(formProps,'formprops')
      throttledFn({...formProps, role: 'brand'});
    } else {
      setErrors(validated);
    }
  };

  const handleRecover = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    recoverFn(formProps);
  };

  const SignForm = () => (
    <>
      <div className="PortalSignForm__Header"> Welcome to Noiz </div>
      <div className="PortalSignForm__Buttons">
        <div
          className={classnames('SignFormButtons', 'SignInButton', signIn ? 'Active-Login' : 'Inactive-Login')}
          onClick={() => {
            router.replace('/portal/sign-in');
            setSignIn(true);
            setErrors([]);
          }}
        >
          SIGN IN
        </div>
        <div
          className={classnames('SignFormButtons', 'SignUpButton', signIn ? 'Inactive-Login' : 'Active-Login')}
          onClick={() => {
            router.replace('/portal/sign-up');
            setSignIn(false);
            setErrors([]);
          }}
        >
          SIGN UP
        </div>
      </div>
      <form className="PortalSignForm__Form" onSubmit={handleSubmit} autoComplete="off">
      {signIn && (<> <div className="PortalSignForm__Form_Headers">USERNAME OR EMAIL</div>
        <input
          className={classnames('PortalSignForm__Form_Input', errors.includes('identity') ? 'Error' : '')}
          type='text'
          name='identity'
          id='identity'
          placeholder='Username or Email'
        />
        </>)}
        {!signIn && (<> <div className="PortalSignForm__Form_Headers">EMAIL</div>
        <input
          className={classnames('PortalSignForm__Form_Input', errors.includes('email') ? 'Error' : '')}
          type='text'
          name='email'
          id='email'
          placeholder='Email'
        />
        </>)}
        {!signIn && (<> <div className="PortalSignForm__Form_Headers">USERNAME</div>
        <input
          className={classnames('PortalSignForm__Form_Input', errors.includes('username') ? 'Error' : '')}
          type='text'
          name='username'
          id='username'
          placeholder='Username'
        />
        </>)}
   
        <div className="PortalSignForm__Form_Headers">PASSWORD</div>
        <input
          className={classnames("PortalSignForm__Form_Input", errors.includes('password') || errors.includes('confirmPassword') ? 'Error' : '')}
          type="password"
          name="password"
          id="password"
          placeholder="Password"
        />
        {!signIn && (<>
          <div className="PortalSignForm__Form_Sub_Headers">{warningMessage}</div>
          <div className='PortalSignForm__Form_Headers'>CONFIRM PASSWORD</div>
          <input
            className={classnames("PortalSignForm__Form_Input", errors.includes('confirmPassword') ? 'Error' : '')}
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm Password"
          />
        </>)}
        {hasError && message &&
          <div className="PortalSignForm__Form_Error">{message}</div>
        }
        {!isLoading && !isUpdating && !isAuthenticated &&
          <button
            className="PortalSignForm__Form_Button"
            type="submit"
          >
            SIGN {signIn ? 'IN' : 'UP'}
          </button>
        }
        {(isLoading || isUpdating) &&
          <div className="PortalSignForm__Form_Button_Spinner">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </div>
        }
      </form>
      {signIn && (
        <div className="PortalSignForm__Forgot" onClick={() => setRecover(true)} >Forgot Password?</div>
      )}
    </>
  );

  const RecoverForm = () => (
    <>
      <div className="PortalSignForm__Back" onClick={() => setRecover(false)}>
        {Icon({ color: '#4be0ca', name: 'angleleft' })} Back
      </div>
      <div className="PortalSignForm__Header"> Recover Your Account </div>
      {!recoverSuccess &&
        <form className="PortalSignForm__Form" onSubmit={handleRecover} autoComplete="off">
          <div className="PortalSignForm__Form_Headers">USERNAME OR EMAIL</div>
          <input
            className={classnames('PortalSignForm__Form_Input', errors.includes('identity') ? 'Error' : '')}
            type='text'
            name='identity'
            id='identity'
            placeholder='Username or Email'
          />
          {hasError && message &&
            <div className="PortalSignForm__Form_Error">{message}</div>
          }
          {(isLoading || isUpdating) &&
            <div className="PortalSignForm__Form_Button_Spinner">
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            </div>
          }
          {!isLoading && !isUpdating && !recoverSuccess &&
            <button
              className="PortalSignForm__Form_Button"
              type="submit"
            >
              SEND
            </button>
          }
        </form>
      }
      {recoverSuccess &&
        <div className="PortalSignForm__Form_Success">
          {Icon({ color: '#4be0ca', name: 'check' })}
          We sent you an email to retrieve your password. Sometimes this email may take a couple of minutes before it reaches your mailbox.
        </div>
      }
    </>
  );

  return (
    <div className="PortalSignForm__Wrapper">
      <div className="PortalSignForm">
        <div className={classnames('PortalSignForm__Bar', 'TopPosition')} />
        <div className={classnames('PortalSignForm__Bar', 'BottomPosition')} />
        {!recover && <SignForm />}
        {recover && <RecoverForm />}
      </div>
    </div>
  );
};

export default PortalSignForm;
