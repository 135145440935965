import buildDashboard from './buildDashboard.js';
import { chunk } from './chunk.js';
import filterUsers from './filterUsers.js';
import {
  countryCodeMap,
  countries,
} from './countries.js';
import prepSummaryCSV from './prepSummaryCSV.js';
import prepUserCSV from './prepUserCSV.js';
import prepRawCSV  from './prepRawCSV.js';

const MILLISECONDS_IN_30_DAYS = 2592000000;

const get30DayDateRange = () => [new Date(Date.now() - MILLISECONDS_IN_30_DAYS).setMinutes(0, 0, 0), new Date().setMinutes(0, 0, 0)];

export {
  buildDashboard,
  chunk,
  countryCodeMap,
  countries,
  filterUsers,
  get30DayDateRange,
  prepSummaryCSV,
  prepRawCSV,
  prepUserCSV
};