import React from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  Paper,
  Grid,
  TextField,
  Tooltip,
  Switch,
} from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment-timezone';
import { flatModal } from '../../../../utils/material_ui/Styles';
import { numeralFilter } from '../../../../utils/functions';
import './EditModal.scss';

export default function EditModal(props) {
  const classes = flatModal();
  const ready = props && props.currentUser;
  const initTimePlaying = ready ? Math.round(props.currentUser.timePlaying) : 0;
  const initAverageViewers = ready ? Math.round(props.currentUser.averageViewers) : 0;
  const initPeakViewers = ready ? Math.round(props.currentUser.peakViewers) : 0;
  const startDateFormatted = ready && props.currentUser.manualStartDate ? moment(props.currentUser.manualStartDate).format('YYYY-MM-DDTHH:mm:ss') : null;
  const endDateFormatted = ready && props.currentUser.manualEndDate ? moment(props.currentUser.manualEndDate).format('YYYY-MM-DDTHH:mm:ss') : null;

  const [open, setOpen] = React.useState(false);

  const [userTimePlayed, setValuesTimePlayed] = React.useState(initTimePlaying);
  const [averageViewers, setAverageViewers] = React.useState(initAverageViewers);
  const [peakViewers, setPeakViewers] = React.useState(initPeakViewers);
  const [manualStartDate, setManualStartDate] = React.useState(startDateFormatted);
  const [manualEndDate, setManualEndDate] = React.useState(endDateFormatted);

  const [commitCheck, setCommitCheck] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, swap) => {
    const selector = {
      peakViewers: setPeakViewers,
      averageViewers: setAverageViewers,
      timePlayed: setValuesTimePlayed,
      manualStartDate: setManualStartDate,
      manualEndDate: setManualEndDate,
    };
    const { value } = event.target;
    if (swap.toString() === 'manualStartDate' || swap.toString() === 'manualEndDate') {
      selector[swap](value);
    } else {
      const filtered = numeralFilter(value);
      selector[swap](Number(filtered));
    }
  };

  const onSubmit = async () => {
    const { token } = props.auth;
    const { questId } = props.router.params;
    const startDate = manualStartDate ? new Date(manualStartDate).toISOString() : null;
    const endDate = manualEndDate ? new Date(manualEndDate).toISOString() : null;
    const body = {
      timePlaying: userTimePlayed,
      questId,
      averageViewers,
      peakViewers,
      userId: props.currentUser.user.id
        ? props.currentUser.user.id
        : props.currentUser.id,
      manualStartDate: startDate,
      manualEndDate: endDate,
    };
    const options = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    try {
      await Axios.post('/api/v1/quest/questtimed', body, options);
    } catch (err) {
      console.log(err.message, 'RESPONSE');
    }

    if (questId) {
      props.rehydrateCallback(questId, token);
    }
    handleClose();
  };

  const twitchUser =
    props.currentUser && props.currentUser.twitchUsername
      ? props.currentUser.twitchUsername
      : props.currentUser.username;

  return (
    <div>
      <Tooltip title="Edit user stream stats" placement="top">
        <button className="small-btn" onClick={handleOpen}>
          <span style={{ fontSize: '14px' }}>&#9998;</span>
        </button>
      </Tooltip>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid
              xs={12}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                container
                xs={12}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.topMast}
              >
                <Grid>
                  <h1> {twitchUser} </h1>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                justify="space-around"
                align="flex-start"
                direction="column"
                className={classes.mainContainer}
              >
                <Grid className={classes.mainFields} container xs={12} justify="space-around" direction="row" align="start" spacing={6} >
                  <Grid>
                    <Grid className={classes.topMargin}>
                      <div className="transition-modal-time">
                        Time Played: {initTimePlaying}
                      </div>
                    </Grid>
                    <div className="edit-modal-input-override">
                      <TextField
                        id="timePlayed"
                        label="Adj. Time Played"
                        type="text"
                        value={userTimePlayed}
                        onChange={e => handleChange(e, 'timePlayed')}
                        className={`${classes.textField}`}
                      />
                    </div>
                  </Grid>

                  <Grid>
                    <Grid className={classes.topMargin}>
                      <div className="transition-modal-time">
                        Average Viewers: {initAverageViewers ? Math.round(initAverageViewers) : 0}
                      </div>
                    </Grid>
                    <div className="edit-modal-input-override">
                      <TextField
                        id="timePlayed"
                        label="Adj. Avg Views"
                        type="text"
                        value={averageViewers}
                        onChange={e => handleChange(e, 'averageViewers')}
                        className={`${classes.textField}`}
                      />
                    </div>
                  </Grid>

                  <Grid>
                    <Grid className={classes.topMargin}>
                      <div className="transition-modal-time">
                        Peak Viewers: {initPeakViewers}
                      </div>
                    </Grid>
                    <div className="edit-modal-input-override">
                      <TextField
                        id="timePlayed"
                        label="Adj. Peak Views"
                        type="text"
                        value={peakViewers}
                        onChange={e => handleChange(e, 'peakViewers')}
                        className={`${classes.textField}`}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid className={classes.mainFields} container xs={12} justify="space-around" direction="row" align="start" spacing={6} >
                  <Grid>
                    <Grid className={classes.topMargin}>
                      <div className="transition-modal-time">
                        Stream start date: <Tooltip title="Required for Chat Data" placement="top"><i className="tooltip-icon fa fa-info-circle" /></Tooltip>
                      </div>
                    </Grid>
                    <div className="edit-modal-input-override">
                      <TextField
                        id="manualStartDate"
                        type="datetime-local"
                        value={manualStartDate}
                        onChange={e => handleChange(e, 'manualStartDate')}
                        className={`${classes.textField}`}
                      />
                    </div>
                  </Grid>

                  <Grid>
                    <Grid className={classes.topMargin}>
                      <div className="transition-modal-time">
                        Stream end date: <Tooltip title="Required for Chat Data" placement="top"><i className="tooltip-icon fa fa-info-circle" /></Tooltip>
                      </div>
                    </Grid>
                    <div className="edit-modal-input-override">
                      <TextField
                        id="manualEndDate"
                        type="datetime-local"
                        value={manualEndDate}
                        onChange={e => handleChange(e, 'manualEndDate')}
                        className={`${classes.textField}`}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.buttonGrid}
                  xs={12}
                  direction="row"
                  width="100%"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onSubmit}
                      disabled={!commitCheck}
                    >
                      Accept
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      onClick={handleClose}
                      className={classes.errorButton}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
                <Grid>
                  <Switch checked={commitCheck} onChange={() => setCommitCheck(!commitCheck)} />
                  I am sure I want to update this user's stats to what is selected above.
                </Grid>
                <div className={classes.warning}>
                  NOTE: Not recommended for editing active quests. This will
                  LOCK all stats for this user from being updated. Use with
                  caution.
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}

// modified:   src/components/admin/command-center/QuestTimeEditModal/EditModal.js
// modified:   src/components/admin/command-center/quest-tiered/QuestTiered.js
// modified:   src/utils/material_ui/Styles.js
