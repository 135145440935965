import React from 'react';
// import PropTypes from 'prop-types';
// import { isEmpty } from 'lodash';
// import Raven from 'raven-js';

import { Link } from 'react-router';
import './WhatsNoiz.scss';

// import { isAuthenticated } from '../../utils/authChecker';
// import { simpleNotification } from '../../utils/notifications';

class WhatsNoiz extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  UNSAFE_componentWillMount() {

  }

  render() {
    return (
      <section className="WhatsNoiz">
        <section className="WhatsNoiz__container">
          <section className="Hero">
            <h2 className="Hero__title">You're now in.</h2>
            <p className="Hero__description">
              <img src="images/logo-noiz-white.svg" alt="Noiz Logo" /> is a platform where Twitch
            </p>
            <p className="Hero__description offset">
              broadcasters can do two things:
            </p>

            <section className="WhatsNoiz__steps two-column-container">
              <section className="column">
                <div className="noiz-icon clock" />
                <h4>1. Early Access</h4>
                <p>Get exclusive early access to upcoming games in exchange for streaming.</p>
              </section>
              <section className="column">
                <div className="noiz-icon money" />
                <h4>2. Make Money</h4>
                <p>If you stream at a particular date and time, you can get paid based on your live viewers.</p>
              </section>
            </section>

            <Link className="cta-button site-gradient-btn" to="/quests" href="/quests" >OK!</Link>
          </section>


          {/* <section className="whats-noiz">
            <h2 className="whats-noiz__title">New Broadcaster Early Access title coming soon!</h2>
            <section className="whats-noiz__features">
              <section className="feature">
                <figure className="feature__image">
                  <img src="" alt="Early Game Access" />
                </figure>
                <h4 className="feature__title">
                  Get exclusive early access to upcoming games in exchange for streaming.
                </h4>
              </section>
              <section className="feature">
                <figure className="feature__image">
                  <img src="" alt="Lorem Ipsum dolar" />
                </figure>
                <h4 className="feature__title">
                  Make money. If you stream at a particular date and time, you can get paid based on your live viewers.
                  (currently only opened to US broadcasters)
                </h4>
              </section>
            </section>
          </section> */}


        </section>
      </section>);
  }
}

export default WhatsNoiz;
