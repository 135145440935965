import React from 'react';
import Parallax from 'parallax-js';
import { forEach } from 'lodash';
import Icon from './Icon';

const rotateMax = 12;
const scalarThreshold = 80;

class IconParticles extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.iconElements = this.el.querySelectorAll('.animated');
    this.setupParallax();
    this.createIconClasses(this.iconElements);
    requestAnimationFrame(this.update);
  }

  setupParallax = () => {
    this.scenes = [];

    const sceneElements = this.el.querySelectorAll('.parallax-scene');

    forEach(sceneElements, (scene, i) => {
      this.scenes[i] = new Parallax(scene, {
        scalarX: 10,
        scalarY: 10,
      });
      if (scene.classList.contains('sword-container')) {
        this.scene3d = this.scenes[i];
      }
    });
  }

  createIconClasses = (icons) => {
    this.icons = [];
    icons.forEach((item, i) => {
      const icon = new Icon(i, this.iconElements[i], 2.4, this.toggleDim);
      this.icons.push(icon);
    });
  }


  update = () => {
    this.icons.forEach(item => item.setPosition());
    const parallax = this.scene3d;
    const y = ((parallax.velocityX) / scalarThreshold) * rotateMax;
    const x = ((parallax.velocityY) / scalarThreshold) * -rotateMax;
    this.scene3d.element.style.transform = `rotateX(${x}deg) rotateY(${y}deg)`;
    requestAnimationFrame(this.update);
  }

  render() {
    return (
      <div className="Hero__icons-container" ref={(el) => { this.el = el; }}>
        <div className="sword-container parallax-scene">
          <div className="icon swords layer" data-depth="0.04" />
        </div>
        <div className="icon animated ghost" />
        <div className="icon animated potion" />
        <div className="icon animated controller" />
        <div className="icon animated dpad" />
        <div className="icon animated crown" />
        <div className="icon animated scroll" />

        <div className="boxes-background parallax-scene">
          <div className="layer layer1" data-depth="0.02"><div className="box box1" /></div>
          <div className="layer layer2" data-depth="0.04"><div className="box box2" /></div>
          <div className="layer layer3" data-depth="0.08"><div className="box box3" /></div>
          <div className="layer layer4" data-depth="0.06"><div className="box box4" /></div>
          <div className="layer layer5" data-depth="0.10"><div className="box box5" /></div>
          <div className="layer layer6" data-depth="0.01"><div className="box box6" /></div>
          <div className="layer layer7" data-depth="0.02"><div className="box box7" /></div>
        </div>

        <div className="boxes-foreground parallax-scene">
          <div className="layer layer1" data-depth="0.50"><div className="box box1" /></div>
          <div className="layer layer2" data-depth="0.60"><div className="box box2" /></div>
          <div className="layer layer3" data-depth="0.30"><div className="box box3" /></div>
          <div className="layer layer4" data-depth="0.20"><div className="box box4" /></div>
          <div className="layer layer5" data-depth="0.24"><div className="box box5" /></div>
          <div className="layer layer6" data-depth="0.32"><div className="box box6" /></div>
          <div className="layer layer7" data-depth="0.16"><div className="box box7" /></div>
          <div className="layer layer8" data-depth="0.10"><div className="box box8" /></div>
        </div>
      </div>
    );
  }
}

export default IconParticles;
