import anime from 'animejs';


export const fadeIn = (c) => {
  const fade = anime({
    targets: `.${c}`,
    opacity: [0, 1],
    duration: 700,
    easing: 'linear',
  });
  return fade;
};

export const fadeInLeft = (c) => {
  const fade = anime({
    targets: `.${c}`,
    opacity: [0, 1],
    duration: 700,
    easing: 'linear',
  });

  const moveLeft = anime({
    targets: `.${c}`,
    translateX: -100,
    duration: 700,
    direction: 'reverse',
    easing: 'linear',
  });

  return [fade, moveLeft];
};

export const fadeInTop = (c) => {
  const fade = anime({
    targets: `.${c}`,
    opacity: [0, 1],
    duration: 700,
    easing: 'linear',
  });

  const moveDown = anime({
    targets: `.${c}`,
    translateY: -100,
    duration: 700,
    direction: 'reverse',
    easing: 'linear',

  });

  return [fade, moveDown];
};

export const rotateIn = (c) => {
  const fade = anime({
    targets: `.${c}`,
    opacity: [0, 1],
    duration: 700,
    easing: 'linear',
  });

  const shimmy = anime({
    targets: `.${c}`,
    rotateY: 90,
    duration: 700,
    direction: 'reverse',
    easing: 'linear',

  });

  return [fade, shimmy];
};


export const fadeUp = (c) => {
  const fade = anime({
    targets: `.${c}`,
    opacity: [0, 1],
    duration: 700,
    easing: 'linear',
  });

  const moveUp = anime({
    targets: `.${c}`,
    translateY: 100,
    duration: 1400,
    direction: 'reverse',
    easing: 'linear',

  });

  return [fade, moveUp];
};
