/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import { without, filter } from 'lodash';
import classNames from 'classnames';
import moment from 'moment-timezone';
import Countdown from 'react-countdown-now';
import { CSVLink } from 'react-csv';
// import { simpleNotification } from '../../../../utils/notifications';
import './QuestTimedActivity.scss';
import EditModal from '../QuestTimeEditModal/EditModal';
import { paymentVendorProcessingFee } from '../../../../utils/functions';

class QuestTimedActivity extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingElements: [],
      tab: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestTimed(questId, token);
    }
  }

  rounding = number => parseFloat(number).toFixed(2);

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [
          ...showingElements,
          elementId,
        ],
      });
    } else {
      this.setState({
        showingElements: [
          ...without(showingElements, elementId),
        ],
      });
    }
  }

  addZeroes = (param) => {
    const num = param.toString();
    let value = Number(num);
    const res = num.split('.');
    if (res.length === 1 || res[1].length < 3) {
      value = value.toFixed(2);
    }
    return value;
  }

  padWithZeroes = (number, length) => {
    let myString = number.toString();
    while (myString.length < length) {
      myString = `0${myString}`;
    }
    return myString;
  }

  generateFailedRowsHTML = (questTimed) => {
    const { showingElements } = this.state;
    const { isLoading: questTimedIsLoading } = this.props.commandCenter.questTimed;
    const { data: quest } = this.props.commandCenter.quest;
    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;
    if (questTimed.length > 0) {
      return questTimed.filter((item) => {
        // const timeIsUp = moment.tz(item.joinedAt, item.timezone).add(quest.requiredStreamTimeHours, 'hours');
        // const completed = item.timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10);
        // return (!completed && moment.tz(item.timezone).toDate() > timeIsUp.toDate());
        const timeIsUp = moment(quest.endDateTime).tz('America/Los_Angeles');
        const completed = item.timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10);
        return (!completed && moment.tz('America/Los_Angeles').toDate() > timeIsUp.toDate());
      }).map((questTimedItem, index) => {
        // const untilDateTime = moment(questTimedItem.joinedAt).add(quest.requiredStreamTimeHours, 'hours').toDate()
        const untilDateTime = moment(quest.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').toDate();
        if (questTimedItem.user) {
          return (
            <div
              key={questTimedItem.username}
              className={classNames('row toggable', { open: (showingElements.indexOf(questTimedItem.username) !== -1) })}
            >
              <div className={classNames('row-basic')}>
                <div className="table-cell">{index + 1}</div>
                <div className="table-cell">{questTimedItem.user.username}</div>
                {(quest.youtubeAccountRequired) && (
                  <div className="table-cell">
                    <a target="_blank" rel="nofollow noopener noreferrer" href={`https://www.youtube.com/channel/${questTimedItem.username}`}>
                      https://www.youtube.com/channel/{questTimedItem.username}
                    </a>
                  </div>
                )}
                {(quest.twitchAccountRequired) && (
                  <div className="table-cell">
                    <a
                      href={`https://www.twitch.tv/${questTimedItem.username}/videos?filter=archives&sort=time`}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {questTimedItem.username}
                    </a>
                  </div>
                )}
                <div className="table-cell">{moment(questTimedItem.joinedAt).tz('America/Los_Angeles').format('MMMM Do YYYY, h:mm:ss a')}</div>
                <div className="table-cell">
                  {
                    (questTimedItem.timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10)) &&
                    (<section className="row-green">Completed</section>)
                  }
                  {
                    (questTimedItem.timePlaying < parseInt(quest.requiredStreamTimeMinutes, 10)) &&
                    (<Countdown
                      date={untilDateTime}
                      daysInHours
                      renderer={({
                        hours,
                        minutes,
                        seconds,
                        completed,
                      }) => {
                        if (completed) {
                          return (
                            <section className="row-red">
                              Failed
                            </section>
                          );
                        }
                        return <section>{hours}:{minutes}:{seconds} left to stream</section>;
                      }}
                    />)
                  }
                </div>
                <div className="table-cell">{questTimedItem.timePlaying}</div>
                <div className="table-cell">{parseInt(questTimedItem.AVGViewers, 10)}</div>
              </div>
            </div>
          );
        }
        return (<React.Fragment />);
      });
    } else if (questTimedIsLoading) {
      return (<div className="NoQuestSale">Loading...</div>);
    }
    return (<div className="NoQuestSale">No activity on this Quest.</div>);
  }

  generateRowsHTML = (questTimed) => {
    const { showingElements } = this.state;
    const { isLoading: questTimedIsLoading } = this.props.commandCenter.questTimed;
    const { data: quest } = this.props.commandCenter.quest;
    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;
    if (questTimed.length > 0) {
      return questTimed.map((questTimedItem, index) => {
        if (questTimedItem.user) {
          // const untilDateTime = moment(quest.endDateTime).tz('America/Los_Angeles').toDate();
          const untilDateTime = moment(quest.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').toDate();
          return (
            <div
              key={questTimedItem.username}
              className={classNames('row toggable', { open: (showingElements.indexOf(questTimedItem.username) !== -1) })}
            >
              <div
                className={classNames('row-basic')}
              >
                <div className="table-cell">{index + 1}</div>
                <div className="table-cell">{questTimedItem.user.username}</div>
                {(quest.youtubeAccountRequired) && (
                  <div className="table-cell" style={{ alignItems: 'flex-start' }}>
                    <a target="_blank" rel="nofollow noopener noreferrer" className="small-btn open" href={`https://www.youtube.com/channel/${questTimedItem.username}`}>
                      Go To Channel
                    </a>
                  </div>
                )}
                {(quest.twitchAccountRequired) && (
                  <div className="table-cell">
                    <a
                      href={`https://www.twitch.tv/${questTimedItem.username}/videos?filter=archives&sort=time`}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {questTimedItem.username}
                    </a>
                  </div>
                )}
                <div className="table-cell">{moment(questTimedItem.joinedAt).tz('America/Los_Angeles').format('MMMM Do YYYY, h:mm:ss a')}</div>
                <div className="table-cell">
                  {
                    (questTimedItem.timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10)) &&
                    (<section className="row-green">Completed</section>)
                  }
                  {
                    (questTimedItem.timePlaying < parseInt(quest.requiredStreamTimeMinutes, 10)) &&
                    (<Countdown
                      date={untilDateTime}
                      daysInHours
                      renderer={({
                        hours,
                        minutes,
                        seconds,
                        completed,
                      }) => {
                        if (completed) {
                          return (
                            <section className="row-red">
                              Failed
                            </section>
                          );
                        }
                        return <section>{hours}:{minutes}:{seconds} left to stream</section>;
                      }}
                    />)
                  }
                </div>
                <div className="table-cell">{questTimedItem.timePlaying} &nbsp; <EditModal {...this.props} currentUser={questTimedItem} rehydrateCallback={this.props.getCCQuestTimed} /> </div>
                <div className="table-cell">{parseInt(questTimedItem.AVGViewers, 10)}</div>
              </div>
            </div>
          );
        }
        return (<React.Fragment />);
      });
    } else if (questTimedIsLoading) {
      return (<div className="NoQuestSale">Loading...</div>);
    }
    return (<div className="NoQuestSale">No activity on this Quest.</div>);
  }

  changeTab = (value) => {
    this.setState({
      tab: value,
    });
  }

  render() {
    const { gameId } = this.props.router.params;
    const { data: questTimedActivity } = this.props.commandCenter.questTimed;
    const { data: quest } = this.props.commandCenter.quest;

    let totalMinutesStreamed = 0;
    let totalHoursStreamed = 0;
    let totalAverageViewers = 0;
    let totalConcurrentViewers = 0;
    let totalSuccesful = 0;
    let totalFails = 0;
    let percentageCompletedQuest = 0;
    let totalEarning = 0;
    let totalProcessingFee = 0;
    let totalToPay = 0;

    const headersCSV = [
      { label: 'Quest Name', key: 'questName' },
      { label: 'Noiz User Name', key: 'noizUsername' },
      { label: 'Twitch User Name', key: 'twitchUsername' },
      { label: 'PayPal Email', key: 'paymentEmail' },
      { label: 'Country', key: 'country' },
      { label: 'Average Viewers', key: 'averageViewers' },
      { label: 'Peak Viewers', key: 'peakViewers' },
      { label: 'Hours Watched', key: 'hoursWatched' },
      { label: 'Total Time Played', key: 'timePlaying' },
      { label: 'Completed', key: 'completed' },
      { label: 'Earnings', key: 'earnings' },
      { label: 'Processing Fee', key: 'processingFee' },
      { label: 'To Pay', key: 'toPay' },
      { label: 'Key', key: 'gameKey' },
    ];

    const dataCSV = [];

    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;
    const timeIsUp = moment(quest.endDateTime).add(hoursOffset, 'hours').tz('America/Los_Angeles');
    const questEnded = moment.tz('America/Los_Angeles').toDate() > timeIsUp.toDate();

    for (let i = 0; i < questTimedActivity.length; i += 1) {
      if (questTimedActivity[i].user) {
        const completed = (questTimedActivity[i].timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10));
        dataCSV.push({
          questName: quest.title,
          noizUsername: questTimedActivity[i].user.username,
          twitchUsername: questTimedActivity[i].user.connectedAccounts.twitch.username,
          paymentEmail: questTimedActivity[i].user.paymentEmail,
          country: questTimedActivity[i].user.country,
          averageViewers: parseInt(questTimedActivity[i].AVGViewers, 10), // change AVGViewers to averageViewers in the future
          peakViewers: parseInt(questTimedActivity[i].peakViewers, 10),
          hoursWatched: parseFloat(questTimedActivity[i].minutesWatched / 60).toFixed(2),
          timePlaying: questTimedActivity[i].timePlaying,
          completed: completed ? 'completed' : ' - - -',
          earnings: (questTimedActivity[i].timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10)) ? this.rounding(quest.paymentAmount) : '0',
          processingFee: (questTimedActivity[i].timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10)) ? this.rounding(paymentVendorProcessingFee(quest.paymentAmount)) : '0',
          toPay: (questTimedActivity[i].timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10)) ? this.rounding(quest.paymentAmount - (paymentVendorProcessingFee(quest.paymentAmount))) : '0',
          gameKey: questTimedActivity[i].key,
        });
        totalMinutesStreamed += questTimedActivity[i].timePlaying;
        if (completed) {
          totalEarning += quest.paymentAmount;
          totalProcessingFee += (paymentVendorProcessingFee(quest.paymentAmount));
          totalToPay += (quest.paymentAmount - (paymentVendorProcessingFee(quest.paymentAmount)));
          totalSuccesful += 1;
          totalAverageViewers += questTimedActivity[i].AVGViewers;
        } else {
          totalFails = questEnded ? (totalFails + 1) : totalFails;
        }
        totalConcurrentViewers += questTimedActivity[i].AVGViewers;
      }
    }

    totalHoursStreamed = this.rounding(totalMinutesStreamed / 60);
    percentageCompletedQuest = (totalSuccesful === 0) ? '0' : this.rounding((totalSuccesful / questTimedActivity.length) * 100);
    totalAverageViewers = Math.floor(totalAverageViewers / totalSuccesful);
    totalConcurrentViewers = Math.floor(totalConcurrentViewers);
    totalEarning = this.rounding(totalEarning);
    totalProcessingFee = this.rounding(totalProcessingFee);
    totalToPay = this.rounding(totalToPay);

    return (
      <section className="CCQuestTimedActivity">
        <Link
          to={`/admin/command-center/${gameId}/quests`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests`}
        >
          <button className="CCQuests__back--btn">Back</button>
        </Link>
        <h2>Title: { quest.title }</h2>
        <h2>Quest Users: <span>{filter(questTimedActivity, o => o.user).length}</span></h2>
        <div className="CCQuestTimedActivity__tabs">
          <div
            className={classNames('CCQuestTimedActivity__tab', { active: this.state.tab === 1 })}
            onClick={this.changeTab.bind(this, 1)}
            aria-hidden
          >
            Summary
          </div>
          <div
            className={classNames('CCQuestTimedActivity__tab', { active: this.state.tab === 2 })}
            onClick={this.changeTab.bind(this, 2)}
            aria-hidden
          >
            Details
          </div>
        </div>
        {
          (this.state.tab === 1)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__body-wrapper">
              <section className="ToggleTable__body">
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Hours Streamed <i className="fa fa-info-circle" />
                      <div className="tooltiptext">The sum of all hours streamed by all participants.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      { totalHoursStreamed || '0' }
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Average Viewers - Completed <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the average of the average viewers for the users that completed this quest.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      { totalAverageViewers || '0' }
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Average Viewers <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the total average viewers from each user's daily Average Viewer count.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      { totalConcurrentViewers || '0' }
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Successful <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the count of all times a user completed this quest, one time or daily.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot green">
                      { totalSuccesful }
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Fails <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is a count of all times a user failed this quest, one time or daily.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot red">
                      { totalFails }
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      % Completed Quest <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Percentage of users that completed this quest.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      { (percentageCompletedQuest) || '0' }%
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row" style={{ display: (quest.paymentAmount > 0) ? 'flex' : 'none' }}>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Payouts <i className="fa fa-info-circle" />
                      <div className="tooltiptext">To-date cumulative earnings for all successful quest users.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${ totalEarning }
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Processing Fee <i className="fa fa-info-circle" />
                      <div className="tooltiptext">3% of total payouts.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${ totalProcessingFee }
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total to Pay <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Total Payouts - Total Processing Fee.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${ totalToPay }
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        }
        {
          (this.state.tab === 2)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">Details</h3>
              <CSVLink data={dataCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}.csv` : 'report.csv'}>
                <i className="fa fa-download" />
              </CSVLink>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  {(quest.youtubeAccountRequired) && (
                    <div className="table-cell headline">Youtube</div>
                  )}
                  {(quest.twitchAccountRequired) && (
                    <div className="table-cell headline">Twitch</div>
                  )}
                  <div className="table-cell headline">Start Date</div>
                  <div className="table-cell headline">Time Left</div>
                  <div className="table-cell headline">Minutes Streamed</div>
                  <div className="table-cell headline">Average Viewers</div>
                </div>
                { this.generateRowsHTML(questTimedActivity) }
              </section>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  {(quest.youtubeAccountRequired) && (
                    <div className="table-cell headline">Youtube</div>
                  )}
                  {(quest.twitchAccountRequired) && (
                    <div className="table-cell headline">Twitch</div>
                  )}
                  <div className="table-cell headline">Start Date</div>
                  <div className="table-cell headline">Time Left</div>
                  <div className="table-cell headline">Minutes Streamed</div>
                  <div className="table-cell headline">Average Viewers</div>
                </div>
                { this.generateFailedRowsHTML(questTimedActivity) }
              </section>
            </section>
          </section>
        }
      </section>
    );
  }
}

QuestTimedActivity.propTypes = {

};

export default QuestTimedActivity;
