import React, { useEffect } from 'react';

// import PortalSubscription from './PortalSubscription';
import PortalLeft from './PortalLeft/PortalLeft';
import PortalRight from './PortalRight/PortalRight';
import PortalFull from './PortalFull/PortalFull';
import { getQuestRequirements, getUserAvatar } from '../../../utils/functions';

import './PortalMainView.scss';

const PortalMainView = (props) => {
  const {
    brandUser,
    getBrandGame,
    getBrandQuest,
    token,
    view,
    view: { component },
  } = props;

  useEffect(() => {
    getBrandGame({ statusGame: 'all' }, token);
    getBrandQuest({ statusQuest: 'all', statusGame: 'all', pageSize: 0 }, token);
  }, []);

  // --- Left Side ---
  const campaigns = brandUser?.campaigns?.data || [];
  const products = brandUser?.games?.data?.games || [];
  const productsLoading = brandUser?.games?.isLoading || false;
  const quests = brandUser?.quests?.data?.quests || [];
  const questsLoading = brandUser?.quests?.isLoading || false;
  const subscriptionQuests = brandUser?.subscriptionQuests?.data?.subscriptionQuests || [];
  // --- End Left Side ---

  // --- Right Side ---
  const today = new Date();
  const activeQuests = quests.filter(({ status, startDateTime, endDateTime }) => status === 'active' && new Date(startDateTime) <= today && new Date(endDateTime) >= today);

  const applicants = quests.reduce((acc, quest) => {
    if (quest?.userQuests?.length) {
      const userQuests = quest.userQuests.filter(uq => uq.status === 'pending').map(uq => ({
        profile_img_url: getUserAvatar(uq?.user),
        displayName: uq?.user?.displayName,
        username: uq?.user?.username,
        title: quest?.title,
        createdAt: uq?.createdAt,
        questId: quest?.id,
        userId: uq?.user?.id,
        userQuestId: uq?.id,
      }));
      return [...acc, ...userQuests];
    }

    return acc;
  }, []) || [];

  const deliverables = quests.reduce((acc, quest) => {
    const questRequirements = getQuestRequirements(quest, true);

    if (questRequirements?.length && quest.userQuests?.length) {
      const questRequirementIds = questRequirements.map(qr => qr.id);
      const userQuests = quest?.userQuests?.filter(uq => uq.status === 'approved' || uq.status === 'normal')?.reduce((acc2, uq) => {
        const userQuestRequirements = uq?.requirements?.filter(ur => questRequirementIds.includes(ur.questReqId))?.map(ur => ({
          profile_img_url: getUserAvatar(uq?.user),
          displayName: uq?.user?.displayName,
          username: uq?.user?.username,
          createdAt: ur?.submissionDate,
          questId: quest?.id,
          userId: uq?.user?.id,
          userQuestId: uq?.id,
          questRequirementId: ur?.questReqId,
          title: ur?.title,
          note: ur?.note,
          type: ur?.type,
          status: ur?.status,
          content: ur?.content,
          questTimedActivity: uq?.questTimedActivity,
        }));

        return [...acc2, ...userQuestRequirements];
      }, []) || [];

      return [...acc, ...userQuests];
    }

    return acc;
  }, []) || [];
  // --- End Right Side ---

  const isFullView = component === 'report';

  return (
    <div className="Portal__MainView">
      {/* {stubData.plan && <PortalSubscription plan={stubData.plan.plan} url={stubData.plan.url} />} */}
      {!isFullView &&
        <>
          <PortalLeft
            tab={view?.tab}
            view={view}
            products={products}
            productsLoading={productsLoading}
            quests={quests}
            questsLoading={questsLoading}
            subscriptionQuests={subscriptionQuests}
            deliverables={deliverables}
          />
          <PortalRight
            tab={view?.tab}
            view={view}
            campaigns={campaigns}
            products={products}
            quests={quests}
            activeQuests={activeQuests}
            applicants={applicants}
            deliverables={deliverables}
          />
        </>
      }
      {isFullView && <PortalFull
        tab={view?.tab}
        view={view}
      // campaigns={campaigns}
      // products={products}
      // quests={quests}
      // activeQuests={activeQuests}
      // applicants={applicants}
      // deliverables={deliverables}
      />}
    </div>
  )
};

export default PortalMainView;
