import React from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';

import CampaignSelector from '../main/brand/CampaignSelector';
import { getMyQuestsFiltered } from '../../utils/functions';

import './NavMenu.scss';

function NavMenu({
  auth,
  brandUser,
  isBrandUser,
  isClientPortal,
  router,
  toggleMyQuestsMenu,
  user,
  visible,
}) {
  if (!visible) return null;
  const { isLoadingMyQuests, myQuestsFiltered, requirementsOutstanding } = getMyQuestsFiltered(user);
  const myQuestsCount = (myQuestsFiltered.length > 0 && !isLoadingMyQuests) && myQuestsFiltered.length;

  return (
    <nav className="navigation">
      <ul className="navigation-left">
        {!isBrandUser && (
          <>
            <li>
              <Link
                activeClassName="active"
                to="/quests"
              >
                Quests
              </Link>
            </li>
            <li>
              <Link
                activeClassName="active"
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </>
        )}
        {((auth.user.role === 'admin') && isClientPortal) && (
          <CampaignSelector
            campaign={brandUser.campaign.data}
            campaigns={brandUser.campaigns}
            router={router}
          />
        )}
      </ul>
      <ul className="navigation-right">
        {!isBrandUser && (
          <li>
            <button
              onClick={toggleMyQuestsMenu}
              className="custom-btn"
              role="link"
            >
              <i className="noiz-scroll" />
              <span>Quest Book&nbsp;{myQuestsCount}</span>
            </button>
            {(requirementsOutstanding > 0) && (
              <>
                <ReactTooltip />
                <p data-tip="You have quests that require additional action. Click here for more information!">
                  <i
                    className="fa fa-exclamation-circle"
                    onClick={toggleMyQuestsMenu}
                    style={{ color: '#dd4a41', margin: '0px 20px' }}
                  />
                </p>
              </>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
}

export default NavMenu;
