import React from 'react';
import NoizLogo from '../logo/noiz-logo.png';
import Invader from '../images/gifs/invade.gif';
import './MaintenanceMode.scss';

const MaintenanceMode = () => (
  <div className="maintenance-mode">
    <video autoPlay muted loop id="maintenance-mode-myVideo">
      <source src="https://http-error-pages.s3.us-west-1.amazonaws.com/spaceBack.5f74cb3c.mp4" type="video/mp4" />
    </video>
    <div className="maintenance-mode-content">
      <img alt="spaceman" className="maintenance-mode-spaceman" src={Invader} />
      <div className="maintenance-mode-noiz-logo" >
        <img alt="Noiz" src={NoizLogo} />
      </div>
      <h1>Down for scheduled maintenance</h1>
      <p>Don't worry, we're still tracking your streams. We'll be back online soon.</p>
      <p>In the meantime, check our <a className="maintenance-mode-link" href="https://discord.com/invite/N2PrJxK" target="_blank" rel="noopener noreferrer">Discord</a> & <a className="maintenance-mode-link" href="https://twitter.com/noizgg" target="_blank" rel="noopener noreferrer">Twitter</a> for updates!</p>
    </div>
  </div>
);

export default MaintenanceMode;
