import React from 'react';
import { Link } from 'react-router';
import 'react-id-swiper/src/styles/css/swiper.css';
import './Checkout.scss';

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { data: game } = this.props.config.sfgame;
    const { questSlug, username } = this.props.params;
    let backUrl = '';
    if (questSlug && username) {
      backUrl = `/buy/${game.slug}/${questSlug}/${username}`;
    } else {
      backUrl = `/buy/${game.slug}`;
    }
    return (
      <section className="SFGame__checkout">
        <section className="ModalMaster">
          <section className="Modal Modal__store__game__success active">
            <Link
              to={backUrl}
              href={backUrl}
              className="Modal__layer"
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <Link
                className="Modal__close-btn"
                to={backUrl}
                href={backUrl}
                role="button"
                aria-hidden
              />
              <section className="Modal__success__content">
                <div className="Modal__success__image" />
                <div className="Modal__success__title">
                  Success!
                </div>
                <div className="Modal__success__description">
                  Check your email with your new game key. Enjoy.
                </div>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

Success.propTypes = {
};

export default Success;
