import React, { useEffect } from 'react';

import { getPaymentAmount } from '../../utils/functions';
import { addRedirectFromLogin } from '../../utils/util';
// import { getStreamerBankAccounts } from '../../utils/apiv2';

const defaultButtonBehavior = () => console.log('Default Button Behavior');

const toConnectAccounts = () => window.location.assign('/connect-accounts');
const toMyAccount = () => window.location.assign('/my-account');
const toMyQuest = questId => window.location.assign(`/my-quests/${questId}`);

const StatusButton = (props) => {
  const {
    onApply = null,
    buttonStylings = {},
    quest = {},
    user = {},
    userQuest = {},
    userQuestIsLoading = false,
    location = {},
    router = {},
  } = props;

  // const [paymentMethods, setPaymentMethods] = useState([]);
  const paymentMethods = [];

  // const getPaymentMethods = async () => {
  //   const {
  //     data: { data: accountInfo },
  //   } = (await getStreamerBankAccounts()) || { data: [] };
  //   if (accountInfo) setPaymentMethods(accountInfo);
  // };

  // write a drop down input component to pick a country

  useEffect(() => {
    // getPaymentMethods();
  }, []);

  const {
    closeRegistrationDate = null,
    id: questId = '',
    memberTiers,
    openRegistrationDate = null,
    platforms = [],
    status = 'disabled',
    twitchAccountRequired = false,
    viewerTiers,
  } = quest;

  const {
    connectedAccounts: {
      twitch: { id: twitchId, username: twitchUsername },
    },
    displayName,
    username,
  } = user;

  const {
    status: userQuestStatus = null,
  } = userQuest;

  const brandQuest = !!platforms.length && platforms[0].platform === 'Brand';
  const stripeLinked = !!paymentMethods?.length && paymentMethods[0]?.details_submitted && paymentMethods[0]?.payouts_enabled;
  let buttonClass = 'CTAButton__red disabled';
  let buttonDisabled = true;
  let buttonFunction = onApply || defaultButtonBehavior;
  let buttonText = 'Button';


  if (status !== 'active') { // Check if Quest is Currently Active
    buttonText = `Quest is ${status}`;
  } else if (closeRegistrationDate && openRegistrationDate) { // Check if Quest Dates are there
    const today = new Date();
    const dateBegin = new Date(openRegistrationDate);
    const dateEnd = new Date(closeRegistrationDate);

    if (today < dateBegin || today > dateEnd) {
      // Check if Quest is Currently Active
      buttonClass = 'CTAButton__lightGrey disabled';
      buttonText = today < dateBegin ? 'Quest Has Not Started' : 'Quest Has Ended';
    } else {
      buttonClass = 'CTAButton__purple';
      buttonDisabled = false;

      if (!displayName && !username) {
        // Check if User is Logged In
        buttonText = 'Sign In';
        buttonFunction = () => addRedirectFromLogin(router, location.pathname);
      } else if (twitchAccountRequired && !twitchId && !twitchUsername) {
        buttonText = 'Twitch Account Required';
        buttonFunction = toConnectAccounts;
      } else if (!userQuestStatus) {
        // Check if User has Applied for Quest
        const { paymentAmount } = getPaymentAmount(memberTiers, user, viewerTiers, userQuest);

        if ((!brandQuest || (brandQuest && stripeLinked)) && paymentAmount !== Number.MIN_SAFE_INTEGER) {
          buttonText = 'Apply Now';
          buttonFunction = onApply || defaultButtonBehavior;
        } else if (brandQuest && !stripeLinked) {
          // Check if Brand Quest And No Stripe Account
          buttonClass = 'CTAButton__cyan';
          buttonFunction = toMyAccount;
          buttonText = !!paymentMethods.length && (!paymentMethods[0].stripeAccountRaw?.details_submitted || !paymentMethods[0].stripeAccountRaw?.payouts_enabled) ? 'Check Your Stripe Details' : 'Link Stripe Account to Apply';
        } else {
          buttonClass = 'CTAButton__red ineligible';
          buttonDisabled = true;
          buttonFunction = defaultButtonBehavior;
          buttonText = 'Ineligible';
        }
      } else if (userQuestStatus) {
        // Check Status of Application
        switch (userQuestStatus) {
          case 'approved':
          case 'normal':
            buttonFunction = () => toMyQuest(questId);
            buttonText = 'See MyQuest';
            break;
          case 'pending':
            buttonClass = 'CTAButton__lightGrey disabled';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Pending';
            break;
          case 'declined':
          case 'rejected':
            buttonClass = 'CTAButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Ineligible';
            break;
          case 'leftQuest':
            buttonClass = 'CTAButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Left Quest';
            break;
          default:
            buttonClass = 'CTAButton__red ineligible';
            buttonDisabled = true;
            buttonFunction = defaultButtonBehavior;
            buttonText = 'Error';
            break;
        }
      }
    }
  } else {
    buttonText = 'Error';
  }

  const spinner = <i className="fa fa-spinner fa-spin fa-3x fa-fw" />;

  return (
    <button
      className={buttonClass}
      disabled={buttonDisabled}
      onClick={buttonFunction}
      styles={buttonStylings}
    >
      {userQuestIsLoading ? spinner : buttonText}
    </button>
  );
};

export default StatusButton;
