import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  // Paper,
} from '@material-ui/core';

import AdminTabs from '../util/tabs/AdminTabs';
import Loading from '../../../global/components/loading/Loading';

import { getContacts } from '../../../utils/apiv2';

import './Contacts.scss';

const Contacts = props => {
  const headCells = [
    {
      id: "createdAt",
      label: "Created Date",
      minWidth: 170,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 170,
    },
    {
      id: "company",
      label: "Company",
      minWidth: 170,
    },
    {
      id: "product",
      label: "Product",
      minWidth: 170,
    },
    {
      id: "campaignGoals",
      label: "Campaign Goals",
      minWidth: 170,
    },
    {
      id: "additionalNotes",
      label: "Additional Notes",
      minWidth: 170,
    },
    {
      id: "promoCode",
      label: "Promo Code",
      minWidth: 170,
    },
    {
      id: "finished",
      label: "Finished",
      minWidth: 170,
    },
    {
      id: "followUp",
      label: "Follow Up Email",
      minWidth: 170,
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getContacts(rowsPerPage, page).then((res) => {
      const { data: { data: { contacts, total } } } = res;
      setRows(contacts);
      setTotal(total);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    getContacts(rowsPerPage, page).then((res) => {
      const { data: { data: { contacts, total } } } = res;
      setRows(contacts);
      setTotal(total);
    }).finally(() => {
      setLoading(false);
    });
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="contacts-container">
      <AdminTabs {...props} />
      <h1>Contacts</h1>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headCells.map((hc) => (
                <TableCell
                  key={hc.id}
                  align={hc.align}
                  style={{ minWidth: hc.minWidth }}
                >
                  {hc.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.email}>
                <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                <TableCell component="th" scope="row">
                  {row.emailDisplay || row.email}
                </TableCell>
                <TableCell>{row.companyName}</TableCell>
                <TableCell>{row.productName}</TableCell>
                <TableCell>{row.campaignGoals}</TableCell>
                <TableCell>{row.additionalNotes}</TableCell>
                <TableCell>{row.promoCode}</TableCell>
                <TableCell align='center'>{row.finished ? '✅' : '❌'}</TableCell>
                <TableCell align='center'>{row.finished && !row.followUpEmailSent ? '-' : row.followUpEmailSent ? '✅' : '❌'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  ) 
};

export default Contacts;
