/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { numberWithCommas } from '../../../utils/functions';

import languages from '../../../utils/languages';

import { Image, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import logo from '../../logo/noiz-logo.png';
import pdfBackground from '../../images/pdf-background.jpg';
import avatarBg from '../../images/white-bg.png';
import purpleBar from '../../images/purple-bar.png';
import languageIcon from '../../images/language-icon@3x.png';
import clockIcon from '../../images/clock.png';
import eyeIcon from '../../images/eye.png';
import globeIcon from '../../images/globe.png';

import statsLine from '../../images/userPDF/30dayline.png';
import reachLine from '../../images/userPDF/reachline.png';

import rectangle from '../../images/userPDF/rectangle.png';
import rectangleTall from '../../images/userPDF/rectangleTall.png';

import twitterIcon from '../../images/userPDF/twitter.png';
import twitchIcon from '../../images/userPDF/twitch.png';

import Roboto from '../../fonts/Roboto/Roboto-Regular.ttf';
import RobotoBold from '../../fonts/Roboto/Roboto-Bold.ttf';
import RobotoLight from '../../fonts/Roboto/Roboto-Light.ttf';

import { getEmojiImage } from '../../../utils/getEmojiImage.js';

// Utils
import {
  getCorrectTwitchAverages,
} from '../../../utils/functions';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: Roboto,
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
    {
      src: RobotoLight,
      fontWeight: 'light',
    },
  ],
});

const styles = StyleSheet.create({

  // Avatar and name
  username: {
    color: '#ffffff', fontSize: 48, fontWeight: 'bold', left: '380px', top: '29px', position: 'absolute', display: 'block',
  },
  avatar: {
    width: '300px', height: '300px', position: 'absolute', left: '35px', top: '35px', display: 'block',
  },
  avatarBg: {
    width: '300px', height: '300px', position: 'absolute', left: '43px', top: '43px', display: 'block',
  },

  // General labels and views
  accvLabel: {
    color: '#ffffff', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 19,
  },
  accvText: {
    fontSize: 19, fontFamily: 'Roboto', fontWeight: 'bold', letterSpacing: 0.95, marginTop: '16px', marginBottom: '15px',
  },
  bigNumContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
  bigNum: {
    fontSize: 52, fontFamily: 'Roboto', fontWeight: 'bold', color: 'white', marginTop: '5px',
  },

  // Lines
  statsLine: {
    width: '602px', height: '1px', top: '233px', left: '380px', position: 'absolute', display: 'block',
  },
  reachLine: {
    width: '947px', height: '1px', top: '443px', left: '35px', position: 'absolute', display: 'block',
  },

  // Icons
  accvIcon: {
    width: '18px', height: '12px', padding: 1, marginRight: 10,
  },
  clockIcon: {
    width: '17px', height: '17px', padding: 1, marginRight: 10,
  },
  globeIcon: {
    width: '17px', height: '17px', padding: 1, marginRight: 10,
  },
  twitchTwitterIcon: { width: '24px', height: '24px', marginRight: 10 },
  languageIcon: {
    width: '25px', height: '25px', display: 'flex', flexDirection: 'row', marginRight: 16,
  },
  countryIcon: { display: 'flex', flexDirection: 'row', marginRight: 16 },

  // Top (purple) bar
  topBarContainer: {
    left: '380px', top: '121px', position: 'absolute', display: 'flex', flexDirection: 'row', paddingBottom: '16px',
  },
  topBar: {
    color: 'white', fontSize: 20, fontWeight: 'bold', display: 'flex', flexDirection: 'row',
  },
  topBar2: {
    color: 'white', fontSize: 20, fontWeight: 'bold', display: 'flex', flexDirection: 'row',
  },
  purpleBar: {
    height: '56px', width: '1280px', position: 'absolute', top: '105px',
  },
  userCountry: { marginRight: '48px' },

  // Backgrounds
  totalReachViewSm: {
    position: 'absolute', width: '291px', height: '137px', left: '37px', top: '479px',
  },
  totalReachViewBg: {
    position: 'absolute', width: '445px', height: '137px', left: '37px', top: '479px',
  },

  twitchReachViewSm: {
    position: 'absolute', width: '291px', height: '137px', left: '363px', top: '479px',
  },
  twitchReachViewBg: {
    position: 'absolute', width: '444px', height: '137px', left: '517px', top: '479px',
  },

  twitterReachViewBg: {
    position: 'absolute', width: '291px', height: '137px', left: '689px', top: '479px',
  },

  // Reach boxes
  reachLabel: {
    color: '#ffffff', fontSize: 19, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
  },
  reachView: {
    width: '943px', height: '137px', position: 'absolute', left: '37px', top: '479px', display: 'flex', flexDirection: 'row',
  },

  totalReachView: {
    border: '1pt', borderColor: '#83858c', borderRadius: '12pt', minWidth: '291px', marginRight: '17px', height: '137px', display: 'flex', flexGrow: 1, position: 'relative',
  },
  twitchReachView: {
    border: '1pt', borderColor: '#83858c', borderRadius: '12pt', minWidth: '291px', marginRight: '17px', marginLeft: '17px', height: '137px', display: 'flex', flexGrow: 1,
  },
  twitterReachView: {
    border: '1pt', borderColor: '#83858c', borderRadius: '12pt', minWidth: '291px', marginLeft: '17px', height: '137px', display: 'flex', flexGrow: 1,
  },

  // 30 Day Stats
  statsBar1: {
    color: '#83858c', fontSize: 30, fontWeight: 'light', fontFamily: 'Roboto', left: '380px', top: '190px', position: 'absolute', display: 'block', borderBottom: '1px solid red',
  },
  statsBar2: {
    color: '#83858c', fontSize: 30, fontWeight: 'light', fontFamily: 'Roboto', left: '35px', top: '401px', position: 'absolute', display: 'block',
  },

  accvView: {
    border: '1pt', borderColor: '#83858c', borderRadius: '12pt', width: '283px', height: '137px', position: 'absolute', left: '380px', top: '269px',
  },
  accvViewBg: {
    width: '283px', height: '137px', position: 'absolute', left: '380px', top: '269px',
  },

  minsView: {
    border: '1pt', borderColor: '#83858c', borderRadius: '12pt', width: '283px', height: '137px', position: 'absolute', left: '698px', top: '269px',
  },
  minsViewBg: {
    width: '283px', height: '137px', position: 'absolute', left: '698px', top: '269px',
  },

  // Top games
  mainArt: {
    width: '231px', position: 'absolute', top: '35px', right: '35px', display: 'block',
  },
  topGame: {
    fontSize: 20, color: '#ffffff', display: 'flex', flexDirection: 'row', marginBottom: '16px', alignItems: 'center',
  },
  smallArt: {
    width: '30px', height: '40px', marginLeft: '30px', marginRight: '19px',
  },
  topGamesPlayedText: {
    fontSize: 19, fontFamily: 'Roboto', fontWeight: 'bold', marginTop: '18px', marginBottom: '21px', marginLeft: 12, alignItems: 'center', letterSpacing: 0.95,
  },
  gameName: { fontFamily: 'Roboto', fontWeight: 'bold', maxWidth: '150px' },
  gameNameSmall: {
    fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, maxWidth: '140px', maxHeight: '50px',
  },
  topGamesView: {
    border: '1pt', borderColor: '#83858c', borderRadius: '12pt', width: '230px', height: '238px', display: 'flex', position: 'absolute', flexDirection: 'column', top: '378px', right: '35px',
  },
  topGamesViewBg: {
    width: '230px', height: '238px', position: 'absolute', top: '378px', right: '35px',
  },

  // General background stuff
  disclaimer: {
    color: '#83858c', fontSize: 14, fontWeight: '200', bottom: '33px', display: 'block', textAlign: 'center', position: 'absolute',
  },
  background: {
    position: 'absolute', minWidth: '100%', minHeight: '100%', width: '100%', height: '100%', display: 'block',
  },
  logo: {
    width: '77px', bottom: '35px', left: '35px', position: 'absolute',
  },
});

class UserPDFModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  getUsername(user) {
    let username = '(no username)';

    if (user.connectedAccounts && user.connectedAccounts.twitch) {
      username = user.connectedAccounts.twitch.username;
    } else if (user.username) {
      username = user.username;
    }

    return username;
  }

  getPage(user) {
    const {
      country, language, userGame30, ipCountry,
    } = user;

    // Avatar
    let avatar = false;
    if (user.connectedAccounts.twitch) {
      if (user.connectedAccounts.twitch.profile_image_url) avatar = user.connectedAccounts.twitch.profile_image_url;
      else if (user.connectedAccounts.twitch.avatar) avatar = user.connectedAccounts.twitch.avatar;
    }

    // Country
    let userCountry = '';
    if (country && country !== '') userCountry = country;
    else if (ipCountry && ipCountry !== '') userCountry = ipCountry;

    const countryImage = getEmojiImage(userCountry);
    const username = this.getUsername(user);

    // Game data
    let userGame30Sorted = userGame30.sort((a, b) => b.timePlaying - a.timePlaying);
    userGame30Sorted = userGame30Sorted.length > 3 ? userGame30Sorted.slice(0, 3) : userGame30Sorted;

    // 30 day stats
    const avgData = getCorrectTwitchAverages(user, false, 30);
    const { correctAverage } = avgData;

    let totalTimePlaying = 0;
    for (let i = 0; i < userGame30.length; i++) {
      totalTimePlaying += parseInt(userGame30[i].timePlaying);
    }

    const viewerMinWatched = parseFloat(totalTimePlaying * correctAverage).toFixed(0);

    // Reach stats
    let twitterFollowers = 0;
    if (user.connectedAccounts.twitter && user.connectedAccounts.twitter.id !== null) {
      twitterFollowers = user.connectedAccounts.twitter.followers_count;
    }

    let twitchFollowers = 'n/a';
    if (user.connectedAccounts.twitch && user.connectedAccounts.twitch.id !== null) {
      twitchFollowers = user.connectedAccounts.twitch.followers;
    }

    // Flexible styling if twitter is not connected
    const totalReach = twitchFollowers + twitterFollowers;
    const totalReachStyle = twitterFollowers ? styles.totalReachViewSm : styles.totalReachViewBg;
    const twitchReachStyle = twitterFollowers ? styles.twitchReachViewSm : styles.twitchReachViewBg;

    const game1Name = userGame30Sorted[0] && userGame30Sorted[0].gameString && userGame30Sorted[0].box_art ? userGame30Sorted[0].gameString : false;
    const game2Name = userGame30Sorted[1] && userGame30Sorted[1].gameString && userGame30Sorted[1].box_art ? userGame30Sorted[1].gameString : false;
    const game3Name = userGame30Sorted[2] && userGame30Sorted[2].gameString && userGame30Sorted[2].box_art ? userGame30Sorted[2].gameString : false;

    const game1Style = game1Name && game1Name.length > 15 ? styles.gameNameSmall : styles.gameName;
    const game2Style = game2Name && game2Name.length > 15 ? styles.gameNameSmall : styles.gameName;
    const game3Style = game3Name && game3Name.length > 15 ? styles.gameNameSmall : styles.gameName;

    return (
      <Page size={[720, 1280]} orientation="landscape">
        <Image src={pdfBackground} style={styles.background} />
        <Image src={logo} style={styles.logo} />
        <Image src={purpleBar} style={styles.purpleBar} />
        <Image src={avatarBg} style={styles.avatarBg} />
        { avatar ? (<Image src={avatar} style={styles.avatar} />) : null }
        <Image style={styles.gamesBg} src="linear-gradient(to bottom, #57616b, #31383f)" />

        <Text style={styles.username}>{ username }</Text>

        <View style={styles.topBarContainer}>
          { userCountry ? (
            <View style={styles.topBar}>
              { countryImage ? (<Image style={styles.countryIcon} src={countryImage} />) : null }
              <Text style={styles.userCountry}>{userCountry}</Text>
            </View>
          ) : null }
          { language ? (
            <View style={styles.topBar2}>
              <Image src={languageIcon} style={styles.languageIcon} />
              <Text>{languages(language)}</Text>
            </View>
          ) : null }
        </View>

        <Text style={styles.statsBar1}>30 Day Stats</Text>
        <Image style={styles.statsLine} src={statsLine} />

        <Image src={rectangle} style={styles.accvViewBg} />
        <View style={styles.accvView}>
          <View style={styles.accvLabel}>
            <Image src={eyeIcon} style={styles.accvIcon} />
            <Text style={styles.accvText}> ACCV </Text>
          </View>
          <View style={styles.bigNumContainer}>
            <Text style={styles.bigNum}>{numberWithCommas(parseFloat(correctAverage).toFixed(0))}</Text>
          </View>
        </View>

        <Image src={rectangle} style={styles.minsViewBg} />
        <View style={styles.minsView}>
          <View style={styles.accvLabel}>
            <Image src={clockIcon} style={styles.clockIcon} />
            <Text style={styles.accvText}> VIEWER MIN. WATCHED </Text>
          </View>
          <View style={styles.bigNumContainer}>
            <Text style={styles.bigNum}>{numberWithCommas(parseFloat(viewerMinWatched).toFixed(0))}</Text>
          </View>
        </View>

        <Text style={styles.statsBar2}>Reach</Text>
        <Image style={styles.reachLine} src={reachLine} />

        <Image src={rectangle} style={totalReachStyle} />
        <Image src={rectangle} style={twitchReachStyle} />
        { twitterFollowers ? (<Image src={rectangle} style={styles.twitterReachViewBg} />) : null}

        <View style={styles.reachView}>
          <View style={styles.totalReachView}>
            <View style={styles.reachLabel}>
              <Image src={globeIcon} style={styles.globeIcon} />
              <Text style={styles.accvText}> TOTAL REACH </Text>
            </View>

            <View style={styles.bigNumContainer}>
              <Text style={styles.bigNum}>{numberWithCommas(totalReach)}</Text>
            </View>
          </View>

          <View style={styles.twitchReachView}>
            <View style={styles.reachLabel}>
              <Image src={twitchIcon} style={styles.twitchTwitterIcon} />
              <Text style={styles.accvText}> TWITCH FOLLOWING </Text>
            </View>

            <View style={styles.bigNumContainer}>
              <Text style={styles.bigNum}>{numberWithCommas(twitchFollowers)}</Text>
            </View>
          </View>

          { twitterFollowers ? (
            <View style={styles.twitterReachView}>
              <View style={styles.reachLabel}>
                <Image src={twitterIcon} style={styles.twitchTwitterIcon} />
                <Text style={styles.accvText}> TWITTER FOLLOWING </Text>
              </View>
              <View style={styles.bigNumContainer}>
                <Text style={styles.bigNum}>{numberWithCommas(twitterFollowers)}</Text>
              </View>
            </View>
          ) : null }

        </View>

        <Image src={rectangleTall} style={styles.topGamesViewBg} />
        <View style={styles.topGamesView}>
          <View style={styles.accvLabel}>
            <Text style={styles.topGamesPlayedText}> TOP GAMES PLAYED </Text>
          </View>

          { game1Name ? (
            <View style={styles.topGame}>
              <Image src={userGame30Sorted[0].box_art} style={styles.smallArt} />
              <Text style={game1Style}>{ game1Name }</Text>
            </View>
          ) : null }

          { game2Name ? (
            <View style={styles.topGame}>
              <Image src={userGame30Sorted[1].box_art} style={styles.smallArt} />
              <Text style={game2Style}>{ game2Name }</Text>
            </View>
          ) : null }

          { game3Name ? (
            <View style={styles.topGame}>
              <Image src={userGame30Sorted[2].box_art} style={styles.smallArt} />
              <Text style={game3Style}>{ game3Name }</Text>
            </View>
          ) : null }
        </View>

        { userGame30Sorted[0] && userGame30Sorted[0].box_art ? (
          <Image src={userGame30Sorted[0].box_art} style={styles.mainArt} />
          ) : null }

        <Text style={styles.disclaimer}>{ `${username} is not exclusive to Noiz` }</Text>
      </Page>
    );
  }

  render() {
    const { user } = this.props;

    const MyDocument = () => (
      <Document>
        { this.getPage(user) }
      </Document>
    );

    const username = this.getUsername(user);

    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.props.closeModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Modal__download">
            <div className="Modal__download--content">
              <div className="Modal__download--header">
                Download PDF
              </div>
              <div className="Modal__download--body">
                <div className="Modal__download--row">
                  <PDFDownloadLink document={<MyDocument />} fileName={`${username}.pdf`}>
                    {({
 blob, url, loading, error,
}) => (loading ? 'Loading document...' : 'Click to download')}
                  </PDFDownloadLink>
                </div>
              </div>
              <div className="Modal__download--footer">
                <button
                  onClick={this.props.closeModal.bind(this)}
                  className="Modal__download--cta"
                >
                  <i className="fa fa-times" />&nbsp;Close
                </button>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

UserPDFModal.propTypes = {

};

export default UserPDFModal;
