
import defaultAvatar from '../../../images/avatars/defaultSpaceman.png';
import spaceman2 from '../../../images/avatars/spaceman2.png';
import spaceman3 from '../../../images/avatars/spaceman3.png';
import spaceman4 from '../../../images/avatars/spaceman4.png';
import spaceman5 from '../../../images/avatars/spaceman5.png';
import spaceman6 from '../../../images/avatars/spaceman6.png';

export const getRandomAvatar = () => {
  const avatars = [
    defaultAvatar,
    spaceman2,
    spaceman3,
    spaceman4,
    spaceman5,
    spaceman6,
  ];

  return avatars[Math.floor(Math.random() * avatars.length)];
};
