
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */

import React from 'react';
import 'react-id-swiper/src/styles/css/swiper.css';
// import ReactHtmlParser from 'react-html-parser';
// import Truncate from 'react-truncate-html';
// import Truncate2 from 'react-truncate';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { chunk, filter } from 'lodash';
import TruncateMarkup from 'react-truncate-markup';
import { rounding } from '../../utils/functions';
import './Store.scss';

class Store extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'games',
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    this.props.getStoreGames(token);
  }

  handleTabChange = (param) => {
    this.setState({
      activeTab: param,
    });
  }

  goToStorefront = (gameSlug) => {
    this.props.router.push(`/buy/${gameSlug}`);
  }

  renderStoreTabs = () => {
    const { activeTab } = this.state;
    return (
      <div className="Store__tabs">
        <ul>
          {/* <li>
            <button
              className={classNames({ active: activeTab === 'products' })}
              onClick={this.handleTabChange.bind(this, 'products')}
            >
              Products
            </button>
          </li> */}
          <li>
            <button
              className={classNames({ active: activeTab === 'games' })}
              onClick={this.handleTabChange.bind(this, 'games')}
            >
              Games
            </button>
          </li>
        </ul>
      </div>
    );
  };

  renderStoreGame = (g, isDesktop, i) => {
    let hasSalePrice = false;
    let buttonPrice = `Buy now for $${rounding(g.price)}`;
    let percentage = 0;
    if (g.salePrice && g.saleEndDate) {
      const today = moment().tz('America/Los_Angeles');
      const hoursOffset = today.utcOffset() / 60;
      const saleDate = new Date(g.saleEndDate);
      const now = moment(new Date()).add(hoursOffset, 'hours').toDate();
      if (saleDate > now) {
        hasSalePrice = true;
        buttonPrice = `Buy now for $${rounding(g.salePrice)}`;
        percentage = Math.round((g.salePrice / g.price) * 100);
      }
    }
    return (
      <div className={classNames('Store__game', { desktop: isDesktop, notLast: (i === 0 || i === 1) })} key={g.id}>
        <div className="Store__game--header">
          <div className="Store__game--image" style={{ backgroundImage: `url(${g.cover})` }} />
          <div className="Store__game--figure1" />
          <div className="Store__game--figure2" />
        </div>
        <div className="Store__game--body">
          <div className="Store__game--name">
            {g.name}
          </div>
          <div className="Store__game--description">
            {
              (g.storeDescription)
              ?
                <TruncateMarkup lines={5}>
                  <div>{g.storeDescription}</div>
                </TruncateMarkup>
              :
                ''
            }
          </div>
        </div>
        <div className="Store__game--footer">
          {
            (hasSalePrice) &&
            <div className="Store__game--save">
              <div className="Store__game--saveText">
                SAVE {percentage}% ON Retail Price of ${rounding(g.price)}
              </div>
              <div className="Store__game--figure3" />
            </div>
          }
          <button onClick={() => this.goToStorefront(g.slug)}>
            {buttonPrice}
          </button>
        </div>
      </div>
    );
  };

  renderStoreGames = () => {
    const { data: games } = this.props.config.storeGames;
    const gamesWithKeysAvailable = filter(games, o => o.hasKeys === true);
    const hasGames = (gamesWithKeysAvailable && gamesWithKeysAvailable.length > 0);
    const gamesChunk = chunk(gamesWithKeysAvailable, 3);
    if (hasGames) {
      return (
        <div className="Store__games">
          <div className="Store__games--mobile">
            {
              gamesWithKeysAvailable.map(g => this.renderStoreGame(g, false))
            }
          </div>
          <div className="Store__games--desktop">
            {
              gamesChunk.map(gchunk => (
                <div className="Store__games--row" key={gchunk[0].id}>
                  {gchunk.map((g, index) => this.renderStoreGame(g, true, index))}
                </div>
              ))
            }
          </div>
        </div>
      );
    }
    return (
      <div className="Store__games--empty">
        No games on sale.
      </div>
    );
  }

  render() {
    const { isLoading } = this.props.config.storeGames;
    return (
      <section className="Store">
        <div className="Store__container">
          <div className="Store__title">Welcome to the Noiz Store</div>
          { this.renderStoreTabs() }
          { (!isLoading) && this.renderStoreGames() }
          {
            (isLoading) &&
            <div className="Store__loading">
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            </div>
          }
        </div>
      </section>
    );
  }
}

Store.propTypes = {
};

export default Store;
