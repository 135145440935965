import React from 'react';
import './AdminOffPlatformTalent.scss';
import AdminAddOffPlatformTalentForm from './AdminAddOffPlatformTalentForm';

function AdminAddOffPlatformTalent(props) {
  return (
    <div className="off-platform-talent-container">
      <div className="off-platform-action-buttons">
        <AdminAddOffPlatformTalentForm {...props} />
      </div>
    </div>
  );
}

export default AdminAddOffPlatformTalent;
