import controller from './game-icon.svg';
import scale from './scalability-icon.svg';
import location from './region-icon.svg';
import clock from './always-on-icon.svg';
import payments from './payments-icon.svg';
import shield from './safety-icon.svg';

const bestInClassIndex = [{ icon: controller, title: 'Game-Specific Targeting', text: 'Select influencers who play a specific game or game genre' }, { icon: scale, title: 'Scale to Any Size', text: 'We handle every step of the process from planning to payment so you can effortlessly scale your campaigns' }, { icon: location, title: 'Work with Influencers From Any Region', text: 'Choose influencers from any or all of the 133 different countries in our global network' }, { icon: clock, title: 'Always-on Management', text: 'Dedicated, full service campaign support available 24/7' }, { icon: payments, title: 'Secure Payments', text: 'Choose the best form of incentive for your brand and we handle the rest' }, { icon: shield, title: 'Brand Safety', text: 'All of our campaigns follow the disclosure guidelines from the United States Federal Trade Commission' }];

export default bestInClassIndex;
