import React from 'react';

import Gallery from './Gallery';
import SocialNetworks from './SocialNetworks';

import './Gallery.scss';
import './GallerySocials.scss';

const GallerySocials = ({ gallery, socialNetworks }) => {
  const galleryContent = gallery.filter(g => g.type === 'image');

  if (galleryContent.length === 0 && socialNetworks.length === 0) {
    return null;
  }

  return (
    <div className="GallerySocials">
      {galleryContent.length > 0 && <Gallery gallery={galleryContent} />}
      {socialNetworks.length > 0 && <SocialNetworks socialNetworks={socialNetworks} />}
    </div>
  );
};

export default GallerySocials;
