import Background1 from './Background1.png';
import Background2 from './Background2.png';
import Background3 from './Background3.png';
import Background4 from './Background4.png';
import Background5 from './Background5.png';
import Background6 from './Background6.png';

const questBackgrounds = [Background1, Background2, Background3, Background4, Background5, Background6]
export default questBackgrounds;

