/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RichText from '../RichText';

// const rteDefaultConfig = {
//   skin: 'dark',
//   plugins: 'link lists advlist media image textcolor',
//   toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat',
//   content_style: '#tinymce{background-color:#f4f4f9 !important;color:#000 !important; font-family:Helvetica;}',
// };

class CustomInput extends Component {
  render() {
    const { input, meta: { touched, error }, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    const { copyUrl } = custom;

    return (
      <div className="input-container">
        <label htmlFor={input.label}>
          {custom.customLabel}
        </label>
        <div className="input-wrapper">
          {(custom.type === 'textarea') &&
            <textarea
              autoComplete="off"
              id={custom.id}
              rows={custom.rows}
              {...input}
              type={custom.type}
              placeholder={custom.placeholder}
              onChange={
                (value) => {
                  if (custom.handleChange) { custom.handleChange(value); }
                  input.onChange(value);
                }
              }
              className={classNames({
                touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value, copyUrl,
              })}
            />}

          {custom.type === 'richtext' &&
            <RichText
              initialValue={input.value}
              callback={custom.handleChange}
            // config={rteDefaultConfig}
            />
          }

          {custom.type !== 'textarea' && custom.type !== 'richtext' &&
            <input
              autoComplete="off"
              id={custom.id}
              {...input}
              type={custom.type}
              placeholder={custom.placeholder}
              onChange={
                (event) => {
                  if (
                    (custom.onlyNumbers === 'true' && /^\d*$/.test(event.target.value)) ||
                    (custom.onlyCurrency === 'true' && /^(?:\d+(?:\.\d{1,2})?|\.\d{1,2}|\d+\.|)$/.test(event.target.value)) ||
                    (custom.onlyNumbers !== 'true' && custom.onlyCurrency !== 'true')
                  ) {
                    if (custom.handleChange) { custom.handleChange(event); }
                    input.onChange(event);
                  }
                }
              }
              className={classNames({
                touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value, copyUrl,
              })}
              readOnly={custom.readOnly}
            />}
          {custom.customLabel2}
          {hasError && touched &&
            <div className="error-icon">
              <i className="fa fa-exclamation" />
            </div>
          }
          {!hasError && touched &&
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          }
          {hasError &&
            <div className="error-message">{error}</div>
          }
        </div>
      </div>
    );
  }
}

CustomInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomInput.defaultProps = {
  placeholder: '',
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

export default CustomInput;
