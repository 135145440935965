/* eslint max-len: ["error", { "code": 800 }] */
import React from "react";
// import PropTypes from 'prop-types';
import "./CareersPost.scss";
// import IconParticles from '../icon-particles/IconParticles';

const titleStyle = {
  backgroundColor: "transparent",
  fontWeight: 700,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  lineHeight: "1.8",

  verticalAlign: "baseline",
  whiteSpace: "pre-wrap",
  marginBottom: "40px",
};

const textStyle = {
  listStyleType: "disc",

  lineHeight: "1.8",

  backgroundColor: "transparent",
  fontWeight: 400,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  whiteSpace: "pre",
};

const subStyle = {
  backgroundColor: "transparent",
  fontWeight: 400,
  lineHeight: "1.8",

  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  whiteSpace: "pre-wrap",
};

const spacingStyle = {
  lineHeight: "1.8",

  marginTop: "5px",
  marginBottom: "5px",
};

const topStyle = {
  lineHeight: "1.8",

  backgroundColor: "transparent",
  fontWeight: 400,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  whiteSpace: "pre-wrap",
};

class BrandSalesManager extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}
  // replace all inline styles with classNames

  render() {
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">
              <section className="PostingItem">
                <a
                  className="PostingItem__apply-btn"
                  href="mailto:angie@noiz.gg"
                >
                  Apply for this job <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">
                    Brand & Sales Strategist (Manager)
                  </h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">
                      Beverly Hills, CA
                    </span>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>Brand and Sales Strategist</span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={topStyle}>
                    Noiz is a group of esports and video game industry
                    veterans—bringing together a diverse community of Twitch and
                    YouTube creators, with established streamers across mediums
                    and global franchises. Founded in 2018, Noiz empowers micro
                    and macro streamers and inspires expression. We pride
                    ourselves on providing real value to both streamers and
                    brands worldwide. Our goal is to support streamers through
                    meaningful partnerships and brand building. Attracting
                    thousands of views monthly with over 33,000 loyal
                    subscribers globally, Noiz reaches audiences across all
                    platforms.
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>
                    <br></br>
                    The Role:
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={topStyle}>
                    Noiz is looking for a Brand and Sales Strategist. You will
                    be part of a committed group of gamers and professionals who
                    develop ideas. You have a love of creating brand identities
                    and voices and reaching passionate users. You’ve probably
                    run a few social media campaigns and user acquisition
                    campaigns.
                  </span>
                  <span style={topStyle}>
                    <br />
                  </span>
                  <span style={topStyle}>
                    <br />
                  </span>
                  <span style={titleStyle}>
                    As our Brand and Sales Strategist, you will be responsible
                    for:
                    <br></br>
                  </span>
                  <span style={topStyle}>
                    <br />
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>You Are:&nbsp;</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        A marketing pro: you have deep knowledge and a strong
                        track record creating and executing marketing or brand
                        campaigns focused on delivering value to target
                        audiences in unique and memorable ways
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Strategic and insight-informed: you have the ability to
                        see the big picture and utilize your suite of skills to
                        level up your team’s creative ideas; you back up those
                        strong marketing instincts with meticulous analysis,
                        metrics and stats
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        A communicator: you’re comfortable collaborating,
                        whether in the hallway or via online
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Affinity for streamers; you use your gaming experience
                        to analyze industry marketing campaigns, evaluating
                        their strengths and weaknesses and spotting
                        opportunities for improvement
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        A self-starter: whether you’re working on a new campaign
                        or mastering a new game, it’s always been your
                        prerogative to learn, practice, improve and repeat; that
                        work ethic keeps you going, even when the way forward
                        isn’t clear
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>
                    <br />
                  </span>
                  <span style={titleStyle}>You Will:&nbsp;</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Lead global brand marketing programs that engage content
                        creators around the world and champion the Noiz
                        &nbsp;brand
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Create and manage our social media channels and
                        campaigns
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Lead and create user acquisition campaigns to grow our
                        list of influencers
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Create campaign strategies in partnership with the
                        development team and marketing teams that will hit the
                        mark in culturally-relevant ways for players and content
                        creators
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Establish and use KPIs to help us hand-craft marketing
                        solutions that smash player’s expectations
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Evaluate and advocate new programs and campaigns that
                        elevate and reinforce Noiz’s market position
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Manage key user acquisition channels, which include
                        manage channels end to end, including &nbsp; helping
                        develop and refine channel strategy, defining targeting,
                        managing creativity, optimizing campaigns, measure
                        performance, and deriving insights to inform future
                        campaigns (30/70 Strategy vs Execution and optimization)
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Create and A/B test campaign messages, landing pages,
                        and funnels
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Be data-driven and always develop and test new
                        hypotheses
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Inspire, lead, and coach marketing associates who are
                        responsible for global marketing programs
                      </span>
                      <span style={subStyle}>
                        <br />
                        <br />
                      </span>
                    </p>
                  </li>
                </ul>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>Ideal candidate will have:</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        3-4+ years of digital marketing experience for a
                        technology company
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Experience building growth-focused systems that
                        scale&nbsp;
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Demonstrated success influencing and converting
                        users&nbsp;
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Experience building and testing email nurturing
                        drips&nbsp;
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Experience building and testing landing pages&nbsp;
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        A proven method to identify and prioritize
                        experiments&nbsp;
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Competency with analytics and excel&nbsp;
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        High standards for customer experience and design&nbsp;
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        A problem solver with a passion for learning
                      </span>
                    </p>
                  </li>
                </ul>
                <br></br>

                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span style={titleStyle}>Extra Credit:</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Familiarity with TikTok, Twitch, YouTube Live / Gaming
                      </span>
                    </p>
                  </li>
                  <li aria-level={1} dir="ltr" style={textStyle}>
                    <p dir="ltr" style={spacingStyle}>
                      <span style={subStyle}>
                        Influencer marketing experience
                      </span>
                      <span style={subStyle}>
                        <br />
                        <br />
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
              </>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Noiz is committed to creating an inclusive employee experience
                  for all. Regardless of race, gender, religion, sexual
                  orientation, age, disability, we firmly believe that our work
                  is at its best when everyone feels free to be themselves.
                </span>
              </p>
              <p className="CareersPostDetails__content--cta">
                Email your resume to{" "}
                <a href="mailto:angie@noiz.gg">angie@noiz.gg</a>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

// CareersPostFullStackEngineer.propTypes = {
//
// };

export default BrandSalesManager;
