import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router';

import Icon from '../../global/images/icons/Icon';
import { localizeIncorrectDate } from '../../utils/dateTime';
import { getPaymentAmount, rounding } from '../../utils/functions';

import './QuestItem.scss';

function QuestItem({ isBrand, quest, user, userQuest }) {
  const {
    endDateTime,
    id = '',
    memberTiers = [],
    platforms = [],
    slug = '',
    startDateTime,
    title = '',
    twitchAccountRequired = false,    // type = 'basic',
    viewerTiers = [],
  } = quest;

  const { status } = userQuest;

  const { paymentAmount } = getPaymentAmount(memberTiers, user, viewerTiers, userQuest);
  const ineligible = ['declined', 'leftQuest', 'rejected'].includes(status) || paymentAmount === Number.MIN_SAFE_INTEGER;
  const useGrey = ineligible || status;
  const iconColor = useGrey ? '#83858c' : 'white';
  const platformIcon = (platforms && platforms[0] && platforms[0].baseService) || 'Other';
  const freeKey = paymentAmount === -1;
  const points = paymentAmount === -2;
  let disclaimer = 'This is an estimate and may be adjusted prior to acceptance.';

  let paymentText = `$${rounding(paymentAmount)}`;

  if (paymentAmount === Number.MIN_SAFE_INTEGER || ineligible) {
    paymentText = '--';
    disclaimer = null;
  } else if (freeKey) {
    paymentText = 'Free Key';
    disclaimer = null;
  } else if (points) {
    paymentText = 'Points';
  } else if (paymentAmount > 0) {
    disclaimer = null;
  }

  const PaymentRender = () => {
    if (!!disclaimer) {
      return (
        <Tooltip placement="top" title={disclaimer}>
          <span className={`payment-amount disclaimer ${freeKey || points ? 'free-key' : ''}`}>
            {paymentText}&nbsp;<i className="fa fa-exclamation-circle" />
          </span >
        </Tooltip>
      )
    }

    return (
      <span className={`payment-amount ${freeKey || points ? 'free-key' : ''}`}>
        {paymentText}
      </span>
    )
  };

  let buttonClass = 'view-details';
  let buttonText = 'View Details';

  if (['declined', 'leftQuest', 'rejected'].includes(status)) {
    buttonClass = 'ineligible';
    buttonText = 'Ineligible';
  } else if (status === 'pending') {
    buttonClass = 'applied';
    buttonText = 'Applied';
  } else if (!status) {
    buttonText = 'Apply';
  }

  return (
    <li className={`quest-item ${useGrey ? 'grey' : ''}`}>
      <Tooltip title={title || ''}>
        <span className='title'>{title}</span>
      </Tooltip>
      {/* <div className="Quest__ManagedBy">
        {!isBrand ? (
          <>
            <Icon name='handgamecontroller' color='black' />
            <div className='Quest__ManagedBy__Label'>Actively Managed</div>
          </>
        ) : (
          <>
            <Icon name='lightningbolt' color='black' />
            <div className='Quest__ManagedBy__Label'>Community Quest</div>
          </>
        )}
      </div> */}
      <PaymentRender />
      <div className="platform-icons">
        <div>
          {twitchAccountRequired ?
            <Icon
              color={iconColor}
              name="Twitch"
            />
            : <Icon
              color={iconColor}
              name='Scroll'
            />
          }
        </div>
        <div className="right">
          <Icon
            color={iconColor}
            name={platformIcon}
          />
        </div>
      </div>
      <span className="date-range">
        {`${localizeIncorrectDate(startDateTime).format('MMM D')} - ${localizeIncorrectDate(endDateTime).format('MMM D, YYYY')}`}
      </span>
      <Link
        className={`button ${buttonClass}`}
        to={`/quests/${slug || id}`}
      >
        <span className="text">
          {buttonText}
        </span>
      </Link>
    </li>
  );
}

export default QuestItem;
