import React, { useEffect, useState } from "react";
// import classnames from "classnames";

import classnames from "classnames";
import General from "./General";
import Payment from '../../../../../global/components/Payment/Payment';
// import Stripe from "../../payments/Stripe";

import { simpleNotification } from "../../../../../utils/notifications";
import { uploadToS3 } from "../../../../../utils/util";

import "./Profile.scss";

const defaultForm = {
  avatar: "",
  bio: "",
  country: "",
  organization: "",
  username: "",
};

const Profile = props => {
  const {
    auth: {
      isLoading,
      errors: { hasError: hasUserError, message: userErrorMessage },
      // user: { id: userId },
      user,
      isUpdating,
    },
    brandUser,
    brandUser: {
      preSignedUrl: {
        // data: { preSignedUrl, url: signedUrl },
        data: { preSignedUrl },
      },
    },

    auth,
    brandUserUploadImageUrl,
    updateProfileInfo,
    token,
  } = props;

  const [form, setForm] = useState(defaultForm);
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState([]);
  const [page, setPage] = useState(1);
  // const page = 1;
  // const [uploading, setUploading] = useState("");
  const [pendingFile, setPendingFile] = useState(null);
  // const [modal, setModal] = useState({
  //   cover: false,
  //   coverPortrait: false,
  //   logo: false,
  //   developerLogo: false,
  // });

  const handleLoad = e => {
    if (e.target.status !== 200) {
      simpleNotification({
        level: "error",
        title: "Error",
        message: "Error uploading the image, try again please.",
      });
    } else {
      let newProfilePic = {
        // thumb: signedUrl,
        type: "image",
        // url: signedUrl,
      };

      // handleFormChange(uploading, signedUrl);
      handleFormChange("avatar", newProfilePic);
    }

    // setUploading("");
    setPendingFile(null);
  };

  useEffect(() => {
    if (pendingFile && preSignedUrl) {
      uploadToS3(pendingFile, preSignedUrl, handleLoad);
    }
  }, [preSignedUrl]);

  useEffect(() => {
    if (auth) {
      if (auth?.user) {
        setForm(auth.user);
        setLoad(true);
      } else {
        setLoad(false);
      }
    } else {
      setLoad(true);
    }
  }, [auth]);

  useEffect(() => {
    if (hasUserError && !isLoading) {
      setLoad(false);
    }
  }, [hasUserError]);

  useEffect(() => {
    if (!isLoading && !isUpdating) {
      simpleNotification({
        type: "success",
        title: "Success",
        message: `User Updated`,
      });
    }
  }, [user.id]);

  useEffect(() => {
    if (hasUserError && userErrorMessage) {
      simpleNotification({
        level: "error",
        title: "Error",
        message: userErrorMessage,
      });
    }
  }, [hasUserError]);

  const handleSubmit = e => {
    e.preventDefault();

    const validationErrors = validateUserForm(form);

    setErrors(validationErrors);
    if (validationErrors?.length === 0) {
      try {
        updateProfileInfo(form, token);
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  const handleFormChange = (name, value) => {
    if (name) {
      setForm(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (errors.includes(name)) {
      setErrors(errors.filter(error => error !== name));
    }
  };

  const Buttons = () => (
    <div className='Profile__Buttons'>
      {page < 4 && (
        <>
          <button className='Profile__Buttons_Button Save' onClick={handleSubmit}>
            Save
          </button>
        </>
      )}
    </div>
  );

  const formArgs = {
    form,
    handleFormChange,
    errors,
  };

  const PageSelector = ({ value, text, fields, transform }) => {
    const errorPresent = fields.some(e => errors.includes(e));
    const fill = page >= value;
    const transStyle = { transform: `translateX(-${transform}px)` };
    return (
      <div className='Profile__Pages_Page' onClick={() => setPage(value)}>
        <div className='Profile_Navigation'>
          <div
            className={classnames(
              "Profile_Navigation_Bouy",
              errorPresent ? "ErrorPresent" : fill && "Fill"
            )}
          />
        </div>
        <div
          style={transStyle}
          className={classnames(
            "Profile__Pages_Page_Text",
            errorPresent ? "ErrorPresent" : fill && "Fill"
          )}>
          {text}
        </div>
      </div>
    );
  };

  // TODO: Need to address products that are not games and its associated platforms for selection

  return (
    <div className='Profile__Wrapper'>
      {isLoading && <i className='fa fa-spinner fa-spin fa-3x fa-fw' />}
      {/* {!isLoading && !load && <NotFound home={`/portal?component=default&tab=${tab}`} buttonText={'Back'} />} */}
      {!isLoading && load && (
        <div className='Profile'>
          <div className='Profile__Pages'>
            <div className='Profile__Pages_Bar' />
            <PageSelector
              value={1}
              text='GENERAL'
              transform={25}
              fields={[
                "name",
                "sponsorType",
                "description",
                "developer",
                "publisher",
                "status",
                "releaseDate",
                "platforms",
                "isPrivate",
              ]}
            />
            <PageSelector value={2} transform={27} text='PAYMENT' fields={["gallery"]} />
            <PageSelector
              value={3}
              text='PLANS'
              transform={20}
              fields={["cover", "coverPortrait", "logo", "developerLogo"]}
            />
          </div>
          <div className='Profile__Header'>Edit Profile</div>

          <div className='Profile__Content'>
            {page === 1 && (
              <General
                {...formArgs}
                auth={auth}
                brandUserUploadImageUrl={brandUserUploadImageUrl}
                preSignedUrl={brandUser.preSignedUrl}
                token={token}
                updateProfileInfo={updateProfileInfo}
              />
            )}
            {page === 2 && (
              <Payment {...formArgs} auth={auth} updateProfileInfo={updateProfileInfo} />
            )}
          </div>
          <Buttons />
        </div>
      )}
    </div>
  );
};

const validateUserForm = form => {
  const errors = [];

  if (!form.username || !form.username.trim()) {
    errors.push("username");
  }

  if (!form.bio) {
    errors.push("bio");
  }

  return errors;
};

export default Profile;
