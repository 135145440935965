import React from 'react';

import SocialIcons from '../../../../../global/components/SocialIcons/SocialIcons';
import { makeProper } from '../../../../../utils/functions';

import './SocialNetworks.scss';

const SocialNetworks = ({ socialNetworks }) => (
  <div className="SocialNetworks">
    <div className="SocialNetworks__Header">Social Networks</div>
    <div className="SocialNetworks__Cards">
      {socialNetworks.map(({ name, url }) => <SocialIcons key={name} network={name} tooltip={name === 'youtube' ? 'YouTube' : makeProper(name)} url={url} />)}
    </div>
  </div>
);

export default SocialNetworks;
