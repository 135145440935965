import * as React from "react";

import './QuestCampaignToggle.scss';

export default function QuestCampaignToggle({ isCampaigns = true, toReturn = () => null }) {

  return (
    <div className="QuestCampaignToggle_Wrapper">
      <label>Campaigns</label>
      <input type='radio' name='campaignOrQuest' value='campaign' onClick={() => toReturn(true)} defaultChecked={isCampaigns} />
      <label>Quests</label>
      <input type='radio' label='Quest' name='campaignOrQuest' value='quest' onClick={() => toReturn(false)} defaultChecked={!isCampaigns} />
    </div>
  );
};
