import React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, reduxForm, change } from 'redux-form';
import moment from 'moment';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import CustomSelectBasic from '../../utils/redux-forms-custom-fields/CustomSelectBasic';
import CustomDropDown from '../../utils/redux-forms-custom-fields/CustomDropDown';
// import CustomInputCheckbox from '../../utils/redux-forms-custom-fields/CustomInput';
import { simpleNotification } from '../../utils/notifications';
// import CustomDatePicker from '../../utils/redux-forms-custom-fields/CustomDatePicker';
// import CustomDateTimePicker from '../../utils/redux-forms-custom-fields/CustomDateTimePicker';
import CustomDropDownGeneric from '../../utils/redux-forms-custom-fields/CustomDropDownGeneric';
// import CustomDateTimePicker from '../../utils/redux-forms-custom-fields/CustomDateTimePicker';
import { formatMonth, formatMonth2 } from '../../utils/functions';

// import dots from '../../utils/dots.svg';
const dateStoreFormat = 'YYYY-MM-DD';
// const dateDisplayFormat = 'MM-DD-YYYY';

class ProfileInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.changePassword = {
      text: 'Change Password',
      checked: false,
    };

    this.state = {
      formEditable: false,
      streamingConsoles: props.initialValues.streamingConsoles ? props.initialValues.streamingConsoles : {
        pc: false,
        xbox: false,
        playstation: false,
        nintendo: false,
        mobile: false,
      },
    };
  }


  handleCancel = () => {
    const { profileInfoForm } = this.props;
    let isValidDate = false;
    if (profileInfoForm && profileInfoForm.values && profileInfoForm.values.birthDate) {
      isValidDate = moment(profileInfoForm.values.birthDate, dateStoreFormat, true).isValid();
    }
    simpleNotification({
      level: 'warning',
      title: 'Changes not saved.',
      message: 'Your changes will not be saved!',
    });
    this.changePassword.text = 'Change Password';
    this.changePassword.checked = false;
    this.props.reset();
    this.setState({
      formEditable: false,
    });
    if (isValidDate) {
      if (profileInfoForm.values.birthDate) {
        const month = profileInfoForm.values.birthDate.substring(5, 7);
        this.props.dispatch(change('profileInfoForm', 'year', profileInfoForm.values.birthDate.substring(0, 4)));
        this.props.dispatch(change('profileInfoForm', 'day', profileInfoForm.values.birthDate.substring(8, 10)));
        this.props.dispatch(change('profileInfoForm', 'month', formatMonth2(month)));
      } else {
        this.props.dispatch(change('profileInfoForm', 'year', 'year'));
        this.props.dispatch(change('profileInfoForm', 'day', 'day'));
        this.props.dispatch(change('profileInfoForm', 'month', 'month'));
      }
    }
  }



  handleEdit = () => {
    this.setState({
      formEditable: true,
    });
  }

  submit({
    firstName, lastName, username, email, paymentEmail, country, oldPassword, newPassword, gender, birthDate, day, month, year, twitter, discord,
  }) {
    let userFormInfo = {};
    if (!this.changePassword.checked) {
      userFormInfo = {
        firstName,
        lastName,
        username,
        email,
        paymentEmail,
        country,
        gender,
        changePassword: false,
        birthDate,
        day,
        month,
        year,
        twitter,
        discord,

      };
    } else {
      userFormInfo = {
        firstName,
        lastName,
        username,
        email,
        paymentEmail,
        country,
        gender,
        changePassword: true,
        oldPassword,
        newPassword,
        birthDate,
        day,
        month,
        year,
        twitter,
        discord,
      };
    }

    userFormInfo.birthDate = `${userFormInfo.year}-${formatMonth(userFormInfo.month)}-${userFormInfo.day}`;
    userFormInfo.streamingConsoles = this.state.streamingConsoles;

    // Passing this params a level up on submit parent function.
    this.setState({
      formEditable: false,
    });
    this.changePassword.text = 'Change Password';
    this.changePassword.checked = false;
    this.props.onSubmit(userFormInfo);
    const { profileInfoForm } = this.props;
    let isValidDate = false;
    if (profileInfoForm && profileInfoForm.values && profileInfoForm.values.birthDate) {
      isValidDate = moment(profileInfoForm.values.birthDate, dateStoreFormat, true).isValid();
    }
    if (isValidDate) {
      if (profileInfoForm.values.birthDate) {
        const monthTemp = profileInfoForm.values.birthDate.substring(5, 7);
        this.props.dispatch(change('profileInfoForm', 'year', profileInfoForm.values.birthDate.substring(0, 4)));
        this.props.dispatch(change('profileInfoForm', 'day', profileInfoForm.values.birthDate.substring(8, 10)));
        this.props.dispatch(change('profileInfoForm', 'month', formatMonth2(monthTemp)));
      } else {
        this.props.dispatch(change('profileInfoForm', 'year', 'year'));
        this.props.dispatch(change('profileInfoForm', 'day', 'day'));
        this.props.dispatch(change('profileInfoForm', 'month', 'month'));
      }
    }
    this.props.reset();

  }

  changePasswordClick() {
    this.changePassword.checked = !this.changePassword.checked;
    if (this.changePassword.checked) {
      this.changePassword.text = 'Cancel';
    } else {
      this.changePassword.text = 'Change Password';
    }
    this.setState({});
  }

  onChangeStreamingConsoles(checkbox) {
    const newStreamingConsoles = this.state.streamingConsoles;
    newStreamingConsoles[checkbox.target.id] = !this.state.streamingConsoles[checkbox.target.id];
    this.setState({
      streamingConsoles: newStreamingConsoles,
    });
  }


  render() {
    const { handleSubmit, profileInfoForm, daysValues } = this.props;
    const { initialValues: activeUser } = this.props;
    const hasTwitter = activeUser.connectedAccounts && activeUser.connectedAccounts.twitter && activeUser.connectedAccounts.twitter.id;
    let isValidDate = false;
    const recoveryLink = (profileInfoForm && profileInfoForm.initial && profileInfoForm.initial.recoveryToken) ? `${window.location.origin}/recover?recovery-token=${profileInfoForm.initial.recoveryToken}` : '';
    if (profileInfoForm && profileInfoForm.values && profileInfoForm.values.birthDate) {
      isValidDate = moment(profileInfoForm.values.birthDate, dateStoreFormat, true).isValid();
    }

    const streamingConsoleOptions = [
      { label: 'PC', value: 'pc' },
      { label: 'Xbox', value: 'xbox' },
      { label: 'PlayStation', value: 'playstation' },
      { label: 'Nintendo', value: 'nintendo' },
      { label: 'Mobile', value: 'mobile' },
    ];

    return (
      <form className="form-container Profile__info__form" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__left" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Real First Name Here"
              customLabel={(<div>First Name</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__left" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="firstName"><div>First Name</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.firstName)
                  &&
                  <input type="text" value={`${profileInfoForm.values.firstName}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.firstName)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
          <div className="Profile__info__form__right" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Real Last Name Here"
              customLabel={(<div>Last Name</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__right" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="lastName"><div>Last Name</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.lastName)
                  &&
                  <input type="text" value={`${profileInfoForm.values.lastName}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.lastName)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row" style={{ marginBottom: '20px' }}>
          <div className="Profile__info__form__single" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              type="text"
              name="username"
              id="username"
              placeholder="username"
              customLabel={(<div>Username</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__single" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="username"><div>Username</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.username)
                  &&
                  <input type="text" value={`${profileInfoForm.values.username}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.username)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__left" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              type="text"
              name="email"
              id="email"
              placeholder="youremail@gmail.com"
              customLabel={(<div>Email</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__left" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="email"><div>Email</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.email)
                  &&
                  <input type="text" value={`${profileInfoForm.values.email}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.email)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
          <div className="Profile__info__form__right" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              type="text"
              name="paymentEmail"
              id="paymentEmail"
              placeholder="paypalemail@gmail.com"
              customLabel={(<div>PayPal Email Address</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__right" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="paymentEmail"><div>PayPal Email Address</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.paymentEmail)
                  &&
                  <input type="text" value={`${profileInfoForm.values.paymentEmail}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.paymentEmail)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__left" style={{ display: this.state.formEditable && !hasTwitter ? 'block' : 'none' }}>
            <Field
              type="text"
              name="twitter"
              id="twitter"
              placeholder="@twitterperson"
              customLabel={(<div>Twitter</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__left" style={{ display: !this.state.formEditable || hasTwitter ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="twitter"><div>Twitter</div></label>
              <div className="input-wrapper" >
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.twitter)
                  &&
                  <input type="text" value={`${profileInfoForm.values.twitter}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.twitter)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
          <div className="Profile__info__form__right" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              type="text"
              name="discord"
              id="discord"
              placeholder="mrdiscord"
              customLabel={(<div>Discord</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__right" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="discord"><div>Discord</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.discord)
                  &&
                  <input type="text" value={`${profileInfoForm.values.discord}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.discord)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__left" style={{ display: this.state.formEditable ? 'none' : 'block' }}>
            <div className="input-container">
              <label htmlFor="birthDate">
                <div>Birth Date</div>
              </label>
              <div className="input-wrapper">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.birthDate && isValidDate)
                    &&
                    <>
                      <input type="text" value={`${profileInfoForm.values.month}`} style={{ flexBasis: '44%' }} disabled />
                      <input type="text" value={`${profileInfoForm.values.day}`} style={{ flexBasis: '23%' }} disabled />
                      <input type="text" value={`${profileInfoForm.values.year}`} style={{ flexBasis: '30%' }} disabled />
                    </>
                  }
                  {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.birthDate || !isValidDate)
                    &&
                    <>
                      <input type="text" value="" style={{ flexBasis: '44%' }} disabled />
                      <input type="text" value="" style={{ flexBasis: '23%' }} disabled />
                      <input type="text" value="" style={{ flexBasis: '30%' }} disabled />
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="Profile__info__form__left" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="birthDate">
                <div>Birth Date</div>
              </label>
              <div className="input-wrapper">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Field
                    type="text"
                    name="month"
                    id="month"
                    flexBasis="44%"
                    time="months"
                    component={CustomSelectBasic}
                  />
                  <Field
                    type="text"
                    name="day"
                    id="day"
                    flexBasis="23%"
                    time="days"
                    component={CustomSelectBasic}
                    options={daysValues}
                  />
                  <Field
                    type="text"
                    name="year"
                    id="year"
                    flexBasis="30%"
                    time="years"
                    component={CustomSelectBasic}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Profile__info__form__right" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              type="text"
              name="country"
              id="country"
              placeholder="Country"
              customLabel={(<div>Country</div>)}
              component={CustomDropDown}
            />
          </div>
          <div className="Profile__info__form__right" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="country"><div>Country</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.country)
                  &&
                  <input type="text" value={`${profileInfoForm.values.country}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.country)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__left" style={{ display: this.state.formEditable ? 'block' : 'none' }}>
            <Field
              name="gender"
              id="gender"
              placeholder="Select Gender"
              customLabel={((<div>Gender</div>))}
              component={CustomDropDownGeneric}
              options={['Male', 'Female', 'Other']}
            />
          </div>
          <div className="Profile__info__form__left" style={{ display: !this.state.formEditable ? 'block' : 'none' }}>
            <div className="input-container">
              <label htmlFor="gender"><div>Gender</div></label>
              <div className="input-wrapper">
                {(profileInfoForm && profileInfoForm.values && profileInfoForm.values.gender)
                  &&
                  <input type="text" value={`${profileInfoForm.values.gender}`} className="touched ok not-empty" disabled />
                }
                {(!profileInfoForm || !profileInfoForm.values || !profileInfoForm.values.gender)
                  &&
                  <input type="text" value="" className="touched ok not-empty" disabled />
                }
              </div>
            </div>
          </div>
          <div className="Profile__info__form__right">
            <div className="input-container">
              <label htmlFor="password"><div>Password.</div></label>
              <div className="input-wrapper">
                <input type="text" value="••••••••" placeholder="••••••••" className="touched ok not-empty" disabled />
              </div>
            </div>
            <div className="Profile__info__change-password" style={{ display: this.state.formEditable ? 'block' : 'none' }} onClick={this.changePasswordClick.bind(this)} aria-hidden>{this.changePassword.text}</div>
          </div>
        </div>
        <div className={classNames('Profile__info__form__row', { mobile__hide: !this.changePassword.checked })} style={{ display: this.changePassword.checked ? 'flex' : 'none', opacity: this.changePassword.checked ? '1' : '0' }}>
          <div className="Profile__info__form__left">
            {/*
              <Field
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="••••••••"
                customLabel={(<div>New Password</div>)}
                component={CustomInput}
              />
            */}
            <Field
              type="password"
              name="oldPassword"
              id="oldPassword"
              placeholder="••••••••"
              customLabel={(<div>Old Password</div>)}
              component={CustomInput}
            />
          </div>
          <div className="Profile__info__form__right">
            <Field
              type="password"
              name="newPassword"
              id="newPassword"
              placeholder="••••••••"
              customLabel={(<div>New Password</div>)}
              component={CustomInput}
            />
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__single">
            <label style={{ marginBottom: '10px' }}>Streaming Consoles</label>
            {
              streamingConsoleOptions.map(platform => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <input
                    name={`${platform.value}`}
                    id={`${platform.value}`}
                    customLabel={`${platform.label}`}
                    type="checkbox"
                    style={{ width: '30px' }}
                    onChange={this.onChangeStreamingConsoles.bind(this)}
                    defaultChecked={this.state.streamingConsoles[`${platform.value}`]}
                    disabled={!this.state.formEditable}
                  />
                  <span className={this.state.formEditable ? '' : 'disabled'}>{platform.label}</span>
                </div>
              ))
            }
          </div>
        </div>

        <div className="Profile__info__form__row" style={{ display: 'none' }}>
          <div className="Profile__info__form__single">
            <div className="input-container">
              <label htmlFor=""><div>Password Recovery Link</div></label>
              <div
                className="input-wrapper"
                onClick={() => {
                  document.getElementById('password-recovery-link').select();
                  document.execCommand('copy');
                }}
              >
                <input
                  style={{ fontSize: '12px' }}
                  type="text"
                  value={recoveryLink}
                  id="password-recovery-link"
                  className="touched ok not-empty"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Profile__info__form__row">
          <div className="Profile__info__form__last" style={{ display: this.state.formEditable ? 'flex' : 'none' }}>
            <div className="Profile__info__form__cancel cta2__button" onClick={this.handleCancel.bind(this)} aria-hidden>
              Cancel
            </div>
            <button className="Profile__info__form__save cta__button">
              Save
            </button>
          </div>
          <div className="Profile__info__form__last" style={{ display: !this.state.formEditable ? 'flex' : 'none' }}>
            <div className="Profile__info__form__cancel cta__button" onClick={this.handleEdit.bind(this)} aria-hidden>
              Edit
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.firstName || values.firstName.trim() === '') {
    errors.firstName = 'This field is required';
  }
  if (!values.lastName || values.lastName.trim() === '') {
    errors.lastName = 'This field is required';
  }

  if (!values.username || values.username.trim() === '') {
    errors.username = 'This field is required';
  } else if (!values.username.match('^[a-zA-Z0-9_]*$')) {
    errors.username = 'Usernames can only contain numbers, letters, and underscores.';
  } else if (values.username.length > 25) {
    errors.username = 'Usernames must be less than 25 characters long.';
  }

  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  }
  if (!values.paymentEmail || values.paymentEmail.trim() === '') {
    errors.paymentEmail = 'This field is required';
  }
  if (!values.day || values.day.trim() === '' || values.day === 'day') {
    errors.day = 'Required';
  }
  if (!values.month || values.month.trim() === '' || values.month === 'month') {
    errors.month = 'Required';
  }
  if (!values.year || values.year.trim() === '' || values.year === 'year') {
    errors.year = 'Required';
  }
  if (!values.country || values.country.trim() === '') {
    errors.country = 'This field is required';
  }
  // if (values.twitter && !validateTwitterHandle(values.twitter)) {
  //   errors.twitter = 'Username missing "@"';
  // }

  // if (!values.oldPassword || values.oldPassword.trim() === '') {
  //   errors.oldPassword = 'This field is required';
  // }

  const checkSpecial = /[*@!#%&()^~{}$]+/.test(values.newPassword);
  const checkUpper = /[A-Z]+/.test(values.newPassword);
  const checkLower = /[a-z]+/.test(values.newPassword);

  if (values.newPassword) {
    if (values.newPassword.length < 8) {
      errors.newPassword = 'Must have at least 8 characters';
    } else if (values.newPassword.length > 30) {
      errors.newPassword = 'Must be less than 30 characters';
    } else if (!checkSpecial || !checkLower || !checkUpper) {
      errors.newPassword = 'Must have one number, one spec. char, and one uppercase letter';
    }
  }

  return errors;
};


ProfileInfoForm.propTypes = {
};

export default reduxForm({
  form: 'profileInfoForm',
  initialValues: { check: false },
  validate,
})(ProfileInfoForm);
