import React, { useState } from "react";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
// import Modal from "@material-ui/core/Modal";

import { getDefaultIcon, getQuestRequirements, timeAgoCalc } from "../../../../../utils/functions";
import Icon from "../../../../../global/images/icons/Icon";
import { getRandomAvatar } from "../../../../../global/images/icons/Avatar";
import { SetViewContext } from "../../PortalContext";

import "./DeliverableCard.scss";
import "./ViewAll.scss";

export const deliverableFilters = {
  completed: false,
  sorting: null,
};

export const deliverablesSorts = [
  "Most Recent Ascending",
  "Most Recent Descending",
  "Username Ascending",
  "Username Descending",
];

export const filterConsolidatedDeliverables = (consolidateDeliverables = {}, search = '') => {
  if (!search) return consolidateDeliverables;

  let filteredConsolidatedDeliverables = { ...consolidateDeliverables };

  filteredConsolidatedDeliverables = Object.keys(filteredConsolidatedDeliverables).reduce((acc, questId) => {
    const { requirements = [], users = {} } = filteredConsolidatedDeliverables[questId];

    acc[questId] = {
      requirements: filteredConsolidatedDeliverables[questId].requirements,
      users: {},
    };

    const inRequirements = requirements.filter(r => r?.title?.includes(search)).map(r => r.id);

    acc[questId].users = Object.keys(users).reduce((acc2, userId) => {
      const user = users[userId];
      const { displayName = '', requirements: userRequirements = [], status = '', username = '' } = user;

      const includeUser = displayName.includes(search) || username.includes(search) || status.includes(search) || !!(userRequirements.find(ur => inRequirements.includes(ur.questRequirementId)));

      if (includeUser) { acc2[userId] = user; }

      return acc2;
    }, {});

    return acc;
  }, {});

  return filteredConsolidatedDeliverables;
};

export const sortConsolidatedDeliverables = (consolidateDeliverablesMap = [], filter = {}) => {
  let sorted = [...consolidateDeliverablesMap];

  if (filter.sorting) {
    const sort = filter.sorting.split(" ");
    const sortKey = sort[2] ? `${sort[0]} ${sort[1]}` : sort[0];
    const sortOrder = sort[2] || sort[1];

    sorted = sorted.sort((a, b) => {
      if (sortKey === "Most Recent") {
        return sortOrder === "Descending" ? new Date(b.user.mostRecentSubmitted) - new Date(a.user.mostRecentSubmitted) : new Date(a.user.mostRecentSubmitted) - new Date(b.user.mostRecentSubmitted);
      } else if (sortKey === "Username") {
        return sortOrder === "Descending"
          ? b.user.username.localeCompare(a.user.username)
          : a.user.username.localeCompare(b.user.username);
      }
      else return -1;
    });
  }

  return sorted;
};

export const consolidateDeliverables = (quests = [], deliverables = []) =>
  deliverables.reduce((acc, d) => {
    const quest = quests.find(q => q.id === d.questId);
    const questRequirements = getQuestRequirements({ requirements: quest.requirements }, true);
    if (quest?.requiredStreamTimeMinutes) {
      questRequirements.push({
        timeRequired: quest.requiredStreamTimeMinutes,
        title: `Stream For ${quest.requiredStreamTimeMinutes} Minutes`,
      });
    }

    if (!acc[d.questId]) {
      acc[d.questId] = {
        requirements: questRequirements,
        users: {},
      };
    }

    if (!acc[d.questId].users[d.userId]) {
      acc[d.questId].users[d.userId] = {
        profile_img_url: d.profile_img_url,
        displayName: d.displayName,
        username: d.username,
        mostRecentSubmitted: d.createdAt,
        userQuestId: d.userQuestId,
        questTimedActivity: d.questTimedActivity,
        requirements: [],
      };
    } else {
      if (new Date(d.createdAt) > new Date(acc[d.questId].users[d.userId].mostRecentSubmitted)) {
        acc[d.questId].users[d.userId].mostRecentSubmitted = d.createdAt;
      }
    }

    acc[d.questId].users[d.userId].requirements.push({
      createdAt: d.createdAt,
      questRequirementId: d.questRequirementId,
      title: d.title,
      note: d.note,
      type: d.type,
      status: d.status,
      content: d.content,
    });

    return acc;
  }, {});

const DeliverableButton = ({ title, icon, completed = false }) => (
  <Tooltip placement='top' title={title}>
    <div className={classnames("DeliverableButton", completed && "Completed")}>
      {icon === "clock" ? (
        <Icon name={icon} color={completed ? "black" : "white"} />
      ) : (
        <img className='DeliverableButton__Icon' src={icon} alt='icon' />
      )}
    </div>
  </Tooltip>
);

export const DeliverableCard = props => {
  const {
    requirements,
    user: {
      profile_img_url,
      displayName,
      // username,
      mostRecentSubmitted,
      userId,
      userQuestId,
      requirements: userRequirements,
      questTimedActivity: { timePlaying = 0 },
    },
    // postBrandRequirement,
    // token,
  } = props;

  const [requirementsResponse, setRequirementsResponse] = useState({});

  const requirementButtons = [];
  const requirementProgress = [];
  const requirementsNeedingReview = [];

  requirements.forEach(r => {
    const foundUserRequirement = r?.timeRequired
      ? r
      : userRequirements.find(ur => ur.questRequirementId === r.id);

    const completed = foundUserRequirement?.timeRequired
      ? timePlaying >= foundUserRequirement.timeRequired
      : foundUserRequirement?.status === "completed";

    requirementButtons.push(
      <DeliverableButton
        key={`${userId}-${r.id}`}
        title={r.title}
        icon={foundUserRequirement?.timeRequired ? "clock" : getDefaultIcon(r.type)}
        completed={completed}
        reqResp={requirementsResponse[r.id]}
        setReqResp={setRequirementsResponse}
      />
    );

    if (completed) {
      requirementProgress.push(true);
    } else if (!completed && !r?.timeRequired) {
      requirementsNeedingReview.push(r);
    }
  });

  const fullyCompleted = requirementProgress.length >= requirements.length;

  return (
    <div className='DeliverableCard'>
      <img
        className='DeliverableCard_Profile_Img'
        src={profile_img_url}
        alt={displayName}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = getRandomAvatar();
        }}
      />
      <div className='DeliverableCard__Right'>
        <div className='DeliverableCard__Right__Info'>
          <div className='DeliverableCard__Right__Buttons'>{requirementButtons}</div>
          <div className='DeliverableCard__Right__Info__Name'>{displayName}</div>
          <div className='DeliverableCard__Right__Info__Time'>
            {timeAgoCalc(mostRecentSubmitted)}
          </div>
        </div>
        <div className='DeliverableCard__Right__Review__Buttons'>
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className={classnames(
                  "DeliverableCard__Right__Review__Buttons__Button",
                  fullyCompleted && "Completed"
                )}
                onClick={() => setView({
                  tab: view.tab,
                  component: 'ReviewDeliverable',
                  // currentQuest: questId,
                  // currentUser: userId,
                  currentUserQuest: userQuestId,
                })}
                disabled={fullyCompleted}
              >
                Review
              </div>
            )}
          </SetViewContext.Consumer>
        </div>
      </div>
      <div className='DeliverableCard__Progress'>
        <div
          className={classnames("DeliverableCard__Progress__Bar", fullyCompleted && "Completed")}
          style={{ width: `${(requirementProgress.length / (requirements?.length || 1)) * 100}%` }}
        />
      </div>
    </div>
  );
};

export const NoDeliverablesCard = () => (
  <div className='NoDeliverablesCard'>No Deliverables Found</div>
);
