import React, { useEffect } from 'react';
import './ToggleMobileMenu.scss';

function ToggleMobileMenu({
  fadingOut, open, setFadingOut, toggleMenu, visible,
}) {
  useEffect(() => {
    if (fadingOut) {
      setTimeout(() => {
        toggleMenu();
        setFadingOut(false);
      }, 618);
    }
  }, [fadingOut]);

  if (!visible) return null;

  return (
    <button
      className={`toggle-mobile-menu ${open && !fadingOut ? 'open' : ''}`}
      style={{backgroundColor: 'transparent'}}  // necessary to override default css
      onClick={() => {
        if (open) {
          setFadingOut(true);
        } else {
          toggleMenu();
        }
      }}
    >
      <span />
      <span />
      <span />
    </button>
  );
}

export default ToggleMobileMenu;
