import React from "react";
import Report from "./Report.js";
import { BrandUserContext } from "../PortalContext";
import './PortalFull.scss';

const PortalFull = props => {

  return (
    <BrandUserContext.Consumer>
      {brandUser => (
        <div className='PortalFull'>
          <Report
            brandUser={brandUser}
            getBUCampaign={brandUser?.getBUCampaign}
            token={brandUser?.token}
            // view={view}
          />
        </div>
      )}
    </BrandUserContext.Consumer>
  );
};

export default PortalFull;
