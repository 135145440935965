/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
import $ from 'jquery';
import qs from 'qs';
// import { Link } from 'react-router';
// import classNames from 'classnames';
// import PropTypes from 'prop-types';

// import ContactMainForm from '../forms/ContactMainForm';
import { simpleNotification } from '../../utils/notifications';
import IconParticles from '../icon-particles/IconParticles';
import streamerContact from './assets/streamer-contact.jpg';

import './Contact.scss';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // showingErrors: false,
      // errors: {
      //   hasError: false,
      //   code: '',
      //   message: '',
      //   all: {},
      // },
      // activeModal: false,
    };
  }

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.isLoading === true && nextProps.auth.isLoading === false) {
      // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      }
    }
  }

  onSubmit = (allFields) => {
    this.submitGForm(allFields);
  }

  submitGForm = (allFields) => {
    const baseURL = 'https://docs.google.com/forms/d/e/1FAIpQLSfImSeGuX-GyABX6L0pWny9FuuzB8xK3FSuQaKac8r-djjTzA/formResponse?';
    const baseGFormFields = {
      submit: 'Submit',
      emailAddress: allFields.email ? allFields.email : '',
      'entry.351391559': allFields.contactName ? allFields.contactName : '',
      'entry.1587419735': allFields.website ? allFields.website : '',
      'entry.591337854': allFields.companyName ? allFields.companyName : '',
      'entry.617606079': allFields.comments ? allFields.comments : '',
    };

    const parameters = qs.stringify(baseGFormFields);

    const completeUrl = `${baseURL}${parameters}`;

    $.ajax({
      type: 'GET',
      url: completeUrl,
      contentType: 'application/x-www-form-urlencoded',
      success: () => {
        this.setState({
          activeModal: true,
        });
        simpleNotification({
          level: 'success',
          title: 'Thank you!',
          message: 'Thanks for reaching out! We\'ll be in touch shortly.',
        });
      },
      error: () => {
        this.setState({
          activeModal: true,
        });
        simpleNotification({
          level: 'success',
          title: 'Thank you!',
          message: 'Thanks for reaching out! We\'ll be in touch shortly.',
        });
      },
    });
  }

  // dismissErrors = () => {
  //   this.setState({
  //     showingErrors: false,
  //     errors: {
  //       hasError: false,
  //       code: '',
  //       message: '',
  //       all: {},
  //     },
  //   });
  // }

  render() {
    // const { showingErrors, errors, activeModal } = this.state;
    return (
      <section className="Contact">
        <section className="Contact__container">

          <section className="Hero">
            {/* <section className="Hero__particles" /> */}
            <IconParticles />
            <section className="Hero__content">
              <h2 className="Hero__title">Contact</h2>
            </section>
          </section>

          <section className="MainContact">
            <section className="MainContact__content">
              {/* <section className="MainContact__content--left">
                <h2 className="MainContact__content--title">For brands</h2>
                <p className="MainContact__content--description">
                  Are you a game developer, gaming startup, publisher, marketing/growth agency, or brand? Do you have a game or product you want to promote? Awesome, we can help! To start, fill out this form below and we’ll get in touch. Let’s make some Noiz!
                </p>
                <ContactMainForm onSubmit={this.onSubmit.bind(this)} />
              </section> */}
              <section className="MainContact__content--right">
                <h2 className="MainContact__content--title">For streamers</h2>
                <p className="MainContact__content--description">
                  Have a general question about Noiz? Contact us at <a rel="noopener noreferrer" target="_blank" href="mailto:hello@noiz.gg">hello@noiz.gg</a> or you can join us on <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/noizgg">Twitter</a> and <a rel="noopener noreferrer" target="_blank" href="https://discordapp.com/invite/N2PrJxK">Discord</a>! <br /><br />
                  <strong> Need support? Contact us at <a rel="noopener noreferrer" target="_blank" href="mailto:support@noiz.gg">support@noiz.gg </a></strong>
                </p>
                <section className="MainContact__art">
                  <div className="MainContact__decoration">
                    <div className="d1" />
                    <div className="d2" />
                    <div className="d3" />
                    <div className="d4" />
                    <div className="d5" />
                    <div className="d6" />
                    <div className="d7" />
                  </div>
                  <img className="MainContact__art-img" src={streamerContact} alt="Community Icon" />
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>);
  }
}

// Contact.propTypes = {
//
// };

export default Contact;
