import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { stableSort, getSorting } from '../../../../utils/functions';
import CancelIcon from '@material-ui/icons/Cancel';


const headCells = [
  {
    id: 'spotsAvailable', numeric: true, disablePadding: true, label: 'Spots Available',
  },
  {
    id: 'minimumAverageViewers', numeric: true, disablePadding: false, label: 'Min Average Viewers',
  },
  {
    id: 'paymentAmount', numeric: true, disablePadding: false, label: 'Payment Amount',
  },
  {
    id: 'noizPoints', numeric: true, disablePadding: true, label: 'ARKade Ticket',
  },
];

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontSize: '20px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.dark,
        fontSize: '20px',
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
        fontSize: '20px',
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
    fontSize: '20px',
  },
  mainTitle: {
    fontSize: '20px',
    fontWeight: 600,
  },

}));

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = property => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" >
          Selected
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,

};


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { payoutSelected, user } = props;

  return (
    <Toolbar
      className={classnames(classes.root, {
        [classes.highlight]: payoutSelected,
      })}
    >
      <div className={classes.title}>
        {payoutSelected !== '' ? (
          <Typography color="inherit" className={classes.mainTitle} variant="subtitle1">
            {user.username} - {payoutSelected ? '$' : ''}{payoutSelected || 'No Viewer Tier'}  Payout Selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Viewer Tiers
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>

        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>

      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ViewerTable(props) {
  const {
    viewerTiers: rows, user, setPaymentSelections, paymentInfo, member,
  } = props;
  // const { averageViewersAtJoin, username } = user;
  // console.log(user);
  const isMemberTier = !!member;
  // console.log(member, 'memberDownLow');

  const initValueTable = isMemberTier ? null : paymentInfo.value;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('minimumAverageViewers');
  const [selected, setSelected] = React.useState(initValueTable);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    setPaymentSelections(selected);
  });

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };


  const handleClick = (event, paymentAmount) => {
    if (paymentAmount === selected) {
      setSelected(null);
    } else {
      setSelected(paymentAmount);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = payment => selected === payment;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (member && selected) {
    setSelected(null);
  } else if (!member && !selected) {
    setSelected(initValueTable);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar payoutSelected={selected} user={user} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}

              order={order}
              orderBy={orderBy}

              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.paymentAmount);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // const alreadySelected = user.paymentAmount === row.paymentAmount;
                  return (
                    <TableRow
                      hover

                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.paymentAmount}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        { !isMemberTier && row.spotsInt ? <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={event => handleClick(event, row.paymentAmount)}
                        /> : <CancelIcon />}
                      </TableCell>


                      <TableCell align="right">{row.spotsAvailable}</TableCell>
                      <TableCell align="right">{row.minimumAverageViewers}</TableCell>
                      <TableCell align="right">{row.paymentAmount}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>


    </div>
  );
}
