import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

let guessedTimezone = false;

function localizeIncorrectDate(dt) {
  if (!guessedTimezone) {
    guessedTimezone = dayjs.tz.guess(); // Minimizing how often this is called
  }

  return dayjs.utc(dt)
    .tz('America/Los_Angeles', true) // Switching from UTC to PT without changing time
    .tz(guessedTimezone); // Localize to user's timezone
}

// Countries that use 12 hour clock
const countries = [
  'United Kingdom',
  'United States',
  'Canada',
  'Ireland',
  'Australia',
  'New Zealand',
  'India',
  'Pakistan',
  'Bangladesh',
  'Malaysia',
  'Malta',
  'Egypt',
  'Mexico',
  'Nepal',
  'Philippines',
  'Columbia',
  'Honduras',
  'Nicaragua',
  'Jordan',
];

let uses12HrClock = null;

function shouldUse12HrClock(user) {
  if (uses12HrClock === null) {
    uses12HrClock = false; // Default to 24hr clock - most countries use it
    const { country, region }
      = (user.ipRegistry && user.ipRegistry.data) // Use ipRegistry data if available
        ? [...user.ipRegistry.data].sort((a, b) => new Date(b.time) - new Date(a.time))[0] // Finds the latest entry
        : { country: user.country, region: null }; // Leaves open the possibility of people in Quebec seeing 12hr clock

    if (countries.includes(country) && region !== 'Quebec') {
      uses12HrClock = true;
    }
  }

  return uses12HrClock;
}

function token12v24(user) {
  return shouldUse12HrClock(user) ? 'h' : 'H';
}

export {
  dayjs,
  localizeIncorrectDate,
  shouldUse12HrClock,
  token12v24,
};
