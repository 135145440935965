import React, { useState } from 'react';
import {
  Backdrop,
  Button,
  Fade,
  FormLabel,
  Modal,
  TextField,
} from '@material-ui/core';

import { basicModal } from '../../utils/material_ui/Styles';

import './Platform.scss';

const Platform = (props) => {
  const {
    createAPlatform, modal, toggleModal, token,
  } = props;
  const classes = basicModal();

  const [fields, setFields] = useState({
    platform: '',
    baseService: '',
    storePageUrl: '',
  });
  const [errors, setErrors] = useState({
    platform: false,
    baseService: false,
    storePageUrl: false,
  });

  const validateFields = () => {
    const newErrors = {};

    if (!fields.platform || !fields.platform.trim().length) {
      newErrors.platform = true;
    }
    if (!fields.baseService || !fields.baseService.trim().length) {
      newErrors.baseService = true;
    }
    if (!fields.storePageUrl || !fields.storePageUrl.trim().length) {
      newErrors.storePageUrl = true;
    }

    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateFields()) {
      createAPlatform({
        platform: fields.platform.trim(),
        baseService: fields.baseService.trim(),
        storePageUrl: fields.storePageUrl.trim(),
      }, token);
      toggleModal();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        onClose={() => toggleModal()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <form autoComplete="off" onSubmit={handleSubmit} className="Platform">
              <FormLabel className="Platform__Title">Add a Platform:</FormLabel>
              <TextField
                className="Platform__Input"
                id="platform-platform"
                label="Platform"
                // variant="outlined"
                value={fields.platform}
                onChange={e => setFields({ ...fields, platform: e.target.value })}
                error={errors.platform}
              />
              <TextField
                className="Platform__Input"
                id="platform-base-service"
                label="Base Service"
                // variant="outlined"
                value={fields.baseService}
                onChange={e => setFields({ ...fields, baseService: e.target.value })}
                error={errors.baseService}
              />
              <TextField
                className="Platform__Input"
                id="platform-store-page-url"
                label="Store Page URL"
                // variant="outlined"
                value={fields.storePageUrl}
                onChange={e => setFields({ ...fields, storePageUrl: e.target.value })}
                error={errors.storePageUrl}
              />
              <Button variant="contained" color="primary" type="submit">Submit</Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Platform;
