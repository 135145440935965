import Axios from "axios";

import { simpleNotification } from "./notifications";
import { queryString } from "./functions";

const endpoints = {
  //brand user endpoints
  connectUserBrandPaymentOption: () => "/api/v1/user-brand/payment/connect",
  deleteUserBrandPaymentMethod: () => "/api/v1/user-brand/payment/delete-payment-method",
  enterArbitrationBrand: () => "/api/v1/user-brand/arbitration",
  getBrandQuestSummary: questId => `/api/v1/user-brand/quest/get-summary/${questId}`,
  getBrandUsersList: () => "/api/v1/user-brand/get-users",
  getQuestInvites: query => `/api/v1/user-brand/quest/invite/${queryString(query)}`,
  getUserBrandPaymentMethods: () => "/api/v1/user-brand/payment/get-payment-methods",
  getArbitrationStatusUserQuest: userQuestId => `/api/v1/user-brand/arbitration/${userQuestId}`,
  makePaymentForQuest: () => "/api/v1/user-brand/payment/make-a-payment",
  postQuestInvite: () => "/api/v1/user-brand/quest/invite",
  putAgreement: () => '/api/v1/user/agreement/accept',

  // contact us endpoints
  getContacts: (limit, page, sort) => `/api/v4/guest/get-all/${limit}/${page}/${sort}`,
  initialContact: () => '/api/v4/guest/initial-contact',
  updateContact: () => '/api/v4/guest/update-contact',

  // streamer endpoints
  calculatePaymentInfoForStreamer: (streamerId, questId) =>
    `/api/v2/user-brand/streamer/get-payment-info/${streamerId}/${questId}`,
  checkQuestCompletionStreamer: userQuestId =>
    `/api/v1/user-brand/streamer/check-quest-completion/${userQuestId}`,
  connectStreamerBankAccount: region => `/api/v1/user/payment/connect/${region}`,
  createProductUserBrand: () => "/api/v1/user-brand/game/create",
  discoverStreamers: pagination => `/api/v1/user-brand/streamer/discover${queryString(pagination)}`,
  deleteStreamerStripeAccount: () => "/api/v1/user/payment/delete-stripe-account",
  forceSullyGnomeUpdate: userId => `/api/v4/user/force-sullygnome-update/${userId}`,
  getAllAgreements: () => "/api/v1/user/agreement/get-all",
  getContentMediaTypes: questId =>
    `/api/v1/user/quest/get-content-media-types/${questId}`,
  getListOfCountries: () => "/api/v1/stripe/countries",
  getStreamerBankAccounts: () => "/api/v1/user/payment/list-bank-accounts",
  loginToStripe: () => "/api/v1/user/payment/login-to-stripe",
  payStreamer: () => "/api/v1/user-brand/payment/pay-streamer",

  // admin endpoints
  adminGetArbitration: (status, pageSize, numPage, sort, search) =>
    `/api/v1/admin/arbitration${queryString({ status, pageSize, numPage, sort, search })}`,
  adminGetUserCounts: (startDate, endDate) =>
    `/api/v4/admin/users/get-new-user-counts${queryString({ startDate, endDate })}`,
};

const errorFailover = "Error failover, no error on response";

const axiosWrapper = async (endpoint, method, data = {}, notifyUser = false) => {
  try {
    const res = await Axios({
      method,
      url: endpoint,
      data,
    });
    if (notifyUser) {
      simpleNotification({
        level: "success",
        title: "Success!",
        message: res?.data?.message || "It worked!",
      });
    }
    return res;
  } catch (err) {
    const message = err?.response?.data?.message || errorFailover;
    if (message === 'You are not brand or noiz') {
      window.location.href = '/logout';
    }

    simpleNotification({
      level: "error",
      title: "Oops, there was a problem",
      message,
    });
  }
};

const axiosWrapperRedirect = async (endpoint, method, data = {}) => {
  try {
    const toRedirect = await Axios({
      method,
      url: endpoint,
      data,
    });
    const {
      data: { data },
    } = toRedirect;
    window.open(data, "_blank");
  } catch (err) {
    simpleNotification({
      level: "error",
      title: "Could Not Redirect",
      message: err?.response?.data?.message || errorFailover,
    });
  }
};

export const calculatePaymentInfoForStreamer = async (streamerId, questId) =>
  await axiosWrapper(endpoints.calculatePaymentInfoForStreamer(streamerId, questId), "GET");

export const checkQuestCompletionStreamer = async userQuestId =>
  await axiosWrapper(endpoints.checkQuestCompletionStreamer(userQuestId), "GET");

export const connectUserBrandPaymentOption = async () =>
  await axiosWrapperRedirect(endpoints.connectUserBrandPaymentOption(), "GET");

export const connectStreamerBankAccount = async region =>
  await axiosWrapperRedirect(endpoints.connectStreamerBankAccount(region), "GET");

export const createProductUserBrand = async data =>
  await axiosWrapper(endpoints.createProductUserBrand(), "POST", data, true);

export const deleteStreamerStripeAccount = async () =>
  await axiosWrapper(endpoints.deleteStreamerStripeAccount(), "DELETE");

export const deleteUserBrandPaymentMethod = async paymentId =>
  await axiosWrapper(endpoints.deleteUserBrandPaymentMethod(), "DELETE", { paymentId });

export const discoverStreamers = async pagination =>
  await axiosWrapper(endpoints.discoverStreamers(pagination), "GET");

export const enterArbitrationBrand = async data =>
  await axiosWrapper(endpoints.enterArbitrationBrand(), "POST", data, true);

export const forceSullyGnomeUpdate = async userId =>
  await axiosWrapper(endpoints.forceSullyGnomeUpdate(userId), "GET", {}, true);

export const getAllAgreements = async () => await axiosWrapper(endpoints.getAllAgreements(), "GET");

export const getArbitrationStatusUserQuest = async userQuestId =>
  await axiosWrapper(endpoints.getArbitrationStatusUserQuest(userQuestId), "GET");
  
export const getBrandQuestSummary = async questId =>
  await axiosWrapper(endpoints.getBrandQuestSummary(questId), "GET");

export const getBrandUsersList = async () =>
  await axiosWrapper(endpoints.getBrandUsersList(), "GET");

export const getContacts = async (limit = 10, page = 0, sort = 'createdAt DESC') =>
  await axiosWrapper(endpoints.getContacts(limit, page, sort), "GET");

export const getContentMediaTypes = async questId =>
  await axiosWrapper(endpoints.getContentMediaTypes(questId), "GET");

export const getQuestInvites = async query =>
  await axiosWrapper(endpoints.getQuestInvites(query), "GET");

export const getStreamerBankAccounts = async () =>
  await axiosWrapper(endpoints.getStreamerBankAccounts(), "GET");

export const getStripeCountries = async () =>
  await axiosWrapper(endpoints.getListOfCountries(), "GET");

export const getUserBrandPaymentMethods = async () =>
  await axiosWrapper(endpoints.getUserBrandPaymentMethods(), "GET");

export const initialContact = async data =>
  await axiosWrapper(endpoints.initialContact(), "POST", data, true);

export const loginToStripe = async () =>
  await axiosWrapperRedirect(endpoints.loginToStripe(), "GET");

export const makePaymentForQuest = async questId =>
  await axiosWrapper(endpoints.makePaymentForQuest(), "POST", { questId });

export const payStreamer = async userQuestId =>
  await axiosWrapper(endpoints.payStreamer(), "POST", { userQuestId }, true);

export const postQuestInvite = async body =>
  await axiosWrapper(endpoints.postQuestInvite(), "POST", body, true);

export const putAgreement = async (type, version) =>
  await axiosWrapper(endpoints.putAgreement(), "PUT", { type, version }, true);

export const updateContact = async data =>
  await axiosWrapper(endpoints.updateContact(), "PUT", data, true);

//
// admin endpoints
//
export const adminGetArbitration = async (status = "pending", pageSize = 15, numPage = 1, sort = 'desc', search = '') =>
  await axiosWrapper(endpoints.adminGetArbitration(status, pageSize, numPage, sort, search), "GET");

export const adminGetUserCounts = async (startDate, endDate) =>
  await axiosWrapper(endpoints.adminGetUserCounts(startDate, endDate), "GET");
