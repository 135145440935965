/* eslint-disable import/prefer-default-export */
export const uploadCSV = (multipartFormData, token) => ({
  type: 'UPLOAD_CSV',
  payload: {
    multipartFormData,
    token,
  },
});


export const getGameLiveStreams = (gameId, token) => ({
  type: 'GET_GAMES_LIVE_STREAMS',
  payload: {
    gameId,
    token,
  },
});

export const getGameBountiesData = (gameId, token) => ({
  type: 'GET_GAME_BOUNTIES_DATA',
  payload: {
    gameId,
    token,
  },
});

export const getAUserList = (pageNum, filter, sorting, token, exactMatch) => ({
  type: 'GET_A_USER_LIST',
  payload: {
    pageNum,
    filter,
    sorting,
    token,
    exactMatch,
  },
});

export const getAUserListCsv = (pageNum, filter, sorting, token) => ({
  type: 'GET_A_USER_LIST_CSV',
  payload: {
    pageNum,
    filter,
    sorting,
    token,
  },
});

export const getAUser = (userId, token) => ({
  type: 'GET_A_USER',
  payload: {
    userId,
    token,
  },
});

export const createAUser = (user, token) => ({
  type: 'CREATE_A_USER',
  payload: {
    user,
    token,
  },
});

export const createAUserBrand = (user, token) => ({
  type: 'CREATE_A_USER_BRAND',
  payload: {
    user,
    token,
  },
});

export const updateAUser = (userId, user, token) => ({
  type: 'UPDATE_A_USER',
  payload: {
    userId,
    user,
    token,
  },
});

export const getAConfigList = token => ({
  type: 'GET_A_CONFIG_LIST',
  payload: {
    token,
  },
});

export const getAConfig = (configId, token) => ({
  type: 'GET_A_CONFIG',
  payload: {
    configId,
    token,
  },
});

export const createAConfig = (config, token) => ({
  type: 'CREATE_A_CONFIG',
  payload: {
    config,
    token,
  },
});

export const updateAConfig = (config, token) => ({
  type: 'UPDATE_A_CONFIG',
  payload: {
    config,
    token,
  },
});

export const getAdminQuestListByGame = (gameId, token) => ({
  type: 'GET_ADMIN_QUEST_LIST_BY_GAME',
  payload: {
    gameId,
    token,
  },
});

export const getAGameList = (token) => ({
  type: 'GET_A_GAME_LIST',
  payload: {
    token,
  },
});

export const getAGame = (gameId, token) => ({
  type: 'GET_A_GAME',
  payload: {
    gameId,
    token,
  },
});

export const createAGame = (game, token) => ({
  type: 'CREATE_A_GAME',
  payload: {
    game,
    token,
  },
});

export const updateAGame = (gameId, game, token) => ({
  type: 'UPDATE_A_GAME',
  payload: {
    gameId,
    game,
    token,
  },
});

export const createAPlatform = (platform, token) => ({
  type: 'CREATE_A_PLATFORM',
  payload: {
    platform,
    token,
  },
});

export const getAllPlatforms = token => ({
  type: 'GET_ALL_PLATFORMS',
  payload: {
    token,
  },
});

export const updateAFeatured = (featuredGames, token) => ({
  type: 'UPDATE_A_FEATURED',
  payload: {
    featuredGames,
    token,
  },
});

export const requestUploadImageUrl = (slug, format, token) => ({
  type: 'REQUEST_UPLOAD_IMAGE_URL',
  payload: {
    slug,
    format,
    token,
  },
});

export const getATrackedGames = token => ({
  type: 'GET_A_TRACKED_GAMES',
  payload: {
    token,
  },
});

export const getACampaign = (campaignId, token) => ({
  type: 'GET_A_CAMPAIGN',
  payload: {
    campaignId,
    token,
  },
});

export const getACampaignList = token => ({
  type: 'GET_A_CAMPAIGN_LIST',
  payload: {
    token,
  },
});

export const getACampaignQuestsReport = (campaignId, token) => ({
  type: 'GET_A_CAMPAIGN_QUESTS_REPORT',
  payload: {
    campaignId,
    token,
  },
});

export const getAPaymentList = (pageNum, filter, sorting, token) => ({
  type: 'GET_A_PAYMENT_LIST',
  payload: {
    pageNum,
    filter,
    sorting,
    token,
  },
});

export const getAPaymentListCsv = (pageNum, filter, sorting, token) => ({
  type: 'GET_A_PAYMENT_LIST_CSV',
  payload: {
    pageNum,
    filter,
    sorting,
    token,
  },
});

export const getPaypalPayments = token => ({
  type: 'GET_PAYPAL_PAYMENTS',
  payload: {
    token,
  },
});

export const getAQuestList = token => ({
  type: 'GET_A_QUEST_LIST',
  payload: {
    token,
  },
});

export const getInsightsData = (filters, timeData, token) => ({
  type: 'GET_INSIGHTS_DATA',
  payload: {
    filters,
    timeData,
    token,
  },
});

export const getInsightsOptions = token => ({
  type: 'GET_INSIGHTS_OPTIONS',
  payload: {
    token,
  },
});

export const createOffPlatformUser = (twitchLogin, email, paymentEmail, first, last, token) => ({
  type: 'CREATE_OFF_PLATFORM',
  payload: {
    twitchLogin, email, paymentEmail, first, last, token,
  },
});
