import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { basicModal } from '../../../../utils/material_ui/Styles';
import { numeralFilterDecimals } from '../../../../utils/functions';

export default function ConfirmModal(props) {
  const classes = basicModal();


  const [memberTier, setMemberTier] = React.useState(props.member);

  const [open, setOpen] = React.useState(false);
  const validPayment = memberTier ? memberTier.paymentAmount : 0;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const cleaned = numeralFilterDecimals(value);
    const m = { identity: props.username, paymentAmount: cleaned };
    setMemberTier(m);
    // console.log(value);
  };

  const onSubmit = () => {
    const { submitCallback } = props;
    memberTier.paymentAmount = Number(memberTier.paymentAmount).toFixed(2);
    submitCallback(memberTier);
    handleClose();
  };


  return (
    <div>
      <IconButton onClick={handleOpen} size="small" >
        { props.add ? <AddIcon size="small" /> : <EditIcon size="small" /> }
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >


        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Enter New Member Tier Payment</h2>
            <Grid style={{ marginBottom: '30px' }}>
              <div className="edit-modal-input-override">
                <TextField
                  id="paymentAmount"
                  label="New Payment Amount"
                  type="text"
                  value={validPayment}
                  onChange={e => handleChange(e)}
                  className={`${classes.textField}`}
                />
              </div>


            </Grid>
            <Grid style={{ marginBottom: '30px' }} />
            <Grid
              container
              className={classes.buttonGrid}
              xs={12}
              direction="row"
              width="100%"
              justify="space-between"
              alignItems="center"
            >

              <Grid>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                >
                  Accept
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={handleClose}
                  className={classes.errorButton}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
