import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

import Icon from "../../../../../global/images/icons/Icon";
// import Loading from "../loading/Loading";
import { enterArbitrationBrand } from "../../../../../utils/apiv2";
import CustomCheckbox from "../../CustomInputs/CustomCheckbox";
import { getDefaultIcon } from "../../../../../utils/functions";
import classnames from "classnames";
import "./Arbitration.scss";
import "./ReviewDeliverable.scss";
const streamTimeId = "requiredStreamTimeMinutes"; // since no id exists for this on the model. 

const StreamerDeliverablesCard = StreamerDeliverablesProps => {
  const { content, subtype, timeRequired, title, type, quest, getter, setter, id } =
    StreamerDeliverablesProps;
  const clickable = content && !!!timeRequired;

  // let sdCardStatus = "Incomplete";
  // switch (status) {
  //   case "completed":
  //     sdCardStatus = "Completed"
  //     break;
  //   case "rejected":
  //     sdCardStatus = "Rejected";
  //     break;
  //   case "to-review":
  //     if (!timeRequired) sdCardStatus = "Pending";
  //     break;
  //   default:
  //     break;
  // }
  const checked = getter(id);
  return (
    <div className={classnames("StreamerDeliverablesCard", clickable && "Clickable")}>
      {timeRequired ? (
        <Icon name='clock' />
      ) : (
        <img
          className='StreamerDeliverablesCard__Icon'
          src={getDefaultIcon(type, subtype, quest)}
          alt={title}
        />
      )}
      <div className='StreamerDeliverablesCard__Title'>{title}</div>
      <div className={classnames("StreamerDeliverablesCard__Completion")}>
        <CustomCheckbox checked={checked} onChange={() => setter(id || streamTimeId, !checked)} />
      </div>
    </div>
  );
};

const StreamerDeliverables = ({ deliverables, getter, setter }) => (
  <div className='StreamerDeliverables'>
    <div className='StreamerDeliverables__Title'>Select Disputed Requirements</div>
    <div className='StreamerDeliverables__List'>
      {deliverables.map(qr => (
        <StreamerDeliverablesCard getter={getter} setter={setter} key={qr.id} {...qr} />
      ))}
    </div>
  </div>
);

const Arbitration = props => {
  const {
    open,
    setOpen = () => console.log("you need to set a callback to close the ConfirmModal"),
    requirements = [],
    setArbitration = () => console.log("you need to set a callback to set arbitration"),

  } = props;
  const handleClose = () => setOpen(false);
  const mustSelectReq = "You must select at least one requirement to dispute";
  const mustAddComment = "You must provide some detail about the unsatisfactory deliverable";
  const [selectedDeliverables, setSelectedDeliverables] = useState([]);
  const [comments, setComments] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errors, setErrors] = useState([]);
  const onTextAreaChange = e => setComments(e.target.value);

  const addRemoveDeliverable = id => {
    if (selectedDeliverables.includes(id)) {
      setSelectedDeliverables(selectedDeliverables.filter(d => d !== id));
    } else {
      setSelectedDeliverables([...selectedDeliverables, id]);
    }
  };

  const containsDeliverable = id => selectedDeliverables.includes(id);
  
  console.log(comments, selectedDeliverables);
  const closeAndZeroOut = () => {
    setComments("");
    setSelectedDeliverables([]);
    setAgreedToTerms(false);
    setErrors([]);
    setArbitration();
    handleClose();
  };

  const submitToArbitration = async () => {
    const errors = []
    if (selectedDeliverables.length === 0) {
      errors.push(mustSelectReq);
    }
    if (comments.length === 0) {
      errors.push(mustAddComment);
    }
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    setErrors(errors)
    const data = {
      disputedRequirements: selectedDeliverables,
      disputeReason: comments,
      userquestId: props?.userQuest?.id
    };

   await enterArbitrationBrand(data);
   closeAndZeroOut()
    


    console.log(props, "props");
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <Box className='BrandArbitration_ModalBox'>
        <div className='BrandArbitration_Deliverables ReviewDeliverable'>
          <StreamerDeliverables
            deliverables={requirements}
            setter={addRemoveDeliverable}
            getter={containsDeliverable}
          />
        </div>

        <div className='BrandArbitration_Comments'>
          <h1>Comments</h1>
          <textarea
            onChange={onTextAreaChange}
            placeholder='Enter your comments here, please be a thorough as possible'>
            {comments}
          </textarea>
          <div className='BrandArbitration_AgreementText'>
            <CustomCheckbox
              checked={agreedToTerms}
              onChange={() => setAgreedToTerms(!agreedToTerms)}
            />
            &nbsp; I agree to submit to the NOIZ arbitration process and abide by the subsequent
            decision
          </div>
          <ul className="BrandArbitration_ErrorList">
            {errors.map((e, i) => (
              <li key={i} className='BrandArbitration_Error'>
                {e}
                </li>
            ))}
          </ul>
          <button className={`BrandArbitration_RejectButton ${agreedToTerms ? 'Active' : null} `}  onClick={submitToArbitration}> Start NOIZ Review </button>
        </div>
      </Box>
    </Modal>
  );
};

export default Arbitration;
