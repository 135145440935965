import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import CustomInputCheckbox from '../../utils/redux-forms-custom-fields/CustomInputCheckbox';
import CustomDropDownGeneric from '../../utils/redux-forms-custom-fields/CustomDropDownGeneric';
import CustomDateTimePicker from '../../utils/redux-forms-custom-fields/CustomDateTimePicker';
import CustomDropDown from '../../utils/redux-forms-custom-fields/CustomDropDown';
import { simpleNotification } from '../../utils/notifications';
import {
  getCorrectTwitchAverages,
} from '../../utils/functions';

const dateStoreFormat = 'YYYY-MM-DD';
const dateDisplayFormat = 'MM-DD-YYYY';

class AdminUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      twitchConnected: true,
      twitterConnected: true,
    };
  }

  onDisconnectTwitch = () => {
    this.setState({
      twitchConnected: false,
    });
    simpleNotification({
      level: 'warning',
      title: 'Disconnect twitch',
      message: 'Save button must be clicked in order to disconnect twitch successfully',
    });
  }

  onDisconnectTwitter = () => {
    this.setState({
      twitterConnected: false,
    });
    simpleNotification({
      level: 'warning',
      title: 'Disconnect twitter',
      message: 'Save button must be clicked in order to disconnect twitter successfully',
    });
  }

  submit = (info) => {
    const {
      twitchConnected,
      twitterConnected,
    } = this.state;
    this.props.onSubmit(info, twitchConnected, twitterConnected);
  }

  render() {
    const { handleSubmit, adminUserForm } = this.props;
    const {
      twitchConnected,
      twitterConnected,
    } = this.state;
    let recoveryToken = '';
    let AVGViewers = '';
    try {
      recoveryToken = (adminUserForm && adminUserForm.values) ? adminUserForm.values.recoveryToken : '';

      if (this.props.adminUserForm) {
        const { values } = adminUserForm;
        AVGViewers = getCorrectTwitchAverages(values, true).correctAverage;
      }
    } catch (err) {
      console.log(err);
    }
    const recoveryLink = (recoveryToken !== '') ? `${window.location.origin}/recover?recovery-token=${recoveryToken}` : '';

    return (
      <form className="form-container admin-user" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Fragment>
        <div className="input-container">
                <label>User ID</label>
                <div className="input-wrapper">
                  <input autoComplete="off" id="recoveryLink" name="recoveryLink" type="text" placeholder="" className="ok empty" value={this.props.currUserId} />
                </div>
              </div>
          <Field
            type="text"
            name="firstName"
            id="firstName"
            placeholder="First Name"
            customLabel={(<label>First Name</label>)}
            component={CustomInput}
          />
          <Field
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Last Name"
            customLabel={(<label>Last Name</label>)}
            component={CustomInput}
          />
          <Field
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            customLabel={((<label>Username</label>))}
            component={CustomInput}
          />
          <Field
            type="text"
            name="email"
            id="email"
            placeholder="Email"
            customLabel={(<label>Email</label>)}
            component={CustomInput}
          />
          <Field
            name="role"
            id="role"
            placeholder="Select Role"
            customLabel={((<label>Role</label>))}
            component={CustomDropDownGeneric}
            options={['user', 'admin', 'publisher', 'helper', 'brand']}
          />
          { adminUserForm && adminUserForm.values && adminUserForm.values.role === 'brand' ? (
            <Field
              type="text"
              name="company"
              id="company"
              placeholder="Company Name"
              customLabel={((<label>Company Name</label>))}
              component={CustomInput}
            />
          ) : null }
          <Field
            name="status"
            id="status"
            placeholder="Select Status"
            customLabel={((<label>Status</label>))}
            component={CustomDropDownGeneric}
            options={['active', 'on wait list', 'deleted']}
          />
          <Field
            type="text"
            name="birthDate"
            id="birthDate"
            placeholder="Birth Date"
            dateStoreFormat={dateStoreFormat}
            dateDisplayFormat={dateDisplayFormat}
            customLabel={(<label className="datepicker-label">Birth Date</label>)}
            component={CustomDateTimePicker}
          />
          <Field
            type="text"
            name="country"
            id="country"
            customLabel={(<label>Country</label>)}
            component={CustomDropDown}
          />
          <Field
            type="text"
            name="language"
            id="language"
            customLabel={(<label>Language</label>)}
            component={CustomInput}
          />
          {/* <Field
            type="text"
            name="twitter"
            id="twitter"
            customLabel={(<label>Twitter</label>)}
            component={CustomInput}
          /> */}
          <Field
            type="text"
            name="discord"
            id="discord"
            customLabel={(<label>Discord</label>)}
            component={CustomInput}
          />
          <Field
            name="blacklist"
            id="blacklist"
            customLabel={((<label>Blacklist</label>))}
            component={CustomInputCheckbox}
          />
              <Field
                name="offPlatform"
                id="offPlatform"
                customLabel={((<label>User is Off Platform</label>))}
                component={CustomInputCheckbox}
              />
          <Field
            type="textarea"
            name="notes"
            id="notes"
            customLabel={(<label>Notes</label>)}
            className="extra-big-text-area"
            component={CustomInput}
          />
        </Fragment>

        {/* { (adminUserForm && adminUserForm.values &&
          adminUserForm.values.firstName &&
          adminUserForm.values.lastName &&
          adminUserForm.values.username &&
          adminUserForm.values.email) && (
          <Fragment>
            <div className="input-container">
              <label>First Name</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.firstName}`} className="touched ok not-empty" disabled />
              </div>
            </div>
            <div className="input-container">
              <label>Last Name</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.lastName}`} className="touched ok not-empty" disabled />
              </div>
            </div>
            <div className="input-container">
              <label>Username</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.username}`} className="touched ok not-empty" disabled />
              </div>
            </div>
            <div className="input-container">
              <label>Email</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.email}`} className="touched ok not-empty" disabled />
              </div>
            </div>
        
          </Fragment>
        )
      } */}
        { (twitchConnected && adminUserForm && adminUserForm.values &&
          adminUserForm.values.connectedAccounts && adminUserForm.values.connectedAccounts.twitch &&
          adminUserForm.values.connectedAccounts.twitch.username) &&
          <Fragment>
            <div className="AUserForm__disconnectPlatform--container" onClick={this.onDisconnectTwitch.bind(this)} aria-hidden>
              <div className="AUserForm__disconnectPlatform">Disconnect Twitch Account</div>
            </div>
            <div className="input-container">
              <label>Twitch Username</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.connectedAccounts.twitch.username}`} className="touched ok not-empty" disabled />
              </div>
            </div>
            <Fragment>
              <div className="input-container">
                <label>Actual Average Twitch Viewers</label>
                <div className="input-wrapper">
                  <input autoComplete="off" id="recoveryLink" name="recoveryLink" type="text" placeholder="" className="ok empty" disabled value={Math.round(AVGViewers)} />
                </div>
              </div>
              {/* <Field
                type="text"
                name="connectedAccounts.twitch.AVGViewers"
                id="connectedAccounts.twitch.AVGViewers"
                placeholder="Average Viewers"
                customLabel={(<label>Twitch Average Viewers</label>)}
                component={CustomInput}

              /> */}

              <Field
                name="freezeTwitchViewers.isFrozen"
                id="freezeTwitchViewers.isFrozen"
                customLabel={((<label>Freeze Twitch Viewers</label>))}
                component={CustomInputCheckbox}
              />
              <Field
                type="text"
                name="twitchAVGOverride"
                id="twitchAVGOverride"
                placeholder="0"
                customLabel={((<label>Twitch Average Viewer Override</label>))}
                component={CustomInput}
              />
            </Fragment>
          </Fragment>
        }

        {twitterConnected && adminUserForm && adminUserForm.values
          && adminUserForm.values.connectedAccounts && adminUserForm.values.connectedAccounts.twitter
          && adminUserForm.values.connectedAccounts.twitter.username &&
          <Fragment>
            <div className="AUserForm__disconnectPlatform--container" onClick={this.onDisconnectTwitter.bind(this)} aria-hidden>
              <div className="AUserForm__disconnectPlatform">Disconnect Twitter Account</div>
            </div>
            <div className="input-container">
              <label>Twitter Username</label>
              <div className="input-wrapper">
                <input type="text" value={`${adminUserForm.values.connectedAccounts.twitter.username}`} className="touched ok not-empty" disabled />
              </div>
            </div>
          </Fragment>
        }

        <div className="input-container">
          <label>Password Recovery Link</label>
          <div className="input-wrapper">
            <input autoComplete="off" id="recoveryLink" name="recoveryLink" type="text" placeholder="" className="ok empty" disabled value={recoveryLink} />
          </div>
        </div>

        <div className="form__footer">
          <button className="AUserForm__save--btn">Save</button>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.username || values.username.trim() === '') {
    errors.username = 'This field is required';
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  }
  if (!values.role || values.role.trim() === '') {
    errors.role = 'This field is required';
  }
  if (!values.status || values.status.trim() === '') {
    errors.status = 'This field is required';
  }
  return errors;
};


AdminUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'adminUserForm',
  validate,
})(AdminUserForm);
