import axios from 'axios';
import { simpleNotification } from '../../../utils/notifications';
import { buildDashboard, chunk } from './helpers';

const genHeaders = token => ({
  Authorization: `JWT ${token}`,
  'Content-Type': 'application/json',
});

async function getCompleteUsers(payload, callback) {
  const { token, userIds } = payload;
  try {
    const response = await axios.post('/api/admin/insights/get-complete-users', {
      userIds,
    }, {
      headers: genHeaders(token),
    });

    if (callback) {
      callback(response.data.completedUsers);
    } else {
      return response.data.completedUsers;
    }
  } catch (error) {
    console.error(error);
    if (callback) {
      callback({});
    } else {
      return false;
    }
  }
};

async function getInitialData(payload, callback) {
  const { isReload, token } = payload;
  try {
    const response = await axios.get('/api/admin/insights/get-initial-data', {
      headers: genHeaders(token),
    });

    if (isReload) {
      simpleNotification({
        level: 'success',
        title: 'Insights Reload Success',
        message: 'User & game data has been successfully reloaded.',
      });
    }
    callback(response.data);
  } catch (error) {
    console.error(error);
    callback([]);
  }
};

const CHUNK_SIZE = 1000;

async function getInsights(payload, callback) {
  const { allUsers, dateRange, filtered, selected, token, users } = payload;

  if (users.length === 0) {
    simpleNotification({
      level: 'error',
      title: 'Warning.',
      message: 'No users match your filters!',
    });
    return false;
  }

  if (!filtered) {
    if (!window.confirm('You have not applied any filters! Do you want to send this query?')) {
      callback({});
      return false;
    }
  }

  // If all users, send empty user chunk
  const chunkedUsers = allUsers ? [null] : chunk(users.map(u => u.twitchUsername), CHUNK_SIZE);
  const insightBatches = chunkedUsers.map(async users => {
    try {
      const response = await axios.post('/api/admin/insights/get-insights', {
        dateRange,
        games: selected.games,
        users,
      }, {
        headers: genHeaders(token),
      });

      return response.data.insightsData;
    } catch (error) {
      console.error(error);
      return [];
    }
  });

  const responses = await Promise.all(insightBatches);
  callback(buildDashboard(users, [].concat(...responses), selected.accv));
};

export {
  getCompleteUsers,
  getInitialData,
  getInsights,
};