import React from 'react';

import GamesPlayedModal from './GamesPlayedModal';

import gameIcon from '../../global/images/icons/game-icon.svg';

const GamesPlayed = (props) => {
  const { games = [] } = props;

  let gameHtml = (
    <span className="label">
      Play {games.slice(0, -1).join(', ')}{games.length > 2 && ','}{games.length > 1 && ' or '}{games.slice(-1)}
    </span>
  );

  if (games.length > 2) {
    gameHtml = <GamesPlayedModal games={games} />;
  }

  return (
    <div className="eligibilityItem make-visible">
      <img src={gameIcon} alt="eligibility item" />
      {gameHtml}
    </div>
  );
};

export default GamesPlayed;
