import React, { useEffect, useState } from 'react';

import { getUserAvatar, nFormatter, rounding } from '../../../../../utils/functions';
import { calculatePaymentInfoForStreamer } from '../../../../../utils/apiv2';
import { SetViewContext } from '../../PortalContext';

import CustomCheckbox from '../../CustomInputs/CustomCheckbox';
import Loading from '../../../../../global/components/loading/Loading';

import './FundQuestApplicant.scss';

const FundQuestApplicant = (props) => {
  const {
    checkout,
    // handleFundQuestModalChange,
    // showFundQuestModal,
    rejection = false,
    uq = {},
  } = props;

  const { quest = '', user = {} } = uq;
  const { connectedAccounts = {}, displayName = '', freezeTwitchViewers = {}, id: userId = '', statistics = {}, twitchAVGOverride = 0, username = '' } = user;

  const { isFrozen = false } = freezeTwitchViewers;
  const { AVG30 = 0 } = statistics;
  const avgViewers = isFrozen ? twitchAVGOverride : AVG30;

  const { twitch = {} } = connectedAccounts;
  const { followers = 0 } = twitch;

  const [calculatedPaymentInfo, setCalculatedPaymentInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const titleLang = rejection ? 'Reject and Refund' : 'Fund Your Quest';
  const handleAgreeToTerms = rejection ? "Reject streamer and issue me a refund less transaction fees" : "Pay the above and approve this streamer. Transaction fees are non-refundable"

  useEffect(() => {
    const calculatePaymentInfo = async () => {
      const data = await calculatePaymentInfoForStreamer(userId, quest);
      if (data?.data?.data) { setCalculatedPaymentInfo(data.data.data); }
    };

    calculatePaymentInfo();
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className='FundQuestApplicant'>
        <Loading />
      </div>
    );
  }

  const {
    noizCut = 0.1,
    seatPrice = 0,
    // seats = 1,
    stripeFee = 0,
    total: subTotal = 0,
  } = calculatedPaymentInfo;

  const total = rejection ? subTotal - stripeFee : subTotal; 
  const totalText = rejection ? 'Total to Refund:' : 'Total:';

  const CostBreakdownRender = ({ title = '', cost = 0 }) => (
    <div className='FundQuestApplicant__QuestInfo__CostBreakdown'>
      <div className='FundQuestApplicant__QuestInfo__CostBreakdown__Item'>{title}</div>
      <div className='FundQuestApplicant__QuestInfo__CostBreakdown__Cost'>${rounding(cost)}</div>
    </div>
  );

  const StatRender = ({ title = '', stat = 0 }) => (
    <div className='FundQuestApplicant__ApplicantInfo__Stats__Stat'>
      <div className='FundQuestApplicant__ApplicantInfo__Stats__Stat__Stat'>
        {stat}
      </div>
      <div className='FundQuestApplicant__ApplicantInfo__Stats__Stat__Title'>
        {title}
      </div>
    </div>
  );

  return (
    <div className="FundQuestApplicant">
      <div className='FundQuestApplicant__QuestInfo'>
        <div className='FundQuestApplicant__QuestInfo__Title'>{titleLang}</div>
        <div className='FundQuestApplicant__QuestInfo__Divider' />
        <CostBreakdownRender title='Streamer Fee:' cost={seatPrice} />
        <CostBreakdownRender title='Noiz Fee:' cost={noizCut * seatPrice} />
       {!rejection && <CostBreakdownRender title='Transaction Fee:' cost={stripeFee} /> }
        <div className='FundQuestApplicant__QuestInfo__Divider' />
        <div className='FundQuestApplicant__QuestInfo__Total'>
          <div className='FundQuestApplicant__QuestInfo__Total__Item'>{totalText}</div>
          <div className='FundQuestApplicant__QuestInfo__Total__Cost'>${rounding(total)}</div>
        </div>
        <CustomCheckbox
          header={handleAgreeToTerms}
          name="showFundQuestModal"
          value={agreedToTerms}
          onChange={() => setAgreedToTerms(!agreedToTerms)}
        />
        <div
          className={`FundQuestApplicant__QuestInfo__Button ${!agreedToTerms ? 'disabled' : 'null'}`}
          disabled={!agreedToTerms}
          onClick={agreedToTerms ? checkout : null}>
          CHECKOUT 
        </div>
      </div>
      <div className='FundQuestApplicant__ApplicantInfo'>
        <img className='FundQuestApplicant__ApplicantInfo__Img' src={getUserAvatar(user)} alt='avatar' />
        <div className='FundQuestApplicant__ApplicantInfo__Name'>
          {displayName || username}
        </div>
        <div className='FundQuestApplicant__ApplicantInfo__FeeText'>STREAMER FEE:</div>
        <div className='FundQuestApplicant__ApplicantInfo__FeeAmount'>${seatPrice}</div>
        <div className='FundQuestApplicant__ApplicantInfo__Stats'>
          <StatRender title='ACCV' stat={nFormatter(avgViewers)} />
          <StatRender title='Followers' stat={nFormatter(followers)} />
        </div>
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <div
              className='FundQuestApplicant__ApplicantInfo__ViewProfile'
              onClick={() => setView({
                tab: view.tab,
                component: 'StreamerProfile',
                currentUser: username,
              })}
            >
              View Profile
            </div>
          )}
        </SetViewContext.Consumer>
      </div>
    </div>
  );
};

export default FundQuestApplicant;
