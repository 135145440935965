/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import '../contact/Contact.scss';

class ContactLightForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (userFormInfo) => {
    this.props.onSubmit(userFormInfo);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form-container contact-light" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="text"
          name="email"
          id="email"
          placeholder="Email Address*"
          customLabel2={(<label data-label="Email Address*" data-label-small="Your email address here" />)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="contactName"
          id="contactName"
          placeholder="Contact Name*"
          customLabel2={(<label data-label="Contact Name*" data-label-small="Your contact name here" />)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="website"
          id="website"
          placeholder="Website"
          customLabel2={(<label data-label="Website" data-label-small="Your website here" />)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="companyName"
          id="companyName"
          placeholder="Company Name*"
          customLabel2={(<label data-label="Company Name*" data-label-small="Your company name here" />)}
          component={CustomInput}
        />
        <Field
          type="textarea"
          name="comments"
          id="comments"
          rows="3"
          placeholder="Tell Us Anything!*"
          customLabel2={(<label data-label="Tell Us Anything!*" data-label-small="Tell Us Anything!" />)}
          component={CustomInput}
        />
        <div className="form__footer grid-x">
          <div className="form__footer__left large-5">
            <button className="cta-button">
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.contactName || values.contactName.trim() === '') {
    errors.contactName = 'This field is required';
  }
  if (!values.companyName || values.companyName.trim() === '') {
    errors.companyName = 'This field is required';
  }
  if (!/^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/i.test(values.website)) {
    if (!(!values.website || values.website.trim() === '')) {
      errors.website = 'Invalid website URL';
    }
  }
  if (!values.comments || values.comments.trim() === '') {
    errors.comments = 'This field is required';
  }
  return errors;
};


ContactLightForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'contactLightForm',
  validate,
})(ContactLightForm);
