import React from 'react';

// import { rounding } from '../../../../utils/functions';

import './CustomInputs.scss';

const CustomNumber = (props) => {
  const {
    header,
    name,
    value,
    onChange,
    error,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    step = 1,
  } = props;

  const handleChange = (e) => {
      onChange(name, e.target.value > 9999 ? 9999 : Number(e.target.value));
  };

  return (
    <div className="CustomInput">
      {!!header && <div className="CustomInput__Header">{header}</div>}
      <input
        className={`CustomInput__TextField InputClass ${error && 'Error'}`}
        max={max}
        min={min}
        name={name}
        onChange={handleChange}
        step={step}
        type="number"
        value={value}
      />
    </div>
  );
};

export default CustomNumber;
