import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

import { getContentMediaTypes } from '../../../utils/apiv2';

const ContentMediaSelector = props => {
  const {
    handleChange,
    quest,
    reqId,
    value,
  } = props;

  const [loading, setLoading] = useState(true);
  const [contentMediaTypes, setContentMediaTypes] = useState([]);

  useEffect(() => {
    fetchContentMediaTypes();
  }, []);

  const fetchContentMediaTypes = () => {
    setLoading(true);
    const fetchData = async () => {
      const res = await getContentMediaTypes(quest.id);
      setContentMediaTypes(res?.data?.contentMediaTypes || []);
      setLoading(false);
    };
    fetchData();
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const renderContentMediaTypes = () => {
    return contentMediaTypes.map((contentMediaType, index) => {
      return (
        <MenuItem
          key={index}
          value={contentMediaType.value}
        >
          {contentMediaType.label}
        </MenuItem>
      )
    })
  };

  if (!loading && !contentMediaTypes.length) {
    return null;
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Type</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        defaultValue=""
        value={value}
        label="Type"
        onChange={e => handleChange(e.target.value, reqId)}
      >
        {loading ? <i className="fa fa-spinner fa-spin fa-3x fa-fw" /> :
          renderContentMediaTypes()
        }
      </Select>
    </FormControl>
  );
};

export default ContentMediaSelector;
