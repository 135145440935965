import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// import Tooltip from '@material-ui/core/Tooltip';

import { simpleNotification } from '../../../../../utils/notifications';
import { makeProper } from '../../../../../utils/functions';
import { getUserBrandPaymentMethods, getAllAgreements, putAgreement } from '../../../../../utils/apiv2';
import { SetViewContext } from '../../PortalContext';

import eula from '../../../../../global/text/brand-quest-eula';
import AddImage from '../../CustomInputs/AddImage';
import CustomDate from '../../CustomInputs/CustomDate';
import CustomDropdown from '../../CustomInputs/CustomDropdown';
import CustomModal from '../../CustomInputs/CustomModal';
import CustomNumber from '../../CustomInputs/CustomNumber';
import CustomTextArea from '../../CustomInputs/CustomTextArea';
import CustomTextField from '../../CustomInputs/CustomTextField';
// import Icon from '../../../../../global/images/icons/Icon';
import LargeToggle from '../../CustomInputs/LargeToggle';
import Loading from '../../../../../global/components/loading/Loading';
import TrackingTags from './TrackingTags';

import './QuestEdit.scss';

const today = new Date();
const defaultForm = {
  cover: '',
  endDateTime: today,
  description: '',
  game: '',
  minimumAverageViewers: 25,
  openSpots: 10,
  overlayImage: '',
  paymentAmount: 0,
  requiredBanner: '',
  requiredStreamTimeMinutes: 60,
  requirements: {
    beforeStream: [],
    duringStream: [],
    afterStream: [],
  },
  startDateTime: today,
  status: 'disabled',
  title: '',
  trackingTags: [],
};

const QuestEdit = props => {
  const {
    brandUser: {
      games: {
        data: { games: products = [] },
        errors: { hasError: productError, message: productErrorMessage },
        isLoading: productLoading,
      },
      preSignedUrl,
      quests: {
        data: { quests = [] },
        errors: { hasError: questError, message: questErrorMessage },
        isLoading: questLoading,
        isUpdating: questUpdating,
      },
      user: {
        id: userId,
      },
    },
    brandUserUploadImageUrl,
    // currentProduct,
    currentQuest,
    // getBrandGame,
    // getBrandQuest,
    postBrandQuest,
    putBrandQuest,
    tab,
    token,
  } = props;

  const [brandAgreements, setBrandAgreements] = useState([]);
  const [brandAgreementsLoading, setBrandAgreementsLoading] = useState(false);
  const [brandAgreementsModal, setBrandAgreementsModal] = useState(false);
  const [brandAgreementSubmitLoading, setBrandAgreementSubmitLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [goLive, setGoLive] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [tracking, setTracking] = useState(false);

  const getPaymentMethods = async () => {
    setPaymentLoading(true);
    const {
      data: { data: paymentInfo },
    } = (await getUserBrandPaymentMethods()) || { data: [] };
    if (paymentInfo) setPaymentMethods(paymentInfo);
    setPaymentLoading(false);
  };

  const getAgreements = async () => {
    setBrandAgreementsLoading(true);
    const { data: agreements } = await getAllAgreements();
    if (!!agreements) {
      setBrandAgreements(agreements.filter(agreement => agreement.type === 'quest' && agreement.version === currentQuest));
    }
    setBrandAgreementsLoading(false);
  };

  const isLoading = productLoading || questLoading || paymentLoading || brandAgreementsLoading;

  useEffect(() => {
    getAgreements();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (currentQuest) {
      const foundQuest = quests.find((q) => q.id.toString() === currentQuest);
      if (foundQuest) {
        setForm(foundQuest);
        setGoLive(foundQuest.status === 'active');
        if (foundQuest?.trackingTags?.length > 0) { setTracking(true); }
      }
    } else {
      setForm(defaultForm);
    }
  }, [currentQuest]);

  useEffect(() => {
    if (currentQuest) {
      const foundQuest = quests.find(q => q.id.toString() === currentQuest);
      if (foundQuest) {
        setForm(foundQuest);
        setGoLive(foundQuest.status === 'active');
        if (foundQuest?.trackingTags?.length > 0) { setTracking(true); }
      }
    }
  }, [quests.length]);

  useEffect(() => {
    if (productError || questError) {
      simpleNotification({
        level: 'error',
        title: 'Error',
        message: productErrorMessage || questErrorMessage || 'Something went wrong',
      });
    }
  }, [productError, questError]);

  useEffect(() => {
    if (!questError) {
      const foundQuest = quests.find(q => q.title === form.title);

      if (foundQuest) {
        simpleNotification({
          level: 'success',
          title: 'Success',
          message: `Quest ${currentQuest ? 'updated' : 'created'} successfully`,
        });

        if (!currentQuest) {
          window.location.replace(`/portal?tab=${tab}&component=QuestEdit&currentQuest=${foundQuest.id}`);
        }
      }
    }
  }, [questLoading, questUpdating]);

  const handleFormChange = (name, value) => {
    if (name) {
      setForm(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (errors.includes(name)) {
      setErrors(errors.filter(error => error !== name));
    }
  };

  const handleImageRequirement = (name, value) => {
    if (name) {
      let reqSubtype = {};

      switch (name) {
        case 'overlayImage':
          reqSubtype = {
            helpLink: 'https://howto.streamlabs.com/streamlabs-obs-19/game-overlay-for-streamlabs-obs-3752',
            type: 'overlay',
            tooltipText: 'an overlay',
          };
          break;
        case 'requiredBanner':
          reqSubtype = {
            helpLink: 'https://help.twitch.tv/s/article/how-to-edit-info-panels?language=en_US',
            type: 'banner',
            tooltipText: 'a panel',
          };
          break;
        default: break;
      }

      if (reqSubtype) {
        const newRequirements = form.requirements.duringStream?.filter(requirement => requirement.subtype !== reqSubtype.type);

        if (!!value) {
          const newRequirement = {
            approvalRequired: false,
            customIcon: false,
            description: `<p>Place this ${reqSubtype.type} on your stream for the sponsored portion.</p>`,
            icon: false,
            id: uuidv4().replace(/-/g, ''),
            showOnApp: true,
            subtype: reqSubtype.type,
            title: makeProper(reqSubtype.type),
            tooltip: `<p>Click <a href="${reqSubtype.helpLink}" target="_blank" rel="noopener">here</a> for instructions on how to upload ${reqSubtype.tooltipText} banner.</p>`,
            type: 'display-download',
          };

          newRequirements.push(newRequirement);
        }

        setForm(prevState => ({
          ...prevState,
          [name]: value,
          requirements: {
            ...prevState.requirements,
            duringStream: newRequirements,
          },
        }));
      }
    }
  };

  const handleAgreementSubmit = async () => {
    setBrandAgreementSubmitLoading(true);
    const { data } = await putAgreement('quest', currentQuest);

    if (data) {
      setBrandAgreementsModal(false);
      setBrandAgreements(prevState => prevState.push(data));
      setGoLive(true);
      setForm(prevState => ({
        ...prevState,
        status: 'active',
      }));
    }

    setBrandAgreementSubmitLoading(false);
  };

  const handleGoLive = () => {
    const newLive = !goLive;
    const status = newLive ? 'active' : 'disabled';

    if (newLive && !brandAgreements.length) {
      setBrandAgreementsModal(true);
    } else {
      setGoLive(newLive);
      setForm(prevState => ({
        ...prevState,
        status: status,
      }));
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const validationErrors = validateQuestForm(form) || [];

    setErrors(validationErrors);

    if (validationErrors?.length === 0) {
      if (!tracking) { form.trackingTags = []; }
      if (form?.game?.id) { form.game = form.game.id; }
      if (form?.platforms?.length > 0 && typeof form.platforms[0] !== 'string') { form.platforms = form.platforms.map(p => p.id); }

      if (currentQuest) {
        putBrandQuest(form, token);
      } else {
        form.owner = userId;
        postBrandQuest(form, token);
      }
    }
  };

  if (isLoading) {
    return <Loading isBrand />;
  }

  if (!paymentMethods.length) {
    return (
      <div className='QuestEdit'>
        <div className='QuestEdit__Title'>No Payment Method Found</div>
        <SetViewContext.Consumer>
          {({ setView }) => (
            <div className='QuestEdit__Submit_Area'>
              <div className='QuestEdit__Button' onClick={() => setView({
                component: 'Profile',
              })}>
                Update Profile
              </div>
            </div>
          )}
        </SetViewContext.Consumer>
      </div>
    )
  }

  const foundProduct = (products.find(p => p.id === (form?.game?.id || form?.game)) || {});
  const gallery = foundProduct.gallery?.filter(i => i.type === 'image') || [];
  if (foundProduct.cover) { gallery.push({ type: 'image', url: foundProduct.cover }); }
  if (foundProduct.coverPortrait) { gallery.push({ type: 'image', url: foundProduct.coverPortrait }); }
  if (foundProduct.developerLogo) { gallery.push({ type: 'image', url: foundProduct.developerLogo }); }
  if (foundProduct.logo) { gallery.push({ type: 'image', url: foundProduct.logo }); }

  return (
    <div className='QuestEdit'>
      {brandAgreementsModal && (
        <CustomModal
          open={brandAgreementsModal}
          clickOutsideToClose
          content={
            <div className='QuestEdit'>
              <div className='QuestEdit__Title'>End-User<br /> License Agreement</div>
              <div className='QuestEdit__Agreement'>
                <div className='QuestEdit__Agreement__Text'>{eula}</div>
              </div>
              <div className='QuestEdit__Submit_Area'>
                <div className='QuestEdit__Button' onClick={handleAgreementSubmit}>
                  {brandAgreementSubmitLoading ? (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />) : 'Agree'}
                </div>
              </div>
            </div>
          }
          onClose={() => setBrandAgreementsModal(false)}
        />
      )}
      <div className='QuestEdit__Title'>
        {currentQuest ? 'Edit' : 'Add'} Quest
        {currentQuest && (
          <LargeToggle
            header='GO LIVE'
            onChange={handleGoLive}
            value={goLive}
          />
        )}
      </div>
      <CustomDropdown
        error={errors.includes('game')}
        header='CHOOSE YOUR PRODUCT'
        onChange={handleFormChange}
        options={products.map(product => ({
          optionName: product.name,
          optionValue: product.id,
        }))}
        name='game'
        value={form.game}
      />
      {form.game && (
        <>
          <CustomTextField
            error={errors.includes('title')}
            header='QUEST TITLE'
            onChange={handleFormChange}
            name='title'
            value={form.title}
          />
          <AddImage
            brandUserUploadImageUrl={brandUserUploadImageUrl}
            error={errors.includes('cover')}
            gallery={gallery}
            header='UPLOAD YOUR QUEST COVER'
            onChange={handleFormChange}
            name='cover'
            preSignedUrl={preSignedUrl}
            token={token}
            value={form.cover}
          />
          <div className='QuestEdit__DateFields'>
            <CustomDate
              error={errors.includes('startDateTime')}
              header='START DATE'
              onChange={handleFormChange}
              name='startDateTime'
              value={form.startDateTime}
            />
            <CustomDate
              error={errors.includes('endDateTime')}
              header='END DATE'
              onChange={handleFormChange}
              name='endDateTime'
              value={form.endDateTime}
            />
          </div>
          <CustomNumber
            error={errors.includes('paymentAmount')}
            header='REWARD PER STREAMER'
            onChange={handleFormChange}
            max={Number(99999)}
            min={Number(0)}
            name='paymentAmount'
            step={Number(0.01)}
            value={form.paymentAmount}
          />
          <div className='QuestEdit__AverageViewers__Header'>
            MINIMUM ACCV &nbsp;
            {/* <Tooltip placement='top' title='Average Concurrent Viewers'>
              <Icon name='questioncircle' />
            </Tooltip> */}
          </div>
          <CustomNumber
            error={errors.includes('minimumAverageViewers')}
            max={Number(99999)}
            min={Number(0)}
            name='minimumAverageViewers'
            onChange={handleFormChange}
            value={form.minimumAverageViewers}
          />
          <CustomNumber
            error={errors.includes('openSpots')}
            header='OPEN SPOTS'
            max={Number(99999)}
            min={Number(1)}
            name='openSpots'
            onChange={handleFormChange}
            value={form.openSpots}
          />
          <CustomNumber
            error={errors.includes('requiredStreamTimeMinutes')}
            header='STREAM DURATION (MINUTES)'
            max={Number(99999)}
            min={Number(1)}
            name='requiredStreamTimeMinutes'
            onChange={handleFormChange}
            value={form.requiredStreamTimeMinutes}
          />
          <CustomTextArea
            error={errors.includes('description')}
            header='QUEST INSTRUCTIONS'
            onChange={handleFormChange}
            name='description'
            value={form.description}
          />
          <AddImage
            brandUserUploadImageUrl={brandUserUploadImageUrl}
            error={errors.includes('requiredBanner')}
            gallery={gallery}
            header='UPLOAD OR SELECT YOUR QUEST BANNER'
            onChange={handleImageRequirement}
            name='requiredBanner'
            preSignedUrl={preSignedUrl}
            token={token}
            value={form.requiredBanner}
          />
          <AddImage
            brandUserUploadImageUrl={brandUserUploadImageUrl}
            error={errors.includes('overlayImage')}
            gallery={gallery}
            header='UPLOAD OR SELECT YOUR QUEST OVERLAY'
            onChange={handleImageRequirement}
            name='overlayImage'
            preSignedUrl={preSignedUrl}
            token={token}
            value={form.overlayImage}
          />
          <div className='QuestEdit__Tracking'>
            <div className='QuestEdit__Tracking__Header'>TRACKING</div>
            <LargeToggle
              header='TRACK BY HASHTAG'
              onChange={(name, value) => setTracking(value)}
              value={tracking}
            />
            {tracking && (
              <TrackingTags
                onChange={handleFormChange}
                value={form.trackingTags}
                style={{
                  marginLeft: '-23px',
                  marginRight: '-23px',
                }}
              />
            )}
          </div>
          <div className='QuestEdit__Submit_Area'>
            <div className='QuestEdit__Button' onClick={handleSubmit}>
              Save Quest
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const validateQuestForm = (form) => {
  const errors = [];

  if (!form.game || (typeof form.game === 'string' && form.game?.trim() === '')) {
    errors.push('game');
  }

  if (!form.title || form.title?.trim() === '') {
    errors.push('title');
  }

  if (!form.startDateTime) {
    errors.push('startDateTime');
  }

  if (!form.endDateTime) {
    errors.push('endDateTime');
  }

  if (!form.paymentAmount) {
    errors.push('paymentAmount');
  }

  // if (!form.minimumAverageViewers || form.minimumAverageViewers?.trim() === '') {
  //   errors.push('minimumAverageViewers');
  // }

  // if (!form.openSpots || form.openSpots?.trim() === '') {
  //   errors.push('openSpots');
  // }

  // if (!form.requiredStreamTimeMinutes || form.requiredStreamTimeMinutes?.trim() === '') {
  //   errors.push('requiredStreamTimeMinutes');
  // }

  if (!form.description || form.description?.trim() === '') {
    errors.push('description');
  }

  return errors;
};

export default QuestEdit;
