/* eslint-disable */
import React from 'react';
// import PropTypes from 'prop-types'
import './static.css';

class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  render() {
    return (
      <section className="TermsOfUse static">
      
      <h1 dir="ltr">
    EMINENCE CORPORATION
</h1>
<h1 dir="ltr">
    TERMS OF USE
</h1>
<br/>
<br/>
<p dir="ltr">
    Welcome to Eminence Corporation (together with its subdomains, Content,
    Marks, App and services, the “Site”) headquartered at 4972 Warner Ave Suite
201, Huntington Beach, CA 92649 and accessible online at<a href="http://www.noiz.gg" > www.noiz.gg</a> and    <a href="http://www.eminence.gg"> www.eminence.gg</a>. Please read the
    following Terms of Use carefully before installing and/or using the
    Eminence Corporation software application (the "App") using this Site so
    that you are aware of your legal rights and obligations with respect to the
    Company ("Eminence Corporation", "we", "our" or "us").
</p>

<p dir="ltr">
    By accessing or using the Site, you expressly acknowledge and agree that
    you are entering a legal agreement with us and have understood and agree to
    comply with, and be legally bound by, these Terms of Use, together with the
    Privacy Policy (the "Terms"). Under this legal agreement, you hereby agree
    to stream Provided via [website] at the agreed upon time, [agreed time of
    streaming].
</p>

<p dir="ltr">
    You hereby waive any applicable rights to require an original
    (non-electronic) signature or delivery or retention of non-electronic
    records, to the extent not prohibited under applicable law.
</p>
<p dir="ltr">
    If you do not agree to be bound by these Terms please do not access or use
    the Site.
</p>

<ol>
    <li dir="ltr">
        <p dir="ltr">
            Ability to Accept Terms. The Site and the Site’s content is only
            intended for individuals aged eighteen (18) years or older. If you
            are under 18 years please do not visit or use the Site.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Entire Agreement. This offer, once accepted, constitutes the entire
            agreement between you and the Company with respect to the subject
            matter hereof and supersedes all prior offers, negotiations and
            agreements, if any, whether written or oral, relating to such
            subject matter. You acknowledge that neither the Company nor its
            agents have made any promise, representation or warranty
            whatsoever, either express or implied, written or oral, which is
            not contained in this agreement for the purpose of inducing you to
            execute the agreement, and you acknowledge that you have executed
            this agreement in reliance only upon such promises, representations
            and warranties as are contained herein.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Severability. If any provision of this agreement is determined to
            be invalid, void or unenforceable, in whole or in part, such
            invalidity, voidance or unenforceability will attach only to such
            provision or part thereof, and the remaining part of such provision
            and all other provisions thereof will continue in full force and
            effect.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Modification. Any modification to this agreement must be in writing
            and isgned by the parties or it will have no effect and will be
            void.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Waiver. The waiver of any term of these Terms shall be deemed a
            further or continuing waiver of such term or any other term, and a
            party's failure to assert any right or provision under these Terms
            shall not constitute a waiver of such right or provision.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Assignment. These Terms, and any rights and licenses granted
            hereunder, may not be transferred or assigned by you but may be
            assigned by Eminence Corporation without restriction or
            notification to you. Any prohibited assignment shall be null and
            void.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Site Access. For such time as these Terms are in effect, we hereby
            grant you permission to visit and use the Site provided that you
            comply with these Terms and applicable law.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Restrictions. You shall not: (i) copy, distribute or modify any
            part of the Site’s content without our prior written authorization;
            (ii) use, modify, create derivative works of, transfer (by sale,
            resale, license, sublicense, download or otherwise), reproduce,
            distribute, display or disclose Content (defined below), except as
            expressly authorized herein; (iii) disrupt servers or networks
            connected to the Site; (iv) use or launch any automated system
            (including without limitation, "robots" and "spiders") to access
            the Site; and/or (v) circumvent, disable or otherwise interfere
            with security-related features of the Site or features that prevent
            or restrict use or copying of any Content or that enforce
            limitations on use of the Site.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Account. In order to use some of the services of the Site, you may
            have to create an account ("Account"). You agree not to create an
            Account for anyone else or use the account of another without their
            permission. When creating your Account, you must provide accurate
            and complete information. You are solely responsible for the
            activity that occurs in your Account, and you must keep your
            Account password secure. You must notify Eminence Corporation
            immediately of any breach of security or unauthorized use of your
            Account. As between you and Eminence Corporation, you are solely
            responsible and liable for the activity that occurs in connection
            with your Account. If you wish to delete your Account you may send
            an email request to Eminence Corporation.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Payments to Eminence Corporation. Except as expressly set forth in
            the Terms, your general right to access and use the Site is
            currently for free, but Eminence Corporation may in the future
            charge a fee for certain access or usage. You will not be charged
            for any such access or use of the Site unless you first agree to
            such charges, but please be aware that any failure to pay
            applicable charges may result in you not having access to some or
            all of the Site.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            YouTube API. The Site makes use of the YouTube API to provide certain
            functionality to you. You agree that, by using the Site and related
            Eminence Corporation services, you are bound by YouTube’s Terms of
            Service,&nbsp;<a href="https://www.youtube.com/t/terms" target="_blank">which you can view here</a>.
        </p>
    </li>
</ol>

<h2 dir="ltr">
    Intellectual Property Rights
</h2>

<ol>
    <li dir="ltr">
        <p dir="ltr">
            End User License. Subject to the terms and conditions of this
            Terms, we hereby grant you a personal, revocable, non-exclusive,
            non-sublicensable, non-assignable, non-transferable license
            ("License") to: (i) download, install and use the App on a
            computer, mobile telephone or any other device you are using to
            access the Site (the "Device"); and (ii) access and use the Site on
            that Device in accordance with these Terms.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Content and Marks. The (i) content on the Site, including without
            limitation, the text, documents, articles, brochures, descriptions,
            products, software, graphics, photos, sounds, videos, interactive
            features, and services (collectively, the "Materials"), (ii) and
            User Submissions, as defined below (together with the Materials,
            the " Content "), and (iii) the trademarks, service marks and logos
            contained therein ("Marks"), are the property of Eminence
            Corporation and/or its licensors and may be protected by applicable
            copyright or other intellectual property laws and treaties.
            “Eminence Corporation”, the Eminence Corporation logo, and other
            marks are Marks of Eminence Corporation or its affiliates. All
            other trademarks, service marks, and logos used on the Site are the
            trademarks, service marks, or logos of their respective owners. We
            reserve all rights not expressly granted in and to the Site and the
            Content.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            License Restrictions. You agree not to, and shall not permit any
            third party to: (i) sublicense, redistribute, sell, lease, lend or
            rent the Site; (ii) make the Site available over a network where it
            could be used by multiple devices owned or operated by different
            people at the same time; (iii) disassemble, reverse engineer,
            decompile, decrypt, or attempt to derive the source code of, the
            Site; (iv) copy (except for back-up purposes), modify, improve, or
            create derivative works of the Site or any part thereof; (v)
            circumvent, disable or otherwise interfere with security-related
            features of the Site or features that prevent or restrict use or
            copying of any content or that enforce limitations on use of the
            Site; (vi) remove, alter or obscure any proprietary notice or
            identification, including copyright, trademark, patent or other
            notices, contained in or displayed on or via the Site; (vii) use
            any communications systems provided by the App to send unauthorized
            and/or unsolicited commercial communications; (viii) use the
            Eminence Corporation name, logo or trademarks without our prior
            written consent; and/or (ix) use the Site to violate any applicable
            laws, rules or regulations, or for any unlawful, harmful,
            irresponsible, or inappropriate purpose, or in any manner that
            breaches this Agreement.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            User Submissions.
        </p>
    </li>
    <ol>
        <li dir="ltr">
            <p dir="ltr">
                Responsibility. The Site permit the submission, hosting,
                sharing and publishing of Content by you and other users ("User
                Submissions"). You understand that whether or not such User
                Submissions are published, we do not guarantee any
                confidentiality with respect to any User Submissions. You shall
                be solely responsible for your User Submissions and the
                consequences of posting, publishing or uploading them. We have
                complete discretion whether to publish your User Submissions
                and we reserve the right in our sole discretion and without
                further notice to you, to monitor, censor, edit, remove,
                delete, and/or remove any and all Content posted on the Site
                (including User Submissions) at any time and for any reason.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Ownership. You represent and warrant that you own or have the
                necessary rights and permissions to use and authorize Eminence
                Corporation to use all Intellectual Property Rights (defined
                below) in and to your User Submissions, and to enable inclusion
                and use thereof as contemplated by the Site and these Terms.
                Unless the User Submissions are separately referred to, all
                references herein to Content shall include references to User
                Submissions. "Intellectual Property Rights" means any and all
                rights, titles and interests, whether foreign or domestic, in
                and to any and all trade secrets, patents, copyrights, service
                marks, trademarks, know-how, or similar intellectual property
                rights, as well as any and all moral rights, rights of privacy,
                publicity and similar rights of any type under the laws or
                regulations of any governmental, regulatory, or judicial
                authority, foreign or domestic. You retain all of your
                ownership rights in and to your User Submissions.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                License to User Submissions. By submitting the User Submissions
                to Eminence Corporation, you hereby grant Eminence Corporation
                a worldwide, irrevocable, non-exclusive, royalty-free,
                perpetual, sublicenseable and transferable license to use,
                reproduce, distribute, prepare derivative works of, display,
                and perform the User Submissions in connection with the Site
                and Eminence Corporation's business, including without
                limitation for publishing and redistributing part or all of
                your User Submissions (and derivative works thereof) in any
                media formats and through any media channels and, and you
                hereby waive any moral rights in your User Submissions, to the
                extent permitted by law. You also hereby grant each user of the
                Site or other viewer or user of the User Submission a
                non-exclusive right to use, reproduce, distribute, prepare
                derivative works of, display and perform such User Submissions,
                all in accordance with these Terms.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Prohibited Content. You agree that you will not display, post,
                submit, publish, upload or transmit a User Submission that: (i)
                is unfair or deceptive under the consumer protection laws of
                any jurisdiction; (ii) is copyrighted, protected by trade
                secret or otherwise subject to third party proprietary rights,
                including privacy and publicity rights, unless you are the
                owner of such rights; (iii) creates a risk to a person’s safety
                or health, creates a risk to public safety or health,
                compromises national security, or interferes with an
                investigation by law enforcement; (iv) impersonates another
                person; (v) promotes illegal drugs, violates export control
                laws, relates to illegal gambling, or illegal arms trafficking;
                (vi) is unlawful, defamatory, libelous, threatening,
                pornographic, harassing, hateful, racially or ethnically
                offensive, or encourages conduct that would be considered a
                criminal offense, gives rise to civil liability, violates any
                law, or is inappropriate; (vii) involves theft or terrorism; or
                (viii) is otherwise malicious or fraudulent.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Exposure. You understand and acknowledge that when accessing
                and using the Site: (i) you will be exposed to User Submissions
                from a variety of sources, and that Eminence Corporation is not
                responsible for the accuracy, usefulness, safety, or
                Intellectual Property Rights of, or relating to, such User
                Submissions; and (ii) you may be exposed to User Submissions
                that are inaccurate, offensive, indecent, or objectionable. You
                hereby agree to waive, and hereby do waive, any legal or
                equitable rights or remedies you may have against Eminence
                Corporation with respect to (i) and (ii) herein.
            </p>
        </li>
    </ol>
    <li dir="ltr">
        <p dir="ltr">
            Information Description. We attempt to be as accurate as possible.
            However, we cannot and do not warrant that the Content available on
            the Site is accurate, complete, reliable, current, or error-free.
            We reserve the right to make changes in or to the Content, or any
            part thereof, in our sole judgment, without the requirement of
            giving any notice prior to or after making such changes to the
            Content. Your use of the Content, or any part thereof, is made
            solely at your own risk and responsibility.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Links. The Site may contain links, and may enable you to post
            content, to third party websites that are not owned or controlled
            by Eminence Corporation. We are not affiliated with, have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of, any third party websites. You: (i) are
            solely responsible and liable for your use of and linking to third
            party websites and any content that you may send or post to a third
            party website; and (ii) expressly release Eminence Corporation from
            any and all liability arising from your use of any third party
            website. Accordingly, we encourage you to read the terms and
            conditions and privacy policy of each third party website that you
            may choose to visit.
        </p>
    </li>
    <ol>
        <li dir="ltr">
            <p dir="ltr">
                Eminence Corporation permits you to link to the Site provided
                that: (i) you link to but do not replicate any page on this
                Site; (ii) the hyperlink text shall accurately describe the
                Content as it appears on the Site; (iii) you shall not
                misrepresent your relationship with Eminence Corporation or
                present any false information about Eminence Corporation and
                shall not imply in any way that we are endorsing any services
                or products, unless we have given you our express prior
                consent; (iv) you shall not link from a website ("Third Party
                Website") which prohibits linking to third parties; (v) such
                Third party Website does not contain content that (a) is
                offensive or controversial (both at our discretion), or (b)
                infringes any intellectual property, privacy rights, or other
                rights of any person or entity; and/or (vi) you, and your
                website, comply with these Terms and applicable law.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Eminence Corporation permits you to link to the Site provided
                that: (i) you link to but do not replicate any page on this
                Site; (ii) the hyperlink text shall accurately describe the
                Content as it appears on the Site; (iii) you shall not
                misrepresent your relationship with Eminence Corporation or
                present any false information about Eminence Corporation and
                shall not imply in any way that we are endorsing any services
                or products, unless we have given you our express prior
                consent; (iv) you shall not link from a website ("Third Party
                Website") which prohibits linking to third parties; (v) such
                Third party Website does not contain content that (a) is
                offensive or controversial (both at our discretion), or (b)
                infringes any intellectual property, privacy rights, or other
                rights of any person or entity; and/or (vi) you, and your
                website, comply with these Terms and applicable law.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Privacy. We will use any personal information that we may
                collect or obtain in connection with the Site in accordance
                with our privacy policy which is available on the Site. You
                agree that we may use personal information that you provide or
                make available to us in accordance with the Privacy Policy.
            </p>
        </li>
    </ol>
</ol>

<h2 dir="ltr">
    Warranty Disclaimers
</h2>

<ol>
    <li dir="ltr">
        <p dir="ltr">
            This section applies whether or not the services provided under the
            Site are for payment. Applicable law may not allow the exclusion of
            certain warranties, so to that extent certain exclusions set forth
            herein may not apply.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, AND
            WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. EMINENCE
            CORPORATION HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY,
            TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND
            THOSE ARISING BY STATUTE OR FROM A COURSE OF DEALING OR USAGE OF
            TRADE. EMINENCE CORPORATION DOES NOT GUARANTEE THAT THE SITE WILL
            BE FREE OF BUGS, SECURITY BREACHES, OR VIRUS ATTACKS. THE SITE MAY
            OCCASIONALLY BE UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR
            OTHER REASONS. YOU AGREE THAT EMINENCE CORPORATION WILL NOT BE HELD
            RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT MAY
            RESULT FROM TECHNICAL PROBLEMS OF THE INTERNET, SLOW CONNECTIONS,
            TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS. WE DO NOT
            WARRANT, ENDORSE OR GUARANTEE ANY CONTENT, PRODUCT, OR SERVICE THAT
            IS FEATURED OR ADVERTISED ON THE SITE BY A THIRD PARTY.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            EMINENCE CORPORATION DOES NOT WARRANT, ENDORSE OR GUARANTEE ANY
            CONTENT THAT APPEARS IN A USER SUBMISSION, AND DOES NOT MAKE ANY
            REPRESENTATION OR WARRANTY WITH RESPECT TO, AND DISCLAIMS ALL
            LIABILITY FOR, ANY SUCH CONTENT.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            YOU SPECIFICALLY ACKNOWLEDGE THAT EMINENCE CORPORATION SHALL NOT BE
            RESPONSIBLE FOR THE USER SUBMISSIONS OR CONDUCT (INCLUDING
            DEFAMATORY, OFFENSIVE, ILLEGAL, OR NEGLIGENT CONDUCT) OF ANY SITE
            USER AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
            ENTIRELY WITH YOU.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            YOUR RELIANCE ON, OR USE OF, ANY USER SUBMISSION, OR INTERACTION
            WITH ANY SITE USER OR OWNER, IS AT YOUR SOLE RISK. IF YOU HAVE A
            DISPUTE WITH ANY SITE USER OR OWNER IN CONNECTION WITH THE SITE OR
            ANY USER SUBMISSION, YOU AGREE THAT EMINENCE CORPORATION IS NOT
            LIABLE FOR ANY CLAIMS OR DAMAGES ARISING OUT OF OR CONNECTED WITH
            SUCH A DISPUTE. EMINENCE CORPORATION RESERVES THE RIGHT, BUT HAS NO
            OBLIGATION, TO MONITOR ANY SUCH DISPUTE.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, EMINENCE
            CORPORATION DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR
            CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE SECURITY OF
            ANY INFORMATION YOU MAY PROVIDE OR ACTIVITIES YOU ENGAGE IN DURING
            THE COURSE OF YOUR USE OF THE SITE.
        </p>
    </li>
</ol>

<h2 dir="ltr">
    Limitation of Liability
</h2>

<ol>
    <li dir="ltr">
        <p dir="ltr">
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, EMINENCE CORPORATION
            SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL,
            CONSEQUENTIAL, OR INCIDENTAL DAMAGES OF ANY KIND, OR FOR ANY LOSS
            OF DATA, REVENUE, PROFITS OR REPUTATION, ARISING UNDER THESE TERMS
            OR OUT OF YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF
            EMINENCE CORPORATION HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES OR LOSSES. Some jurisdictions do not allow the limitation
            or exclusion of liability for incidental or consequential damages,
            so the above limitations may not apply to you.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            IN NO EVENT SHALL THE AGGREGATE LIABILITY OF EMINENCE CORPORATION
            FOR ANY DAMAGES ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR
            INABILITY TO USE, THE SITE EXCEED THE TOTAL AMOUNT OF FEES, IF ANY,
            PAID BY YOU TO EMINENCE CORPORATION FOR USING THE SITE DURING THE
            THREE (3) MONTHS PRIOR TO BRINGING THE CLAIM.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Indemnity. You agree to defend, indemnify and hold harmless
            Eminence Corporation and our affiliates, and our respective
            officers, directors, employees and agents, from and against any and
            all claims, damages, obligations, losses, liabilities, costs and
            expenses (including but not limited to attorney's fees) arising
            from: (i) your use of, or inability to use, the Site; (ii) your
            User Submissions; (iii) your interaction with any Site user; or
            (iv) your violation of these Terms.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Updates and Upgrades. We may from time to time provide updates or
            upgrades to the App (each a "Revision"), but are not under any
            obligation to do so. Such Revisions will be supplied according to
            our then-current policies, which may include automatic updating or
            upgrading without any additional notice to you. You consent to any
            such automatic updating or upgrading of the App. All references
            herein to the App shall include Revisions. This Agreement shall
            govern any Revisions that replace or supplement the original App,
            unless the Revision is accompanied by a separate license agreement
            which will govern the Revision.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Third Party Open Source Software. Portions of the App may include
            third party open source software that is subject to third party
            terms and conditions ("Third Party Terms"). If there is a conflict
            between any Third Party Terms and the terms of this Terms, then the
            Third Party Terms shall prevail but solely in connection with the
            related third party open source software.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Term and Termination. These Terms are effective until terminated by
            Eminence Corporation or you. Eminence Corporation, in its sole
            discretion, has the right to terminate these Terms and/or your
            access to the Site, or any part thereof, immediately at any time
            and with or without cause (including, without any limitation, for a
            breach of these Terms). Eminence Corporation shall not be liable to
            you or any third party for termination of the Site, or any part
            thereof. If you object to any term or condition of these Terms, or
            any subsequent modifications thereto, or become dissatisfied with
            the Site in any way, your only recourse is to immediately
            discontinue use of the Site. Upon termination of these Terms, you
            shall cease all use of the Site. This Section 17 and Sections 8
            (Intellectual Property Rights), 9.3 (License to User Submissions),
            12 (Privacy), ‎14 (Warranty Disclaimers), ‎15 (Limitation of
            Liability), ‎16 (Indemnity), and ‎20 (Independent Contractors) to
            ‎23 (General) shall survive termination of these Terms.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Independent Contractors. You and Eminence Corporation are
            independent contractors. Nothing in these Terms creates a
            partnership, joint venture, agency, or employment relationship
            between you and Eminence Corporation. You must not under any
            circumstances make, or undertake, any warranties, representations,
            commitments or obligations on behalf of Eminence Corporation.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Governing Law. Eminence Corporation reserves the right to
            discontinue or modify any aspect of the Site at any time. These
            Terms and the relationship between you and Eminence Corporation
            shall be governed by and construed in accordance with the laws of
            the State of California, without regard to its principles of
            conflict of laws. You agree to submit to the personal and exclusive
            jurisdiction of the courts located in Los Angeles, California and
            waive any jurisdictional, venue, or inconvenient forum objections
            to such courts, provided that Eminence Corporation may seek
            injunctive relief in any court of competent jurisdiction.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Arbitration and Class Action Waiver. You and the Company agree to
            submit to mandatory binding arbitration any and all claims arising
            out of or related to your use of the Site and the Site’s Content,
            including, but not limited to, claims for unpaid compensation,
            wrongful termination, torts, ownership interest in the Company,
            and/or discrimination (including harassment) based upon any
            federal, state or local ordinance, statute, regulation or
            constitutional provision except that each party may, at its, his or
            her option, seek injunctive relief in court related to the improper
            use, disclosure or misappropriation of a party’s private,
            proprietary, confidential or trade secret information]
            (collectively, “Arbitrable Claims”). Further, to the fullest extent
            permitted by law, you and the Company agree that no class or
            collective actions can be asserted in arbitration or otherwise. All
            claims, whether in arbitration or otherwise, must be brought solely
            in your or the Company’s individual capacity, and not as a
            plaintiff or class member in any purported class or collective
            proceeding. Nothing in this Arbitration and Class Action Waiver
            section, however, restricts your right, if any, to file in court a
            representative action under California Labor Code Sections 2698, et
            seq.
        </p>
    </li>
</ol>

<p dir="ltr">
    THE PARTIES HEREBY WAIVE ANY RIGHTS THEY MAY HAVE TO TRIAL BY JURY IN
    REGARD TO ARBITRABLE CLAIMS. THE PARTIES FURTHER WAIVE ANY RIGHTS THEY MAY
    HAVE TO PURSUE OR PARTICIPATE IN A CLASS OR COLLECTIVE ACTION PERTAINING TO
    ANY CLAIMS BETWEEN YOU AND THE COMPANY.
</p>

<p dir="ltr">
    The parties agree that, to the fullest extent permitted by law, arbitration
    shall be the exclusive remedy for the subject matter of such administrative
    claims. The arbitration shall be conducted in Los Angeles County,
    California through JAMS before a single neutral arbitrator, in accordance
    with the JAMS employment arbitration rules then in effect. The JAMS rules
    may be found and reviewed at
    <a href="http://www.jamsadr.com/rules-employment-arbitration">
        http://www.jamsadr.com/rules-employment-arbitration
    </a>
    . If you are unable to access these rules, please let me know and I will
    provide you with a hardcopy. The arbitrator shall issue a written decision
    that contains the essential findings and conclusions on which the decision
    is based.
</p>
<div>
    
</div>

      </section>);
  }
}

  export default TermsOfUse;
