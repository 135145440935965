import React from 'react';
import { textTruncate } from '../../utils/functions';

const ItemCard = (props) => {
  const {
    baseCSS = false, item: {
      image = '', title = '', slug = '',
    }, showLearnMore = false,
  } = props;
  return (
    <div className={!baseCSS && 'carousel-item'}>
      <img className="carousel-image" src={image || ''} alt="carousel quest" />
      <div className="carousel-title">{textTruncate(title, 80) || ''}</div>
      <div className="carousel-border" />
      {showLearnMore && <a href={`/quests/${slug}`} ><div className="carousel-click-through">Learn More</div> </a>}
    </div>
  );
};

export default ItemCard;
