import React from 'react';
import OpenBar from './OpenBar';
import { v4 as uuidv4 } from 'uuid';

const FAQs = ({ faqs, faqsRef }) => (
  <div className="faqSection">
    <h1>FAQs</h1>{' '}
    {faqs.map(faqItem => (
      <OpenBar
        description={faqItem.description}
        key={uuidv4()}
        title={faqItem.title}
      />
      ))}{' '}
    <div ref={faqsRef} />
  </div>
);

export default FAQs;
