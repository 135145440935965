import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Icon from '../../../../../global/images/icons/Icon';
import LoadingGif from '../../assets/LoadingGif';
import noizisotype from '../../../../../images/noiz-isotype.png';

import { ActiveFilters, SearchBar } from "../../../../../global/components/filters/SearchBarAndFilters";
import { consolidateDeliverables, deliverableFilters, deliverablesSorts, DeliverableCard, NoDeliverablesCard, filterConsolidatedDeliverables, sortConsolidatedDeliverables } from './DeliverableCard';
import { ApplicantCard, NoApplicantsCard } from './ApplicantCard';
import { ItemCard, NoItemCard } from './ItemCard';

import './ViewAll.scss';

const ViewAll = props => {
  const {
    view: {
      component,
      currentQuest,
      currentUser,
    },
    brandUser: {
      quests: {
        data: { quests = [] },
        isLoading: questsLoading,
      },
      games: {
        data: { games: products = [] },
        isLoading: productsLoading,
      },
    },
    deliverables,
    getBrandGame,
    getBrandQuest,
    postBrandPreapproval,
    postBrandRequirement,
    token,
    type,
  } = props;

  const [filter, setFilter] = useState({});
  const [filterModal, setFilterModal] = useState(false);
  const [listStyle, setListStyle] = useState('list');

  // Specific to the DeliverableCard
  const [deliverablesSearch, setDeliverablesSearch] = useState('');
  const [deliverablesFilter, setDeliverablesFilter] = useState({});
  const [deliverablesFilterModal, setDeliverablesFilterModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  // End of specific to the DeliverableCard

  useEffect(() => {
    if (currentQuest) {
      setFilter({
        id: currentQuest,
        title: quests?.find(q => q.id === currentQuest)?.title || '',
        ...(currentUser ? { userId: currentUser } : {}),
      });
    }
  }, [currentQuest]);

  useEffect(() => {
    switch (component) {
      case 'ViewAllProducts': getBrandGame({ statusGame: 'all' }); getBrandQuest({ statusQuest: 'all' }); break;
      case 'ViewAllQuests': getBrandQuest({ statusQuest: 'all' }); break;
      default: break;
    };
  }, [component]);

  const isLoading = questsLoading || productsLoading;

  if (isLoading) {

    return <div className="Portal_Product_LoadingGif">
      <LoadingGif />;
           </div>
  }

  let itemsFound = [];
  let headerText = '';
  let filterText = null;
  let content = null;

  const FilterModal = ({ titleText, options }) => (
    <div className='ViewAllFilter' onKeyPress={(e) => console.log(e)} onClick={() => setFilterModal(!filterModal)}>
      {titleText} &nbsp; <Icon name={filterModal ? 'caretup' : 'caretdown'} />
      {filterModal && (
          <div className='ViewAllFilter__Options'>
          {options}
          <div className='ViewAllFilter__Option' onClick={() => setFilter({})}>All</div>
        </div>
      )}
    </div>
  );

  switch (type) {
    case 'Applicants': {
      headerText = 'Review Applicants';
      if (!filter.id) {
        itemsFound = quests?.reduce((acc, quest) => [...acc, ...(quest?.userQuests || [])], []) || [];
      } else {
        const questFound = quests?.find(quest => quest.id === filter.id) || {};

        itemsFound = questFound?.userQuests || [];
        filterText = questFound?.title || '';
      }

      const filteredApplicants = itemsFound.reduce((acc, uq) => {
        if (uq.status === 'pending') {
          acc.pending.push(uq);
        } else if (['approved', 'normal'].includes(uq.status)) {
          acc.approved.push(uq);
        } else {
          acc.rejected.push(uq);
        }

        return acc;
      }, {
        pending: [],
        approved: [],
        rejected: [],
      });

      content = (
        <div className='ViewAllApplicants'>
          <>
            <div className="ViewAllTitle">Pending Approval</div>
            <div className={classnames('ApplicantsList', listStyle)}>
              {filteredApplicants.pending.length ? filteredApplicants.pending.map(uq => (
                <ApplicantCard
                  key={uq.id}
                  display={listStyle}
                  userQuest={uq}
                  postBrandPreapproval={postBrandPreapproval}
                  token={token}
                  type="pending"
                />
              )) : <NoApplicantsCard />}
            </div>
          </>
          <>
            <div className='ViewAllTitle'>Approved</div>
            <div className={classnames('ApplicantsList', listStyle)}>
              {filteredApplicants.approved.length ? filteredApplicants.approved.map(uq => (
                <ApplicantCard
                  key={uq.id}
                  display={listStyle}
                  userQuest={uq}
                  postBrandPreapproval={postBrandPreapproval}
                  token={token}
                  type="approved"
                />
              )) : <NoApplicantsCard />}
            </div>
          </>
          <>
            <div className='ViewAllTitle'>Rejected</div>
            <div className={classnames('ApplicantsList', listStyle)}>
              {filteredApplicants.rejected.length ? filteredApplicants.rejected.map(uq => (
                <ApplicantCard
                  key={uq.id}
                  display={listStyle}
                  userQuest={uq}
                  postBrandPreapproval={postBrandPreapproval}
                  token={token}
                  type="rejected"
                />
              )) : <NoApplicantsCard />}
            </div>
          </>
        </div>
      );

      filterText = <FilterModal
        options={quests?.map(q => (
          <div
            className='ViewAllFilter__Option'
            key={q.id}
            onClick={() => setFilter({ id: q.id, title: q.title })}
          >
            {q.title}
          </div>
        ))}
        titleText={filter.title || 'All'}
      />;

      break;
    }
    case 'Deliverables': {
      headerText = 'Quest Deliverables';

      if (filter.id) {
        console.log(filter.id, 'filterid');
        itemsFound = deliverables.filter(d => {
        if (d.userId === "5eb94c54482bcc4ccb665ab1") console.log(d,'kjaszz')
        return  d.questId === filter.id;
        // return  d.questId === filter.id && (filter?.userId ? d.userId === filter.userId : true)
        });
        console.log(itemsFound,'itemsfound')
      } else {
        itemsFound = deliverables;
      }

      filterText = (
        <FilterModal
          options={quests?.map(q => (
            <div
              className='ViewAllFilter__Option'
              key={q.id}
              onClick={() => setFilter({ id: q.id, title: q.title })}
            >
              {q.title}
            </div>
          ))}
          titleText={filter.title || 'All'}
        />
      );

      let questConsolidatedDeliverables = consolidateDeliverables(quests?.filter(q => (filter?.id === q.id) || true)?.map(q => ({ id: q.id, requirements: q.requirements, requiredStreamTimeMinutes: q.requiredStreamTimeMinutes })), itemsFound);

      questConsolidatedDeliverables = filterConsolidatedDeliverables(questConsolidatedDeliverables, deliverablesSearch, deliverableFilters) || {};

      const deliverableContentMap = Object.keys(questConsolidatedDeliverables).reduce((acc, qid) => {
        const questDeliverables = questConsolidatedDeliverables[qid];
        return [...acc, ...Object.keys(questDeliverables?.users).map(d => {
          const userDeliverable = questDeliverables.users[d];

          return {
            key: `${qid}-${userDeliverable.userQuestId}`,
            requirements: questDeliverables.requirements,
            user: userDeliverable,
            postBrandRequirement,
            token,
          };
        })];
      }, []);

      const deliverableContent = sortConsolidatedDeliverables(deliverableContentMap, deliverablesFilter).map(d => <DeliverableCard key={d.key} {...d} />);

      content = (
        <>
          <div style={{ margin: '12px 0' }}>
            <SearchBar
              search={deliverablesSearch}
              setSearch={setDeliverablesSearch}
              filterModal={deliverablesFilterModal}
              setFilterModal={setDeliverablesFilterModal}
              filter={deliverablesFilter}
              setFilter={setDeliverablesFilter}
              possibleSorts={deliverablesSorts}
            />
            <ActiveFilters
              filter={deliverablesFilter}
              setFilter={setDeliverablesFilter}
              defaultFilters={deliverableFilters}
            />
          </div>
          {deliverableContent?.length ? deliverableContent.slice(0, 6) : <NoDeliverablesCard />}
          {deliverableContent?.length > 6 && showMore && deliverableContent.slice(6)}
          {deliverableContent?.length > 6 && (
            <div className='ViewAll__ShowMore' onClick={() => setShowMore(!showMore)}>
              <Icon name={showMore ? 'caretup' : 'caretdown'} />
              &nbsp; Show {showMore ? 'Less' : 'More'} &nbsp;
              <Icon name={showMore ? 'caretup' : 'caretdown'} />
            </div>
          )}
        </>
      );

      break;
    }
    case 'Quests': {
      headerText = 'Quests';

      if (!filter.id) {
        itemsFound = quests || [];
      } else {
        const questsFound = quests?.filter(q => (q.game?.id || q.game) === filter.id) || [];

        itemsFound = questsFound;
      }

      content = itemsFound?.length ?
        <div className={classnames('ViewAllQuests', listStyle)}>
          {itemsFound.map(q => (
            <ItemCard
              key={q.id}
              display={listStyle}
              id={q.id}
              image={q?.cover || q?.coverPortrait || q?.game?.cover || q?.game?.coverPortrait || noizisotype}
              title={q?.title}
              type='Quest'
            />
          ))}
        </div> :
        <NoItemCard />;

      filterText = <FilterModal
        options={products?.map(g => (
          <div
            className='ViewAllFilter__Option'
            key={g.id}
            onClick={() => setFilter({ id: g.id, title: g.name })}
          >
            {g.name}
          </div>
        ))}
        titleText={filter.title || 'All'}
      />;

      break;
    }
    case 'Products': {
      headerText = 'Products';

      if (!filter.id) {
        itemsFound = products || [];
      } else {
        const productsFound = products?.filter(q => (q.game?.id || q.game) === filter.id) || [];

        itemsFound = productsFound;
      }

      content = itemsFound?.length ?
        <div className={classnames('ViewAllProducts', listStyle)}>
          {itemsFound.map(p => (
            <ItemCard
              key={p.id}
              display={listStyle}
              id={p.id}
              image={p?.cover || p?.coverPortrait || noizisotype}
              title={p.name}
              type='Product'
            />
          ))}
        </div> :
        <NoItemCard type='Product' />;

      // filterText = <FilterModal
      //   options={products?.map(g => (
      //     <div
      //       className='ViewAllFilter__Option'
      //       key={g.id}
      //       onClick={() => setFilter({ id: g.id, title: g.name })}
      //     >
      //       {g.name}
      //     </div>
      //   ))}
      //   titleText={filter.title || 'All'}
      // />;

      break;
    }
    case 'Campaigns': {
      content = <div>Coming Soon!</div>;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <div className="ViewAll">
      <div className="ViewAll__Header">{headerText}</div>
      <div className="ViewAll__Filter">
        {!!filterText && <div className="ViewAllTitle dropdown">{filterText}</div>}
        {type !== 'Deliverables' && (
          <div className='ViewAll__Filter__Buttons'>
            <div className={classnames('ViewAll__Filter__Buttons__Button', listStyle === 'list' && 'ListStyleSelected')} onClick={() => setListStyle('list')} disabled={listStyle === 'list'}>
              <Icon name="list" />
            </div>
            <div className={classnames('ViewAll__Filter__Buttons__Button', listStyle === 'grid' && 'ListStyleSelected')} onClick={() => setListStyle('grid')} disabled={listStyle === 'grid'}>
              <Icon name="grip" />
            </div>
          </div>
        )}
      </div>
      {content}
    </div>
  );
};

export default ViewAll;
