/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import {
  without,
  reduce,
  forEach,
  filter,
  findIndex,
  find,
  orderBy,
} from 'lodash';
import classNames from 'classnames';
import { CSVLink } from 'react-csv';
import BarChart from '../../../../utils/charts/BarChart';
// import { simpleNotification } from '../../../../utils/notifications';
import './QuestTieredMultipleDays.scss';
import StackedChart from '../../../../utils/charts/StackedChart';
import EditModal from '../QuestTimeEditModal/EditModal';
import { paymentVendorProcessingFee } from '../../../../utils/functions';

class QuestTieredMultipleDays extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingElements: [],
      tab: 1,
      chart: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestTieredMultipleDays(questId, token);
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   // const { gameId } = nextProps.router.params;
  //
  // }

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [
          ...showingElements,
          elementId,
        ],
      });
    } else {
      this.setState({
        showingElements: [
          ...without(showingElements, elementId),
        ],
      });
    }
  }

  rounding = number => parseFloat(number).toFixed(2);

  generateStreamActivityRowsHTML = (streamActivity, paymentAmount) => {
    const { data: quest } = this.props.commandCenter.quest;
    if (streamActivity.length > 0) {
      return streamActivity.map(streamActivityItem => (
        <div
          key={streamActivityItem.activityId}
          className="row"
        >
          <div className="table-cell">{`${streamActivityItem.month}/${streamActivityItem.day}/${streamActivityItem.year}`}</div>
          <div className="table-cell">{Math.round(streamActivityItem.AVGViewers)}</div>
          <div className="table-cell">{streamActivityItem.timePlaying}min &nbsp; <EditModal {...this.props} currentUser={streamActivityItem} rehydrateCallback={this.props.getCCQuestTieredMultipleDays} /></div>
          { (quest.requiredStreamTimeMinutes && streamActivityItem.timePlaying >= quest.requiredStreamTimeMinutes) && (
          <div className="table-cell">${this.rounding(paymentAmount)}</div>
            )}
          { (quest.requiredStreamTimeMinutes && streamActivityItem.timePlaying < quest.requiredStreamTimeMinutes) && (
          <div className="table-cell">$0</div>
            )}
        </div>
      ));
    }
    return (<div className="NoStreamActivity">No Stream Activity.</div>);
  }

  generateLinkRowsHTML = (questTier, quest) => {
    const { showingElements } = this.state;
    if (questTier.length > 0) {
      return questTier.map((item, index) => {
        let pre = '';
        if (item && item.link && !item.link.toLowerCase().startsWith('http')) {
          pre = 'http://';
        }
        return (
          <div
            key={questTier.username}
            className={classNames('row toggable tooltip', { open: (showingElements.indexOf(item.username) !== -1) })}
          >
            <div className="row-basic">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">
                {item.username}
              </div>
              <div className="table-cell">
                <a
                  href={`https://www.twitch.tv/${item.twitchUsername}/videos?filter=archives&sort=time`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.twitchUsername}
                </a>
              </div>
              {
                (item.link) &&
                <div className="table-cell"><Link href={`${pre}${item.link.toLowerCase()}`} target="_blank">{item.link}</Link></div>
              }
              {
                (!item.link) &&
                <div className="table-cell" />
              }
            </div>
          </div>
        );
      });
    }
    return (<div className="NoQuestTiered">No one has joined yet.</div>);
  }

  generateRowsHTML = (questActivity) => {
    const { showingElements } = this.state;
    const { data: quest } = this.props.commandCenter.quest;


    if (questActivity.length > 0) {
      return questActivity.map((questActivityItem, index) => {
        const earnings = reduce(questActivityItem.streamActivity, (sum, item) => {
          if (quest.requiredStreamTimeMinutes && questActivityItem.paymentAmount) {
            return sum + ((item.timePlaying >= quest.requiredStreamTimeMinutes) ? questActivityItem.paymentAmount : 0);
          }
          return sum + 0;
        }, 0);
        if (questActivityItem.user) {
          let tier = '(?)';
          if (questActivityItem.tier === 'viewer') {
            tier = 'V';
          } else if (questActivityItem.tier === 'member') {
            tier = 'M';
          }
          return (
            <div
              key={questActivity.userId}
              className={classNames('row toggable tooltip', { open: (showingElements.indexOf(questActivityItem.user._id) !== -1) })}
            >
              <div className="row-basic">
                <div className="table-cell">{ index + 1 }</div>
                <div className="table-cell">
                  {questActivityItem.user.username}
                  <div className="tooltiptext tooltiptext--100">{questActivityItem.user.email}</div>
                </div>
                <div className="table-cell">
                  ${this.rounding(earnings)}
                </div>
                <div className="table-cell">
                  {tier}
                </div>
                <div className="table-cell">
                  ${questActivityItem.paymentAmount}{(questActivityItem.averageViewersAtJoin) ? ` (${questActivityItem.averageViewersAtJoin} viewers)` : ''}
                </div>
                <div className="table-cell">
                  { (questActivityItem.totalAVGViewers) && Math.round(questActivityItem.totalAVGViewers) }
                </div>
                <div className="table-cell">
                  { (questActivityItem.totalAVGTimePlaying) && Math.round(questActivityItem.totalAVGTimePlaying) }
                </div>
                <div className="table-cell">
                  { (questActivityItem.totalTimePlayed) && Math.round(questActivityItem.totalTimePlayed) }
                </div>
                <div className="table-cell">
                  { (showingElements.indexOf(questActivityItem.user._id) === -1) && (
                    <button className="small-btn" onClick={this.toggleElementInfo.bind(this, questActivityItem.user._id)}>Show Details</button>
                  )}
                  { (showingElements.indexOf(questActivityItem.user._id) !== -1) && (
                    <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, questActivityItem.user._id)}>Hide Details</button>
                  )}
                </div>
              </div>
              <div className="row-details">
                <section className="StreamActivityTable flex-table">
                  <div className="row">
                    <div className="table-cell headline">Date</div>
                    <div className="table-cell headline">Average Viewers</div>
                    <div className="table-cell headline">Time Playing</div>
                    <div className="table-cell headline">Earnings</div>
                  </div>
                  { this.generateStreamActivityRowsHTML(questActivityItem.streamActivity, questActivityItem.paymentAmount) }
                </section>
              </div>
            </div>
          );
        }
        return (<React.Fragment />);
      });
    }
    return (<div className="NoQuestActivity">No Activity on this Quest.</div>);
  }

  changeTab = (value) => {
    this.setState({
      tab: value,
    });
  }

  // getAverageTimePerDay = (streamActivity) => {
  //   var streamsNum = 0;
  //   var totalMins = 0;
  //   for(var x=0;x<streamActivity.length;x++){
  //     var obj=streamActivity[x];
  //     if (obj.timePlaying && obj.timePlaying > 0) {
  //       streamsNum++;
  //       totalMins+=obj.timePlaying;
  //     }
  //   }
  //   return (totalMins/streamsNum).toFixed(2)+" mins";
  // }
  //
  // getAverageViewerPerDay = (streamActivity) => {
  //   var streamsNum = 0;
  //   var totalViewers = 0;
  //   for(var x=0;x<streamActivity.length;x++){
  //     var obj=streamActivity[x];
  //     if (obj.timePlaying && obj.timePlaying > 0) {
  //       streamsNum++;
  //       totalViewers+=obj.AVGViewers;
  //     }
  //   }
  //   return (totalViewers/streamsNum).toFixed(2);
  // }

  changeChart = (value) => {
    this.setState({
      chart: value,
    });
  }

  render() {
    const { gameId } = this.props.router.params;
    const { data: questTieredMultipleDays, isLoading } = this.props.commandCenter.questTieredMultipleDays;
    const { data: quest } = this.props.commandCenter.quest;
    const { showingElements } = this.state;

    const chartMinutesStreamedPerDayData = [];
    const chartTimesCompletedPerDayData = [];
    const chartConcurrentViewersPerDayData = [];
    const chartAverageViewersPerDayData = [];
    const chartPayoutsPerDayData = [];
    const chartProcessingFeePerDayData = [];
    const chartToPayPerDayData = [];

    let tempDate;
    let endDateTime;
    const dates = [];

    if (quest && quest.startDateTime) {
      tempDate = new Date(`${quest.startDateTime.substring(0, 10)}T00:00:00+0000`);
      endDateTime = new Date(`${quest.endDateTime.substring(0, 10)}T00:00:00+0000`);
      if (tempDate <= endDateTime) {
        while (tempDate <= endDateTime) {
          const dateString = `${tempDate.getUTCMonth() + 1}/${tempDate.getUTCDate()}/${tempDate.getUTCFullYear()}`;
          dates.push({
            dateString,
            completedCount: 0,
          });
          chartMinutesStreamedPerDayData.push({
            date: dateString,
            minutesStreamed: 0,
            y: 0,
            x: new Date(dateString),
          });
          chartTimesCompletedPerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartConcurrentViewersPerDayData.push({
            concurrentViewers: 0,
            date: dateString,
            y: 0,
            x: new Date(dateString),
            count: 0,
          });
          chartAverageViewersPerDayData.push({
            averageViewers: 0,
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartPayoutsPerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartProcessingFeePerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          chartToPayPerDayData.push({
            date: dateString,
            y: 0,
            x: new Date(dateString),
          });
          const dateDay = tempDate.getUTCDate();
          tempDate.setUTCDate(dateDay + 1);
        }
      }
    }
    let totalPossibleCompleted = 0;
    if (dates.length > 0 && questTieredMultipleDays.length > 0) {
      totalPossibleCompleted = dates.length * questTieredMultipleDays.length;
    }
    let totalCompletedCount = 0;

    let totalMinutesStreamed = 0;
    let totalHoursStreamed = 0;
    let totalAverageViewers = 0;
    let totalConcurrentViewers = 0;
    let totalEarning = 0;
    let totalToPay = 0;
    let totalProcessingFee = 0;
    let totalUsers = 0;

    const headersCSV = [
      { label: 'Quest Name', key: 'questName' },
      { label: 'Noiz User Name', key: 'noizUsername' },
      { label: 'Twitch User Name', key: 'twitchUsername' },
      { label: 'PayPal Email', key: 'paymentEmail' },
      { label: 'Country', key: 'country' },
      { label: 'Average Viewers', key: 'totalAVGViewers' },
      { label: 'Peak Viewers', key: 'peakViewers' },
      { label: 'Hours Watched', key: 'totalHoursWatched' },
      { label: 'Total Time Played', key: 'totalTimePlayed' },
      { label: 'Completed', key: 'completed' },
      { label: 'Earnings', key: 'earnings' },
      { label: 'Processing Fee', key: 'processingFee' },
      { label: 'To Pay', key: 'toPay' },
      { label: 'Tier', key: 'tier' },
      { label: 'Link', key: 'link' },
    ];
    const dataCSV = [];
    const totalUsersCount = questTieredMultipleDays.length;
    let totalEarnings = (quest && questTieredMultipleDays.length > 0) ?
      reduce(questTieredMultipleDays, (globalEarnings, questActivityItem) => {
        if (questActivityItem.streamActivity) {
          const totalUserEarnings = reduce(questActivityItem.streamActivity, (userEarnings, item) => {
            if (quest.requiredStreamTimeMinutes && questActivityItem.paymentAmount) {
              return userEarnings + ((item.timePlaying >= quest.requiredStreamTimeMinutes) ? questActivityItem.paymentAmount : 0);
            }
            return userEarnings + 0;
          }, 0);
          return globalEarnings + totalUserEarnings;
        }
        return globalEarnings + 0;
      }, 0) : 0;
    forEach(questTieredMultipleDays, (value) => {
      let userEarnings = 0;
      if (value.streamActivity) {
        forEach(value.streamActivity, (value2) => {
          // HERE - Boolean constant with the value of completed
          const completed = (value2.timePlaying >= quest.requiredStreamTimeMinutes);
          // HERE - Date
          const date = `${value2.month}/${value2.day}/${value2.year}`;
          // HERE - Sum user earnings
          userEarnings += (completed ? value.paymentAmount : 0);
          // HERE - Sum for total concurrent
          totalConcurrentViewers += value2.AVGViewers;
          // START - Total completed count
          if (completed) {
            totalCompletedCount += 1;
          }
          // END - Total completed count
          // START - Completed users per day
          const tempCompletedPerDay = {
            date,
            minutesStreamed: value2.timePlaying,
            y: value2.timePlaying,
          };
          const index2 = findIndex(chartTimesCompletedPerDayData, { date: tempCompletedPerDay.date });
          if (index2 >= 0 && completed) {
            chartTimesCompletedPerDayData[index2].completedCount += 1;
            chartTimesCompletedPerDayData[index2].y += 1;
          }
          // END - Completed users per day
          // START - Chart minutes streamed per day
          const tempMinutesStreamedPerDay = {
            date,
            minutesStreamed: value2.timePlaying,
            y: value2.timePlaying,
          };
          const index = findIndex(chartMinutesStreamedPerDayData, { date: tempMinutesStreamedPerDay.date });
          if (index >= 0) {
            chartMinutesStreamedPerDayData[index].minutesStreamed += tempMinutesStreamedPerDay.minutesStreamed;
            chartMinutesStreamedPerDayData[index].y += tempMinutesStreamedPerDay.minutesStreamed;
          }
          // END - Chart minutes streamed per day
          // START - Chart concurrent viewers per day
          const tempConcurrentPerDay = {
            date,
            concurrentViewers: value2.AVGViewers,
            y: value2.AVGViewers,
          };
          const index3 = findIndex(chartConcurrentViewersPerDayData, { date: tempConcurrentPerDay.date });
          if (index3 >= 0) {
            chartConcurrentViewersPerDayData[index3].concurrentViewers += tempConcurrentPerDay.concurrentViewers;
            chartConcurrentViewersPerDayData[index3].y += tempConcurrentPerDay.concurrentViewers;
            chartConcurrentViewersPerDayData[index3].count += 1;
          }
          // END - Chart concurrent viewers per day
          // START - Chart 'payouts' - 'processing fee' - 'to pay' per day
          const tempMoneyStatsPerDay = {
            date,
            payouts: (completed ? value.paymentAmount : 0),
            processingFee: (completed ? (paymentVendorProcessingFee(value.paymentAmount)) : 0),
            toPay: (completed ? (value.paymentAmount - (paymentVendorProcessingFee(value.paymentAmount))) : 0),
          };
          const index4 = findIndex(chartPayoutsPerDayData, { date: tempMoneyStatsPerDay.date });
          if (index4 >= 0) {
            chartPayoutsPerDayData[index4].y += tempMoneyStatsPerDay.payouts;
            chartProcessingFeePerDayData[index4].y += tempMoneyStatsPerDay.processingFee;
            chartToPayPerDayData[index4].y += tempMoneyStatsPerDay.toPay;
          }
          // END - Chart 'payouts' - 'processing fee' - 'to pay' per day
        });
      }
      if (value.user) {
        // User earnings
        const earnings = (userEarnings);
        // User processingFee
        const processingFee = (paymentVendorProcessingFee(userEarnings));
        // User toPay
        const toPay = (userEarnings - (paymentVendorProcessingFee(userEarnings)));

        dataCSV.push({
          questName: quest.title,
          noizUsername: value.user.username,
          twitchUsername: value.user.connectedAccounts.twitch.username,
          paymentEmail: value.user.paymentEmail,
          country: value.user.country,
          totalAVGViewers: Math.round(value.totalAVGViewers),
          peakViewers: Math.round(value.peakViewers),
          totalHoursWatched: parseFloat(value.totalMinutesWatched / 60).toFixed(2),
          totalTimePlayed: Math.round(value.totalTimePlayed),
          completed: (value && value.streamActivity) ? value.streamActivity.length : false,
          earnings,
          processingFee,
          toPay,
          tier: (value.tier === 'viewer') ? `${value.tier} (${value.averageViewersAtJoin} viewers)` : value.tier,
          link: (value.link) ? value.link : '',
        });
        totalMinutesStreamed += value.totalTimePlayed;
        totalAverageViewers += value.totalAVGViewers;
        totalUsers += 1;
        totalEarning += parseFloat(earnings);
        totalProcessingFee += parseFloat(processingFee);
        totalToPay += parseFloat(toPay);
      } else {
        totalEarnings -= userEarnings;
      }
    });
    totalHoursStreamed = this.rounding(totalMinutesStreamed / 60);
    totalAverageViewers = Math.floor(totalAverageViewers / totalUsers);
    totalEarning = parseFloat(totalEarning).toFixed(2);
    totalProcessingFee = parseFloat(totalProcessingFee).toFixed(2);
    totalToPay = parseFloat(totalToPay).toFixed(2);
    totalConcurrentViewers = Math.floor(totalConcurrentViewers);

    const totalNotCompleted = totalPossibleCompleted - totalCompletedCount;
    const percentageCompleted = this.rounding((totalCompletedCount / totalPossibleCompleted) * 100);
    const chartHoursStreamedPerDayData = [];
    forEach(chartMinutesStreamedPerDayData, (o) => {
      chartHoursStreamedPerDayData.push({
        x: o.x,
        y: parseFloat(o.y / 60).toFixed(2),
      });
    });
    for (let i = 0; i < dates.length; i += 1) {
      chartConcurrentViewersPerDayData[i].y = Math.round(chartConcurrentViewersPerDayData[i].y);
      if (chartConcurrentViewersPerDayData[i].count !== 0) {
        chartAverageViewersPerDayData[i].y = this.rounding(chartConcurrentViewersPerDayData[i].y / chartConcurrentViewersPerDayData[i].count);
      }
      chartPayoutsPerDayData[i].y = this.rounding(chartPayoutsPerDayData[i].y);
      chartProcessingFeePerDayData[i].y = this.rounding(chartProcessingFeePerDayData[i].y);
      chartToPayPerDayData[i].y = this.rounding(chartToPayPerDayData[i].y);
    }
    const timesFailed = [];
    for (let i = 0; i < chartTimesCompletedPerDayData.length; i += 1) {
      const tempFailed = {
        x: chartTimesCompletedPerDayData[i].x,
        y: totalUsersCount - chartTimesCompletedPerDayData[i].y,
        date: chartTimesCompletedPerDayData[i].date,
      };
      timesFailed.push(tempFailed);
    }
    // chartTimesCompletedPerDayData = [...chartTimesCompletedPerDayData, ...timesFailed];
    const stackedData =
      [
        {
          label: 'Succesful',
          data: chartTimesCompletedPerDayData,
          backgroundColor: '#55DD6B',
        },
        {
          label: 'Failed',
          data: timesFailed,
          backgroundColor: '#E23645',
        },
      ];

    const memberTiersOpenSpots = [];
    const viewerTiersSpotsOccupied = [];

    if (quest.memberTiers && quest.memberTiers.length) {
      for (let i = 0; i < quest.memberTiers.length; i += 1) {
        const found = find(questTieredMultipleDays, o => o.tier === 'member' && o.twitchUsername === quest.memberTiers[i].identity);
        if (found) {
          memberTiersOpenSpots.push(0);
        } else {
          memberTiersOpenSpots.push(1);
        }
      }
    }

    if (quest.viewerTiers && quest.viewerTiers.length) {
      for (let i = 0; i < quest.viewerTiers.length; i += 1) {
        const found = filter(questTieredMultipleDays, o => o.tier === 'viewer' && o.paymentAmount === quest.viewerTiers[i].paymentAmount);
        viewerTiersSpotsOccupied.push(found.length);
      }
    }

    if (isLoading) {
      return <section className="CCQuestTieredMultipleDays loading"><i className="fa fa-spinner fa-spin fa-3x fa-fw" /></section>;
    }

    const containsLinks = filter(questTieredMultipleDays, o => (o.link)).length;

    return (
      <section className="CCQuestTieredMultipleDays">
        <Link
          to={`/admin/command-center/${gameId}/quests`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests`}
        >
          <button className="CCQuests__back--btn">Back</button>
        </Link>
        <h2>Title: { quest.title }</h2>
        <h2>Quest Users: <span>{filter(questTieredMultipleDays, o => o.user).length}</span></h2>
        <div className="Global__tabs">
          <div
            className={classNames('Global__tab', { active: this.state.tab === 1 })}
            onClick={this.changeTab.bind(this, 1)}
            aria-hidden
          >
            Summary
          </div>
          <div
            className={classNames('Global__tab', { active: this.state.tab === 2 })}
            onClick={this.changeTab.bind(this, 2)}
            aria-hidden
          >
            Details
          </div>
          <div
            className={classNames('Global__tab', { active: this.state.tab === 3 })}
            onClick={this.changeTab.bind(this, 3)}
            aria-hidden
          >
            Tier Summary
          </div>
          {
            (containsLinks > 0) &&
            <div
              className={classNames('Global__tab', { active: this.state.tab === 5 })}
              onClick={this.changeTab.bind(this, 5)}
              aria-hidden
            >
              Links
            </div>
          }
        </div>
        {
          (this.state.tab === 1)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__body-wrapper">
              <section className="ToggleTable__body">
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Hours Streamed <i className="fa fa-info-circle" />
                      <div className="tooltiptext">The sum of all hours streamed by all participants.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button', { active: this.state.chart === 1 })}
                        onClick={this.changeChart.bind(this, 1)}
                        aria-hidden
                      >
                        { (totalHoursStreamed && !isNaN(totalHoursStreamed)) ? totalHoursStreamed : '0' }
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Average Viewers - All Users <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the average of the average viewers for all users.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button', { active: this.state.chart === 2 })}
                        onClick={this.changeChart.bind(this, 2)}
                        aria-hidden
                      >
                        { totalAverageViewers || '0' }
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Average Viewers <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the total average viewers from each user's daily Average Viewer count.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button', { active: this.state.chart === 3 })}
                        onClick={this.changeChart.bind(this, 3)}
                        aria-hidden
                      >
                        { totalConcurrentViewers || 0 }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Succesful <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the count of all times a user completed this quest, one time or daily.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot green">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button green', { active: this.state.chart === 4 })}
                        onClick={this.changeChart.bind(this, 4)}
                        aria-hidden
                      >
                        { totalCompletedCount }
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Fails <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is a count of all times a user failed this quest, one time or daily.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot red">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button red', { active: this.state.chart === 4 })}
                        onClick={this.changeChart.bind(this, 4)}
                        aria-hidden
                      >
                        { totalNotCompleted }
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      % Completed Quest <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Percentage of users that completed this quest.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div>
                        { (percentageCompleted) || '0' }%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Payouts <i className="fa fa-info-circle" />
                      <div className="tooltiptext">To-date cumulative earnings for all successful quest users.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button', { active: this.state.chart === 5 })}
                        onClick={this.changeChart.bind(this, 5)}
                        aria-hidden
                      >
                        ${ totalEarning }
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Processing Fee <i className="fa fa-info-circle" />
                      <div className="tooltiptext">3% of total payouts.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button', { active: this.state.chart === 6 })}
                        onClick={this.changeChart.bind(this, 6)}
                        aria-hidden
                      >
                        ${ totalProcessingFee }
                      </div>
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total to Pay <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Total Payouts - Total Processing Fee.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      <div
                        className={classNames('ToggleTable__body-itemBot_button', { active: this.state.chart === 7 })}
                        onClick={this.changeChart.bind(this, 7)}
                        aria-hidden
                      >
                        ${ totalToPay }
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames('CCQuestTieredMultipleDays__chart', { active: this.state.chart === 1 })}
                >
                  <div className="CCQuestTieredMultipleDays__chart_title">Total Hours Streamed</div>
                  <BarChart data={chartHoursStreamedPerDayData} labelY="Total Hours Streamed" />
                </div>
                <div
                  className={classNames('CCQuestTieredMultipleDays__chart', { active: this.state.chart === 2 })}
                >
                  <div className="CCQuestTieredMultipleDays__chart_title">Average Viewers - All Users</div>
                  <BarChart data={chartAverageViewersPerDayData} labelY="Average Viewers - All Users" />
                </div>
                <div
                  className={classNames('CCQuestTieredMultipleDays__chart', { active: this.state.chart === 3 })}
                >
                  <div className="CCQuestTieredMultipleDays__chart_title">Total Average Viewers</div>
                  <BarChart data={chartConcurrentViewersPerDayData} labelY="Total Average Viewers" />
                </div>
                <div
                  className={classNames('CCQuestTieredMultipleDays__chart', { active: this.state.chart === 4 })}
                >
                  <div className="CCQuestTieredMultipleDays__chart_title">Total Succesful / Total Fails</div>
                  <StackedChart data={stackedData} labelY="Total Succesful / Total Fails" />
                </div>
                <div
                  className={classNames('CCQuestTieredMultipleDays__chart', { active: this.state.chart === 5 })}
                >
                  <div className="CCQuestTieredMultipleDays__chart_title">Total Payouts</div>
                  <BarChart data={chartPayoutsPerDayData} labelY="Total Payouts" />
                </div>
                <div
                  className={classNames('CCQuestTieredMultipleDays__chart', { active: this.state.chart === 6 })}
                >
                  <div className="CCQuestTieredMultipleDays__chart_title">Total Processing Fee</div>
                  <BarChart data={chartProcessingFeePerDayData} labelY="Total Processing Fee" />
                </div>
                <div
                  className={classNames('CCQuestTieredMultipleDays__chart', { active: this.state.chart === 7 })}
                >
                  <div className="CCQuestTieredMultipleDays__chart_title">Total to Pay</div>
                  <BarChart data={chartToPayPerDayData} labelY="Total to Pay" />
                </div>
              </section>
            </section>
          </section>
        }
        {
          (this.state.tab === 2)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">Quest Users: <span>{questTieredMultipleDays.length}</span></h3>
              <CSVLink data={dataCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}.csv` : 'report.csv'}>
                <i className="fa fa-download" />
              </CSVLink>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Username</div>
                  <div className="table-cell headline">Earnings</div>
                  <div className="table-cell headline">Tier</div>
                  <div className="table-cell headline">Payment Amount (Daily)</div>
                  <div className="table-cell headline">Avg Viewers/day</div>
                  <div className="table-cell headline">Avg time/day</div>
                  <div className="table-cell headline">Total time played</div>
                  <div className="table-cell headline">Actions</div>
                </div>
                { this.generateRowsHTML(questTieredMultipleDays) }
                <div className="row">
                  <div className="table-cell">---</div>
                  <div className="table-cell">---</div>
                  <div className="table-cell">TOTAL: </div>
                  <div className="table-cell">${this.rounding(totalEarnings)}</div>
                  <div className="table-cell">---</div>
                </div>
              </section>
            </section>
          </section>
        }
        {
          (this.state.tab === 3)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="ToggleTable__table--section" style={{ display: (quest.memberTiers.length !== 0) ? 'block' : 'none' }}>
                  <div className="ToggleTable__table--title">
                    Member Tiers
                  </div>
                  <div className="row">
                    <div className="table-cell headline" style={{ maxWidth: 'inherit' }}>Twitch Username</div>
                    <div className="table-cell headline" style={{ fontWeight: 'normal' }}>Payment Amount</div>
                    <div className="table-cell headline">Open Spots Left</div>
                  </div>
                  {
                    quest.memberTiers.map((mt, mtIndex) => (
                      <div key={mt.paymentAmount} className="row">
                        <div className="table-cell" style={{ maxWidth: 'inherit' }}>{mt.identity}</div>
                        <div className="table-cell" style={{ fontWeight: 'normal' }}>${mt.paymentAmount}</div>
                        <div className="table-cell">{(memberTiersOpenSpots[mtIndex] <= 0) ? 'FULL' : memberTiersOpenSpots[mtIndex]}</div>
                      </div>
                    ))
                  }
                </div>
                <div className="ToggleTable__table--section" style={{ display: (quest.viewerTiers.length !== 0) ? 'block' : 'none' }}>
                  <div className="ToggleTable__table--title">
                    Viewer Tiers
                  </div>
                  <div className="row">
                    <div className="table-cell headline" style={{ maxWidth: 'inherit' }}>Payment Amount</div>
                    <div className="table-cell headline" style={{ fontWeight: 'normal' }}>Minimum Viewers</div>
                    <div className="table-cell headline">Open Spots Left</div>
                    <div className="table-cell headline" />
                  </div>
                  {
                    quest.viewerTiers.map((vt, vtIndex) => {
                      const openSpots = vt.spotsAvailable - viewerTiersSpotsOccupied[vtIndex];
                      return (
                        <div
                          key={vt.paymentAmount}
                          className={classNames('row toggable', { open: (showingElements.indexOf(vt.paymentAmount) !== -1) })}
                        >
                          <div className="row-basic">
                            <div className="table-cell" style={{ maxWidth: 'inherit' }}>${vt.paymentAmount}</div>
                            <div className="table-cell" style={{ fontWeight: 'normal' }}>{vt.minimumAverageViewers}</div>
                            <div className="table-cell">{(openSpots <= 0) ? 'FULL' : openSpots}</div>
                            <div className="table-cell last">
                              {
                                (showingElements.indexOf(vt.paymentAmount) === -1)
                                &&
                                (
                                  <button className="small-btn" onClick={this.toggleElementInfo.bind(this, vt.paymentAmount)}>Show Details</button>
                                )
                              }
                              {
                                (showingElements.indexOf(vt.paymentAmount) !== -1)
                                &&
                                (
                                  <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, vt.paymentAmount)}>Hide Details</button>
                                )
                              }
                            </div>
                          </div>
                          <div className="row-details">
                            <section className="flex-table">
                              <div className="row">
                                <div className="table-cell headline">Username</div>
                                <div className="table-cell headline">Twitch Username</div>
                                <div className="table-cell headline">Average Viewers</div>
                              </div>
                              {
                                (questTieredMultipleDays.length > 0) &&
                                questTieredMultipleDays.map((item) => {
                                  const viewerTiers = orderBy(quest.viewerTiers, ['minimumAverageViewers'], ['desc']);
                                  let higherPaymentAmount = 0;
                                  let highlight = <React.Fragment />;
                                  for (let i = 0; i < viewerTiers.length; i += 1) {
                                    if (item.averageViewersAtJoin >= viewerTiers[i].minimumAverageViewers) {
                                      higherPaymentAmount = viewerTiers[i].paymentAmount;
                                      break;
                                    }
                                  }
                                  if (higherPaymentAmount > item.paymentAmount) {
                                    highlight =
                                      (
                                        <div className="tooltip">
                                          <i className="fa fa-info-circle" />
                                          <div className="tooltiptext nodecoration">This user qualifies for a higher tier.</div>
                                        </div>
                                      );
                                  }
                                  if (item.paymentAmount === vt.paymentAmount) {
                                    return (
                                      <div
                                        key={item.id}
                                        className="row"
                                      >
                                        <div className="table-cell">{item.username}</div>
                                        <div className="table-cell">{item.twitchUsername}</div>
                                        <div className="table-cell">{item.averageViewersAtJoin}{highlight}</div>
                                      </div>
                                    );
                                  }
                                  return <React.Fragment />;
                                })
                              }
                              {
                                (questTieredMultipleDays.length <= 0) &&
                                (<div className="NoStreamActivity">No users have joined this quest</div>)
                              }
                            </section>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </section>
            </section>
          </section>
        }
        {
          (this.state.tab === 5)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">Twitch</div>
                  <div className="table-cell headline">Link</div>
                </div>
                { this.generateLinkRowsHTML(questTieredMultipleDays, quest) }
              </section>
            </section>
          </section>
        }
      </section>
    );
  }
}

QuestTieredMultipleDays.propTypes = {

};

export default QuestTieredMultipleDays;
