const baseFunc = classText => (
  inTime = 500,
  outTime = 500,
  appear = true,
  enter = true,
  exit = true,
) => ({
  classNames: classText,
  timeout: { enter: inTime, exit: outTime },
  appear,
  enter,
  exit,
});

export const fade = baseFunc('fadeinout');
export const longFade = baseFunc('longfadeinout');
export const slide = baseFunc('slide');
