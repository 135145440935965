

export const tableSchema = {
  twitchUsername: "STREAMER",
  deliverables: "DELIVERABLES",
  schedule: "SCHEDULE",
  vod: "VOD",
  vodViews: "VOD VIEWS",
  hoursWatched: "HOURS WATCHED",
  peakCCV: "PEAK CCV",
  ccv: "CCV",
  hoursStreamed: "HOURS STREAMED",
  chatMessages: "CHAT MESSAGES",
  tweets: "TWEETS",
  engagements: "ENGAGEMENTS",
  impressions: "IMPRESSIONS/FOL",
  // profileImage: "PROFILE IMAGE",
};

// remake tableSchema with all values as empty arrays

const emptyTemplate = () => ({
  twitchUsername: [],
  deliverables: [],
  schedule: [],
  vod: [],
  vodViews: [],
  hoursWatched: [],
  peakCCV: [],
  ccv: [],
  hoursStreamed: [],
  chatMessages: [],
  tweets: [],
  engagements: [],
  impressions: [],
  // profileImage: [],
});


export const finalsRow = (tableReady) => {
  let allTogether = ['','','','',0,0,0,0,0,0,0,0,0]

    for (let pointer = 4; pointer < allTogether.length; pointer++) {
    allTogether[pointer] = tableReady.reduce((acc,streamer) => {
      return acc + streamer[pointer].reduce((a, x)=> a + isNaN(x) ? 0 : x, 0)
    },0)
  }
  return allTogether.map(val => [val]);
}

const mapToArray = (arrOfObj, value = "questTiered") => arrOfObj.map(obj => obj[value]);

const deliverablesString = (streams, tweets) => `${streams} stream${streams > 1 ? 's' : ''}${ tweets ? ` and ${tweets} tweet${streams > 1 ? 's' : ''}` : ""}`;

const getImpressions = userQuestPerformanceInfo => {
  const impressions = userQuestPerformanceInfo?.user?.connectedAccounts?.twitter?.followers_count || 0;
  // const friends = userQuestPerformanceInfo?.user?.connectedAccounts?.twitter?.friends_count || 0;

  return impressions;
};

const getProfileImageURL = userQuestPerformanceInfo => {
  const profile = userQuestPerformanceInfo?.user?.connectedAccounts?.twitch?.profile_image_url || "";
  const offLineProfile = userQuestPerformanceInfo?.user?.connectedAccounts?.twitch?.offline_image_url || "";
  return profile || offLineProfile;
};

const getVod = (userQuestPerformanceInfo) => {
  const { requirements = [] } = userQuestPerformanceInfo;
  const vodArr = [];
  requirements.forEach(req => {
    if (req.type === "submit-link" && req.subtype === "vod") {
      vodArr.push({ vod: req.content || 0, vodViews:req.views || 0 })
    }
    // if (vodObj[req.questReqId]) {

    // }
  });
  return  vodArr.length ? vodArr.reduce((acc, v) => {
    acc.vod.push(v.vod);
    acc.vodViews.push(v.vodViews);
    return acc;
  }, {vod: [], vodViews: []}) : {vod: [], vodViews: [0]};
};

const getSchedule = (userQuestPerformanceInfo, vodObj = {}) => {
  const { requirements = [] } = userQuestPerformanceInfo;
  const schedArr = [];
  requirements.forEach(req => {
    if (req.type === "submit-text" && req.subtype === "schedule") {
      schedArr.push(req.content)
    }
  });
  return schedArr.length ? schedArr : ["none given"];
};

const getTweet = userQuestPerformanceInfo => {
  const { requirements = [] } = userQuestPerformanceInfo;
  let theTweets = [];
  requirements.forEach(req => {
    if (req.type === "submit-tweet") {
      const { tweetData = {} } = req;
      const { likes = 0, quotes = 0, retweets = 0, comments = 0 } = tweetData;
      const tweetLink = tweetData.fullTweet || "none";
      const tweetEngagements = likes + quotes + retweets + comments || 0;
      const text = tweetData.text || "none";
      theTweets.push({ tweet: tweetLink, engagements: tweetEngagements, text });
    }
  });
  const final = theTweets.reduce(
    (acc, t) => {
      const { tweet, engagements, text } = t;
      acc.tweet.push(tweet);
      acc.engagements.push(engagements);
      acc.text.push(text);
      return acc;
    },
    { tweet: [], engagements: [], text: [] }
  );
  return final;
};




const getDeliverablesPerQuest = quests => {
  return quests.map(function (quest) {
    const { beforeStream, duringStream, afterStream } = quest.requirements;
    let tweets = 0;
    let streams = 1
    if (!beforeStream || !duringStream || !afterStream) return {};
    [beforeStream, duringStream, afterStream].forEach(requirements => {
      requirements.forEach(req => {
        if (req.type === "submit-tweet") {
          tweets += 1;
        }
      });
    });
    return {quest: quest.id, tweets, streams}
  });
};

const getVODIds = quests => {
  const vodIds = {};
  quests.forEach(quest => {
    const { afterStream } = quest.requirements;
    if (!afterStream) return;
    afterStream.forEach(req => {
      if (req.type === "submit-link" && req.subtype === "vod") {
        vodIds[req.id] = true;
      }
    });
  });
  return vodIds;
};



const getTwitchData = userQuestPerformanceInfo => {
  const twitchUsername = userQuestPerformanceInfo?.user?.connectedAccounts?.twitch?.username || "";
  const { peakViewers = 0, timePlaying = 0, averageViewers = 0 } = userQuestPerformanceInfo;
  const hoursBase = averageViewers * timePlaying;
  const hoursWatched = Math.round(Math.floor(hoursBase / 60), 2);
  const hoursStreamed = Math.round(Math.floor(timePlaying / 60), 2);
  const profileImage = getProfileImageURL(userQuestPerformanceInfo);
  const totalMessages = userQuestPerformanceInfo?.chatDetails?.totalMessages || 0;
  return {
    hoursWatched,
    hoursStreamed,
    ccv: Math.round(averageViewers),
    peakCCV: peakViewers,
    twitchUsername,
    profileImage,
    chatMessages: totalMessages,
  };
};



const assembly = (prepped, vodIds, deliverables) => {
 

 return prepped
    .map(questAndUserQuests => {
      return questAndUserQuests.map(userQuestPerformanceInfo => {
        const twitchInfo = getTwitchData(userQuestPerformanceInfo);
        const vodInfo = getVod(userQuestPerformanceInfo.requirements, vodIds);
        const tweetData = getTweet(userQuestPerformanceInfo);
        const schedule = getSchedule(userQuestPerformanceInfo);
        const {quest:questId} = userQuestPerformanceInfo;
        let streams = 0, tweets = 0;
         const d = deliverables.find(d => d.quest === questId);
         if (d){
            streams = d.streams || 0;
            tweets = d.tweets || 0;
         }

        return {
          ...vodInfo,
          ...twitchInfo,
          deliverables: {deliverableS: streams, deliverableT: tweets},
          impressions: getImpressions(userQuestPerformanceInfo),
          ...getVod(userQuestPerformanceInfo, vodIds),
          ...tweetData,
          schedule
        };
      });
    })
    .reduce((acc, tier) => {
      for (let i = 0; i < tier.length; i++) {
        const userRow = tier[i];

        const { twitchUsername } = userRow;
        if (acc[twitchUsername]) {
          acc[twitchUsername].push(userRow);
        } else {
          acc[twitchUsername] = [userRow];
        }
      }
      return acc;

    }, {});

  }

const createRows = chartData => {
  return chartData.map(streamer => {
    let flatData = emptyTemplate();
    if (!streamer || !streamer.length) return [];
    const statics = streamer[0];
    const { twitchUsername, profileImage } = statics;
    flatData.twitchUsername = [profileImage, twitchUsername];
    flatData.deliverables = [0, 0];
    for (let i = 0; i < streamer.length; i++) {
      const stream = streamer[i];
      const {
        schedule,
        vod,
        vodViews,
        hoursWatched,
        peakCCV,
        ccv,
        hoursStreamed,
        chatMessages,
        tweet: fullTweet = "",
        // text: tweetText,
        deliverables: {
        deliverableS = 0,
        deliverableT = 0
      },
        engagements: tweetEngagements,
        impressions = 0,
      } = stream;

      if (schedule) {
        flatData.schedule = [...flatData.schedule, schedule];
      }
      flatData.vod = [...flatData.vod, ...vod];
      flatData.vodViews = [...flatData.vodViews, ...vodViews];
      flatData.hoursWatched.push(hoursWatched);
      flatData.peakCCV.push(peakCCV);
      flatData.ccv.push(ccv);
      flatData.hoursStreamed.push(hoursStreamed);
      flatData.chatMessages.push(chatMessages);
      flatData.tweets = [...flatData.tweets, ...fullTweet];
      flatData.engagements = [...flatData.engagements, ...tweetEngagements];
      flatData.impressions = [impressions]
      flatData.deliverables[0] += deliverableS;
      flatData.deliverables[1] += deliverableT;
    }
      const streams = flatData.deliverables[0] || 0;
      const tweets = flatData.deliverables[1] || 0;
      flatData.deliverables = [deliverablesString(streams, tweets)];
    
    
    return Object.values(flatData);
  });
};

export const formatTiersForTable = (tiers = [], referenceQuests = []) => {
  // pulls userquest tiers off API object with quests and userquests
  const prepped = mapToArray(tiers);
  // gets deliverable count from each quest
  const deliverablesMap = getDeliverablesPerQuest(referenceQuests);
  // gets vod ids from quest
  const vodIds = getVODIds(referenceQuests);
  // formats data to be used in table
  const final = assembly(prepped, vodIds, deliverablesMap);
  return createRows(Object.values(final));
};
