import React from 'react';

import LoadingGif from '../../../components/brand/portal/assets/LoadingGif';

import './Loading.scss';

const Loading = ({ isBrand = false }) => (
  <div className='Loading'>
    {
      !isBrand ?
        <i className="fa fa-spinner fa-spin fa-3x fa-fw" /> :
        <LoadingGif />
    }
  </div>
);

export default Loading;
