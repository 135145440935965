class Icon {
  constructor(index, el, speed, callback) {
    this.width = el.offsetWidth;
    this.height = el.offsetHeight;
    this.x = (window.innerWidth - this.width) * Math.random();
    this.y = (window.innerHeight - this.height) * Math.random();
    this.vx = ((4 * Math.random()) - 2) / speed;
    this.vy = ((4 * Math.random()) - 2) / speed;
    this.el = el;
    this.callback = callback;
  }


  setPosition() {
    this.x += this.vx;
    this.y += this.vy;
    const { innerWidth, innerHeight } = window;

    if (this.x < 0 || (this.x + this.width) > innerWidth) {
      this.vx = -this.vx;
    }
    if (this.y < 0 || (this.y + this.height) > innerHeight) {
      this.vy = -this.vy;
    }

    const xdir = this.vx < 0 ? 0 : 1;
    const ydir = this.vy < 0 ? 0 : 1;
    const xOne = innerWidth - (innerWidth * (0.75));
    const xTwo = innerWidth - (innerWidth * (0.25));
    const yOne = innerHeight - (innerHeight * (0.75));
    const yTwo = innerHeight - (innerHeight * (0.2));

    if (
      (this.x + ((this.width / 1) * xdir)) > xOne
      && (this.x + ((this.width / 1) * xdir)) < xTwo
      && (this.y + ((this.height / 1) * ydir)) > yOne
      && (this.y + ((this.height / 1) * ydir)) < yTwo
    ) {
      this.el.classList.add('dimmed');
    } else {
      this.el.classList.remove('dimmed');
    }

    this.el.style.webkitTransform = `translate(${this.x}px, ${this.y}px)`;
  }
}

export default Icon;
