import {
  filter,
  map,
  omit,
  find,
  remove,
} from 'lodash';

const commandCenter = (state = {}, action) => {
  switch (action.type) {
    case 'GET_CC_GAME_LIST_PENDING':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_GAME_LIST_ERROR':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_GAME_LIST_ABORTED':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_GAME_LIST_SUCCESS':
      return {
        ...state,
        games: {
          ...state.games,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPLOAD_KEYS_PENDING':
      return {
        ...state,
        uploadKeys: {
          ...state.uploadKeys,
          isLoading: true,
          data: {
            createdTotal: 0,
            repeatedTotal: 0,
            createdKeys: [],
            repeatedKeys: [],
          },
          errors: {
            ...state.uploadKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPLOAD_KEYS_ERROR':
      return {
        ...state,
        uploadKeys: {
          ...state.uploadKeys,
          isLoading: false,
          data: {
            createdTotal: 0,
            repeatedTotal: 0,
            createdKeys: [],
            repeatedKeys: [],
          },
          errors: {
            ...state.uploadKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'UPLOAD_KEYS_ABORTED':
      return {
        ...state,
        uploadKeys: {
          ...state.uploadKeys,
          isLoading: false,
          data: {
            createdTotal: 0,
            repeatedTotal: 0,
            createdKeys: [],
            repeatedKeys: [],
          },
          errors: {
            // We are placing here the uploadKeys error because it's the only one this response
            ...state.uploadKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPLOAD_KEYS_SUCCESS':
      return {
        ...state,
        uploadKeys: {
          ...state.uploadKeys,
          data: {
            ...action.payload.response.data,
          },
          isLoading: false,
          errors: {
            ...state.uploadKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        gameKeys: {
          ...state.gameKeys,
          data: [
            ...action.payload.response.data.allKeys,
          ],
          isLoading: false,
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_KEYS_PENDING':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: true,
          data: [],
          usersWaitingForKeys: [],
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_KEYS_ERROR':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          data: [],
          usersWaitingForKeys: [],
          errors: {
            ...state.gameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_GAME_KEYS_ABORTED':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          data: [],
          usersWaitingForKeys: [],
          errors: {
            // We are placing here the gameKeys error because it's the only one this response
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_KEYS_SUCCESS':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          data: [
            ...action.payload.response.gameKeys,
          ],
          usersWaitingForKeys: [
            ...action.payload.response.usersWaitingForKeys,
          ],
          isLoading: false,
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'DELETE_GAME_KEYS_PENDING':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: true,
          data: [
            ...map(state.gameKeys.data, (gameKey) => {
              if (action.payload.gameKeyId === gameKey.id) {
                return {
                  ...gameKey,
                  loading: true,
                };
              }
              return gameKey;
            }),
          ],
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'DELETE_GAME_KEYS_ERROR':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          data: [
            ...map(state.gameKeys.data, (gameKey) => {
              if (action.payload.gameKeyId !== gameKey.id) {
                return omit(gameKey, 'loading');
              }
              return gameKey;
            }),
          ],
          errors: {
            ...state.gameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'DELETE_GAME_KEYS_ABORTED':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          data: [
            ...map(state.gameKeys.data, (gameKey) => {
              if (action.payload.gameKeyId !== gameKey.id) {
                return omit(gameKey, 'loading');
              }
              return gameKey;
            }),
          ],
          errors: {
            // We are placing here the gameKeys error because it's the only one this response
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'DELETE_GAME_KEYS_SUCCESS':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          data: [
            ...filter(state.gameKeys.data, gameKey => (action.payload.response.gameKeyIds.indexOf(gameKey.id) <= -1)),
          ],
          isLoading: false,
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BOUNTY_PREDICTIONS_PENDING':
      return {
        ...state,
        bountyPredictions: {
          ...state.bountyPredictions,
          isLoading: true,
          data: [],
          errors: {
            ...state.bountyPredictions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BOUNTY_PREDICTIONS_ERROR':
      return {
        ...state,
        bountyPredictions: {
          ...state.bountyPredictions,
          isLoading: false,
          data: [],
          errors: {
            ...state.bountyPredictions.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_BOUNTY_PREDICTIONS_ABORTED':
      return {
        ...state,
        bountyPredictions: {
          ...state.bountyPredictions,
          isLoading: false,
          data: [],
          errors: {
            ...state.bountyPredictions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_BOUNTY_PREDICTIONS_SUCCESS':
      return {
        ...state,
        bountyPredictions: {
          ...state.bountyPredictions,
          data: action.payload.response.bountyPredictions,
          isLoading: false,
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_WHITE_LIST_PENDING':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          isLoading: true,
          data: [],
          errors: {
            ...state.whiteList.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_WHITE_LIST_ERROR':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          isLoading: false,
          data: [],
          errors: {
            ...state.whiteList.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_WHITE_LIST_ABORTED':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          isLoading: false,
          data: [],
          errors: {
            // We are placing here the whiteList error because it's the only one this response
            ...state.whiteList.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_WHITE_LIST_SUCCESS':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          data: [
            ...action.payload.response.data,
          ],
          isLoading: false,
          errors: {
            ...state.whiteList.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'TOGGLE_USER_IN_WHITE_LIST_PENDING':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          isLoading: true,
          data: [
            ...map(state.whiteList.data, (item) => {
              if (action.payload.userId === item.id) {
                return {
                  ...item,
                  loading: true,
                };
              }
              return item;
            }),
          ],
          errors: {
            ...state.whiteList.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'TOGGLE_USER_IN_WHITE_LIST_ERROR':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          isLoading: false,
          data: [
            ...map(state.whiteList.data, (item) => {
              if (action.payload.userId !== item.id) {
                return omit(item, 'loading');
              }
              return item;
            }),
          ],
          errors: {
            ...state.whiteList.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'TOGGLE_USER_IN_WHITE_LIST_ABORTED':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          isLoading: false,
          data: [
            ...map(state.whiteList.data, (item) => {
              if (action.payload.userId !== item.id) {
                return omit(item, 'loading');
              }
              return item;
            }),
          ],
          errors: {
            // We are placing here the whiteList error because it's the only one this response
            ...state.whiteList.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'TOGGLE_USER_IN_WHITE_LIST_SUCCESS':
      return {
        ...state,
        whiteList: {
          ...state.whiteList,
          data: (action.payload.response.code === 'ADDED_TO_WHITE_LIST') ?
            [...state.whiteList.data, action.payload.response.user] :
            [...filter(state.whiteList.data, item => action.payload.response.user.id !== item.id)],
          isLoading: false,
          errors: {
            ...state.whiteList.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'FIND_USERS_PENDING':
      return {
        ...state,
        findUsers: {
          ...state.findUsers,
          isLoading: true,
          data: [],
          total: 0,
          showing: 0,
          errors: {
            ...state.findUsers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'FIND_USERS_ERROR':
      return {
        ...state,
        findUsers: {
          ...state.findUsers,
          isLoading: false,
          data: [],
          total: 0,
          showing: 0,
          errors: {
            ...state.findUsers.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'FIND_USERS_ABORTED':
      return {
        ...state,
        findUsers: {
          ...state.findUsers,
          isLoading: false,
          data: [],
          total: 0,
          showing: 0,
          errors: {
            // We are placing here the findUsers error because it's the only one this response
            ...state.findUsers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'FIND_USERS_SUCCESS':
      return {
        ...state,
        findUsers: {
          ...state.findUsers,
          data: [
            ...action.payload.response.users,
          ],
          total: action.payload.response.total,
          showing: action.payload.response.showing,
          isLoading: false,
          errors: {
            ...state.findUsers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_ACTIVITY_PENDING':
      return {
        ...state,
        gameActivity: {
          ...state.gameActivity,
          isLoading: true,
          data: [],
          total: 0,
          errors: {
            ...state.gameActivity.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_ACTIVITY_ERROR':
      return {
        ...state,
        gameActivity: {
          ...state.gameActivity,
          isLoading: false,
          data: [],
          total: 0,
          errors: {
            ...state.gameActivity.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_GAME_ACTIVITY_ABORTED':
      return {
        ...state,
        gameActivity: {
          ...state.gameActivity,
          isLoading: false,
          data: [],
          total: 0,
          errors: {
            // We are placing here the gameActivity error because it's the only one this response
            ...state.gameActivity.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_ACTIVITY_SUCCESS':
      return {
        ...state,
        gameActivity: {
          ...state.gameActivity,
          data: [
            ...action.payload.response.data,
          ],
          total: action.payload.response.total,
          isLoading: false,
          errors: {
            ...state.gameActivity.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'DISTRIBUTE_GAME_KEYS_PENDING':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: true,
          data: [
            ...state.gameKeys.data,
          ],
          usersWaitingForKeys: [
            ...state.gameKeys.usersWaitingForKeys,
          ],
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'DISTRIBUTE_GAME_KEYS_ERROR':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          data: [
            ...state.gameKeys.data,
          ],
          usersWaitingForKeys: [
            ...state.gameKeys.usersWaitingForKeys,
          ],
          errors: {
            ...state.gameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'DISTRIBUTE_GAME_KEYS_ABORTED':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          data: [
            ...state.gameKeys.data,
          ],
          usersWaitingForKeys: [
            ...state.gameKeys.usersWaitingForKeys,
          ],
          errors: {
            // We are placing here the gameKeys error because it's the only one this response
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'DISTRIBUTE_GAME_KEYS_SUCCESS':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          data: [
            ...state.gameKeys.data.map((gameKey) => {
              const updatedKey = find(action.payload.response.distributedKeys, distributedKey => (distributedKey.id === gameKey.id));
              return updatedKey || gameKey;
            }),
          ],
          usersWaitingForKeys: [
            ...state.gameKeys.usersWaitingForKeys.filter((gameKey) => {
              const updatedKey = find(action.payload.response.distributedKeys, distributedKey => (distributedKey.owner.id === gameKey.user.id));
              return !updatedKey;
            }),
          ],
          isLoading: false,
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'ASSIGN_NEW_KEY_PLATFORMS_PENDING':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: true,
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'ASSIGN_NEW_KEY_PLATFORMS_ERROR':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          errors: {
            ...state.gameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'ASSIGN_NEW_KEY_PLATFORMS_ABORTED':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          isLoading: false,
          errors: {
            // We are placing here the gameKeys error because it's the only one this response
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'ASSIGN_NEW_KEY_PLATFORMS_SUCCESS':
      return {
        ...state,
        gameKeys: {
          ...state.gameKeys,
          data: [
            ...state.gameKeys.data.map((gameKey) => {
              const updatedKey = find(action.payload.response.gameKeys, gk => (gk.id === gameKey.id));
              return updatedKey || gameKey;
            }),
          ],
          isLoading: false,
          errors: {
            ...state.gameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ADMIN_QUEST_LIST_BY_GAME_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_ADMIN_QUEST_LIST_BY_GAME_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_ADMIN_QUEST_LIST_BY_GAME_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_ADMIN_QUEST_LIST_BY_GAME_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_CAMPAIGN_LIST_BY_GAME_PENDING':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: true,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_CAMPAIGN_LIST_BY_GAME_ERROR':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_CAMPAIGN_LIST_BY_GAME_ABORTED':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_CAMPAIGN_LIST_BY_GAME_SUCCESS':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_CAMPAIGN_PENDING':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: true,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        brandUsers: {
          ...state.brandUsers,
          isLoading: true,
          errors: {
            ...state.brandUsers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          isLoading: true,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_CAMPAIGN_ERROR':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        brandUsers: {
          ...state.brandUsers,
          isLoading: false,
          errors: {
            ...state.brandUsers.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          isLoading: false,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_CAMPAIGN_ABORTED':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        brandUsers: {
          ...state.brandUsers,
          isLoading: false,
          errors: {
            ...state.brandUsers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          isLoading: false,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_CAMPAIGN_SUCCESS':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: action.payload.response.campaign,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          data: action.payload.response.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        brandUsers: {
          ...state.brandUsers,
          data: action.payload.response.brandUsers,
          isLoading: false,
          errors: {
            ...state.brandUsers.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          data: action.payload.response.adminActivityLogs,
          isLoading: false,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_CC_CAMPAIGN_PENDING':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: true,
          isCreating: true,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_CC_CAMPAIGN_ERROR':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          isCreating: false,
          errors: {
            ...state.campaign.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'CREATE_CC_CAMPAIGN_ABORTED':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          isCreating: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_CC_CAMPAIGN_SUCCESS':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: action.payload.response.data,
          isLoading: false,
          isCreating: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_CAMPAIGN_PENDING':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isUpdating: true,
          isLoading: true,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_CAMPAIGN_ERROR':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'UPDATE_CC_CAMPAIGN_ABORTED':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_CAMPAIGN_SUCCESS':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          data: action.payload.response.data,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.campaign.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_CC_GAMES_PLATFORM_PENDING':
      return {
        ...state,
        games: {
          ...state.games,
          isUpdating: true,
          isLoading: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_GAMES_PLATFORM_ERROR':
      return {
        ...state,
        games: {
          ...state.games,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'UPDATE_CC_GAMES_PLATFORM_ABORTED':
      return {
        ...state,
        games: {
          ...state.games,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_GAMES_PLATFORM_SUCCESS':
      return {
        ...state,
        games: {
          ...state.games,
          data: action.payload.response.games,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_CC_QUESTS_PLATFORM_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isUpdating: true,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_QUESTS_PLATFORM_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'UPDATE_CC_QUESTS_PLATFORM_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_QUESTS_PLATFORM_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          data: action.payload.response.quests,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CC_QUEST_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: true,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          isLoading: true,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        campaigns: {
          ...state.campaigns,
          isLoading: true,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          isLoading: false,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          isLoading: false,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        adminActivityLogs: {
          ...state.adminActivityLogs,
          data: action.payload.response.adminActivityLogs,
          isLoading: false,
          errors: {
            ...state.adminActivityLogs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        campaigns: {
          ...state.campaigns,
          data: action.payload.response.campaigns,
          isLoading: false,
          errors: {
            ...state.campaigns.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CC_QUEST_LIST_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_LIST_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_LIST_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_LIST_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          data: action.payload.response.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_CC_QUEST_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          isCreating: true,
          isLoading: true,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_CC_QUEST_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          isCreating: false,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'CREATE_CC_QUEST_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isCreating: false,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_CC_QUEST_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isCreating: false,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_QUEST_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          isUpdating: true,
          isLoading: true,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_QUEST_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'UPDATE_CC_QUEST_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_CC_QUEST_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_ACTIVITY_PENDING':
      return {
        ...state,
        questActivity: {
          ...state.questActivity,
          isLoading: true,
          errors: {
            ...state.questActivity.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_ACTIVITY_ERROR':
      return {
        ...state,
        questActivity: {
          ...state.questActivity,
          isLoading: false,
          errors: {
            ...state.questActivity.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_ACTIVITY_ABORTED':
      return {
        ...state,
        questActivity: {
          ...state.questActivity,
          isLoading: false,
          errors: {
            ...state.questActivity.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_ACTIVITY_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questActivity: {
          ...state.questActivity,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.questActivity.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_SALES_PENDING':
      return {
        ...state,
        questSales: {
          ...state.questSales,
          isLoading: true,
          errors: {
            ...state.questSales.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_SALES_ERROR':
      return {
        ...state,
        questSales: {
          ...state.questSales,
          isLoading: false,
          errors: {
            ...state.questSales.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_SALES_ABORTED':
      return {
        ...state,
        questSales: {
          ...state.questSales,
          isLoading: false,
          errors: {
            ...state.questSales.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_SALES_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questSales: {
          ...state.questSales,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.questSales.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_TIMED_PENDING':
      return {
        ...state,
        questTimed: {
          ...state.questTimed,
          isLoading: true,
          errors: {
            ...state.questTimed.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_TIMED_ERROR':
      return {
        ...state,
        questTimed: {
          ...state.questTimed,
          isLoading: false,
          errors: {
            ...state.questTimed.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_TIMED_ABORTED':
      return {
        ...state,
        questTimed: {
          ...state.questTimed,
          isLoading: false,
          errors: {
            ...state.questTimed.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_TIMED_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questTimed: {
          ...state.questTimed,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.questTimed.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_GENERAL_PENDING':
      return {
        ...state,
        questGeneral: {
          ...state.questGeneral,
          isLoading: true,
          loadUsers: false,
          errors: {
            ...state.questGeneral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: true,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: true,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: true,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },

      };
    case 'GET_CC_QUEST_GENERAL_ERROR':
      return {
        ...state,
        questGeneral: {
          ...state.questGeneral,
          isLoading: false,
          loadUsers: false,
          errors: {
            ...state.questGeneral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_GENERAL_ABORTED':
      return {
        ...state,
        questGeneral: {
          ...state.questGeneral,
          isLoading: false,
          errors: {
            ...state.questGeneral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_GENERAL_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          pagination: action.payload.response.pagination,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questGeneral: {
          ...state.questGeneral,
          data: action.payload.response.data,
          keysAvailables: action.payload.response.availableKeys,
          successCount: action.payload.response.successCount,
          batchErrorMessage: action.payload.response.batchErrorMessage,
          isLoading: false,
          loadUsers: false,
          errors: {
            ...state.questGeneral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          data: action.payload.response.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          data: action.payload.response.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          data: action.payload.response.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CC_QUEST_GENERAL_CSV_PENDING':
      return {
        ...state,
        questGeneralCsv: {
          ...state.questGeneralCsv,
          isLoading: true,
          errors: {
            ...state.questGeneralCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: true,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: true,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: true,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },

      };
    case 'GET_CC_QUEST_GENERAL_CSV_ERROR':
      return {
        ...state,
        questGeneralCsv: {
          ...state.questGeneralCsv,
          isLoading: false,
          errors: {
            ...state.questGeneralCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_GENERAL_CSV_ABORTED':
      return {
        ...state,
        questGeneralCsv: {
          ...state.questGeneralCsv,
          isLoading: false,
          errors: {
            ...state.questGeneralCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_GENERAL_CSV_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          pagination: action.payload.response.pagination,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questGeneralCsv: {
          ...state.questGeneralCsv,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.questGeneralCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          data: action.payload.response.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          data: action.payload.response.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          data: action.payload.response.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CC_QUEST_AFFILIATE_PENDING':
      return {
        ...state,
        questAffiliate: {
          ...state.questAffiliate,
          isLoading: true,
          errors: {
            ...state.questAffiliate.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_AFFILIATE_ERROR':
      return {
        ...state,
        questAffiliate: {
          ...state.questAffiliate,
          isLoading: false,
          errors: {
            ...state.questAffiliate.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_AFFILIATE_ABORTED':
      return {
        ...state,
        questAffiliate: {
          ...state.questAffiliate,
          isLoading: false,
          errors: {
            ...state.questAffiliate.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_AFFILIATE_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questAffiliate: {
          ...state.questAffiliate,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.questAffiliate.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_PREAPPROVAL_PENDING':
      return {
        ...state,
        questGeneral: {
          ...state.questGeneral,
          isLoading: true,
          loadUsers: false,
          errors: {
            ...state.questGeneral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_PREAPPROVAL_ERROR':
      return {
        ...state,
        questGeneral: {
          ...state.questGeneral,
          isLoading: false,
          loadUsers: false,
          errors: {
            ...state.questGeneral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'POST_CC_PREAPPROVAL_ABORTED':
      return {
        ...state,
        questGeneral: {
          ...state.questGeneral,
          isLoading: false,
          loadUsers: false,
          errors: {
            ...state.questGeneral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_PREAPPROVAL_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          pagination: action.payload.response.pagination,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questGeneral: {
          ...state.questGeneral,
          data: action.payload.response.data,
          keysAvailables: action.payload.response.availableKeys,
          successCount: action.payload.response.successCount,
          batchErrorMessage: action.payload.response.batchErrorMessage,
          isLoading: false,
          loadUsers: true,
          errors: {
            ...state.questGeneral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'POST_CC_AFFILIATE_RESOLUTION_PENDING':
      return {
        ...state,
        questAffiliate: {
          ...state.questAffiliate,
          isLoading: true,
          errors: {
            ...state.questAffiliate.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_AFFILIATE_RESOLUTION_ERROR':
      return {
        ...state,
        questAffiliate: {
          ...state.questAffiliate,
          isLoading: false,
          errors: {
            ...state.questAffiliate.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'POST_CC_AFFILIATE_RESOLUTION_ABORTED':
      return {
        ...state,
        questAffiliate: {
          ...state.questAffiliate,
          isLoading: false,
          errors: {
            ...state.questAffiliate.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_AFFILIATE_RESOLUTION_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questAffiliate: {
          ...state.questAffiliate,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.questAffiliate.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_REQUIREMENT_PENDING':
    case 'POST_CC_REQUIREMENT_MULTIPLE_PENDING':
      return {
        ...state,
        userQuests: {
          ...state.userQuests,
          isLoading: true,
          errors: {
            ...state.userQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_REQUIREMENT_ERROR':
    case 'POST_CC_REQUIREMENT_MULTIPLE_ERROR':
      return {
        ...state,
        userQuests: {
          ...state.userQuests,
          isLoading: false,
          errors: {
            ...state.userQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'POST_CC_REQUIREMENT_ABORTED':
    case 'POST_CC_REQUIREMENT_MULTIPLE_ABORTED':
      return {
        ...state,
        userQuests: {
          ...state.userQuests,
          isLoading: false,
          errors: {
            ...state.userQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_CC_REQUIREMENT_SUCCESS':
    case 'POST_CC_REQUIREMENT_MULTIPLE_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuests: {
          ...state.userQuests,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.userQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        reputationSystem: {
          ...state.reputationSystem,
          data: action.payload.response.reputationSystem,
          isLoading: false,
          errors: {
            ...state.reputationSystem.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'COPY_CC_QUEST_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'COPY_CC_QUEST_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'COPY_CC_QUEST_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'COPY_CC_QUEST_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          data: (action.payload.response.quest) ? [action.payload.response.quest, ...state.quests.data] : [...state.quests.data],
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_TIERED_PENDING':
      return {
        ...state,
        questTiered: {
          ...state.questTiered,
          isLoading: true,
          errors: {
            ...state.questTiered.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuests: {
          ...state.userQuests,
          isLoading: true,
          errors: {
            ...state.userQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: true,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: true,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: true,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_TIERED_ERROR':
      return {
        ...state,
        questTiered: {
          ...state.questTiered,
          isLoading: false,
          errors: {
            ...state.questTiered.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        userQuests: {
          ...state.userQuests,
          isLoading: false,
          errors: {
            ...state.userQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },

      };
    case 'GET_CC_QUEST_TIERED_ABORTED':
      return {
        ...state,
        questTiered: {
          ...state.questTiered,
          isLoading: false,
          errors: {
            ...state.questTiered.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuests: {
          ...state.userQuests,
          isLoading: false,
          errors: {
            ...state.userQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },

      };
    case 'GET_CC_QUEST_TIERED_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questTiered: {
          ...state.questTiered,
          data: action.payload.response.data,
          dataReferral: action.payload.response.dataReferral,
          dataKeysAvailables: action.payload.response.keysAvailables,
          reputationRatings: action.payload.response.reputationRatings,
          questSummary: action.payload.response.questSummary,
          isLoading: false,
          errors: {
            ...state.questTiered.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuests: {
          ...state.userQuests,
          data: action.payload.response.userQuests,
          isLoading: false,
          errors: {
            ...state.userQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeQuests: {
          ...state.activeQuests,
          data: action.payload.response.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeGames: {
          ...state.activeGames,
          data: action.payload.response.activeGames,
          isLoading: false,
          errors: {
            ...state.activeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        activeUserQuests: {
          ...state.activeUserQuests,
          data: action.payload.response.activeUserQuests,
          isLoading: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'ADD_CC_USER_QUEST_PENDING':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: true,
          isLoadingSpecial: true,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'ADD_CC_USER_QUEST_ERROR':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'ADD_CC_USER_QUEST_ABORTED':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'ADD_CC_USER_QUEST_SUCCESS':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          data: action.payload.response.data,
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: action.payload.response.message,
          },
        },
      };

    case 'REMOVE_CC_USER_QUEST_PENDING':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: true,
          isLoadingSpecial: true,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REMOVE_CC_USER_QUEST_ERROR':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'REMOVE_CC_USER_QUEST_ABORTED':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REMOVE_CC_USER_QUEST_SUCCESS':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          data: [
            ...remove(
              [...state.activeUserQuests.data],
              x =>
                (
                  action.payload.response.data.id !== x.id
                ),
            ),
          ],
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: action.payload.response.message,
          },
        },
      };
    case 'MOVE_CC_USER_QUEST_PENDING':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: true,
          isLoadingSpecial: true,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'MOVE_CC_USER_QUEST_ERROR':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'MOVE_CC_USER_QUEST_ABORTED':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'MOVE_CC_USER_QUEST_SUCCESS':
      return {
        ...state,
        activeUserQuests: {
          ...state.activeUserQuests,
          data: [
            ...remove(
              [...state.activeUserQuests.data],
              x =>
                (
                  action.payload.response.oldUserQuest.id !== x.id
                ),
            ),
            action.payload.response.newUserQuest,
          ],
          isLoading: false,
          isLoadingSpecial: false,
          errors: {
            ...state.activeUserQuests.errors,
            hasError: false,
            code: '',
            message: action.payload.response.message,
          },
        },
      };
    case 'GET_CC_QUEST_TIERED_MULTIPLE_DAYS_PENDING':
      return {
        ...state,
        questTieredMultipleDays: {
          ...state.questTieredMultipleDays,
          isLoading: true,
          errors: {
            ...state.questTieredMultipleDays.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_TIERED_MULTIPLE_DAYS_ERROR':
      return {
        ...state,
        questTieredMultipleDays: {
          ...state.questTieredMultipleDays,
          isLoading: false,
          errors: {
            ...state.questTieredMultipleDays.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_QUEST_TIERED_MULTIPLE_DAYS_ABORTED':
      return {
        ...state,
        questTieredMultipleDays: {
          ...state.questTieredMultipleDays,
          isLoading: false,
          errors: {
            ...state.questTieredMultipleDays.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_QUEST_TIERED_MULTIPLE_DAYS_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        questTieredMultipleDays: {
          ...state.questTieredMultipleDays,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.questTieredMultipleDays.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_UPLOAD_CC_IMAGE_URL_PENDING':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: true,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_UPLOAD_CC_IMAGE_URL_ERROR':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'REQUEST_UPLOAD_CC_IMAGE_URL_ABORTED':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_UPLOAD_CC_IMAGE_URL_SUCCESS':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_ACTIVE_QUESTS_BY_GAME_PENDING':
      return {
        ...state,
        activeQuests: {
          ...state.activeQuests,
          isLoading: true,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_ACTIVE_QUESTS_BY_GAME_ERROR':
      return {
        ...state,
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_CC_ACTIVE_QUESTS_BY_GAME_ABORTED':
      return {
        ...state,
        activeQuests: {
          ...state.activeQuests,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CC_ACTIVE_QUESTS_BY_GAME_SUCCESS':
      return {
        ...state,
        activeQuests: {
          ...state.activeQuests,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.activeQuests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_LINKS_DASHBOARD_PENDING':
      return {
        ...state,
        linksDashboard: {
          ...state.linksDashboard,
          isLoading: true,
          errors: {
            ...state.linksDashboard.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_LINKS_DASHBOARD_ERROR':
      return {
        ...state,
        linksDashboard: {
          ...state.linksDashboard,
          isLoading: false,
          errors: {
            ...state.linksDashboard.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_LINKS_DASHBOARD_ABORTED':
      return {
        ...state,
        linksDashboard: {
          ...state.linksDashboard,
          isLoading: false,
          errors: {
            ...state.linksDashboard.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_LINKS_DASHBOARD_SUCCESS':
      return {
        ...state,
        linksDashboard: {
          ...state.linksDashboard,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.linksDashboard.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPLOAD_LINKS_PENDING':
      return {
        ...state,
        uploadLinks: {
          ...state.uploadLinks,
          isLoading: true,
          data: {
            createdTotal: 0,
            repeatedTotal: 0,
            createdLinks: [],
            repeatedLinks: [],
          },
          errors: {
            ...state.uploadLinks.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPLOAD_LINKS_ERROR':
      return {
        ...state,
        uploadLinks: {
          ...state.uploadLinks,
          isLoading: false,
          data: {
            createdTotal: 0,
            repeatedTotal: 0,
            createdLinks: [],
            repeatedLinks: [],
          },
          errors: {
            ...state.uploadLinks.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'UPLOAD_LINKS_ABORTED':
      return {
        ...state,
        uploadLinks: {
          ...state.uploadLinks,
          isLoading: false,
          data: {
            createdTotal: 0,
            repeatedTotal: 0,
            createdLinks: [],
            repeatedLinks: [],
          },
          errors: {
            // We are placing here the uploadLinks error because it's the only one this response
            ...state.uploadLinks.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPLOAD_LINKS_SUCCESS':
      return {
        ...state,
        uploadLinks: {
          ...state.uploadLinks,
          data: {
            ...action.payload.response.data,
          },
          isLoading: false,
          errors: {
            ...state.uploadLinks.errors,
            hasError: false,
            code: '',
            messsage: '',
          },
        },
      };
    case 'ASSIGN_MASTER_LINKS_PENDING':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          isLoading: true,
          data: {
          },
          errors: {
            ...state.linkAssignment.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'ASSIGN_MASTER_LINKS_ERROR':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          isLoading: false,
          data: {
          },
          errors: {
            ...state.linkAssignment.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'ASSIGN_MASTER_LINKS_ABORTED':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          isLoading: false,
          data: {
          },
          errors: {
            // We are placing here the linkAssignment error because it's the only one this response
            ...state.linkAssignment.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'ASSIGN_MASTER_LINKS_SUCCESS':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          data: {
            ...action.payload.response.data,
          },
          isLoading: false,
          errors: {
            ...state.linkAssignment.errors,
            hasError: false,
            code: '',
            messsage: '',
          },
        },
      };
    case 'ASSIGN_CUSTOM_LINKS_PENDING':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          isLoading: true,
          data: {
          },
          errors: {
            ...state.linkAssignment.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'ASSIGN_CUSTOM_LINKS_ERROR':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          isLoading: false,
          data: {
          },
          errors: {
            ...state.linkAssignment.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'ASSIGN_CUSTOM_LINKS_ABORTED':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          isLoading: false,
          data: {
          },
          errors: {
            // We are placing here the linkAssignment error because it's the only one this response
            ...state.linkAssignment.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'ASSIGN_CUSTOM_LINKS_SUCCESS':
      return {
        ...state,
        linkAssignment: {
          ...state.linkAssignment,
          data: {
            ...action.payload.response.data,
          },
          isLoading: false,
          errors: {
            ...state.linkAssignment.errors,
            hasError: false,
            code: '',
            messsage: '',
          },
        },
      };
    case 'DELETE_LINKS_PENDING':
      return {
        ...state,
        deletedLinks: {
          ...state.deletedLinks,
          isLoading: true,
          data: {
          },
          errors: {
            ...state.deletedLinks.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'DELETE_LINKS_ERROR':
      return {
        ...state,
        deletedLinks: {
          ...state.deletedLinks,
          isLoading: false,
          data: {
          },
          errors: {
            ...state.deletedLinks.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'DELETE_LINKS_ABORTED':
      return {
        ...state,
        deletedLinks: {
          ...state.deletedLinks,
          isLoading: false,
          data: {
          },
          errors: {
            // We are placing here the deletedLinks error because it's the only one this response
            ...state.deletedLinks.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'DELETE_LINKS_SUCCESS':
      return {
        ...state,
        deletedLinks: {
          ...state.deletedLinks,
          data: {
            ...action.payload.response.data,
          },
          isLoading: false,
          errors: {
            ...state.deletedLinks.errors,
            hasError: false,
            code: '',
            messsage: '',
          },
        },
      };
    case 'POST_REPUTATION_RATING_PENDING':
      return {
        ...state,
        reputationSystem: {
          ...state.reputationSystem,
          isLoading: true,
          data: {
          },
          errors: {
            ...state.reputationSystem.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_REPUTATION_RATING_ERROR':
      return {
        ...state,
        reputationSystem: {
          ...state.reputationSystem,
          isLoading: false,
          data: {
          },
          errors: {
            ...state.reputationSystem.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'POST_REPUTATION_RATING_ABORTED':
      return {
        ...state,
        reputationSystem: {
          ...state.reputationSystem,
          isLoading: false,
          data: {
          },
          errors: {
            ...state.reputationSystem.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_REPUTATION_RATING_SUCCESS':
      return {
        ...state,
        reputationSystem: {
          ...state.reputationSystem,
          data: {
            ...action.payload.response.data,
          },
          isLoading: false,
          errors: {
            ...state.reputationSystem.errors,
            hasError: false,
            code: '',
            messsage: '',
          },
        },

        questTiered: {
          ...state.questTiered,
          reputationRatings: action.payload.response.reputationRatings,
          isLoading: false,
          errors: {
            ...state.questTiered.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    default:
      return state;
  }
};

export default commandCenter;
