import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';

import { stableSort, getSorting, flattenObject } from '../../../../utils/functions';

const useStylesSearch = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export const SearchBar = (props) => {
  const classes = useStylesSearch();
  const handleChange = (e) => {
    props.passUp(e.target.value);
  };

  return (
    <div>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="input-with-icon-adornment">Table Search</InputLabel>
        <Input
          onChange={handleChange}
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <FilterListIcon />
            </InputAdornment>
          }
        />
      </FormControl>

    </div>
  );
};
// SCHEMA
// root level schema for tabbed data swapping, best heldin state.
// [{ name: 'Available', data: 'available' }, { name: 'Distributed', data: 'distributed' }, { name: 'Users In Campaign', data: 'users' }, { name: 'Waitlist', data: 'waitlist' }],

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

const nestedValuePainter = (o, headers) => {
  headers.forEach((h) => {
    const { id } = h;
    if (!o.hasOwnProperty(id)) {
      o[id] = 'no value';
    }
  });
  return o;
};

const defaultHeaders = [
  {
    id: '', numeric: false, disablePadding: true, label: 'NO HEADERS DEFINED',
  },
];

const defaultData = thisFunctionIsAMap => (<TableRow>
  <TableCell component="th" scope="row">
    No Data Mapping for Data Set, Make One
  </TableCell>
                                           </TableRow>);

// END TABLE DATA SWITCHING


export default function LinkTable(props) {
  const {
    dataKey, tableHeaders, tableData, rowTemplates, headerTemplates, dataOverride, headerOverride,
  } = props;

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [filter, setFilterBy] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleRequestSort = (event, property) => {
    const desc = order === 'desc' && orderBy === property;
    setOrder(desc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const createSortHandler = property => (event) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tRows = rowTemplates || {};
  const tHeaders = headerTemplates || {};
  const mapFunctions = { default: defaultData, ...tRows };
  const headerMaker = { default: defaultHeaders, ...tHeaders };
  const classes = useStyles();
  const key = tableHeaders[dataKey].data;
  let headers;
  if (headerOverride) {
    headers = headerOverride;
  } else {
    headers = (headerMaker[key] ? headerMaker[key] : headerMaker.default);
  }
  const dataFunc = mapFunctions[key] ? mapFunctions[key] : mapFunctions.default;

  let data;
  if (dataOverride) {
    data = dataOverride;
  } else {
    data = tableData[key] ? tableData[key] : [];
  }
  const flatData = data.map(d => nestedValuePainter(flattenObject(d), headers));
  const searchData = filter ? flatData.filter((o) => {
    const searchable = JSON.stringify(o);
    if (searchable.search(filter) !== -1) {
      return true;
    }
    return false;
  }) : flatData;
  const sortedData = stableSort(searchData, getSorting(order, orderBy));
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedData.length - page * rowsPerPage);
  const paginated = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <Paper className={classes.paper}>

      <TableContainer component={Paper}>
        <SearchBar passUp={setFilterBy} />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map(headCell => (
                <TableHeaderCell
                  key={headCell.id}
                  align="left"
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection="asc"
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={ArrowDropDownIcon}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span style={{ display: 'none' }}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                        ) : null}
                  </TableSortLabel>
                </TableHeaderCell>))}

            </TableRow>
          </TableHead>
          <TableBody>
            {dataFunc(paginated, { passUp: props.passUp, selectAll: props.selectAll })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 50]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
