import React from 'react';
import $ from 'jquery';
import qs from 'qs';
import './DataForm.scss';
import SignupFracturedLands from '../forms/SignupFracturedLands';
import logo2 from './logo-fractured.png';

class DataForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  onSubmit = (allFields) => {
    this.submitGForm(allFields);
  }

  submitGForm = (allFields) => {
    const baseURL = 'https://docs.google.com/forms/d/e/1FAIpQLSdojavIF9Sl_HM7h5P9_H7FCSaLSVUTdNwVymrmQiJ6AnBXWw/formResponse?';
    const baseGFormFields = {
      submit: 'Submit',
      'entry.287027332': allFields.firstname ? allFields.firstname : '',
      'entry.484988332': allFields.lastname ? allFields.lastname : '',
      'entry.249887340': allFields.email ? allFields.email : '',
      'entry.1320847757': allFields.country ? allFields.country : '',
      'entry.1821618640': allFields.twitch ? allFields.twitch : '',
      'entry.1806644319': allFields.youtube ? allFields.youtube : '',
      'entry.1993350920': allFields.primary_account ? allFields.primary_account : '',
      'entry.1197954615': allFields.twitter ? allFields.twitter : '',
      'entry.1132439028': allFields.discord ? allFields.discord : '',
      'entry.1918181507': allFields.upload ? allFields.upload : '',
      'entry.1572375059': allFields.comment ? allFields.comment : '',
    };

    const parameters = qs.stringify(baseGFormFields);

    $.ajax({
      type: 'GET',
      url: `${baseURL}${parameters}`,
      contentType: 'application/x-www-form-urlencoded',
      success: () => {
        this.props.router.push('/thank-you');
      },
      error: () => {
        this.props.router.push('/thank-you');
      },
    });
  }

  render() {
    return (
      <div id="DataFormContainer">
        <div className="content-container">
          <img className="tt-image" src={logo2} alt="logo" />
          <SignupFracturedLands
            isLoading={false}
            isUpdating={false}
            onSubmit={this.onSubmit.bind(this)}
          />
        </div>
        <iframe src="#" title="x-title" id="no-target" name="no-target" style={{ visibility: 'hidden' }} />
      </div>
    );
  }
}

export default DataForm;
