// Components (external)
import React from 'react';
import classNames from 'classnames';

// Styles
import './StatusBar.scss';

export default function StatusBar(props) {
  const {
    status,
    // date,
  } = props;
  // const [open, setOpen] = React.useState(false);

  let statusMessage = '';
  let statusLinkMessage = '';
  let statusLink = '';

  // Update language and styling depending on status of bar
  switch (status) {
    case 'pending':
      statusMessage = 'You applied for this quest on March 22, 2021. Your application is currently under review.';
      break;
    case 'no-twitch':
      statusMessage = 'You need to connect your Twitch account to apply to this quest.';
      statusLinkMessage = 'Connect Twitch Account →';
      statusLink = '../connect-accounts';
      break;
    case 'accv-min':
      statusMessage = 'You do not have enough viewers to participate in this quest.';
      statusLinkMessage = 'Browse other quests →';
      statusLink = '../quests';
      break;
    default:
      break;
  }

  const classes = classNames({
    StatusBar: true,
    pending: status === 'pending',
    'no-twitch': status === 'no-twitch',
    'accv-min': status === 'accv-min',
    hidden: !status,
  });

  return (
    <div className={classes}>
      <span>
        {statusMessage}
        <a href={statusLink}> {statusLinkMessage} </a>
      </span>
    </div>
  );
}
