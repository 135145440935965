/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React from 'react';
// import { Link } from 'react-router';
// import Dropzone from 'react-dropzone';

import { find } from 'lodash';
import './WhiteList.scss';

class WhiteList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.dropzoneRef = null;
    this.state = {
      // dropzoneActive: false,
      // disableClick: false,
      // file: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    this.props.getWhiteList(gameId, token);
  }

  // onDragEnter() {
  //   this.setState({ dropzoneActive: true });
  // }

  // onDragLeave() {
  //   this.setState({ dropzoneActive: false });
  // }

  // onDrop(files) {
  //   // Step 1. Files were selected or droped
  //   this.setState({ dropzoneActive: false, file: files[0] });
  // }

  uploadKeys() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;

    const formData = new FormData();
    formData.append('file', this.state.file);
    // Upload the file
    this.props.uploadKeys(formData, gameId, token);
  }

  toggleUserInWhiteList(userId) {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    this.props.toggleUserInWhiteList(gameId, userId, token);
  }

  findUsers(event) {
    const { token } = this.props.auth;
    if (event.target.value.length > 0) {
      this.props.findUsers(event.target.value, token);
    }
  }

  render() {
    // const { dropzoneActive } = this.state;

    const { data: whiteList } = this.props.commandCenter.whiteList;
    const { data: findUsers } = this.props.commandCenter.findUsers;


    const whiteListRows = whiteList.length > 0 ?
      whiteList.map((item) => {
        const username = item.username ? item.username : '- - -';
        const email = item.email ? item.email : '- - -';
        return (
          <div className="row" key={item.id}>
            <div className="table-cell">{ username }</div>
            <div className="table-cell">{ email }</div>
            <div className="table-cell">
              { !item.loading ? <button className="remove-key" onClick={this.toggleUserInWhiteList.bind(this, item.id)}>REMOVE</button> : <span className="removing-key">Removing...</span> }
            </div>
          </div>
        );
      }) : (<div className=""><div className="no-results">No User White Listed.</div></div>);

    const findUsersRows = findUsers.length > 0 ?
      findUsers.filter(item => !find(whiteList, w => w.id === item._id)).map((item) => {
        const username = item.username ? item.username : '- - -';
        const email = item.email ? item.email : '- - -';
        return (
          <div className="row" key={item._id}>
            <div className="table-cell">{ username }</div>
            <div className="table-cell">{ email }</div>
            <div className="table-cell">
              { !item.loading ? <button className="remove-key" onClick={this.toggleUserInWhiteList.bind(this, item._id)}>WHITE LIST</button> : <span className="removing-key">White listing...</span> }
            </div>
          </div>
        );
      }) : (<div className=""><div className="no-results">No User found, try typing something in the search field.</div></div>);

    return (
      <section className="WhiteList">
        <section className="WhiteList__crud">
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">WHITE LISTED USERS: <span>{ whiteList.length }</span> </h3>
              <i className="fa fa-caret-down" />
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">Username</div>
                  <div className="table-cell headline">Email</div>
                  <div className="table-cell headline">Actions</div>
                </div>
                { whiteListRows }
              </section>
            </section>
          </section>

          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">
                SEARCH FOR NOIZ USERS TO WHITE LIST THEM: <input type="text" placeholder="Username or Email..." onChange={this.findUsers.bind(this)} />
              </h3>
              <i className="fa fa-caret-down" />
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">Username</div>
                  <div className="table-cell headline">Email</div>
                  <div className="table-cell headline">Actions</div>
                </div>
                { findUsersRows }
              </section>
            </section>
          </section>

          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">
                Create a new user to white list them.
              </h3>
              <i className="fa fa-caret-down" />
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">Username</div>
                  <div className="table-cell headline">Email</div>
                  <div className="table-cell headline">Actions</div>
                </div>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

WhiteList.propTypes = {

};

export default WhiteList;
