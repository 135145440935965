import React, { useState, useEffect } from "react";

import { ActiveFilters, SearchBar } from "../../../../../global/components/filters/SearchBarAndFilters";
import { QuestCard, CampaignCard, BlankCampaign } from "./QuestCampaignCards";
import { filterQuests } from "./QuestCampaignDataFilters";

import CustomDropdown from "../../CustomInputs/CustomDropdown";
import Icon from "../../../../../global/images/icons/Icon";
import NothingHere from '../../assets/NothingHere';
import QuestCampaignToggle from "./QuestCampaignToggle";

import "./QuestsDisplay.scss";
import "./CampaignDisplay.scss";
import LoadingGif from "../../assets/LoadingGif";

const QuestCampaignFilters = (isCampaign = true) => ({
  [`Active ${isCampaign ? "Campaigns" : "Quests"}`]: false,
  "Recently Added": false,
  sorting: null,
});

const QuestCampaignDisplay = props => {
  const {
    // brandUser,
    brandUser: {
      campaigns: {
        data: campaigns,
        loading: campaignsLoading,
      },
      quests: {
        data: { quests = [] },
        isLoading: questsLoading,
      },
    },
    getBrandQuest,
    token,
  } = props;

  const isLoading = questsLoading || campaignsLoading;

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(QuestCampaignFilters());
  const [filterModal, setFilterModal] = useState(false);
  const [isCampaigns, setCampaigns] = useState(localStorage.getItem("portalCampaignList") === "true" || false);
  const [paginationSize, setPaginationSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const filteredQuests = quests.length && !isCampaigns ? filterQuests(quests, search, filter) : [];
  const filteredCampaigns =
    campaigns.length && isCampaigns ? filterQuests(campaigns, search, filter) : [];

  let possibleSorts = [
    "Date Created Ascending",
    "Date Created Descending",
  ];

  if (isCampaigns) {
    possibleSorts = [
      "Budget Ascending",
      "Budget Descending",
      ...possibleSorts,
      "Campaign Name Ascending",
      "Campaign Name Descending",
    ];
  } else {
    possibleSorts = [
      ...possibleSorts,
      "Highest Reward Ascending",
      "Highest Reward Descending",
      "Quest Title Ascending",
      "Quest Title Descending",
    ];
  }

  // useEffect(() => {
  //   getBrandQuest({ statusQuest: "all", statusGame: "all", pageSize: 0 }, token);
  // }, []);

  useEffect(() => {
    if (!isCampaigns) {
      getBrandQuest({ statusQuest: "all", statusGame: "all", pageSize: 0 }, token);
    }
  }, [isCampaigns]);

  const PaginationRender = () => (
    <div className='QuestDisplay_Pagination'>
      <div className='QuestDisplay_Pagination_Page_Size'>
        <div className="mr-10">Page Size:</div>
        <CustomDropdown
          header=''
          options={[10, 25, 50, 100].map(size => ({
            optionName: size,
            optionValue: size,
          }))}
          value={paginationSize}
          onChange={(blank, value) => { setPaginationSize(value); setCurrentPage(1); }}
          enableDefaultOption={false}
        />
      </div>
      <div className='QuestDisplay_Pagination_Buttons'>
        <div
          className={`QuestDisplay_Pagination_Button Left ${currentPage !== 1 && 'Clickable'}`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage <= 1}
        >
          <Icon name='angleleft' />
        </div>
        <div className='QuestDisplay_Pagionation_Current_Page'>
          Page {currentPage} of {Math.ceil((isCampaigns ? filteredCampaigns.length : filteredQuests.length) / paginationSize)}
        </div>
        <div
          className={`QuestDisplay_Pagination_Button Right ${currentPage !== Math.ceil((isCampaigns ? filteredCampaigns.length : filteredQuests.length) / paginationSize) && 'Clickable'}`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= Math.ceil((isCampaigns ? filteredCampaigns.length : filteredQuests.length) / paginationSize)}
        >
          <Icon name='angleright' />
        </div>
      </div>
    </div>
  );

  const renderPagination = !!filteredCampaigns.length;

  return (
    <div className='QuestDisplay CampaignDisplay'>
      <SearchBar
        search={search}
        setSearch={setSearch}
        filterModal={filterModal}
        setFilterModal={setFilterModal}
        filter={filter}
        setFilter={setFilter}
        possibleSorts={possibleSorts}
        customComponent={
          <QuestCampaignToggle
            isCampaigns={isCampaigns}
            toReturn={value => {
              localStorage.setItem("portalCampaignList", value);
              setCampaigns(value);
              setFilter(QuestCampaignFilters(value));
            }}
          />
        }
      />
      <div className='QuestDisplay_Title'>Your {isCampaigns ? "Campaigns" : "Quests"}</div>
      <ActiveFilters
        filter={filter}
        setFilter={setFilter}
        defaultFilters={QuestCampaignFilters}
      />
      {isLoading && <LoadingGif />}
      {!isLoading && (
        <>
          {renderPagination && <PaginationRender />}
          <div className='QuestDisplay_List'>
            {!renderPagination && !campaigns && <NothingHere />}
            {isCampaigns && filteredCampaigns.slice((currentPage - 1) * paginationSize, currentPage * paginationSize).map(c => <CampaignCard key={c.id} campaign={c} />)}
            {!isCampaigns && filteredQuests.slice((currentPage - 1) * paginationSize, currentPage * paginationSize).map(q => <QuestCard key={q.id} quest={q} />)}
            {isCampaigns && <BlankCampaign />}
          </div>
          {renderPagination && <PaginationRender />}
        </>
      )}
    </div>
  );
};

export default QuestCampaignDisplay;
