import React from "react";

const xmlns = "http://www.w3.org/2000/svg";

const AddCommentBubble = color => (
  <svg xmlns={xmlns} width="20" height="16.577" viewBox="0 0 20 16.577">
    <path d="M40.011 128.4c-5.522 0-10.011 3.2-10.011 7.142 0 2.255 1.454 4.257 3.73 5.564a7.313 7.313 0 0 1-1.538 2.508.816.816 0 0 0 .885 1.307c1.644-.611 3.541-1.475 5.29-2.318a13.678 13.678 0 0 0 1.623.105c5.522 0 10.011-3.2 10.011-7.144.021-3.962-4.468-7.165-9.989-7.165zm3.751 7.966a.243.243 0 0 1-.253.253h-2.424v2.424a.243.243 0 0 1-.253.253h-1.643a.243.243 0 0 1-.253-.253v-2.424h-2.424a.243.243 0 0 1-.253-.253v-1.646a.243.243 0 0 1 .253-.253h2.424v-2.424a.243.243 0 0 1 .253-.253h1.644a.243.243 0 0 1 .253.253v2.424h2.424a.243.243 0 0 1 .253.253z" transform="translate(-30 -128.398)" fill={color} />
  </svg>
);

const AddPerson = color => (
  <svg xmlns={xmlns} viewBox='0 0 640 512'>
    <path
      fill={color}
      d='M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'
    />
  </svg>
);

const AngleLeft = color => (
  <svg xmlns={xmlns} viewBox='0 0 256 512'>
    <path d='M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z' fill={color} />
  </svg>
);

const AngleRight = color => (
  <svg xmlns={xmlns} viewBox='0 0 256 512'>
    <path d='M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z' fill={color} />
  </svg>
);

const AnglesLeft = color => (
  <svg xmlns={xmlns} viewBox="0 0 448 512"><path d="M77.25 256l137.4-137.4c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0l-160 160c-12.5 12.5-12.5 32.75 0 45.25l160 160C175.6 444.9 183.8 448 192 448s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25L77.25 256zM269.3 256l137.4-137.4c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0l-160 160c-12.5 12.5-12.5 32.75 0 45.25l160 160C367.6 444.9 375.8 448 384 448s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25L269.3 256z" fill={color} /></svg>
);

const ArrowLeft = color => (
  <svg xmlns={xmlns} viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" fill={color} /></svg>
);

const CaretDown = color => (
  <svg xmlns={xmlns} viewBox='0 0 320 512'>
    <path d='M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z' fill={color} />
  </svg>
);

const CaretUp = color => (
  <svg xmlns={xmlns} viewBox='0 0 320 512'>
    <path d='M9.39 265.4l127.1-128C143.6 131.1 151.8 128 160 128s16.38 3.125 22.63 9.375l127.1 128c9.156 9.156 11.9 22.91 6.943 34.88S300.9 320 287.1 320H32.01c-12.94 0-24.62-7.781-29.58-19.75S.2333 274.5 9.39 265.4z' fill={color} />
  </svg>
);

const Check = color => (
  <svg xmlns={xmlns} viewBox='0 0 512 512'>
    <path
      fill={color}
      d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'
    />
  </svg>
);

const Checklist = color => (
  <svg xmlns={xmlns} viewBox="0 0 18.854 18.854">
    <path d="M46.274 30H32.58A2.575 2.575 0 0 0 30 32.58v13.694a2.575 2.575 0 0 0 2.58 2.58h13.694a2.575 2.575 0 0 0 2.58-2.58V32.58a2.575 2.575 0 0 0-2.58-2.58zm-10.1 5.537a.755.755 0 0 1 1.072 0l1.29 1.29 3.076-3.1a.758.758 0 0 1 1.07 1.073l-3.612 3.635a.767.767 0 0 1-1.072 0l-1.826-1.826a.755.755 0 0 1 0-1.072zm6.569 9.844h-6.63a.754.754 0 0 1 0-1.508h6.629a.754.754 0 1 1 0 1.508zm1.25-3.215h-9.131a.754.754 0 0 1 0-1.508h9.129a.754.754 0 1 1 0 1.508z" transform="translate(-30 -30)" fill={color} />
  </svg>
);

const Clock = color => (
  <svg xmlns={xmlns} viewBox='0 0 512 512'>
    <path
      d='M256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512zM232 256C232 264 236 271.5 242.7 275.1L338.7 339.1C349.7 347.3 364.6 344.3 371.1 333.3C379.3 322.3 376.3 307.4 365.3 300L280 243.2V120C280 106.7 269.3 96 255.1 96C242.7 96 231.1 106.7 231.1 120L232 256z'
      fill={color}
    />
  </svg>
);

const Edit = color => (
  <svg xmlns={xmlns} viewBox='0 0 576 512'>
    <path
      fill={color}
      d='M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z'>
    </path>
  </svg>
);

const Empty = color => (
  <svg xmlns={xmlns} viewBox='0 0 57.208 67.765'>
    <path fill={color} d='M48.767 20.19a3.045 3.045 0 0 0 3.067-3.067V5.567a3.067 3.067 0 1 0-6.135 0v11.485a3.106 3.106 0 0 0 3.068 3.138z' transform='translate(-20.163 -2.5)' />
    <path fill={color} d='M25.116 22.316a3.076 3.076 0 0 0 2.853 1.926 3.623 3.623 0 0 0 1.213-.214 3.063 3.063 0 0 0 1.641-4.066L26.4 9.334a3.1 3.1 0 0 0-5.707 2.425z' transform='translate(-12.918 -3.913)' />
    <path fill={color} d='M66.522 24.032a3.332 3.332 0 0 0 1.213.214 3.076 3.076 0 0 0 2.853-1.926l4.422-10.629a3.147 3.147 0 0 0-1.64-4.066 3.309 3.309 0 0 0-4.137 1.783L64.81 20.037a3.075 3.075 0 0 0 1.712 3.995z' transform='translate(-25.577 -3.916)' />
    <path
      fill={color}
      d='m66.823 58.5-8.417-17.617a3.072 3.072 0 0 0-2.782-1.783h-34.24a3.072 3.072 0 0 0-2.784 1.783L10.185 58.5a2.9 2.9 0 0 0-.285 1.358v17.833a3.045 3.045 0 0 0 3.067 3.067h51.074a3.045 3.045 0 0 0 3.067-3.067V59.858a3.091 3.091 0 0 0-.285-1.358zM23.382 45.3H53.7l5.279 11.056H18.032z'
      transform='translate(-9.9 -12.993)'
    />
  </svg>
);

const Expand = color => (
  <svg xmlns={xmlns} viewBox='0 0 352.054 352.054' >
    <g>
      <polygon
        points='144.206,186.634 30,300.84 30,238.059 0,238.059 0,352.054 113.995,352.054 113.995,322.054 51.212,322.054 
 165.419,207.847 	'
        fill={color}
      />
      <polygon
        points='238.059,0 238.059,30 300.84,30 186.633,144.208 207.846,165.42 322.054,51.213 322.054,113.995 352.054,113.995 
 352.054,0 	'
        fill={color}
      />
    </g>
  </svg>
);

const Eye = color => (
  <svg xmlns={xmlns} viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM432 256c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM288 192c0 35.3-28.7 64-64 64c-11.5 0-22.3-3-31.6-8.4c-.2 2.8-.4 5.5-.4 8.4c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6z" fill={color} /></svg>
);

const Facebook = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path d='M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z' fill={color} />
  </svg>
);

const Filters = color => (
  <svg xmlns={xmlns} viewBox='0 0 512 512'>
    <path
      d='M0 416C0 398.3 14.33 384 32 384H86.66C99 355.7 127.2 336 160 336C192.8 336 220.1 355.7 233.3 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H233.3C220.1 476.3 192.8 496 160 496C127.2 496 99 476.3 86.66 448H32C14.33 448 0 433.7 0 416V416zM192 416C192 398.3 177.7 384 160 384C142.3 384 128 398.3 128 416C128 433.7 142.3 448 160 448C177.7 448 192 433.7 192 416zM352 176C384.8 176 412.1 195.7 425.3 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H425.3C412.1 316.3 384.8 336 352 336C319.2 336 291 316.3 278.7 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H278.7C291 195.7 319.2 176 352 176zM384 256C384 238.3 369.7 224 352 224C334.3 224 320 238.3 320 256C320 273.7 334.3 288 352 288C369.7 288 384 273.7 384 256zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H265.3C252.1 156.3 224.8 176 192 176C159.2 176 131 156.3 118.7 128H32C14.33 128 0 113.7 0 96C0 78.33 14.33 64 32 64H118.7C131 35.75 159.2 16 192 16C224.8 16 252.1 35.75 265.3 64H480zM160 96C160 113.7 174.3 128 192 128C209.7 128 224 113.7 224 96C224 78.33 209.7 64 192 64C174.3 64 160 78.33 160 96z'
      fill={color}
    />
  </svg>
);

const GamePad = color => (
  <svg viewBox='0 0 640 512' xmlns={xmlns}>
    <path
      fill={color}
      d='M480.07 96H160a160 160 0 1 0 114.24 272h91.52A160 160 0 1 0 480.07 96zM248 268a12 12 0 0 1-12 12h-52v52a12 12 0 0 1-12 12h-24a12 12 0 0 1-12-12v-52H84a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h52v-52a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v52h52a12 12 0 0 1 12 12zm216 76a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm64-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40z'
    />
  </svg>
);

const Graph = color => (
  <svg viewBox='0 0 512 512' xmlns={xmlns}>
    <path
      fill={color}
      d='M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z'
    />
  </svg>
);

const Grip = color => (
  <svg xmlns={xmlns} viewBox="0 0 448 512"><path d="M128 184C128 206.1 110.1 224 88 224H40C17.91 224 0 206.1 0 184V136C0 113.9 17.91 96 40 96H88C110.1 96 128 113.9 128 136V184zM128 376C128 398.1 110.1 416 88 416H40C17.91 416 0 398.1 0 376V328C0 305.9 17.91 288 40 288H88C110.1 288 128 305.9 128 328V376zM160 136C160 113.9 177.9 96 200 96H248C270.1 96 288 113.9 288 136V184C288 206.1 270.1 224 248 224H200C177.9 224 160 206.1 160 184V136zM288 376C288 398.1 270.1 416 248 416H200C177.9 416 160 398.1 160 376V328C160 305.9 177.9 288 200 288H248C270.1 288 288 305.9 288 328V376zM320 136C320 113.9 337.9 96 360 96H408C430.1 96 448 113.9 448 136V184C448 206.1 430.1 224 408 224H360C337.9 224 320 206.1 320 184V136zM448 376C448 398.1 430.1 416 408 416H360C337.9 416 320 398.1 320 376V328C320 305.9 337.9 288 360 288H408C430.1 288 448 305.9 448 328V376z" fill={color} /></svg>
);

const HandGameController = color => (
  <svg xmlns={xmlns} width="29.749" height="29.642" viewBox="0 0 29.749 29.642">
    <path data-name="Path 1736" d="M56.564 43.3a.394.394 0 0 0-.073-.009h-3.159v-.877a.91.91 0 0 0-.913-.909H51.4v-.432a.512.512 0 0 1 .509-.514h1.636a1.2 1.2 0 0 0 0-2.39H42.98a6.106 6.106 0 1 0 .055.791.868.868 0 0 0 0-.109h10.506a.514.514 0 0 1 0 1.027h-1.636a1.193 1.193 0 0 0-1.191 1.2v.422h-1.026a.906.906 0 0 0-.909.909v.877h-2.99a4.2 4.2 0 1 0 3.7 6.19h3.122a4.2 4.2 0 1 0 3.953-6.176zm-20 .127a4.487 4.487 0 0 1-4.132-4.127h2.777a1.729 1.729 0 0 0 1.354 1.359zm.682 0v-2.773A1.735 1.735 0 0 0 38.6 39.3h2.772a4.483 4.483 0 0 1-4.126 4.131zm1.354-4.813a1.728 1.728 0 0 0-3.39 0h-2.778a4.482 4.482 0 0 1 8.939 0zm8.962 9.5h-1.146v1.145h-1.25v-1.142h-1.145v-1.25h1.145v-1.145h1.25v1.145h1.145zm4.517-.554h-2.05a.341.341 0 0 1 0-.682h2.05a.341.341 0 1 1 0 .682zm0-1.223h-2.05a.341.341 0 0 1 0-.682h2.05a.341.341 0 1 1 0 .682zm2.495 1.736a.584.584 0 1 1 .586-.586.588.588 0 0 1-.587.589zm1.741 1.745a.586.586 0 1 1 .586-.586.584.584 0 0 1-.587.589zm0-3.486a.586.586 0 1 1 .586-.586.584.584 0 0 1-.587.589zm1.745 1.741a.584.584 0 1 1 .586-.586.585.585 0 0 1-.587.589z" fill={color} transform="translate(-30.769 -32.824)" />
    <path data-name="Path 1737" d="M65.524 713.61h-5.376a.34.34 0 0 0-.341.341v9.589a.34.34 0 0 0 .341.341h5.376a.34.34 0 0 0 .341-.341v-9.589a.34.34 0 0 0-.341-.341zm-1.686 8.621h-1.654v-1.65h1.654z" transform="translate(-59.048 -695.82)" fill={color} />
    <path data-name="Path 1738" d="M339.82 724.316c-.292-1.05-1.114-1.363-2.19-1.363a13.434 13.434 0 0 0-4.735 1.236 4.694 4.694 0 0 1-3.722 2.422 4.453 4.453 0 0 1-2.481-.686 8.515 8.515 0 0 0-2.686-.9.342.342 0 0 1-.291-.386.338.338 0 0 1 .382-.291c2.659.354 3.345 1.672 5.04 1.577a3.723 3.723 0 0 0 2.736-1.486.444.444 0 0 0 0-.555c-.782-.963-2.872-1.409-3.136-1.459-2.136-.373-5.94-2.545-5.944-2.545a3.885 3.885 0 0 0-2.359-.714 8.044 8.044 0 0 0-2.695.545v8.576a54.154 54.154 0 0 1 7.912 2.05c4.184 1.576 7.432-.729 9.716-1.7a14.509 14.509 0 0 0 3.99-2.418 1.967 1.967 0 0 0 .459-1.909z" transform="translate(-310.244 -701.237)" fill={color} />
  </svg>
);

const HandShield = color => (
  <svg className="handshield-icon" xmlns={xmlns} width="26.601" height="22.639" viewBox="0 0 22.639 26.601">
    <path data-name="Path 1761" d="M37.943 682.758h3.174a.47.47 0 0 0 .222-.058l2.15-1.241.035.062a.975.975 0 0 0 1.33.359l1.72-.993a.972.972 0 0 0 .355-1.33l-.035-.062.971-.563 5.1.164a2.2 2.2 0 0 0 1.565-.647l4.686-4.673a1.56 1.56 0 0 0-.111-2.3 1.6 1.6 0 0 0-2.124.146l-2.3 2.3a1.483 1.483 0 0 0-1.206-.59H51.16l-1.277-.767c-.59-.35-2.638-1.432-4.3-.505l-2.23 1.29-.04-.066a.975.975 0 0 0-1.326-.355l-1.72.993a.977.977 0 0 0-.359 1.33l.04.062-2.226 1.286a.443.443 0 0 0-.222.386v5.334a.443.443 0 0 0 .443.443zm8.074-9.927c.989-.55 2.407-.106 3.409.5l1.383.829a.443.443 0 0 0 .226.062h2.438a.691.691 0 0 1 .665.665.665.665 0 0 1-.665.665h-2.437a.443.443 0 0 0 0 .887h2.438a1.544 1.544 0 0 0 1.547-1.6l2.585-2.585a.709.709 0 0 1 .935-.093.667.667 0 0 1 .049.989l-4.69 4.673a1.325 1.325 0 0 1-.927.386l-5.209-.168a.422.422 0 0 0-.239.062l-1.077.621-2.66-4.606z" transform="translate(-37.5 -656.157)" fill={color} />
    <path data-name="Path 1762" d="M217.706 356.845a.443.443 0 0 0 .767-.443 11.454 11.454 0 0 1-1.273-3.295.443.443 0 0 0-.866.192 12.359 12.359 0 0 0 1.372 3.546z" transform="translate(-212.096 -345.306)" fill={color} />
    <path data-name="Path 1763" d="M207.137 281.693a.443.443 0 1 1-.443-.443.443.443 0 0 1 .443.443" transform="translate(-202.26 -275.487)" fill={color} />
    <path data-name="Path 1764" d="M922.185 285.466a13.24 13.24 0 0 0 .883-3.724.443.443 0 1 0-.882-.089 12.365 12.365 0 0 1-.823 3.48.443.443 0 0 0 .822.332z" transform="translate(-900.432 -275.491)" fill={color} />
    <path data-name="Path 1765" d="M891.137 497.8a.443.443 0 1 1-.443-.443.443.443 0 0 1 .443.443" transform="translate(-870.086 -486.487)" fill={color} />
    <path data-name="Path 1766" d="M284.748 52.946a.942.942 0 0 0 .736 0c7.027-2.979 7.475-8.539 7.2-12.463a.874.874 0 0 0-1.086-.8c-2.638.634-4.979-1.144-5.861-1.937a.931.931 0 0 0-1.241 0c-.891.794-3.245 2.576-5.857 1.942a.893.893 0 0 0-1.091.8c-.275 3.924.173 9.483 7.2 12.463zm-2.767-8.206a1.045 1.045 0 0 1 1.478 0l1.015 1.016 2.822-2.674a1.045 1.045 0 1 1 1.438 1.516l-3.561 3.374a1.045 1.045 0 0 1-1.458-.02l-1.735-1.735a1.045 1.045 0 0 1 0-1.477z" transform="translate(-271.815 -37.504)" fill={color} />
  </svg>
);

const House = color => (
  <svg xmlns={xmlns} viewBox='0 0 576 512'>
    <path
      fill={color}
      d='M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z'
    />
  </svg>
);

const InfoSolid = color => (
  <svg xmlns={xmlns} viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" fill={color} /></svg>
);

const Instagram = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path
      d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'
      fill={color}
    />
  </svg>
);

const LightningBolt = color => (
  <svg xmlns={xmlns} width="30.813" height="20.504" viewBox="0 0 30.813 20.504">
    <path data-name="Path 1739" d="m910.023 309.078 3.377-1.4a1.267 1.267 0 0 0 .649-.649 1.109 1.109 0 0 0 0-.909 1.209 1.209 0 0 0-1.559-.649l-3.377 1.4a1.168 1.168 0 0 0-.714 1.1 1.146 1.146 0 0 0 .1.455 1.2 1.2 0 0 0 1.524.652z" transform="translate(-884.632 -303.112)" fill={color} />
    <path data-name="Path 1740" d="m912.229 756.382-3.377-1.4a1.192 1.192 0 0 0-1.559.649 1.038 1.038 0 0 0-.1.455 1.22 1.22 0 0 0 .714 1.1l3.377 1.4a1.181 1.181 0 0 0 1.559-.649 1.167 1.167 0 0 0-.614-1.555z" transform="translate(-883.461 -740.448)" fill={color} />
    <path data-name="Path 1741" d="M952.839 556.8h-3.669a1.169 1.169 0 1 0 0 2.338h3.637a1.164 1.164 0 0 0 1.169-1.169 1.137 1.137 0 0 0-1.136-1.169z" transform="translate(-923.162 -547.717)" fill={color} />
    <path data-name="Path 1742" d="m84.23 306.382-3.377-1.4a1.181 1.181 0 0 0-1.559.649 1.109 1.109 0 0 0 0 .909 1.268 1.268 0 0 0 .649.649l3.377 1.4a1.192 1.192 0 0 0 1.559-.649 1.038 1.038 0 0 0 .1-.455 1.308 1.308 0 0 0-.749-1.103z" transform="translate(-77.866 -302.624)" fill={color} />
    <path data-name="Path 1743" d="m83.9 755.627-3.377 1.4a1.194 1.194 0 0 0 .909 2.208l3.377-1.4a1.168 1.168 0 0 0 .714-1.1 1.145 1.145 0 0 0-.1-.455 1.2 1.2 0 0 0-1.526-.649z" transform="translate(-78.449 -741.089)" fill={color} />
    <path data-name="Path 1744" d="M36.009 557.97a1.164 1.164 0 0 0-1.169-1.17h-3.669a1.169 1.169 0 0 0 0 2.338h3.637a1.172 1.172 0 0 0 1.2-1.169z" transform="translate(-30.002 -547.718)" fill={color} />
    <path data-name="Path 1745" d="m377.539 228.282-2.825.162.487-6.234a1 1 0 0 0-1.851-.617l-7.241 11.3a1.019 1.019 0 0 0 .909 1.559l2.825-.162-.487 6.234a1 1 0 0 0 1.851.617l7.241-11.3a1.009 1.009 0 0 0-.909-1.559z" transform="translate(-356.856 -221.115)" fill={color} />
  </svg>
);

const Live = color => (
  <svg xmlns={xmlns} width="19" height="11.821" viewBox="0 0 19 11.821">
    <path d="M2.373 226.7a.594.594 0 0 0-.5.246 9.5 9.5 0 0 0 0 11.335.6.6 0 0 0 .956-.711 8.3 8.3 0 0 1 0-9.914.594.594 0 0 0-.459-.956zm14.24 0a.594.594 0 0 0-.438.956 8.312 8.312 0 0 1 0 9.914.6.6 0 0 0 .956.711 9.516 9.516 0 0 0 0-11.334.594.594 0 0 0-.517-.246zm-12.264 1.745a.594.594 0 0 0-.486.263 6.874 6.874 0 0 0 0 7.8.594.594 0 1 0 .976-.676 5.688 5.688 0 0 1 0-6.451.594.594 0 0 0-.491-.938zm10.287 0a.594.594 0 0 0-.47.938 5.674 5.674 0 0 1 0 6.451.594.594 0 1 0 .976.676 6.853 6.853 0 0 0 0-7.8.594.594 0 0 0-.5-.263zM9.5 228.6a4.015 4.015 0 1 0 4.015 4.015A4.024 4.024 0 0 0 9.5 228.6z" transform="translate(-.002 -226.696)" fill={color} />
  </svg>
);

const Key = color => (
  <svg xmlns={xmlns} viewBox='0 0 25.897 15.7'>
    <path
      fill={color}
      d='M3.734 8.1a7.83 7.83 0 0 1 12.883.939h8.691a2.6 2.6 0 0 1 2.59 2.59v2.751a2.6 2.6 0 0 1-2.59 2.59h-1.18a.8.8 0 0 1-.809-.809V14.91h-1.44v1.246a.812.812 0 0 1-.809.809h-4.452a7.76 7.76 0 0 1-6.766 3.885 8.947 8.947 0 0 1-1.942-.227A7.807 7.807 0 0 1 2.2 14.78 7.9 7.9 0 0 1 3.734 8.1zm3.949 6.587a1.682 1.682 0 1 0-1.191-.493 1.683 1.683 0 0 0 1.191.493z'
      transform='translate(-2.001 -5.15)'
    />
  </svg>
);

const LinkOut = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path
      d='M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z'
      fill={color}
    />
  </svg>
);

const List = color => (
  <svg xmlns={xmlns} viewBox="0 0 512 512"><path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z" fill={color} /></svg>
);

const Logout = color => (
  <svg xmlns={xmlns} viewBox='0 0 512 512'>
    <path
      fill={color}
      d='M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z'
    />
  </svg>
);

const Loudspeaker = color => (
  <svg xmlns={xmlns} viewBox='0 0 26 18.908'>
    <path
      fill={color}
      d='M7.157 14.829v7.636a.43.43 0 0 1-.43.43H4.149A2.151 2.151 0 0 1 2 20.746v-4.2A2.151 2.151 0 0 1 4.149 14.4h2.578a.43.43 0 0 1 .43.429zm-.43 8.926H4.149a.43.43 0 0 0-.43.43v3.438a1.289 1.289 0 0 0 1.289 1.289h.86a1.289 1.289 0 0 0 1.289-1.289v-3.439a.43.43 0 0 0-.43-.43zm20.628-5.8h-1.289a.645.645 0 0 0 0 1.289h1.289a.645.645 0 1 0 0-1.289zM24.807 16.2a.645.645 0 0 0 .456-.189l.912-.912a.645.645 0 1 0-.912-.912l-.911.912a.645.645 0 0 0 .456 1.1zm.456 4.977a.645.645 0 1 0-.912.912l.912.911a.645.645 0 1 0 .912-.912zM22.2 16.448h-.43v-4.257a2.18 2.18 0 0 0-.274-1.093 2.153 2.153 0 0 0-2.629-.957L8.294 14.1a.43.43 0 0 0-.279.4v8.281a.43.43 0 0 0 .279.4l10.572 3.964a2.149 2.149 0 0 0 2.536-.8 2.213 2.213 0 0 0 .367-1.263v-4.34h.43a1.719 1.719 0 0 0 1.719-1.719v-.86a1.719 1.719 0 0 0-1.718-1.715z'
      transform='translate(-2 -10.004)'
    />
  </svg>
);

const Mobile = color => (
  <svg viewBox='0 0 550 512' xmlns={xmlns}>
    <g fill={color} transform='translate(110, 0) scale(1.02, 1.02)'>
      <path d='M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z' />
    </g>
  </svg>
);

const Moneybag = color => (
  <svg xmlns={xmlns} width='17.543' height='24' viewBox='0 0 17.543 24'>
    <path
      data-name='Path 1808'
      d='M41.876 3.8a2.942 2.942 0 0 1 .873-.764A10.2 10.2 0 0 0 44.5 1.068C44.058.3 42.312.194 41.657.631c-1.2.655-1.2-.437-2.183.437-.764.546-.655-.437-1.637-.764-.764-.327-2.4-.327-2.838.437A10.2 10.2 0 0 0 36.746 2.7a5.438 5.438 0 0 1 1.2 1.091z'
      transform='translate(-31.166 -.096)'
      fill={color}
    />
    <path data-name='Path 1809' d='M68.947 40.655a.923.923 0 0 1 .218-.655H66c.109.327.218.437.218.655z' transform='translate(-58.782 -35.645)' fill={color} />
    <path
      data-name='Path 1810'
      d='M8.616 71.226c8.4-.109 9.6-5.348 8.4-9.6A13.884 13.884 0 0 0 10.363 53H6.979a13.687 13.687 0 0 0-6.766 8.622c-1.092 4.256.109 9.495 8.403 9.604zM8.4 68.607v-.873a5.244 5.244 0 0 1-3.165-1.419l.982-1.091A3.791 3.791 0 0 0 8.4 66.315v-2.4c-4.584-1.091-2.947-4.911 0-5.02v-.655h.764v.655a4.441 4.441 0 0 1 2.619.873l-.764 1.2a4.48 4.48 0 0 0-1.857-.768v2.4c.764 0 2.838.655 2.838 2.619 0 1.528-1.31 2.4-2.838 2.51v.873z'
      transform='translate(.129 -47.226)'
      fill={color}
    />
    <path data-name='Path 1811' d='M67.515 119c-1.31.109-2.074 1.528 0 2.183z' transform='translate(-58.988 -106.023)' fill={color} />
    <path data-name='Path 1812' d='M85 157.292c1.419-.218 2.183-1.637 0-2.292z' transform='translate(-75.709 -138.094)' fill={color} />
  </svg>
);

const Moon = color => (
  <svg xmlns={xmlns} viewBox="0 0 512 512"><path d="M32 256c0-123.8 100.3-224 223.8-224c11.36 0 29.7 1.668 40.9 3.746c9.616 1.777 11.75 14.63 3.279 19.44C245 86.5 211.2 144.6 211.2 207.8c0 109.7 99.71 193 208.3 172.3c9.561-1.805 16.28 9.324 10.11 16.95C387.9 448.6 324.8 480 255.8 480C132.1 480 32 379.6 32 256z" fill={color} /></svg>
);

const NoizLogo = color => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1398.74 524.02">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path className="cls-1" d="M219.67,328.62h4.86V194.51H358V514.91H260.94L138.36,382H133.5v132.9H0V194.51H97.09Z" fill={color} />
        <path className="cls-1" d="M798,356.53a155.18,155.18,0,0,1-13.35,63.11,163.3,163.3,0,0,1-38.23,53.71q-24.89,23.37-60.38,37T606.82,524q-43.09,0-77.37-13t-58.26-35.5a151.54,151.54,0,0,1-36.71-53.1,169.73,169.73,0,0,1-12.74-65.84q0-39.44,15.47-71a170,170,0,0,1,41.26-54,179.76,179.76,0,0,1,60.08-34.29,218.79,218.79,0,0,1,71.91-11.83,211.74,211.74,0,0,1,70.7,11.83,190.63,190.63,0,0,1,59.77,33.68,160.63,160.63,0,0,1,41.57,53.7Q798,316.48,798,356.53Zm-135.32-1.21q0-16.38-4.85-28.22T645.36,308a50.3,50.3,0,0,0-17.29-10.92,55,55,0,0,0-19.42-3.64q-22.46,0-38.23,14.87t-15.78,47q0,31.56,14.87,46.12T609.25,416q21.85,0,37.62-14.26T662.65,355.32Z" fill={color} />
        <path className="cls-1" d="M1004.3,514.91H864.73V194.51H1004.3Z" fill={color} />
        <path className="cls-1" d="M1266.45,411.75H1395.7V514.91H1072.87l-2.43-3.64L1202.73,300.7l-1.82-3H1084.4V194.51h312.52l1.82,2.43L1264.63,409.32Z" fill={color} />
        <circle className="cls-2" cx="929.35" cy="68.89" r="68.89" fill='red' />
      </g>
    </g>
  </svg>
);

const Play = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path fill={color} d='M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z' />
  </svg>
);

const PlayStation = color => (
  <svg viewBox='0 0 576 512' xmlns={xmlns}>
    <path
      fill={color}
      d='M570.9 372.3c-11.3 14.2-38.8 24.3-38.8 24.3L327 470.2v-54.3l150.9-53.8c17.1-6.1 19.8-14.8 5.8-19.4-13.9-4.6-39.1-3.3-56.2 2.9L327 381.1v-56.4c23.2-7.8 47.1-13.6 75.7-16.8 40.9-4.5 90.9.6 130.2 15.5 44.2 14 49.2 34.7 38 48.9zm-224.4-92.5v-139c0-16.3-3-31.3-18.3-35.6-11.7-3.8-19 7.1-19 23.4v347.9l-93.8-29.8V32c39.9 7.4 98 24.9 129.2 35.4C424.1 94.7 451 128.7 451 205.2c0 74.5-46 102.8-104.5 74.6zM43.2 410.2c-45.4-12.8-53-39.5-32.3-54.8 19.1-14.2 51.7-24.9 51.7-24.9l134.5-47.8v54.5l-96.8 34.6c-17.1 6.1-19.7 14.8-5.8 19.4 13.9 4.6 39.1 3.3 56.2-2.9l46.4-16.9v48.8c-51.6 9.3-101.4 7.3-153.9-10z'
    />
  </svg>
);

const Plus = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path
      d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'
      fill={color}
    />
  </svg>
);

const QuestionCircle = color => (
  <svg xmlns={xmlns} viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z" fill={color} /></svg>
);

const QuestionMark = color => (
  <svg xmlns={xmlns} viewBox="0 0 320 512"><path d="M96 96c-17.7 0-32 14.3-32 32s-14.3 32-32 32s-32-14.3-32-32C0 75 43 32 96 32h97c70.1 0 127 56.9 127 127c0 52.4-32.2 99.4-81 118.4l-63 24.5 0 18.1c0 17.7-14.3 32-32 32s-32-14.3-32-32V301.9c0-26.4 16.2-50.1 40.8-59.6l63-24.5C240 208.3 256 185 256 159c0-34.8-28.2-63-63-63H96zm48 384c-22.1 0-40-17.9-40-40s17.9-40 40-40s40 17.9 40 40s-17.9 40-40 40z" fill={color} /></svg>
);

const Scroll = color => (
  <svg viewBox='0 0 640 512' xmlns={xmlns}>
    <path
      fill={color}
      d='M48 0C21.53 0 0 21.53 0 48v64c0 8.84 7.16 16 16 16h80V48C96 21.53 74.47 0 48 0zm208 412.57V352h288V96c0-52.94-43.06-96-96-96H111.59C121.74 13.41 128 29.92 128 48v368c0 38.87 34.65 69.65 74.75 63.12C234.22 474 256 444.46 256 412.57zM288 384v32c0 52.93-43.06 96-96 96h336c61.86 0 112-50.14 112-112 0-8.84-7.16-16-16-16H288z'
    />
  </svg>
);

const Search = color => (
  <svg xmlns={xmlns} viewBox='0 0 512 512'>
    <path
      fill={color}
      d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'
    />
  </svg>
);

const Splotch = color => (
  <svg clipRule='evenodd' fillRule='evenodd' viewBox='0 0 128 109'>
    <path
      d='M136.782 85.209S154.383 10.46 75 7.083c0 0-26.354-.066-31.49.653-9.826 1.377-31.491 8.224-32.362 33.343 0 0-.783 32.313-.109 36.067 5.35 29.794 33.176 38.573 48.27 38.573l27.459.218c15.64-1.526 44.13-8.768 50.014-30.728Z'
      fill={color}
      transform='translate(-10.765 -7.081)'
    />
  </svg>
);

const Square = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path d='M0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96z' fill={color} />
  </svg>
);

const SquareCheck = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path
      d='M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM339.8 211.8C350.7 200.9 350.7 183.1 339.8 172.2C328.9 161.3 311.1 161.3 300.2 172.2L192 280.4L147.8 236.2C136.9 225.3 119.1 225.3 108.2 236.2C97.27 247.1 97.27 264.9 108.2 275.8L172.2 339.8C183.1 350.7 200.9 350.7 211.8 339.8L339.8 211.8z'
      fill={color}
    />
  </svg>
);

const Steam = color => (
  <svg xmlns={xmlns} viewBox='0 0 496 512'>
    <path
      d='M496 256c0 137-111.2 248-248.4 248-113.8 0-209.6-76.3-239-180.4l95.2 39.3c6.4 32.1 34.9 56.4 68.9 56.4 39.2 0 71.9-32.4 70.2-73.5l84.5-60.2c52.1 1.3 95.8-40.9 95.8-93.5 0-51.6-42-93.5-93.7-93.5s-93.7 42-93.7 93.5v1.2L176.6 279c-15.5-.9-30.7 3.4-43.5 12.1L0 236.1C10.2 108.4 117.1 8 247.6 8 384.8 8 496 119 496 256zM155.7 384.3l-30.5-12.6a52.79 52.79 0 0 0 27.2 25.8c26.9 11.2 57.8-1.6 69-28.4 5.4-13 5.5-27.3.1-40.3-5.4-13-15.5-23.2-28.5-28.6-12.9-5.4-26.7-5.2-38.9-.6l31.5 13c19.8 8.2 29.2 30.9 20.9 50.7-8.3 19.9-31 29.2-50.8 21zm173.8-129.9c-34.4 0-62.4-28-62.4-62.3s28-62.3 62.4-62.3 62.4 28 62.4 62.3-27.9 62.3-62.4 62.3zm.1-15.6c25.9 0 46.9-21 46.9-46.8 0-25.9-21-46.8-46.9-46.8s-46.9 21-46.9 46.8c.1 25.8 21.1 46.8 46.9 46.8z'
      fill={color}
    />
  </svg>
);

const Sun = color => (
  <svg xmlns={xmlns} viewBox="0 0 512 512"><path d="M256 159.1c-53.02 0-95.1 42.98-95.1 95.1S202.1 351.1 256 351.1s95.1-42.98 95.1-95.1S309 159.1 256 159.1zM509.3 347L446.1 255.1l63.15-91.01c6.332-9.125 1.104-21.74-9.826-23.72l-109-19.7l-19.7-109c-1.975-10.93-14.59-16.16-23.72-9.824L256 65.89L164.1 2.736c-9.125-6.332-21.74-1.107-23.72 9.824L121.6 121.6L12.56 141.3C1.633 143.2-3.596 155.9 2.736 164.1L65.89 256l-63.15 91.01c-6.332 9.125-1.105 21.74 9.824 23.72l109 19.7l19.7 109c1.975 10.93 14.59 16.16 23.72 9.824L256 446.1l91.01 63.15c9.127 6.334 21.75 1.107 23.72-9.822l19.7-109l109-19.7C510.4 368.8 515.6 356.1 509.3 347zM256 383.1c-70.69 0-127.1-57.31-127.1-127.1c0-70.69 57.31-127.1 127.1-127.1s127.1 57.3 127.1 127.1C383.1 326.7 326.7 383.1 256 383.1z" fill={color} /></svg>
);

const Switch = color => (
  <svg viewBox='0 0 900 900' xmlns={xmlns}>
    <g fill={color} transform='translate(-275, 1430) scale(0.17, -0.17)'>
      <path d='M2965 8314 c-481 -86 -868 -442 -990 -910 -44 -169 -47 -268 -42 -1579 3 -1204 4 -1232 24 -1325 111 -501 467 -858 973 -976 66 -15 150 -18 691 -21 560 -4 618 -3 633 12 15 15 16 208 16 2396 0 1622 -3 2386 -10 2400 -10 18 -27 19 -613 18 -476 -1 -619 -4 -682 -15z m905 -2400 l0 -2026 -407 5 c-375 4 -415 6 -490 25 -322 83 -561 331 -628 654 -22 101 -22 2589 -1 2688 60 281 255 514 518 619 132 53 193 59 621 60 l387 1 0 -2026z' />
      <path d='M3051 7329 c-63 -12 -159 -60 -210 -105 -105 -91 -157 -220 -149 -372 4 -79 9 -100 41 -164 47 -97 118 -168 215 -216 67 -33 84 -37 171 -40 79 -3 107 0 160 18 217 73 348 284 311 500 -43 257 -287 429 -539 379z' />
      <path d='M4757 8323 c-4 -3 -7 -1087 -7 -2409 0 -2181 1 -2402 16 -2408 27 -10 803 -6 899 4 406 46 764 293 959 660 25 47 58 126 75 175 63 188 61 138 61 1575 0 1147 -2 1318 -16 1391 -99 521 -496 914 -1018 1004 -70 12 -178 15 -526 15 -240 0 -440 -3 -443 -7z m1068 -2178 c156 -41 284 -160 336 -312 33 -94 32 -232 -1 -318 -61 -158 -181 -269 -335 -310 -250 -65 -516 86 -589 334 -22 76 -21 204 4 282 75 245 335 389 585 324z' />
    </g>
  </svg>
);

const Times = color => (
  <svg xmlns={xmlns} viewBox='0 0 352 512'>
    <path
      fill={color}
      d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'
    />
  </svg>
);

const Trash = color => (
  <svg xmlns={xmlns} viewBox='0 0 448 512'>
    <path
      d='M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z'
      fill={color}
    />
  </svg>
);

const Twitch = color => (
  <svg viewBox='0 0 512 512' xmlns={xmlns}>
    <path fill={color} d='M391.17,103.47H352.54v109.7h38.63ZM285,103H246.37V212.75H285ZM120.83,0,24.31,91.42V420.58H140.14V512l96.53-91.42h77.25L487.69,256V0ZM449.07,237.75l-77.22,73.12H294.61l-67.6,64v-64H140.14V36.58H449.07Z' />
  </svg>
);

const Twitter = color => (
  <svg xmlns={xmlns} viewBox='0 0 512 512'>
    <path
      d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'
      fill={color}
    />
  </svg>
);

const Upload = color => (
  <svg xmlns={xmlns} viewBox='0 0 384 512'>
    <path
      d='M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM288.1 344.1C284.3 349.7 278.2 352 272 352s-12.28-2.344-16.97-7.031L216 305.9V408c0 13.25-10.75 24-24 24s-24-10.75-24-24V305.9l-39.03 39.03c-9.375 9.375-24.56 9.375-33.94 0s-9.375-24.56 0-33.94l80-80c9.375-9.375 24.56-9.375 33.94 0l80 80C298.3 320.4 298.3 335.6 288.1 344.1z'
      fill={color}
    />
  </svg>
);

const User = color => (
  <svg xmlns={xmlns} viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" fill={color} /></svg>
);

const Users = color => (
  <svg xmlns={xmlns} viewBox='0 0 640 512'>
    <path
      fill={color}
      d='M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z'
    />
  </svg>
);

const Windows = color => (
  <svg viewBox='0 0 448 512' xmlns={xmlns}>
    <path fill={color} d='M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z' />
  </svg>
);

const XBox = color => (
  <svg viewBox='0 0 512 512' xmlns={xmlns}>
    <path
      fill={color}
      d='M369.9 318.2c44.3 54.3 64.7 98.8 54.4 118.7-7.9 15.1-56.7 44.6-92.6 55.9-29.6 9.3-68.4 13.3-100.4 10.2-38.2-3.7-76.9-17.4-110.1-39C93.3 445.8 87 438.3 87 423.4c0-29.9 32.9-82.3 89.2-142.1 32-33.9 76.5-73.7 81.4-72.6 9.4 2.1 84.3 75.1 112.3 109.5zM188.6 143.8c-29.7-26.9-58.1-53.9-86.4-63.4-15.2-5.1-16.3-4.8-28.7 8.1-29.2 30.4-53.5 79.7-60.3 122.4-5.4 34.2-6.1 43.8-4.2 60.5 5.6 50.5 17.3 85.4 40.5 120.9 9.5 14.6 12.1 17.3 9.3 9.9-4.2-11-.3-37.5 9.5-64 14.3-39 53.9-112.9 120.3-194.4zm311.6 63.5C483.3 127.3 432.7 77 425.6 77c-7.3 0-24.2 6.5-36 13.9-23.3 14.5-41 31.4-64.3 52.8C367.7 197 427.5 283.1 448.2 346c6.8 20.7 9.7 41.1 7.4 52.3-1.7 8.5-1.7 8.5 1.4 4.6 6.1-7.7 19.9-31.3 25.4-43.5 7.4-16.2 15-40.2 18.6-58.7 4.3-22.5 3.9-70.8-.8-93.4zM141.3 43C189 40.5 251 77.5 255.6 78.4c.7.1 10.4-4.2 21.6-9.7 63.9-31.1 94-25.8 107.4-25.2-63.9-39.3-152.7-50-233.9-11.7-23.4 11.1-24 11.9-9.4 11.2z'
    />
  </svg>
);

const XMark = color => (
  <svg xmlns={xmlns} viewBox='0 0 320 512'>
    <path
      d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z'
      fill={color}
    />
  </svg>
);

const YouTube = color => (
  <svg xmlns={xmlns} viewBox='0 0 576 512'>
    <path
      d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'
      fill={color}
    />
  </svg>
);


function Icon({ color = '#fff', name }) {
  switch (name?.trim()?.toLowerCase()) {
    case 'addcommentbubble': return AddCommentBubble(color);
    case 'addperson': return AddPerson(color);
    case 'angleleft': return AngleLeft(color);
    case 'angleright': return AngleRight(color);
    case 'anglesleft': return AnglesLeft(color);
    case 'arrowleft': return ArrowLeft(color);
    case 'caretdown': return CaretDown(color);
    case 'caretup': return CaretUp(color);
    case 'check': return Check(color);
    case 'checklist': return Checklist(color);
    case 'clock': return Clock(color);
    case 'edit': return Edit(color);
    case 'empty': return Empty(color);
    case "expand": return Expand(color);
    case 'eye': return Eye(color);
    case 'facebook': return Facebook(color);
    case 'filter': return Filters(color);
    case 'gamepad': return GamePad(color);
    case 'graph': return Graph(color);
    case 'grip': return Grip(color);
    case 'handgamecontroller': return HandGameController(color);
    case 'handshield': return HandShield(color);
    case 'house': return House(color);
    case 'infosolid': return InfoSolid(color);
    case 'instagram': return Instagram(color);
    case 'key': return Key(color);
    case 'lightningbolt': return LightningBolt(color);
    case 'linkout': return LinkOut(color);
    case 'list': return List(color);
    case 'live': return Live(color);
    case 'logout': return Logout(color);
    case 'loudspeaker': return Loudspeaker(color);
    case 'mobile': return Mobile(color);
    case 'moneybag': return Moneybag(color);
    case 'moon': return Moon(color);
    case 'noizlogo': return NoizLogo(color);
    case 'play': return Play(color);
    case 'playstation': return PlayStation(color);
    case 'plus': return Plus(color);
    case 'questioncircle': return QuestionCircle(color);
    case 'questionmark': return QuestionMark(color);
    case 'scroll': return Scroll(color);
    case 'search': return Search(color);
    case 'splotch': return Splotch(color);
    case 'square': return Square(color);
    case 'squarecheck': return SquareCheck(color);
    case 'steam': return Steam(color);
    case 'sun': return Sun(color);
    case 'switch': return Switch(color);
    case 'times': return Times(color);
    case 'trash': return Trash(color);
    case 'twitch': return Twitch(color);
    case 'twitter': return Twitter(color);
    case 'upload': return Upload(color);
    case 'user': return User(color);
    case 'users': return Users(color);
    case 'windows': return Windows(color);
    case 'xbox': return XBox(color);
    case 'xmark': return XMark(color);
    case 'youtube': return YouTube(color);
    default: return null;
  }
}

export default Icon;
