import React from 'react';
import { times } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import './Pagination.scss';

function Pagination({
  currentPage, handleClick, limit, total,
}) {
  const totalPages = Math.ceil(total / limit);
  const timesTotalPages = times(totalPages);

  return (
    <nav className="pagination">
      {timesTotalPages.map((p) => {
        const actualPage = p + 1;
        let dotsBefore = false;
        let dotsAfter = false;

        if (actualPage === 1 && currentPage !== 1 && currentPage !== 2 && currentPage !== 3 && actualPage !== totalPages) {
          dotsAfter = true;
        } else if (actualPage === totalPages && currentPage !== totalPages && currentPage !== totalPages - 1 && currentPage !== totalPages - 2) {
          dotsBefore = true;
        } else if (
          !((actualPage === 1) // Always display 1
            || (actualPage === totalPages) // Always display last
            || (currentPage === actualPage) // Always display current
            || (currentPage + 1 === actualPage) // Always display current + 1
            || (currentPage - 1 === actualPage) // Always display current - 1
            || (currentPage === 1 && actualPage === 3) // Display 3 if current is 1
            || (currentPage === totalPages && actualPage === totalPages - 2) // Display antepenultimate in current is last
          )
        ) {
          return <React.Fragment />;
        }

        return (
          <div
            className="page"
            key={uuidv4()}
          >
            {dotsBefore && <span>...</span>}
            <button
              className={`${actualPage === currentPage ? 'active' : ''}`}
              onClick={() => handleClick(actualPage)}
            >
              {actualPage}
            </button>
            {dotsAfter && <span>...</span>}
          </div>
        );
      })}
    </nav>
  );
}

export default Pagination;
