import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export const MDeleteIcon = DeleteIcon;
export const MSendIcon = SendIcon;
export const DownloadIcon = CloudDownloadIcon;
export const PowerIcon = PowerSettingsNewIcon;

export const BlueSwitch = withStyles({
  switchBase: {
    color: blue[300],
    '&$checked': {
      color: blue[500],
    },
    '&$checked + $track': {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export const RedButton = withStyles({
  root: {
    color: red[50],
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
})(Button);

export const RedSwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: red[500],
    },
    '&$checked + $track': {
      backgroundColor: red[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const textFieldStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      marginBottom: '20px',
    },
  },
}));

export const MasterLink = (props) => {
  const classes = textFieldStyles();
  const [value, setValue] = React.useState('');
  const [oldCamp, setCamp] = React.useState('');

  const handleChange = (e, newValue) => {
    setValue(newValue);
    props.update(e);
  };

  useEffect(() => {
    if (props.existingLink !== oldCamp) {
      setCamp(props.existingLink);
      setValue(props.existingLink);
    }
  }, [props,oldCamp]);

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        value={value}
        onChange={handleChange}
        id="master-link"
        label="http:// or https:// required"
        variant="standard"
      />
    </form>
  );
};
