import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import './SelectedGames.scss';

const SelectedGames = (props) => {
  const { games, onClick } = props;

  const handleClick = (title) => {
    if (window.confirm('Are you sure about removing this game?')) {
      onClick(title);
    }
  };

  const selectedGamesRender = games.map(g => (
    <ListItem button id={g} key={g} onClick={() => handleClick(g)}>
      <ListItemText primary={g} />
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
    </ListItem>
  ));

  return (
    <Paper style={{ height: 200, overflow: 'auto' }} className="SelectedGames">
      <List>
        {selectedGamesRender}
      </List>
    </Paper>
  );
};

export default SelectedGames;
