import React from 'react';

import LoadingGif from '../assets/LoadingGif';
import PortraitCards from './PortraitCards/PortraitCards';

const Products = ({ products, productsLoading }) => {
  if (productsLoading) {
    return <LoadingGif />;
  }

  const preparedProducts = products.map(({ id, coverPortrait, cover, name, quests }) => ({
    id,
    img: coverPortrait || cover,
    name,
    quests,
  }));

  const editFn = (id, newItem = false) => ({ ...(newItem ? {} : { currentProduct: id }), component: 'ProductEdit' });
  const detailFn = (id) => ({ currentProduct: id, component: 'ProductDetail' });
  const viewAllFn = (type, view) => ({ ...view, component: `ViewAll${type}s` });

  return (
    <PortraitCards
      items={preparedProducts}
      type='products'
      proper='Product'
      editFn={editFn}
      detailFn={detailFn}
      viewAllFn={viewAllFn}
      showViewAll={true}
    />
  );
};

export default Products;
