/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import { without } from 'lodash';
import classNames from 'classnames';
import { CSVLink } from 'react-csv';
// import { simpleNotification } from '../../../../utils/notifications';
import './QuestSales.scss';
import { paymentVendorProcessingFee } from '../../../../utils/functions';

class QuestSales extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showingElements: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestSales(questId, token);
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   // const { gameId } = nextProps.router.params;
  //
  // }

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [
          ...showingElements,
          elementId,
        ],
      });
    } else {
      this.setState({
        showingElements: [
          ...without(showingElements, elementId),
        ],
      });
    }
  }

  generateSalesRowsHTML = (saleActivity) => {
    if (saleActivity.length > 0) {
      return saleActivity.map(saleItem => (
        <div
          key={`-${saleItem.name}`}
          className="row"
        >
          <div className="table-cell">{`${saleItem.month}/${saleItem.dayOfMonth}/${saleItem.year} ${this.padWithZeroes(saleItem.hour, 2)}:${this.padWithZeroes(saleItem.minute, 2)}`}</div>
          <div className="table-cell">Success</div>
          <div className="table-cell">${this.rounding(saleItem.earning / 100)}</div>
          <div className="table-cell">${this.rounding(saleItem.price / 100)}</div>
        </div>
      ));
    }
    return (<div className="NoSale">No Sale.</div>);
  }

  addZeroes = (param) => {
    const num = param.toString();
    let value = Number(num);
    const res = num.split('.');
    if (res.length === 1 || res[1].length < 3) {
      value = value.toFixed(2);
    }
    return value;
  }

  rounding = number => parseFloat(number).toFixed(2);

  padWithZeroes = (number, length) => {
    let myString = number.toString();
    while (myString.length < length) {
      myString = `0${myString}`;
    }
    return myString;
  }

  generateRowsHTML = (questSale) => {
    const { showingElements } = this.state;

    if (questSale.length > 0) {
      return questSale.map((questSaleItem, index) => (
        <div
          key={questSale.twitchUsername}
          className={classNames('row toggable', { open: (showingElements.indexOf(questSaleItem.twitchUsername) !== -1) })}
        >
          <div className="row-basic">
            <div className="table-cell">{index + 1}</div>
            <div className="table-cell">{questSaleItem.twitchUsername}</div>
            <div className="table-cell">{questSaleItem.username}</div>
            <div className="table-cell">{questSaleItem.referralCode}</div>
            <div className="table-cell">{questSaleItem.numberOfReferrals}</div>
            <div className="table-cell">${this.rounding(questSaleItem.totalSelled / 100)}</div>
            <div className="table-cell">${this.rounding(questSaleItem.totalEarnings / 100)}</div>
            <div className="table-cell">
              { (showingElements.indexOf(questSaleItem.twitchUsername) === -1 && questSaleItem.numberOfReferrals > 0) && (
              <button className="small-btn" onClick={this.toggleElementInfo.bind(this, questSaleItem.twitchUsername)}>Show Details</button>
                )}
              { (showingElements.indexOf(questSaleItem.twitchUsername) !== -1) && (
              <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, questSaleItem.twitchUsername)}>Hide Details</button>
                ) }
            </div>
          </div>
          <div className="row-details">
            <section className="StreamActivityTable flex-table">
              <div className="row">
                <div className="table-cell headline">Date</div>
                <div className="table-cell headline">Status</div>
                <div className="table-cell headline">Earning</div>
                <div className="table-cell headline">Price</div>
              </div>
              { this.generateSalesRowsHTML(questSaleItem.purchases) }
            </section>
          </div>
        </div>
      ));
    }
    return (<div className="NoQuestSale">No sales on this Quest.</div>);
  }

  // user names, paypal email, # sold, total sold, total earned
  render() {
    const { gameId } = this.props.router.params;
    const { data: questSales } = this.props.commandCenter.questSales;
    const { data: quest } = this.props.commandCenter.quest;

    const headersCSV = [
      { label: 'Quest Name', key: 'questName' },
      { label: 'Noiz User Name', key: 'noizUsername' },
      { label: 'Twitch User Name', key: 'twitchUsername' },
      { label: 'PayPal Email', key: 'paymentEmail' },
      { label: '# Sold', key: 'numberSold' },
      { label: 'Total Sold', key: 'totalSold' },
      { label: 'Earnings', key: 'earnings' },
      { label: 'Processing Fee', key: 'processingFee' },
      { label: 'toPay', key: 'toPay' },
    ];

    const dataCSV = [];

    for (let i = 0; i < questSales.length; i += 1) {
      dataCSV.push({
        questName: quest.title,
        noizUsername: questSales[i].user.username,
        twitchUsername: questSales[i].user.connectedAccounts.twitch.username,
        paymentEmail: questSales[i].user.paymentEmail,
        numberSold: questSales[i].numberOfReferrals,
        totalSold: `${this.rounding((questSales[i].totalSelled) / 100)}`,
        earnings: `${this.rounding((questSales[i].totalEarnings) / 100)}`,
        processingFee: `${this.rounding((paymentVendorProcessingFee(questSales[i].totalEarnings)) / 100)}`,
        toPay: `${this.rounding((questSales[i].totalEarnings - (paymentVendorProcessingFee(questSales[i].totalEarnings))) / 100)}`,
      });
    }
    return (
      <section className="CCQuestSales">
        <Link
          to={`/admin/command-center/${gameId}/quests`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests`}
        >
          <button className="CCQuests__back--btn">Back</button>
        </Link>
        <h2>Title: { quest.title }</h2>
        <section className="ToggleTable">
          <section className="ToggleTable__header">
            <h3 className="ToggleTable__header-title">Quest Users: <span>{questSales.length}</span></h3>
            <CSVLink data={dataCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}.csv` : 'report.csv'}>
              <i className="fa fa-download" />
            </CSVLink>
          </section>
          <section className="ToggleTable__table-wrapper">
            <section className="ToggleTable__table flex-table">
              <div className="row">
                <div className="table-cell headline">#</div>
                <div className="table-cell headline">Twitch</div>
                <div className="table-cell headline">Noiz</div>
                <div className="table-cell headline">Referral Code</div>
                <div className="table-cell headline">No. Keys Sold</div>
                <div className="table-cell headline">Total Sold</div>
                <div className="table-cell headline">Total Earnings</div>
                <div className="table-cell headline">Actions</div>
              </div>
              { this.generateRowsHTML(questSales) }
              {/*
              <div className="row">
                <div className="table-cell">---</div>
                <div className="table-cell">---</div>
                <div className="table-cell">TOTAL: </div>
                <div className="table-cell">${totalErnings} USD</div>
                <div className="table-cell">---</div>
              </div>
              */}
            </section>
          </section>
        </section>
      </section>
    );
  }
}

QuestSales.propTypes = {

};

export default QuestSales;
