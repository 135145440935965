import React, {useState} from "react";
import './DisputeItem.scss';

const getFirstLetters = name => {
  const names = name.split(" ");
  const firstLetters = names.map(n => n[0]);
  return firstLetters.join("");
};



const questLinkGen = (questId, gameId) =>`/admin/command-center/${gameId}/quests/${questId}/tiered-one-time`

export const DisputeCouplet = ({ title, content }) => {
  return (
    <section className='DisputeCouplet'>
      <div className='DisputeCouplet_Title'>{title}</div>
      <div className='DisputeCouplet_Content'>{content}</div>
    </section>
  );
};

export const DisputeItem = ({dispute}) => {
  const {streamer:{username: streamerName}, brandUser: {username: brandName},  quest:questId, createdAt} = dispute;
  console.log(questId,'questId')
  const categories = [["BRAND", brandName], ["STREAMER", streamerName], ["QUEST ID", questId], ["DATE", createdAt], ["DECISION", ''], ["ACTION", '']];
  return (
    <section className='DisputeItem'>
      {categories.map((category, index) => {
        const [title, content] = category;
      return  <DisputeCouplet title={title} content={content} key={index} />

      }
      )}
    </section>
  );
};

export const DisputeDropdown = ({
  product = {},
  quest = {},
  // questTitle = "",

  productLink = "",
  disputes = [],
}) => {
  const [showDisputes, setShowDisputes] = useState(false);
  const{title: questTitle} = quest;

  const {cover} = product
  const questLink = questLinkGen(quest.id, product.id)

  return (
    <section className="DisputeDropdown_Col">
      <div className="DisputeDropdown_Row">
      <div className='DisputeDropdown_Left'>
        <div className='DisputeDropdown_ProductBox'>
          {product ? <img src={cover} alt='product' /> : getFirstLetters(questTitle)}
        </div>
        <div className='DisputeDropdown_QuestTitle'>{questTitle}</div>
      </div>
      <div className='DisputeDropdown_Right'>
        <a href={questLink} target='_blank' rel='noopener noreferrer'>
          <div className='DisputeDropdown_QuestDetails'>Quest Details</div>
        </a>
        <a href={productLink} target='_blank' rel='noopener noreferrer'>
          <div className='DisputeDropdown_ProductLink'>
            <i className='fa fa-up-right-from-square'></i>
          </div>
        </a>
        <div className='DisputeDropdown_DropDownButton' onClick={() => setShowDisputes(!showDisputes)} aria-label='view quest disputes'>
         {showDisputes ? <i className='fa fa-chevron-up'></i> : <i className='fa fa-chevron-down'></i>}
        </div>
      </div>
      </div>
      <section className="DisputeList">



      {showDisputes && (disputes.map((dispute, index) => {
       
        return (
          <DisputeItem
            key={index}
            dispute={dispute}
          />
        );
      }))}
      </section>
    </section>
  );
};


