import React from "react";
import "./ArbitrationStatus.scss";
// import { capitalizeFirstLetter } from "../../../../../utils/functions";

const decisionSwitch = {
  pending: "Decision Pending",
  streamer: "Ruled In Favor Of Streamer",
  brand: "Ruled In Your Favor",
  other: "Please reach out to Noiz Support",
};

const ArbitrationStatus = props => {
  const {
    ruledInFavorOf = "pending",
    questTitle = "Quest",
    disputeReason,
    disputeResponse,
  } = props;
  console.log(props, "props in arb stat");
  const message =
    ruledInFavorOf === "pending" ? "Your Quest Is Under Review" : "Your Quest Review Has Concluded";

  return (
    <section>
      <div className='ArbitrationStatus_Container'>
        <div className='ArbitrationStatus_TitleBox'>
          <h1>{message}</h1>
          <div className='title'>"{questTitle}"</div>
        </div>
        <div className='ArbitrationStatus_Decision'>
          <h2>Decision</h2>
          <div className='decision'>{decisionSwitch[ruledInFavorOf]}</div>
          <div className='reason'>{disputeReason} </div>
        </div>
        {disputeResponse.length ? (
          <div className='ArbitrationStatus_Comments'>
            <h2>Noiz Comments</h2>
            <div className='comments'>{disputeResponse}</div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default ArbitrationStatus;
// brandUser: "5d55959c723dec098b915603";
// cmNotes: "";
// createdAt: "2022-10-11T23:21:14.427Z";
// disputeReason: "asdfasdfa";
// disputeResponse: "";
// disputedRequirements: (2)[("854ade2266164383acae123cbb144845", "requiredStreamTimeMinutes")];
// fundingId: "";
// id: "6345fa6ac930b23e6d9e7326";
// quest: "6344a0d946f0a71e47fafd02";
// refundId: "";
// ruledInFavorOf: "pending";
// streamer: "5eb94c54482bcc4ccb665ab1";
// transferId: "";
// updatedAt: "2022-10-11T23:21:14.427Z";
