import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { SetViewContext } from "../PortalContext";
import { textTruncate, timeAgoCalc } from "../../../../utils/functions";
import { getRandomAvatar } from "../../../../global/images/icons/Avatar";
import { navigationAssembly } from "../../../../utils/functions";

import Icon from "../../../../global/images/icons/Icon";

import "./ReviewItems.scss";

// const WAIT_TIME = 5000;
// const INCREMENTS = 100;

const filterProcessed = items => items.slice(0, 3);

const ReviewItemsBlank = ({ type }) => (
  <div className='ReviewItems__Blank'>
    {Icon({ color: "#302f34", name: type === "Applicants" ? "users" : "empty" })}
    <div className='ReviewItems__Blank_Text'>Your incoming {type} list is empty.</div>
    <SetViewContext.Consumer>
      {({ setView, view }) => (
        <div
          className='ActiveItems__View_All'
          onClick={() =>
            setView(navigationAssembly(type, view))
          }>
          View All {type}
        </div>
      )}
    </SetViewContext.Consumer>
  </div>
);

const ItemCard = ({ type, item, removeItem, accept, reject }) => {
  const componentRef = useRef(null);
  // const progressRef = useRef(0);
  // const runningRef = useRef(null);

  // const [running, setRunning] = useState(null);
  // const [, setProgress] = useState(0);
  const [componentWidth, setComponentWidth] = useState(0);

  useEffect(() => {
    setComponentWidth(componentRef?.current?.offsetWidth || 0);
  }, [componentRef.current]);

  const {
    profile_img_url,
    displayName,
    username,
    title,
    createdAt,
    questId,
    userId,
    userQuestId,
    requirementId,
    requirementTitle,
  } = item;

  const timeAgo = timeAgoCalc(createdAt);

  const params = {};
  let itemCardInfo = null;

  const characterCount = title?.length || 0;
  const titleSize = characterCount * 14;
  const truncateTitle = titleSize > componentWidth;

  const displayTitle = title ? (
    truncateTitle ? (
      <Tooltip placement='top' title={title.toUpperCase()}>
        <span className='ReviewItems__Card_Info_Title'>
          {textTruncate(title.toUpperCase(), Math.floor(componentWidth / 14))}
        </span>
      </Tooltip>
    ) : (
      <span className='ReviewItems__Card_Info_Title'>{title.toUpperCase()}</span>
    )
  ) : (
    ""
  );

  if (type.toLowerCase() === "applicants") {
    params.questId = questId;
    params.userId = userId;

    itemCardInfo = (
      <div className='ReviewItems__Card_Info'>
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <span
              className='ReviewItems__Card_Info_Name'
              onClick={() =>
                setView({
                  ...view,
                  component: "StreamerProfile",
                  currentUser: username,
                })
              }>
              {displayName || username}
            </span>
          )}
        </SetViewContext.Consumer>
        {displayTitle}
        <span className='ReviewItems__Card_Info_Date'>{timeAgo}</span>
      </div>
    );
  } else if (type.toLowerCase() === "deliverables") {
    params.userQuestId = userQuestId;
    params.requirementId = requirementId;

    itemCardInfo = (
      <div className='ReviewItems__Card_Info'>
        <span className='ReviewItems__Card_Info_Name'>{displayName || username || title}</span>
        {displayTitle}
        <span className='ReviewItems__Card_Info_Requirement'>{requirementTitle}</span>
        <span className='ReviewItems__Card_Info_Date'>{timeAgo}</span>
      </div>
    );
  }

  // const regularActionsFunction = fn => {
  //   setRunning(true);
  //   runningRef.current = setInterval(() => {
  //     if (progressRef.current >= WAIT_TIME) {
  //       progressRef.current = 0;
  //       setProgress(0);
  //       clearInterval(runningRef.current);
  //       removeItem(type)(params);
  //       setRunning(false);
  //       fn();
  //       return;
  //     }

  //     progressRef.current += INCREMENTS;
  //     setProgress(progressRef.current);
  //   }, INCREMENTS);
  // };

  // const regularActions = (
  //   <>
  //     <div
  //       className='ReviewItems__Card_Actions_Button Accept'
  //       onClick={() => regularActionsFunction(() => accept(...Object.values(params)))}>
  //       {Icon({ color: "white", name: "check" })}
  //     </div>
  //     <div
  //       className='ReviewItems__Card_Actions_Button Reject'
  //       onClick={() => regularActionsFunction(() => reject(...Object.values(params)))}>
  //       {Icon({ color: "white", name: "times" })}
  //     </div>
  //   </>
  // );

  // const undoActionFunction = () => {
  //   setRunning(false);
  //   clearInterval(runningRef.current);
  //   setProgress(0);
  //   progressRef.current = 0;
  // };

  const reviewAction = (type, questId, userId) => (
    <SetViewContext.Consumer>
      {({ setView, view }) => (
        <div
          className='ReviewItems__Card_Actions_Review'
          onClick={() =>
            setView({
              ...view,
              component: `ViewAll${type === 'deliverables' ? 'Deliverables' : 'Applicants'}`,
              currentQuest: questId,
              currentUser: userId,
            })
          }>
          Review
        </div>
      )}
    </SetViewContext.Consumer>
  );

  // const percentage = (progressRef.current / WAIT_TIME) * 100;
  // const undoAction = (
  //   <div className='ReviewItems__Card_Actions_Undo' onClick={undoActionFunction}>
  //     <div className='ReviewItems__Card_Actions_Undo_Text'>UNDO?</div>
  //     <div
  //       className='ReviewItems__Card_Actions_Undo_Progress'
  //       style={{ width: `${percentage}%` }}
  //     />
  //   </div>
  // );

  return (
    <li ref={componentRef} className='ReviewItems__Card'>
      <img
        className='ReviewItems__Card_Img'
        src={profile_img_url}
        alt={displayName || username || title}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = getRandomAvatar();
        }}
      />
      {itemCardInfo}
      <div className='ReviewItems__Card_Actions'>
        {/* {type?.toLowerCase() === "applicants" && !running && (
          <div style={{ display: "flex", flexDirection: "row" }}>{regularActions}</div>
        )}
        {type?.toLowerCase() === "applicants" && !!running && undoAction} */}
        {reviewAction(type.toLowerCase(), questId, userId)}
      </div>
    </li>
  );
};

const ReviewItems = ({ type, items, removeItem, accept, reject }) => {
  const content =
    items && items.length ? (
      <ul className='ReviewItems__List'>
        {filterProcessed(items).map(item => (
          <ItemCard
            type={type}
            item={item}
            removeItem={removeItem}
            accept={() => {
              if (type.toLowerCase() === "applicants") {
                accept(item.userQuestId);
              } else {
                accept(item.userQuestId, item.requirementId);
              }
            }}
            reject={() => {
              if (type.toLowerCase() === "applicants") {
                reject(item.userQuestId);
              } else {
                reject(item.userQuestId, item.requirementId);
              }
            }}
            key={
              type.toLowerCase() === "applicants"
                ? `${item.userId}-${item.questId}`
                : `${item.userQuestId}-${item.requirementId}`
            }
          />
        ))}
        <SetViewContext.Consumer>
          {({ setView, view }) => (
            <li
              className='ReviewItems__List_View_All'
              onClick={() =>
                setView({
                  ...view,
                  component: `ViewAll${type}`,
                })
              }>
              View All {type}
            </li>
          )}
        </SetViewContext.Consumer>
      </ul>
    ) : (
      <ReviewItemsBlank type={type} />
    );

  return (
    <div className='ReviewItems'>
      {content}
    </div>
  );
};

export default ReviewItems;
