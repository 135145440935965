/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import { dayjs } from '../../utils/dateTime';

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (userFormInfo) => {
    const { role, onSubmit } = this.props;
    
    onSubmit({
      browserTimezone: dayjs.tz.guess(),
      identity: userFormInfo.identity.toLowerCase(),
      password: userFormInfo.password,
      ...(role ? { role } : {}),
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const isClientPortal = !!window.location.pathname.includes('portal');

    if (isClientPortal) {
      return (
        <form className="form-container signup client-portal" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <span style={{ fontSize: '12px', fontWeight: 'bold', margin: '0 0 10px 0' }}> EMAIL OR USERNAME </span>
          <Field
            type="text"
            name="identity"
            id="identity"
            component={CustomInput}
          />
          <span style={{ fontSize: '12px', fontWeight: 'bold', margin: '0 0 10px 0' }}> PASSWORD </span>
          <Field
            type="password"
            name="password"
            id="password"
            component={CustomInput}
          />
          <div className="form__footer form__footer__2" style={{ margin: '40px 0 0 0' }}>
            {this.props.auth.isLoading && (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />)}
            {!this.props.auth.isLoading && (
            <div className="form__footer__left">
              <button style={{ width: '120px', borderRadius: '25px' }} className="CTAButton__purple">Login</button>
              <span style={{ margin: '0 0 0 20px' }}>
                {'Forgot your password?  '}
                <Link
                  className="Modal__tab"
                  to="/recover"
                  href="/recover"
                  role="button"
                  aria-hidden
                >
                  Click here
                </Link>
              </span>
            </div>) }
          </div>
        </form>
      );
    }
    return (
      <form className="form-container signup" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="text"
          name="identity"
          id="identity"
          placeholder="Email or Username"
          customLabel2={(<label data-label="Email or Username" data-label-small="Your email or username here" htmlFor="identity" />)}
          component={CustomInput}
        />
        <Field
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          customLabel2={(<label data-label="Password" data-label-small="Your password here" />)}
          component={CustomInput}
        />
        <div className="form__footer form__footer__2">
          {this.props.auth.isLoading && (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />)}
          {!this.props.auth.isLoading && (
          <div className="form__footer__left">
            <button className="cta-button">Continue</button>
          </div>
            )}
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.identity || values.identity.trim() === '') {
    errors.identity = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address or username';
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'This field is required';
  }

  return errors;
};


SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'signInForm',
  validate,
})(SignInForm);
