import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import ReactHTMLParser from 'react-html-parser';
// import { debounce } from './functions';
import './RichText.scss';

const rte_api_key = 'wulnzhippckjtehbc6kaq1irabfkdvhgkgjezk8i24a9c653';
const rte_default_config = {
  skin: 'dark',
  menubar: false,
  plugins: 'link lists advlist',
  toolbar1:
  'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat',
  content_style: '#tinymce{background-color:#282830 !important;color:#fff !important;}',
  auto_focus: true, // must auto focus - critical for functionality
};

const styleO = {
  width: '100%',
  minWidth: '200px',
  minHeight: '70px',
  backgroundColor: '#282830',
  cursor: 'pointer',
  padding: '0px 10px',
};

const RichText = (props) => {
  const {
    initialValue = '',
    apiKey = rte_api_key,
    config = rte_default_config,
    callback = () => null,
  } = props;
  const [isFocused, setFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getContentForCallback = (event, editor) => {
    if (editor) {
      // const hasFocus = editor.hasFocus();
      const content = editor.getContent();
      callback(content);
    }
  };

  const displayLoadingGif = (e) => {
    console.log(e) 
  }

  useEffect(() => {
    if (isFocused === true) {
      setIsLoading(true);
    }
    if (isFocused === false) {
      setIsLoading(false);
    }
  }, [isFocused])




  // const debouncedCallback = debounce(getContentForCallback, 100);
  const handleChange = (booly) => {
    if (booly) {
      setFocus(booly);
    } else {
      setFocus(false);
    }
  };

  if (isFocused) {
    return (
      <Editor
        onBlur={(event, editor) => {
        // const content = editor.getContent();
        getContentForCallback(event, editor);
        handleChange(false);
      }}
        initialValue={initialValue}
        apiKey={apiKey}
        oninit={() => setIsLoading(false)}
        init={{ ...config, auto_focus: true }}
        // onKeyPress={debouncedCallback}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="rich-text-center">
        <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
      </div>
    );
  }

  return (
    <div style={styleO} className="white-hover-border" onClick={() => handleChange(true)}>
      {ReactHTMLParser(initialValue)}
    </div>
  );
};

export default RichText;
