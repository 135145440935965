import React from 'react';
import { Line } from 'react-chartjs-2';
import { lifeGreen } from '../../../utils/colors';

class LineChartDeprecated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { data } = this.props;

    const chartData = canvas => ({
      datasets: [{
        label: 'Viewers',
        borderColor: lifeGreen,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointHoverBackgroundColor: '#18191D',
        pointHoverBorderColor: lifeGreen,
        pointHoverRadius: 6,
        pointHoverBorderWidth: 2,
        pointHoverDotRadius: 8,
        tension: 0.4,
        pointHitRadius: 5,
        data,
        pointStyle: 'circle',
      }],
    });

    return (
      <section className="Chart">
        <Line
          data={chartData}
          options={{
            scaleLineColor: 'rgba(255,255,0,0)',
            mantainAspectRatio: false,
            layout: {
              padding: {
                top: 20,
                bottom: 20,
              },
            },
            tooltips: {
              intersect: false,
              mode: 'nearest',
              backgroundColor: '#2A2B2F',
              bodyFontSize: '0',
              callbacks: {
                label: (tooltipItem, data) => '',
                title: (tooltipItem, data) => `${tooltipItem[0].yLabel} at ${tooltipItem[0].xLabel}`,
              },
              titleMarginBottom: 0,
              footerMarginTop: 0,
            },
            legend: {
              display: false,
              position: 'bottom',
              fontColor: 'rgba(255, 255, 255, 0.2)',
            },
            hover: {
              mode: 'nearest',
              intersect: false,
            },
            scales: {
              yAxes: [{
                ticks: {
                  fontColor: 'rgba(255, 255, 255, 0.4)',
                  // fontStyle: 'bold',
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  padding: 30,
                  // stepSize: 300,
                },
                gridLines: {
                  drawTicks: true,
                  display: true,
                  drawBorder: true,
                  color: 'rgba(255, 255, 255, 0.1)',
                  zeroLineColor: 'rgba(255, 255, 255, 0.1)',
                  borderDash: [5],
                },
              }],
              xAxes: [{
                type: 'time',
                distribution: 'linear', // 'series',
                time: {
                  tooltipFormat: 'MMM D h:mm a',
                  // unit: 'minute',
                  // round: '5 minutes',
                  displayFormats: {
                    day: 'MMM D h:mm a',
                  },
                },
                gridLines: {
                  drawTicks: false,
                  display: false,
                  drawBorder: false,
                  // zeroLineColor: "rgba(255, 255, 255, 0.2)",
                  // color: 'rgba(255, 255, 255, 0.2)'
                },
                ticks: {
                  display: false,
                  padding: 30,
                  fontColor: 'white',
                  // fontStyle: 'bold',
                },
                pointLabels: {
                  fontColor: 'white',
                },
              }],
            },
          }}
        />
      </section>
    );
  }
}

export default LineChartDeprecated;
