import React from 'react';
import classNames from 'classnames';
import InsightsFilter from './InsightsFilter.js';
import GamesIcon from './icons/gameHistory.svg';
import GamingPlatformIcon from './icons/gamingPlatform.svg';
import GenderIcon from './icons/gender.svg';
import LanguageIcon from './icons/language.svg';
import RegionIcon from './icons/region.svg';
import ACCVIcon from './icons/accv.svg';
import QuestsIcon from './icons/quests.svg';
import UsersIcon from './icons/user.svg';
import GamesIconSelected from './icons/gameHistorySelected.svg';
import GamingPlatformIconSelected from './icons/gamingPlatformSelected.svg';
import GenderIconSelected from './icons/genderSelected.svg';
import LanguageIconSelected from './icons/languageSelected.svg';
import RegionIconSelected from './icons/regionSelected.svg';
import ACCVIconSelected from './icons/accvSelected.svg';
import QuestsIconSelected from './icons/questsSelected.svg';
import UsersIconSelected from './icons/userSelected.svg';
import { isEqual, without } from 'lodash';
import { countries } from './helpers/countries.js';
import languages from './languages.json';
import './InsightsFilterBar.scss';

class InsightsFilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      filterShown: false,
      prevPos: 0,
      filtersChanged: false,
      filters: {
        games: props.games,
        consoles: [
          { name: 'PC', id: 'pc' },
          { name: 'XBox', id: 'xbox' },
          { name: 'PlayStation', id: 'playstation' },
          { name: 'Nintendo', id: 'nintendo' },
          { name: 'Mobile', id: 'mobile' },
        ],
        streamingPlatforms: [
          { name: 'Twitch', id: 'twitch' },
        ],
        genders: [
          { name: 'Female', id: 'Female' },
          { name: 'Male', id: 'Male' },
          { name: 'Other', id: 'Other' },
        ],
        users: props.allUsers,
        regions: countries,
        languages,
        accv: {
          min: 0,
          max: props.maxACCV,
        },
        quests: {
          min: 0,
          max: props.maxQuests,
        },
      },
      selected: {
        games: [],
        consoles: [],
        streamingPlatforms: [],
        genders: [],
        regions: [],
        users: [],
        languages: [],
        accv: {
          min: 0,
          max: props.maxACCV,
        },
        quests: {
          min: 0,
          max: props.maxQuests,
        },
      },
    };
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { allUsers, games, maxACCV, maxQuests } = nextProps;
    const { filters, selected } = this.state;
    if (filters.users.length !== allUsers.length) {
      this.setState({
        filters: {
          ...filters,
          games,
          users: allUsers,
          accv: {
            min: 0,
            max: maxACCV
          },
          quests: {
            min: 0,
            max: maxQuests
          }
        },
        selected: {
          ...selected,
          accv: {
            min: 0,
            max: maxACCV
          },
          quests: {
            min: 0,
            max: maxQuests
          }
        }
      });
    }
  }

  // Clear all entries from selected.selectedList
  clearSelected(selectedList) {
    const newSelectedList = this.state.selected;
    newSelectedList[selectedList] = [];
    this.setSelected(newSelectedList);
  }

  setSelected = selected => this.setState({ selected, filtersChanged: true });

  setSelectedRange(selectedRange, rangeValue) {
    const newSelectedList = this.state.selected;
    newSelectedList[selectedRange] = rangeValue;
    this.setSelected(newSelectedList);
  }

  toggleFilterShown(filter) {
    const { filterShown, selected, filtersChanged } = this.state;
    if (filterShown === filter) {
      this.setState({ filterShown: false });

      // Tell parent to update if any filters have changed
      if (filtersChanged) this.props.updateFilters(selected);
    } else {
      this.setState({ filterShown: filter });
    }

    this.setState({ filtersChanged: false });
  }

  toggleSelectOption(selectedList, option) {
    const newSelectedList = this.state.selected;

    // Add to or remove option ID from selected list
    let listToUpdate = newSelectedList[selectedList];
    if (listToUpdate.indexOf(option) === -1) {
      listToUpdate = [...listToUpdate, option];
    } else {
      listToUpdate = [...without(listToUpdate, option)];
    }

    // Update selected object and set state
    newSelectedList[selectedList] = listToUpdate;
    this.setSelected(newSelectedList);
  }

  render() {
    const { filterShown, filters, selected } = this.state;
    const { isLoadingInitialData: isLoading } = this.props;
    const classes = classNames({ InsightsFilterBar: true });

    return (
      <>
        {filterShown ? (
          <div
            className="InsightsFilterBarOverlay"
            onClick={() => this.toggleFilterShown(filterShown)}
          />
        ) : null}
        <div id="InsightsFilterBar-container" className="InsightsFilterBar-placeholder">
          <div className={classes}>
            <InsightsFilter
              title="All Games"
              allowSearch
              icon={selected.games.length ? GamesIconSelected : GamesIcon}
              toggleFilterShown={() => this.toggleFilterShown('games')}
              toggleSelectOption={this.toggleSelectOption.bind(this, 'games')}
              clearSelected={this.clearSelected.bind(this, 'games')}
              filterShown={filterShown === 'games'}
              filterType="search"
              selected={selected.games}
              options={filters.games}
              isFiltering={!!selected.games.length}
              isLoading={isLoading}
            />
            <InsightsFilter
              title="All Platforms"
              allowSearch={false}
              icon={selected.consoles.length ? GamingPlatformIconSelected : GamingPlatformIcon}
              toggleFilterShown={() => this.toggleFilterShown('consoles')}
              toggleSelectOption={this.toggleSelectOption.bind(this, 'consoles')}
              clearSelected={this.clearSelected.bind(this, 'consoles')}
              filterShown={filterShown === 'consoles'}
              filterType="search"
              selected={selected.consoles}
              options={filters.consoles}
              isFiltering={!!selected.consoles.length}
              isLoading={false}
            />
            <InsightsFilter
              title="All Regions"
              allowSearch
              icon={selected.regions.length ? RegionIconSelected : RegionIcon}
              toggleFilterShown={() => this.toggleFilterShown('regions')}
              toggleSelectOption={this.toggleSelectOption.bind(this, 'regions')}
              clearSelected={this.clearSelected.bind(this, 'regions')}
              filterShown={filterShown === 'regions'}
              selected={selected.regions}
              filterType="search"
              options={filters.regions}
              isFiltering={!!selected.regions.length}
              isLoading={false}
            />
            <InsightsFilter
              title="All Languages"
              allowSearch
              icon={selected.languages.length ? LanguageIconSelected : LanguageIcon}
              toggleFilterShown={() => this.toggleFilterShown('languages')}
              toggleSelectOption={this.toggleSelectOption.bind(this, 'languages')}
              clearSelected={this.clearSelected.bind(this, 'languages')}
              filterShown={filterShown === 'languages'}
              selected={selected.languages}
              options={filters.languages}
              filterType="search"
              isFiltering={!!selected.languages.length}
              isLoading={false}
            />
            <InsightsFilter
              title="All Genders"
              allowSearch={false}
              icon={selected.genders.length ? GenderIconSelected : GenderIcon}
              toggleFilterShown={() => this.toggleFilterShown('genders')}
              clearSelected={this.clearSelected.bind(this, 'genders')}
              toggleSelectOption={this.toggleSelectOption.bind(this, 'genders')}
              selected={selected.genders}
              options={filters.genders}
              filterShown={filterShown === 'genders'}
              filterType="search"
              isFiltering={!!selected.genders.length}
              isLoading={false}
            />
            <InsightsFilter
              title={isEqual(filters.accv, selected.accv) ? 'All ACCV' : `${selected.accv.min} - ${selected.accv.max}`}
              icon={isEqual(filters.accv, selected.accv) ? ACCVIcon : ACCVIconSelected}
              toggleFilterShown={() => this.toggleFilterShown('accv')}
              clearSelected={this.clearSelected.bind(this, 'accv')}
              filterShown={filterShown === 'accv'}
              selected={selected.accv}
              filterType="range"
              setRangeSelected={this.setSelectedRange.bind(this, 'accv')}
              isFiltering={!isEqual(filters.accv, selected.accv)}
              isLoading={false}
              range={this.state.filters.accv}
            />
            <InsightsFilter
              title={isEqual(filters.quests, selected.quests) ? 'Completed Quests' : `${selected.quests.min} - ${selected.quests.max}`}
              icon={isEqual(filters.quests, selected.quests) ? QuestsIcon : QuestsIconSelected}
              toggleFilterShown={() => this.toggleFilterShown('quests')}
              clearSelected={this.clearSelected.bind(this, 'quests')}
              filterShown={filterShown === 'quests'}
              selected={selected.quests}
              filterType="range"
              setRangeSelected={this.setSelectedRange.bind(this, 'quests')}
              isFiltering={!isEqual(filters.quests, selected.quests)}
              isLoading={false}
              range={this.state.filters.quests}
            />
            <InsightsFilter
              title="All Users"
              allowSearch
              icon={selected.users.length ? UsersIconSelected : UsersIcon}
              toggleFilterShown={() => this.toggleFilterShown('users')}
              clearSelected={this.clearSelected.bind(this, 'users')}
              toggleSelectOption={this.toggleSelectOption.bind(this, 'users')}
              filterShown={filterShown === 'users'}
              selected={selected.users}
              filterType="search"
              justify="right"
              options={filters.users}
              isFiltering={!!selected.users.length}
              isLoading={isLoading}
            />
          </div>
        </div>
      </>
    );
  }
}

export default InsightsFilterBar;
