import React from 'react';
import classNames from 'classnames';
import './CustomCheckbox.scss';

class CustomCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  handleClick() {
    const { disabled } = this.props;
    if (!disabled) {
      this.props.onChange();
    }
  }

  render() {
    const { checked, disabled, status } = this.props;
    const classes = classNames({
      CustomCheckbox: true,
      review: status === 'to-review',
      editable: !disabled,
      completed: checked,
    });

    return (
      <div className={classes} onClick={this.handleClick.bind(this)} />
    );
  }
}

CustomCheckbox.propTypes = {
};

export default CustomCheckbox;
