import React, { useState, useEffect } from "react";
// import { useSelector } from 'react-redux';

import { withStyles, makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { countryNames } from "../../../utils/countryNames";
import { simpleNotification } from "../../../utils/notifications";
import OffPlatformInstructions from "./OffPlatformInstructions";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { slide, fade } from "../../../utils/transitionIndex";

const snackBar = (title, message, level = "error") =>
  simpleNotification({
    level: level,
    title: title,
    autoDismiss: 5,
    position: "bl",
    message: message,
  });

const alphaCountries = Object.entries(countryNames).sort((a, b) => {
  const nameA = a[1];
  const nameB = b[1];
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
});

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
};
const isMobile = window.innerWidth < 700;

const CustomInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: "white !important",
      fontWeight: 400,
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1 1 1",
    border: 0,
    borderRadius: 0,
    "& .MuiTextField-root": {
      borderRadius: 0,
      fontSize: 16,
      color: "white !important",
      width: isMobile ? "80vw !important" : "360px !important",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      // maxWidth: '80vw',
      marginRight: "10px",

      marginTop: isMobile ? theme.spacing(1.5) : theme.spacing(3),
      marginBottom: isMobile ? theme.spacing(3) : theme.spacing(3),

      //   paddingTop: 0,
      //   paddingBottom: 0,
    },
    "& .MuiInputBase-input": {
      borderRadius: 0,
      border: 0,
      padding: 10,
      fontSize: 16,
    },
    "& .MuiSelect-root": {
      maxHeight: 58,
      border: 0,
      color: "white !important",
    },
  },
}));

export default function AdminAddOffPlatformTalentForm(props) {
  // const auth = useSelector(state => state.auth);
  // const dispatch = useDispatch();

  const classes = useStyles({
    formLabel: {
      color: "#000",
      "&.Mui-focused": {
        color: "#000",
      },
    },
  });
  const [country, setCountry] = useState("US");
  const [twitchUsername, setTwitchUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [showLastUser, setShowLastUser] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const { isLoading, user, userCreated, errors } = props.admin.offPlatform;

  const clearForm = () => {
    setCountry("US");
    setTwitchUsername("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPaymentEmail("");
  };

  const submit = async () => {
    if ((email && !validateEmail(email)) || !email) {
      snackBar("Valid Email Required", "Please enter a valid email");
      return null;
    }
    if (!paymentEmail) {
      snackBar(
        "Valid payment email required",
        "please enter a valid payment email"
      );
      return null;
    }
    if (!firstName || !lastName) {
      snackBar("User needs a name", "please enter a full name");
      return null;
    }
    if (!twitchUsername) {
      snackBar(
        "User needs a Twitch Username",
        "please enter user's Twitch username"
      );
      return null;
    }
    const { token } = props.auth;

    props.createOffPlatformUser(
      twitchUsername,
      email,
      paymentEmail,
      firstName,
      lastName,
      token
    );
  };

  useEffect(() => {
    const { hasError, message, code } = errors;
    if (hasError) snackBar(code, message);
    if (userCreated) {
      snackBar(
        "User Added",
        "Your off platform user was successfully created",
        "success"
      );
      setShowLastUser(true);
      clearForm();
    }
  }, [isLoading]);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeTwitchUsername = (event) => {
    setTwitchUsername(event.target.value);
  };
  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleChangePaymentEmail = (event) => {
    setPaymentEmail(event.target.value);
  };

  return (
    <div className="off-platform-form-container">
      <button
        className="instructions-button"
        onClick={() => setShowInstructions(!showInstructions)}
      >
        Instructions
      </button>
      <TransitionGroup>
        {showInstructions && (
          <CSSTransition className="fading-game-label" {...slide(500, 500)} {...fade(400,400)}>
            <OffPlatformInstructions />
          </CSSTransition>
        )}
      </TransitionGroup>

      <form
        className={classes.root}
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <FormControl className={classes.margin}>
          <div className="contact-form-label">NAME IN TWITCH</div>
          <CustomInput
            value={twitchUsername}
            onChange={handleChangeTwitchUsername}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">FIRST NAME</div>
          <CustomInput
            value={firstName}
            required
            onChange={handleChangeFirstName}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">LAST NAME</div>
          <CustomInput
            value={lastName}
            required
            onChange={handleChangeLastName}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">EMAIL</div>
          <CustomInput
            required
            error={!email}
            value={email}
            onChange={handleChangeEmail}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">PAYMENT EMAIL</div>
          <CustomInput
            onChange={handleChangePaymentEmail}
            value={paymentEmail}
            // mask={numberMask}
            InputProps={{ disableUnderline: true }}
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <div className="contact-form-label">COUNTRY</div>
          <TextField
            select
            value={country}
            onChange={handleChangeCountry}
            InputProps={{ disableUnderline: true }}
          >
            {alphaCountries.map((option) => {
              const [code, name] = option;
              return (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </form>
      <div className="offplatform-button-group">
        {isLoading && (
          <div className="Store__loading">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          </div>
        )}
        {!isLoading && (
          <div>
            <button onClick={() => submit()} className="brand-rec-button">
              Submit
            </button>
          </div>
        )}

        <button
          onClick={() => clearForm()}
          className="brand-rec-button"
          style={{
            background: "transparent",
            outline: "1px solid grey",
            "&hover": { background: "red" },
          }}
        >
          Clear
        </button>
      </div>
     <TransitionGroup>
      {showLastUser && (
                  <CSSTransition  {...fade(400,400)}>

        <div className="latest-added-user">
          <div> Recently Added User: </div>
          <div>
            <div>Noiz Username: </div> <div>{user?.username}</div>
          </div>
          <div>
            <div>Twitch Username: </div>
            <div> {user?.connectedAccounts?.twitch?.username}</div>
          </div>
          <div>
            <div>First Name: </div> <div>{user?.firstName}</div>
          </div>
          <div>
            <div>Last Name: </div>
            <div> {user?.lastName}</div>
          </div>
          <div>
            <div>Email: </div>
            <div>{user?.email} </div>
          </div>
          <div>
            <div>AVG30:</div>
            <div>{user?.connectedAccounts?.twitch?.avg30}</div>{" "}
          </div>
        </div>
        </CSSTransition>
      )}
      </TransitionGroup>
    </div>
  );
}
