
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import Tooltip from '@material-ui/core/Tooltip';

export const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const linkStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

// export const CheckBox = (props) => {
//   const { passUp, item, selectAll } = props;
//   const [checked, setChecked] = useState(false);

//   const handleChange = (event) => {
//     passUp(item, event.target.checked);
//     setChecked(event.target.checked);
//   };

//   useEffect(() => {
//     // Update the document title using the browser API
//     if (selectAll && selectAll.selected && !checked) {
//       setChecked(true);
//     } else if (selectAll && !selectAll.selected && checked) {
//       setChecked(false);
//     }
//   });

//   return (<Checkbox
//     checked={checked}
//     onChange={handleChange}
//     inputProps={{ 'aria-label': 'primary checkbox' }}
//   />);
// };

// export const SelectAllCheckBox = (props) => {
//   const { callBack, tab } = props;
//   const [checked, setChecked] = useState(false);
//   const [oldTab, setOldTab] = useState(false);

//   const handleChange = (event) => {
//     callBack(event.target.checked);
//     setChecked(event.target.checked);
//   };

//   useEffect(() => {
//     if (oldTab !== tab) {
//       callBack(false);
//       setChecked(false);
//       setOldTab(tab);
//     }
//   });

//   return (
//     <Tooltip title="Selects ALL in group - not just visible on page">
//       <FormControlLabel
//         style={{ color: 'white' }}
//         control={<Checkbox
//           checked={checked}
//           onChange={handleChange}
//           inputProps={{ 'aria-label': 'primary checkbox' }}
//         />}
//         label="Select All"
//       />
//     </Tooltip>
//   );
// };

export const LinkTabs = (props) => {
  const classes = linkStyles();
  const [value, setValue] = useState(0);
  const {
    tabList, Table,
  } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabList.map((t, i) => (<Tab label={t.name} key={i} {...a11yProps(i)} />))}
        </Tabs>
      </AppBar>
      {tabList.map((t, i) => (
        <TabPanel value={value} key={i} index={i}>
          <Table {...props} dataKey={i} />
        </TabPanel>))}
    </div>
  );
};
