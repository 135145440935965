import React from 'react';
import anime from 'animejs';

import './Loading.scss';
import NoizLoader from '../../../src/components/images/noizloader.mp4';

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      animating: false,
      active: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const _this = this;
    const { animating, active } = this.state;

    // When loading is complete, fade off
    if (!nextProps.isLoading && active && !animating) {
      if (document.getElementById('NoizLoader')) {
        anime({
          targets: '#NoizLoader',
          opacity: 0,
          duration: 500,
          delay: 2000,
          easing: 'linear',
          begin(anim) {
            _this.setState({
              animating: true,
            });
          },
          complete(anim) {
            if (document.getElementById('NoizLoader')) {
              document.getElementById('NoizLoader').style.display = 'none';
              _this.setState({
                animating: false,
                active: false,
              });
            }
          },
        });
      } else {
        anime({
          targets: '#NoizLoader',
          opacity: 0,
          duration: 500,
          delay: 2000,
          easing: 'linear',
          begin(anim) {
            _this.setState({
              animating: true,
            });
          },
          complete(anim) {
            _this.setState({
              animating: false,
              active: false,
            });
          },
        });
      }
    }
  }

  render() {
    return (
      <div className="NoizLoader" id="NoizLoader">
        <video playsInline loop autoPlay muted>
          <source type="video/mp4" src={NoizLoader} />
        </video>
      </div>
    );
  }
}

Loading.propTypes = {
};

export default Loading;
