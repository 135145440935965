/* eslint-disable */
import React from 'react';
// import PropTypes from 'prop-types'
import './static.css';

class DnlReferralSalesAgreement extends React.Component {
  render() {
    return (
      <section className="PrivacyPolicy static">
    <h1>Dark and Light “Referral Sales” - Sales Agreement
    </h1>
    <h2>
NOIZ “The Company” deliverables: </h2>
<ul>
<li>NOIZ will provide a Steam game key via Noiz.gg platform after login and agreement to Terms of Use.
</li><li>Referral sales will be tracked via Noiz.gg landing page and a unique tracking link given to each user.
</li><li>Referral commission of each game sale of “Dark and Light” will be $3 and PAID via PayPal - NET30 (provided “the User” enters correct information on Noiz.gg platform).
</li><li>At the end of agreement, we will request feedback and evaluate for ongoing interest.
</li>
</ul>
<h2>
STREAMER “Noiz User” deliverables:
</h2>
<ul>
<li>The streamer agrees to login via Noiz.gg and stream the game via Twitch.tv within 3 days of receiving a game key.
</li><li>Streamer agrees to post referral link to any personal media to remain on the “Quest” for Noiz.gg.
</li><li>Streamer agrees to Noiz.gg’s “Referral Sales - Terms and Conditions”
</li>
</ul>
<h2>
IMPORTANT NOTICES: 
</h2>
<strong>Failure to Deliver:</strong> If the STREAMER does not complete the required quest deliverable: Noiz.gg has the right to revoke the game key if the user has not streamed the game within 3 days of receiving the game on Twitch.tv or posted the referral link on any media.
<br />
<br />
<strong>Cancellation policy:</strong> Noiz.gg and our partnering clients reserve the right to cancel any quest or reward given if the User does not act within “Terms of Use” and/or attempts to sell the Game Key to an unintended user.

</section>
    );
  }
}

export default DnlReferralSalesAgreement;
