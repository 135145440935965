/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const flatModal = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
  },
  paper: {
    backgroundColor: '#212227',
    boxShadow: theme.shadows[5],
    // padding: '40px',
    color: 'white',
    maxWidth: '50%',
  },
  topMast: {
    backgroundColor: '#313337',
    padding: '20px 40px',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: '40px 40px',
    marginBottom: '20px',
  },
  mainFields: {
    marginBottom: '20px',
  },
  textField: {
    width: 200,
  },
  errorButton: {},
  confirmButton: {
    backgroundColor: 'green',
  },
  buttonGrid: {
    width: '100%',
    marginTop: '30px',
    marginBottom: '25px',
  },
  inputBox: {
    // minHeight: '100px',
    // height: '200px',

    lineHeight: 2,
  },
  warning: {
    color: 'red',
    marginTop: '20px',
    // width: '20%',
  },
  topMargin: {
    marginTop: '30px',
  },
}));

export const basicModal = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  topMast: {
    backgroundColor: '#313337',
    padding: '20px 40px',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: '40px 40px',
  },
  textField: {
    width: 200,
  },
  errorButton: {},
  confirmButton: {
    backgroundColor: 'green',
  },
  buttonGrid: {
    width: '100%',
    marginTop: '30px',
  },
  inputBox: {
    // minHeight: '100px',
    // height: '200px',

    lineHeight: 2,
  },
  warning: {
    color: 'red',
    marginTop: '20px',
    // width: '20%',
  },
  root: {
    // width: '100%',
    width: 360,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 300,
  },
}));
