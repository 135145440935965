import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';

import Back from '../shared/Back';
import Loading from '../loading/Loading';
import QuestParticipationAgreement from '../static-pages/QuestParticipationAgreement';
import { simpleNotification } from '../../utils/notifications';
import { debounce } from '../../utils/functions';

import './OffplatformQuest.scss';

const OffplatformQuest = (props) => {
  const {
    config: {
      quest: {
        data: quest,
        isLoading: isLoadingQuest,
        isJoiningToAQuest,
        errors: {
          hasError,
          message,
        },
      },
      userQuestReferral: {
        data: userQuestReferral,
      },
    },
    router: { params: { questSlug, userId } },
    getQuestBySlugGuest,
    joinQuestOffplatform,
  } = props;

  const debounceJoin = debounce(joinQuestOffplatform, 1000);
  useEffect(() => {
    if (hasError) {
      simpleNotification({
        level: 'error',
        title: 'Error',
        message,
        // autoDismiss: 7,
      });
    }
  
  }, [hasError])
  
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    getQuestBySlugGuest(questSlug, userId);
  }, []);

  useEffect(() => { }, [isLoadingQuest]);

  let opuqstatus;
  
  if (userQuestReferral && userQuestReferral.length) {
    const foundUser = userQuestReferral.find(uqr => uqr.quest === quest.id && uqr.user === userId);
    if (foundUser) {
      opuqstatus = foundUser.status;
    }
  } else if (quest && quest.offplatformUserQuest) {
    opuqstatus = quest.offplatformUserQuest.status;
  }

  let renderContent;

  switch (opuqstatus) {
    case 'approved':
      renderContent = (
        <div className="OffplatformQuest_Back">
          Thank you for joining this quest.
          <Back />
        </div>
      );
      break;
  
    case 'leftQuest':
      renderContent = (
        <div className="OffplatformQuest_Back">
          You denied joining this quest.
          <Back />
        </div>
      );
      break;
  
    case 'pending':
      const submitButtonClass = accepted ? 'CTAButton__purple button-hover-glow' : 'CTAButton__lightGrey disabled';

      renderContent = (
        <div>
          <div className="agreementCopy">
            <QuestParticipationAgreement />
          </div>
          <div className="checkbox-container-questmodal" style={{ 'justify-content': 'center', display: 'flex', 'flex-direction': 'column' }}>
            <div style={{ display: 'flex', 'justify-content': 'space-between', 'align-items': 'center' }}>
              <div>I agree to the above terms and conditions</div>
              <div>
                <Checkbox
                  checked={accepted}
                  onChange={() => setAccepted(!accepted)}
                />
              </div>
            </div>
            <button
              className={submitButtonClass}
              disabled={!accepted}
              onClick={() => debounceJoin(quest.id, userId)}
              style={{
                'min-width': '150px',
                padding: '0 10px',
              }}
            >
              Join Quest
            </button>
          </div>
        </div>
      );
      break;
    
    default:
      renderContent = (
        <div className="OffplatformQuest_Back">
          No user found or not added to this Quest.
          <Back />
        </div>
      );
  }

  return (
    <div className="OffplatformQuest">
      <Loading isLoading={isLoadingQuest && !isJoiningToAQuest} />
      {isJoiningToAQuest && <i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
      {!isLoadingQuest && !isJoiningToAQuest && renderContent}
    </div>
  );
};

export default OffplatformQuest;
