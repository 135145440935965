import React from 'react';

import { BrandUserContext } from "../PortalContext";

import BrowserBack from '../../../../global/components/browser-back/BrowserBack';
// import Discover from './Discover/Discover';
import Masthead from './Masthead';
import ProductDetail from './ProductDetail/ProductDetail';
import ProductEdit from './ProductEdit/ProductEdit';
import Products from './Products';
import QuestCampaignDisplay from './QuestCampaignView/QuestCampaignDisplay';
import QuestDetail from './QuestDetail/QuestDetail';
import QuestEdit from './QuestEdit/QuestEdit';
import ReviewDeliverable from './ReviewDeliverable/ReviewDeliverable';
import StreamerProfile from './StreamerProfile/StreamerProfile';
// import SubscriptionQuests from '../PortalLeft/SubscriptionQuests';
import ViewAll from './ViewAll/ViewAll';
import Profile from './Profile/Profile';

import './PortalLeft.scss';

const PortalLeft = (props) => {
  const {
    view,
    view: {
      component,
      currentProduct,
      currentQuest,
      currentUser,
      currentUserQuest,
      tab,
    },
    deliverables,
    products,
    productsLoading,
    quests,
    questsLoading,
    // subscriptionQuests,
    // campaigns,
    // auth
    // campaigns,
  } = props;

  const masthead = quests?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.[0] || {};

  const HomeLeftDefault = () => (
    <>
      <Masthead quest={masthead} questsLoading={questsLoading} />
      <Products products={products} productsLoading={productsLoading} />
      {/* <SubscriptionQuests quests={quests} /> */}
    </>
  );

  return (
    <BrandUserContext.Consumer>
      {consumer => (
        <div className="PortalLeft">
          {tab === 'home' && component === 'default' && <HomeLeftDefault />}
          {tab === 'quests' && component === 'default' && <QuestCampaignDisplay quests={quests} {...consumer} />}
          {/* {tab === 'discover' && component === 'default' && <Discover view={view} {...consumer} />} */}

          {component !== 'default' && <BrowserBack margin={{ marginBottom: '12px' }} text='Back' />}
          {component === 'Profile' && <Profile currentUser={currentUser} {...consumer} tab={tab} />}

          {component === 'ProductEdit' && <ProductEdit currentProduct={currentProduct} {...consumer} tab={tab} />}
          {component === 'ProductDetail' && <ProductDetail currentProduct={currentProduct} {...consumer} tab={tab} />}
          {component === 'QuestDetail' && <QuestDetail currentQuest={currentQuest} {...consumer} tab={tab} />}
          {component === 'QuestEdit' && <QuestEdit currentProduct={currentProduct} currentQuest={currentQuest} {...consumer} tab={tab} />}
          {component === 'ReviewDeliverable' && <ReviewDeliverable currentUserQuest={currentUserQuest} {...consumer} tab={tab} />}
          {component === 'StreamerProfile' && <StreamerProfile currentUser={currentUser} {...consumer} tab={tab} />}

          {component === 'ViewAllApplicants' && <ViewAll {...consumer} tab={tab} type='Applicants' view={view} />}
          {component === 'ViewAllCampaigns' && <ViewAll {...consumer} tab={tab} type='Campaigns' view={view} />}
          {component === 'ViewAllDeliverables' && <ViewAll {...consumer} tab={tab} type='Deliverables' deliverables={deliverables} view={view} />}
          {component === 'ViewAllQuests' && <ViewAll {...consumer} tab={tab} type='Quests' view={view} />}
          {component === 'ViewAllProducts' && <ViewAll {...consumer} tab={tab} type='Products' view={view} />}
        </div>
      )}
    </BrandUserContext.Consumer>
  );
};

export default PortalLeft;
