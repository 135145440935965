import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import shortid from 'shortid';


import './AdminImportUsers.scss';

class AdminImportUsers extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      dropzoneActive: false,
    };
  }

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    // Step 1. Files were selected or droped
    this.setState({ dropzoneActive: false });
    const final = files && files.length ? files[0] : e[0];

    const { token } = this.props.auth;
    const formData = new FormData();
    formData.append('file', final);
    // Upload the file
    this.props.uploadCSV(formData, token);
  }

  render() {
    const { isLoading, data: csvUploadData } = this.props.admin.csvUpload;
    const { dropzoneActive } = this.state;
    let dropzoneRef;

    return (
      <section className="AdminImportUsers">
        <h1 className="title">CSV Import Tool</h1>
        <Dropzone
          ref={(node) => { dropzoneRef = node; }}
          disableClick="disableClick"
          className="Dropzone"
          style={{}}
          onDrop={(e, event) => this.onDrop(event, e)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          disablePreview={false}
          accept="text/csv"
          multiple={false}
          name="file"
          inputProps={{ id: 'file' }}
        >
          { dropzoneActive && <div className="Dropzone__DropLayer">Drop here your CSV file...</div> }
          <button className="FilePickButton" type="button" onClick={() => { dropzoneRef.open(); }}>
            <div className="FilePickButton__icon-container">
              <i className="fa fa-cloud-upload" />
            </div>
            <div className="FilePickButton__info">
              <h3>Drop here your CSV file...</h3>
              <div>
                or
                <span className="link-style">pick one</span>
                clicking here.
              </div>
            </div>
          </button>
        </Dropzone>

        { isLoading && (<section>Loading...</section>) }

        {(!isLoading &&
          !csvUploadData.errors.hasError &&
          !isEmpty(csvUploadData.errors) &&
          csvUploadData.errors.total > 0) &&
          <section className="UploadErrors">
            {
              csvUploadData.errors.data.map(errorItem => (
                <li key={shortid.generate()} className="error-item">
                  <span className="email">{errorItem.row.email}</span>
                  <span className="username">{errorItem.row.username}</span>
                  <span className="firstName">{errorItem.row.firstName}</span>
                  <span className="lastName">{errorItem.row.lastName}</span>
                  <span className="gameKey">{errorItem.row.gameKey}</span>
                  <span className="contractId">{errorItem.error.contractId}</span>
                  <span className="message">{errorItem.error.message}</span>
                </li>))
            }
          </section>}

        {(!isLoading &&
          !csvUploadData.errors.hasError &&
          !isEmpty(csvUploadData.creations) &&
          csvUploadData.creations.total > 0) &&
          <section className="UploadCreations">
            {
              csvUploadData.creations.data.map(creationItem => (
                <li key={shortid.generate()} className="error-item">
                  <span className="email">{creationItem.data.agreement.user.email}</span>
                  <span className="username">{creationItem.data.agreement.user.username}</span>
                  <span className="firstName">{creationItem.data.agreement.user.firstName}</span>
                  <span className="lastName">{creationItem.data.agreement.user.lastName}</span>
                  <span className="gameKey">{creationItem.data.agreement.gameKey.keycode}</span>
                  <span className="contractId">{creationItem.data.agreement.contract.id}</span>
                  <span className="message">{creationItem.status.message}</span>
                  <span className="wasOnWaitList">
                    prev status: { creationItem.data.wasOnWaitList ? 'on wait list' : 'N/A new user' }
                  </span>
                </li>))
             }
          </section>}

        {(!isLoading && csvUploadData.errors.hasError) &&
          <section className="UploadErrors">
            csvUploadData.errors.message
          </section>}
      </section>
    );
  }
}


AdminImportUsers.propTypes = {
  admin: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    csvUpload: PropTypes.shape({
      data: PropTypes.array.isRequired,
      isLoading: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  uploadCSV: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default AdminImportUsers;
