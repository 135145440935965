import React from "react";
import ChartStatBox from "../../StatBoxes/ChartStatBox";
import { TopPerformingTweets } from "../../StatBoxes/TopPerformingTweets";
// import QuadStatBox from "../../StatBoxes/QuadStatBox";
// import SmallStatBox from "../../StatBoxes/SmallStatBox";

export const ExtraChatContent = ({ data = [], titles = [] }) => {
  return (
    <>
      {data.map((item, index) => {
       return (<ChartStatBox key={index} data={item} title={titles[index]} />);
      })}
      {/* <QuadStatBox /> */}
      {/* <SmallStatBox /> */}
    </>
  );
};


export const ExtraTwitterContent = ({ data = [], titles = [] }) => {

   return (<TopPerformingTweets data={data}/>)
}
