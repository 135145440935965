import React from 'react';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';

import { basicModal } from '../../../../utils/material_ui/Styles';

const SelectPlatform = (props) => {
  const classes = basicModal();
  const {
    isLoading,
    platforms,
    platformsSelected,
    setNext,
    setPlatformsSelected,
  } = props;

  const handleToggle = value => () => {
    const currentIndex = platformsSelected.indexOf(value);
    const newChecked = [...platformsSelected];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setPlatformsSelected(newChecked);
  };

  const grouped = platforms.reduce((acc, pl) => {
    if (!acc[pl.baseService]) {
      acc[pl.baseService] = [];
    }
    acc[pl.baseService].push(pl);
    return acc;
  }, {});

  const platformsHTML = Object.keys(grouped).map(key => (
    <>
      <ListSubheader disableSticky>{key}</ListSubheader>
      {grouped[key].map(p => (
        <ListItem key={p.id} dense button onClick={handleToggle(p)}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={platformsSelected.indexOf(p) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `checkbox-list-label-${p.id}` }}
            />
          </ListItemIcon>
          <ListItemText id={`checkbox-list-label-${p.id}`} primary={p.platform} />
        </ListItem>
      ))}
    </>
  ));

  return (
    <div>
      <div className={classes.root}>
        {isLoading && <i className="fa fa-spinner fa-pulse" />}
        {!isLoading && <List>{platformsHTML}</List>}
      </div>
      <Button variant="contained" disabled={platformsSelected.length <= 0} onClick={() => setNext(true)} style={{ marginTop: 10 }}>
        Next
      </Button>
    </div>
  );
};

export default SelectPlatform;
