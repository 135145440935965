import { createContext } from 'react';

export const SetViewContext = createContext({
  setView: () => console.log('default setview'),
  view: {},
});

export const defaultBaseView = {
  component: 'default',
  currentProduct: '',
  currentQuest: '',
  currentUser: '',
  tab: 'home',
};

export const BrandUserContext = createContext({
  campaigns: {},
  campaign: {},
});
