import React from 'react';
import Raven from 'raven-js';
import classNames from 'classnames';
import { Link } from 'react-router';
import SignInForm from '../../forms/SignInForm';
import '../login.scss';
import './SignIn.scss';
import { throttle } from '../../../utils/functions';
import {pullRedirectParamAndRedirect} from '../../../utils/util';


class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.throtSignIn = throttle(this.props.signIn, 3000);
    this.state = {
      showingErrors: false,
      errors: {
        hasError: false,
        code: '',
        message: '',
        all: {},
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.isLoading === true && nextProps.auth.isLoading === false) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.errors.hasError) {
        this.setState({
          showingErrors: true,
          errors: nextProps.auth.errors,
        });
      } else if (this.props.auth.isAuthenticated !== nextProps.auth.isAuthenticated) {
        Raven.setUserContext({
          email: nextProps.auth.user.email,
          id: nextProps.auth.user.id,
          username: nextProps.auth.user.username,
          role: nextProps.auth.user.role,
          status: nextProps.auth.user.status,
        });

        Raven.captureMessage('Login', {
          level: 'info', // one of 'info', 'warning', or 'error'
        });
        // Getting the initial info with the obtained token.
        const { token } = nextProps.auth;
        this.props.getInitialInfo(token);
        // Redirecting user to the game list
        // firstname, lastname, birthday, country
        if (
          nextProps.auth.user.firstName !== '' &&
          nextProps.auth.user.lastName !== '' &&
          nextProps.auth.user.timezone !== '' &&
          nextProps.auth.user.birthDate !== '' &&
          nextProps.auth.user.gender !== '' &&
          nextProps.auth.user.country !== ''
        ) {
          if (this.props.router?.location?.query?.redirectto) {
            pullRedirectParamAndRedirect(this.props.router);
          } else {
            this.props.router.replace('/quests');
          }
        } else {
          this.props.router.replace('/complete-registration');
        }
      }
    }
  }

  onSubmit(payload) {
    this.throtSignIn(payload);
  }

  dismissErrors = () => {
    this.setState({
      showingErrors: false,
      errors: {
        hasError: false,
        code: '',
        message: '',
        all: {},
      },
    });
  }

  render() {
    const { showingErrors, errors } = this.state;
    return (
      <section className="SignIn LogIn">
        <section className="ModalMaster">
          <section className={classNames('Modal active')}>
            <Link to="/" href="/" className="Modal__layer" role="button" aria-hidden />
            <section className="Modal__wrapper">
              <div className="Modal__left">
                <div className="logo" />
                <span className="blurb">
                  Designed by game developers and gamers, for game developers and gamers
                </span>
                <div className="shapes-container">
                  <div className="box box1" />
                  <div className="box box2" />
                </div>
              </div>
              <div className="Modal__right">
                <Link
                  className="Modal__close-btn"
                  to="/"
                  href="/"
                  role="button"
                  aria-hidden
                />
                <div className="Modal__content">
                  <ul className="Modal__tabs">
                    <li>
                      <Link
                        className="Modal__tab"
                        to="/sign-up"
                        href="/sign-up"
                        role="button"
                        aria-hidden
                        activeClassName="active"
                      >
                        Sign Up
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="Modal__tab"
                        to="/sign-in"
                        href="/sign-in"
                        role="button"
                        aria-hidden
                        activeClassName="active"
                      >
                        Sign In
                      </Link>
                    </li>
                  </ul>

                  <section className={classNames('errors-container', { active: showingErrors })}>
                    <button className="error-dismiss" type="button" onClick={this.dismissErrors.bind(this)} />
                    <div className="error-message">
                      { errors.message }
                    </div>
                  </section>

                  <SignInForm auth={this.props.auth} onSubmit={this.onSubmit.bind(this)} />
                  <div className="Modal__forgot">
                    <Link
                      className="Modal__tab"
                      to="/recover"
                      href="/recover"
                      role="button"
                      aria-hidden
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

SignIn.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
};

export default SignIn;
