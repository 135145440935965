import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import './Carousel.scss';

const defaultOnClick = (index, item) => console.log(index, item);

const Carousel = (props) => {
  const {
    autoPlay = false,
    centerMode = true,
    customStyle = {},
    infiniteLoop = true,
    interval = 10000,
    items = [],
    onClickItem = defaultOnClick,
    // renderDefaultTitles = false,]
    // height = 'auto',
    showArrows = false,
    showStatus = false,
    centerSlidePercentage = 80,
    width = '90%',
    screenBreak = 1024,
    smallCenterSize = 50,
    largeCenterSize = 75,
    // showLearnMore = true,
    // baseCSS = false,
  } = props;

  const [centSlideSize, setCentSlideSize] = useState(centerSlidePercentage);

  useEffect(() => {
    const winWidth = window.innerWidth;
    if (centerMode) {
      if (winWidth <= screenBreak) {
        setCentSlideSize(largeCenterSize);
      } else if (winWidth >= screenBreak) {
        setCentSlideSize(smallCenterSize);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  if (items.length <= 0) { return null; }

  return (
    <ResponsiveCarousel
      autoPlay={autoPlay}
      centerMode={centerMode}
      infiniteLoop={infiniteLoop}
      interval={interval}
      onClickItem={onClickItem}
      showArrows={showArrows}
      showStatus={showStatus}
      style={customStyle}
      width={width}
      centerSlidePercentage={centSlideSize}
    >
      { items }
    </ResponsiveCarousel>
  );
};

export default Carousel;
