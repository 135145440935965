
import React from 'react';
import AdminTabs from '../util/tabs/AdminTabs';
import './AdminUserContainer.scss';
import AdminUsersTab from './AdminUsersIndex';


export default function AdminUserContainer(props) {
  return (
    <div className="admin-users-container">
      <AdminTabs {...props} />
      <AdminUsersTab {...props} />
    </div>
  );
}
