import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  TextField,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';

import { debounce } from '../../../utils/functions';

import './AddGameModal.scss';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const pingTwitchGames = (searchValue, headers, setFunc) => {
  axios
    .get(`/api/v1/admin/game/search/${searchValue.trim()}`, headers)
    .then((resp) => {
      setFunc(resp.data.games);
    })
    .catch(err => console.error(err));
};

const debouncePingingTwitchGames = debounce(pingTwitchGames, 1000);

const AddGameModal = (props) => {
  const classes = useStyles();

  const { handleAddSelectedGame, token, values } = props;

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [availableGames, setAvailableGames] = useState([]);


  const headers = { headers: { Authorization: `JWT ${token}` } };

  useEffect(() => {
    if (searchValue && searchValue.length > 1) debouncePingingTwitchGames(searchValue, headers, setAvailableGames);
    else setAvailableGames([]);
  }, [searchValue]);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchValue('');
    setAvailableGames([]);
    setOpen(false);
  };

  const handleGameClick = (game) => {
    handleAddSelectedGame(game);
    setAvailableGames(availableGames.filter(g => g !== game));
  };

  const handleTextChange = (e) => {
    setSearchValue(e.target.value);
  };

  const renderAvailableGames = availableGames.filter(g => !values.includes(g)).map(g => (
    <ListItem key={g} id={g} button onClick={() => handleGameClick(g)}>
      <ListItemIcon>
        <AddBoxIcon />
      </ListItemIcon>
      <ListItemText primary={g} />
    </ListItem>
  ));

  const AddModal = (
    <div id="AddModal" style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Add{values && values.length ? ' More' : ''} Required Games</h2>
      <div id="simple-modal-description" className="AddGameModal--list">
        <Paper style={{ height: 200, overflow: 'auto' }}>
          <List>
            {renderAvailableGames}
          </List>
        </Paper>
      </div>
      <TextField
        className="AddGameModal--textfield"
        label="Game Name"
        onChange={handleTextChange}
        value={searchValue}
        variant="filled"
      />
      <Button onClick={handleClose} className="AddGameModal--close">
        Close
      </Button>
    </div>
  );

  return (
    <div className="AddGameModal">
      <Button variant="contained" onClick={handleOpen} color="primary" className="AddGameModal--button">
        Add More Required Games
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {AddModal}
      </Modal>
    </div>
  );
};

export default AddGameModal;
