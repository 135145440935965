import React from 'react';
import Modal from '@material-ui/core/Modal';
import './BrandServiceSlides.scss';
import CancelIcon from '@material-ui/icons/Cancel';


export default function SimpleModal(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render

  const { open = false, handleClose = () => null, src = 'https://www.youtube.com/embed/dQw4w9WgXcQ' } = props;


  const body = (
    <div onClick={handleClose} className="brand-iframe-container" >
      <div>
        <iframe
          frameBorder="0"
          title="Influencer Marketing"
          src={src}
          className="brand-iframe"
        />
        <CancelIcon className="exit" onClick={handleClose} />

      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        className="brand-lightbox"
      >
        {body}
      </Modal>
    </div>
  );
}
