import React from "react";

import "./IntercomPortal.scss";

const IntercomPortal = ({
  user,
  email = "",
  username = "",
  children,
  renderDiv = false,
}) => {
  const activateIntercom = () => {
    window.Intercom("show", {
      app_id: "ito0ceux",
      email,
      name: username,
    });
  };
  return (
    <>
      {renderDiv && (
        <div className='IntercomPortal_Main' onClick={activateIntercom}>
          {children}
        </div>
      )}
      {!renderDiv && (
        <span className='IntercomPortal_Main' onClick={activateIntercom}>
          {children}
        </span>
      )}
    </>
  );
};

export default IntercomPortal;
