import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { filter } from "lodash";
import { Link } from "react-router";
import "./CommandCenter.scss";
import AdminTabs from "../util/tabs/AdminTabs";
import AnalyticsPull from "./AnalyticsPull";
// import Toggle from "../../../global/components/toggle/Toggle";
import { Tooltip } from "@material-ui/core";

// import { isAuthenticated } from '../../utils/authChecker';

class CommandCenter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      inRoot: this.props.router.location.pathname === "/admin/command-center",
      showArchivedGames: false,
      showDisabledGames: false,
    };
  }

  UNSAFE_componentWillMount() {
    // TODO: Get list of Games
    const { token } = this.props.auth;
    this.props.getCCGameList(token, {});
  }

  render() {
    const { showArchivedGames, showDisabledGames } = this.state;
    const { data: games = [], isLoading } = this.props.commandCenter.games;
    const { gameId } = this.props.router.params;
    const activeGames = filter(games, x => x.status === "active");
    const archivedGames = filter(games, x => x.status === "archived");
    const disabledGames = filter(games, x => x.status === "disabled");

    return (
      <section className={classNames("CommandCenter", { inRoot: this.state.inRoot })}>
        <section className='CommandCenter__wrapper'>
          <AdminTabs {...this.props} />
          <h2 className='CommandCenter__title'>Command Center</h2>
          <section className='CommandCenter__container'>
            <section className='GameList'>
              <h3 className='GameList__title'>
                Games
              </h3>

              <section className='GameList__wrapper'>
                {activeGames && activeGames.length > 0 && !isLoading && (
                  <ul className='GameList__items'>
                    {activeGames.map(game => (
                      <li
                        key={game.id}
                        className={classNames("GameList__GameItem", {
                          active: gameId === game.id,
                        })}>
                        {game.owner && (<>
                          <Tooltip title="Brand" placement="top">
                            <i className='fa fa-black-tie' />
                          </Tooltip>
                          &nbsp;
                        </>
                        )}
                        <Link
                          to={`/admin/command-center/${game.id}/quests`}
                          activeClassName='active'
                          href={`/admin/command-center/${game.id}/quests`}>
                          {game.name}
                        </Link>
                        <Link
                          className='link-out'
                          to={`/admin/games/edit/${game.id}`}
                          href={`/admin/command-center/${game.id}/quests`}
                          target='_blank'>
                          <i className='fa fa-external-link' />
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
                {archivedGames && archivedGames.length > 0 && !isLoading && (
                  <ul className='GameList__items'>
                    <li
                      className='GameList__label'
                      onClick={() => {
                        this.setState({ showArchivedGames: !showArchivedGames });
                      }}
                      aria-hidden>
                      Archived ({archivedGames.length})
                      <i
                        className={classNames("fa", {
                          "fa-plus": !showArchivedGames,
                          "fa-minus": showArchivedGames,
                        })}
                      />
                    </li>
                    {showArchivedGames &&
                      archivedGames.map(game => (
                        <li
                          key={game.id}
                          className={classNames("GameList__GameItem", {
                            active: gameId === game.id,
                          })}>
                          <Link
                            to={`/admin/command-center/${game.id}/quests`}
                            activeClassName='active'
                            href={`/admin/command-center/${game.id}/quests`}>
                            {game.name}
                          </Link>
                          <Link
                            className='link-out'
                            to={`/admin/games/edit/${game.id}`}
                            href={`/admin/command-center/${game.id}/quests`}
                            target='_blank'>
                            <i className='fa fa-external-link' />
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
                {disabledGames && disabledGames.length > 0 && !isLoading && (
                  <ul className='GameList__items'>
                    <li
                      className='GameList__label'
                      onClick={() => {
                        this.setState({ showDisabledGames: !showDisabledGames });
                      }}
                      aria-hidden>
                      Disabled ({disabledGames.length})
                      <i
                        className={classNames("fa", {
                          "fa-plus": !showDisabledGames,
                          "fa-minus": showDisabledGames,
                        })}
                      />
                    </li>
                    {showDisabledGames &&
                      disabledGames.map(game => (
                        <li
                          key={game.id}
                          className={classNames("GameList__GameItem", {
                            active: gameId === game.id,
                          })}>
                          <Link
                            to={`/admin/command-center/${game.id}/quests`}
                            activeClassName='active'
                            href={`/admin/command-center/${game.id}/quests`}>
                            {game.name}
                          </Link>
                          <Link
                            className='link-out'
                            to={`/admin/games/edit/${game.id}`}
                            href={`/admin/command-center/${game.id}/quests`}
                            target='_blank'>
                            <i className='fa fa-external-link' />
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}

                {!isLoading && <AnalyticsPull auth={this.props.auth} />}

                {isLoading && (
                  <section className='loading'>
                    <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                  </section>
                )}
                {!games.length && !isLoading && (
                  <section className='no-game'>
                    There is no game registered on noiz platform.
                  </section>
                )}
              </section>
            </section>
            <section className='CommandCenter__child'>
              {gameId && (
                <section className='CommandCenter__navigation'>
                  {/* <Link
                    onlyActiveOnIndex
                    to={`/admin/command-center/${gameId}`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}`}
                  >
                    Game Summary
                  </Link> */}

                  <Link
                    to={`/admin/command-center/${gameId}/white-list`}
                    activeClassName='active'
                    href={`/admin/command-center/${gameId}/white-list`}>
                    White List
                  </Link>

                  <Link
                    to={`/admin/command-center/${gameId}/keys`}
                    activeClassName='active'
                    href={`/admin/command-center/${gameId}/keys`}>
                    Keys
                  </Link>

                  <span
                    className={classNames({ active: window.location.pathname.includes("quests") })}>
                    <Link
                      to={`/admin/command-center/${gameId}/quests`}
                      activeClassName='active'
                      href={`/admin/command-center/${gameId}/quests`}>
                      Quests
                    </Link>
                  </span>

                  <span
                    className={classNames({
                      active: window.location.pathname.includes("campaigns"),
                    })}>
                    <Link
                      to={`/admin/command-center/${gameId}/campaigns`}
                      activeClassName='active'
                      href={`/admin/command-center/${gameId}/campaigns`}>
                      Campaigns
                    </Link>
                  </span>

                  <Link
                    to={`/admin/command-center/${gameId}/links`}
                    activeClassName='active'
                    href={`/admin/command-center/${gameId}/links`}>
                    Links
                  </Link>
                </section>
              )}
              {this.props.children && this.props.children.props.children
                ? React.cloneElement(this.props.children.props.children, this.props)
                : "Select a Game in the left bar to show its report"}
            </section>
          </section>
        </section>
      </section>
    );
  }
}

CommandCenter.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  // auth: PropTypes.shape({
  //   token: PropTypes.string.isRequired,
  //   user: PropTypes.shape({
  //
  //   }).isRequired,
  // }).isRequired,
  // user: PropTypes.shape({
  //   currentAgreement: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  //   currentScheduledBroadcast: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
  // children: PropTypes.element.isRequired,
};

export default CommandCenter;
