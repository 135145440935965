/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
import './CareersPost.scss';

class CareersPost extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  render() {
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">
              <section className="PostingItem">
                <a className="PostingItem__apply-btn" href="mailto:erik@noiz.gg">Apply for this job <i className="fa fa-envelope" /></a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">Business Development Representative</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <p>NOIZ is a group of esports and video game industry veterans. We come from companies like Major League Gaming, Riot Games, IGN, ESL, and more. We’re all passionate gamers and love the growing community of influencers and content creators that are helping our industry grow. Whether you are grinding APEX Legends on Twitch or creating Fortnite montages on YouTube, we’re here to support you.</p>

              <p>We're building a platform to connect two mutually beneficial groups: game developers and content creators.</p>

              <p>The Role:</p>

              <p>Noiz is looking for a Business Development Representative. You will be part of a committed group of gamers and professionals who develop ideas and are creating a unique platform in the video game industry. This is considered an entry-level role and will be a great first step into the industry or a transition role for the right candidate.</p>

              <p>As our Business Development Representative, you will be responsible for:</p>

              <ul>
                <li>Sourcing new sales opportunities through inbound lead follow up and outbound cold calls and emails.</li>
                <li>Understanding customer needs and requirements</li>
                <li>Negotiate and help build a content strategy to tell our story</li>
                <li>Drive initiatives with game publishers, video game streamers, and influencer management teams in authentic and positive ways</li>
                <li>Build relationships with decision makers across the video game industry</li>
                <li>Research industry trends and uncover new leads</li>
                <li>Responsible for full sales cycle from lead to close</li>
                <li>Communicating our story, promotions, and partnerships across various channels</li>
                <li>Engage daily with game publishers, gaming influencers, and influencer management teams</li>
              </ul>

              <p>Your Skills:</p>

              <ul>
                <li>Bachelor’s degree or equivalent experience</li>
                <li>1-2+ years experience in sales and/or marketing (Bonus experience: SaaS, Adtech, Inside Sales, Digital Marketing, Agency, Video Games)</li>
                <li>Deep understanding of gaming industry and how to build effective relationships</li>
                <li>The ability to analyze competitor strategies and innovate on best practices</li>
                <li>Strong organizational and program management skills</li>
                <li>Self Starter with entrepreneurial mindset.</li>
                <li>Experience working with and managing external agencies</li>
                <li>Ability to use quantitative data and metrics to drive strategic decisions and marketing activities</li>
                <li>Self-motivated, passionate and high energy level</li>
              </ul>

              <p>Extra Credit:</p>

              <ul>
                <li>Familiarity with Twitch, YouTube Live / Gaming</li>
                <li>Gamers in a past or current life who slayed digital dragons, knows how to move the payload, and/or can navigate through pixelated dungeons</li>
              </ul>


              <p className="CareersPostDetails__content--cta">Email your resume to <a href="mailto:erik@noiz.gg">erik@noiz.gg</a></p>
            </section>
          </section>
        </section>
      </section>);
  }
}

export default CareersPost;
