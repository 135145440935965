import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';

/* eslint-disable import/prefer-default-export */

export const getBUCampaignList = action$ =>
  action$.ofType('GET_BU_CAMPAIGN_LIST')
    .mergeMap(action =>
      api.getBUCampaignList(action.payload.token)
        .map(response => ({ type: 'GET_BU_CAMPAIGN_LIST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_BU_CAMPAIGN_LIST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_BU_CAMPAIGN_LIST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_BU_CAMPAIGN_LIST_ERROR', error }))
        .startWith({ type: 'GET_BU_CAMPAIGN_LIST_PENDING', payload: action.payload }));

export const getBUCampaign = action$ =>
  action$.ofType('GET_BU_CAMPAIGN')
    .mergeMap(action =>
      api.getBUCampaign(action.payload.campaignId, action.payload.startDate, action.payload.endDate, action.payload.token)
        .map(response => ({ type: 'GET_BU_CAMPAIGN_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_BU_CAMPAIGN_ABORTED'))
        .defaultIfEmpty({ type: 'GET_BU_CAMPAIGN_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_BU_CAMPAIGN_ERROR', error }))
        .startWith({ type: 'GET_BU_CAMPAIGN_PENDING', payload: action.payload }));

export const getBrandQuest = action$ =>
  action$.ofType("GET_BRAND_QUEST").mergeMap(action => {
    const {
      payload: { query = {}, token },
    } = action;
    return api
      .getBrandQuest(query, token)
      .map(response => ({ type: "GET_BRAND_QUEST_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("GET_BRAND_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "GET_BRAND_QUEST_CANCELLED" })
      .catch(error => getErrorResponse({ type: "GET_BRAND_QUEST_ERROR", error }))
      .startWith({ type: "GET_BRAND_QUEST_PENDING", payload: action.payload });
  });

export const getBrandStreamer = action$ =>
  action$.ofType("GET_BRAND_STREAMER").mergeMap(({ payload, payload: { username, token } }) => api
    .getBrandStreamer(username, token)
    .map(response => ({ type: "GET_BRAND_STREAMER_SUCCESS", payload: response }))
    .takeUntil(action$.ofType("GET_BRAND_STREAMER_ABORTED"))
    .defaultIfEmpty({ type: "GET_BRAND_STREAMER_CANCELLED" })
    .catch(error => getErrorResponse({ type: "GET_BRAND_STREAMER_ERROR", error }))
    .startWith({ type: "GET_BRAND_STREAMER_PENDING", payload: payload })
  );

export const getBrandUserQuest = action$ =>
  action$.ofType("GET_BRAND_USER_QUEST").mergeMap(({ payload, payload: { userQuestId, token } }) => api
    .getBrandUserQuest(userQuestId, token)
    .map(response => ({ type: "GET_BRAND_USER_QUEST_SUCCESS", payload: response }))
    .takeUntil(action$.ofType("GET_BRAND_USER_QUEST_ABORTED"))
    .defaultIfEmpty({ type: "GET_BRAND_USER_QUEST_CANCELLED" })
    .catch(error => getErrorResponse({ type: "GET_BRAND_USER_QUEST_ERROR", error }))
    .startWith({ type: "GET_BRAND_USER_QUEST_PENDING", payload: payload })
  );

export const getBrandGame = action$ =>
  action$.ofType("GET_BRAND_GAME").mergeMap(action => {
    const {
      payload: { query = {}, token },
    } = action;
    return api
      .getBrandGame(query, token)
      .map(response => ({ type: "GET_BRAND_GAME_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("GET_BRAND_GAME_ABORTED"))
      .defaultIfEmpty({ type: "GET_BRAND_GAME_CANCELLED" })
      .catch(error => getErrorResponse({ type: "GET_BRAND_GAME_ERROR", error }))
      .startWith({ type: "GET_BRAND_GAME_PENDING", payload: action.payload });
  });

export const brandUserUploadImageUrl = action$ =>
  action$.ofType('BRAND_REQUEST_UPLOAD_IMAGE_URL')
    .mergeMap(action =>
      api.brandUserUploadImageUrl(action.payload.slug, action.payload.format, action.payload.token)
        .map(response => ({ type: 'BRAND_REQUEST_UPLOAD_IMAGE_URL_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('BRAND_REQUEST_UPLOAD_IMAGE_URL_ABORTED'))
        .defaultIfEmpty({ type: 'BRAND_REQUEST_UPLOAD_IMAGE_URL_CANCELED' })
        .catch(error => getErrorResponse({ type: 'BRAND_REQUEST_UPLOAD_IMAGE_URL_ERROR', error }))
        .startWith({ type: 'BRAND_REQUEST_UPLOAD_IMAGE_URL_PENDING', payload: action.payload }));

export const postBrandPreapproval = action$ =>
  action$.ofType("POST_BRAND_PREAPPROVAL").mergeMap(action => {
    const {
      payload: { userQuestIds, status, token },
    } = action;
    return api
      .postBrandPreapproval(userQuestIds, status, token)
      .map(response => ({ type: "POST_BRAND_PREAPPROVAL_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("POST_BRAND_PREAPPROVAL_ABORTED"))
      .defaultIfEmpty({ type: "POST_BRAND_PREAPPROVAL_CANCELLED" })
      .catch(error => getErrorResponse({ type: "POST_BRAND_PREAPPROVAL_ERROR", error }))
      .startWith({ type: "POST_BRAND_PREAPPROVAL_PENDING", payload: action.payload });
  });

export const postBrandGame = action$ =>
  action$.ofType("POST_BRAND_GAME").mergeMap(action => {
    const {
      payload: { game = {}, token },
    } = action;
    return api
      .postBrandGame(game, token)
      .map(response => ({ type: "POST_BRAND_GAME_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("POST_BRAND_GAME_ABORTED"))
      .defaultIfEmpty({ type: "POST_BRAND_GAME_CANCELLED" })
      .catch(error => getErrorResponse({ type: "POST_BRAND_GAME_ERROR", error }))
      .startWith({ type: "POST_BRAND_GAME_PENDING", payload: action.payload });
  });

export const postBrandQuest = action$ =>
  action$.ofType("POST_BRAND_QUEST").mergeMap(action => {
    const {
      payload: { quest = {}, token },
    } = action;
    return api
      .postBrandQuest(quest, token)
      .map(response => ({ type: "POST_BRAND_QUEST_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("POST_BRAND_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "POST_BRAND_QUEST_CANCELLED" })
      .catch(error => getErrorResponse({ type: "POST_BRAND_QUEST_ERROR", error }))
      .startWith({ type: "POST_BRAND_QUEST_PENDING", payload: action.payload });
  });

export const postBrandRequirement = action$ =>
  action$.ofType("POST_BRAND_REQUIREMENT").mergeMap(action => {
    const {
      payload: { requirement = {}, token },
    } = action;
    return api
      .postBrandRequirement(requirement, token)
      .map(response => ({ type: "POST_BRAND_REQUIREMENT_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("POST_BRAND_REQUIREMENT_ABORTED"))
      .defaultIfEmpty({ type: "POST_BRAND_REQUIREMENT_CANCELLED" })
      .catch(error => getErrorResponse({ type: "POST_BRAND_REQUIREMENT_ERROR", error }))
      .startWith({ type: "POST_BRAND_REQUIREMENT_PENDING", payload: action.payload });
  });

export const putBrandGame = action$ =>
  action$.ofType("PUT_BRAND_GAME").mergeMap(action => {
    const {
      payload: { game = {}, token },
    } = action;
    return api
      .putBrandGame(game, token)
      .map(response => ({ type: "PUT_BRAND_GAME_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("PUT_BRAND_GAME_ABORTED"))
      .defaultIfEmpty({ type: "PUT_BRAND_GAME_CANCELLED" })
      .catch(error => getErrorResponse({ type: "PUT_BRAND_GAME_ERROR", error }))
      .startWith({ type: "PUT_BRAND_GAME_PENDING", payload: action.payload });
  });

export const putBrandQuest = action$ =>
  action$.ofType("PUT_BRAND_QUEST").mergeMap(action => {
    const {
      payload: { quest = {}, token },
    } = action;
    return api
      .putBrandQuest(quest, token)
      .map(response => ({ type: "PUT_BRAND_QUEST_SUCCESS", payload: response }))
      .takeUntil(action$.ofType("PUT_BRAND_QUEST_ABORTED"))
      .defaultIfEmpty({ type: "PUT_BRAND_QUEST_CANCELLED" })
      .catch(error => getErrorResponse({ type: "PUT_BRAND_QUEST_ERROR", error }))
      .startWith({ type: "PUT_BRAND_QUEST_PENDING", payload: action.payload });
  });
